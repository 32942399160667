export const getDataByBanks = (dataToUpdate, adviserData, action) => {
    let dataToUpdateAux = [...dataToUpdate] || [];
    
    for(let bank of adviserData.banks){
        let currentDataBank = dataToUpdateAux?.find(bankAux => bankAux.bankName == bank.bankName);
        let addBank = false;
        let filesId = [];

        for(let company of bank.companies){
            if(!(filesId.find(file => file.dispersionFileId == company.dispersionFileId))){
                filesId.push({
                    dispersionFileId: company.dispersionFileId,
                    filePath: company.filePath,
                    fileExtension: company.fileExtension,
                    fileName: company.fileName
                });
            }
        }

        if(!currentDataBank){
            currentDataBank = {};
            currentDataBank.total = 0;
            currentDataBank.advisers = [];
            currentDataBank.bankName = bank.bankName;
            if(bank.simpleBankName == 'Banorte') currentDataBank.fileName = bank.fileName
            currentDataBank.files = [];
            addBank = true;
        }

        
        currentDataBank.total = currentDataBank.total + (action == 'add' ? (+ bank.total ) : (- bank.total)) ;
        
        if(action == 'add'){
            currentDataBank.advisers.push({
                adviserName: adviserData.adviserName,
                totalByBank: bank.total,
                adviserEmail: adviserData.adviserEmail
            });

            currentDataBank.files = [...currentDataBank.files, ...filesId];

        }else{
            currentDataBank.advisers = currentDataBank?.advisers?.filter(adviserAux => adviserAux.adviserEmail != adviserData.adviserEmail);
            
            currentDataBank.files = currentDataBank?.files?.filter(
                element => filesId.indexOf(element) == -1
            );
            
        }

        if(addBank) dataToUpdateAux.push(currentDataBank);
    }

    dataToUpdateAux = dataToUpdateAux.filter(bankData => bankData.total > 0)
    return dataToUpdateAux;
}
    

import moment from 'moment';

export const isOrdinaryDispersionValid = (selectedDate, periodType) => {

    const firstPeriodDates = [ moment().set('date', 10).startOf('day'),  moment().set('date', 15).endOf('day') ];    
    const secondPeriodDates = [ moment().set('date', 25).startOf('day'), moment().set('date', 30).endOf('day') ];

    if(periodType === 'Ordinario'){
        if(moment().month() == moment(selectedDate[0]).month()){
            if(moment(selectedDate[0]).isBetween(firstPeriodDates[0], firstPeriodDates[1])){
                return true
            }
            if(moment(selectedDate[0]).isBetween(secondPeriodDates[0], secondPeriodDates[1])){
                return true
            }
        }

        return false
    }

    return true
}

export default {
    

    test(workbook, interns) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        let num = 1;
        const data = {
                'cols': ["A","B","C","D"],
                'config' : [
                            {  width: 32,  value: "fatherLastName",              text: "PRIMER APELLIDO",                      getData: (intern) => { return intern.father_lastname}  },
                            {  width: 32,  value: "motherLastName",              text: "SEGUNDO APELLIDO",                      getData: (intern) => { return intern.mother_lastname}  },
                            {  width: 32,  value: "firstname",              text: "NOMBRE(S)",                      getData: (intern) => { return intern.firstname}  },
                            {  width: 32,  value: "accountNumber",              text: "NO DE CUENTA",                      getData: (intern) => { return intern.santanderData.accountNumber}  },
                        ],
                'worksheetName': "Ligado Santander",
                'headerText': "Ligado Santander"
        }

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ff0000'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'ffffff'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createTableAvail(worksheetName){
            let cellNumber = 1;

            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            if(interns.length){
                interns.forEach((intern, index) => {
                    cellNumber++
                    config.forEach((configItem) => {
                        createWhiteCell(
                            configItem.col + cellNumber,
                            configItem.getData(intern),
                            worksheetName
                        )
                    });
                })
            }
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}

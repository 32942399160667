import JsPDF from 'jspdf'
import moment from 'moment';
const InternsLogo = require("./img");

  function getFont() {
    return "helvetica";
  }

  function getDate() {
    const today = new Date();
    const anio = today.getFullYear();
    const mes = today.getMonth();
    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const m = meses[mes];
    let dateDay = today.getDate().toString();
    if (today.getDate() <= 9) {
      dateDay = '0' + dateDay;
    }
    return dateDay + "-" + m + "-" + anio;
  }

  function formatDate(date){
    const meses = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const dateSplit = date.split("-");
    return dateSplit[2] + "-" + meses[parseInt(dateSplit[1], 10)] + "-" + dateSplit[0];
  }

  function getHeader(doc, title, subtitle, y) {
    const wide = 90;
    const tall = 600;
    doc.addImage(InternsLogo.getImgCaintra(doc), 'png', 55, y + 30, 80, 30);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.5);
    doc.line(40, y, tall, y);
    doc.line(40, y, 40, y + wide);
    doc.line(40, y + 90, tall, y + 90);
    doc.line(150, y + 50, 450, y + 50);
    doc.line(40, y + wide, tall, y + wide);
    doc.line(150, y, 150, y + wide);
    doc.line(450, y, 450, y + wide);
    doc.line(tall, y, tall, y + wide);
    doc.setFont(getFont(), "bold");
    doc.addImage(InternsLogo.getImgCaintra(doc), 'png', 469, y + 30, 80, 30);
    doc.text(240, y + 30, title);
    doc.setFont(getFont(), "bold");
    doc.setFont(getFont(), "normal");
    doc.setFontSize(8);
    doc.text(260, y + 72, '"Reporte de Practicantes"');
    doc.text(280, y + 82, 'PC+ CAINTRA');
    doc.setFontSize(9);
    doc.setFont(getFont(), "normal");
    doc.setFontSize(20);
    doc.setFont(getFont(), "bold");
    doc.text(subtitle, 50, 200);
  }

  function getFooter(doc, currentPage) {
    doc.setFont(getFont(), "normal");
    doc.setTextColor(128, 0, 0);
    doc.setFontSize(9);
    doc.text(50, 760, 'CONFIDENCIAL');
    doc.text(230, 760, 'PARA USO EXCLUSIVO CAINTRA');
    doc.text(490, 760, 'Página ' + currentPage);
    doc.setTextColor(0, 0, 0);
  }

  function getCover(doc, title) {
    InternsLogo.getImgVertical(doc);
    doc.addImage(InternsLogo.getImgCuadros(doc), 'png', 540, 10, 60, 70);
    doc.addImage(InternsLogo.getImgCaintra(doc), 'png', 250, 250, 160, 80);
    doc.setDrawColor(210, 210, 210);
    doc.rect(110, 400, 480, 80);
  
    doc.setLineWidth(3);
    doc.line(110, 481, 590, 481);
  
    doc.setFontSize(12);
    doc.setFont(getFont(), "bold");
    doc.text(235, 430, title);
    doc.setFont(getFont(), "normal");
  
    doc.setFontSize(14);
    const now = new Date().toLocaleString("es-MX")
    doc.text(320, 690, 'Fecha de impresión: ' + now);
  
    doc.setFontSize(9);
    doc.setFont(getFont(), "normal");
    doc.text(310, 770, 'Reporte de Practicantes');
    doc.text(330, 780, 'PC+ CAINTRA');
  }

  function getFilterHeaders(doc, filters, length) {
    const rows = length + 1;
    const wide = rows * 21;
    const tall = filters.length * 58;
    let columnLine = 93;
    let headerColSpace = 45;
    let y = 280;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(1);
    doc.line(40, y, tall, y);
    doc.line(40, y, 40, y + wide);
    doc.line(40, y + wide, tall, y + wide);
    doc.line(tall, y, tall, y + wide); 
    for(let index = 0; index < filters.length; index++){
        if(index != filters.length - 1){
            doc.line(columnLine, y, columnLine, y + wide);
            if(filters[index+1].value == "costCenter"){
                columnLine += 57;
            } else {
                columnLine += 53;
            }
        }
    }
    for (let j = 1; j < rows; j += 1){
        const line = j * 21
        doc.line(40, y + line, tall, y + line);
    }
    for(let index = 0; index < filters.length; index++){
      switch(filters[index].value){
      case "firstname":
          doc.text(headerColSpace, y + 15, "Nombre");
          headerColSpace += 53;
          break;
      
      case "fatherLastName":
          doc.text(headerColSpace, y + 15, "A. Paterno");
          headerColSpace += 53;
          break;

      case "motherLastName":
          doc.text(headerColSpace, y + 15, "A. Materno");
          headerColSpace += 53;
          break;

      case "costCenter":
          doc.text(headerColSpace, y + 15, "C. de Costos");
          headerColSpace += 53;
          break;

      case "startDate":
          doc.text(headerColSpace, y + 15, "I. de Conv.");
          headerColSpace += 53;
          break;
      
      case "endDate":
          doc.text(headerColSpace, y + 15, "F. de Conv.");
          headerColSpace += 53;
          break;
      
      case "daysToPay":
          doc.text(headerColSpace, y + 15, "Días");
          headerColSpace += 53;
          break;
      
      case "scolarshipAmount":
          doc.text(headerColSpace, y + 15, "Monto Beca");
          headerColSpace += 53;
          break;

      case "adjustment":
          doc.text(headerColSpace, y + 15, "Ajuste");
          headerColSpace += 53;
          break;

      case "companyInsurance":
          doc.text(headerColSpace, y + 15, "S. Empresa");
          headerColSpace += 53;
          break;
      
      case "internsInsurance":
          doc.text(headerColSpace, y + 15, "S. Pract");
          headerColSpace += 53;
          break;

      case "amountToPay":
          doc.text(headerColSpace, y + 15,"Monto Pago");
          headerColSpace += 53;
          break;

      case "bank":
          doc.text(headerColSpace, y + 15, "Banco");
          headerColSpace += 53;
          break;

      case "clabe":
          doc.text(headerColSpace, y + 15,"Cuenta");
          headerColSpace += 53;
          break;
      }
    }
  }

  function getInternsColumns(doc, filters, interns, days, dates) {
    doc.setFont(getFont(), "normal");
    let internColSpace = 53;
    let space = 21;
    const y = 280;
    for(let intern of interns){
      for(let index = 0; index < filters.length; index++){
        switch(filters[index].value){
          case "firstname":
          doc.text(internColSpace, y + space + 15, intern.firstname);
          internColSpace += 53;
          break;
          
          case "fatherLastName":
          doc.text(internColSpace, y + space + 15, intern.father_lastname);
          internColSpace += 53;
          break;

          case "motherLastName":
          doc.text(internColSpace, y + space  + 15, intern.mother_lastname);
          internColSpace += 53;
          break;

          case "costCenter":
          doc.text(internColSpace, y + space  + 15, (intern.cost_center_name || "N/A"));
          internColSpace += 53;
          break;

          case "startDate":
          doc.text(internColSpace, y + space  + 15, intern.contractStartDate);
          internColSpace += 53;
          break;
          
          case "endDate":
          doc.text(internColSpace, y + space  + 15, intern.contractEndDate);
          internColSpace += 53;
          break;
          
          case "daysToPay":
          doc.text(internColSpace, y + space  + 15, (intern.daysToPay || intern.pay_days).toString());
          internColSpace += 53;
          break;
          
          case "scolarshipAmount":
          doc.text(internColSpace, y + space  + 15,  currencyFormat(intern.scolarshipAmount));
          internColSpace += 53;
          break;

          case "adjustment":
          doc.text(internColSpace, y + space  + 15,  currencyFormat(intern.adjustment));
          internColSpace += 53;
          break;

          case "companyInsurance":
          doc.text(internColSpace, y + space  + 15, currencyFormat(intern.company_insurance_amount));
          internColSpace += 53;
          break;
          
          case "internsInsurance":
          doc.text(internColSpace, y + space  + 15, currencyFormat(intern.intern_insurance_amount));
          internColSpace += 53;
          break;

          case "amountToPay":
          doc.text(internColSpace, y + space  + 15, currencyFormat(intern.total_amount));
          internColSpace += 53;
          break;

          case "bank":
          doc.text(internColSpace, y + space  + 15, intern.bank);
          internColSpace += 53;
          break;

          case "clabe":
          doc.text(internColSpace, y + space  + 15, intern.clabe);
          internColSpace += 53;
          break;
        }
      }
      internColSpace = 53;
      space += 21;
    }
  }

  async function getPdf(moduleTitle, interns, dates, days, prefacture, filters) {
    filters = filters.filter(element => element.value != "status");
    const doc = new JsPDF('l', 'pt', 'letter',);
    let internsPerPage = 10;
    let currentPage = 1;
    let y = 200;
    const pages = Math.ceil(interns.length / internsPerPage);
    for (let i = 0; i < pages; i += 1){
      getHeader(doc, moduleTitle, "1.- Lista de Practicantes", 30);
      doc.setFontSize(8);
      let index = i * 10;
      let internsAux = interns.slice(index,index + 9);
      getFilterHeaders(doc, filters, internsAux.length);
      getInternsColumns(doc, filters, internsAux, days, dates);
    }

    doc.addPage("letter", "p");
    getHeader(doc, moduleTitle, "2.- Lista de Costos Totales", 30);
    y = 259;
    const wide = 232
    const tall = 570;
    doc.line(40, y, tall, y);
    doc.line(40, y, 40, y + wide);
    doc.line(40, y + wide, tall, y + wide);
    doc.line(tall, y, tall, y + wide);
    doc.line(305, y, 305, y + wide);
    for (let j = 1; j < 11; j += 1){
      const line = j * 21
      doc.line(40, y + line, tall, y + line);
    }
    doc.setFontSize(12);
    doc.setFont(getFont(), "bold");
    doc.text(45, 274, "Concepto");
    doc.setFont(getFont(), "normal");
    doc.text(45, 295, "Monto a Pagar");
    doc.text(45, 316, `${ (prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense)} % G. Admin`);
    doc.text(45, 337, "Subtotal sin Seguro");
    doc.text(45, 358, "Seguro Empresa");
    doc.text(45, 379, "Adeudos Empresa");
    doc.text(45, 400, "Subtotal con Seguro");
    doc.text(45, 421, "Abono Empresa");
    doc.text(45, 442, "Subtotal con Abonos");
    doc.text(45, 463, "I.V.A.");
    doc.text(45, 484, "Total Final");
    
    
    doc.setFont(getFont(), "bold");
    doc.text(310, 274, "Monto");
    doc.setFont(getFont(), "normal");
    doc.text(310, 295, currencyFormat(prefacture.totalCost));
    doc.text(310, 316, currencyFormat(prefacture.totalAdministrativeExpense));
    doc.text(310, 337, currencyFormat(prefacture.subtotalNoInsurance));
    doc.text(310, 358, currencyFormat(prefacture.insuranceCompanyData));
    doc.text(310, 379, currencyFormat(prefacture.debitCompany));
    doc.text(310, 400, currencyFormat(prefacture.subtotalInsurance));
    doc.text(310, 421, currencyFormat(prefacture.creditCompany));
    doc.text(310, 442, currencyFormat(prefacture.subtotalCredits));
    doc.text(310, 463, currencyFormat(parseFloat(prefacture.subtotalCredits * (0.16))));
    doc.text(310, 484, currencyFormat(prefacture._total));
    currentPage += 1
    getFooter(doc, currentPage);
    window.open(doc.output('bloburl'), '_blank', "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=200,left=350,width=1200,height=1200");
  }

const currencyFormat = (value) => {
  return "$" + parseFloat(value || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString();
};

/* eslint-disable import/prefer-default-export */
export { getPdf };

import React, { useState, useEffect } from 'react'
import { Table, Row, Col, Button, Form, Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { motion } from 'framer-motion';
import Methods from '../../../utils/https';
import { RiSearch2Line } from 'react-icons/ri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight, faDownload} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import DatePicker from "react-datepicker";
import ExcelCSV from "../Excel/DownloadInvoiceReports";
import ExcelError from "../Excel/DownloadEmailReport";
import * as Excel from "exceljs";
import {saveAs} from 'file-saver';
import { IoReload } from 'react-icons/io5';

export const ListInvoiceRecords = ({context, id_company}) => {
    const errorLabels = [
      { name: "date", label: 'Fecha'},
      { name: "company", label: 'Empresa'},
      { name: "email", label: 'Correo', convertValue:(data) =>{
        let res = "";
        for(let item in data){
          res += data[item]+ " ";
        }
        return res;
      }},
      { name: "period", label: 'Periodo'},
      { name: "invoice", label: 'Factura'},
      { name: "description", label: 'Error', convertValue:(data) =>{
        let res = "";
        for(let item in data){
          
          if(item === "code" || item === "message")
          res += data[item]+" ";
        }
        return res;
      }}
    ]
    const [isErrorSelected, setIsErrorSelected] = useState(false);
    const billingLabels = [
        { name: "periodType", label: 'Periodo'},
        { name: "companyName", label: 'Compañía'},
        { name: "isAuthorized", label: 'Autorizada',
          convertValue: (data) => {
            return data ? 'Sí' : 'No'
          }
        },
        { name: "isStamped", label: 'Timbrada',
          convertValue: (data) => {
            return data ? 'Sí' : 'No'
          }
        },
        { name: "authorizeAuthorName" , label: 'Autorizada por'},
        { name: "authorizeAuthorEmail", label: 'Email autorizada por'},
        { name: "authorizeDate", label: 'Fecha de autorización',
          convertValue: (date) => {
            return date ? moment(date).format('DD/MM/YYYY HH:ss:mm') : ''
          }
        },
        { name: "stampedAuthorEmail", label: 'Timbrada por'},
        { name: "stampedAuthorName", label: 'Email timbrada por'},
        { name: "stampedDate", label: 'Fecha de timbrado',
          convertValue: (date) => {
            return date ? moment(date).format('DD/MM/YYYY HH:ss:mm') : ''
          }
        },
        { name: 'agentName', label: 'Agente'},
        { name: "invoiceAmount", label: 'Total de factura', 
          convertValue : (value) => {
            return "$ " + (parseFloat(value) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          }
        },
        { name: "periodDates", label: 'Fechas de periodo'},
        { name: 'invoiceNumber', label: 'Número de factura'}
    ]
    const [availableKeys, setAvailableKeys] =useState(billingLabels); 
    const [filterValues, setFilterValues] = useState([
      { periodType: 'Catorcenal', show: true },
      { periodType: 'Quincenal', show: true },
      { periodType: 'Quincenal-Mensual', show: true },
      { periodType: 'Quincenal-Mensual C', show: true },
      { periodType: 'Quincenal 2', show: true },
      { periodType: 'Mensual 15', show: true },
      { periodType: 'Mensual 30', show: true },
      { periodType: 'Mensual 15 BAT', show: true}
    ])

    const [reportTypeSelected, setReportTypeSelected] = useState('Todos');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState(0);
    const [reportsAux, setReportsAux] = useState({
      allReports: [],
      reportsFiltered: [],
      pages: 1
    });

    const { allReports, reportsFiltered, pages } = reportsAux;
    const [rotateSync, setRotateSync] = useState(false);
    const rotate = rotateSync ? "rotate" : "";
    
    const getError = async (startDateAux, endDateAux) => {
        setRotateSync(true);
        setSearch('');
        let result = await Methods.getBillingErrors( {
          startDate: parseInt(moment(startDateAux || startDate).startOf('day').format('X')),
          endDate:  parseInt(moment(endDateAux || endDate).endOf('day').format('X'))}
        );
        for(let res of result){
          res.date = moment.unix(res.errorTimestamp).format("DD/MM/YYYY HH:mm:ss") 
        }
        if(result) {        
          let reportsFilteredAux = new Array(...result)
  
          let reportsByPage = getChunkArrays(reportsFilteredAux, 10);
  
          setReportsAux({...reportsAux, allReports: result, reportsFiltered: reportsByPage, pages: reportsByPage.length});
          setRotateSync(false);
        }
   }

    const getData = (start ,end ) => {
      isErrorSelected ? getError(start ,end) : getReports(start ,end);
    }

    useEffect(() => {
      getData();
    }, [isErrorSelected]);

    const getReports = async(startDateAux, endDateAux) => {
      setRotateSync(true);
      setSearch('');

      let result = await Methods.getInvoiceRecordsByType({
        startDate: parseInt(moment(startDateAux || startDate).startOf('day').format('X')),
        endDate:  parseInt(moment(endDateAux || endDate).endOf('day').format('X')),
        reportType: reportTypeSelected
      });

      if(result) {        
        let reportsFilteredAux = new Array(...result).filter((report) => { 
          for(let filterAux of filterValues){
            if(filterAux.show && filterAux.periodType == report.periodType) return true;
          }
          return false
        });

        let reportsByPage = getChunkArrays(reportsFilteredAux, 10);

        setReportsAux({...reportsAux, allReports: result, reportsFiltered: reportsByPage, pages: reportsByPage.length});
        setRotateSync(false);
      }
    }

    const handleChangeFilter = (filter, search) => {
      setCurrentPage(0);
      if(filter){
        filter.show = !filter.show;
        setFilterValues([...filterValues]);
      }
      let reportsFilteredAux = allReports.filter((report) => { 
        for(let filterAux of filterValues){
          if(filterAux.show && filterAux.periodType == report.periodType) {
            if((search && report.companyName?.toLowerCase().includes(search.toLowerCase()) 
            || report.authorEmail?.toLowerCase().includes(search.toLowerCase()) || !search)) return true;
          } 
        }
        return false
      });
      let reportsByPage = getChunkArrays(reportsFilteredAux, 10);
      setReportsAux({...reportsAux, reportsFiltered: reportsByPage, pages: reportsByPage.length || 1});
    }

    const downloadExcel = () => {
      const workbook = new Excel.Workbook();
     
      if(isErrorSelected){
        ExcelError.test(
          workbook,
          allReports,
          context.user.data.fullName
      );

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        saveAs(blob, `ReportesCorreos.xlsx`);
      });
      }else{
        let reportExcel = new Array(...reportsFiltered);
        if(!reportExcel[0] || !reportExcel[0].length) return;
        let aux = [];
        for (let reportExcelAux of reportExcel){
            for(let reportAux of reportExcelAux){
                let newValue = {...reportAux}
                newValue.isAuthorized = newValue.isAuthorized ? 'Sí' : 'No';
                newValue.isStamped = newValue.isStamped ? 'Sí' : 'No';
                newValue.authorizeDate = moment(newValue.authorizeDate).format('DD/MM/YYYY HH:ss:mm');
                newValue.stampedDate = moment(newValue.stampedDate).format('DD/MM/YYYY HH:ss:mm');
                newValue.invoiceAmount = "$ " + (parseFloat(newValue.invoiceAmount) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString();
                aux.push({...newValue});
            }
        }
        ExcelCSV.test(
          workbook,
          aux,
          context.user.data.fullName
      );

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        saveAs(blob, `ReportesFacturación.xlsx`);
      });
      }
      
    }



    const getChunkArrays = (array, size) => {
        let chunks = [];
        while (array.length){
            chunks.push(array.splice(0, size));
        }
        return chunks;
    }
    const changeSelected =() => {
        isErrorSelected ? setAvailableKeys(billingLabels) : setAvailableKeys(errorLabels);
        setIsErrorSelected(!isErrorSelected)
        
    }
    const DatePickerAux = ({dateAux, label, setAux, isStartDate}) => {
        return (
          <>

            {label}  <Button className="default-button date-btn"
              style={{backgroundImage: "none", backgroundColor: "#1B3769", marginLeft: "5px"}}
            >
            <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
              <DatePicker
                  selected={dateAux}
                  onChange={
                      async (date) => {
                        setAux(date);
                        setCurrentPage(0);
                        isErrorSelected ? await getError(isStartDate ? date : undefined, !isStartDate ? date : undefined) 
                                        : await getReports(isStartDate ? date : undefined, !isStartDate ? date : undefined);
                       
                      }
                  }
                  placeholderText={label}
                  dateFormat='dd MMM yyyy'
                  style={{backgroundImage: "none", backgroundColor: "transparent"}}
              />  
            </Button> 
          </>        
        );
    };

    return (
      <div>
          <Col>
            <Row>
              <Col className='col-reports'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="search-icon"><RiSearch2Line/></div>
                    <Form.Group controlId="search-bar" className="search-container">
                        <Form.Control 
                            type="text" 
                            placeholder="Búsqueda" 
                            autoComplete={"off"}
                            value={search}
                            onInputCapture={(e) => {
                              if(e.target.value === "") {
                                  handleChangeFilter(undefined, '')
                              }else{
                                  handleChangeFilter(undefined, e.target.value);
                              }
                              setSearch(e.target.value);
                          }}
                        />
                    </Form.Group>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                      >
                          <a href="#" className="reload-table-grey" onClick={async () => {isErrorSelected ?  await getError() : await getReports()}}><IoReload className={rotate}/></a>
                    </OverlayTrigger>
                </div>
                </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} sm={12} className='col-reports'>
                <div className="options">
                { !isErrorSelected && <Dropdown disab drop={"down"}>
                            <Dropdown.Toggle
                                className="drop-down-filters"
                                id="dropdown-custom-components"
                            >
                                Información a consultar
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{margin: "0px", padding: "0px"}}>
                              
                              { filterValues.map( (filter, index) => { 
                                  return ( 
                                    <div className='check-container'>
                                      <Form.Check 
                                        className="checkbox" 
                                        type="checkbox"                                       
                                        checked={filter.show} 
                                        onChange={ () => { 
                                          handleChangeFilter(filter, search);
                                        }}
                                      />
                                      {filter.periodType} 
                                    </div>
                                  );
                                })
                              }
                              
                            </Dropdown.Menu>
                        </Dropdown>}
                      <DatePickerAux dateAux={startDate} label={'Desde la fecha:'} setAux={setStartDate} isStartDate={true}/>
                      <DatePickerAux dateAux={endDate} label={'Hasta la fecha:'} setAux={setEndDate}/>
                      <Button className="default-button" onClick={() => changeSelected()}> 
                              {isErrorSelected ? "Facturación" : "Correos"}
                      </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} sm={12} className='col-reports'>
              <div className="options">
                <Button className="default-button" onClick={() =>{
                        downloadExcel();
                    }}>
                    <FontAwesomeIcon className="inner-text" icon={faDownload}/>
                    Descargar Excel
                </Button>
                
              </div>    
              </Col>
            </Row>
            <Row>
            <Col className='col-reports'>
                  <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ duration: 0.4 }} style={{width: '95%'}}>                     
                      <div className="table-scroll">
                          <Table hover>
                              <thead>
                                { availableKeys.map(keyItem => {
                                    return <td style={{textAlign: 'center'}}>{keyItem.label}</td>
                                })}
                              </thead>
                              {reportsFiltered.length ? <tbody>
                                  {reportsFiltered[currentPage].map((item, index) => {
                                    return <tr>
                                        {availableKeys.map(keyItem => {
                                            return <td style={{minWidth: '200px', whiteSpace: 'pre-wrap'}}>{ keyItem.convertValue ?  keyItem.convertValue(item[keyItem.name]) : item[keyItem.name] }</td>
                                        })}
                                    </tr>
                                  })}
                              </tbody> : <tbody></tbody>}
                          </Table>
                      </div>
                      <ReactPaginate
                          pageCount={pages} 
                          forcePage={currentPage}
                          containerClassName="pagination-container" 
                          previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                          nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                          onPageChange={(e) => { 
                              setCurrentPage(e.selected);
                          }}
                      />
                  </motion.div>
              </Col>
            </Row>
          </Col>
      </div>
    )
}

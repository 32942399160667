import React from 'react';
import { motion } from "framer-motion";
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InternProfilePicture } from './InternProfilePicture';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
export const InternListTable = ({
    interns,
    context, 
    setUpdateList,
    updateList,
    statusTable,
    isGlobal,
    companies,
    requireSife,
    changeSignatureStatus,
    dropDownOptions,
    changeStatusByFilter,
    signatureStatus
}) => {

    const getCircleByStatus = (status) => {

        let color = {
          'Todo': 'circle-div  all-signatures',
          'Firmado': 'circle-div  signed-signatures',
          'Pendiente': 'circle-div  pending-signatures',
          'Atrasado': 'circle-div  red-signatures'
        }
        
        return (
          <div className={`${color[status] || 'circle-div transparent'}`}>
          </div>
        )
    }

    return (
    <motion.div className='intern-list-table'>
            <div id="table" className="verify-interns-docs table-container-list table-container-list-interns">
            <Table hover>
                <thead>
                    <tr>
                        <th style={{minWidth: '500pxpx'}} className='th-text'>Nombre</th>
                        <th style={{minWidth: '350px'}} className='th-text'>Correo</th>
                        <th style={{minWidth: '160px'}} className='th-text'>Teléfono</th>
                        <th style={{minWidth: '160px'}} className='th-text'>Documentación</th>
                        <th style={{minWidth: '250px'}} className='th-text'>
                            <a className="filter-button" onClick={() => changeStatusByFilter()}>
                                Estatus
                                <FontAwesomeIcon style={{paddingLeft:"2px", fontSize:"1.3em"}} icon={faFilter}/>
                                <span className='span-text'>{statusTable}</span>
                            </a>
                        </th>
                        {requireSife && 
                            <th style={{ minWidth: '110px' }} className='th-text' >
                            <div className='center-status-signature'>
                                <a className="filter-button" onClick={() => changeSignatureStatus()}>
                                <span className='span-text'>
                                    Firma
                                </span>
                                </a>
                                {getCircleByStatus(signatureStatus)}
                            </div>
                            </th>
                        }
                        {isGlobal && <th className='tr-text'>Compañía</th>}
                    </tr>
                </thead>
                <tbody>
                    { interns ?  interns.map((intern, index) => {
                        if (intern.internStatus == statusTable || statusTable == "Todo"){
                            let showMissingValueAlert = false;
                            Object.entries(intern).forEach(([key, value]) => value == "" && (showMissingValueAlert = true));
                            return (
                                <tr>
                                    <td>
                                        <div className='profile-picture-container'>
                                            <InternProfilePicture
                                                intern={intern}
                                                context={context}
                                                setUpdateList={setUpdateList}
                                                updateList={updateList}
                                            />
                                            {intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname}
                                        </div>
                                    </td>
                                    <td>{intern.email}</td>
                                    <td>{intern.phone}</td>
                                    <td>{intern.documentationStatus}</td>
                                    <td>{intern.internStatus}</td>
                                    {requireSife && 
                                        <td> 
                                            <div className='center-status-signature'>
                                                {getCircleByStatus(intern.signatureStatus)}
                                            </div>
                                        </td>
                                    }
                                    {isGlobal && <td>{companies[index]}</td>}
                                    <td>
                                        { dropDownOptions(intern, showMissingValueAlert) }
                                    </td>
                                </tr>
                            )
                        }
                        }) : <></> 
                    } 
                </tbody>
            </Table>
            </div>
    </motion.div>
    )
}

import React from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import './LoadingModal.css';

export default function LoadingModal({isLoading}) {
    return(
        <Modal className="loading-screen-container" show={isLoading} centered>
                {isLoading && 
                    <div class="cssload-thecube">
                        <div class="cssload-cube cssload-c1"></div>
                        <div class="cssload-cube cssload-c2"></div>
                        <div class="cssload-cube cssload-c4"></div>
                        <div class="cssload-cube cssload-c3"></div>
                    </div>
                }
        </Modal>
    );
};
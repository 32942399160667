import { useEffect, useState, useContext } from 'react';
import { SearchBar } from './components/SearchBar.jsx';
import { Button, Spinner } from 'react-bootstrap';
import { CompaniesTable } from './components/CompaniesTable.jsx';
import { HistoryRecords } from './components/HistoryRecords.jsx'
import { CompanyInvoices } from './components/CompanyInvoices.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar, faChevronRight, faChevronLeft, faFileExcel, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { getDisabledCompanies, createExcel, sendNotifications } from './helpers';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context/UserContext';
import ReactPaginate from "react-paginate";
import Methods from '../../utils/https.js';
import { notify } from '../../utils/notify.js';
import saveAs from 'file-saver'
import moment from 'moment';
import './CompaniesSuspension.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const CompaniesSuspension = () => {

    const context = useContext(UserContext);
    const [companies, setCompanies] = useState({
        allCompanies: [],
        companiesByPage: []
    });
    const [total , setTotal] = useState({});
    const [invoices , setInvoices] = useState({})
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState('');
    const [updateList, setUpdateList] = useState(false);
    const [statusTable, setStatusTable] = useState("Todas");
    const [historyRecords, setHistoryRecords] = useState([]);
    const [showHistoryRecords, setShowHistoryRecords] = useState(false);
    const [companyIdRecordsToSearch, setCompanyIdRecordsToSearch] = useState('');
    const { allCompanies, companiesByPage } = companies;
    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [companyInvoices, setCompanyInvoices] = useState([]);
    const [showCompanyInovices, setShowCompanyInovices] = useState(false);
    const [companyIdInvoice, setCompanyIdInvoice] = useState(false);
    const [company, setCompany] = useState([]);
    const [companiesToNotify, setCompaniesToNotify] = useState([]);
    const [isSendingEmails, setIsSendingEmails] = useState(false);
    const [inovicesToSend, setInovicesToSend] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {
        getCompanies();
        setCurrentPage(0);
    }, [updateList]);

    useEffect(()=> {
        getHistoryRecords();
    }, [companyIdRecordsToSearch]);

    useEffect(()=> {
        getInoviceRecords();
    }, [companyIdInvoice]);
    

    const totalHandler = (totalData) => {
        setTotal(totalData);
        
    }
    const invoiceHandler = (invoices) =>{
        setInvoices(invoices);
      
    }
    const getHistoryRecords = async() => {

    
        if(companyIdRecordsToSearch){
            let { records } = await Methods.getDesactivationHistoryRecords(companyIdRecordsToSearch);
            setHistoryRecords(records);
            if(records.length){
                setShowHistoryRecords(true);
            }else{
                setCompanyIdRecordsToSearch('');
                setShowHistoryRecords(false);
                notify('No se encontraron registros', 'error');
            }
        }
    }

    const getInoviceRecords = async() => {
        console.log(1)
        for(let i=0 ; i<allCompanies?.companies?.length;i++){
            console.log(i)
            console.log(allCompanies?.companies[i], company)
            if((allCompanies?.companies[i]?._id === company && allCompanies.companies[i].isDisabled === false)){
                notify('No se puede efectuar la acción ya que la empresa no está suspendida', 'error'); 
                setCompany("");
                return;
            }
            else if(allCompanies?.companies[i]?._id === company && !('isDisabled' in allCompanies.companies[i])){ 
                notify('No se puede efectuar la acción ya que la empresa no está suspendida', 'error');
                setCompany("");
                return;
            }
        }
        
        if(companyIdInvoice){
           
            const data = {
                startDate: parseInt(moment().subtract(6, "months").format('X')),
                endDate: parseInt(moment().endOf('day').format('X')),
                reportType: 'Todos',
                additionalConditions: [{
                    key: 'companyId',
                    value: companyIdInvoice
                }],
                dontShowError: true
            }
            
            let records = await Methods.getInvoiceRecordsByType(data);
            setCompanyInvoices(records);
            if(records?.length){
                setShowCompanyInovices(true);
            }else{
                setCompanyIdInvoice('');
                setShowCompanyInovices(false);
                notify('No se encontraron registros', 'error');
            }
        }
    }

    const getCompanies = async() => {
        setIsLoadingSearch(true);
    
        let {allCompanies, companiesByPage} = await getDisabledCompanies({
            search,
            pageLimit: 10,
            onlyDisabledCompanies: statusTable == "Suspendidas",
            onlyEnabledCompanies: statusTable == "Liberadas",
            companiesLimit: 200, 
            companiesToNotify
        });
      
        setCompanies({
            allCompanies, 
            companiesByPage
        });
        setIsLoadingSearch(false);
    }

    const changeStatusByFilter = () => {

        let statusTableAux = {
            'Todas': 'Suspendidas',
            'Suspendidas': 'Liberadas',
            'Liberadas': 'Todas'
        }

        setStatusTable(statusTableAux[statusTable]);
        setUpdateList(!updateList);
    }

    const downloadExcel = async() => {
        setIsLoadingExcel(true);
        let blob = await createExcel({    
            createdBy: context.user.data.email,  
            search,
            onlyDisabledCompanies: statusTable == "Suspendidas",
            onlyEnabledCompanies: statusTable == "Liberadas",
            startDay : startDate,
            endDay :endDate
        });

        if(blob){
            saveAs(blob, 'CompañíasSuspendidas.xlsx');
        }

        setIsLoadingExcel(false);
    }

    const cleanCheckedCompanies = async () => {

        allCompanies.companies.map(company => company.notifyCheck = false);

        let companiesByPageAux = await getChunkArrays([...allCompanies.companies], 10);

        setCompanies({
            allCompanies, 
            companiesByPage: companiesByPageAux
        });
    }

    const getChunkArrays = async(array, size) => {
        let chunks = [];
        while (array.length){
            chunks.push(array.splice(0, size));
        }
        return chunks;
    }


    const StartDay = () => {
        return (
            <DatePicker
                selected={startDate}
                onChange={ async (date) => {
                    setStartDate(date);

                }}
                dateFormat='dd MMM yyyy'
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
            />           
        );
    };

    const EndDay = () => {
        return (
            <DatePicker
                selected={endDate}
                onChange={
                    async (date) => {
                        setEndDate(date);
                        
                    }
                }
                dateFormat='dd MMM yyyy'
                fixedHeight
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
            />           
        );

    };

    return (
        <div className='companies-suspension'>
            {isLoadingSearch &&
                            <div  style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: '0px',
                                zIndex: '999',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente
                              }} >
                                <Spinner animation="border" role="status" />
                            </div>
                                
                            }
            <h4>Suspensión de compañías</h4>

            <div className='options-container'>
                <SearchBar
                    search={search}
                    setSearch={setSearch}
                />
                <div className='buttons-general-container'>
                    <div className='buttons-container'>
                        <Button 
                            className='green-button'
                            onClick={()=> setUpdateList(!updateList)}
                        >   

                            {isLoadingSearch &&
                            <div className='center-container'>
                                <Spinner animation="border" role="status" />
                            </div>
                                
                            }
                            
                            Buscar
                        </Button>

                        <Button 
                            className='green-button'
                            disabled={isSendingEmails}
                            onClick={async()=> {
                                setIsSendingEmails(true);
                                await sendNotifications(companiesToNotify, inovicesToSend);
                                await cleanCheckedCompanies();
                                setCompaniesToNotify([]);
                                setInovicesToSend([]);
                                setIsSendingEmails(false);
                            }}
                        >
                            
                            {isSendingEmails ? 
                                <Spinner animation="border" className='spinner-custom' /> 
                                :
                                <FontAwesomeIcon
                                    className='file-excel-icon'
                                    icon={faEnvelope}
                                />
                            }
                            Notificar
                        </Button>
                    </div>

                    <div className='buttons-container'>
                    Desde la fecha: <Button className="default-button date-btn my-date"
                        style={{backgroundImage: "none", backgroundColor: "#1B3769", marginLeft: "5px"}}
                        >
                            <FontAwesomeIcon className="inner-text my-btn" icon={faCalendar}/>
                            <StartDay/>
                        </Button>

                        Hasta la fecha: <Button 
                        className="default-button date-btn my-date"
                        style={{backgroundImage: "none", backgroundColor: "#1B3769", marginLeft: "5px"}}
                        >
                            <FontAwesomeIcon className="inner-text my-btn" icon={faCalendar}/>
                            <EndDay/>
                        </Button>
                        <Button className='green-button' onClick={()=> downloadExcel()}>

                            {isLoadingExcel ? 
                                <Spinner animation="border" className='spinner-custom' /> 
                                :
                                <FontAwesomeIcon
                                    className='file-excel-icon'
                                    icon={faFileExcel}
                                />
                            }
                            
                            Descargar excel
                        </Button>
                        <a className="filter-button" onClick={() => changeStatusByFilter()}>
                            <span className='span-text'>{statusTable}</span>
                            <FontAwesomeIcon 
                                style={{paddingLeft:"2px", fontSize:"1.3em"}} 
                                icon={faFilter}
                            />
                        </a>

                    </div>
                </div>
            </div>

            <CompaniesTable
                statusTable = {statusTable}
                invoicesSelected = {invoices}
                totalCount = {total}
                companiesByPage={companiesByPage}
                currentPage={currentPage} 
                setCompanies={setCompanies}
                setCompany={setCompany}
                allCompanies={allCompanies}
                setCompanyIdRecordsToSearch={setCompanyIdRecordsToSearch}
                setCompanyIdInvoice={setCompanyIdInvoice}
                setCompaniesToNotify={setCompaniesToNotify}
                companiesToNotify={companiesToNotify}
            />

            <ReactPaginate
                pageCount={companiesByPage.length}
                forcePage={currentPage}
                containerClassName="pagination-container"
                previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                onPageChange={(e) => {
                    setCurrentPage( e.selected)
                }}
            />

            {showHistoryRecords && 
                <HistoryRecords
                    historyRecords={historyRecords}
                    setHistoryRecords={setHistoryRecords}
                    setShowHistoryRecords={setShowHistoryRecords}
                    showHistoryRecords={showHistoryRecords}
                    setCompanyIdRecordsToSearch={setCompanyIdRecordsToSearch}
                />
            }

            {showCompanyInovices && 
                <CompanyInvoices
                    invoicesSelected = {invoiceHandler}
                    onInvoice = {totalHandler}
                    companyInvoices={companyInvoices}
                    setCompanyInvoices={setCompanyInvoices}
                    setShowCompanyInovices={setShowCompanyInovices}
                    showCompanyInovices={showCompanyInovices}
                    setCompanyIdInvoice={setCompanyIdInvoice}
                    setInovicesToSend={setInovicesToSend}
                    inovicesToSend={inovicesToSend}
                    allCompanies={allCompanies}
                    companyIdInvoice = {companyIdInvoice}
                />
            }
        </div>
    )
}

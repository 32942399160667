import React, {useContext, useState} from 'react';
import {Table, OverlayTrigger, Tooltip, Card, Row, Col, Form, Dropdown, Button} from 'react-bootstrap';
import {faCheckCircle, faExclamationTriangle, faEye, faTimesCircle, faUsers, faUserTag, faUser, faCamera, faMinusCircle, faBuilding, faTrashAlt, faMoneyBill} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';
import {UserContext} from '../../../context/UserContext';
import ApproveCompanyModal from '../components/ApproveCompanyModal.jsx';
import { notify } from '../../../utils/notify';
import DefaultUser from '../../../assets/default-user.png';
import Methods from '../../../utils/https';
import { BsThreeDots } from 'react-icons/bs';
import GeneralistaIcon from "../../../assets/IconsCaintra/GeneralistasIcon.svg";
const axios = require("axios");

export default function ListCompanies ({companies, pendingCompanies, setCurrentCompanyId, setTab, isLoading, updateList, setUpdateList, setShowDeleteCompanyModal, showDeleteCompanyModal, showUnsuscribeCompanyModal, setShowUnsuscribeCompanyModal}) {
    const context = useContext(UserContext);
    const [showApproveCompanyModal, setShowApproveCompanyModal] = useState(false);
    const [showRejectCompanyModal, setShowRejectCompanyModal] = useState(false);
    const [idToApprove, setIdToApprove] = useState(undefined);
    const [companyEmail, setCompanyEmail] = useState(undefined);
    const [companyName, setCompanyName] = useState(undefined);
    const isMirrorAdmin = context.user.data.mirrorAdmin;


    const renderNoCompaniesMessage = () => {
        return(<h4>No hay compañías registradas aún</h4>);
    };

    const handleChangeTab = (e, companyId, tab) => {
        e.preventDefault();
        
        if(tab != "Advisors"){
            if(!context.can("view", tab)){
                notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
                return;
            };
        }
        
        setCurrentCompanyId(companyId);
        setTab(tab);
    }
    const handleUploadCompanyLogo = async (companyId, file, name, key, lastDocId, isUrl) => {
        await Methods.uploadCompanyDoc(companyId, file, name, key, lastDocId, isUrl);
        await setUpdateList(!updateList);
    };

    const renderMyCompanies = () => {
        let duration = 0.4;

        return(
            <div>
                <div className="title-container">
                    <h5>Compañías</h5>
                </div>
                {
                    companies.map(company => {
                        duration+=0.1;
                        return (
                            <div className="table-row">
                                <div className="profile-photo" style={{backgroundImage: company.logo_url != "" ? `url(${company.logo_url})` : `url(${DefaultUser})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                    <a href="#" className="profile-photo-camera">
                                        <Form.Group className="files">
                                            <a href="#">
                                                
                                                <Form.File 
                                                onChange={(e) => {
                                                        e.preventDefault();
                                                        if(e.target.files[0]){ handleUploadCompanyLogo(company._id, e.target.files[0], "Company_Logo", "Company Logo", undefined, true)
                                                        };
                                                    }
                                                }
                                                id={company._id}
                                                label={<FontAwesomeIcon icon={faCamera}/>}
                                                />
                                                </a>
                                        </Form.Group>
                                    </a>
                                </div>
                                <div className="name-container">
                                    {company.business_name}
                                </div>
                                <div className="proved-container center-container"> 
                                    {!company.unsuscribedCompany ?
                                        <span style={{color: company.isApproved ? "darkgreen" : "tomato"}}>{company.isApproved ? "Aprobada" : "Pendiente"}</span> : 
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">Motivo: {company.unsuscribeDescription}</Tooltip>}
                                        >
                                        <span style={{color:"tomato"}}>{company.unsuscribeMotive}</span>
                                        </OverlayTrigger>
                                    }
                                </div>
                                <div className="options-container">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                        <BsThreeDots/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentCompanyId(company._id);
                                                    setTab("verifyCompanyData");    
                                                }}
                                            >
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Verificación de Datos
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>

                                            <Dropdown.Item onClick={(e) => handleChangeTab(e, company._id, "Users")}>
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faUsers} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Gestión de usuarios
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>

                                            <Dropdown.Item onClick={(e) => handleChangeTab(e, company._id, "Roles")}>
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faUserTag} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Gestión de roles
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>

                                            <Dropdown.Item 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentCompanyId(company._id); 
                                                handleChangeTab(e, company._id, "Departments");
                                                }}
                                            >
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faBuilding} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                    {company.isITESMCompany == true && "Administrar VP-VR" }
                                                    {company.isITESMCompany != true && "Administrar departamentos" }
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>
                                            {company.isITESMCompany == true && <Dropdown.Item 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentCompanyId(company._id);
                                                    handleChangeTab(e, company._id, "CostCenters");
                                                }}
                                            >
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faMoneyBill} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Centro de costos
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>}

                                            {company.isITESMCompany == true && <Dropdown.Item 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentCompanyId(company._id);
                                                    handleChangeTab(e, company._id, "Generalistas");
                                                }}
                                            >
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <img src={GeneralistaIcon}/>
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Generalistas
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>}

                                            <Dropdown.Item 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentCompanyId(company._id); 
                                                    setShowUnsuscribeCompanyModal(!showUnsuscribeCompanyModal);
                                                }}
                                            >
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Dar de baja
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>
                                            
                                            <Dropdown.Item 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentCompanyId(company._id); 
                                                    setShowDeleteCompanyModal(!showDeleteCompanyModal);
                                                }}
                                            >
                                                <Row>
                                                    <Col md={2} className="col icon-container">
                                                        <FontAwesomeIcon icon={faMinusCircle} />
                                                    </Col>
                                                    <Col md={9} className="col">
                                                        Eliminar Compañía
                                                    </Col>
                                                </Row>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        );

    };



    const renderPendingCompanies = () => { 
        let duration = 0.4;
        return(
            <div style={{marginTop: "2rem"}}>
                <h4 style={{marginLeft: "12px"}}>Autorización de compañías</h4>
                <div className="cards-grid scroll" style={{marginBottom: "100px"}}>
                {
                    pendingCompanies.map((company, index) => {
                        duration+=0.1;
                        return(
                            <motion.div initial={{opacity: 0, y: -40}} key={index} animate={{opacity: 1, y: 0}} transition={{duration: duration}}>
                                <Card style={{height:"250px"}}>
                                    <Card.Header className="myHeader">
                                        <p className="company-name">{company.business_name}</p>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row style={{height: "70%"}}>
                                            
                                            <Col className="rounded-circle" md={4} xs={4} sm={4}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Verificación de Datos</Tooltip>}
                                                >
                                                    <div >
                                                    <a href="#" 
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCurrentCompanyId(company._id);
                                                            setTab("verifyCompanyData");
                                                        }}>
                                                      <Button className="myButton" variant="warning"><FontAwesomeIcon icon={faEye} /></Button>
                                                    </a>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col className="rounded-circle" md={4} xs={4} sm={4}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Aprobar compañia</Tooltip>}
                                                >                                           
                                            <div 
                                                    className="option approve"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIdToApprove(company._id);
                                                        setCompanyEmail(company.org_admin_email);
                                                        setCompanyName(company.business_name);
                                                        setShowApproveCompanyModal(!showApproveCompanyModal);
                                                    }}>
                                                    <Button className="myButton" variant="success"><FontAwesomeIcon icon={faCheckCircle} /></Button>
                                                </div>
                                                
                                                 </OverlayTrigger>
                                            </Col>
                                            <Col className="rounded-circle" md={4} xs={4} sm={4}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Rechazar compañia</Tooltip>}
                                                >                                           
                                            <div style={{ marginLeft: "-8px"}}
                                                    className="option deny"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCurrentCompanyId(company._id);
                                                        setCompanyEmail(company.org_admin_email);
                                                        setCompanyName(company.business_name);
                                                        setShowRejectCompanyModal(!showRejectCompanyModal);
                                                    }}>
                                                    <Button className="myButton" variant="danger"><FontAwesomeIcon icon={faTimesCircle} /></Button>
                                                </div>
                                                
                                                 </OverlayTrigger>
                                            </Col>
                                          
                                        </Row>

                                     
                                    </Card.Body>
            
                                    {(!company.processFinished || !company.isApproved) &&
                                        <Card.Footer>
                                            {!company.processFinished && 
                                            <Row>
                                                <Col md={12} xs={12} sm={12}>
                                                    <div style={{display: "flex", flexDirection: "row", marginTop: "5px"}}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{marginTop: "1px", marginRight: "5px", color: "orange"}}/>
                                                    <p style={{margin: 0, fontWeight: "lighter", fontSize: "small"}}>Tienes información por llenar</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            }

                                            {(!company.isApproved && company.processFinished) && 
                                            <Row>
                                            <Col md={12} xs={12} sm={12}>
                                            <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} style={{marginTop: "1px", marginRight: "5px", color: "orange"}}/>
                                                <p style={{margin: 0, fontWeight: "lighter", fontSize: "small"}}>En espera de aprobación</p>
                                            </div>
                                            </Col>
                                        </Row>
                                        }
                                        </Card.Footer>
                                    }
                                </Card>
                            </motion.div>
                        )
                    })
                }
                </div>
            </div>
        );
    };

    return(
        <>
            {!isLoading &&
                <div className="list-companies" style={{width: "100%"}}>
                    {companies.length == 0 ? renderNoCompaniesMessage() : renderMyCompanies()}
                    {context.can("approve", "Companies") && !isMirrorAdmin && renderPendingCompanies()}
                </div>
            }

            <ApproveCompanyModal 
                currentCompanyId={idToApprove} 
                setShowApproveCompanyModal={setShowApproveCompanyModal} 
                showApproveCompanyModal={showApproveCompanyModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                companyEmail={companyEmail} 
                companyName={companyName}
            />
        </>
    );
};
import React, {useState} from 'react';
import {DateTimePicker} from 'react-widgets';
import {Button, Row, Col, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {notify} from "../../../utils/notify";
import { motion } from "framer-motion";
import Methods from '../../../utils/https';
import { BiArrowBack } from 'react-icons/bi';
import moment from 'moment';
const uuid = require("uuid");
const $ENV = "dev"; //modified in buildspec

const sifeEmailUrl = () => {
    const sife = {
        "dev": "https://dev.sife.io/",
        "prod": "https://sife.io/"
    }

    return sife[$ENV || "dev"] || sife.dev;
}

export default function NewIntern ({setCurrentTab, context}) {
    const [contractEndDate, setContractEndDate] = useState(new Date());
    const [duration, setDuration] = useState(0);
    const [contractStartDate, setContractStartDate] = useState(new Date());
    const [sendEmail, setSendEmail] = useState(false);
    const [canEditEndDate, setCanEditEndDate] = useState(false);
    const currentCompany = context.user ? context.user.company.business_name : "";
    const maxMonthsContract = context.user.company.isITESMCompany ? 12 : 6;
    const isITESMCompany = context.user.company.isITESMCompany;
    const maxMonths = isITESMCompany ?  ['1','2','3','4','5','6','7','8','9','10','11','12'] : ['1','2','3','4','5','6'];
    const requireInsurance = context.user.company.interns_medical_insurance == 'Sí' ? true : false;
    const isCaintraUser = context.user.data.isCaintraUser;
    const [internRequireInsurance, setInternRequireInsurance] = useState(requireInsurance);
    const handleNewIntern = async (e) => {
        e.preventDefault();
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
        let intern = {
            firstname: e.target.elements.name.value,
            father_lastname: e.target.elements.father_lastname.value,
            mother_lastname: e.target.elements.mother_lastname.value,
            email: e.target.elements.email.value,
            surveyKey: uuid.v1(),
            id_company: context.user.company._id,
            contractStartDate: e.target.elements.contractstartdate.value,
            contractEndDate: e.target.elements.contractenddate.value,
            contractDuration: e.target.elements.contractduration.value,
            bank: context.user.company.internship_bank,
            created_by: context.user.data._id,
            insurancePayment: context.user.company.insurance_payment,
            createdByEmail: context.user.data.email,
            createdByFullName: context.user.data.fullName, 
            requireInsurance: internRequireInsurance
        };
        let contractStartDateAux = moment(contractStartDate).startOf('day');
        let contractEndDateAux = moment(contractEndDate).startOf('day');
        let currentDateAuxSevenDays = moment().startOf('day');
        currentDateAuxSevenDays.subtract(6,'days');

        if(contractStartDateAux.diff(contractEndDateAux, 'days') > 0){
            notify("La fecha de fin del convenio debe ser mayor a la fecha de inico del convenio", "error");
            return;
        }

        if(currentDateAuxSevenDays.diff(contractStartDateAux, 'days') > 0 && context.user.company.interns_medical_insurance == "Sí"){
            notify("La fecha inicio del convenio no debe ser 7 días menor que la fecha actual", "error");
            return;
        }

        if(duration == 0){
            let endDate = new Date(contractEndDate);
            endDate.setHours(0,0,0,0);
            let evaluatedDate = moment(endDate);
            const dif = evaluatedDate.diff(moment().startOf('day'), 'days');
            let totalDuration = Math.ceil(evaluatedDate.diff(moment(contractStartDate),"months", true));
            intern.contractDuration = totalDuration;
            if(dif <= 0){
                notify("La fecha finalización del convenio debe ser mayor a la fecha actual", "error");
                return;
            }

            if(totalDuration > maxMonthsContract){
                notify(`La fecha fin no puede ser mayor a ${maxMonthsContract} meses de la fecha actual`, "error");
                return;
            }
        }

        if(!emailRegex.test(intern.email)){
            notify("El correo electrónico es inválido, corrígelo e intenta nuevamente", "error");
            return;
        };
        const urlStartProcess = `${window.location.origin}/internship/${intern.surveyKey}`;
        console.log(context.user)
        let internCompany = await getCompanyById(context.user.company._id)
        const emailParams = {
            to: intern.email,
            subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
            text: `¡Bienvenido ${intern.firstname} ${intern.father_lastname} ${intern.mother_lastname}! <br/> 

            Estás a punto de iniciar el primer paso hacía tu desarrollo profesional.
            <br/>

            <span style="font-weight: bold;">¡Ingresa ahora y contesta el formulario de prácticas!</span>
            <br/>

            Link Web: <a href="${urlStartProcess}">${urlStartProcess}</a>
            <br/>
            Consulta el archivo adjunto que te será de ayuda para conocer los beneficios de <span style="color: #0080fd;">Practicantes CAINTRA</span>
            <br/>

            <a href="https://caintrabucket-prod.s3.amazonaws.com/mailImages/ToolKit.pdf">ToolKit</a>
            <br/>
            En caso de presentar problemas para acceder, por favor notifícalo al correo de atención:
            <br/>
            <span style="color: #0080fd;"><a href="mailto:soporte.practicantes@caintra.org.mx">soporte.practicantes@caintra.org.mx</a></span>
            <br>
            Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.
            `,
         
        };
        
        await Methods.createIntern(intern, emailParams, setCurrentTab, sendEmail);

    };

    const getCompanyById = async (id) => {
        const [company] = await Methods.getCompanies([id]);   
        return company
    };

    const generateNewEndDate = (event) => {
        event.preventDefault();
        const months = event.target.value;
        let date = new Date(contractStartDate);
        setCanEditEndDate( months != 0 );
        date.setMonth(date.getMonth() + parseInt(months));
        date.setDate(date.getDate() - 1);
        
        setContractEndDate(date);
    };
    const getNewDate = (date) => {
        let newDate = new Date(date);
        let contractEndDateAux = new Date(contractEndDate);
        contractEndDateAux.setHours(0,0,0,0);
        setContractStartDate(date);

        if(parseInt(duration) != 0){
            newDate.setMonth(newDate.getMonth() + parseInt(duration));
            newDate.setDate(newDate.getDate() - 1);

            if(date.getDate() > 28 && newDate.getMonth() == 2){
                let dateAux = new Date(newDate.getFullYear(), 2, 0);
                newDate = dateAux;
            }   
            newDate.setHours(0,0,0,0);
            setContractEndDate(newDate);
        }
    };

    const getNewStartDate = (date) => {
        if(!date) return null;
        let newDate = new Date(date);
        let evaluatedDate = moment(newDate);
        let currentDateAux = new Date();
        const dif = evaluatedDate.diff(moment().startOf('day'), 'days');
        if(dif <= 0){
            notify("La fecha finalización del convenio debe ser mayor a la fecha actual", "error");
            currentDateAux.setDate(currentDateAux.getDate() + 1);
            setContractEndDate(currentDateAux);
            return;
        }
        setContractEndDate(date);
        if(duration != 0){
            newDate.setMonth(newDate.getMonth() - parseInt(duration));
            newDate.setHours(0,0,0,0);
            setContractStartDate(newDate);
        }
    };


    return (
        <motion.div initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }} className="new-intern">
            <div className="title-container">
                <BiArrowBack onClick={() => setCurrentTab("internList")}/>
                <div> 
                    <h4>Alta de practicantes</h4>
                    <h3 className="company">{currentCompany}</h3>
                </div>
            </div>
            <Form onSubmit={handleNewIntern}>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="name">
                            <Form.Control className="input" type="text" placeholder="Nombre (s)" required onChange={e => e.target.value = e.target.value.toUpperCase()}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="father_lastname">
                            <Form.Control className="input" type="text" placeholder="Apellido paterno" required onChange={e => e.target.value = e.target.value.toUpperCase()}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="mother_lastname">
                            <Form.Control className="input" type="text" placeholder="Apellidos materno" required onChange={e => e.target.value = e.target.value.toUpperCase()}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="email">
                            <Form.Control className="input" type="email" placeholder="Correo" onChange={e => e.target.value = e.target.value.toLowerCase()} required/>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="contractduration">
                            <Form.Control className="input" as="select" onChange={(e)=> {setDuration(e.target.value); generateNewEndDate(e);}}>
                                <option hidden value>Duración del convenio</option>
                                <option value="0">Por determinar</option>
                                {maxMonths.map(months => {
                                    return <option value={months}> {months} {months != '1' ? 'meses' : 'mes'} </option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <Form.Group controlId="contractstartdate">
                            <Form.Label>Inicio del convenio</Form.Label>
                            <DateTimePicker name="contractstartdate" format={'DD/MM/YYYY'} editFormat={'DD/MM/YYYY'} value={contractStartDate} onChange={(e)=>{getNewDate(e);}} time={false} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="contractenddate">
                            <Form.Label>Finalización del convenio</Form.Label>
                            <DateTimePicker disabled={canEditEndDate} name="contractenddate" format={'DD/MM/YYYY'} editFormat={'DD/MM/YYYY'} value={contractEndDate} onCurrentDateChange={(date) => {getNewStartDate(date)}} time={false} />
                        </Form.Group>
                    </Col>
                </Row>

                <div className='checkClass'>
                    <Form.Check 
                        onChange={() => setSendEmail(!sendEmail)}
                        type="checkbox"
                        />
                        Enviar formulario a practicante
                </div>

                {isCaintraUser && 
                    <div className='checkClass'>
                    <Form.Check 
                        onChange={() => setInternRequireInsurance(!internRequireInsurance)}
                        type="checkbox"
                        checked={internRequireInsurance}
                        />
                        El practicante requiere seguro
                    </div> 
                }

                <div className="btn-container">
                    <Button className="btn-cancel mt-4" variant="primary" onClick={() => setCurrentTab("internList")}>
                        Cancelar
                    </Button>
                    <Button className="btn-confirm mt-4" variant="primary" type="submit">
                        Enviar solicitud
                    </Button> 
                </div>
            </Form>



         </motion.div>
    );
};
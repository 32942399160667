import React, { useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';
import { notify } from '../../../utils/notify';
import Methods from "../../../utils/https";
const uuid = require("uuid");


export default function UploadExcel({showUpdateExcel, setShowUpdateExcel, dates, periodAux}){

    const [data, setData] = useState([]);
    const [period, setPeriod] = useState();
    let dateAux = [];

    useEffect(()=>{
    }, []);
    let dataAux = ['start_date', 'end_date', 'payment_date', 'limit_authorization', 'isConsolidationPeriod', 'isFirstConsolidationPeriod' ,'isSecondConsolidationPeriod', 'forceApprovePeriod'];


    const onChange = (e) => {
        setData([]);
        readXlsxFile(e.target.files[0]).then((rows) => {
            if(rows[0][1] !== periodAux.selectedPeriod){
                notify(`La plantilla no corresponde al periodo actual`, "error");
                e.target.value = null;
                return;
            }
            setData(rows.slice(4)); 
            setPeriod(rows[0][1]);
        });
    };

    const getDataCreate = async () => {
        let dateFormats = {
            'start_date': true,
            'end_date': true,
            'payment_date': true,
            'limit_authorization': true
        }
        let boolFormats = {
            "isConsolidationPeriod":  true,
            "isFirstConsolidationPeriod":  true,
            "isSecondConsolidationPeriod":  true,
            "forceApprovePeriod": true
        }
 
        let batch = uuid.v1();
        for (let record in data) { 
            if(moment.utc(data[record][0]).format("DD/MM/YYYY") != "Invalid date"){
                createCalendar(data[record], dateFormats, dataAux, boolFormats, batch);
            }else{
                break;
            }
        }
        checkDates(); 
    }

    const createCalendar = async(record, dateFormats, dataAux, boolFormats, batch) => {
        try{
            let calendar = {
                batch_id: batch,
                start_date: "",
                end_date: "",
                payment_date: "",
                limit_authorization: "",
                payment_period: period,
            }
            for(let recordIndex in record){
                if(dateFormats[dataAux[recordIndex]]){
                    calendar[dataAux[recordIndex]] = moment.utc(record[recordIndex]).format("DD/MM/YYYY");
                }else if(boolFormats[dataAux[recordIndex]] && record[recordIndex] == "Sí"){ 
                    calendar[dataAux[recordIndex]] = true;
                }
            }
            dateAux.push(calendar);
        }catch(error){
            notify('Error en el archívo', 'error');
        }        
    }

    const checkDates = async () => {
        dateAux.sort(function(a,b){
            let momentA = a.start_date.includes("/") ? moment(a.start_date,"DD/MM/YYYY").startOf("day"): moment(a.start_date.slice(0,10)).startOf("day");
            let momentB = b.start_date.includes("/") ? moment(b.start_date,"DD/MM/YYYY").startOf("day"): moment(b.start_date.slice(0,10)).startOf("day");

            return momentA.isAfter(momentB) ? -1 : 1;
        });
        let aux = [...dates].filter(date => date.start_date == dateAux[0].start_date)
        if (aux.length == 0){
            if(moment(dates[0].end_date,"DD/MM/YYYY").startOf("day").isAfter(moment(dateAux[0].start_date,"DD/MM/YYYY").startOf("day") )){
                notify('Error, fechas no concuerdan', 'error');
                setShowUpdateExcel(!showUpdateExcel); 
            }
            await Methods.savePeriods(dateAux);
            notify('El calendario se cargo correctamente', 'success');
            setShowUpdateExcel(!showUpdateExcel);
        }else{
            let aux = [...dates].filter(date => date.start_date == dateAux[0].start_date)
            if (aux.length == 0){
                if(moment(dates[0].end_date,"DD/MM/YYYY").startOf("day").isAfter(moment(dateAux[0].start_date,"DD/MM/YYYY").startOf("day") )){
                    notify('Error, fechas no concuerdan', 'error');
                    setShowUpdateExcel(!showUpdateExcel); 
                }
                try {
                    await Methods.savePeriods(dateAux);
                    notify('El calendario se cargo correctamente', 'success');
                    setShowUpdateExcel(!showUpdateExcel);
                } catch (error) {
                    notify('Error en carga de calendario', 'error');
                    setShowUpdateExcel(!showUpdateExcel);
                }
                
            }else{
                let batchAux = [...dates].filter(date => date.batch_id == aux[0].batch_id)
                let deleteAux = [];
                let response = await Methods.getInvoiceRecordsByPeriod({period: periodAux.selectedPeriod , date: batchAux[0].start_date.slice(-4)})
                if(response.length != 0){
                    notify("No se puede borrar el calendario por que hay una prefactura autorizada", "error");
                    setShowUpdateExcel(!showUpdateExcel);
                    return;
                }
                for(let record in dateAux){
                    if(moment(batchAux[record].start_date,"DD/MM/YYYY").startOf("day").isSame(moment(dateAux[record].start_date,"DD/MM/YYYY").startOf("day")) && 
                    moment(batchAux[record].end_date,"DD/MM/YYYY").startOf("day").isSame(moment(dateAux[record].end_date,"DD/MM/YYYY").startOf("day"))){
                        deleteAux.push(batchAux[record]._id)
                    }else{
                        notify('Error, fechas no concuerdan en ambos calendarios', 'error');
                        setShowUpdateExcel(!showUpdateExcel);
                        return;
                    }
                }
                try {
                    await Methods.deletePeriodYear(deleteAux);
                    await Methods.savePeriods(dateAux);
                    notify('El calendario se actualizo correctamente', 'success');
                    setShowUpdateExcel(!showUpdateExcel);
                } catch (error) {
                    notify('Error en carga de calendario', 'error');
                    setShowUpdateExcel(!showUpdateExcel);
                }
               
            }
        }
        
        
    }

    return(
        <Modal className="prefacture-modals" onHide={() => {}} show={showUpdateExcel} centered>
            <Modal.Body>
                <Modal.Header>
                    <p>Subir Excel</p>
                </Modal.Header>
                <input type="file" accept=".xlsx, .xls, .csv" onChange={onChange} />
                <Button className="float-right ml-2" variant="primary" onClick={async () => {getDataCreate()}} disabled={!data.length}>
                    Confirmar
                </Button> 
                <Button className="float-right" variant="danger" onClick={() => {setShowUpdateExcel(!showUpdateExcel); setData([]);}}>
                    Cancelar
                </Button>
            </Modal.Body>
        </Modal>
    );
};
import React from 'react';
import {Modal, Image, Form, Button, Col, Row} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import Methods from '../../../utils/https';
const axios = require("axios");
const moment = require("moment");

export default function CompanyCredit ({setShowCompanyCredit, showCompanyCredit, creditCompany, setCreditCompany, setData, setSetData, period, company}) {
    let typeValue;
    const addValue = async (e) => {
        e.preventDefault();
        let startDate;
        let endDate;
        let prefacturesAdjustments = [];
        let alreadyExist = false;
        if(period){
            startDate = moment(period[0]).format("DD/MM/YYYY");
            endDate = moment(period[1]).format("DD/MM/YYYY");
        }
        let value = parseFloat(e.target.elements.debit.value); 
        
        if(!company.prefacturesAdjustments?.length){
            prefacturesAdjustments.push({
                startDate: startDate,
                endDate: endDate,
                creditCompany: value,
                debitCompany: 0
            });
        }else{
            for(let adjutsment of company.prefacturesAdjustments){
                if(adjutsment.startDate == startDate && adjutsment.endDate == endDate){
                    adjutsment.creditCompany = value;
                    alreadyExist = true
                }
            }
            prefacturesAdjustments = company.prefacturesAdjustments
            if(!alreadyExist){
                prefacturesAdjustments.push({
                    startDate: startDate,
                    endDate: endDate,
                    creditCompany: value,
                    debitCompany: 0
                });
            }
        }

        const attributesToBeUpdated = {
            _id: company._id,
            attributes: [
                {updateKey: "prefacturesAdjustments", updateValue: prefacturesAdjustments},
            ]
        };

        await Methods.updateCompany(attributesToBeUpdated);
        setCreditCompany(parseFloat(value));
        setShowCompanyCredit(!showCompanyCredit);
        setSetData(true);
    };

    const blockInvalidChar = (e) => {
        ['e', 'E', '+', '-', '`'].includes(e.key) && e.preventDefault();
    };


    return(
        <Modal className="new-cc-modal" show={showCompanyCredit} onHide={() => setShowCompanyCredit(!showCompanyCredit)} centered>
            <Modal.Header>
                <p>Abono de Empresa</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={addValue}>
                    <Form.Group controlId="debit">
                        <Form.Label>Abono de Empresa</Form.Label>
                        <Form.Control type="number" step=".01" min="0" onKeyDown={blockInvalidChar}   required/>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Button className="float-left" variant="danger" type="button" onClick={() => setShowCompanyCredit(!showCompanyCredit)}>
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="primary" onClick={() => typeValue = "incremente"} type="submit">
                                Guardar
                            </Button>
                        </Col>
                    </Row>


 
                </Form>
            </Modal.Body>
        </Modal>
    )
};
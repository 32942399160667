import React, {useState, useContext} from 'react';
import {Row, Button, Form, Image, InputGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {notify} from '../../../utils/notify';
import {encrypt} from '../../../utils/crypto';
import { ImWhatsapp } from 'react-icons/im';
import whatsapp from '../../../assets/whatsapp.png';
import Methods from '../../../utils/https';
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
const axios = require("axios");

export default function RegisterUser ({setValidateEmailDisplay, setEmailToValidate}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [showCompanies, setShowCompanies] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [seePass, setSeePass] = useState(false);
    const [seeConfirmPass, setConfirmPass] = useState(false);


    const signup = async (userData) => {

        try{
            await axios({
                url: `${URL}/users`,
                method: "POST",
                data: encrypt(userData)
            });

            notify("Usuario registrado exitosamente", "success");
            clearForm();
        } catch (e) {
            switch (e._name) {
                case "UsernameExistsException":
                        notify("Este correo ya ha sido registrado", "error");
                    break;
                default:
                    e.response && notify(e.response.data.message, "error");
                    break;
            };
        };
    };

    const isRfcTaken = async (rfc) => {
        let response = await Methods.isRfcTaken(rfc);
        if(response){
            setShowCompanies(response.isTaken)
            if(response.isTaken){
                setCompanies(response.companies)
            } else{
                setCompanies([]);
            }
        }
    }

    const validateUserData = (e) => {
        e.preventDefault();

        let _errors = 0;
        const passwordRegex = new RegExp (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\w<>{}()\[\]'"+\-=*/\\,.;:!@#$%^&¡!¿?~`|]{8,24}$/);
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
        let user = {
            fullName: e.target.elements.fullName.value,
            email: e.target.elements.email.value,
            password: e.target.elements.password.value,
            confirmpassword: e.target.elements.confirmpassword.value,
            rfc: e.target.elements.rfc.value,
            role: "Sin Asignar",
            companies: []
        };

        if(showCompanies){
            companies.map( company => {
                user.companies.push(company._id);
            })
            user = {...user, principalCompany: e.target.elements.companyId.value}
            if(user.principalCompany == "División Comercial"){
                _errors++;
                notify("Seleccione una opción para división comercial", "error");
            }
        }

        
        if(!passwordRegex.test(user.password)){
            _errors++;
            notify("Tu contraseña debe tener al menos 8 caracteres, una letra en mayúscula y un número", "error");
        };

        if(user.password !== user.confirmpassword){
            _errors++;
            notify("Las contraseñas no coinciden", "error");
        }

        if(!emailRegex.test(user.email)){
            _errors++;
            notify("El correo no es válido", "error");
        };

        _errors == 0 && signup(user);

    };

    const clearForm = () => { 
        document.getElementById("register-form").reset();
      }

    const getSeePass = () => 
      !seePass ? 
          <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>
      : 
          <IoEyeOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>;

    
    const getConfirmPass = () =>
    !seeConfirmPass ?
        <IoEyeOffOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>;
        
    return(
        <>
            <Row className="row-selection">
                <Link to="/login">Log in</Link>
                <a style={{color: "#d7d7d7"}}>|</a>
                <a href="#"><strong>Sign up</strong></a>
            </Row>
            <Form id="register-form" onSubmit={validateUserData}>
                <Form.Group controlId="fullName">
                    <Form.Control type="text" placeholder="Nombre Completo" autoComplete="off" />
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Control type="email" placeholder="Correo" />
                    <Form.Text style={{color: '#121213'}}>
                        Recuerda que tus datos están protegidos
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="rfc">
                    <Form.Control type="text" placeholder="RFC de la compañía" onChange={event => isRfcTaken( event.target.value)} />
                </Form.Group>
                {showCompanies && companies && <Form.Group controlId="companyId">
                    <Form.Control as="select" placeholder="RFC de la compañía">
                    <option value={null} hidden>División Comercial</option>
                    {companies.map(company => {
                               if(company.social_reason != undefined && company.social_reason.length){
                                return <option value={company._id}>{company.social_reason}</option>
                               }
                            })}
                    </Form.Control>
                </Form.Group>}
                <Form.Group controlId="password">
                    <InputGroup>
                        <Form.Control type={!seePass ? "password" : "text"}  placeholder="Contraseña" style={{backgroundColor: '#f1f1f1 !important'}}/>
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {getSeePass()}
                                </InputGroup.Text>
                            </InputGroup.Append>
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="confirmpassword">
                    <InputGroup>
                        <Form.Control type={!seeConfirmPass ? "password" : "text"}  placeholder="Confirma tu contraseña" style={{backgroundColor: '#f1f1f1 !important'}}/>
                        <InputGroup.Append>
                                <InputGroup.Text>
                                    {getConfirmPass()}
                                </InputGroup.Text>
                            </InputGroup.Append>
                    </InputGroup>
                </Form.Group>

                <Button className="btn-register" variant="primary" type="submit">
                    Registrarse
                </Button>
                <Link to="/register/company">
                    <div className="register-company-container">
                        <div className="register-here">
                            Registra tu empresa aquí
                        </div>
                    </div>
                </Link>
                
                <div className="need-help-container">
                    <div className="need-help">
                        ¿Necesitas ayuda?<Image src={whatsapp} className='whatsapp-icon'/>
                    </div>
                </div>

            </Form>
        </>
    );
}
import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Form, Button, Spinner, InputGroup} from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { verify } from 'crypto';
import { UserContext } from '../../../context/UserContext';

const axios = require("axios");

export default function CreateUser({currentCompanyId, updateList, setUpdateList, setTab, availableDepartments, setAvailableDepartments, adminCompanies}){
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [isLeader, setIsLeader] = useState(false);
    const [departments, setDepartments] = useState(undefined);
    const [leaderValue, setLeaderValue] = useState("No");
    const [currentDepartmentId, setCurrentDepartmentId] = useState("");
    const [currentCompany, setCurrentCompany] = useState(undefined);
    const [rfcCompanies, setRfcCompanies] = useState([]);
    const [seePass, setSeePass] = useState(false);
    const [seeConfirmPass, setConfirmPass] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);

    const context = useContext(UserContext);

    
    useEffect(async () => {
        await getDepartments();
        await getCurrentCompany();
    }, [updateList]);

    const getCurrentCompany = async () => {
        let company = await Methods.getCompanies([currentCompanyId]);
        if(company.length){
            setCurrentCompany(company[0]);
            let response = await Methods.isRfcTaken(company[0].rfc);
            if(response.isTaken && response.companies){
                let arrAux = [];
                response.companies.map(company => {
                    arrAux.push(company._id)
                })
                setRfcCompanies(arrAux);
            }
        }
    }


    const signup = async (userData) => {
        await Methods.adminRegisterUser(userData);
        setUpdateList(!updateList);
        setRfcCompanies([]);
        setTab("Users");
    };

    const validateUserData = (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        let _errors = 0;
        const passwordRegex = new RegExp (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\w<>{}()\[\]'"+\-=*/\\,.;:!@#$%^&¡!¿?~`|]{8,24}$/);
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
        let isCaintraCompany = false;
        if(currentCompany.isCaintraCompany){
            isCaintraCompany = true;
        }
        let user = {
            fullName: e.target.elements.fullName.value,
            email: e.target.elements.email.value,
            password: e.target.elements.password.value,
            confirmpassword: e.target.elements.confirmpassword.value,
            companies: [currentCompanyId],
            role: "Sin Asignar",
            currentCompanyId: currentCompanyId,
            isCaintraUser: isCaintraCompany,
            principalCompany: currentCompanyId,
            sendEmail: sendEmail
        };

        if(rfcCompanies.length){
            rfcCompanies.map(id => {
                if(!user.companies.includes(id)) user.companies.push(id)
            })
        }

        if(isLeader && currentDepartmentId){
            user.departmentId = currentDepartmentId
        } else if(isLeader && !currentDepartmentId){
            _errors++;
            setIsLoading(false);
            notify(`Debes seleccionar un ${context.getDepartmentField()}`, "error");
        }
        
        if(!passwordRegex.test(user.password)){
            _errors++;
            setIsLoading(false);
            notify("La contraseña debe tener al menos 8 caracteres, una letra en mayúscula y un número", "error");
        };

        if(user.password !== user.confirmpassword){
            _errors++;
            setIsLoading(false);
            notify("Las contraseñas no coinciden", "error");
        }
        if(!emailRegex.test(user.email)){
            _errors++;
            setIsLoading(false);
            notify("El correo no es válido", "error");
        };

        _errors == 0 && signup(user);

    };

    const getDepartments = async () => {
        try {
            let _departments = await axios({
                url: `${URL}/departments`,
                method: "PUT",
                data: {id_company: currentCompanyId, departmentField: context.getDepartmentField()} 
            });
            await verifyDepartments(_departments.data.data);
        } catch (error) {
            setDepartments(undefined);
        };
    };

    const verifyDepartments = async (department)=>{
        let departmentsNotAssigned = [];
        await department.forEach(element => {
            if(!element.leaderId){
                departmentsNotAssigned.push(element);
            }
        });
        setDepartments(undefined);
        if(departmentsNotAssigned.length != 0) await setDepartments(departmentsNotAssigned);
    }

    const verifyLeader = async (value) => {
        setIsLeader(false);
        if(value == "Si"){
            if(!departments){
                setIsLeader(false);
                setLeaderValue("No");
                notify(`Esta compañía no cuenta con ${context.getDepartmentField(false, true)} para asignar`, "error");
            } else {
                setIsLeader(true);
                setSendEmail(false); 
                setLeaderValue("Si");
            }
        } else {
            setIsLeader(false);
            setSendEmail(true);
            setLeaderValue("No");
        }
    }

    const validateEntryKey = (e) => {
        e.charCode == 13 && e.preventDefault()
    }

    const getSeePass = () => 
    !seePass ? 
        <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>;

  
  const getConfirmPass = () =>
  !seeConfirmPass ?
      <IoEyeOffOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>
  : 
      <IoEyeOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>;

    return(
        <motion.div className="users" initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>

            <Row className="title">
                <a href="#" onClick={() => {setTab("Users"); setUpdateList(!updateList)}}><FontAwesomeIcon icon={faArrowLeft} /></a> 

                <h4>Registrar Usuario</h4>
            </Row>
            <Form onSubmit={validateUserData} className="create-user" autoComplete={"off"}>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="fullName">
                            <Form.Control onKeyPress={validateEntryKey} type="text" placeholder="Introduce tu nombre" />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="email">
                            <Form.Control onKeyPress={validateEntryKey} type="email" placeholder="Correo" onChange={e => e.target.value = e.target.value.toLowerCase()}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="password">
                            <InputGroup>
                                <Form.Control onKeyPress={validateEntryKey} type={!seePass ? "password" : "text"}  placeholder="Contraseña" style={{backgroundColor: '#f1f1f1 !important'}}/>
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        {getSeePass()}
                                    </InputGroup.Text>
                            </InputGroup.Append>
                    </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="confirmpassword">
                            <InputGroup>
                                <Form.Control onKeyPress={validateEntryKey} type={!seeConfirmPass ? "password" : "text"} placeholder="Confirma tu contraseña" />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {getConfirmPass()}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="isLeader" className="company-form select">
                            <Form.Label>
                                {context.getGeneralistaField(true)}
                            </Form.Label>
                            <Form.Control as="select" value={leaderValue} onChange={(e) => {e.preventDefault();   verifyLeader(e.target.value);}}>
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        {isLeader && 
                            <Form.Group controlId="isLeader" className="company-form select">
                                <Form.Label>
                                    {context.getDepartmentField(true)}
                                </Form.Label>
                                <Form.Control as="select" onChange={(e) => {setCurrentDepartmentId(e.target.value);}}>
                                    <option disabled selected>Selecciona un {context.getDepartmentField()}</option>
                                    {departments && departments.map( element => {
                                        return(
                                            <option value={element._id}>{element.title}</option>
                                        );
                                    })}                               
                                </Form.Control>
                            </Form.Group>
                        }
                    </Col>
                </Row>

                

                <div className='checkClass'>
                    <Form.Check 
                        defaultChecked={sendEmail}
                        onChange={() => setSendEmail(!sendEmail)}
                        type="checkbox"
                        checked={sendEmail}
                        />
                        Enviar notifiación a usuario
                </div>

                <Row>
                    <Col>
                        <Button className="btn-register float-right" variant="primary" type="submit">
                            {isLoading ? <Spinner animation="border" /> : "Registrar"}
                        </Button>
                    </Col>
                </Row>
                
                
            </Form>
        </motion.div>
    );
};
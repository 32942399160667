import React, {useEffect, useState, useRef} from 'react'
import Methods from '../../../utils/https';
import CoinInfoIcon from "../../../assets/IconsCaintra/CoinInfoIcon.svg";
import topUniversitiesIcon from "../../../assets/IconsCaintra/topUniversities.svg";
import {DownloadDashboard} from '../components/DownloadDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft, faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import moment from 'moment';
export const AverageStatsDashboards = ({
    selectedCompanies,
    periodSelected,
    context, 
    periodSelectedToCompare,
    getPeriodString,
    monthsToDownload,
    refsAux,
    setInvoiceAverageExcelValues,
    setTopUniversitiesExcelValues
}) => {
    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
    );

    const [invoiceAverage, setInvoiceAverage] = useState(0);
    const exportRef = useRef();
    const [excelValues, setExcelValues] = useState([{}])
    const [excelConfigurations, setExcelConfigurations] = useState([
        {key: 'month', label: 'Mes'},
        {key: 'invoiceAverage', label: 'Facturado en el mes'}
    ]);
    const [topUniversities, setTopUniversities] = useState([]);
    const [nextPageSelected, setNextPageSelected] = useState(false);
    const topUniversitiesRef = useRef();
    const topUniversitiesConfigurations = [
        {key: 'top', label: 'Lugar'},
        {key: 'collegeName', label: 'Nombre de universidad'}, 
        {key: 'total', label: 'Total de practicantes'}
    ]
    const [invoiceValues, setInvoiceValue] = useState({
        currentValue: 0,
        valueToCompare: 0
    })
    const dateRefInvoice = useRef();
    const dateRefUniversities = useRef();
    const [topUniversitiesExcel, setTopUniversitiesExcel] = useState([]);
    const [isLoadingInvoiceExcel, setIsLoadingInoviceExcel] = useState(false);
    const [isLoadingTopUniversities, setIsLoadingTopUniversities] = useState(false);
    const universitesDownloadRef = useRef(null);
    const averageInvoiceDownloadRef = useRef(null);

    useEffect(() => {
        getAllDashboarsData();
    }, [periodSelected, selectedCompanies, periodSelectedToCompare]);

    useEffect(() => {
        setInoviceExcelData();
    }, [periodSelected, selectedCompanies, monthsToDownload]);

    useEffect(() => {
        setTopUniversitiesExcelData();
    }, [periodSelected, selectedCompanies, monthsToDownload]);

    useEffect(() => {
        refsAux.componentRef.current[1] = exportRef.current;
        refsAux.dateRef.current[1] = dateRefInvoice.current;
        refsAux.downloadRef.current[1] = averageInvoiceDownloadRef.current;
    }, [exportRef.current, dateRefInvoice.current, averageInvoiceDownloadRef.current]);

    useEffect(() => {
        refsAux.componentRef.current[2] = topUniversitiesRef.current;
        refsAux.dateRef.current[2] = dateRefUniversities.current;
        refsAux.downloadRef.current[2] = universitesDownloadRef.current;
    }, [topUniversitiesRef.current, dateRefUniversities.current, universitesDownloadRef.current]);

    const getAllDashboarsData = async() => {
        await Promise.all([
            getAndSetAverageInoviceTotal(),
            getTopUniversitiesData()
        ])
    }

    const getAndSetAverageInoviceTotal = async () =>{
        
        let [currentTotal] = await getInvoiceAmountData(periodSelected);
        let currentAverageInovice = currentTotal;
        if(periodSelectedToCompare){
            let [totalToCompare] = await getInvoiceAmountData(periodSelectedToCompare);
            currentAverageInovice = currentTotal - totalToCompare;
            setInvoiceValue({
                currentValue: currentTotal,
                valueToCompare: totalToCompare
            });
        }
        setInvoiceAverage(currentAverageInovice);
    }

    const getInvoiceAmountData = async(periodSelectedAux) =>{
        let promises = [];
        let totalPrefactures = 0;
        let totalByPrefacture = 0;

        for(let selectedCompany of selectedCompanies){
            promises.push(Methods.getInoviceRecordsByMonths(selectedCompany, parseInt(periodSelectedAux)));
        }
        
        let results = await Promise.all(promises);

        for(let companyData of results){
            for(let dataByMonth of companyData){
                if(dataByMonth.totalPrefactures){
                    totalPrefactures = totalPrefactures + dataByMonth.totalPrefactures;
                    totalByPrefacture = totalByPrefacture + dataByMonth.totalByPrefacture;
                }
            }
        }

        let total = totalByPrefacture/totalPrefactures;
        return [total || 0];
    }

    const setTopUniversitiesExcelData = async() => {
        setIsLoadingTopUniversities(true);

        let promises = [];
        let excelValuesAux = [];
        
        for(let selectedCompany of selectedCompanies){
            promises.push(Methods.getTopUniversitiesByCompanyMonth(selectedCompany, parseInt(periodSelected), monthsToDownload));
        }

        let results = await Promise.all(promises);

        for (let companyData of results) {
            let companyExcelValues = {}
            let universitiesByCompany = [];
            let companyAux = context?.user?.data?.companiesByName?.find(companyAux => companyAux._id === companyData[0].companyId);
            companyExcelValues.name = companyAux?.business_name;
            companyExcelValues.values = [];
            for(let dataByMonth of companyData){
                if (dataByMonth?.topUniversities?.length > 0) { 
                    dataByMonth?.topUniversities.map(universityAux => {

                        let finded = universitiesByCompany.find(
                            (university) => university.searchName === universityAux.searchName
                        );
                        if (finded) {
                            finded.total += universityAux.total;
                        } else {
                            universitiesByCompany.push(universityAux);
                        }

                    });
                }
            }

            universitiesByCompany?.sort((a,b) => (a.collegeName > b.collegeName) ? 1 : ((b.collegeName > a.collegeName) ? -1 : 0));
            
            companyExcelValues.values = universitiesByCompany?.sort((a,b) => {
                if (a.total < b.total) {
                    return 1;
                  }
                  if (a.total > b.total) {
                    return -1;
                  }
                return 0;
            });

            companyExcelValues.values.map((university, index) => {
                university.top = index + 1;
            });

            companyExcelValues.values = companyExcelValues.values.slice(0,10);

            excelValuesAux.push(companyExcelValues);
        }

        setTopUniversitiesExcel(excelValuesAux);
        setTopUniversitiesExcelValues({
            excelValues: excelValuesAux,
            excelConfigurations: topUniversitiesConfigurations
        });
        setIsLoadingTopUniversities(false);
    }

    const setInoviceExcelData = async() => {
        setIsLoadingInoviceExcel(true);
        let promises = [];
        let excelValuesAux = [];
        
        for(let selectedCompany of selectedCompanies){
            promises.push(Methods.getInoviceRecordsByMonths(selectedCompany, parseInt(periodSelected), monthsToDownload));
        }

        let results = await Promise.all(promises);

        for(let companiesData of results){
            let companyExcelValues = {}
            let companyAux = context?.user?.data?.companiesByName?.find(companyAux => companyAux._id === companiesData[0].companyId);
            companyExcelValues.name = companyAux?.business_name;
            companyExcelValues.values = [];
            for(let dataByMonth of companiesData){
                let avergareTotal = parseFloat(dataByMonth?.totalByPrefacture || 0) / (dataByMonth?.totalPrefactures || 0) || 0
                companyExcelValues.values.push({
                    month: getPeriodString(dataByMonth.month),
                    invoiceAverage: getFormatedMoney(avergareTotal)
                });
            }
            excelValuesAux.push(companyExcelValues);
        }   
        setExcelValues(excelValuesAux);
        setInvoiceAverageExcelValues({
            excelValues: excelValuesAux,
            excelConfigurations: excelConfigurations
        });
        setIsLoadingInoviceExcel(false);
    }

    const getTopUniversitiesData = async () => {
        let promises = [];
        let allUniversities = [];
        for (let selectedCompany of selectedCompanies) {
            promises.push(
                Methods.getTopUniversitiesByCompanyMonth(
                    selectedCompany,
                    parseInt(periodSelected)
                )
            );
        }
        let results = await Promise.all(promises);
        for (let companyData of results) {
            for(let dataByMonth of companyData){
                if (dataByMonth?.topUniversities?.length > 0) {
                    for (let universityAux of dataByMonth?.topUniversities) {
                        let finded = allUniversities.find(
                            (university) => university.searchName === universityAux.searchName
                        );
                        if (finded) {
                            finded.total += universityAux.total;
                        } else {
                            allUniversities.push(universityAux);
                        }
                    }
                }
            }
        }

        allUniversities?.sort((a,b) => (a.collegeName > b.collegeName) ? 1 : ((b.collegeName > a.collegeName) ? -1 : 0));

        allUniversities = allUniversities.sort((a,b) => {
            if (a.total < b.total) {
                return 1;
              }
              if (a.total > b.total) {
                return -1;
              }
            return 0;
        });

        setTopUniversities(allUniversities);
    };

    const getFormatedMoney = (value = 0, requireNegatives) => {
       return parseFloat(requireNegatives ? value : Math.abs(value)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    const getPercentByComparasion = (currentValue, valueToCompare) => {
        let result = !valueToCompare ? 100 : 
            ( !currentValue ? 100 : (parseFloat(currentValue/(valueToCompare/100)) - 100))
        return ' (' + ( result > 0 ? '+' : '')+ result.toFixed(2) + '%)';
    }

  return (
    <div className='avergare-info-container'>
        <div className='scholarship-container' ref={exportRef}>
            <div className='info-container'>
                <img src={CoinInfoIcon} className='coin-icon' width="30"/>
                <div className='title-container'>
                    <p>Facturado en el mes</p>
                    <h2>
                        <div>
                            {'MXN '}
                            {periodSelectedToCompare && invoiceAverage != 0 ? 
                                <FontAwesomeIcon 
                                    className={invoiceAverage > 0 ? 'arrow-up' : 'arrow-down'}
                                    icon={invoiceAverage > 0 ? faArrowUp : faArrowDown}
                                />
                                : <></>
                            }
                            {getFormatedMoney(invoiceAverage)}
                            
                            {(periodSelectedToCompare && invoiceAverage != 0) && 
                                getPercentByComparasion(invoiceValues.currentValue, invoiceValues.valueToCompare)
                            }
                        </div>
                    </h2>
                </div>              
            </div>
            <div className='options-icons'>
                <p style={{'display': 'none', 'fontSize': '8px', 'paddingTop':'20px'}} ref={dateRefInvoice}>
                    {getPeriodString(periodSelected)} 
                    {periodSelectedToCompare && ' comparado con ' + getPeriodString(periodSelectedToCompare)}
                </p>
                <DownloadDashboard
                    refDashboard={exportRef} 
                    fileName={'Facturado_en_el_mes'} 
                    excelConfigurations={excelConfigurations}
                    excelValues={excelValues}
                    periodSelected={periodSelected}
                    dateRef={dateRefInvoice}
                    isLoadingExcel={isLoadingInvoiceExcel}
                    currentRef={averageInvoiceDownloadRef}
                    monthsToDownload={monthsToDownload}
                />
            </div>  
        </div>


        <div className='scholarship-container universities-container' ref={topUniversitiesRef}>
            <div className='info-container info-universities-container'>
                <img src={topUniversitiesIcon} className='coin-icon' width="30"/>
                <div className='title-container universities-title-container'>
                    <p className='title-top'>Top universidades</p>
                    <div className='top-uniersities-container'>
                        {   topUniversities.slice( 
                                nextPageSelected ? 5 : 0,
                                nextPageSelected ? 10 : 5
                            )?.map((item, index) => {
                                return (

                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Total: {item.total}</Tooltip>}
                                >
                                    <p className='top-universities'>
                                        {( (nextPageSelected ? 6 : 1) + (index)) + '. ' + item.collegeName} 
                                    </p>
                                </OverlayTrigger>
                                )
                            })
                        }
                    </div>
                </div>              
            </div>
            <div className='options-icons options-university'>
                <p style={{'display': 'none'}} ref={dateRefUniversities}>
                    {moment().startOf('day').subtract((periodSelected-1) , 'month').format('MMMM YYYY')}
                </p>
                <DownloadDashboard
                    refDashboard={topUniversitiesRef} 
                    fileName={'Top_universidades'} 
                    excelConfigurations={topUniversitiesConfigurations}
                    excelValues={topUniversitiesExcel}
                    periodSelected={periodSelected}
                    dateRef={dateRefUniversities}
                    isLoadingExcel={isLoadingTopUniversities}
                    currentRef={universitesDownloadRef}
                    monthsToDownload={monthsToDownload}
                />

                <FontAwesomeIcon 
                    className='pagination-icon'
                    icon={nextPageSelected ? faChevronLeft : faChevronRight}
                    onClick={()=>{
                        setNextPageSelected(!nextPageSelected);
                    }}
                />
            </div>  
        </div>
    </div>
  )
}


import React, { useState, useEffect} from 'react';
import { Button, Form } from "react-bootstrap";
import Methods from "../../../utils/https";


export default function Mails (){
    const [billingA, setBillingA] = useState();
    const [billingB, setBillingB] = useState();
    const [logistic, setLogistic] = useState();
    const [dispersion, setDispersion] = useState();

    useEffect(()=>{
        getMail();
    }, []);

    const getMail = async () => {
        let response = await Methods.getMail();
        for(let m of response){
            if(m.app === "facturacionA" ){
                setBillingA(m.email)
            }else if(m.app === "logistica"){
                setLogistic(m.email)
            }else if(m.app === "facturacionB"){
                setBillingB(m.email)
            }else if(m.app === "dispersion"){
                setDispersion(m.email)
            }
        }
    }

    const setMail = (event) => {
        event.preventDefault();
        Methods.saveMail({app: event.target[0].name, email: event.target[0].value})
        if(event.target[0].name === "facturacionA" ){
            setBillingA(event.target[0].name)
        }else if(event.target[0].name === "logistica"){
            setLogistic(event.target[0].name)
        }else if(event.target[0].name === "facturacionB"){
            setBillingB(event.target[0].name)
        }else if(event.target[0].name === "dispersion"){
            setDispersion(event.target[0].name)
        }
    }

    return(
        <div className="api-container">
            <div>
                <Form onSubmit={setMail}>
                    <div className="api-group">
                        <Form.Group >
                            <Form.Label>Facturación administrativo</Form.Label>
                            <Form.Control name="facturacionA" className="api" type="text" style={{width: "500px"}} placeholder="Correo de Facturación" defaultValue={billingA}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testMail("facturacionA")} >Probar</Button>
                        </div>
                    </div>
                </Form>
            </div>
            <div>
                <Form onSubmit={setMail}>
                    <div className="api-group">
                        <Form.Group>
                            <Form.Label>Facturación cobranza</Form.Label>
                            <Form.Control name="facturacionB" className="api" type="text" style={{width: "500px"}} placeholder="Correo de Facturación" defaultValue={billingB}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testMail("facturacionB")}>Probar</Button>
                        </div>
                    </div>
                </Form>
            </div>
            <div>
                <Form onSubmit={setMail}>
                    <div className="api-group">
                        <Form.Group>
                            <Form.Label>Logística</Form.Label>
                            <Form.Control name="logistica" className="api" type="text" style={{width: "500px"}} placeholder="Correo de Logística" defaultValue={logistic}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testMail("logistica")}>Probar</Button>
                        </div>
                    </div> 
                </Form>
            </div>
            <div>
                <Form onSubmit={setMail}>
                    <div className="api-group">
                        <Form.Group>
                            <Form.Label>Responsable de dispersión</Form.Label>
                            <Form.Control name="dispersion" className="api" type="text" style={{width: "500px"}} placeholder="Correo de Dispersión" defaultValue={dispersion}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testMail("dispersion")}>Probar</Button>
                        </div>
                    </div> 
                </Form>
            </div>
        </div>
    );
}
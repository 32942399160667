import React, { useContext, useState } from 'react';
import { Modal, Image, Form, Button } from 'react-bootstrap';

export default function ChangeGroupNameModal(props) {
    const [error, setError] = React.useState(false);


    function closeHandler() {
        props.hideModal();
        setError(false);
    }

    return <Modal className="new-cc-modal" show={props.displayModal} onHide={closeHandler} centered>
        <Modal.Header>
            <p>Cambiar Nombre</p>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={async event => {
                event.preventDefault();
                try {
                    await props.changeGroupName(props.selectedGroup._id, event.target.elements.name.value);
                } catch (error) {
                    setError(error.message);
                }
            }}>
                <Form.Group controlId="name">
                    <Form.Label>Nombre del grupo</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={props.selectedGroup.name}
                        required
                    />
                    {error && <p className='groups-error-message'>{error}</p>}
                </Form.Group>

                <Button className="float-right ml-2" variant="primary" type="submit">
                    Registrar
                </Button>
                <Button className="float-right" variant="danger" type="button" onClick={closeHandler}>
                    Cancelar
                </Button>
            </Form>
        </Modal.Body>

    </Modal>
};
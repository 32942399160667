import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Methods from '../../utils/https';
const crypto = require('crypto');
const axios = require("axios");

export default function VersionNotes ({setShowVersion, showVersion}){
    const [result, setResult] = useState();
    const [dontShowVersionNotes, setDontShowVersionNotes] = useState(
        localStorage.getItem("dontShowVersionNotes1") == 'true'
    );

    useEffect(()=>{
        getVersionNotes();
    }, [showVersion]);

    const getVersionNotes = async () => {

        let urlPatch = await Methods.getPatchNotes();

        let patchHtml = await axios({
            url: urlPatch + `/patchNotes.json?${Math.floor(Math.random() * 100) + 1}`,
            method: "GET"
        }); 

        const hash = crypto.createHash('md5');
       // console.log(patchHtml?.data?.htmlBody);
        hash.update(patchHtml?.data?.htmlBody);
        const hashMD5 = hash.digest('hex');
        //console.log(hashMD5);
        localStorage.removeItem("serverHash")
        localStorage.setItem("serverHash" , hashMD5);

        setResult(patchHtml?.data?.htmlBody || "<h1>No hay notas de versión</h1>");
      
        if(localStorage.getItem("logout") === false || localStorage.getItem("logout") === 'false'){
            if(localStorage.getItem("localHash") == null || localStorage.getItem("localHash") !== localStorage.getItem("serverHash")){
                setResult(patchHtml?.data?.htmlBody);
                localStorage.setItem("localHash", localStorage.getItem("serverHash"));
            }
            else{
                setResult(false);
            }
        }
        else{
            setResult(false);
        }
        
    }

    const handleClose =  () => {
        localStorage.setItem("dontShowVersionNotes1", JSON.stringify(dontShowVersionNotes));
        setShowVersion(!showVersion);
    }

    return(
        <>
            {result && 
                <Modal 
                    className="prefacture-modals"
                    dialogClassName='modal-width' 
                    contentClassName='modal-height-patch modal-patch-notes-container' 
                    show={showVersion}
                    centered
                >
                    <div className='version-notes' dangerouslySetInnerHTML={{__html: result}}></div>
                    <div className='buttons-container'>
                        <div className='checkbox-container'>
                            <input
                                type="checkbox"
                                className='checkbox-class'
                                checked={dontShowVersionNotes}
                                onChangeCapture={() => setDontShowVersionNotes(!dontShowVersionNotes)}
                            />
                            <p>No volver a mostrar</p>
                        </div>
                        <Button 
                            className="default-button date-btn"
                            onClick={()=> handleClose()}
                        >
                            Cerrar
                        </Button>
                    </div>
                </Modal>
            }
        </>

    );
};
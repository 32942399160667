import React from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function NewContractTab ({setShowCreateNewContract, showCreateNewContract, setCreateNewContract, setIsNewContract}) {
    const createContract = async () => {
        setCreateNewContract(true);
        setShowCreateNewContract(false);
        setIsNewContract(true);
    };

    return(
        <Modal className="new-cc-modal" show={showCreateNewContract} onHide={() => setShowCreateNewContract(!showCreateNewContract)} centered>
            <Modal.Header>
                <p>Advertencia</p>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group controlId="title">
                        <Form.Label>Este cambio generará un nuevo convenio e invalidará el actual</Form.Label>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type='submit' onClick={() => createContract()} form='updateInternForm' >
                        Continuar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setShowCreateNewContract(!showCreateNewContract)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};

import React, {useContext, useState} from 'react';
import {UserContext} from '../../context/UserContext';
import {Row, Col, Form, OverlayTrigger, Tooltip, Dropdown, InputGroup, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding, faPlusCircle, faSyncAlt, faSlidersH, faTable, faAddressCard, faSearch} from '@fortawesome/free-solid-svg-icons';
import NewIntern from './components/NewIntern.jsx';
import UnsuscribeIntern from './components/UnsuscribeIntern.jsx';
import InternsList from './components/InternsList.jsx';
import VerifyInternDocs from './components/VerifyInternDocs.jsx';
import DeleteInternModal from './components/DeleteInternModal.jsx';
import Training from './components/Training.jsx';
import LetterIn from './components/LetterIn.jsx';
import LetterOut from './components/LetterOut.jsx';
import InternMedicalInsurance from './components/InternMedicalInsurance.jsx';
import UpdateRenewContract from './components/UpdateRenewContract.jsx';
import { motion } from 'framer-motion';
import './Interns.css';
import { notify } from '../../utils/notify';
import { BsUpload } from 'react-icons/bs';
import { BsDownload} from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import {saveAs} from 'file-saver';
import ExcelCSV from "./Excel/internsReport";
import * as Excel from "exceljs";
import Methods from '../../utils/https';
import moment from 'moment';
import videoPlay from '../../../src/assets/IconsCaintra/videoPlay.svg';
import {VideoPlayer} from './components/VideoPlayer'
const axios = require("axios");
const $ENV = "localEnv";

export default function Interns () {
    const context = useContext(UserContext);
    const isCaintraUser = context.user.data.isCaintraUser;
    const [updateList, setUpdateList] = useState(false);
    const [showUnsuscribeInternModal, setShowUnsuscribeInternModal] = useState(false);
    const [showDeleteInternModal, setShowDeleteInternModal] = useState(false);
    const [showTrainingModal, setShowTrainingModal] = useState(false);
    const [showLetterInModal, setShowLetterInModal] = useState(false);
    const [showLetterOutModal, setShowLetterOutModal] = useState(false);
    const [isGlobal, setIsGlobal] = useState(false);
    const [currentIntern, setCurrentIntern] = useState('');
    const [currentInternId, setCurrentInternId] = useState(undefined);
    const [currentInternName, setCurrentInternName] = useState(undefined);
    const [currentDepartmentId, setCurrentDepartmentId] = useState(undefined);
    const [curp, setCurp] = useState('')
    const [firstName, setFirstName] = useState('')
    const [fatherLastName, setFatherLastName] = useState('')
    const [motherLastName, setMotherLastName] = useState('')
    const [listType, setListType] = useState("table");
    const [interns, setInterns] = useState([]);
    const [rotateSync, setRotateSync] = useState(false);
    const rotate = rotateSync ? "rotate" : "";
    const [currentTab, setCurrentTab] = useState("internList");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQuery2, setSearchQuery2] = useState("");
    const updatedFromPrefacture = false;
    const canEdit = context.can("update", "Interns");
    const canCreate = context.can("create", "Interns");
    const [statusTable, setStatusTable] = useState("Activo");
    const [signatureStatus, setSignatureStatus] = useState("Todo");
    const currentCompany = context.user ? context.user.company.business_name : "";
    const [contractToUpdateRenew, setContractToUpdateRenew] = useState({});
    const [contractToUpdateZipCode, setContractToUpdateZipCode] = useState('');
    const [loadingExcel, setLoadingExcel] = useState(false)
    const [showVideoPlayer, setShowVideoPlayer] = useState(false);
    const [videoLink, setVideoLink] = useState(false);
    const temas = [
        { "id": "1", "tema": "Desarrollo personal", "titulo": "Desarrollo personal", "subtitulo": "Desarrollo personal", "autor": "Grow", "caption": "img/resources/desarrollo.jpeg" },
        { "id": "2", "tema": "Tendencias en empresas", "titulo": "Tendencias en empresas", "subtitulo": "Tendencias en empresas", "autor": "Grow", "caption": "img/resources/tendencias.jpg" },
        { "id": "3", "tema": "Básicos para la incorporación laboral", "titulo": "Básicos para la incorporación laboral", "subtitulo": "Básicos para la incorporación laboral", "autor": "Grow", "caption": "img/resources/incorporacion.jpg" },
        { "id": "4", "tema": "Desarrollo técnico", "titulo": "Desarrollo técnico", "subtitulo": "Desarrollo técnico", "autor": "Grow", "caption": "img/resources/tecnico.jpg" },
      ];



    const search = (e) => {
        let text;
        if (!e) {
            const searchText = document.getElementById("search-text");
            text = searchText.value;
        } else {
            text = e.target.value;
        }
        text = text.toUpperCase();

        setSearchQuery(text);
        setUpdateList(!updateList)
    }

    const searchGlobal = () =>{
        setSearchQuery2(JSON.stringify({
            curp: curp,
            firstName: firstName,
            fatherLastName: fatherLastName,
            motherLastName: motherLastName,
        }));
        setUpdateList(!updateList)
    }

    const handleChangeTab = (tab) => {
        const params = [{
                label: "newIntern",
                action: "create",
                module: "Interns"
            }];

        for(let param of params){
            if(param.label === tab){
                if(!context.can(param.action, param.module)){
                    notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
                    return;
                };
                setCurrentTab(tab);
            }
        };        
    };

    const downloadExcelInterns = async() => {
        setLoadingExcel(true);
        let result = context.dashboardRedirectData.isRedirected ? context.dashboardRedirectData.interns : await Methods.getAllInternsByStatusReport(context.user.company._id, statusTable);
        if(!result){
            setLoadingExcel(false);
            return;
        };



        console.log(context);
        let tmpRes = [];
        let resultTraining = [];

        result.sort((a, b) => { return a.firstname < b.firstname ? -1 : 1 })
        
        const workbook = new Excel.Workbook();
        ExcelCSV.test(
            workbook,
            {
                companyName: context.user.company.business_name,
                companyRFC: context.user.company.rfc,
                companyId: context.user.company._id,
            },
            result
        );

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            saveAs(blob, `practicantes_${moment().format('DD/MM/YYYY')}`);
        });
        setLoadingExcel(false);
    }

    return(
        <div className="interns">

            {currentTab == "internList" && 
                <motion.div id="interns" initial={{x: -30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>
                    <h4 class="h4">Practicantes</h4>
                    <h3 className="company">{currentCompany}</h3>
                    <div className="alter-table search-container-interns">
                        { canCreate &&
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Registrar Practicante</Tooltip>}>
                                <div className='mt-15' onClick={() => {handleChangeTab("newIntern")}} id="addIntern"><FiPlus/></div>
                            </OverlayTrigger>
                        }
                        { !isGlobal &&
                            <div className="search mt-15">
                            <div className="search-icon" title="Buscar" onClick={ () => { 
                                    context.cleanDataByDashboard();
                                    search();
                                }}><RiSearch2Line/></div>
                                <Form.Group controlId="search-bar" className="search-container">
                                    <Form.Control
                                        type="text"
                                        placeholder="Búsqueda de Practicante"
                                        autoComplete={"off"}
                                        id="search-text"
                                        onChange={(e) => {setSearchQuery(e.target.value.toUpperCase())}}
                                        onKeyDown={ (e) => { 
                                            context.cleanDataByDashboard();
                                            e.keyCode == 13 && search(e);
                                        }}
                                        onInputCapture={ (e) => !e.target.value && search(e) }
                                        value={searchQuery}
                                    />
                                </Form.Group>
                            </div>
                        }
                        { isGlobal && 
                            <div className='search mt-15'>
                                <div className='search-icon' title="Buscar" onClick={() => search()}><RiSearch2Line/></div>
                                <Form.Group controlId="search-bar" className="search-container-global">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre"
                                        autoComplete={"off"}
                                        id="search-text"
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        }
                        { isGlobal &&
                            <div className='search mt-15'>
                            <div className='search-icon' title="Buscar" onClick={() => search()}><RiSearch2Line/></div>
                                <Form.Group controlId="search-bar" className="search-container-global">
                                    <Form.Control
                                        type="text"
                                        placeholder="Apellido Paterno"
                                        autoComplete={"off"}
                                        id="search-text"
                                        onChange={(e) => setFatherLastName(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        }
                        { isGlobal &&
                            <div className='search mt-15'>
                                <div className='search-icon' title="Buscar" onClick={() => search()}><RiSearch2Line/></div>
                                <Form.Group controlId="search-bar" className="search-container-global">
                                    <Form.Control
                                        type="text"
                                        placeholder="Apellido Materno"
                                        autoComplete={"off"}
                                        id="search-text"
                                        onChange={(e) => setMotherLastName(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        }

                        {
                        isGlobal &&
                            <div className="search mt-15">
                                <div className="search-icon" title="Buscar" onClick={ () => search() }><RiSearch2Line/></div>
                                <Form.Group controlId="search-bar" className="search-container-global">
                                    <Form.Control
                                        type="text"
                                        placeholder="CURP"
                                        autoComplete={"off"}
                                        id="search-text"
                                        onChange={(e) => setCurp(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        }
                        { isGlobal &&
                            <div className="search mt-15">
                                <Button className='search-btn' onClick={() => searchGlobal()}> Buscar </Button>   
                            </div>
                        }
                        
                        {isCaintraUser &&                         
                            <div className='check'>
                                <Form.Check className='mt-15' 
                                    label="Buscador Global" 
                                    onChange={() => {
                                        context.cleanDataByDashboard();
                                        setSearchQuery('');
                                        setSearchQuery2(undefined);
                                        setIsGlobal(!isGlobal);
                                    }}
                                    type="checkbox" 
                                    style={{marginBottom: "12px"}}
                                />
                            </div>
                        }

                        <div className="loadCSV mt-15"><BsUpload/> Cargar CSV</div>

                        { !isGlobal &&
                            <div className="loadCSV mt-15" onClick={()=> { downloadExcelInterns(); }} > 
                                { loadingExcel ?  <IoReload className='rotate'/> : <BsDownload/> } 
                                Descargar Excel
                            </div>
                        }

                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}>
                            <a href="#" className="reload-table-interns mt-15" onClick={() => {
                                context.cleanDataByDashboard();
                                setUpdateList(!updateList); 
                                setRotateSync(true)
                            }}><IoReload className={rotate}/></a>
                        </OverlayTrigger>
                        <div style={{display: 'flex',    'flex-direction': 'column', 'justify-content': 'space-evenly'}}>
                            <div className="video-container" onClick={ () => {setVideoLink(`https://caintrabucket-${($ENV == 'localEnv') ? 'testing' : $ENV}.s3.amazonaws.com/mailImages/prefacturaTutorial.mp4`); setShowVideoPlayer(!showVideoPlayer)}}>  
                                <img src={videoPlay} size={25} />
                                <h3 className='video-text'>Tutorial</h3>
                            </div>
                            <div className="video-container" onClick={ () => { setVideoLink(`https://caintrabucket-${($ENV == 'localEnv') ? 'testing' : $ENV}.s3.amazonaws.com/mailImages/prefacturaTutorial.mp4`); setShowVideoPlayer(!showVideoPlayer) }}>  
                                <img src={videoPlay} size={25} />
                                <h3 className='video-text'>Tutorial SIFE</h3>
                            </div>
                        </div>
                    </div>

                    <InternsList 
                        context={context} 
                        isGlobal={isGlobal}
                        showUnsuscribeInternModal={showUnsuscribeInternModal} 
                        setShowUnsuscribeInternModal={setShowUnsuscribeInternModal} 
                        setCurrentInternId={setCurrentInternId} 
                        setCurrentInternName={setCurrentInternName}
                        updateList={updateList} 
                        setUpdateList={setUpdateList} 
                        interns={interns} 
                        setInterns={setInterns} 
                        setRotateSync={setRotateSync} 
                        setCurrentTab={setCurrentTab} 
                        setCurrentDepartmentId={setCurrentDepartmentId} 
                        showDeleteInternModal={showDeleteInternModal} 
                        setShowDeleteInternModal={setShowDeleteInternModal} 
                        showTrainingModal={showTrainingModal} 
                        setShowTrainingModal={setShowTrainingModal} 
                        showLetterInModal={showLetterInModal} 
                        setShowLetterInModal={setShowLetterInModal} 
                        showLetterOutModal={showLetterOutModal} 
                        setShowLetterOutModal={setShowLetterOutModal} 
                        listType={listType} 
                        searchQuery={searchQuery}
                        searchQuery2={searchQuery2}
                        currentIntern={currentIntern}
                        setCurrentIntern={setCurrentIntern}
                        statusTable={statusTable}
                        setStatusTable={setStatusTable}
                        signatureStatus={signatureStatus}
                        setSignatureStatus={setSignatureStatus}
                    />

                    <UnsuscribeIntern 
                        currentIntern={currentIntern}
                        showUnsuscribeInternModal={showUnsuscribeInternModal} 
                        setShowUnsuscribeInternModal={setShowUnsuscribeInternModal} 
                        currentInternId={currentInternId} 
                        currentInternName = {currentInternName}
                        setUpdateList={setUpdateList} 
                        updateList={updateList}
                    />

                    <DeleteInternModal 
                        setUpdateList={setUpdateList} 
                        setShowDeleteInternModal={setShowDeleteInternModal} 
                        showDeleteInternModal={showDeleteInternModal} 
                        currentInternId={currentInternId} 
                        updateList={updateList} 
                    />

                    <Training 
                        setUpdateList={setUpdateList} 
                        setShowTrainingModal={setShowTrainingModal} 
                        showTrainingModal={showTrainingModal} 
                        currentInternId={currentInternId} 
                        updateList={updateList} 
                        currentIntern={currentIntern}
                        setCurrentIntern={setCurrentIntern}
                    />

                    <LetterIn 
                        setUpdateList={setUpdateList} 
                        setShowLetterInModal={setShowLetterInModal} 
                        showLetterInModal={showLetterInModal}
                        currentInternId={currentInternId} 
                        updateList={updateList} 
                        currentIntern={currentIntern}
                        setCurrentIntern={setCurrentIntern}
                    />

                    <LetterOut
                        setUpdateList={setUpdateList} 
                        showLetterOutModal={showLetterOutModal} 
                        setShowLetterOutModal={setShowLetterOutModal} 
                        currentInternId={currentInternId} 
                        updateList={updateList} 
                        currentIntern={currentIntern}
                        setCurrentIntern={setCurrentIntern}
                    />

                    <VideoPlayer
                        showVideoPlayer={showVideoPlayer}
                        setShowVideoPlayer={setShowVideoPlayer}
                        videoLink={videoLink}
                    />

                </motion.div>
            }

            {currentTab == "newIntern" &&
                <NewIntern 
                    setCurrentTab={setCurrentTab} 
                    context={context} 
                    setUpdateList={setUpdateList}
                />
            }

            {currentTab == "editContrat" &&
                <UpdateRenewContract 
                    setCurrentTab={setCurrentTab} 
                    context={context}
                    contractToUpdateRenew={contractToUpdateRenew}
                    contractToUpdateZipCode={contractToUpdateZipCode}
                />
            }

            {currentTab == "verifyInternDocs" &&
                <VerifyInternDocs 
                    setCurrentTab={setCurrentTab} 
                    currentTab={currentTab} 
                    currentInternId={currentInternId} 
                    interns={interns} 
                    setUpdateList={setUpdateList} 
                    updateList={updateList}
                    updatedFromPrefacture={updatedFromPrefacture}
                    setContractToUpdateRenew={setContractToUpdateRenew}
                    setContractToUpdateZipCode={setContractToUpdateZipCode} 
                />
            }

            {currentTab == "internMedicalInsurance" &&
                <InternMedicalInsurance 
                    currentInternId={currentInternId} 
                    setCurrentTab={setCurrentTab} 
                    currentTab={currentTab} 
                />
            }

        </div>
    );
};
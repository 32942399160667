import React, { useState } from 'react';
import {Form, Row, Col, Button, Modal, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import {DateTimePicker} from 'react-widgets';
import Methods from '../../../utils/https';
import { deletePosteriorPolicies } from '../../../utils/insuranceMethods';
import moment from 'moment';
import { sendITESMdocs } from '../../../utils/signatureMethods';
const axios = require("axios");
const URL = process.env.REACT_APP_SOCKET_URL;


export default function UpdateContractStatus ({showUpdateContrat, setShowUpdateContract, currentInternContract, contractToUpdate, contractActionLabel, context, setUpdateInfo, updateInfo, currentInternData,  currentInsuranceData, isRenovationCase, internContracts, internPolicies, currentCompany, sendDocs, requireSife, requireInsurance, isITESMCompany}) {
    
    const [contractEndDate, setContractEndDate] = useState(new Date());
    const [disableButton, setDisableButton] = useState(false);

    const sortPolicies = (policies) => {
        policies.sort(function(a,b){
            let momentA = a.contractStartDate.includes("/") ? moment(a.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(a.contractStartDate.slice(0,10)).startOf("day");
            let momentB = b.contractStartDate.includes("/") ? moment(b.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(b.contractStartDate.slice(0,10)).startOf("day");

            return momentA.isAfter(momentB) ? 1 : -1;
        });
        return policies;
    }

    const handleUnsuscribeDate = (newDate) => {
        let start =  moment(contractToUpdate.internStartDate, "DD/MM/YYYY");
        let finish =  moment(contractToUpdate.internEndDate, "DD/MM/YYYY");
        let dateSelectedAux = moment(newDate).startOf('day');

        if(dateSelectedAux.isAfter(finish)){
            notify("La fecha de baja no puede ser posterior al final del convenio ", "error");
            return;
        }

        if(dateSelectedAux.isBefore(start)){
            notify("La fecha de baja no puede ser anterior al inicio del convenio ", "error");
            return;
        }

        setContractEndDate(newDate)
    }


    const replacePolicies = async () => {
        let newInsuranceData;
        let actualPolicies = await Methods.getPolicyById(currentInternData._id);
        if(actualPolicies.policy.length){
            let sortedPolicies = sortPolicies(actualPolicies.policy)
            let contractSortedPolicies = sortedPolicies.filter(policy => {
                return policy.contractId == contractToUpdate._id
            })
            if(!contractSortedPolicies.length) return;
            let lastPolicy = contractSortedPolicies[contractSortedPolicies.length-1];
            let momentAux = new moment(contractEndDate)
            newInsuranceData = {
                policyId: lastPolicy._id,
                unsubscribe: momentAux.format("DD/MM/YYYY"),
            }
            await Methods.resupplyPolicies(newInsuranceData);
        }
    }

    const compareDates = (oldDate) => {
        let oldMoment =  moment(oldDate.substring(0,10))
        let newMoment =  moment()
        let diff = newMoment.diff(oldMoment,"days");
        return diff > 7
    }
    
    
    const updateContract = async (e) => {
        setDisableButton(true)
        e.preventDefault();

        let currentDate = moment().format();
        let description;
        let inactiveInternByDate = true;
        let currentContractData = {
            _id: contractToUpdate._id,
            attributes: [],
            approved: false,
            sendDocument: false,
            startDate: contractToUpdate.internStartDate,
            duration: contractToUpdate.internContractDuration,
            currentInsurance: currentInternData.insurance
        }

        let internData = {
            _id: contractToUpdate.internId,
            attributes: []
        };
        let promises = [];

        if(contractActionLabel == "Invalidado" || contractActionLabel == "Inactivo"){

            let start = contractToUpdate.internStartDate.includes("/") ? moment(contractToUpdate.internStartDate,"DD/MM/YYYY").startOf("day") : moment(contractToUpdate.internStartDate.slice(0,10)).startOf("day");
            let finish = contractToUpdate.internEndDate.includes("/") ? moment(contractToUpdate.internEndDate,"DD/MM/YYYY").startOf("day") : moment(contractToUpdate.internEndDate.slice(0,10)).startOf("day");

            let dateSelectedAux = moment(contractEndDate).startOf('day');

            description = e.target.elements.description.value;
        
            if(description.trim() == ""){
                notify("La descripción de la baja no puede estar vacía", "error");
                return;
            };

            if(contractActionLabel == "Inactivo"){
                if(contractToUpdate.unsuscribeDate && compareDates(contractToUpdate.unsuscribeDate)){
                    notify(" No se puede modificar este campo si ya pasaron 7 días después de la última baja ", "error");
                    return;
                }

                if(dateSelectedAux.isAfter(finish)){
                    notify("La fecha de baja no puede ser posterior al final del convenio ", "error");
                    return;
                }

                if(dateSelectedAux.isBefore(start)){
                    notify("La fecha de baja no puede ser anterior al inicio del convenio ", "error");
                    return;
                }
                
                let contractStartDateAux = moment(getFormatedDate(contractToUpdate.internStartDate));
                let contractEndDateAux = moment(getFormatedDate(contractToUpdate.internEndDate))
                await getUnsuscribeErrors(dateSelectedAux, contractStartDateAux, contractEndDateAux, currentContractData, inactiveInternByDate, internData);
            }else{
                currentContractData.attributes.push({updateKey: "contractStatus", updateValue: contractToUpdate.contractStatus == "En proceso" ? "Invalidado" : contractActionLabel})
                currentContractData.attributes.push({updateKey: "unsuscribeDate", updateValue: currentDate});
                internData.attributes.push({updateKey: "internStatus", updateValue: "Inactivo"});
                internData.lastStatus = contractToUpdate.contractStatus;
            }
            currentContractData.attributes.push({updateKey: "unsuscribeDescription", updateValue: description});
            currentContractData.attributes.push({updateKey: "unsuscribedBy", updateValue: context.user.data.email});
        }


        if(contractActionLabel == "Activo"){
            currentContractData.approved = true;
            let contractStartDateAux = moment(getFormatedDate(contractToUpdate.internStartDate));
            let dateSelected = moment().startOf('day');

            currentContractData.attributes.push(
                {updateKey: "contractApprovalDate", updateValue: moment().format()},
                {updateKey: "approvedBy", updateValue: context.user.data.email}
            )          
            
            if(getApproveErrorsByIntern(dateSelected, contractStartDateAux, currentContractData, internData)) return;
        }

        const notification = {
            name: getLabelContract(contractActionLabel),
            user: context.user.data.fullName,
            description: `Motivo: ${getLabelContract(contractActionLabel)}. Descripción: ${description}. Usuario: ${context.user.data.email}` ,
            type: "Información",
            date: new Date()
        };

        try{

            if((contractActionLabel == "Activo" || contractActionLabel == "Pendiente") && contractToUpdate.requireInsurance){
                let momentStart = contractToUpdate.internStartDate.includes("/") ? moment(contractToUpdate.internStartDate,"DD/MM/YYYY").startOf("day"): moment(contractToUpdate.internStartDate.slice(0,10)).startOf("day");
                if(!isRenovationCase || (isRenovationCase && moment().diff(momentStart,"days") <= 6 && momentStart.diff(moment(),'days') < 30 )){
                    let currentInsuranceAux = getCurrentInsuranceData(contractToUpdate);
                    let insuranceData = {
                        policyId: currentInsuranceAux?._id,
                        totalDuration: contractToUpdate.internContractDuration,
                        contractStartDate: contractToUpdate.internStartDate,
                        contractEndDate: contractToUpdate.internEndDate,
                        contractId: contractToUpdate._id,
                        isPending: currentContractData.isRenew || false
                    }
                    let response = await Methods.setPolicies(insuranceData);
                    if(response.status == 400) return
                }
            }
            if(contractActionLabel == "Invalidado" || contractActionLabel == "Inactivo" && contractToUpdate.requireInsurance){
                if(contractToUpdate.contractStatus != 'En proceso') setLastSignatureStatus();
                await deletePosteriorPolicies(internPolicies, contractActionLabel, contractEndDate, contractToUpdate._id, currentInternData, currentCompany);
                if(contractActionLabel == "Inactivo"){
                    await replacePolicies();
                }else{
                    await setLastInsuranceData(contractToUpdate);
                }
                if(contractToUpdate.activeInternInsurance) {
                    await Methods.updateIntern({
                        _id: contractToUpdate.internId,
                        attributes: [{updateKey: "requireInsurance", updateValue: false}]
                    });
                }
            }

            if(contractToUpdate._id == currentInternContract._id){
                if(contractActionLabel == 'Activo' && isFirstApproved()) internData.emailCase = 'Alta';
                promises.push(Methods.updateIntern(internData));
            }

            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });
            if(currentContractData.sendDocument && requireSife){
                promises.push(sendDocs(contractToUpdate));
                if(isITESMCompany && internData.emailCase == 'Alta'){
                    promises.push(sendITESMdocs(contractToUpdate));
                }
                currentContractData.attributes.push({updateKey: "isSifeDocument", updateValue: true});
                currentContractData.attributes.push({updateKey: "signatureStatus", updateValue: 'Pendiente'});
                currentContractData.attributes.push({updateKey: "lastSignatureReminder", updateValue: moment().format('DD/MM/YYYY')});
            }
            promises.push(Methods.updateContractById(currentContractData));
            await Promise.all(promises);
            setShowUpdateContract(!showUpdateContrat)
            setUpdateInfo(!updateInfo);
            setDisableButton(false);
        }catch(e){
            setShowUpdateContract(!showUpdateContrat)
            e.data && notify(e.data.response.message, "error");
            setUpdateInfo(!updateInfo);
            setDisableButton(false);
        }
    }

    const getCurrentInsuranceData = (contractToUpdate) => {
        let insurance = internPolicies.filter(insurance => {
            return (insurance.contractId == contractToUpdate._id || insurance.currentContractId == contractToUpdate._id);
        })
        return insurance[0]
    }


    const getApproveErrorsByIntern = (dateSelected, contractStartDateAux, currentContractData, internData) =>  {

        let dateToEvaluate = dateSelected || moment().startOf('day');


        if(!context.can("approve", "Interns")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return true;
        };

        if(currentInternData.bank == "Ninguno"){
            notify("El practicante no cuenta con un banco asignado", "error");
            return true;
        };

        const dataToEvaluate = ["collegeName", "departmentName", "directBoss", "internAmount", "internCareer", "internCurp", "internEmail", "internExtNumber", "internFaculty", "internFatherLastname", "internFirstName", "internMotherLastname", "internMunicipality", "internPhone", "internScholarship", "internStreet", "internSuburb", "schoolEnrollment"];

        for(let key of dataToEvaluate){
            if(contractToUpdate[key] == "" || contractToUpdate[key] == "_" || !contractToUpdate[key]){
                notify("Todavía no llenas la información necesaria para continuar", "error");
                return true;
            };
        };

        if(!currentInternData.rfc){
            notify("Todavía no llenas la información necesaria para continuar", "error");
            return true;
        }

        if(dateToEvaluate.diff(contractStartDateAux, 'days') > 6 && contractToUpdate.requireInsurance){
            notify("La fecha de aprobación no debe ser mayor a 7 días despues del inicio del convenio seleccionado", "error");
            return true
        }

        if(contractToUpdate._id == currentInternContract._id){
            currentContractData.attributes.push({updateKey: "contractStatus", updateValue: verifyPendingStatus(contractToUpdate.internStartDate)});
            internData.attributes.push({updateKey: "internStatus", updateValue: verifyPendingStatus(contractToUpdate.internStartDate)});
            internData.lastStatus = contractToUpdate.contractStatus;
        }else{
            currentContractData.isRenew = true;
            currentContractData.attributes.push({updateKey: "contractStatus", updateValue: "Pendiente"});
        }
        currentContractData.sendDocument = true;
        return false;
    }

    const verifyPendingStatus = (startDate) => {
        let startDateAux = moment(getFormatedDate(startDate)).startOf('day');
        let currentDate = moment().startOf('day');
        if(currentDate.isBefore(startDateAux)) return "Pendiente";
        return "Activo";
    }
    
    const getUnsuscribeErrors = (dateSelected, contractStartDate, contractEndDate, data, inactiveInternByDate, internData) =>  {
        let currentDate = moment().startOf('day');
        if(dateSelected.diff(contractStartDate, 'days') < 0){
            notify("La fecha de baja no debe ser menor a la fecha de inicio del convenio", "error");
            return true 
        }

        if(dateSelected.diff(contractEndDate, 'days') > 0){
            notify("La fecha de baja no debe ser mayor al fin del convenio seleccionado", "error");
            return true
        }

        if(currentDate.diff(dateSelected) > 0){
            data.attributes.push({updateKey: "contractStatus", updateValue: "Inactivo"})
            internData.attributes.push({updateKey: "internStatus", updateValue: "Inactivo"});
            internData.lastStatus = currentInternData.internStatus;
            inactiveInternByDate = false;
        }
        data.attributes.push({updateKey: "unsuscribeDate", updateValue: dateSelected.format()})

        return false;
    }

    const isFirstApproved = () => {
        for(let contract of internContracts){
            if(contract._id != contractToUpdate._id && contract.contractStatus != 'Invalidado') return false
        }
        return true
    }

    const setLastSignatureStatus = async () => {
        let internData = {
            _id: contractToUpdate.internId
        }
        if (requireSife) {
            if((contractToUpdate._id == currentInternContract._id && !verifyPendingContract(contractToUpdate)) || contractToUpdate._id != currentInternContract._id) {
                let lastContract = getLastContract(contractToUpdate);
                if (lastContract && lastContract.signatureStatus && lastContract.lastSignatureReminder) {
                    internData.attributes = [{ updateKey: "signatureStatus", updateValue: lastContract.signatureStatus },
                    { updateKey: "lastSignatureReminder", updateValue: lastContract.lastSignatureReminder }]
                } else {
                    internData.attributes = [{ updateKey: "signatureStatus", updateValue: '' },
                    { updateKey: "lastSignatureReminder", updateValue: ''}]
                };
                await Methods.updateIntern(internData)
            }
        } 
    }

    const setLastInsuranceData = async (currentContractToInvalidate) => {
        let sortedPolicies = sortPolicies(internPolicies)
        let lastContract = getLastContract(currentContractToInvalidate);
        if(lastContract){
            let contractSortedPolicies = sortedPolicies.filter(policy => {
                return policy.contractId == lastContract._id
            })
            if(!contractSortedPolicies.length) return;
            let lastPolicy = contractSortedPolicies[contractSortedPolicies.length-1];
            let internDataToUpdate = {
                _id: currentContractToInvalidate.internId,
                attributes: [
                    { updateKey: "currentInsuranceId", updateValue: lastPolicy._id },
                    { updateKey: "insurance", updateValue: lastPolicy.insuranceId || '' }
                ] 
            }
            await Methods.updateIntern(internDataToUpdate)
        }
    }


    const getLastContract = contractToEvaluate => {
        let startDate = moment(getFormatedDate(contractToEvaluate.internStartDate)).startOf('day');
        for (let contract of internContracts) {
            let contractEndDate = contract.unsuscribeDate ? moment(contract.unsuscribeDate).startOf('day') : moment(getFormatedDate(contract.internEndDate)).startOf('day')
            if (contract.contractStatus != 'Invalidado' && startDate.isAfter(contractEndDate) && contract._id != contractToEvaluate._id) return contract;
        }
    }

    const verifyPendingContract = contractToEvaluate => {
        for (let contract of internContracts) {
            if (contract.contractStatus == 'Pendiente' && contractToEvaluate._id != contract._id) return true;
        }
        return false;
    }

    const getLabelContract = (contractActionLabel) => {
        let label = {
            "Invalidado": "Invalidación de Convenio",
            "Inactivo": "Baja de Convenio",
            "Activo": "Aprobación de Convenio" 
        }
        return label[contractActionLabel] || "";
    };


    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            result = month + "/" + day + "/"+ year;
        } else {        
            const newDateArray = dateString.split("/");
            month = addZeroToDate(newDateArray[0]);
            day = addZeroToDate(newDateArray[1]);
            result = day + "/" + month + "/" + newDateArray[2];
        }
        return result;
    }

    const addZeroToDate = (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };

    

    return(
        <Modal className="new-cc-modal" show={showUpdateContrat} onHide={() => setShowUpdateContract(!showUpdateContrat)} centered>
            <Form onSubmit={updateContract}>

                <Modal.Header>
                    <Image src={InternsLogo} className="logoCaintra"/>
                    <p>{getLabelContract(contractActionLabel)}</p>
                </Modal.Header>
                
                <Modal.Body>

                    <Row>
                        <Col>

                            { contractActionLabel == "Inactivo" &&
                                
                                <Form.Group style={{marginTop: "20px"}} controlId="contractEndDate">
                                    <Form.Label>Selecciona la fecha de baja</Form.Label>
                                    <DateTimePicker name="contractEndDate" format={'DD/MM/YYYY'} editFormat={'DD/MM/YYYY'} value={contractEndDate} onCurrentDateChange={(date) => handleUnsuscribeDate(date)} time={false} />
                                </Form.Group> 
                    
                            }
                            { contractActionLabel != "Activo" && 
                                <Form.Group controlId="description">
                                    <Form.Label>Escribe el porqué</Form.Label>
                                    <Form.Control className="description-input" style={{height: "50px"}} type="text" autoComplete={"off"} placeholder="Escribe el porqué" required/>
                                </Form.Group>

                            }

                        </Col>
                    </Row>
                    
                

                    <div className='center-buttons'>
                        <Button className="float-right" variant="danger" type="button" onClick={() => setShowUpdateContract(!showUpdateContrat)}>
                            Cancelar
                        </Button>
                        <Button className="float-right ml-2" disabled={disableButton} variant="primary" type="submit">
                            Confirmar
                        </Button>
                    </div>

                </Modal.Body>
            </Form>
        </Modal>
    )
};
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { Table, Accordion, Card, Button } from 'react-bootstrap';
import moment from 'moment';
import { notify } from '../../../utils/notify';

export const CompanyInternsTable = ({
    filteredInterns,
    setSelected,
    selected,
    setSomeoneIsAlreadyDispersed,
    forceUpdate,
    searchQuery,
    updateSelected,
    getCompanyPaymentPeriod,
    startDate,
    dateToDisperse
}) => {

    const [accordionOpened, setAccordionOpened] = useState(0);  
    const updateSelectedCompany = (company) => {

        if(!canCompanyDisperse(company)){
            notifyDisabledCompany(company);
            return;
        }

        let selectedAux = selected;
        if (!company.isSelected){
            company.isSelected=true;
            for(let intern of company.interns) {
                if(!intern.isSelected && 
                    (intern.accountColorStatus == undefined || intern.accountColorStatus == '#000000')
                ){
                    intern.isSelected = true;
                    intern.dispersionStatus == "Dispersado" && setSomeoneIsAlreadyDispersed(true);
                    selectedAux.push(intern);
                }
            }
            setSelected(selectedAux);
        } else {
            company.isSelected=false;
            for(let intern of company.interns) {
                if(intern.isSelected){
                    intern.isSelected= false;
                    selectedAux = selectedAux.filter(item => item._id !== intern._id);
                }
            }
            setSelected(selectedAux);
            if(selectedAux.length == 0){
                setSomeoneIsAlreadyDispersed(false);
                return;
            };
            selectedAux.map( _intern => 
                _intern.dispersionStatus == "Dispersado" ? 
                setSomeoneIsAlreadyDispersed(true) : setSomeoneIsAlreadyDispersed(false)
            );
        }
        forceUpdate();
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Pendiente":
                return "#FFB100";
            case "Dispersado":
                return "#25EB51";
            default:
                return "#D7D7D7";
        };
    };

    const getTotalSelected = (interns) => {
        let amount = 0;
        for(let intern of interns){
            if(intern.isSelected) amount += Number.parseFloat(intern.total_amount.toFixed(2));
        }

        return (parseFloat(amount) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };


    const canCompanyDisperse = (company) => {
        if(company.isDisabled) return false;

        let dispersionByDate = company.dateToEndableDispersion ? moment(company.dateToEndableDispersion, 'DD/MM/YYYY').isSameOrBefore(moment()) : true;
        
        return dispersionByDate;
    }

    const selectInternToDisperse = (intern, company) => {
        if(canCompanyDisperse(company)){
            updateSelected(intern);
        }else{
            notifyDisabledCompany(company);
        }
    }

    const notifyDisabledCompany = (company) => {
        notify(`La compañía se encuentra deshabilitada${(company.dateToEndableDispersion && !company.isDisabled) ? ` hasta el ${company.dateToEndableDispersion}`: ''}.`, 'error')
    } 

    return (
        <motion.div 
            initial={{y: -30, opacity: 0}} 
            animate={{y: 0, opacity: 1, background: "#d7d7d7"}} 
            transition={{ duration: 0.4 }} 
            className="table-border"
        >
            <Table hover>
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                checked
                                disabled
                            />
                        </th>
                        <th>Nombres</th>
                        <th>Apellido Paterno</th>
                        <th>Apellido Materno</th>
                        <th>Cuenta</th>
                        <th>Periodo de pago</th>
                        <th>Fecha de pago</th>
                        <th>Fecha a dispersar</th>
                        <th>Monto a dispersar</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
            </Table>

            {filteredInterns.map((company, eventKeyIndex) => {
                return (
                    <Accordion defaultActiveKey="0" key={eventKeyIndex}>
                        <Card>
                            <Card.Header>
                                <div style={{paddingLeft: 10}}>
                                    {!searchQuery && 
                                        <input
                                            type="checkbox"
                                            checked={company.isSelected}
                                            onChange={() => updateSelectedCompany(company)}                                                           
                                        />
                                    }
                                    <Accordion.Toggle 
                                        as={Button} 
                                        variant="link" 
                                        eventKey="0" 
                                        onClick={() => 
                                            setAccordionOpened(accordionOpened == eventKeyIndex+1 ? -1 : eventKeyIndex+1)
                                        }>
                                        <div>
                                            <div className='dispersion-company-header'>
                                                {accordionOpened == eventKeyIndex+1 ? 
                                                    <FontAwesomeIcon icon={faMinusSquare} /> : 
                                                    <FontAwesomeIcon icon={faPlusSquare} />
                                                } 
                                                {company.companyName} - {company.interns.length}
                                                { !canCompanyDisperse(company) && <p>Suspendida</p>} 
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                </div>
                                <div>
                                    <b>${getTotalSelected(company.interns)}</b>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Table>
                                        <tbody>
                                            { company.interns.map((intern) => {
                                              
                                                if(intern.disabledCompany == false){
                                                    intern.isSelected = false
                                                }
                                                return(
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"  
                                                                checked={
                                                                    
                                                                    intern.isSelected
                                                                }                                                                 
                                                                onChange={() => 
                                                                    selectInternToDisperse(intern, company)
                                                                }
                                                            />
                                                        </td>
                                                        <td>{intern.firstname}</td>
                                                        <td>{intern.father_lastname}</td>
                                                        <td>{intern.mother_lastname}</td>
                                                        <td style={{
                                                            color: intern.accountColorStatus ? intern.accountColorStatus : '#000000'
                                                        }}>
                                                            {intern.clabe || "N/A"}
                                                        </td>
                                                        <td>{getCompanyPaymentPeriod(intern.company_id)}</td>
                                                        <td>{moment(startDate).format("DD/MM/YYYY")}</td>
                                                        <td>{moment(dateToDisperse).format("DD/MM/YYYY")}</td>
                                                        <td>
                                                            $ {(parseFloat(intern.total_amount) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                        </td> 
                                                        <td style={{color: getStatusColor(intern.dispersionStatus)}}>
                                                            {intern.dispersionStatus}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                )
            })}
        </motion.div>
    )
}

import React, {useState, useEffect} from 'react'
import {Modal, Button, Form, Col, Row} from 'react-bootstrap';
import Methods from '../../../utils/https';

export const StatusContpaq = ({showStatusContpaq, setShowStatusContpaq, prefactureDetailID, setLabelValue, labelValue, alreadySend, isCanceled, isManuallyUpdated}) => {


    const getStatus = async () => { 
        if(prefactureDetailID != undefined){
            if(!alreadySend){
                setLabelValue("La factura está pendiente de ser timbrada");
            } else if(isCanceled) {
                setLabelValue("La factura fue cancelada por el asesor");
            } else if(isManuallyUpdated) {
                setLabelValue("Los documentos fueron cargados manualmente por el asesor");
            } else {
                let response = await Methods.getContpaqStatus(prefactureDetailID);
                if(response != null && response.description ){
                    setLabelValue(response.description);
                } else {
                    setLabelValue("En espera de respuesta por parte de Contpaq");
                }
            }
        }
    }

    const handleClose = () => {
        setShowStatusContpaq(!showStatusContpaq)
    }

    useEffect(() => {
        getStatus();
    }, [prefactureDetailID, isCanceled, alreadySend]);

    return (
        <Modal className="new-contpaq-modal" onHide={() => handleClose()} show={showStatusContpaq} centered >
            <Modal.Body>
                <Form autoComplete={"off"}>
                    <Modal.Header className='contpaq-margin'>
                        <h3 className='purchase-order-title' style={{marginBottom:'-5px', paddingLeft:'0px'}}>Status</h3>
                    </Modal.Header>
                    <Row className='contpaq-margin'>
                        <Col>              
                            <Form.Label className='center-modal-text' style={{marginTop: '30px'}}>
                                {labelValue}
                            </Form.Label>
                        </Col>
                    </Row>
                    
                    <Modal.Body className='contpaq-margin'>
                        <div className='center-buttons'>
                            <Button variant="primary" onClick={() => handleClose()}>
                                Aceptar
                            </Button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

import { UserContext } from "../../context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { Row, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import LoadingModal from '../../custom/LoadingModal/LoadingModal';
import "./Groups.css";
import "../Companies/Companies.css";

import { BiArrowBack } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { AiOutlineUnorderedList } from "react-icons/ai"
import { FaSort } from "react-icons/fa";

import ChangeGroupNameModal from "./components/ChangeGroupNameModal";
import NewGroup from "./components/NewGroup";
import ConfirmDeleteGroiup from "./components/ConfirmDeleteGroup";
import AddRFC from "./components/AddRFC";
import DeleteRFC from "./components/DeleteRFC";

import Methods from "../../utils/https";
import {notify} from '../../utils/notify';


const Main = (props) => {
    const [searchQuery, setSearchQuery] = React.useState("");
    const [data, setData] = React.useState(null);
    const [selectedGroup, setSelectedGroup] = React.useState("");

    const [displayNewGroup, setDisplayNewGroup] = React.useState(false);
    const [displayDeleteGroup, setDisplayDeleteGroup] = React.useState(false);
    const [displayChangeGroupName, setDisplayChangeGroupName] = React.useState(false);

    // 1 Means ascending sort
    const [reverseSort, setReverseSort] = React.useState(1);

    const context = useContext(UserContext);


    async function getGroups() {
        let groupsAux = await Methods.getGroups()
        props.setAllGroups(groupsAux)
        setData(groupsAux);
    }

    async function addGroup(name) {
        let exists = false;

        for (let i = 0; i < data.length; i++) {
            if (data[i].name == name) {
                exists = true;
                throw new Error("Nombre de grupo ya existente");
            }
        }

        if (exists == false) {
            setDisplayNewGroup(false);
            await Methods.createGroup({ name: name });
            getGroups();
        }
    }

    async function deleteGroup(group) {
        setDisplayDeleteGroup(false);
        await Methods.deleteGroup({ group_id:group._id, groupCompaniesRFC: group.groupCompaniesRFC});
        getGroups();
    }

    useEffect(getGroups, []);

    function deleteGroupHandler(k) {
        setSelectedGroup(k);
        setDisplayDeleteGroup(true);
    }

    function changeGroupNameHandler(k) {
        setSelectedGroup(k);
        setDisplayChangeGroupName(true);
    }
    async function changeGroupName(id, name) {
        let exists = false;

        for (let i = 0; i < data.length; i++) {
            if (data[i].name == name) {
                exists = true;
                throw new Error("Nombre de grupo ya existente");
            }
        }

        if (exists == false) {
            setDisplayChangeGroupName(false);
            await Methods.changeGroupName({ id, name });
            getGroups();
        }
    }

    return <div className="approve-orgs-and-comps">
        <h4>Grupos</h4>
        <h3 className="company">{context.user.company.business_name}</h3>

        <div className="alter-table">
            {context.can("create", "Groups") &&
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Dar de Alta a Grupo</Tooltip>}
                >
                    <div className="add-company" onClick={() => setDisplayNewGroup(true)}><FiPlus /></div>
                </OverlayTrigger>
            }



            <div className="search-icon"><RiSearch2Line /></div>
            <Form.Group controlId="search-bar" className="search-container">
                <Form.Control
                    id="search-text"
                    type="text"
                    placeholder="Búsqueda de grupos"
                    autoComplete={"off"}
                    onInputCapture={async (e) => {
                        setSearchQuery(e.target.value);
                    }}
                />
            </Form.Group>

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
            >
                <a href="#" className="reload-table" onClick={getGroups}><IoReload /></a>
            </OverlayTrigger>
        </div>

        <Row>
            <div className="list-companies" style={{ width: "100%", }}>
                <div className="title-container" style={{ marginBottom: "10px" }}>
                    <h5 style={{ display: "inline" }}>Grupos</h5>
                    <FaSort className="groups-icon" style={{ fontSize: "1rem", marginBottom: "5px", display: "inline" }} onClick={() => setReverseSort(k => -k)} />
                </div>

                {data && data
                    .filter(k => {
                        return k.name.toLowerCase().startsWith(searchQuery.toLowerCase());
                    })
                    .sort((a, b) => {
                        let rtn = 0;
                        if (a.name < b.name) { rtn = -1; }
                        if (a.name > b.name) { rtn = 1; }
                        return rtn * reverseSort;
                    })
                    .map(k => {
                        return <div className="table-row" style={{ height: "60px" }} key={k._id}>
                            <div className="name-container">
                                {k.name}
                            </div>
                            <div className="options-container">
                                <AiOutlineUnorderedList onClick={() => props.selectGroupHandler(k)} className="groups-icon" />
                                {context.can("update", "Groups") && <FiEdit onClick={() => changeGroupNameHandler(k)} className="groups-icon" />}
                                {context.can("delete", "Groups") && <BsTrash color="red" onClick={() => deleteGroupHandler(k)} className="groups-icon" />}
                            </div>
                        </div>
                    })
                }
            </div>
        </Row>

        <ChangeGroupNameModal
            displayModal={displayChangeGroupName}
            hideModal={() => { setDisplayChangeGroupName(false) }}
            selectedGroup={selectedGroup}
            changeGroupName={changeGroupName}
        />
        <NewGroup
            displayNewGroup={displayNewGroup}
            hideModal={() => { setDisplayNewGroup(false) }}
            addGroup={addGroup}
        />
        <ConfirmDeleteGroiup
            displayDeleteGroup={displayDeleteGroup}
            selectedGroup={selectedGroup}
            hideModal={() => setDisplayDeleteGroup(false)}
            deleteGroup={deleteGroup}
        />
    </div>
};

const GroupView = (props) => {
    const [data, setData] = useState(null);
    const [selectedRFC, setSelectedRFC] = React.useState("");
    const [displayDeleteRFC, setDisplayDeleteRFC] = useState(false);
    const [displayAddGroupItem, setDisplayAddGroupItem] = React.useState(false);
    const context = useContext(UserContext);

    const [reverseSort, setReverseSort] = React.useState(1);

    async function getGroupItems(groupCompaniesRFC) {
        let dataAux = await Methods.getGroupItems({ groupCompaniesRFC: groupCompaniesRFC ? groupCompaniesRFC : props.selectedGroup.groupCompaniesRFC || []})
        setData(dataAux);
    }
    useEffect(getGroupItems, []);

    async function addGroupItem(_id, rfc) {
        let currentCompaniesAux = props.selectedGroup.groupCompaniesRFC || [];
        currentCompaniesAux = currentCompaniesAux ? new Array(...currentCompaniesAux, rfc) : new Array(rfc)
        let body = {
            group_item: {
                RFC: rfc,
                _id: _id,
            },
            group_id: props.selectedGroup._id,
            groupCompaniesRFC: currentCompaniesAux
        }
        props.selectedGroup.groupCompaniesRFC = currentCompaniesAux;
        await Methods.addGroupItem(body);
        props.setSelectedGroup({...props.selectedGroup});
        getGroupItems(currentCompaniesAux);
        setDisplayAddGroupItem(false);
    };

    async function deleteRFC() {
        let groupCompaniesRFC = props.selectedGroup.groupCompaniesRFC ? 
        props.selectedGroup.groupCompaniesRFC.filter(rfc => rfc != selectedRFC.rfc) : []; 
        
        await Methods.deleteGroupItem({ 
            group_id: props.selectedGroup._id,
            rfc: selectedRFC.rfc, 
            groupCompaniesRFC: groupCompaniesRFC 
        });

        notify("Se eliminó exitosamente el RFC del grupo.", "success");
        props.selectedGroup.groupCompaniesRFC = groupCompaniesRFC;
        props.setSelectedGroup({...props.selectedGroup});
        getGroupItems();
        setDisplayDeleteRFC(false);
    }

    function deleteRFCHandler(k) {
        setSelectedRFC(k);
        setDisplayDeleteRFC(true);
    }

    async function getCompanyNamesByRFC(RFC) {
        return await Methods.getCompanyNamesByRFC({ RFC: RFC });
    }
    return <div className="approve-orgs-and-comps">
        <h4><BiArrowBack onClick={props.selectHomeHandler} className="button-back" /> RFCs</h4>
        <h3 className="company">{props.selectedGroup.name}</h3>


        <div className="alter-table" style={{ marginBottom: "15px" }}>
            {context.can("update", "Groups") &&
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Agregar RFC</Tooltip>}
                >
                    <div className="add-company" onClick={() => { setDisplayAddGroupItem(true) }}><FiPlus /></div>
                </OverlayTrigger>
            }

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
            >
                <a href="#" className="reload-table" onClick={() => getGroupItems()}><IoReload /></a>
            </OverlayTrigger>

            <FaSort className="groups-icon" style={{ fontSize: "1rem", marginBottom: "5px", display: "inline" }} onClick={() => setReverseSort(k => -k)} />
        </div>

        <Row>
            <div className="list-companies" style={{ width: "100%", }}>
                {data && data
                    .sort((a, b) => {
                        let rtn = 0;
                        if (a.rfc < b.rfc) { rtn = -1; }
                        if (a.rfc > b.rfc) { rtn = 1; }
                        return rtn * reverseSort;
                    })
                    .map(k => {
                        return <div className="table-row" style={{ height: "60px" }}>
                            <div className="name-container">
                                {k.rfc} - {k.business_name}
                            </div>
                            <div className="options-container">
                                {context.can("update", "Groups") && <BsTrash color="red" onClick={() => deleteRFCHandler(k)} className="groups-icon" />}
                            </div>
                        </div>
                    })}
            </div>
        </Row>

        <AddRFC
            addRFC={addGroupItem}
            getCompanyNamesByRFC={getCompanyNamesByRFC}
            displayModal={displayAddGroupItem}
            hideModal={() => setDisplayAddGroupItem(false)}
            data={data}
            allGroups={props.allGroups}
            selectedGroup={props.selectedGroup}
        />

        <DeleteRFC
            displayModal={displayDeleteRFC}
            hideModal={() => setDisplayDeleteRFC(false)}
            selected={selectedRFC}
            delete={deleteRFC}
        />
    </div>
};

export default function Groups() {
    const [tab, setTab] = React.useState("Groups");
    const [selectedGroup, setSelectedGroup] = React.useState("");
    const [allGroups, setAllGroups] = React.useState([]);

    function selectGroupHandler(_selectedGroup) {
        setSelectedGroup(_selectedGroup);
        setTab("Edit");
    }

    function selectHomeHandler() {
        setTab("Groups");
    }

    const tabs = {
        "Groups": <Main selectGroupHandler={selectGroupHandler} setAllGroups={setAllGroups}/>,
        "Edit": <GroupView selectedGroup={selectedGroup} selectHomeHandler={selectHomeHandler} setSelectedGroup={setSelectedGroup} allGroups={allGroups}/>,
    };

    return tabs[tab];
}
import React, {useContext} from 'react';
import InternsLogo from '../../../assets/logo_interns.png';
import {Modal, Button, Form, Image, Row, Col} from 'react-bootstrap';
import {notify} from "../../../utils/notify";
import {UserContext} from '../../../context/UserContext';
const axios = require("axios");

export default function DeleteIntern({setUpdateList, setShowDeleteInternModal, showDeleteInternModal, currentInternId, updateList}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);

    const handleDeleteIntern = async (e) => {
        e.preventDefault();
        
        if(!context.can("delete", "Interns")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        if(e.target.elements.test.value != "eliminar"){
            notify("La frase fue escrita de forma errónea, inténtalo nuevamente", "error");
            return;
        };
        
        try {
            await axios({
                url: `${URL}/interns`,
                method: "DELETE",
                data: {intern_id: currentInternId}
            });

            setShowDeleteInternModal(!showDeleteInternModal);
            setUpdateList(!updateList);
            notify("El practicante fue eliminado del sistema satisfactoriamente", "success");
        } catch (e) {
            notify(e.response.data.message, "error");
        };
    
    };

    return (
        <Modal className="unsuscribe-intern-modal" show={showDeleteInternModal} onHide={() => setShowDeleteInternModal(!showDeleteInternModal)} centered>
            <Modal.Header>
                <h5>Eliminar registro de practicante</h5>
            </Modal.Header>

            <Modal.Body>
                
                <Form onSubmit={handleDeleteIntern}>
                    
                    <Form.Group controlId="test">
                        <Form.Label style={{fontWeight: "600"}}>Escribe "eliminar" para confirmar</Form.Label>
                        <Form.Control className="description-input" type="text" autoComplete={"off"} placeholder="eliminar" required/>
                        <small>Al eliminar el registro, se borrará permanentemente del sistema</small>
                    </Form.Group>

                    <Row className="btn-container">
                        <Col md={4} className="col">
                            <Button className="cancel-btn" onClick={() => setShowDeleteInternModal(!showDeleteInternModal)}>
                                Cancelar
                            </Button>             
                        </Col>
                        <Col>
                            <Button className="delete-btn ml-2" type="submit">
                                Eliminar
                            </Button> 
                        </Col>
                    </Row>

                </Form>
            </Modal.Body>
        </Modal>
    );
};
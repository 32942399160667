import React, { useState, useEffect} from 'react';
import { BsTrash } from "react-icons/bs";
import {Button, Row, Col, Form, Spinner, InputGroup} from 'react-bootstrap';
import {notify} from '../../../utils/notify';
import plusIconSvg from '../../../assets/IconsCaintra/plusIcon.svg';
import Methods from '../../../utils/https';
const uuid = require("uuid");

export default function AmountUsers ()  {
    const [currentContacts, setCurrentContacts] = useState([])
    const [dispersion, setDispersion] = useState()
    const [dispersionNumber, setDispersionNumber] = useState("")
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);

    useEffect(() => {
        getContacts();      
    }, []);

    const getContacts = async () => {
        let aux = await Methods.getAmountContacts()
        setCurrentContacts(aux);
        let dispersionAux = await Methods.getDispersionNumber();
        setDispersionNumber(dispersionAux.number)
        setDispersion(dispersionAux)
    }

    const handleNewContact = () => {
        setCurrentContacts([...currentContacts, {_id: uuid.v1(), name: '', email: '',status: 'new'}]);
    }

    const deleteContact = async(id, stat) => {
        let contactsAux = currentContacts.filter(contact => id != contact._id)
        setCurrentContacts(contactsAux);
        if(stat) await Methods.deleteAmountContact(id)
    }

    const setValue = (e, contact, key) => {
        if(key === 'email' && !regexEmail.test(e.target.value) ){
            document.getElementById(e.target.id).classList.add('error');
        }else if(key === 'email' &&  document.getElementById(e.target.id).classList.contains('error')){
            document.getElementById(e.target.id).classList.remove('error');
        }
        contact[key] = e.target.value;
        if(contact.status != "new") contact.status = "modified";
        setCurrentContacts([...currentContacts]);
    }

    const modifyDispersion = (e) => {
        let dispersionAux = dispersion;
        dispersionAux.number= e.target.value;
        setDispersionNumber(e.target.value)
        dispersionAux.status = "modified";
        setDispersion(dispersionAux)
    }

    const saveContacts = async () => {
        let newContacts = currentContacts.filter(contact => contact.status === "new" && contact.name !== '' && contact.email !== '' && regexEmail.test(contact.email))
        let modifiedContacts = currentContacts.filter(contact => contact.status === "modified" && contact.name !== '' && contact.email !== '' && regexEmail.test(contact.email))
        try {
            if(dispersion.status === "modified") await Methods.updateDispersionNumber(dispersion)
            if(newContacts.length > 0)await Methods.addAmountContacts(newContacts);
            if(modifiedContacts.length > 0)await Methods.updateAmountContacts(modifiedContacts);
            notify(`Usuarios de montos guardados correctamente`, "success");
        } catch (error) {
            notify(error.response.data.message, "error");
        }
      
    }

    return (
        <>
            <div className='dispersion-row'>
                <p>Cantidad de dispersiones esperadas por periodo: </p>
                <Form.Group>
                    <Form.Control className='dispersion-text'  value={dispersionNumber} onChange={(e)=> {modifyDispersion(e)}} type='text' />
                </Form.Group> 
            </div>
            {currentContacts.map((contact, index) => {
                return  <Row key={index}>
                    <Col>
                        <Form.Group>
                            <Form.Control className='text-form'  value={contact.name} onChange={(e)=> {setValue(e, contact, 'name')}} type='text' placeholder="Nombre"/>
                        </Form.Group>   
                    </Col>
                    <Col md={5}>
                        <Form.Group>
                            <Form.Control className='text-form' id= {uuid.v1()} value={contact.email} onChange={(e)=> {setValue(e, contact, 'email')}} type="email" placeholder="Introduce el correo del contacto"/>
                        </Form.Group>   
                    </Col>

                    <Col md={1}>
                        <div className='center-container'>
                            <BsTrash color="black" style={{marginBottom: '10px'}} className="groups-icon" onClick={() => contact.status == "new" ?  deleteContact(contact._id, false) : deleteContact(contact._id, true) }/>                                           
                        </div>
                    </Col>
                </Row>
            })}


            <Row>
                <Col>
                    <Form.Group>
                        <div className='add-deparment-containter' onClick={()=> handleNewContact()}>
                            <p className='department-text'>Añadir correo <img src={plusIconSvg} size={25} /></p>
                        </div>
                    </Form.Group>
                </Col> 
            </Row>
            <div style={{'display': 'flex', 'flex-direction': 'row-reverse'}}>
                <Button className='save-button' onClick={()=> saveContacts()}>
                    Guardar
                </Button>
            </div>

        </>
    )
}


import React, { useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';
import { notify } from '../../../utils/notify';
const axios = require("axios");
const uuid = require("uuid");

export default function UploadExcel({mode, showUpdateExcel, setShowUpdateExcel, setInterns, universities, isITESMCompany,departments, costCentersTec, generalistTec, setAllPages, setCurrentInternsByPage,setInternsLength, context}){

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
    }, []);

    let internDataAuxITESM = ['curp', 'firstname', 'father_lastname', 'mother_lastname', 'contractStartDate', 'contractEndDate', 'contractApprovalDate', 'contractDuration',
    'email', 'scolarshipAmount','department_name', 'cost_center_name', 'directBoss_name','directBoss_email', 'projectName', 'entry_time', 'depurate_time', 'street', 'ext_number', 'int_number',  'zip_code', 'birthday', 'phone',
    'college','faculty', 'career','period_type', 'school_enrollment', 'grade', 'scholarship', 'clabe', '_group', 'linkedin', 'instagram', 'nomina', 'leaderTec', 'position', 'program', 'planType', 'insurancePayment', 'paymentType', 'berneficiary', 
    'percent', 'relationship', 'berneficiarySecond', 'percentSecond', 'relationshipSecond' ];

    let internRenewAuxITESM = [
        'curp',
        'firstname',
        'father_lastname',
        'mother_lastname',
        'school_enrollment',
        'contractStartDate', 
        'contractEndDate', 
        'contractApprovalDate',
        'contractDuration',
        'email',
        'scolarshipAmount', 
        'grade',
        'department_name',
        'cost_center_name',
        'directBoss_name',
        'projectName',
        'leaderTec',
        'clabe',
        'position',
        'program',
        'processed'
    ]

    const header = {
        'create':'Plantilla Altas Masivas',
        'renew': 'Plantilla Renovaciones Masivas',
        'unsubscribe' : 'Plantilla Bajas Masivas',
        'modify' : 'Plantilla Modificaciones Masivas'
    }


    const onChange = (e) => {
        setData([]);
        
        readXlsxFile(e.target.files[0]).then((rows) => {
          
            if(rows[2][0] != header[mode]){
                notify(`Se necesita el formato ${header[mode]} para esta sección`, "error");
                e.target.value = null;
                return;
            }
            if(rows[0][5] != context.user.company._id){
                notify(`La plantilla no corresponde a la compañia actual`, "error");
                e.target.value = null;
                return;
            }
            setData(rows.slice(4));
        })
    };

    const verifyRecord = (data) => {
        if(mode == 'create') {
            for(let i = 0; i < 4 ; i++) {
                if(data[i] != null && data[i] != "" && data[i] != " ") {
                    return true;
                }
            }
        } else if (mode == 'renew') {
            for(let i = 0; i < 1 ; i++) {
                if(data[i] != null && data[i] != "" && data[i] != " ") {
                    return true;
                }
            }
        } else if(mode == 'unsubscribe') {
            for(let i = 0; i < 1 ; i++) {
                if(data[i] != null && data[i] != "" && data[i] != " ") {
                    return true;
                }
            }
        }
        else if(mode == 'modify') {
            for(let i = 0; i < 4 ; i++) {
                if(data[i] != null && data[i] != "" && data[i] != " ") {
                    return true;
                }
            }
        }
        return false;
    }

    const getFormattedTime = (intern) => {
        const ENTRY = intern.entry_time;
        const OUT = intern.depurate_time;
        intern.entry_time =  moment.utc(new Date(ENTRY)).format("hh:mm a");
        intern.depurate_time = moment.utc(new Date(OUT)).format("hh:mm a");
        if(intern.entry_time == "Invalid date") {
            if(ENTRY.includes("p.m.")){
                intern.entry_time = ENTRY.substring(0,5) + " pm";
            } else {
                intern.entry_time = ENTRY.substring(0,5) + " am";
            }
        }
        
        if(intern.depurate_time == 'Invalid date') {
            if(OUT.includes("p.m.")){
                intern.depurate_time = OUT.substring(0,5) + " pm";
            } else {
                intern.depurate_time = OUT.substring(0,5) + " am";
            }
        }
    }

    const verifyDate = (intern) => {
        const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        if(intern.contractEndDate.match(regex)){
            let evaluatedDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            let evaluatedStartDate = moment(intern.contractStartDate, "DD/MM/YYYY");
            let totalDuration = Math.ceil(evaluatedDate.diff(evaluatedStartDate,"months", true));
            intern.contractDuration = totalDuration;
            intern.disabledDuration = true;
        }
    }

    const verifyApprovalDate = (intern) => {
        const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        if(intern.contractApprovalDate.match(regex)){
            intern.approveIntern = true;
            intern.isRetroactivePayment = true;
        }
    }

    const verifyDataItesm = (intern, indexValue, updateValueAux) => {
        let updateValue = updateValueAux?.toString();
        if(updateValue && updateValue != null){
            if(indexValue == 'department_name') {
                const DEPARTMENT = departments.find(item => item.title?.trim() === updateValue?.trim());
                intern.department = {
                    department_name: updateValue,
                    department_id: "",
                };
                if(DEPARTMENT){
                    intern.department = {
                        department_name: DEPARTMENT.title,
                        department_id: DEPARTMENT._id,
                    };
                } else {
                    intern.departmentError = true;
                }
            } else if(indexValue == 'cost_center_name') {
                const CC = costCentersTec.find(item => item.name?.trim() === updateValue?.trim());
                intern.cost_center = {
                    cost_center_name: updateValue,
                    cost_center_id: "",
                };
                if(CC){
                    intern.cost_center = {
                        cost_center_name: CC.name,
                        cost_center_id: CC._id,
                    };
                } else {
                    intern.ccError = true;
                }
            } else if(indexValue == 'directBoss_name') {
                const BOSSNAME = generalistTec.find(item => item.name?.trim() === updateValue?.trim());
                intern[indexValue] = updateValue;
                if(BOSSNAME){
                    intern.directBoss_email = BOSSNAME.email;
                } else {
                    intern.generalistError = true;
                }
            } else if(indexValue == 'college') {
                const COLLEGE = universities.find(item => item._name?.trim() === updateValue?.trim());
                intern[indexValue] = updateValue;
                if(COLLEGE){
                    intern.university = COLLEGE;
                } else {
                    intern.collegeError = true;
                }
            } else if(indexValue == 'faculty' && !intern.collegeError) {
                const FACULTY = intern.university ? intern.university.faculties.find(item => item._name?.trim() === updateValue?.trim()) : null;
                intern[indexValue] = updateValue;
                if(FACULTY){
                    intern.collegeFaculty = FACULTY;
                    intern.region = FACULTY.region;
                } else {
                    intern.facultyError = true;
                }
            } else if(indexValue == 'career'&& !intern.collegeError && !intern.facultyError) {
                const CAREER = intern.collegeFaculty ? intern.collegeFaculty.careers.find(item => item._name?.trim() === updateValue?.trim()) : null;
                intern[indexValue] = updateValue;
                if(!CAREER){
                    intern.careerError = true;
                }
            } else if(indexValue != 'directBoss_email') {
                intern[indexValue] = '';
            }
        }
    }


    const getDataCreate = async () => {
        let aux = [];
        let promises = [];
        let index = 1;
        let internDataAux = isITESMCompany ? internDataAuxITESM : ['curp', 'firstname', 'father_lastname', 'mother_lastname', 'contractStartDate', 'contractEndDate','contractApprovalDate' , 'contractDuration',
        'email', 'scolarshipAmount', 'projectName', 'entry_time', 'depurate_time', 'street', 'ext_number', 'int_number',  'zip_code', 'birthday', 'phone',
        'period_type', 'school_enrollment', 'grade', 'scholarship', 'clabe', '_group', 'linkedin', 'instagram', 'planType', 'insurancePayment', 'paymentType', 'berneficiary', 
        'percent', 'relationship', 'berneficiarySecond', 'percentSecond', 'relationshipSecond' ];

        let dateFormats = {
            'contractStartDate': true,
            'contractEndDate': true,
            'birthday': true,
            'contractApprovalDate': true
        }

        let verifyData = {
            'department_name': true, 
            'cost_center_name': true,
            'directBoss_name': true,
            'directBoss_email': true,
            'college': true,
            'faculty': true,
            'career': true
        }
 

        for (let record in data) {
            promises.push(createInternProcess(data[record], aux, verifyData, dateFormats, internDataAux, index));
            index++;
            if(promises.length > 300){
                await Promise.all(promises);
                promises = [];
            }
        }  
        await Promise.all(promises);
        setInternsLength(aux.length);
        aux.sort((a,b) => {
            return a.index > b.index;
        })
        let internArrayAux = createChunkArray(aux, 50);
        setInterns(internArrayAux);
        setCurrentInternsByPage(internArrayAux[0] || []);
        if (internArrayAux.length === 0) notify("Archivo vacío.","error");
        setAllPages(internArrayAux.length);
    }

    const createInternProcess = async(record, aux, verifyData, dateFormats, internDataAux, index) => {
        let intern;
        try{
            intern = {
                _id: uuid.v1(),
                isSelected: false,
                suburb: "",
                suburbs: [],
                university: null,
                collegeFaculty: null,
                college: "",
                faculty: "",
                career:"",
                department: "",
                cost_center: "",
                directBoss_name: "",
                directBoss_email: "",
                processed: false,
                error: "",
                disabledDuration: false,
                approveIntern: false,
                isRetroactivePayment: false,
                contractApprovalDate: '',
                departmentError: false,
                ccError: false,
                generalistError: false,
                collegeError: false,
                facultyError: false,
                careerError: false,
                index: index
            }
            

            for(let recordIndex in record){
                if(isITESMCompany && verifyData[internDataAux[recordIndex]]){
                    verifyDataItesm(intern,internDataAux[recordIndex],record[recordIndex]);
                }else if(dateFormats[internDataAux[recordIndex]]){
                    intern[internDataAux[recordIndex]] = moment.utc(record[recordIndex]).format("DD/MM/YYYY");
                }else{ 
                    let result = record[recordIndex] == null ? '' : record[recordIndex]
                    intern[internDataAux[recordIndex]] = result
                }
            }

            if(verifyRecord(record)){
                if (typeof intern.street === 'string') {
                    if(intern.street.trim() == "Av. Eugenio Garza Sada" && intern.ext_number == "2501" && intern.zip_code == "64849") {
                        intern.suburb = "Instituto Tecnológico de Estudios Superiores de Monterrey";
                    }
                }
                verifyDate(intern);
                if(isITESMCompany){
                    verifyApprovalDate(intern); 
                }
                getFormattedTime(intern);
                aux.push(intern);
            }
        }catch(error){
            notify('Error en el practicante con CURP: ' + intern.curp, 'error');
        }
        
    }

    const getDataRenew = async () => {
        let aux = [];
        let promises = [];
        let index = 1;
        let internRenewAux = isITESMCompany ? internRenewAuxITESM : [
            'curp', 
            'contractStartDate', 
            'contractEndDate',
            'contractApprovalDate',
            'contractDuration',
            'scolarshipAmount', 
            'grade', 
            'planType',
            'insurancePayment',
            'paymentType',
            'berneficiary', 
            'percent',
            'relationship',
            'berneficiarySecond',
            'percentSecond',
            'relationshipSecond'
        ]

        let dateFormats = {
            'contractStartDate': true,
            'contractEndDate': true,
            'contractApprovalDate': true,
        }

        for (let record of data) {
            promises.push(renewInternProcess(record, aux, dateFormats, internRenewAux, index));
            index++;
            if(promises.length > 1100){
                await Promise.all(promises);
                promises = [];
            }
        }
        await Promise.all(promises);
        setInternsLength(aux.length);
        aux.sort((a,b) => {
            return a.index > b.index;
        })
        let internArrayAux = createChunkArray(aux, 50);
        setInterns(internArrayAux);
        setCurrentInternsByPage(internArrayAux[0] || []);
        if (internArrayAux.length === 0) notify("Archivo vacío.","error");
        setAllPages(internArrayAux.length);
    }

    const renewInternProcess = (record, aux, dateFormats, internRenewAux, index) => {
        let verifyData = { 
            'cost_center_name': true,
            'directBoss_name': true,
            'directBoss_email': true,
            'department_name': true
        }
        let intern
        try{

            intern = {
                _id: uuid.v1(),
                error: "",
                data: "",
                currentContract: "",
                cost_center: "",
                directBoss_name: "",
                directBoss_email: "",
                disabledDuration: false,
                approveIntern: false,
                isRetroactivePayment: false,
                departmentError: false,
                index: index,
                processed: false
            }

            for(let recordIndex in record){
                if(isITESMCompany && verifyData[internRenewAux[recordIndex]]){
                    verifyDataItesm(intern,internRenewAux[recordIndex],record[recordIndex]);
                }else if(isITESMCompany && internRenewAux[recordIndex] == 'contractApprovalDate'){
                    intern[internRenewAux[recordIndex]] = moment.utc(record[recordIndex]).format("DD/MM/YYYY");
                }else if(dateFormats[internRenewAux[recordIndex]]){
                    intern[internRenewAux[recordIndex]] = moment.utc(record[recordIndex]).format("DD/MM/YYYY");
                } else if(internRenewAux[recordIndex] != 'contractApprovalDate'){ 
                    let result = record[recordIndex] == null ? '' : record[recordIndex]
                    intern[internRenewAux[recordIndex]] = result
                }
            }

            if(verifyRecord(record)){
                verifyDate(intern);
                verifyApprovalDate(intern); 
                aux.push(intern);
            }

        }catch(error){
            notify('Error en el practicante con CURP: ' + intern.curp, 'error');
        }
        
    }

    const getDataUnsubscribe = async () => {
        let aux = [];
        let intern = {};
        for (let record of data) {
            if(isITESMCompany){
                intern = {
                    _id: uuid.v1(),
                    curp: record[0],
                    school_enrollment: record[1],
                    firstname: record[2],
                    father_lastname: record[3],
                    mother_lastname: record[4],
                    unsuscribeDate: typeof(record[5]) == 'string' ? moment(record[5], 'DD/MM/YYYY').format("DD/MM/YYYY") : moment.utc(new Date(record[5])).format("DD/MM/YYYY"),
                    unsuscribeMotive: record[6],
                    processed: false,
                    error: "",
                }
            } else {
                intern = {
                    _id: uuid.v1(),
                    curp: record[0],
                    unsuscribeDate: typeof(record[5]) == 'string' ? moment(record[5], 'DD/MM/YYYY').format("DD/MM/YYYY") : moment.utc(new Date(record[5])).format("DD/MM/YYYY"),
                    unsuscribeMotive: record[2],
                    processed: false,
                    error: "",
                }
            }
            if(verifyRecord(record)){
                aux.push(intern);
            }
        }
        setInternsLength(aux.length);
        let internArrayAux = createChunkArray(aux, 50);
        setInterns(internArrayAux);
        setCurrentInternsByPage(internArrayAux[0] || []);
        if (internArrayAux.length === 0) notify("Archivo vacío.","error");
        setAllPages(internArrayAux.length);
    }

    const submitData = async () => {
        setLoading(true);
        if(mode == 'create') {
            await getDataCreate();
        } else if (mode == 'renew') {
            await getDataRenew();
        } else if(mode == 'unsubscribe') {
            await getDataUnsubscribe();
        }
        else if(mode == 'modify') {
            await getDataCreate();
        }
        setData([]);
        setLoading(false);
        setShowUpdateExcel(!showUpdateExcel)
    }


    const createChunkArray = (array, size) => {
        let chunks = [];
        while (array.length){
            chunks.push(array.splice(0, size));
        }
        return chunks;
    }


    return(
        <Modal className="prefacture-modals" onHide={() => {}} show={showUpdateExcel} centered>
            <Modal.Body>
                <Modal.Header>
                    <p>Subir Excel</p>
                    {data.length > 400 && <p> Tiempo de carga aproximado { Math.ceil(data.length * 0.027) } segundos</p> }
                </Modal.Header>
                <input type="file" accept=".xlsx, .xls, .csv" onChange={onChange} />
                {!loading && 
                <Button className="float-right ml-2" variant="primary" onClick={async () => {submitData()}} disabled={!data.length}>
                    Confirmar
                </Button>}
                {!loading && 
                <Button className="float-right" variant="danger" onClick={() => {setShowUpdateExcel(!showUpdateExcel); setData([]);}}>
                    Cancelar
                </Button>}
                {loading && <p className='float-right'>Subiendo...</p>}
            </Modal.Body>
        </Modal>
    );
};
import React, {useRef, useState, useEffect}from 'react';
import ReactApexChart from 'react-apexcharts';
import {DownloadDashboard} from '../components/DownloadDashboard';
import {Spinner} from 'react-bootstrap';
export const TotalInternsDashboard = ({
    periodSelected,
    moment,
    dataByMonths,
    periodSelectedToCompare,
    allMonthsData,
    sortByMonth,
    isLoadingData,
    isLoadingExcel,
    refsAux,
    totalInternsExcelConfigurations,
    monthsToDownload
}) => {
    const [seriesData, setSeriesData] = useState([0]);
    const [months, setMonths] = useState([moment().startOf('day').format('MMM YYYY')])
    const [isProcessingData, setIsProcessingData] = useState(true);
    const exportRef = useRef();
    const dateRef = useRef();
    const downloadRef = useRef();
    
    useEffect(() => {
        getDataByMonthAndCompany();
    }, [dataByMonths]);

    useEffect(() => {
        refsAux.componentRef.current[4] = exportRef.current;
        refsAux.dateRef.current[4] = dateRef.current;
        refsAux.downloadRef.current[4] = downloadRef.current;
    }, [exportRef.current, dateRef.current, downloadRef.current]);

    const getDataByMonthAndCompany = async() => {
        setIsProcessingData(true);
        let categoriesAux = [];
        let allInternsByMonth = []
        let [allCurrentCount] = getCountByMonth(dataByMonths.currentData, periodSelected);
        let [monthsToShow] = getDataByMonth(allCurrentCount, periodSelected, false);

        if(dataByMonths.dataToCompare.length){
            let [allStatusAuxToCompare] = getCountByMonth(
                dataByMonths.dataToCompare, periodSelectedToCompare
            );
            let [monthsToShowAux, indexToDelete] = getDataByMonth(allStatusAuxToCompare, periodSelectedToCompare, true);
            monthsToShow = sortByMonth(monthsToShow, monthsToShowAux);
        }
        

        monthsToShow.map((data => {
            allInternsByMonth.push(data.total);
            categoriesAux.push(data.monthName);
        }));

        if(!dataByMonths.dataToCompare.length<=0){
            for(let i =0 ; i<10 ; i++){
                allInternsByMonth.pop();
                categoriesAux.pop();
            }
        }
            
        
    
        categoriesAux.reverse();

        setSeriesData(allInternsByMonth.reverse());
        setMonths(categoriesAux);
        setIsProcessingData(false);
    }

    const getCountByMonth = (data, periodToEvaluate) => {

        let allInternsByMonth = [0, 0, 0, 0, 0, 0];
        
        for(let companyData of data){
            for(let companyMonthData of companyData){
                if(companyMonthData?.allStatus){
                    let total = companyMonthData.allStatus.reduce((acc, obj) => {
                        return acc + parseInt(obj.total || 0);
                    }, 0);
                    allInternsByMonth[(companyMonthData.month - periodToEvaluate)] += total;
                }
            }
        }

        return [allInternsByMonth];
    }

    const getDataByMonth = (data, periodSelected, validateZeroValues) => {
        let indexToDelete = [];
        let monthsToShow = [];
        let currentDate = moment().startOf('day').subtract(periodSelected - 1, 'month');
        [...Array.from({ length: 6 }, (_, i) => i + 1)].map((period) => {
            let pushValue = false;
            let isValidated = false;

            
            monthsToShow.push({
                monthName: moment(currentDate).subtract(period - 1, "month").format("MMM YYYY"),
                total: data[period - 1],
                timeStamp: parseInt(moment(currentDate).subtract(period - 1, "month").format('X')),
                month: moment(currentDate).subtract(period - 1, "month").format('M'),
                ...(isValidated && { isComparsionMonth: true})
            });
            
        });
        
        return [
          monthsToShow,
          indexToDelete
        ]
    }



    let options = {
        series: [
            {
                data: seriesData,
            },
        ],

        options: {
            chart: {
                height: 350,
                type: "bar",
                events: {
                    click: function (chart, w, e) {},
                },
                toolbar: {
                    show: false,
                },
            },
            colors: periodSelectedToCompare ? ["#0B5FF3", "#00AEEF"] : ["#00AEEF"],
            plotOptions: {
                bar: {
                    columnWidth: "45%",
                    distributed: true,
                },
            },
            grid: {
                borderColor: "#EBEBEB",
                strokeDashArray: 3,
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                categories: months,
                labels: {
                    style: {
                        fontSize: "12px",
                    },
                    ...(dataByMonths.dataToCompare.length && {
                        rotate: -45,
                        rotateAlways: true,
                    }),
                },
            },
            tooltip: {
                shared: false,
                x: {
                    formatter: function (val) {
                        return "Mes: " + val;
                    },
                },
                y: {
                    title: {
                        formatter: () => "",
                    },
                    formatter: function (val) {
                        return "Practicantes: " + val;
                    },
                },
            },
        },
    };


    return (
        <div className="dashboard-container total-interns" ref={exportRef}>
            <div className="title-icon-container">
                <div className="title-dashboard">
                    <div className="title-gender-container">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAB7klEQVR4nO2Zu07DMBSGv6RsTKyMXPoQPAEbIxWMCBA8ATB1YmHlUqDchJBgR2Jl5BWQmJmYWRAtQxI4RE6btHbauueTrFau7XP+P8eJ24KiKIqiKIqiKIqiKIqiKONEMOgELFAFloCw4Lx34G7Cfj6l8wTM9Dq5qGu+MetDBSwCNWCyw5gAWAemSsloyAiAI6BtaHXft0AANIBt0fchB/hsQAAcAhuirwkcy0G+GpCIl1e+CWwSlf4vPtwE0yR7fkv0JeJb6cG+VUAh8eCXAYXFg5stMAcsxAm9AK8OYqQJgTNgTfSdEpnRNs5wQAAcAN/8PWdbRDejisO4IXDB/+d7g+zvOXUxrm4riU6HjTbwgJtqC4CTVKxzOm9t6wYkhw2ZxGPcZN8tdishBC7Jf+UT9sT43X6TMIlPkjB9ZsuEELjKiNuNKvAWt/l+kjCVfbr8kgOJze0QAtdd4jonj3g51pYJIydezunXhJEVL+f2asLIi5drFDWhgifi5Vp5TagAN5bi9oxN8XLNbiZ4K16unWXC0IjPOuS4jPEcN5dxcyXm6sqbYqUrwfsrb4q5D3yJmJ/AjuO4xkTKFi+ZJvqbqxa/L5Uyy37oGHvxgyz7gbPCGIsHWGWMxSe/3CzHr/dERiiKoiiKoiiK5/wAJEv5xfN8xXIAAAAASUVORK5CYII="
                            className="arrow-rise"
                            size={25}
                        />
                        <p>Head count</p>
                    </div>
                    <p style={{ display: "none" }} ref={dateRef}>
                        {moment()
                            .startOf("day")
                            .subtract(periodSelected - 1, "month")
                            .format("MMMM YYYY")}
                    </p>
                    <DownloadDashboard
                        refDashboard={exportRef}
                        fileName={"Total_de_practicantes"}
                        excelConfigurations={totalInternsExcelConfigurations}
                        excelValues={allMonthsData}
                        dateRef={dateRef}
                        isLoadingExcel={isLoadingExcel}
                        periodSelected={periodSelected}
                        currentRef={downloadRef}
                        monthsToDownload={monthsToDownload}
                    />
                </div>
            </div>

            {!isProcessingData && !isLoadingData ?
                <ReactApexChart
                    options={options.options}
                    series={options.series}
                    type="bar"
                    height={500}
                /> : <Spinner 
                    animation="border" 
                    role="status" 
                    className={'loading-screen'}
                />
            }
        </div>
    );
}

import React from 'react'
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import infoIcon  from "../../../assets/IconsCaintra/infoIcon.svg";
import { notify } from '../../../utils/notify';
export const RedirectIntern = ({
    iconSrc,
    totalInterns,
    label,
    customStyles,
    redirectAction,
    customClass,
    isLoading
}) => {

    return (
        <div className={`redirect-container ${customClass || ''}`} style={customStyles}>
            <div className='info-container'>
                <img src={iconSrc} className='people-icon' width="30"/>
                <div className='title-container'>
                    <p>{label}<img src={infoIcon} className='info-icon' width="30"/></p>
                    {isLoading ?  
                        <Spinner 
                            animation="border"
                            style={{marginBottom: '10px'}}
                        /> : 
                        <h2>
                            {totalInterns}
                        </h2>
                    }
                </div>              
            </div>
            <div className='redirect-icon'>
                <FontAwesomeIcon 
                    className='pagination-icon'
                    icon={faChevronRight}
                    onClick={()=>{
                        if(totalInterns == 0) {
                            notify('No hay practicantes en el estatus seleccionado', 'error');
                        }else{
                            (redirectAction != undefined) && redirectAction()
                        }
                    }}
                />
            </div> 
        </div>
    )
}

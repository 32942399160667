import Methods from "../../../utils/https";
const axios = require("axios");

export const disperseInterns = async (internsToDisperse, payDate) => {
    const URL = process.env.REACT_APP_SOCKET_URL;
    let dispersedInterns = [];
    const dispersionEndDate = payDate[0].getDate() + "/" + payDate[0].getMonth() + "/" + payDate[0].getFullYear();
    const dispersionStartDate = payDate[1].getDate() + "/" + payDate[1].getMonth() + "/" + payDate[1].getFullYear();

    try {
        let name;
        let promises = [];

        for (let intern of internsToDisperse) {
            const data = {
                _id: intern._id,
                attributes: [
                    {
                        updateKey: "dispersionStatus",
                        updateValue: "Dispersado",
                    },
                ]
            };

            name = intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname;
            dispersedInterns.push(name);
            promises.push(Methods.updatePrefacture(data));

            if (promises.length > 300) {
                await Promise.all(promises);
                promises = [];
            }
        }

        if (promises.length) await Promise.all(promises);

        let notificationPeriod =
            dispersionStartDate + " - " + dispersionEndDate;

        let body = {
            dataToNotification: {
                interns: dispersedInterns,
                type: "Disperse",
                period: notificationPeriod,
            },
            contactEmail: "practicantes@caintra.org.mx",
            contactName: "Encargado Dispersión",
            subjet: "Dispersión",
        };

        await axios({
            url: `${URL}/disperse/notification`,
            method: "POST",
            data: body,
        });
    } catch (error) {
        throw new Error("Hubo un problema al dispersar los practicantes");
    }
};

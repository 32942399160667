import React from 'react'
import { RiSearch2Line } from 'react-icons/ri';
import { Form } from 'react-bootstrap';
export const SearchBar = ({
    search,
    setSearch   
}) => {
  return (
    <div className='search-container-icon'>
        <div onClick={ () => search() } className="search-icon">
            <RiSearch2Line/>
        </div>
        <Form.Group controlId="search-bar" className="search-container">
            <Form.Control
                type="text" 
                placeholder="Búsqueda de compañía" 
                autoComplete={"off"}
                value={search}
                onInput={ (e) => {
                    setSearch(e.target.value)
                }}
            />
        </Form.Group>
    </div>
  )
}

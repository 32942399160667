import React, {useState}from 'react'
import {Modal, Button, Form, Col, Row, Spinner} from 'react-bootstrap';

export const DownloadPdfModal = ({ showPdfModal, setShowPdfModal, renderPdfTime, createDocument}) => {
    const [isLoading, setIsLoading] = useState() 

    const handleDownloadPdf = async() => {
        setIsLoading(true)
        await createDocument();
        handleClose();
        setIsLoading(false)
    } 

    const handleClose = () => {
        setShowPdfModal(false)
    }


    return (
        <Modal className="new-cc-modal purchase-padding" onHide={() => { handleClose() }} show={showPdfModal} centered>
            <Modal.Body>
                <Form autoComplete={"off"}>
                    <Modal.Header>
                        <h3 className='purchase-order-title'>Descarga de PDF</h3>
                    </Modal.Header>
                    <Row>
                        <Col>
                            <Form.Label className='center-modal-text' style={{ marginTop: '30px' }}>
                                La descarga del pdf tendrá un aproximado de {renderPdfTime} segundos
                            </Form.Label>
                        </Col>
                    </Row>

                    <Modal.Body>
                        <div className='center-buttons'>
                            <Button className="float-right" variant="danger" type="button" onClick={() => handleClose()}>
                                Cancelar
                            </Button>

                            <Button className="float-right ml-2" variant="primary" onClick={() => handleDownloadPdf()}>
                                {isLoading ? <Spinner animation="border" /> : "Descargar"}
                            </Button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

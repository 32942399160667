import React, { useState } from "react";
import "./Help.css";
const $ENV = "localEnv";

export const Help = () => {

    return (
        <div className="help">
            <div className="title">
                <h4>Ayuda</h4>
            </div>
            <embed src={`https://caintrabucket-${($ENV == 'localEnv') ? 'testing' : $ENV}.s3.amazonaws.com/documents/manual_grow.pdf`} className="pdf-viewer"/>
        </div>
    );
};


export default {
    
    test(workbook, values, configuration, date, createdBy, excelName, periodSelected) {


        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()
        let config = configuration.filter(configAux => configAux != 'logisticActions');

        const data = {
            'cols': ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL"],
            'worksheetName': excelName,
            'headerText': excelName
        }


        const createBlueCell = (startCell, text, worksheetName) => {
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'013365'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'ffffff'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        const createWhiteCell = (startCell, text, key, worksheetName) => {
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){

            createBlueCell(
                "A1",
                "Fecha de generación:",
                worksheetName
            )
            createWhiteCell(
                "B1",
                date,
                "",
                worksheetName
            )


            createBlueCell(
                "A2",
                "Generado por:",
                worksheetName
            )
            createWhiteCell(
                "B2",
                createdBy,
                "",
                worksheetName
            )


            createBlueCell(
                "A3",
                "Periodo seleccionado:",
                worksheetName
            )
            createWhiteCell(
                "B3",
                periodSelected,
                "",
                worksheetName
            )
        }

        const createTableAvail = (worksheetName, valuesByCompany) =>{
            let cellNumber = 5;

            createHeaders(worksheetName)

            config.map((configItem, index) => {
                createBlueCell(
                    data.cols[index] + cellNumber,
                    configItem.label,
                    worksheetName
                )
            });


            if(valuesByCompany.length){
                valuesByCompany.map((item, index) => {
                    cellNumber++
                    config.map((configItem, indexCol) => {
                        let key = configItem.key;
                        createWhiteCell(
                            data.cols[indexCol] + cellNumber,
                            item[key],
                            '',
                            worksheetName
                        )
                    });
                })
            }
        }   


        for(let company of values){
            
            const data = {
                'cols': ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL"],
                'worksheetName': company.name,
                'headerText': company.name
            }

            const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
                properties: { tabColor: { argb: "FFB1CEF2" }, },
                views: [{ showGridLines: false }]
            })
    
            let width = []
            config.forEach(item => {
                width.push({ width: 40 });
            });
            
            worksheetPicoUsoInfra.columns = width;
    
            createTableAvail(worksheetPicoUsoInfra, company.values);
        }
        return workbook
    }
}

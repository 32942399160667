import React, {useState, createRef, useContext, useEffect} from 'react';
import {Table, Row, Col, Button, Dropdown, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCalendar, faCaretDown, faDownload, faHistory, faLessThanEqual, faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {motion} from 'framer-motion';
import moment from 'moment';
import {pdf} from '@react-pdf/renderer';
import ChangePeriod from './ChangePeriod.jsx';
import {saveAs} from 'file-saver';
import Records from './Records.jsx';
import UpdateIntern from './UpdateIntern.jsx';
import CompanyCredit from './CompanyCredit.jsx';
import CompanyDebit from './CompanyDebit.jsx';
import { CSVLink } from "react-csv";
import { notify } from '../../../utils/notify.js';
import {UserContext} from '../../../context/UserContext';
import Methods from '../../../utils/https';
import ExcelCSV from "../Excel/DownloadXLSX";
import ExcelDual from "../Excel/DownloadXLSXDual";
import ExcelCSVTEC from "../Excel/DownloadXLSXTEC";
import ExcelAdjust from "../Excel/DownloadAdjust";
import * as Excel from "exceljs";
import '../../../../node_modules/font-awesome/css/font-awesome.min.css'; 
import { IoCalendarClearOutline } from 'react-icons/io5';
import { BsDownload, BsClock, BsUpload } from 'react-icons/bs';
import TemplatePDF from '../pdf/app.jsx';
import { SendPrefactureToCompaq } from './SendPrefactureToCompaq'
import { PurchaseOrder } from './PurchaseOrder'
import { DownloadPdfModal } from './DownloadPdfModal'
import Upload from './Upload.jsx';
import { totalmem } from 'os';
const axios = require("axios");
let insuranceAmount =0;
let paidDual =0;
let allDays = 0;
let bkInterns = null;

export default function ListApprovedInterns({
    totalDual,
    setTotalDual,
    allApprovedInterns,
    setApprovedInterns,
    approvedInterns,
    firstPeriod,
    period,
    setPeriod,
    setUpdateList,
    updateList,
    isApproved,
    setCurrentTab,
    setCurrentInternId,
    isMonthly,
    setPrefactureData,
    prefactureData,
    isHistoricalSearch,
    setIsHistoricalSearch,
    setSpecificMonth,
    setSpecificYear,
    specificMonth,
    specificYear,
    historicalInterns,
    setHistoricalInterns,
    subPeriods,
    previousPeriods,
    isFirstSubPeriod,
    isSecondSubPeriod,
    isFirstPrevPeriod,
    isPrefactureDetail,
    setData,
    setSetData,
    isEditable,
    setPayDay,
    setPayDayLimit,
    actualPayDay,
    actualPayDayLimit,
    setIsConsolidationPrefacture,
    company,
    lastPeriod,
    isConsolidationPrefacture,
    setSendPrefactureAux,
    sendPrefactureAux,
    showRollBack,
    setAllPeriodValues,
    allPeriodValues
}) {   
    
    const filterOptions = [
        { value: 'status',            label: 'Estatus' },
        { value: 'firstname',         label: 'Nombres' },
        { value: 'fatherLastName',    label: 'Apellido Paterno' },
        { value: 'motherLastName',    label: 'Apellido Materno' },
        { value: 'bank',              label: 'Banco' },
        { value: 'clabe',             label: 'Cuenta' },
        { value: 'costCenter',        label: 'Centro de Costos' },
        { value: 'startDate',         label: 'Fecha de Inicio' },
        { value: 'endDate',           label: 'Fecha de Término' },
        { value: 'unsuscribeDate',    label: 'Fecha de Baja' },
        { value: 'daysToPay',         label: 'Días a pagar' },
        { value: 'scolarshipAmount',  label: 'Monto de Beca' },
        { value: 'adjustment',        label: 'Ajuste' },
        { value: 'companyInsurance',  label: 'Seguro Empresa' },
        { value: 'internsInsurance',  label: 'Seguro Practicante' },
        { value: 'amountToPay',       label: 'Monto a pagar' },
        { value: 'dueAmount',         label: 'Neto'},
        { value: 'adminG',            label: 'G. Admin'},
        { value: 'subtotal',          label: 'SubTotal'},
        { value: 'modelodual',        label: 'Cuota admin MD'},
        { value: 'vat',               label: 'IVA'},
        { value: 'internTotal',       label: 'Total de Practicantes'}
    ];
    const context = useContext(UserContext); 
    let periodo = context.user.company.payment_period;
    let fechaLimite = periodo !=="Catorcenal" ? "2024-05-15" : "2024-05-09";
    let tmp = period[1] ? moment(period[1]).format("YYYY-MM-DD") :"2024-05-15" ;
    let fechaCorte =  tmp!==undefined ? tmp : "2024-05-15";
    let fecha2 = fechaCorte;
    let fecha1 = fechaLimite;
    
    const pdfLib = require("../pdf/PDFTemplate");
    
    
    const [filters, setFilters] = useState(filterOptions);
    const [showChangePeriodModal, setShowChangePeriodModal] = useState(false);
    const idCompany = context.user.company._id;
    const [showUpdateInternModal, setShowUpdateInternModal] = useState(false);
    const [showCompanyCredit, setShowCompanyCredit] = useState(false);
    const [creditCompany, setCreditCompany] = useState(0);
    const [showCompanyDebit, setShowCompanyDebit] = useState(false);
    const [debitCompany, setDebitCompany] = useState(0);
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
          href="#"
          ref={ref}
          style={{backgroundImage: "none", backgroundColor: "#1B3769"}}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
            Información a consultar
            <FontAwesomeIcon style={{margin: "0 0 2px 10px"}} icon={faCaretDown}/>
        </Button>
    ));
    const tableRef = createRef();
    const [showRecords, setShowRecords] = useState(false);
    const [dataToBeUpdated, setDataToBeUpdated] = useState({intern: undefined, valueToBeUpdated: ""});
    const [startPeriod, endPeriod] = period;
    const formattedStartPeriod = moment(startPeriod).format("D-MM-YYYY");
    const formattedEndPeriod = moment(endPeriod).format("D-MM-YYYY");
    const [administrativeExpense, setAdministrativeExpense] = useState(context.user.company.administrative_expense);
    const [sortedField, setSortedField] = React.useState("curp");
    const [sortConfig, setSortConfig] = React.useState('ascending');
    const { showSendPrefacture, showPurchaseOrderModal } = sendPrefactureAux;
    const isCaintraUser = context.user.data.isCaintraUser;
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [renderPdfTime, setRenderPdfTime] = useState(0);
    const requirePurchaseOrder = context.user.company.requirePurchaseOrder == 'Sí' || false;
    let showDual = false;
    const [canAdjust, setCanAdjust] = useState(
        context.can('adjust', 'Prefacture') || false
    );
    const [showUpdateExcel, setShowUpdateExcel] = useState(false);


    useEffect(async () => {
        getAdministrativeExpense();
        await setCompanyCredits();
    });

    useEffect(() => {
        getData();
    }, []);
    
    const getData = async () => {
        bkInterns = approvedInterns;
        allDays =0;
        const SETTINGS = await Methods.getSettingsByIntern(context.user.data._id);

        //AÑADIR MODELO DUAL
        
        if(context.user.company.business_name.toUpperCase().includes("DUAL")  && Date.parse(fechaCorte) >= Date.parse(fechaLimite) /* && context.user.company.payment_period !=="Quincenal-Mensual C"  */)
        {
            SETTINGS.settings[0].filters.push({value: "modelodual", label: "Cuota admin MD"})
            showDual = true;
        }
        if(SETTINGS?.total > 0){
            setFilters(SETTINGS.settings[0].filters);
            setSortedField(SETTINGS.settings[0].sortedField);
            setSortConfig(SETTINGS.settings[0].sortConfig);
        } else {
            const DATA = {
                intern: context.user.data._id,
                filters: filters,
                sortConfig: sortConfig,
                sortedField: sortedField
            };
            await Methods.createSettings(DATA);
        }
        if(allApprovedInterns.length <= 10 ){
            paidDual = 1599.00;
        }
        else if(allApprovedInterns.length >10 && allApprovedInterns.length <= 24){
            paidDual = 1499.00;
        }
        else if(allApprovedInterns.length> 24){
            paidDual = 1299.00;
        }
    }

    const getAdministrativeExpense = async () => {
        const result = await Methods.getCompanies([idCompany]); 
        let admin_expense = result ? result[0]?.administrative_expense : "0";

        setAdministrativeExpense(admin_expense);
    }

    const getInternAdjusment = (intern, toShow) => {
        let value = 0;
        if(intern && intern.adjustments){
            let  startDate = moment(period[0]).format("DD/MM/YYYY");
            let endDate = moment(period[1]).format("DD/MM/YYYY");
            for(let adjutsment of intern.adjustments){
                if(adjutsment.startDate == startDate && adjutsment.endDate == endDate){
                    value = adjutsment.adjustment || 0;
                    intern.adjustment = value || 0;
                    intern.adjustmentDaysToPay = adjutsment.adjustmentDaysToPay || 0;
                }
            }
        }else{
            value = intern?.adjustment || 0;
        }
        if(toShow) value = currencyFormat(value)
        return value;
    }

    const setCompanyCredits = async () => {
        let value = []
        if(company && company.prefacturesAdjustments){
            let  startDate = moment(period[0]).format("DD/MM/YYYY");
            let endDate = moment(period[1]).format("DD/MM/YYYY");
            for(let adjutsment of company.prefacturesAdjustments){
                if(adjutsment.startDate == startDate && adjutsment.endDate == endDate){
                    value.push(adjutsment);
                }
            }
            if(value.length){
                await setCreditCompany(value[0].creditCompany);
                await setDebitCompany(value[0].debitCompany);
            }else{
                await setCreditCompany(0);
                await setDebitCompany(0);
            }
        }
    }

    const filterIncludes = (value) => {
        for(let index in filters){
            if(filters[index].value == value) return true;
        };

        return false;
    };

    const updateFilters = async (event, filter) => {
        const SETTINGS = await Methods.getSettingsByIntern(context.user.data._id);
        const value = filter.value;
        const _filters = filters;
        let data = {
            id: SETTINGS.settings[0]._id,
            key: "filters",
            value: []
        };

        if(filterIncludes(value)){
            const filtered = filters.filter((item) => item.value !== value);
            setFilters(filtered);
            data.value = filtered;
            await Methods.updateSetting(data);
            return;
        };

        setFilters([..._filters, filter]);
        data.value = [..._filters, filter];
        await Methods.updateSetting(data);

    };

    const calculateDualTotalPayment = () => {
        let total =0;
        let days;
        approvedInterns.map((intern, index) =>{
            
            if(intern.currentContractData && intern.currentContractData.internStartDate){
                 days = calculateDaysByIntern(intern,3);
            }
            else{
                days = intern.pay_days;
            }
            total +=(paidDual/30)*days;
           
            if(0 > total) total = 0;
            total = getFixedValue(total);
            //intern.total_amount = total;
        })
        setTotalDual(total);
        return total;
       
    };

    const calculateInternTotalPayment = (intern) => {
        
        let adjustmentAux = []; 
        for(let internAux of approvedInterns){
            if(internAux._id == intern._id) adjustmentAux.push(internAux);
        }
        let adjustmentTotal = getInternAdjusment(adjustmentAux[0]); 
        let scolarship = parseFloat(intern.scolarshipAmount) || 0,
              intern_insurance = parseFloat(intern.intern_insurance_amount),
              adjustment = parseFloat(adjustmentTotal);
        let days = calculateDaysByIntern(intern,4);
        let lastContractsPayments = parseFloat(intern.lastContractsPayments) || 0;
        let renewedContractsPayments = parseFloat(intern.renewedContractsPayments) || 0;
        scolarship = (scolarship/30) * (days - intern.daysToPayRenew - intern.lastContractsDays - intern.renewedContractsDays);
        let paymentRenew = parseFloat(intern.paymentRenew) || 0;
        allApprovedInterns && allApprovedInterns.map(_intern => {
            if(_intern._id === intern._id){
                _intern.dispersionAmount = (scolarship + paymentRenew - intern_insurance + lastContractsPayments + renewedContractsPayments) + adjustment;
            };
        });
        let total;
        if((intern.adjustmentDaysToPay || 0) == 0){
            total = (scolarship + paymentRenew  -  intern_insurance + lastContractsPayments + renewedContractsPayments) + adjustment
        }else{
            total = (scolarship + paymentRenew  -  intern_insurance + lastContractsPayments + renewedContractsPayments)
        }
        
        if(0 > total) total = 0;
        total = getFixedValue(total);
        intern.total_amount = total;

        return total
    };
    


    const calculateDaysByIntern = (intern, id) => {
        let periodEndDate = moment(period[1]);
        let periodStartDate = moment(period[0]);
        let plusDifference = periodEndDate.diff(periodStartDate, 'days') >= getDaysToPay() ? false : true;
        let unsuscribeDates = getUnsuscribeResult(intern, periodEndDate, periodStartDate);
        let days = unsuscribeDates != undefined ?  unsuscribeDates : getDaysToPay();
        let internEndDate = moment(getFormatedDate(intern.currentContractData?.internEndDate));
        let realDays = days;
        let difference = 0;
        let lastContractsDays = parseFloat(intern.lastContractsDays) || 0;
        let renewedContractsDays = parseFloat(intern.renewedContractsDays) || 0;
        if(!intern.currentContractData?.unsuscribeDate){
            difference = periodEndDate.diff(internEndDate, 'days') - (!plusDifference ? 1 : 0);
        }
        let internStartDate = moment(getFormatedDate(intern.currentContractData?.internStartDate));

        let differenceStart = (periodEndDate.diff(internStartDate, 'days') + (plusDifference ? 1 : 0));

        if (internStartDate > periodStartDate && difference > 0) {
            realDays = differenceStart - difference;
        } else if (
            internStartDate > periodStartDate &&
            (!intern.currentContractData?.unsuscribeDate ||
                (intern.currentContractData?.unsuscribeDate &&
                    moment(periodEndDate).isBefore(
                        intern.currentContractData?.unsuscribeDate
                    )))
        ) {
            realDays = differenceStart;
        } else if (difference > 0) {
            realDays = days - difference;
        }
        if(realDays == 0 && !intern.renewedContractsDays)
        {
            realDays = 0;
        } 
        intern.daysToPay =
            realDays +
            intern.daysToPayRenew +
            (intern.adjustmentDaysToPay || 0) +
            lastContractsDays +
            renewedContractsDays;
            if(Number.isInteger(intern.daysToPay)){

            allDays +=parseInt(intern.daysToPay);
        }
        return (intern.daysToPay);
    };
    
    const getFormatedDate = (dateString) => {
        if(!dateString) return
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            day = addZeroToDate(newDateArray[1]);
            month = addZeroToDate(newDateArray[2]);
            result = year + "/" + day + "/"+ month;
        } else {        
            const newDateArray = dateString.split("/");
            day = addZeroToDate(newDateArray[0]);
            month = addZeroToDate(newDateArray[1]);
            result = newDateArray[2] + "/" + month + "/" + day;
        }
        return result;
    }

    const addZeroToDate =  (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };

    const getUnsuscribeResult =  (intern, periodEndDate, periodStartDate) => {
        let result = undefined;
        if(intern.currentContractData?.unsuscribeDate){
            let internUnsuscribeDate = moment(intern.currentContractData.unsuscribeDate.substring(0, 10)).startOf('day');
            let internStartDateAux = moment(intern.currentContractData.contractStartDate, 'DD/MM/YYYY')
            let periodEndDateAux = moment(periodEndDate).startOf('day');
            if(moment(periodEndDateAux).diff(moment(internUnsuscribeDate), 'days') > 0 ){
                if(periodStartDate.isBefore(internStartDateAux)){
                    result = internUnsuscribeDate.diff(internStartDateAux, 'days') + 1;
                }else{
                    result = moment(internUnsuscribeDate).diff(moment(periodStartDate, 'DD/MM/YYYY'), 'days') + 1;
                }
            }
        }
        return result
    };


    const getCostsParams = () => {
        let totalAdministrativeExpense;
        let subtotalNoInsurance;
        let subtotalInsurance;
        let subtotalCredits;
        let _total;
        let totalCost;
        let insuranceCompanyData;
        let iva;
        let administrativeExpensePrefacture;
        let debit;
        let credit;

        if(!isPrefactureDetail){
            totalCost = 0;
            insuranceCompanyData = 0;
            allApprovedInterns.map(intern => {
                totalCost += calculateInternTotalPayment(intern);
                insuranceCompanyData += parseFloat(intern.company_insurance_amount);
            });
            totalAdministrativeExpense = parseFloat(totalCost) * (parseFloat(administrativeExpense)/100)
            subtotalNoInsurance = totalCost + totalAdministrativeExpense;
            subtotalInsurance = subtotalNoInsurance + parseFloat(debitCompany) + insuranceCompanyData;
            subtotalCredits = subtotalInsurance - parseFloat(creditCompany);
            _total = parseFloat(subtotalCredits + parseFloat(subtotalCredits * (0.16)));
            iva = parseFloat(subtotalCredits * (0.16));
            administrativeExpensePrefacture = administrativeExpense;
            debit = debitCompany;
            credit = creditCompany;
            if(setData){
                const prefacture = {
                    totalCost : getFixedValue(totalCost),
                    administrativeExpense : getFixedValue(administrativeExpense),
                    totalAdministrativeExpense : getFixedValue(totalAdministrativeExpense),
                    subtotalNoInsurance : getFixedValue(subtotalNoInsurance),
                    insuranceCompanyData : getFixedValue(insuranceCompanyData),
                    debitCompany : getFixedValue(debitCompany),
                    subtotalInsurance : getFixedValue(subtotalInsurance),
                    creditCompany : getFixedValue(creditCompany),
                    subtotalCredits : getFixedValue(subtotalCredits),
                    iva: getFixedValue(iva),
                    _total : getFixedValue(_total)
                }
                setPrefactureData(prefacture);
                setSetData(!setData);
            }
        }else{
            totalCost = prefactureData.totalCost|| 0;
            totalAdministrativeExpense = prefactureData.totalAdministrativeExpense || 0;
            subtotalNoInsurance = prefactureData.subtotalNoInsurance || 0;
            subtotalInsurance = prefactureData.subtotalInsurance || 0;
            subtotalCredits = prefactureData.subtotalCredits || 0;
            insuranceCompanyData = prefactureData.insuranceCompanyData || 0;
            _total = prefactureData._total || 0;
            iva = prefactureData.iva || 0;
            administrativeExpensePrefacture = prefactureData.administrativeExpense;
            debit = prefactureData.debitCompany;
            credit = prefactureData.creditCompany;
        }
        if(allApprovedInterns.length <= 10 ){
            paidDual = 1599.00;
        }
        else if(allApprovedInterns.length >10 && allApprovedInterns.length <= 24){
            paidDual = 1499.00;
        }
        else if(allApprovedInterns.length> 24){
            paidDual = 1299.00;
        }
        let admin = administrativeExpense || administrativeExpensePrefacture;
        if(context.user.company.business_name.toUpperCase().includes("DUAL")  && Date.parse(fechaCorte) >= Date.parse(fechaLimite) /* && context.user.company.payment_period !=="Quincenal-Mensual C"  */){
            return {
                "Monto a dispersar": totalCost,
                [`${admin == 0  ? 10 : admin }   % G. Admin`] : (admin == 0 ? totalCost * 0.10 : (totalCost *(admin/100) ) ) ,
                "Subtotal sin seguro": admin != 0 ? totalCost + (totalCost * admin/100):  (totalCost *0.10) + totalCost  ,
                "Seguro empresa": insuranceCompanyData == 0 ? 0 : insuranceCompanyData,
                [`Cuota admon Modelo Dual`]: calculateDualTotalPayment(),
                "Subtotal con seguro": admin != 0 ? totalCost+insuranceCompanyData+(calculateDualTotalPayment())+ + (totalCost * admin/100) :  (totalCost *0.10) + totalCost+insuranceCompanyData+(calculateDualTotalPayment()),
                [`Descuento(GA+Seguro) ${(admin == 0 ? (totalCost * 0.10).toFixed(2) : ((totalCost * (admin/100) ).toFixed(2)))} + ${(insuranceCompanyData == 0 ? 0 : insuranceCompanyData.toFixed(2)- 0.01)}`]: (admin == 0 ? totalCost * 0.10 : (totalCost * (admin/100))) + (insuranceCompanyData == 0 ? 0 : insuranceCompanyData- 0.01),
                "Subtotal con abonos": ((admin != 0 ? totalCost+insuranceCompanyData+calculateDualTotalPayment() + (totalCost * admin/100) :  (totalCost *0.10) + totalCost+insuranceCompanyData+calculateDualTotalPayment()) - ((admin == 0 ? totalCost * 0.10 : (totalCost * (admin/100))) + (insuranceCompanyData == 0 ? 0 : insuranceCompanyData-0.01))),
                "I.V.A": Math.floor(((totalCost  * 0.16) + ((calculateDualTotalPayment()) * 0.16)) * 100) / 100,
                "Total Final":  (totalCost + (calculateDualTotalPayment()) + (((totalCost  * 0.16) + ((calculateDualTotalPayment()) * 0.16)) * 100) / 100)
            };

        }
                
        return {
            "Monto a dispersar": totalCost,
            [`${administrativeExpensePrefacture} % G. Admin`] : totalAdministrativeExpense,
            "Subtotal sin seguro": subtotalNoInsurance,
            "Seguro empresa": insuranceCompanyData,
            "Adeudos empresa": debit,
            "Subtotal con seguro": subtotalInsurance,
            "Abono empresa": credit,
            "Subtotal con abonos": subtotalCredits,
            "I.V.A": iva,
            "Total Final": _total
        };
        
    };
    
    const handleOpenUpdateValues = (setValue, value) => {
        if(context.userRole == "Administrador Caintra" || context.can("update", "Prefacture")) {
            setValue(value);
        }else {
            notify("No tienes acceso a esta funcionalidad, solicítalo a un adiminstrador", "error");
        }
    }

    const handleOpenUpdateInternModal = (intern, valueToBeUpdated) => {
        if(!context.can("update", "Prefacture")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };
        
        if(isApproved){
            notify("Esta prefactura ha sido autorizada previamente, por lo tanto no se pueden hacer cambios", "error");
            return;
        };

        if(valueToBeUpdated == "all"){
            setCurrentInternId(intern._id);
            setCurrentTab("verifyInternDocs");
        }
        else{
            setDataToBeUpdated({intern: intern, valueToBeUpdated: valueToBeUpdated, currentPeriod: period});
            setShowUpdateInternModal(!showUpdateInternModal);
        }
        
    };

    const getFormatedPayment = (payment_period)=>{
        const formatedPayment = {
            "CATORCENAL": 'Catorcenal',
            "QUINCENAL": 'Quincenal',
            "QUINCENAL MENSUAL":'Quincenal-Mensual',
            "MENSUAL 15": 'Mensual 15',
            "MENSUAL 30": 'Mensual 30',
            "MENSUAL 15 BAT": 'Mensual 15 BAT'
        }
        return formatedPayment[payment_period] || payment_period;
    }

    const getDaysToPay = () => {
        const type = getFormatedPayment(context.user.company.payment_period);
        const params = {
            "Catorcenal": 14,
            "Quincenal": 15,
            "Quincenal 2": 15,
            "Mensual": 30,
            "Mensual 15": 30,
            "Mensual 30": 30,
            "Quincenal-Mensual": 15,
            "Quincenal-Mensual C": 15,
            "Mensual 15 BAT": 30
        };
        
        return params[type];
    };

    const sortColumn = (sortedField, sortConfig) => {
        if(sortedField !== null) {
            let sortedInterns = approvedInterns;
            sortedInterns.sort((a, b) => {
                if (a[sortedField] < b[sortedField]) {
                    return sortConfig === 'ascending' ? -1 : 1;
                }
                if (a[sortedField] > b[sortedField]) {
                    return sortConfig === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            setApprovedInterns(sortedInterns);
        }
    }

    const updateSort = async (key,direction) => {
        const SETTINGS = await Methods.getSettingsByIntern(context.user.data._id);
        let data = {
            id: SETTINGS.settings[0]._id,
            key: "sortedField",
            value: ""
        };
        data.value = key;
        await Methods.updateSetting(data);
        data.key = "sortConfig"
        data.value = direction;
        await Methods.updateSetting(data);
    };

    const requestSort = async key => {
        let direction = 'ascending';
        if (sortedField === key && sortConfig === 'ascending') {
            direction = 'descending';
        }
        setSortConfig(direction);
        setSortedField(key);
        updateSort(key,direction);
        sortColumn(key,direction);
    }

    const getFixedValue = (value) => {
        return parseFloat(parseFloat(value || 0).toFixed(2));
    }

    const currencyFormat = (value) => {
        return "$ " + (parseFloat(value) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    };

    const createDocument = async () => {  
        try{

            let companyInfo = {
                companyName: context.user.company.business_name,
                companySocialReason: context.user.company.social_reason,
                clientNumber: context.user.company.client_number,
                userName: context.user.data.fullName
            } 
    
            const doc = <TemplatePDF interns={approvedInterns} dates={period} prefacture={prefactureData} companyInfo={companyInfo} isConsolidationPrefacture={isConsolidationPrefacture} lastPeriod={lastPeriod} filters={filters}/>
            const asPdf = pdf([]);
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            saveAs(blob, "prefactura.pdf");
        }catch(error){
            notify('Ocurrio un error al generar el archivo pdf', 'error')
        }

    }


    return(
        <div style={{height: "70vh", overflowX: "hidden"}}>
            <Row>
                <Col md={12} xs={12} sm={12}>
                    <div className="options">

                        <Dropdown drop={"down"}>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"></Dropdown.Toggle>
                            <Dropdown.Menu className="filters-menu">
                                <div>
                                    {filterOptions.map((filter, key) => {
                                        return(
                                            <Form.Check type="checkbox" key={key} label={filter.label} onChangeCapture={(event) => updateFilters(event, filter)} checked={filterIncludes(filter.value)}/>
                                        );
                                    })}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button className="default-button" onClick={() => setShowChangePeriodModal(!showChangePeriodModal)}>
                            <IoCalendarClearOutline/>
                            Cambiar período
                        </Button>

                        {periodo !=="Quincenal-Mensual C" && <Button className="default-button" onClick={async () =>{
                            let seconds = ((approvedInterns.length / 45 ) || 0).toFixed(0)
                            setRenderPdfTime(seconds);
                            if(seconds >= 20 ){
                                setShowPdfModal(true);
                            }else{
                                await createDocument()
                            }
                        }
                        }>
                            <BsDownload/>
                            PDF
                        </Button>
                        }

                        {!context.user.company.isITESMCompany && !context.user.company.business_name.toUpperCase().includes("DUAL")   && <Button className="default-button" onClick={() =>{
                            const workbook = new Excel.Workbook();
                            ExcelCSV.test(
                                workbook,
                                "Módulo de Prefactura",
                                approvedInterns, 
                                period,
                                getDaysToPay(),
                                prefactureData,
                                filters,
                                {
                                    companyName: context.user.company.business_name,
                                    companySocialReason: context.user.company.social_reason,
                                    clientNumber: context.user.company.client_number,
                                    userName: context.user.data.fullName
                                },
                                isConsolidationPrefacture,
                                lastPeriod,
                                context.getDepartmentField(),
                            );
                            workbook.xlsx.writeBuffer().then((data) => {
                                const blob = new Blob([data], {
                                  type:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                });
                                saveAs(blob, `Prefactura ${context.user.company.rfc} ${formattedStartPeriod} - ${formattedEndPeriod}.xlsx`);
                              });
                        }
                        }>
                            <BsDownload/>
                            EXCEL
                        </Button>
                        }

                        {/* {!context.user.company.isITESMCompany && context.user.company.business_name.toUpperCase().includes("DUAL") && periodo !=="Quincenal-Mensual C"   && <Button className="default-button" onClick={() =>{
                            const workbook = new Excel.Workbook();
                            console.log(!context.user.company.business_name.toUpperCase().includes("DUAL"));

                            ExcelCSV.test(
                                workbook,
                                "Módulo de Prefactura",
                                approvedInterns, 
                                period,
                                getDaysToPay(),
                                prefactureData,
                                filters,
                                {
                                    companyName: context.user.company.business_name,
                                    companySocialReason: context.user.company.social_reason,
                                    clientNumber: context.user.company.client_number,
                                    userName: context.user.data.fullName
                                },
                                isConsolidationPrefacture,
                                lastPeriod,
                                context.getDepartmentField(),
                            );
                            workbook.xlsx.writeBuffer().then((data) => {
                                const blob = new Blob([data], {
                                  type:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                });
                                saveAs(blob, `Prefactura ${context.user.company.rfc} ${formattedStartPeriod} - ${formattedEndPeriod}.xlsx`);
                              });
                        }
                        }>
                            <BsDownload/>
                            EXCEL
                        </Button>
                        } */}

                        {!context.user.company.isITESMCompany && context.user.company.business_name.toUpperCase().includes("DUAL") && /* periodo !=="Quincenal-Mensual C" && */ <Button className="default-button" onClick={() =>{
                            const workbook = new Excel.Workbook();
                            console.log(context.user.company.business_name.toUpperCase().includes("DUAL"));
                            ExcelDual.test(
                                workbook,
                                "Módulo de Prefactura",
                                approvedInterns, 
                                period,
                                getDaysToPay(),
                                prefactureData,
                                filters,
                                {
                                    companyName: context.user.company.business_name,
                                    companySocialReason: context.user.company.social_reason,
                                    clientNumber: context.user.company.client_number,
                                    userName: context.user.data.fullName
                                },
                                isConsolidationPrefacture,
                                lastPeriod,
                                context.getDepartmentField(),
                            );
                            workbook.xlsx.writeBuffer().then((data) => {
                                const blob = new Blob([data], {
                                  type:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                });
                                saveAs(blob, `Prefactura ${context.user.company.rfc} ${formattedStartPeriod} - ${formattedEndPeriod}.xlsx`);
                              });
                        }
                        }>
                            <BsDownload/>
                            EXCEL MD
                        </Button>}
                        {context.user.company.isITESMCompany && !context.user.company.business_name.toUpperCase().includes("DUAL") && <Button className="default-button" onClick={() =>{
                            const workbook = new Excel.Workbook();
                            console.log(context.user.company);
                            ExcelCSVTEC.test(
                                workbook,
                                "Módulo de Prefactura",
                                approvedInterns, 
                                period,
                                getDaysToPay(),
                                prefactureData,
                                filters,
                                {
                                    companyName: context.user.company.business_name,
                                    companySocialReason: context.user.company.social_reason,
                                    clientNumber: context.user.company.client_number,
                                    userName: context.user.data.fullName
                                },
                                isConsolidationPrefacture,
                                lastPeriod,
                                context.getDepartmentField(),
                            );
                            workbook.xlsx.writeBuffer().then((data) => {
                                const blob = new Blob([data], {
                                  type:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                });
                                saveAs(blob, `Prefactura ${context.user.company.rfc} ${formattedStartPeriod} - ${formattedEndPeriod}.xlsx`);
                              });
                        }
                        }>
                            <BsDownload/>
                            EXCEL
                        </Button>}

                        <Button className="default-button" onClick={() => setShowRecords(!showRecords)}>
                            <BsClock/>
                            Historial
                        </Button>
                        {(canAdjust && !showRollBack()) && 
                            <Button className="default-button" onClick={() => {
                                const workbook = new Excel.Workbook();
                                ExcelAdjust.test(
                                    workbook,
                                    {
                                        companyName: context.user.company.business_name,
                                        companyRFC: context.user.company.rfc,
                                        companyId: context.user.company._id,
                                    },
                                    approvedInterns
                                );
                                workbook.xlsx.writeBuffer().then((data) => {
                                    const blob = new Blob([data], {
                                    type:
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                    });
                                    saveAs(blob, "AjustesPlantilla.xlsx");
                                });
                            }}>
                                <BsDownload />
                                Plantilla
                            </Button>
                        }
                        {(canAdjust && !showRollBack()) && 
                            <Button className="default-button" onClick={() => {setShowUpdateExcel(!showUpdateExcel)}}>
                                <BsUpload />
                                Carga
                            </Button>
                        }
                    </div>
                </Col>
            </Row>

            <div className="table-container">
                <div>
                    <Table hover ref={tableRef}>
                        <thead>
                            <tr className='header-height'>
                                <th># Total: {approvedInterns ? approvedInterns.length : 0}</th>
                                {filterIncludes("status") && <th>Estatus</th>}
                                {filterIncludes("firstname") && <th> Nombres <FontAwesomeIcon  size="lg" rotation={90}  icon={faExchangeAlt} pull={'right'}  onClick={() => requestSort('firstname')}/> </th>}
                                {filterIncludes("fatherLastName") && <th>Apellido Paterno <FontAwesomeIcon  size="lg" rotation={90} pull={'right'}  icon={faExchangeAlt} onClick={() => requestSort('father_lastname')}/></th>}
                                {filterIncludes("motherLastName") && <th>Apellido Materno  <FontAwesomeIcon  size="lg" rotation={90} pull={'right'}  icon={faExchangeAlt} onClick={() => requestSort('mother_lastname')}/> </th>}
                            </tr>
                        </thead>

                        <tbody>
                            {approvedInterns ? approvedInterns.map((intern, index) => {
                                if(isEditable && intern.currentContractData?.internStartDate){
                                return (
                                    <tr key={index}>
                                        {<td className='set-text-center'>{index + 1}</td>}
                                        {filterIncludes("status")           && <td className='set-text-center'><FontAwesomeIcon icon={faCircle} style={{color: intern.statusColor}}/></td>}
                                        {filterIncludes("firstname")        && <td><a href="#" onClick={() => {handleOpenUpdateInternModal(intern, "all")}}>{intern.firstname}</a></td>}
                                        {filterIncludes("fatherLastName")   && <td>{intern.father_lastname}</td>}
                                        {filterIncludes("motherLastName")   && <td>{intern.mother_lastname}</td>}
                                    </tr>
                                )
                                }else{
                                    return (
                                        <tr key={index}>
                                            {<td className='set-text-center'>{index + 1}</td>}
                                            {filterIncludes("status")           && <td className='set-text-center'><FontAwesomeIcon icon={faCircle}  style={{color: intern.statusColor}}/></td>}
                                            {filterIncludes("firstname")        && <td>{intern.firstname}</td>}
                                            {filterIncludes("fatherLastName")   && <td>{intern.father_lastname}</td>}
                                            {filterIncludes("motherLastName")   && <td>{intern.mother_lastname}</td>}
                                        </tr>
                                    )
                                }

                            }): <tr></tr> } 
                            <tr className="spacer"></tr>
                        </tbody>
                    </Table>
                </div>
                <div className='table-scroll'>
                    <Table hover ref={tableRef}>
                        <thead>
                            <tr className='header-height'>
                                {filterIncludes("bank") && <th>Banco</th>}
                                {filterIncludes("clabe") && <th>Cuenta</th>}
                                {filterIncludes("costCenter") && <th>Centro de Costos <FontAwesomeIcon  size="lg" rotation={90} pull={'right'}  icon={faExchangeAlt} onClick={() => requestSort('cost_center_name')}/> </th>}
                                {filterIncludes("startDate") && <th>Fecha de Inicio</th>}
                                {filterIncludes("endDate") && <th>Fecha de Término</th>}
                                {filterIncludes("unsuscribeDate") && <th>Fecha de Baja</th>}
                                {filterIncludes("daysToPay") && <th>Días</th>}
                                {filterIncludes("scolarshipAmount") && <th>Monto de Beca</th>}
                                {filterIncludes("adjustment") && <th>Ajustes</th>}
                                {filterIncludes("companyInsurance") && <th>Seguro Empresa</th>}
                                {filterIncludes("modelodual") && <th>Cuota admin MD</th>}
                                {filterIncludes("internsInsurance") && <th>Seguro Practicante</th>}
                                {filterIncludes("amountToPay") && <th>Monto a dispersar</th>}
                            </tr>
                        </thead>

                        <tbody>
                            {approvedInterns ? approvedInterns.map((intern, index) => {
                                if(isEditable && intern.currentContractData && intern.currentContractData.internStartDate){
                                return (
                                    <tr key={index}>
                                        {filterIncludes("bank")             && <td>{intern.bank}</td>}
                                        {filterIncludes("clabe")            && <td className="text-left" style={{color:  intern.accountColorStatus ? intern.accountColorStatus : '#000000'}}> {intern.clabe || "N/A"} </td>}
                                        {filterIncludes("costCenter")       && <td>{intern.cost_center_name != "" ? intern.cost_center_name : "N/A"}</td>}
                                        {filterIncludes("startDate")        && <td>{intern.contractStartDate}</td>}
                                        {filterIncludes("endDate")          && <td>{intern.contractEndDate}</td>}
                                        {filterIncludes("unsuscribeDate")   && !isCaintraUser && <td>{intern.currentContractData && intern.currentContractData.unsuscribeDate ? moment(intern.currentContractData.unsuscribeDate.substring(0, 10)).format('DD/MM/YYYY') : "N/A"}</td>}
                                        {filterIncludes("unsuscribeDate")   && isCaintraUser && <td><a href="#" onClick={() => handleOpenUpdateInternModal(intern, "contractEndDate")}>{intern.currentContractData && intern.currentContractData.unsuscribeDate ? moment(intern.currentContractData.unsuscribeDate.substring(0, 10)).format('DD/MM/YYYY') : "N/A"}</a></td>}
                                        {filterIncludes("daysToPay")        && <td>{calculateDaysByIntern(intern)}</td>}
                                        {filterIncludes("scolarshipAmount") && <td>{ currencyFormat(intern.scolarshipAmount) }</td>}
                                        {filterIncludes("adjustment")       && <td><a href="#" onClick={() => handleOpenUpdateInternModal(intern, "adjustment")}>{ getInternAdjusment(intern, true) }</a></td>}
                                        {filterIncludes("companyInsurance") && <td>{ currencyFormat(intern.company_insurance_amount) }</td>}
                                        {filterIncludes("modelodual") && context.user.company.business_name.toUpperCase().includes("DUAL")  && Date.parse(fechaCorte) >= Date.parse(fechaLimite) /* && context.user.company.payment_period !=="Quincenal-Mensual C" */ && <td>{ currencyFormat((paidDual/30)*calculateDaysByIntern(intern, 1)) }</td>}
                                        {filterIncludes("internsInsurance") && <td>{ currencyFormat(intern.intern_insurance_amount) }</td>}
                                        {filterIncludes("amountToPay")      && <td>{ currencyFormat(calculateInternTotalPayment(intern)) }</td>}
                                    </tr>
                                )
                                }else{
                                    return (
                                        <tr key={index}>
                                            {filterIncludes("bank")             && <td>{intern.bank}</td>}
                                            {filterIncludes("clabe")            && <td className="text-left" style={{color:  intern.accountColorStatus ? intern.accountColorStatus : '#000000'}}>{intern.clabe}</td>}
                                            {filterIncludes("costCenter")       && <td>{intern.cost_center_name != "" ? intern.cost_center_name : "N/A"}</td>}
                                            {filterIncludes("startDate")        && <td>{intern.contractStartDate}</td>}
                                            {filterIncludes("endDate")          && <td>{intern.contractEndDate}</td>}
                                            {filterIncludes("unsuscribeDate")   && <td>{intern.currentContractData && intern.currentContractData.unsuscribeDate ?  moment(intern.currentContractData.unsuscribeDate.substring(0, 10)).format('DD/MM/YYYY') : "N/A"}</td>}
                                            {filterIncludes("daysToPay")        && <td>{intern.pay_days}</td>}
                                            {filterIncludes("scolarshipAmount") && <td>{ currencyFormat(intern.scolarshipAmount) }</td>}
                                            {filterIncludes("adjustment")       && <td>{ currencyFormat(intern.adjustment) }</td>}
                                            {filterIncludes("companyInsurance") && <td>{ currencyFormat(intern.company_insurance_amount) }</td>}
                                            {filterIncludes("modelodual") && <td>{ currencyFormat((paidDual/30)*intern.pay_days) }</td>}
                                            {filterIncludes("internsInsurance") && <td>{ currencyFormat(intern.intern_insurance_amount) }</td>}
                                            {filterIncludes("amountToPay")      && <td>{ currencyFormat(intern.total_amount) }</td>}
                                        </tr>
                                    )
                                }

                            }): <tr></tr> } 
                            <tr className="spacer"></tr>
                        </tbody>
                    </Table>
                </div>
            </div>


            {((allApprovedInterns.length > 0 && !isPrefactureDetail) || (isPrefactureDetail)) &&
            <div style={{marginTop: "20px"}}>
                <h4>Desglose</h4>
                <Row>
                    <Col>
                        <Table hover className="costs-table">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th></th>
                                    <th>Monto</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Object.entries(getCostsParams()).slice(0, Object.entries(getCostsParams()).length/2).map(([key, value], index) => {
                                    if((key == "Abono empresa" || key == "Adeudos empresa") && (isEditable && isCaintraUser)){    
                                        return(
                                            <>
                                                <tr key={index} className="no-spacer">
                                                    <td style={{color: "#1B3769"}}>
                                                        <a href="" onClick={e => {
                                                        e.preventDefault();
                                                        if(key=="Abono empresa"){
                                                            handleOpenUpdateValues(setShowCompanyCredit, true);
                                                        } else {
                                                            handleOpenUpdateValues(setShowCompanyDebit, true);
                                                        }
                                                        }}>{key} </a>
                                                    </td>
                                                    <td></td>
                                                    <td style={{color: "#1B3769", fontWeight: "bold"}}>$ {parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                                <tr className="spacer"></tr>
                                            </>
                                        )
                                    } else {                          
                                        return(
                                            <>
                                                <tr key={index} className="no-spacer">
                                                    <td>{key}</td>
                                                    <td></td>
                                                    <td>$ {parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                                <tr className="spacer"></tr>
                                            </>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>
                        <Table hover className="costs-table">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th></th>
                                    <th>Monto</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Object.entries(getCostsParams()).slice(Object.entries(getCostsParams()).length/2).map(([key, value], index) => {
                                    if((key == "Abono empresa" || key == "Adeudos empresa") && (isEditable && isCaintraUser)){    
                                        return(
                                            <>
                                                <tr key={index} className="no-spacer">
                                                    <td style={{color: "#1B3769"}}>
                                                        <a href="" onClick={e => {
                                                        e.preventDefault();
                                                        if(key=="Abono empresa"){
                                                            handleOpenUpdateValues(setShowCompanyCredit, true);
                                                        } else {
                                                            handleOpenUpdateValues(setShowCompanyDebit, true);
                                                        }
                                                        }}>{key} </a>
                                                    </td>
                                                    <td></td>
                                                    <td style={{color: "#1B3769", fontWeight: "bold"}}>$ {parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                                <tr className="spacer"></tr>
                                            </>
                                        )
                                    } else {                          
                                        return(
                                            <>
                                                <tr key={index} className="no-spacer">
                                                    <td>{key}</td>
                                                    <td></td>
                                                    <td>$ {parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                                <tr className="spacer"></tr>
                                            </>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        }
            
            <Records showRecords={showRecords} setShowRecords={setShowRecords} />

            <ChangePeriod 
                isMonthly={isMonthly} 
                showChangePeriodModal={showChangePeriodModal}
                setShowChangePeriodModal={setShowChangePeriodModal}
                firstPeriod={firstPeriod} 
                period={period} setPeriod={setPeriod} 
                setUpdateList={setUpdateList} 
                updateList={updateList}
                idCompany={idCompany}
                isHistoricalSearch={isHistoricalSearch}
                setIsHistoricalSearch={setIsHistoricalSearch}
                setSpecificMonth={setSpecificMonth}
                setSpecificYear={setSpecificYear}
                specificMonth={specificMonth}
                specificYear={specificYear}
                historicalInterns={historicalInterns}
                setHistoricalInterns={setHistoricalInterns}
                subPeriods={subPeriods}
                previousPeriods={previousPeriods}
                isFirstSubPeriod={isFirstSubPeriod}
                isSecondSubPeriod={isSecondSubPeriod}
                isFirstPrevPeriod={isFirstPrevPeriod}
                setPayDay={setPayDay}
                setPayDayLimit={setPayDayLimit}
                actualPayDay={actualPayDay}
                actualPayDayLimit={actualPayDayLimit}
                setIsConsolidationPrefacture={setIsConsolidationPrefacture}
                setAllPeriodValues={setAllPeriodValues}
                allPeriodValues={allPeriodValues}
            />

            <UpdateIntern
                dataToBeUpdated={dataToBeUpdated}
                showUpdateInternModal={showUpdateInternModal}
                setShowUpdateInternModal={setShowUpdateInternModal}
                updateList={updateList}
                setUpdateList={setUpdateList}
                setData={setData}
                setSetData={setSetData}
            />

            <CompanyCredit 
                showCompanyCredit={showCompanyCredit} 
                setShowCompanyCredit={setShowCompanyCredit} 
                creditCompany={creditCompany}
                setCreditCompany={setCreditCompany}
                setData={setData}
                setSetData={setSetData}
                period={period}
                company={company}
            />
            <CompanyDebit 
                showCompanyDebit={showCompanyDebit} 
                setShowCompanyDebit={setShowCompanyDebit} 
                debitCompany={debitCompany}
                setDebitCompany={setDebitCompany}
                setData={setData}
                setSetData={setSetData}
                period={period}
                company={company}
            />

            <SendPrefactureToCompaq
                approvedInterns={approvedInterns}
                showSendPrefacture={showSendPrefacture}
                sendPrefactureAux = {sendPrefactureAux}
                setSendPrefactureAux={setSendPrefactureAux}
                updateList={updateList}
                setUpdateList={setUpdateList}
                requirePurchaseOrder={requirePurchaseOrder}
                idCompany={idCompany}
                company={company}
                period={period}
                lastPeriod={lastPeriod}
                isConsolidationPrefacture={isConsolidationPrefacture}
                context={context}
            />

            <PurchaseOrder
                approvedInterns={approvedInterns}
                showPurchaseOrderModal={showPurchaseOrderModal}
                sendPrefactureAux = {sendPrefactureAux}
                setSendPrefactureAux={setSendPrefactureAux}
                updateList={updateList}
                setUpdateList={setUpdateList}
                idCompany={idCompany}
                company={company}
                period={period}
                lastPeriod={lastPeriod}
                isConsolidationPrefacture={isConsolidationPrefacture}
            />

            <Upload
                showUpdateExcel={showUpdateExcel}
                setShowUpdateExcel={setShowUpdateExcel}
                currentPeriod={period}
                approvedInterns={approvedInterns}
                updateList={updateList}
                setUpdateList={setUpdateList}
                setSetData={setSetData}
            />

            <DownloadPdfModal
                showPdfModal={showPdfModal}
                setShowPdfModal={setShowPdfModal}
                renderPdfTime={renderPdfTime}
                createDocument={createDocument}
            />
        </div>
    );
}
import React from 'react'
import {Document, Text, Page, View, StyleSheet, Image, BlobProvider} from '@react-pdf/renderer';

export const EthicCodeFile = (props) => {
    const ethicLabels = [
        {
            labelText: 'Reconocemos la dignidad de las personas y las tratamos con respeto y justicia.',
            squareColor: '#034087',
            paddingSquareNumber: '05 10 05 10'
      },
      {
            labelText: 'Tratamos a todos con equidad y evitamos cualquier tipo de discriminación.',
            squareColor: '#0069b3',
            paddingSquareNumber: '05 10 05 10'
      },
        {
            labelText: 'Actuamos de manera íntegra, honesta, responsable, objetiva, congruente e imparcial.',
            squareColor: '#007731',
            paddingSquareNumber: '05 10 05 10',
            paddingText: '5 40 10 0'
      },
        {
            labelText: 'Reconocemos y respetamos la propiedad intelectual y el mérito de los demás.',
            squareColor: '#009440',
            paddingSquareNumber: '05 10 05 10'
      },
        {
            labelText: 'Evitamos conflictos de interés de cualquier tipo y, en caso de presentarse, los informamos ante las instancias correspondientes.',
            squareColor: '#51ac30',
            paddingSquareNumber: '05 10 05 10',
          paddingText: '5 40 10 0'
      },
        {
            labelText: 'Asumimos como compromiso la transparencia en la información, y respetamos la confidencialidad de los asuntos que la Institución así determina.',
            squareColor: '#602b85',
            paddingSquareNumber: '05 10 05 10',
          paddingText: '5 40 10 0'
      },
        {
            labelText: 'Utilizamos los recursos de manera responsable, austera y eficiente.',
            squareColor: '#8a2f87',
            paddingSquareNumber: '05 10 05 10'
      },
        {
            labelText: 'Protegemos el medio ambiente.',
            squareColor: '#c04308',
            paddingSquareNumber: '05 10 05 10'
      },
        {
            labelText: 'Buscamos el beneficio de la Institución por encima del beneficio personal.',
            squareColor: '#e26206',
            paddingSquareNumber: '05 10 05 10'
      },
        {
            labelText: 'Cumplimos con las leyes, reglamentos y políticas que rigen nuestra actividad a nivel institucional,nacional e internacional.',
            squareColor: '#ec8e00',
            paddingSquareNumber: '05 10 05 3',
          paddingText: '5 40 10 0'	
      },
    ];

    const styles = StyleSheet.create({
        ethicText: {
            margin: 8,
            fontSize: 11,
            textAlign: "justify",
            lineHeight: 1.2,
        },
        imageFooterContainer: {
            position: "absolute",
            bottom: "0px",
            width: "100%",
        },
        imageFooter: {
            bottom: "0px",
            width: "100%",
        },
        alignRigth: {
            textAlign: "right",
        },
        table: {
            display: "table",
            width: "100%",
            padding: "0 20",
        },
        tableRow: {
            width: "100%",
            margin: "auto",
            flexDirection: "row",
        },
        tableCol: {
            width: "100%",
            heigth: "40px",
        },
        tableColSquareBox: {
            width: "20%",
        },
        squareBox: {
            width: "30px",
            heigth: "30px",
            color: "white",
            margin: "0 auto",
            padding: "05 10 05 10",
        },
        squateText: {
            fontSize: "20px",
            margin: "0 auto",
        },
        ethicDescription: {
            fontSize: 12,
            diplay: "flex",
            alignItems: "stretch",
        },
        textFooter: {
            margin: 8,
            fontSize: 13,
            textAlign: "justify",
            lineHeight: 1.1,
            paddingTop: "10",
        },
        textFooterContainer: {
            padding: "0 44",
        },
    });
    return (    
        <Document>
            <Page style={styles.body} size="A4" wrap>
                <Image style={{ padding: '20 20 0 20' }} src='https://caintrabucket-testing.s3.amazonaws.com/mailImages/ethicHeaderDocument.png' />
                <View style={styles.table}>
                    {ethicLabels.map((item, index) => {
                        return (
                            <View style={{ ...styles.tableRow, marginTop: '13px' }}>
                                <View style={styles.tableColSquareBox}>
                                    <View style={{
                                        ...styles.squareBox,
                                        backgroundColor: item.squareColor,
                                        padding: item.paddingSquareNumber
                                    }}>
                                        <Text style={styles.squateText}>{index + 1}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableCol}>
                                    <View>
                                        <Text style={{
                                            ...styles.ethicDescription,
                                            padding: item.paddingText || '12 40 10 0'
                                        }}>
                                            {item.labelText}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )
                    })
                    }

                </View>
                <View style={styles.textFooterContainer}>
                    <Text style={styles.textFooter}>
                        Carta compromiso
                    </Text>
                    <Text style={styles.textFooter}>
                        Yo {props.fullName} como colaborador del Tecnológico de Monterrey, me comprometo a cumplir y hacer
                        cumplir los lineamientos contenidos en el Código de Ética y a <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                        convertirlos en parte de mi vida </Text> y quehacer cotidianos.
                    </Text>
                </View>

                <View style={{ paddingTop: '10' }}>
                    <Text style={{textAlign: 'center', fontSize: '13'}}>
                        {props.internStartDate}
                    </Text>
                </View>

                <View style={styles.imageFooterContainer}>
                    <Image style={styles.imageFooter} src='https://caintrabucket-testing.s3.amazonaws.com/mailImages/ethicFooterDocument.png' />
                </View>
            </Page>
        </Document>
    )
}

import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export default function EditItemModal(props) {
    const [error, setError] = React.useState(false);

    return <Modal className="new-cc-modal" show={props.displayModal} onHide={() => {props.hideModal(); setError(false);}} centered>
        <Modal.Header>
            <p>Cambiar Nombre</p>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={async event => {
                event.preventDefault();
                try {
                    await props.editItem(props.selectedItem._id, event.target.elements.name.value);
                } catch (error) {
                    setError(error.message);
                }
            }}>
                <Form.Group controlId="name">
                    <Form.Label>Nombre del centro de costos</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={props.selectedItem.name}
                        required
                    />
                    {error && <p className='groups-error-message'>{error}</p>}
                </Form.Group>

                <Button className="float-right ml-2" variant="primary" type="submit">
                    Registrar
                </Button>
                <Button className="float-right" variant="danger" type="button" onClick={props.hideModal}>
                    Cancelar
                </Button>
            </Form>
        </Modal.Body>

    </Modal>
};
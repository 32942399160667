import React, {useState} from 'react';
import {Modal, Button, Form, Image, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import {Combobox} from 'react-widgets';
import Methods from '../../../utils/https';

export default function AssignRole({roles, currentUser, openAssignRole, setOpenAssignRole, updateRoles, setUpdateRoles}){
    const [isLoading, setIsLoading] = useState(false);

    const assignRole = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        const label = e.target.elements.role.value;
        let permissions;
        for(let index of roles){
            if(index.label == label){
                permissions = index._permissions;
            }
        }
        const data = {
            _id: currentUser._id,
            attributes: [
                {
                    updateKey: "_role",
                    updateValue: label, 
                    expressionAttributeName: "#_role"
                },
                {
                    updateKey: "_permissions",
                    updateValue: permissions, 
                    expressionAttributeName: "#_permissions"
                }
            ]
        };

        await Methods.updateUser(data);
        setIsLoading(false);
        setOpenAssignRole(false);
        setTimeout(() => setUpdateRoles(!updateRoles), 550);
    };

    return(
        <Modal className="prefacture-modals" show={openAssignRole} onHide={() => setOpenAssignRole(!openAssignRole)} centered>

            <Modal.Body>
                <Form onSubmit={assignRole} autoComplete={"off"}>
                    <p>Asignar rol a {currentUser.firstName} {currentUser.fatherLastName}</p>

                    <Form.Group style={{marginTop: "20px"}} controlId="role">
                        <Form.Control as="select">                           
                            {roles.map(role => {
                                return <option selected={currentUser._role === role.label}>{role.label}</option>
                            })}
                        </Form.Control>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                       {isLoading ? <Spinner animation="border" /> : "Confirmar"}
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setOpenAssignRole(!openAssignRole)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React, {useContext, useState} from 'react';
import {Modal, Image, Form, Button, Row, Col, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { UserContext } from '../../../context/UserContext';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function UpdateFacultyModal ({displayUpdateFacultyModal, setDisplayUpdateFacultyModal, currentFacultyId, updateList, setUpdateList, currentFaculty, currentUniversity}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(UserContext);

    const updateFaculty = async (e) => {
        e.preventDefault();
        setIsLoading(true);    
        const facultyName = e.target.elements.facultyName.value
        const contactName = e.target.elements.contactName.value
        const fatherLastName = e.target.elements.fatherLastName.value
        const motherLastName = e.target.elements.motherLastName.value
        const email = e.target.elements.email.value
        const phone = e.target.elements.phone.value

        const faculty = {
            attributes: [],
            id_faculty: currentFacultyId
        };

        if(facultyName.trim() == ""){
            notify(`El nombre del campo ${context.getFacultadField()} no puede estar vacío`, "error");
            setIsLoading(false);    
            return;
        };

        if(contactName.trim() == ""){
            notify("El nombre del contacto no puede estar vacío", "error");
            setIsLoading(false);    
            return;
        };

        if(fatherLastName.trim() == ""){
            notify("Los apellidos no pueden estar vacíos", "error");
            setIsLoading(false);    
            return;
        };

        if(motherLastName.trim() == ""){
            notify("Los apellidos no pueden estar vacíos", "error");
            setIsLoading(false);    
            return;
        };

        if(email.trim() == ""){
            notify("El email no puede estar vacío", "error");
            setIsLoading(false);    
            return;
        };

        if(phone.trim() == ""){
            notify("El Telefono no puede estar vacío", "error");
            setIsLoading(false);    
            return;
        };

        
        if(currentUniversity == "Universidad TecMilenio" || currentUniversity == "Instituto Tecnológico y de Estudios Superiores de Monterrey" ) {
            const region = e.target.elements.region.value;
            if(region.trim() == ""){
                notify("La región no puede estar vacía", "error");
                setIsLoading(false);
                return;
            };
            region != currentFaculty.region && faculty.attributes.push({updateKey: "region", updateValue: region, expressionAttributeName: "#region"});
        }
        
        
        facultyName != currentFaculty._name && faculty.attributes.push({updateKey: "_name", updateValue: facultyName, expressionAttributeName: "#_name"});
        contactName != currentFaculty.contactName && faculty.attributes.push({updateKey: "contactName", updateValue: contactName});
        fatherLastName != currentFaculty.fatherLastName && faculty.attributes.push({updateKey: "fatherLastName", updateValue: fatherLastName});
        motherLastName != currentFaculty.motherLastName && faculty.attributes.push({updateKey: "motherLastName", updateValue: motherLastName});
        email != currentFaculty.email && faculty.attributes.push({updateKey: "email", updateValue: email});
        phone != currentFaculty.phone && faculty.attributes.push({updateKey: "phone", updateValue: phone});

        if(faculty.attributes.length == 0){
            notify("No hay ningún cambio por realizar", "error");
            setIsLoading(false); 
            return;
        }
        try {
            await axios({
                url: `${URL}/faculties`,
                method: "PATCH",
                data: faculty
            });

            setIsLoading(false);    
            notify("Registro actualizado exitosamente", "success");
            setUpdateList(!updateList);
            setDisplayUpdateFacultyModal(!displayUpdateFacultyModal);
        } catch (error) {
            setIsLoading(false);    
            error.response && notify(error.response.data.message, "error");
        };
    };

    return(
        <Modal className="new-cc-modal" show={displayUpdateFacultyModal} onHide={() => setDisplayUpdateFacultyModal(!displayUpdateFacultyModal)} centered>
            <Modal.Header>
                <p>Actualizar Registro</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={updateFaculty} autoComplete={"off"}>
                    <Form.Group controlId="facultyName">
                        <Form.Label>Nombre de la {context.getFacultadField()}</Form.Label>
                        <Form.Control type="text" defaultValue={currentFaculty._name} required/>
                    </Form.Group>
                    <Form.Group controlId="contactName">
                        <Form.Label>Nombre(s) del Contacto</Form.Label>
                        <Form.Control type="text" defaultValue={currentFaculty.contactName}/>
                    </Form.Group>
                    {currentUniversity == "Instituto Tecnológico y de Estudios Superiores de Monterrey" &&
                        <Form.Group controlId='region'>
                            <Form.Label>Nombre de region</Form.Label>
                            <Form.Control as="select" required defaultValue={currentFaculty.region} >
                                <option>Online</option>
                                <option>EGADE Business School y Escuela de Gobierno y Transfromación Pública</option>
                                <option>Centro/Sur</option>
                                <option>Ciudad de México</option>
                                <option>Monterrey</option>
                                <option>Occidente</option>
                                <option>Vicepresidencia de PrepaTec y Desarrollo Regional</option>
                            </Form.Control >
                        </Form.Group>
                    }
                    {currentUniversity == "Universidad TecMilenio" &&
                        <Form.Group controlId='region'>
                            <Form.Label>Nombre de region</Form.Label>
                            <Form.Control as="select" required defaultValue={currentFaculty.region}>
                                <option>Online</option>
                                <option>Centro/Sur</option>
                                <option>Ciudad de México</option>
                                <option>Monterrey</option>
                                <option>Occidente</option>
                            </Form.Control >
                        </Form.Group>
                    }
                    <Form.Group controlId="fatherLastName">
                        <Form.Label>Apellido Paterno</Form.Label>
                        <Form.Control type="text" defaultValue={currentFaculty.fatherLastName}/>
                    </Form.Group>
                    <Form.Group controlId="motherLastName">
                        <Form.Label>Apellido Materno</Form.Label>
                        <Form.Control type="text" defaultValue={currentFaculty.motherLastName}/>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" defaultValue={currentFaculty.email}/>
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="number" defaultValue={currentFaculty.phone}/>
                    </Form.Group>
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Actualizar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayUpdateFacultyModal(!displayUpdateFacultyModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React, {useState} from 'react'
import { BsTrash } from "react-icons/bs";
import {Button, Row, Col, Form, Table, Spinner, InputGroup} from 'react-bootstrap';
import {notify} from '../../../utils/notify';
import plusIconSvg from '../../../assets/IconsCaintra/plusIcon.svg';
import Methods from '../../../utils/https';

const uuid = require("uuid");

export const FactureContacts = ({currentContacts, setCurrentContacts, canEditCompany, companyId}) => {
    
    
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const handleNewContact = () => {
        if(currentContacts.length == 10){
            notify('Solo se pueden agregar hasta 10 contactos adicionales', 'error');
            return;
        }
        setCurrentContacts([...currentContacts, {_id: uuid.v1(), name: '', email: ''}]);
    }

    const deleteContact = async(id) => {
        let contactsAux = currentContacts.filter(contact => id != contact._id)
        setCurrentContacts(contactsAux);
    }

    const setValue = (e, contact, key) => {
        contact[key] = e.target.value;
        setCurrentContacts([...currentContacts]);
    }

    return (
        <>
            <Row>
                <Col>
                    <p>Correos para facturación</p>
                </Col>
            </Row>

            {currentContacts.map((contact, index) => {
                return  <Row key={index}>
                    <Col>
                        <Form.Group>
                            <Form.Control readOnly={!canEditCompany} value={contact.name} onChange={(e)=> {setValue(e, contact, 'name')}} type='text' placeholder="Nombre"/>
                        </Form.Group>   
                    </Col>
                    <Col md={5}>
                        <Form.Group>
                            <Form.Control readOnly={!canEditCompany} value={contact.email} onChange={(e)=> {setValue(e, contact, 'email')}} type="email" placeholder="Introduce el correo del contacto"/>
                        </Form.Group>   
                    </Col>

                    <Col md={1}>
                        <div className='center-container'>
                            <BsTrash color="red" style={{marginBottom: '10px'}} className="groups-icon" onClick={() => deleteContact(contact._id)}/>                                           
                        </div>
                    </Col>
                </Row>
            })}


            <Row>
                <Col>
                    <Form.Group>
                        <div className='add-deparment-containter' onClick={()=> handleNewContact()}>
                            <p className='department-text'>Añadir correo <img src={plusIconSvg} size={25} /></p>
                        </div>
                    </Form.Group>
                </Col> 
            </Row>
        </>
    )
}

import React, { useState, useEffect, createContext } from "react";
import { Auth } from 'aws-amplify';
import { notify } from '../utils/notify';
import { decrypt, encrypt } from '../utils/crypto';
import Methods from '../utils/https';
const axios = require("axios");

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [token, setToken] = useState(localStorage.getItem("token") || undefined);
    const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail") || undefined);
    const [userFirstName, setUserFirstname] = useState(localStorage.getItem("userFirstname") || undefined);
    const [user, setUser] = useState(undefined);
    const [userRole, setUserRole] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [universities, setUniversities] = useState([]);
    const [reloadUniversities, setReloadUniversities] = useState(true);
    const [isLoadingUniversities, setIsLoadingUniversities] = useState(false);
    const [dashboardRedirectData, setDashboardRedirectData] = useState({
        interns: [],
        isRedirected: false,
        internStatusSelected: ''
    });

    useEffect(() => {
        token ? onLoad() : logout();
    }, []);

    const [tecMode, setTecMode] = useState(false);

    useEffect(() => {
        if (user && user.company.isITESMCompany) {
            setTecMode(true);
        } else {
            setTecMode(false);
        }
    }, [user]);

    useEffect(() => {
        getUniversities();
    }, [reloadUniversities]);

    const getUniversities = async () => {
        setIsLoadingUniversities(true);
        let response = await axios({
            url: `${URL}/universities`,
            method: "PUT",
        });

        const url = response.data.data;
        let dataFile = await axios(
            {
                url: url,
                method: 'GET',
                responseType: 'application/json'
            }
        );
        setUniversities(dataFile.data);
        setIsLoadingUniversities(false);
    }

    const getDepartmentField = (capitalize = false, plural = false) => {
        if (tecMode == true) {
            return "VP-VR";
        } else {
            return `${capitalize ? "D" : "d"}epartamento${plural ? "s" : ""}`
        }
    }

    const getFacultadField = (capitalize = false, plural = false) => {
        if (tecMode == true) {
            return `${capitalize ? "C" : "c"}ampus`;
        } else {
            return `${capitalize ? "F" : "f"}acultad${plural ? "es" : ""}`;
        }
    }

    const getGeneralistaField = (capitalize = false, plural = false) => {
        if (tecMode == true) {
            return `${capitalize ? "G" : "g"}eneralista`;
        } else {
            return `${capitalize ? "L" : "l"}íder${plural ? "es" : ""}`;
        }
    }

    const getRolesByCompany = async (companyId) => {
        

        let res = await axios({
            url: `${URL}/roles`,
            method: "PUT",
            data: { companyId: companyId }
        });

        return res.data ?  JSON.parse(decrypt(res.data)) : undefined
    } 

    const setRolePermissions = async (currentUser) => {
        
        let res = await axios({
            url: `${URL}/roles`,
            method: "PUT",
            data: { companyId: currentUser.principalCompany}
        });
       
        if(res.data){
            let roles =  JSON.parse(decrypt(res.data));
            console.log(roles);
            for (let role of roles) {
                if (role.label == currentUser._role) {
                    currentUser.isGroupRole = role.isGroupRole || false;
                }
            }
        }
        
    }

    const verifyRole = async (currentUser) => {

        let _roles = await getRolesByCompany(currentUser.principalCompany);

        let userPermissions = JSON.stringify(currentUser._permissions)
        if (_roles && _roles.length) {
            for (let role of _roles) {
                let rolePermissions = JSON.stringify(role._permissions)
                if (role.label == currentUser._role && userPermissions != rolePermissions) {
                    currentUser._permissions = role._permissions;
                    currentUser.isGroupRole = role.isGroupRole || false;
                    const userData = {
                        _id: currentUser._id,
                        attributes: [
                            {
                                updateKey: "_permissions",
                                updateValue: role._permissions,
                                expressionAttributeName: "#_permissions"
                            }
                        ]
                    };
                    await Methods.updateUser(userData);
                    break;
                }
            }
        }
        return currentUser;
    }

    const setCaintraUser = async (currentUser, rfc) => {

        let userData = {
            _id: currentUser._id,
            attributes: [{
                updateKey: "isCaintraUser",
                updateValue: true,
            }]
        };

        if (!currentUser.advisedCompanies) {
            userData.attributes.push({ updateKey: "advisedCompanies", updateValue: [rfc] });
        }
        await Methods.updateUser(userData);
    }

    const onLoad = async (email, jwt, hidden, forceLoad) => {
        try {
            if(forceLoad) {
                setIsLoading(true);
            }
            
            let _user;
            let _email;
            let _company;
            let _response;

            email ? _email = email : _email = decrypt(userEmail).slice(1, -1);

            await Auth.currentSession();

            let res = await axios({
                url: `${URL}/users`,
                method: "PUT",
                data: encrypt({ email: _email })
            });

            if (res.data) {
                _response = decrypt(res.data);
                _user = JSON.parse(_response);
            };

            res = await axios({
                url: `${URL}/companies`,
                method: "PUT",
                data: encrypt({ _id: _user.principalCompany })
            });
            getUniversities();

            if (res.data) {
                _response = decrypt(res.data);
                _company = JSON.parse(_response);
                console.log(_company)
                if (_company.isCaintraCompany) {

                    _user.isCaintraUser = true;
                    await setCaintraUser(_user, _company.rfc);
                    let userAux = await verifyRole(_user);
                    _user = userAux;
                
                }else{
                    await setRolePermissions(_user);
                }
                console.log(_user)
                await getCompaniesByType(_user, _company);

                if (!_company.processFinished) {
                    window.location.replace(`/register/company/${_company._id}`);
                    logout("Tu compañía no ha terminado su proceso de llenado de datos");
                    return;
                };

                if (!_company.isApproved) {
                    logout("Esta compañía no ha sido aprobada aún");
                    return;
                };

                setUser({ data: _user, company: _company });
                localStorage.setItem("userFirstname", encrypt(_user.fullName));
                setUserFirstname(encrypt(_user.fullName));
                setUserRole(_user._role);

                if (email) {
                    localStorage.setItem("userEmail", encrypt(email));
                    setUserEmail(encrypt(email));
                };

                if (jwt) {
                    localStorage.setItem("token", jwt);
                    setToken(jwt);
                };

                !hidden && notify(`Bienvenido de vuelta, ${_user.fullName}`, "success");
            } else {
                logout("Hubo un problema al obtener tu información, contacta a un administrador");
            };

            setIsLoading(false);

        } catch (e) {
            console.log(e);
            e.response ? notify(e.response.data.message, "error") : notify("Ocurrió algo inesperado", "error");
            logout();
        };
    };
    


    const getCompaniesByType = async (_user, _company) => {
        
        console.log(_user);
        console.log(_company);
        if(_user._role == 'Administrador Caintra'){
            _user.companiesByName = await Methods.getAllCompaniesName();
        } else if(_user._role == 'CORPORATIVO'){
            _user.companiesByName  = await Methods.getCompaniesFromGroup([], _company.rfc);
        } else if (_user.email === "maribel.breceda@hondaplaza.com.mx" || _user.email === "anwar.centeno@cleber.com" ||  _user.email === "norely.carranza@toyotainnova.com.mx" ||  _user.email === "marisol.arteaga@nissanjidosha.mx") {
            _user.companiesByName  = await Methods.getCompaniesFromGroup(_company.groupCompaniesRFC, _company.rfc);
        }
        else if (_user.isGroupRole) {
            _user.companiesByName  = await Methods.getCompaniesFromGroup(_company.groupCompaniesRFC, _company.rfc);
        } 
        else {
            _user.companiesByName = [_company];
        }
        if(_user.advisedCompanies?.length){
            let advisedCompanies = await Methods.getAllCompaniesName(_user.advisedCompanies)
            _user.companiesByName = _user.companiesByName.concat(advisedCompanies); 
        }

        let aux = {};
        _user.companiesByName = _user.companiesByName.filter(o => aux[o._id] ? false : aux[o._id] = true);

        _user.companies = []; 
        _user.companiesByName?.map(company => {
            _user.companies.push(company._id)
        })

        if(!(_user.companies.find( companyAux => companyAux == _company._id))){
            _user.companiesByName.push(_company);
            _user.companies.push(_company._id);
        }
    }

    const setCurrentCompany = async(companyId) => {
        setIsLoading(true);
        let companyUser = await axios({
            url: `${URL}/companies`,
            method: "PUT",
            data: encrypt({ _id: companyId })
        });

        if (companyUser) {
            let companyResponse = decrypt(companyUser.data);
            companyResponse = JSON.parse(companyResponse);
            setUser({ data: user.data, company: companyResponse });
        }
        setIsLoading(false);
    }

    const can = (action, component) => {
        if (action == "view" && component == "Privacy") {
            return true;
        }

        if(userValidations(action, component)) return false;

        if (user.data.mirrorAdmin != undefined && (action == "view" && (component == "Pending" || component == "Dispersion")) && user.data.isCaintraUser) {
            return !user.data.mirrorAdmin;
        }
        const permissions = user.data._permissions;
        if(component == "Generalistas") return permissions?.['Users']?.[action];
        if(component == "CostCenters") return permissions?.['Departments']?.[action];
        if (permissions?.[component]) {
            if (userRole === "Administrador Caintra") return true;
            return permissions?.[component]?.[action];
        } else {
            if (userRole === "Administrador Caintra") return true;
            return false;
        }
    };

    const userValidations = (action, component) => {

        if (action == "view" && (component == "Pending" || component == "Dispersion")) {
            if (!user.data.isCaintraUser) {
                return true;
            }
        }

        if(action == "view" && (component == "Pending" ||
         component == "Dispersion" || component == "Logistics" || component == "Groups" || 
         component == "Universities") && user.data.isSupervisor){
            return true;
        }
 
        if(action === "view" && component === "suspencion"   && (user.data.isCaintraUser || user.data._role === "Administrador Caintra") ){
            return false;
        }

        return false;
    }

    
    const logout = async (reason) => {
        await Auth.signOut();
        localStorage.removeItem("token");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userFirstname");
        localStorage.removeItem("internAdjustments");
        localStorage.removeItem("serverHash");
        localStorage.setItem("logout", true);
        
        
        setToken(undefined);
        setUser(undefined);
        setUserEmail(undefined);
        setIsLoading(false);
        if (reason) notify(reason, "error");
    };

    const cleanDataByDashboard = () => {
        if(dashboardRedirectData.isRedirected){
            setDashboardRedirectData({
                interns: [],
                isRedirected: false,
                internStatusSelected: ''
            })
        }
    }

    return (
        <UserContext.Provider
            value={{
                token,
                setToken,
                user,
                logout,
                setUserEmail,
                isLoading,
                onLoad,
                userRole,
                userFirstName,
                can,
                getDepartmentField,
                getFacultadField,
                getGeneralistaField,
                universities,
                isLoadingUniversities,
                setCurrentCompany,
                setDashboardRedirectData,
                dashboardRedirectData,
                cleanDataByDashboard
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

import React, {useState} from 'react';
import {Modal, Image, Form, Button, Row, Col, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function UpdateCareerModal ({displayUpdateCareerModal, setDisplayUpdateCareerModal, currentCareerId, updateList, setUpdateList, currentCareer}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);

    const updateCareer = async (e) => {
        e.preventDefault();
        setIsLoading(true);    
        const name = e.target.elements.carrerName.value;
        const career = {
            attributes: [],
            id_career: currentCareerId
        };

        if(name.trim() == ""){
            notify("El nombre no puede estar vacío", "error");
            setIsLoading(false);    
            return;
        };
        
        name != currentCareer._name && career.attributes.push({updateKey: "_name", updateValue: name, expressionAttributeName: "#_name"});

        try {
            await axios({
                url: `${URL}/careers`,
                method: "PATCH",
                data: career
            });

            setIsLoading(false);    
            notify("Registro actualizado exitosamente", "success");
            setUpdateList(!updateList);
            setDisplayUpdateCareerModal(!displayUpdateCareerModal);
        } catch (error) {
            setIsLoading(false);    
            error.response && notify(error.response.data.message, "error");
        };
    };

    return(
        <Modal className="new-cc-modal" show={displayUpdateCareerModal} onHide={() => setDisplayUpdateCareerModal(!displayUpdateCareerModal)} centered>
            <Modal.Header>
                <p>Actualizar Registro</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={updateCareer} autoComplete={"off"}>
                    <Row>
                        <Col>
                            <Form.Group controlId="carrerName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" required defaultValue={currentCareer._name}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Actualizar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayUpdateCareerModal(!displayUpdateCareerModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
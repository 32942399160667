import React, {useState, createRef, useEffect} from 'react';
import {Table, Row, Col, Button, Form, Dropdown, Accordion, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar, faDownload, faUser, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {motion} from 'framer-motion';
import moment from 'moment';
import {saveAs} from 'file-saver';
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from '../../../utils/notify';
import { RiSearch2Line } from 'react-icons/ri';
import ExcelCSV from "../Excel/DownloadXLSX";
import * as Excel from "exceljs";
import { DropdownList } from "react-widgets";
import Methods from '../../../utils/https';
const axios = require("axios");
const pdfLib = require("../pdf/PDFTemplate");

moment.locale('es');



export default function ListInterns ({context, id_company}){

    const filterOptionsCaintra = [
        { value: 'agreement', label: 'ID Convenio' },
    ];

    const [limitValues, setLimitValues] = useState({
        currentLimit: 10,
        limitPages: 20
      });
      const {currentLimit, limitPages} = limitValues;
    
    const filterOptionsCompany = [
        { value: '_id', label: 'ID practicante' },
        { value: 'contractDuration', label: 'Duración Contrato'},
        { value: 'contractEndDate', label: 'Fin del Contrato' },
        { value: 'contractStartDate', label: 'Inicio del Contrato'},
        { value: 'department_name', label: context.getDepartmentField(true)},
        { value: 'directBoss_name', label: 'Jefe directo'},
        { value: 'directBoss_email', label: 'Correo del Jefe Directo', requiereIntern: true},
        { value: 'projectName', label: 'Proyecto', requiereIntern: true},
        { value: 'unsuscribeMotive', label: 'Motivo de Cancelación'},
        { value: 'cost_center_name', label: 'Centro de Costos', requiereIntern: true},
        { value: 'scolarshipAmount', label: 'Aportación Mensual'}
    ];
    const filterOptionsIntern = [
        { value: 'internStatus', label: 'Estatus' },
        { value: 'firstname', label: 'Nombres' },
        { value: 'father_lastname', label: 'Apellido Paterno' },
        { value: 'mother_lastname', label: 'Apellido Materno' },
        { value: 'age', label: 'Edad' },
        { value: 'email', label: 'Correo' },
        { value: 'curp', label: 'CURP' },
        { value: 'phone', label: 'Teléfono'},
        { value: 'bank', label: 'Banco', requiereIntern: true},
        { value: 'location', label: 'Dirección'},
        { value: 'born_date', label: 'Fecha de nacimiento'},
        { value: 'gender', label: 'Género'}
    ];
    const filterOptionsCollege = [
        { value: 'college', label: 'Universidad' },
        { value: 'career', label: 'Carrera' },
        { value: 'grade', label: 'Grado', requiereIntern: true},
        { value: '_group', label: 'Grupo', requiereIntern: true},
        { value: 'period_type', label: 'Ciclo escolar', requiereIntern: true},
        { value: 'school_enrollment', label: 'Matricula'},
    ];
    const filterCategories = [
        { value: 'caintra', label: 'Caintra', filterOptions: filterOptionsCaintra},
        { value: 'intern', label: 'Practicante', filterOptions: filterOptionsIntern},
        { value: 'company', label: 'Empresa', filterOptions: filterOptionsCompany},
        { value: 'college', label: 'Universidad', filterOptions: filterOptionsCollege}
    ];
    let excelCompanyReason = (context.user.company.business_name || "");
 
    if(excelCompanyReason == "" || excelCompanyReason == "NA" || excelCompanyReason == "N A"){
        excelCompanyReason = context.user.company.business_name;
    }
    const [statusTable, setStatusTable] = useState("Todo");
    const [activos, setActivos] = useState(0);
    const [inactivos, setInactivos] = useState(0);
    const [filters, setFilters] = useState(filterOptionsIntern);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [search, setSearch] = useState("");
    const [internsAux, setInternsAux] = useState({
        allInterns: [],
        allInternsFiltered: [],
        interns: [],
        internsFiltered: [],
        totalPages: 1
    });
    const {allInterns, allInternsFiltered, interns, internsFiltered, totalPages} = internsAux;
    const [currentPage, setCurrentPage] = useState(0);
    const [isFilterByIntern, setIsFilterByIntern] = useState(false);

    const StartDay = () => {
        return (
            <DatePicker
                selected={startDate}
                onChange={
                    async (date) => {
                        let diff = moment(endDate).diff(moment(date), 'years', true)
                        if( diff <= 5){
                            setStartDate(date)
                            setCurrentPage(0)
                            await getInternsByDate(date, endDate);
                        }else{
                            notify('Solo es posible seleccionar máximo 5 años de rango', 'error');
                        }
                    }
                }
                dateFormat='dd MMM yyyy'
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
            />           
        );
    };

    const EndDay = () => {
        return (
            <DatePicker
                selected={endDate}
                onChange={
                    async (date) => {
                        let diff = moment(date).diff(moment(startDate), 'years', true);
                        if( diff <= 5){
                            setEndDate(date);
                            setCurrentPage(0)
                            await getInternsByDate(startDate, date);
                        }else{
                            notify('Solo es posible seleccionar máximo 5 años de rango', 'error');
                        }

                    }
                }
                dateFormat='dd MMM yyyy'
                fixedHeight
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
            />           
        );

    };

    const tableRef = createRef();

    useEffect(() => {
        getInternsByDate();
    }, []);

    const getInternsByDate = async (startDateAux, endDateAux, lim) => {
        setSearch('');
        setIsFilterByIntern(false);
        let internsFilteredByStatus = [];
        let data = await Methods.getAllContractsByDateRange(id_company, startDateAux || startDate, endDateAux || endDate);
        if(!data) return;
        if(data.length){
            data = getInternsAndSetData(data);   
            await getInternStatusCount(data);

            if(statusTable != 'Todo'){
                internsFilteredByStatus = [...data.filter(contract => contract.contractStatus == statusTable)];
                internsFilteredByStatus = createChunkArray(internsFilteredByStatus, 10);
            }
            console.log("LENHTH", limitValues.currentLimit, lim)
            let internsArray;
            if(lim !== undefined){
                internsArray = createChunkArray(data, lim);
            }
            else{
                internsArray = createChunkArray(data, limitValues.currentLimit);
            }

            setInternsAux({
                ...internsAux,
                allInterns: internsArray,
                allInternsFiltered: internsFilteredByStatus.length ?  internsFilteredByStatus : internsArray,
                interns: internsArray[0],
                internsFiltered: internsFilteredByStatus[0] || internsArray[0],
                totalPages: internsFilteredByStatus.length ? internsFilteredByStatus.length : internsArray.length,
            })
            
        }
    }

    const getInternsAndSetData = (data) => {
        let total = []
        data.map(intern => {
            if(intern.contractStatus == "Pendiente") intern.statusColor = "#6CE090";
            if(intern.contractStatus == "Activo") intern.statusColor = "#6CE090";
            if(intern.contractStatus == "Inactivo") intern.statusColor = "#ED5A6C";
            intern.internStatus = intern.contractStatus;
            intern.firstname = intern.internFirstName;
            intern.mother_lastname = intern.internMotherLastname;
            intern.father_lastname = intern.internFatherLastname;
            intern.contractNameAux = (intern.internFirstName + ' ' + intern.internMotherLastname + ' '+ intern.internFatherLastname).toLowerCase();
            intern.curp = intern.internCurp;
            intern.phone = intern.internPhone;
            intern.email =  intern.internEmail;
            intern.contractEndDate = intern.internEndDate;
            intern.contractStartDate = intern.internStartDate;
            intern.scolarshipAmount = intern.internAmount;
            intern.department_name = intern.departmentName;
            intern.agreement = "";
            if(intern.curp){
                intern.born_date = curpToBornDate((intern.curp || "_"));
                intern.gender = curpToGender((intern.curp || "_"));
                intern.age = bornDateToAge(intern.born_date);
            }
            intern.contractDuration = Math.ceil(moment(intern.internEndDate, 'DD/MM/YYYY').diff(moment(intern.internStartDate, 'DD/MM/YYYY'), 'months', true))
            intern.college = intern.collegeName;
            intern.career = intern.internCareer;
            intern.unsuscribeMotive = intern.unsuscribeDescription;
            intern.location = intern.internStreet + ' ' + intern.internExtNumber + ' ' +
            intern.internMunicipality  + ' ' + intern.internSuburb;
            intern.school_enrollment = intern.schoolEnrollment;
            intern.directBoss_name = intern.directBoss;
            intern.group = intern._group;
            if(intern.internStatus != 'Invalidado'){
                total.push(intern);
            }
        });
        return total;
    }

    const filterByIntern = (internsToEvaluate) => {
        
        let allInternsAux = []; 

        internsToEvaluate.map(chunk => {
            allInternsAux = allInternsAux.concat(chunk);
        });
        
        let result = allInternsAux.sort(function (a, b) {
            return a.startDateTimestamp > b.startDateTimestamp ? -1 : 1;
        });

        let hash = {}
        
        return allInternsAux = createChunkArray(
            result.filter(o => hash[o.internId] ? false : hash[o.internId] = true), 10
        );
    } 


    const createChunkArray = (array, size) => {
        let chunks = [];
        while (array.length){
            chunks.push(array.splice(0, size));
        }
        return chunks;
    }

    const getInternStatusCount = async(_data) =>{
        let activosCount = 0 
        let inactivosCount = 0
        _data.map((intern) => {
            if (intern.contractStatus == "Activo") {
                activosCount +=1;
            } else if (intern.contractStatus == "Inactivo") {
                inactivosCount +=1;
            }
        })
        setActivos(activosCount);
        setInactivos(inactivosCount);
    }

    const filterIncludes = (value) => {
        for(let index in filters){
            let isInternFilter = isFilterByIntern ? true : !filters[index].requiereIntern;
            if(filters[index].value == value && isInternFilter) return true;
        };
        return false;
    };

    const updateFilters = (event, filter) => {
        const value = filter.value;
        const _filters = filters;

        if(filterIncludes(value)){
            const filtered = filters.filter((item) => item.value !== value);
            setFilters(filtered);
            return;
        };

        setFilters([..._filters, filter]);

    };


    const curpToBornDate = (curp) => {
        let burnDate = "";
        if (curp.length > 0){
            let day = curp.substring(8,10);
            let month = curp.substring(6,8);
            let year = curp.substring(4,6);
            if (year > 40) {
                year = 19 + year;
            } else {
                year = 20 + year;
            }
            burnDate = `${day}/${month}/${year}`
            return burnDate;
        }else{
            return burnDate;
        }
    }

    const bornDateToAge = (bornDate) => {
        let age = " ";
        if (bornDate.length > 0) {
            return moment().diff(moment(bornDate, 'DD/MM/YYYY'), "years");
        }else{
            return age;
        }
        
    }

    const curpToGender = (curp) => {
        let gender = "";
        if (curp.length > 0){
            gender = curp.substring(10,11);
            return gender;
        }else{
            return gender;
        }
    }

    const setStatusFilter = (status, search, internFilter) => {
        let internsFilteredByStatus = [];
        
        if(status == 'Todo' && !search){
            internsFilteredByStatus = allInterns
        }else{
            allInterns.forEach(page => {
                internsFilteredByStatus = internsFilteredByStatus.concat(
                    page.filter(contract => {
                        let matchSearch = search ? contract.contractNameAux.includes(search.toLowerCase()) : true;
                        let matchStatus = status == 'Todo' ? true : contract.contractStatus == status
                        if(matchStatus && matchSearch) return true;
                    }) 
                );
            });
            internsFilteredByStatus = createChunkArray(internsFilteredByStatus, 10);
        }

        if(internFilter){
            internsFilteredByStatus = filterByIntern(internsFilteredByStatus); 
        }

        setInternsAux({
            ...internsAux,
            allInternsFiltered: internsFilteredByStatus,
            internsFiltered: internsFilteredByStatus[0],
            totalPages: internsFilteredByStatus.length || 1,
        })
        setCurrentPage(0);
    }

    return (
        <Col>
            <Row>
                <Col md={12} xs={12} sm={12} className='col-reports'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="search-icon"><RiSearch2Line/></div>
                        <Form.Group controlId="search-bar" className="search-container">
                            <Form.Control 
                                type="text" 
                                placeholder="Búsqueda" 
                                autoComplete={"off"}
                                value={search}
                                onInputCapture={(e) => {
                                    if(e.target.value === "") {
                                        setStatusFilter(statusTable, '', isFilterByIntern);
                                    }else{
                                        setStatusFilter(statusTable, e.target.value, isFilterByIntern);
                                    }
                                    setSearch(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className="options">
                        <Dropdown drop={"down"}>
                            <Dropdown.Toggle
                                className="drop-down-filters"
                                id="dropdown-custom-components"
                            >
                                Información a consultar
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{margin: "0px", padding: "0px"}}>
                                <div>
                                    <Accordion>
                                        {filterCategories.map((filter, key) => {
                                            return(
                                                <Card>
                                                    <Card.Header>
                                                        <Accordion.Toggle className="drop-down-categories" as={Button} variant="link" eventKey={String(key)}>
                                                            {filter.label}
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={String(key)}>
                                                        <Card.Body className="card-body">
                                                        <div>
                                                            {filter.filterOptions.map((filter, key) => {
                                                                return(
                                                                    <Form.Check className="checkbox" disabled={filter.requiereIntern && !isFilterByIntern} type="checkbox" key={key} label={filter.label} onChangeCapture={(event) => updateFilters(event, filter)} checked={filterIncludes(filter.value)}/>
                                                                );
                                                            })}
                                                        </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            );
                                        })}
                                    </Accordion>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>

                        Desde la fecha: <Button className="default-button date-btn"
                        style={{backgroundImage: "none", backgroundColor: "#1B3769", marginLeft: "5px"}}
                        >
                            <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
                            <StartDay/>
                        </Button>

                        Hasta la fecha: <Button 
                        className="default-button date-btn"
                        style={{backgroundImage: "none", backgroundColor: "#1B3769", marginLeft: "5px"}}
                        >
                            <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
                            <EndDay/>
                        </Button>
                    </div>
                    <div className="options">
                        <Button className="default-button" 
                            onClick={() =>{
                                if (statusTable == "Todo") {
                                    setStatusTable("Activo")
                                    setStatusFilter('Activo', search, isFilterByIntern);;
                                } else if (statusTable == "Activo") {
                                    setStatusTable("Inactivo");
                                    setStatusFilter('Inactivo', search, isFilterByIntern);
                                } else if  (statusTable == "Inactivo"){
                                    setStatusTable("Todo");
                                    setStatusFilter('Todo', search, isFilterByIntern);
                                }
                            }}
                        >
                            <FontAwesomeIcon className="inner-text" icon={faUser}/>
                            {statusTable}
                        </Button>

                        <Button className="default-button" onClick={() => pdfLib.getPdf("reporte", internsFiltered)}>
                            <FontAwesomeIcon className="inner-text" icon={faDownload}/>
                            Descargar PDF
                        </Button>

                        <Button className="default-button" onClick={() =>{
                            const workbook = new Excel.Workbook();
                            let contracts = [];
                            for(let contract of allInternsFiltered){
                                contracts = contracts.concat(contract);
                            }
                            ExcelCSV.test(
                                workbook,
                                filters,
                                contracts,
                                excelCompanyReason,
                                [startDate, endDate],
                                context.getDepartmentField(),
                            );
                            workbook.xlsx.writeBuffer().then((data) => {
                                const blob = new Blob([data], {
                                  type:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                });
                                saveAs(blob, `Reportes.xlsx`);
                              });
                        }}>
                            <FontAwesomeIcon className="inner-text" icon={faDownload}/>
                            Descargar Excel
                        </Button>

                        <Button className="default-button" 
                            onClick={() =>{
                                if(isFilterByIntern){
                                    setStatusFilter(statusTable, search, false);
                                    setIsFilterByIntern(false);
                                }else{
                                    setStatusFilter(statusTable, search, true);
                                    setIsFilterByIntern(true);
                                }

                            }}
                        >
                            <FontAwesomeIcon className="inner-text" icon={faUser}/>
                            {isFilterByIntern ? 'Practicante' : 'Convenio'} 
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='col-reports'>
                    <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ duration: 0.4 }} style={{width: '95%'}}>
                        
                        <div className="table-scroll">
                            <Table hover ref={tableRef}>
                                <thead>
                                    <tr>
                                        { <th>#</th>}
                                        {filterIncludes("internStatus") && <th>Estatus</th>}
                                        {filterIncludes("agreement") && <th>ID Convenio</th>}

                                        {filterIncludes("firstname") && <th>Nombres</th>}
                                        {filterIncludes("father_lastname") && <th>Apellido Paterno</th>}
                                        {filterIncludes("mother_lastname") && <th>Apellido Materno</th>}
                                        {filterIncludes("age") && <th>Edad</th>}
                                        {filterIncludes("email") && <th>Correo</th>}
                                        {filterIncludes("curp") && <th>CURP</th>}
                                        {filterIncludes("phone") && <th>Teléfono</th>}
                                        {filterIncludes("bank") && <th>Banco</th>}
                                        {filterIncludes("location") && <th><div style={{textAlign: "center"}}>Dirección</div></th>}
                                        {filterIncludes("born_date") && <th>Fecha de nacimiento</th>}
                                        {filterIncludes("gender") && <th>Género</th>}

                                        {filterIncludes("_id") && <th>ID practicante</th>}
                                        {filterIncludes("startDate") && <th>Fecha de Inicio</th>}
                                        {filterIncludes("endDate") && <th>Fecha de término</th>}
                                        {filterIncludes("contractStartDate") && <th>Fecha Inicio del Contrato</th>}
                                        {filterIncludes("contractEndDate") && <th>Fecha Fin del Contrato</th>}
                                        {filterIncludes("contractDuration") && <th>Duración Contrato</th>}
                                        {filterIncludes("department_name") && <th>{context.getDepartmentField(true)}</th>}
                                        {filterIncludes("directBoss_name") && <th>Jefe directo</th>}
                                        {filterIncludes("directBoss_email") && <th>Correo del Jefe Directo</th>}
                                        {filterIncludes("projectName") && <th>Proyecto</th>}
                                        {filterIncludes("unsuscribeMotive") && <th>Motivo de Cancelación</th>}
                                        {filterIncludes("cost_center_name") && <th>Centro de Costos</th>}
                                        {filterIncludes("scolarshipAmount") && <th>Aportación Mensual</th>}

                                        {filterIncludes("college") && <th>Universidad</th>}
                                        {filterIncludes("career") && <th>Carrera</th>}
                                        {filterIncludes("period_type") && <th>Ciclo escolar</th>}
                                        {filterIncludes("grade") && <th>Grado</th>}
                                        {filterIncludes("_group") && <th>Grupo</th>}
                                        {filterIncludes("school_enrollment") && <th>Matrícula</th>}
                                    </tr>
                                </thead>

                                <tbody>
                                    {internsFiltered && internsFiltered.map((intern, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                       { index+1 } 
                                                    {filterIncludes("internStatus") && <td><p style={{color: intern.statusColor}}>{intern.contractStatus}</p></td>}
                                                    {filterIncludes("agreement") && <td>{intern.agreement}</td>}
                                                    
                                                    {filterIncludes("firstname") && <td>{intern.firstname}</td>}
                                                    {filterIncludes("father_lastname") && <td>{intern.father_lastname}</td>}
                                                    {filterIncludes("mother_lastname") && <td>{intern.mother_lastname}</td>}
                                                    {filterIncludes("age") && <td>{intern.age}</td>}
                                                    {filterIncludes("email") && <td>{intern.email}</td>}
                                                    {filterIncludes("curp") && <td>{intern.curp}</td>}
                                                    {filterIncludes("phone") && <td>{intern.phone}</td>}
                                                    {filterIncludes("bank") && <td>{intern.bank}</td>}
                                                    {filterIncludes("location") && <td><div style={{width: "600px"}}>{intern.location?.substring(0,80)}</div></td>}
                                                    {filterIncludes("born_date") && <td>{intern.born_date}</td>}
                                                    {filterIncludes("gender") && <td>{intern.gender}</td>}

                                                    {filterIncludes("_id") && <td style={{width: "800px"}}>{intern._id}</td>}
                                                    {filterIncludes("startDate") && <td>{intern.startDate}</td>}
                                                    {filterIncludes("endDate") && <td>{intern.endDate}</td>}
                                                    {filterIncludes("contractStartDate") && <td>{intern.contractStartDate}</td>}
                                                    {filterIncludes("contractEndDate") && <td>{intern.contractEndDate}</td>}
                                                    {filterIncludes("contractDuration") && <td>{intern.contractDuration}</td>}
                                                    {filterIncludes("department_name") && <td>{intern.department_name}</td>}
                                                    {filterIncludes("directBoss_name") && <td>{intern.directBoss_name}</td>}
                                                    {filterIncludes("directBoss_email") && <td>{intern.directBoss_email}</td>}
                                                    {filterIncludes("projectName") && <td>{intern.projectName}</td>}
                                                    {filterIncludes("unsuscribeMotive") && <td>{intern.unsuscribeMotive}</td>}
                                                    {filterIncludes("cost_center_name") && <td>{intern.cost_center_name}</td>}
                                                    {filterIncludes("scolarshipAmount") && <td>{intern.scolarshipAmount}</td>}
                                                    
                                                    {filterIncludes("college") && <td>{intern.college}</td>}
                                                    {filterIncludes("career") && <td>{intern.career}</td>}
                                                    {filterIncludes("period_type") && <td>{intern.period_type}</td>}
                                                    {filterIncludes("grade") && <td>{intern.grade}</td>}
                                                    {filterIncludes("_group") && <td>{intern.group}</td>}
                                                    {filterIncludes("school_enrollment") && <td>{intern.school_enrollment}</td>}
                                                </tr>
                                                <tr className="spacer"></tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        
                        <ReactPaginate
                            pageCount={totalPages} 
                            forcePage={currentPage}
                            containerClassName="pagination-container" 
                            previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                            nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                            onPageChange={(e) => { 
                                setCurrentPage(e.selected)
                                setInternsAux({...internsAux, internsFiltered: allInternsFiltered[e.selected]})
                            }}
                        />
                        <div className="interns-list-options float-right">
          <p>Elementos por página:</p>
            <DropdownList
                data={[10, 20, 40, 60, 100, 500, 1000, 5000]}
                defaultValue={currentLimit}
                onSelect={async (e) => {
                    
                    setLimitValues({
                        currentLimit: e,
                      
                    })
                    console.log(e);
                    await getInternsByDate(startDate, endDate, e);
                 
                }}
                containerClassName="limit-container"
            />

           
            
           
        </div>
                    </motion.div>
                </Col>
            </Row>

            <Row>
                <Col className='col-reports'>
                    <Table hover className="costs-table float-left" style={{marginBottom: "50px"}}>
                        <thead>
                            <tr>
                                <th>Practicantes</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{color: "#1B3769"}}>Activo</td>
                                <td style={{color: "#1B3769", fontWeight: "bold"}}>{activos}</td>
                            </tr>
                            <tr className="spacer"></tr>
                            <tr>
                                <td style={{color: "#1B3769"}}>Inactivos</td>
                                <td style={{color: "#1B3769", fontWeight: "bold"}}>{inactivos}</td>
                            </tr>
                            <tr className="spacer"></tr>
                            <tr>
                                <td style={{color: "#1B3769"}}>Todos</td>
                                <td style={{color: "#1B3769", fontWeight: "bold"}}>{activos + inactivos}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col md={1}></Col>
            </Row>
        </Col>
    )
};
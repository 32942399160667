import React, {useContext, useEffect, useState, useRef} from 'react';
import {UserContext} from '../../context/UserContext';
import {Row, Col, Button, Dropdown, Form, Spinner} from 'react-bootstrap';
import { motion } from 'framer-motion';
import '../Dashboard/Dashboard.css';
import { GenderDashboard } from './components/GenderDashboard';
import { AverageStatsDashboards } from './components/AverageStatsDashboards';
import { InternsByStatus } from './components/InternsByStatus'
import download from "../../assets/IconsCaintra/download.svg";
import manual from "../../assets/IconsCaintra/manual.svg";
import toolkit from "../../assets/IconsCaintra/tookit.png";
import moment from 'moment';
import html2canvas from "html2canvas";
import JSZip from 'jszip';
import { notify } from '../../utils/notify';
import {saveAs} from 'file-saver';
import genericExcel from "./Excel/DownloadAllExcel";
import * as Excel from "exceljs";
import { RedirectInternDashboards } from './components/RedirectInternDashboards';
const $ENV = "localEnv";


export default function Dashboard () {
    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
    );
    
    const context = useContext(UserContext);
    const currentCompany = context.user ? context.user.company.business_name : "";
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([context.user.company._id]);
    const [searchCompanyAux, setSearchCompanyAux] = useState('');
    const [periodSelected, setPeriodSelected] = useState(-1);
    const [monthsToDownload, setMonthsToDownload] = useState(1);
    const [periodSelectedToCompare, setPeriodSelectedToCompare] = useState(undefined);
    const [genderExcelValues, setGenderExcelValues] = useState({
        excelValues: [],
        excelConfigurations: []
    });
    const [invoiceAverageExcelValues, setInvoiceAverageExcelValues] = useState({
        excelValues: [],
        excelConfigurations: []
    });
    const [topUniversitiesExcelValues, setTopUniversitiesExcelValues] = useState({
        excelValues: [],
        excelConfigurations: []
    });
    const [allStatusExcelValues, setAllStatusExcelValues] = useState({
        excelValues: [],
        allStatusExcelConfigurations: [],
        totalInternsExcelConfigurations: []
    });

    let refsAux = {
        componentRef: useRef([]),
        dateRef: useRef([]),
        downloadRef: useRef([])
    };

    let {componentRef, dateRef, downloadRef} = refsAux;

    

    const exportAsImage = async (element, dateRefAux, downloadRef) => {
        try{

            if(dateRefAux) dateRefAux.style.display = "";
            if(downloadRef) downloadRef.style.display = "none";
            const canvas = await html2canvas(element);
            if(dateRefAux) dateRefAux.style.display = "none";
            if(downloadRef) downloadRef.style.display = "";

            return canvas.toDataURL("image/png", 1).replace(/^data:image\/(png|jpg);base64,/, "");
        }catch(error){
            notify('No es posible completar la descarga','error')
        }

    };


    const downloadAll = async() => {
        setIsLoading(true);

        const currentFiles = {
            0: { 
                label: 'Relación_demográfica',
                excelValues: genderExcelValues
            }, 
            1: { 
                label: 'Facturado_en_el_mes',
                excelValues: invoiceAverageExcelValues
            },
            2: { 
                label: 'Top_universidades',
                excelValues: topUniversitiesExcelValues
            },
            3: { 
                label: 'Estatus_de_practicantes',
                excelValues: allStatusExcelValues
            },
            4: { 
                label: 'Total_de_practicantes',
                excelValues: allStatusExcelValues
            }
        }

    
            
        try {
            var zip = new JSZip();
            let excelValues = [];
            let companyName = ''
            for (let index = 0; index < 5; index++) {
                
                let currentDashboardValues = currentFiles[index].excelValues

                let genderImage = await exportAsImage(
                    componentRef.current[index],
                    dateRef.current[index],
                    downloadRef.current[index]
                );

                let label = currentFiles[index].label;
                let companyValue = {
                    values: currentDashboardValues.excelValues[0].values,
                    config: currentDashboardValues.excelConfigurations,
                    name: currentDashboardValues.excelValues[0].name,
                    workSheetName: label
                }

                companyName = currentDashboardValues.excelValues[0].name

                if(label == 'Total_de_practicantes'){
                    companyValue.config = currentDashboardValues.totalInternsExcelConfigurations;
                }else if(label == 'Estatus_de_practicantes'){
                    companyValue.config = currentDashboardValues.allStatusExcelConfigurations;
                }

                excelValues.push(companyValue)
                zip.file(`${label}.png`, genderImage, { base64: true });
            }

            let genderExcel = await getExcel(
                excelValues,
                companyName
            );  
            

            zip.file(`${companyName}.xlsx`, genderExcel);
            zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, `dashboards_${moment().format()}.zip`);
            });
        } catch (error) {
            notify("Error al descargar los documentos", "error");
        }

        setIsLoading(false);
    }


    const getFormatedDate = (period) => moment().startOf('day').subtract((period-1) , 'month').format('MMM YYYY')
    

    const getExcel = async(excelData, fileName) => {
        const workbook = new Excel.Workbook();
        let periodDate = getFormatedDate(periodSelected) +
        (monthsToDownload > 1 ? ( ' al ' + getFormatedDate(monthsToDownload) ) : '');

        await genericExcel.test(
            workbook,
            excelData,
            moment().format(),
            context.user.data.fullName || '',
            fileName,
            periodDate
        );


        let excel = workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            return blob;
        });
        return excel;
    }
      
    useEffect(() => {
        
    }, []);
    
    const getPeriodString = (periodDifference) => {
        
        if(periodDifference === -1 ){
            return "Periodo seleccionado";
        }
        else if(periodDifference === undefined){
            return "Comparar con";
        }
        else    
            return moment().startOf('day').subtract((periodDifference-1) , 'month').format('MMMM YYYY')
    }

    return(
        <div className="dashboard" >
            <motion.div initial={{x: -30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>
                <div  className="sticky">
                    <Row className="manual-container element-space">
                        <Col md={8} className="title-search-bar title-dashboard-info">
                            <div className='title-container'>
                                <h4>Dashboard</h4>
                                <h5>Periodo seleccionado: { getPeriodString(periodSelected)} {periodSelectedToCompare && 'comparado con ' + getPeriodString(periodSelectedToCompare)} 
                                {' (Descarga ' + monthsToDownload + (monthsToDownload > 1 ? ' meses' : ' mes') + ')'}
                                </h5>
                            </div>
                            <h3 className="company">{currentCompany}</h3>          
                        </Col>
                        <Col md={4} className="manual-button-container">
                            <Button className="manual-btn" onClick={() => window.open(`https://caintrabucket-${($ENV == 'localEnv') ? 'testing' : $ENV}.s3.amazonaws.com/documents/manual_grow.pdf`, '_blank')}>
                                <img src={manual} className="manual-icon" width="19px" />
                                Ver manual 
                            </Button>
                            <Button className="manual-btn" onClick={() => window.open(`https://caintrabucket-${($ENV == 'localEnv') ? 'testing' : $ENV}.s3.amazonaws.com/documents/toolkitEmpresa.pdf`, '_blank')}>
                                <img src={toolkit} className="manual-icon" width="19px" />
                                ToolKit 
                            </Button>
                        </Col>

                    </Row>
                    <div className='options-dashboard-container element-space'>
                        <div className="title-search-bar options">
                        <Dropdown drop={"down"} className='dropdown-options'>
                            <Dropdown.Toggle>{ getPeriodString(periodSelected)}</Dropdown.Toggle>
                            <Dropdown.Menu className="filters-menu options-search-container">
                                {[...Array.from({length: 60}, (_, i) => i + 1)].map(period => {
                                    return (
                                        <Dropdown.Item
                                            key={period}
                                            active={period == periodSelected}
                                            onSelect={async () => {setPeriodSelected(period)}}>
                                            {moment().startOf('day').subtract((period-1) , 'month').format('MMM YYYY')}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop={"down"} className='dropdown-options'>
                            <Dropdown.Toggle>Compañías</Dropdown.Toggle>
                            <Dropdown.Menu className="filters-menu filters-menu-companies">
                                <Form.Control
                                    type="text"
                                    placeholder="Buscar"
                                    autoComplete={"off"}
                                    onInputCapture={async (e) => {
                                        setSearchCompanyAux(e.target.value);
                                    }}
                                />
                                <div className="options-search-container">
                                    {context?.user?.data?.companiesByName?.filter((companyAux, key) => {
                                        if(!searchCompanyAux) return true;
                                        return companyAux.business_name.toLowerCase()
                                        .trim().includes(searchCompanyAux?.toLowerCase().trim());
                                    }).map((company, key) => {
                                        return(
                                            <Form.Check 
                                                type="checkbox" 
                                                key={key} 
                                                label={company.business_name} 
                                                onChangeCapture={() => {
                                                        let newCompanies = [];
                                                        if(selectedCompanies.includes(company._id)){
                                                            newCompanies = selectedCompanies.filter( c => c !== company._id)
                                                        }else{
                                                            newCompanies = [...selectedCompanies, company._id]
                                                        }
                                                        setSelectedCompanies(newCompanies);
                                                    }
                                                } 
                                                checked={selectedCompanies.includes(company._id)}
                                            />
                                        );
                                    })}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop={"down"} className='dropdown-options'>
                            <Dropdown.Toggle>{getPeriodString(periodSelectedToCompare)}</Dropdown.Toggle>
                            <Dropdown.Menu className="filters-menu options-search-container">
                                    <Dropdown.Item 
                                        key={'noValueToCompare'} 
                                        active={periodSelectedToCompare == undefined} 
                                        onSelect={async () => {
                                            setPeriodSelectedToCompare(undefined)                     
                                        }}>
                                        {'No comparar'}
                                    </Dropdown.Item>
                                    {[...Array.from({length: 60}, (_, i) => i + 1)].map(period => {
                                        return (
                                            <Dropdown.Item 
                                                key={period} 
                                                active={periodSelectedToCompare == period} 
                                                onSelect={async () => {
                                                    if(period == periodSelected){
                                                        notify('No es posible comparar con el mismo periodo', 'error')
                                                    }else{
                                                        setPeriodSelectedToCompare(period)
                                                    }
                                                }}>
                                                {moment().startOf('day').subtract((period-1) , 'month').format('MMM YYYY')}
                                            </Dropdown.Item>
                                        )
                                    })}
                            </Dropdown.Menu>
                        </Dropdown>
                        { periodSelectedToCompare === undefined &&
                            <Dropdown drop={"down"} className='dropdown-options'>
                                <Dropdown.Toggle>Descarga por meses: {monthsToDownload}</Dropdown.Toggle>
                                <Dropdown.Menu className="filters-menu options-search-container">
                                    {[1,2,3,5,6,12,18,24,30,36,42,48,54,60].map(months => {
                                        return (
                                            <Dropdown.Item 
                                            key={months} 
                                            active={months == monthsToDownload} 
                                            onSelect={async () => {setMonthsToDownload(months)}}>
                                            {months}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        </div>
                        { periodSelectedToCompare === undefined &&
                            <Button className='green-button' onClick={()=> downloadAll()}>
                                {isLoading ? 
                                    <Spinner 
                                        animation="border" 
                                        role="status"
                                        className='download-loading-icon'
                                    /> : 
                                    <img src={download} className="download-all-icon" width="19px" />    
                                }
                                Descargar todo
                            </Button>
                        }
                
                    </div>
                </div>
                <div className='dashboard-row'>             
                    <RedirectInternDashboards
                        context={context}
                    />

                    <InternsByStatus
                        selectedCompanies={selectedCompanies} 
                        periodSelected={periodSelected}
                        periodSelectedToCompare={periodSelectedToCompare}
                        context={context}
                        getPeriodString={getPeriodString}
                        monthsToDownload={monthsToDownload}
                        refsAux={refsAux}
                        setAllStatusExcelValues={setAllStatusExcelValues}
                    />
                    <AverageStatsDashboards
                        selectedCompanies={selectedCompanies} 
                        periodSelected={periodSelected}
                        periodSelectedToCompare={periodSelectedToCompare}
                        context={context}
                        getPeriodString={getPeriodString}
                        monthsToDownload={monthsToDownload}
                        refsAux={refsAux}
                        setInvoiceAverageExcelValues={setInvoiceAverageExcelValues}
                        setTopUniversitiesExcelValues={setTopUniversitiesExcelValues}
                    />

                    <GenderDashboard 
                        selectedCompanies={selectedCompanies} 
                        periodSelected={periodSelected}
                        periodSelectedToCompare={periodSelectedToCompare}
                        getPeriodString={getPeriodString}
                        monthsToDownload={monthsToDownload}
                        context={context}
                        refsAux={refsAux}
                        setGenderExcelValues={setGenderExcelValues}
                    />
                    
                    
                </div>
            </motion.div>
        </div>
    )
}


import React, {useState} from 'react';
import {Modal, Image, Form, Button, Spinner, Row, Col, InputGroup} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import Methods from '../../../utils/https';
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
const axios = require("axios");

export default function RegisterUser ({displayRegister, setDisplayRegister, currentEmail}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [seePass, setSeePass] = useState(false);
    const [seeConfirmPass, setConfirmPass] = useState(false);
    const passwordRegex = new RegExp (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\w<>{}()\[\]'"+\-=*/\\,.;:!@#$%^&¡!¿?~`|]{8,24}$/);
    const createCareer = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const password = e.target.elements.password.value;
        const passwordValidate = e.target.elements.passwordValidate.value;
        if(password != passwordValidate){
            notify("Las contraseñas no coinciden", "error");
            setIsLoading(false);
            return;
        }
        if(!passwordRegex.test(password)){
            notify("Tu contraseña debe tener al menos 8 caracteres, una letra en mayúscula, una letra en minúscula, y un número", "error");
            setIsLoading(false);
            return;
        }
        let data = {
            password: password,
            currentEmail: currentEmail,
            email: currentEmail.toLowerCase()
        }

        await Methods.registerUserCognito(data);
        notify("Usuario registrado exitosamente", "success");
        setIsLoading(false);
        setDisplayRegister(!displayRegister); 
    };
    
    const getSeePass = () => 
        !seePass ? 
            <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>
        : 
            <IoEyeOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>;

  
  const getConfirmPass = () =>
    !seeConfirmPass ?
        <IoEyeOffOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>;

    return(
        <Modal className="new-cc-modal" show={displayRegister} onHide={() => setDisplayRegister(!displayRegister)} centered>
            <Modal.Header>
                <p>Ingresa tu nueva contraseña</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={createCareer} autoComplete={"off"}>
                    <Row>
                        <Col>
                            <Form.Label>Para iniciar sesión es necesario cambiar tu contraseña</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="password">
                                <Form.Label>Contraseña</Form.Label>
                                <InputGroup>
                                    <Form.Control type={!seePass ? "password" : "text"} required style={{backgroundColor: '#f1f1f1 !important'}}/>
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                {getSeePass()}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="passwordValidate">
                                <Form.Label>Confirmar contraseña</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={!seeConfirmPass ? "password" : "text"} required style={{backgroundColor: '#f1f1f1 !important'}}/>
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                {getConfirmPass()}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                       {isLoading ? <Spinner animation="border" /> : "Registrar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayRegister(!displayRegister)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import { UserContext } from "../../context/UserContext";
import { Row, Col, Form, OverlayTrigger, Tooltip, Dropdown, InputGroup, Button, Container, Figure } from 'react-bootstrap';
import "./Groups.css";
import "../Companies/Companies.css";

import React from "react";
import { BiArrowBack } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

import EditItemModal from "./components/EditItemModal";
import CreateItemModal from "./components/CreateItemModal";
import DeleteItemModal from "./components/DeleteItemModal";

import Methods from "../../utils/https";
const uuid = require("uuid");

export default function Generalistas(props) {
    const [searchQuery, setSearchQuery] = React.useState("");
    const [data, setData] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState("");
    const [displayCreateItem, setDisplayCreateItem] = React.useState(false);
    const [displayDeleteItem, setDisplayDeleteItem] = React.useState(false);
    const [displayEditItem, setDisplayEditItem] = React.useState(false);

    async function getData() {
        setData(await Methods.getGeneralistas());
    }

    async function createItem(name, email) {
        let exists = false;


        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].name == name || data[i].email == email) {
                    exists = true;
                    throw new Error("Generalista ya existente");
                }
            }
        }

        if (exists == false) {
            setDisplayCreateItem(false);
            await Methods.createGeneralista({ name: name, email: email });
            getData();
        }
    }

    async function deleteItem(id) {
        setDisplayDeleteItem(false);
        await Methods.deleteGeneralista({ id: id });
        getData();
    }

    async function editItem(id, name, email) {
        let exists = false;

        for (let i = 0; i < data.length; i++) {
            if (data[i].name == name) {
                exists = true;
                throw new Error("Datos ingresados presentes en generalista existente");
            }
        }

        if (exists == false) {
            setDisplayEditItem(false);
            await Methods.editGeneralista({ id, name, email });
            getData();
        }
    }

    React.useEffect(getData, []);

    function deleteItemHandler(k) {
        setSelectedItem(k);
        setDisplayDeleteItem(true);
    }

    function editItemHandler(k) {
        setSelectedItem(k);
        setDisplayEditItem(true);
    }


    return <div className="approve-orgs-and-comps">
        <div style={{ display: "flex", alignItems: "center" }}>
            <BiArrowBack onClick={() => props.setTab("listCompanies")} className="back" />
            <h4>Generalistas</h4>
        </div>
        <h3 className="company">{props.companyName}</h3>

        <div className="alter-table">
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Dar de Alta a Grupo</Tooltip>}
            >
                <div className="add-company" onClick={() => setDisplayCreateItem(true)}><FiPlus /></div>
            </OverlayTrigger>


            <div className="search-icon"><RiSearch2Line /></div>
            <Form.Group controlId="search-bar" className="search-container">
                <Form.Control
                    id="search-text"
                    type="text"
                    placeholder="Búsqueda de generalistas"
                    autoComplete={"off"}
                    onInputCapture={async (e) => {
                        setSearchQuery(e.target.value);
                    }}
                />
            </Form.Group>

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
            >
                <a href="#" className="reload-table" onClick={getData}><IoReload /></a>
            </OverlayTrigger>
        </div>

        <Row>
            <div className="list-companies" style={{ width: "100%", }}>
                <div className="title-container" >
                    <h5>Generalistas</h5>
                </div>
                {data && data.filter(k => {
                    return k.name.toLowerCase().startsWith(searchQuery.toLowerCase());
                }).map(k => {
                    return <div className="table-row" style={{ height: "60px" }} key={k._id}>
                        <div className="name-container">
                            {k.name}
                        </div>
                        <div className="name-container">
                            {k.email}
                        </div>
                        <div className="options-container">
                            <FiEdit onClick={() => editItemHandler(k)} className="groups-icon" />
                            <BsTrash color="red" onClick={() => deleteItemHandler(k)} className="groups-icon" />
                        </div>
                    </div>
                })}
            </div>
        </Row>

        <CreateItemModal
            displayModal={displayCreateItem}
            hideModal={() => { setDisplayCreateItem(false) }}
            createItem={createItem}
        />
        <EditItemModal
            displayModal={displayEditItem}
            hideModal={() => { setDisplayEditItem(false) }}
            selectedItem={selectedItem}
            editItem={editItem}
        />
        <DeleteItemModal
            displayModal={displayDeleteItem}
            hideModal={() => setDisplayDeleteItem(false)}
            selectedItem={selectedItem}
            deleteItem={deleteItem}
        />
    </div>
};

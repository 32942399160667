import React from 'react'
import {Modal, Button, Form, Col, Row} from 'react-bootstrap';
import Methods from '../../../utils/https';
import moment from 'moment'

export const SendPrefactureToCompaq = ({approvedInterns, showSendPrefacture, sendPrefactureAux,  setSendPrefactureAux, updateList, setUpdateList, requirePurchaseOrder, idCompany, company, period, lastPeriod, isConsolidationPrefacture, context}) => {

    const sendPrefacture = async () => {
        const periodAux = new Array(...period);
        const periodText = sendPrefactureAux.observations.toLowerCase();
        const record = {
            message: 'Timbrado de prefactura del' + periodText,
            author: `${context.user.data.fullName}`,
            role: context.user.data.role,
            companyId: company._id,
            createdAt: new Date(),
            authorEmail: context.user.data.email,
        };

        if(requirePurchaseOrder){
            setSendPrefactureAux({...sendPrefactureAux, showSendPrefacture: false, showPurchaseOrderModal: true, record: record})
        }else{
            if(isConsolidationPrefacture && lastPeriod){
                periodAux[0] = moment(lastPeriod.start_date, 'DD/MM/YYYY');
            }
            let prefactureDetailId = approvedInterns[0].prefacture_detail_id
            await Methods.setCompaqPrefacture(prefactureDetailId, undefined, idCompany, company, sendPrefactureAux.observations, sendPrefactureAux.authorizedInfo, periodAux);
            await Methods.createRecord(record);
            handleClose();
            setUpdateList(!updateList);
        }       
    }

    const handleClose = () => {
        setSendPrefactureAux({...sendPrefactureAux, showSendPrefacture: false})
    }

    return (
        <Modal className="new-contpaq-modal purchase-padding" onHide={() => {handleClose()}} show={showSendPrefacture} centered>
            <Modal.Body>
                <Form autoComplete={"off"}>
                    <Modal.Header className='contpaq-margin'>
                        <h3 className='purchase-order-title' style={{marginBottom:'-5px', paddingLeft:'0px'}}>Timbrar</h3>
                    </Modal.Header>
                    <Row className='contpaq-margin'>
                        <Col>              
                            <Form.Label className='center-modal-text' style={{marginTop: '30px'}}>
                                ¿Estás seguro de que quieres timbrar esta factura?
                            </Form.Label>
                        </Col>
                    </Row>
                    
                    <Modal.Body className='contpaq-margin'>
                        <div className='center-buttons'>
                            <Button className="float-right" variant="danger" type="button" onClick={() => handleClose()}>
                                Cancelar
                            </Button>

                            <Button className="float-right ml-2" variant="primary" onClick={() => sendPrefacture()}>
                                Confirmar
                            </Button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

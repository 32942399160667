import React, {useEffect, useState} from 'react'
import Methods from '../../../utils/https';
import redPeopleIcon  from "../../../assets/IconsCaintra/redPeopleIcon.svg";
import bluePeopleIcon  from "../../../assets/IconsCaintra/bluePeopleIcon.svg";
import greenPeopleIcon  from "../../../assets/IconsCaintra/greenPeopleIcon.svg";
import { RedirectIntern } from './RedirectIntern';
import {useHistory} from 'react-router-dom';
export const RedirectInternDashboards = ({
    context
}) => {
    const companyId = context?.user?.company?._id;
    const [internsInProcess, setInternsInProcess] = useState({
        total: 0,
        interns: [],
        isLoading: true
    });
    const [internsToFinish, setInternsToFinish] = useState({
        total: 0,
        interns: [],
        isLoading: true
    });
    const [activeInterns, setActiveInterns] = useState({
        total: 0,
        isLoading: true
    });

    const navigate = useHistory();

    useEffect(() => {
        getInternsInProcess();
        getActiveInterns();
        getInternsToFinish();
    }, []);
    
    const getInternsToFinish = async() => {
        let result = await Methods.getInternsToFinishDashboard(companyId);
        setInternsToFinish({
            total: result?.length || 0,
            interns: result,
            isLoading: false
        })
    }

    const getInternsInProcess = async() => {
        let result = await Methods.getInternsInProcessDashboard(companyId);
        setInternsInProcess({
            total: result?.length || 0,
            interns: result,
            isLoading: false
        })
    }

    const getActiveInterns = async() => {
        let onlyActives = true;
        let result = await Methods.getCountActiveInactiveinterns(companyId, onlyActives);
        setActiveInterns({
            total: result.interns?.actives || 0, 
            isLoading: false
        })
    }

    const redirectToActiveInterns = () => {
        context.setDashboardRedirectData({
            setActiveInterns: true
        }); 
        navigate.push('/interns');
    }

    const redirectToInternsToFinish = () => {
        context.setDashboardRedirectData({
            interns: internsToFinish.interns,
            isRedirected: true,
            internStatusSelected: 'Activo'
        }); 
        navigate.push('/interns');
    }

    const redirectToInternsInProcess = () => {
        context.setDashboardRedirectData({
            interns: internsInProcess.interns,
            isRedirected: true,
            internStatusSelected: 'En proceso'
        }); 
        navigate.push('/interns');
    }


    
    return (
        <div className='intern-redirect-container'>
            <RedirectIntern
                iconSrc={bluePeopleIcon}
                totalInterns={internsInProcess.total}
                label={'Practicantes en proceso'}
                isLoading={internsInProcess.isLoading}
                customClass={'start-container'}
                redirectAction={redirectToInternsInProcess}
            />
            <RedirectIntern
                iconSrc={greenPeopleIcon}
                totalInterns={activeInterns.total}
                label={'Practicantes activos'}
                isLoading={activeInterns.isLoading}
                customClass={'middle-container'}
                redirectAction={redirectToActiveInterns}
            />
            <RedirectIntern
                iconSrc={redPeopleIcon}
                totalInterns={internsToFinish.total}
                label={'Practicantes por vencer'}
                isLoading={internsToFinish.isLoading}
                customClass={'end-container'}
                redirectAction={redirectToInternsToFinish}
            />
        </div>
    )
}

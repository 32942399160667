import React, {useState, useEffect, useContext} from 'react';
import Methods from '../../utils/https';
import {UserContext} from '../../context/UserContext';
import {Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faCalendar} from '@fortawesome/free-solid-svg-icons';
import ListPrefactures from './components/ListPrefactures.jsx';
import Dropdown from '../../custom/Dropdown/Dropdown.jsx';
import { CSVLink } from "react-csv";
import ChangePeriod from './components/ChangePeriod.jsx';
import moment from 'moment';
import './Pending.css';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';

export default function Pending(){
    const [companies, setCompanies] = useState([]);
    const [interns, setInterns] = useState([]);
    const [payDate, setPayDate] = useState([
        null, 
        new Date(new Date().getFullYear(), new Date().getMonth(), 15)
    ]);
    const [selectedInterns, setSelectedInterns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const context = useContext(UserContext);
    const companyId = context.user.company._id;
    const [selectedCompanies, setSelectedCompanies] = useState([companyId]);
    const [selectedBank, setSelectedBank] = useState("Santander");
    const [dateToDisperse, setDateToDisperse] = useState(new Date());
    const [csvData, setCSVData] = useState([]);
    const [showChangePeriodModal, setShowChangePeriodModal] = useState(false);

    useEffect(() => {
        getApprovedPrefactures();
    }, [payDate, selectedCompanies]);

    const getApprovedPrefactures = async () => {
        const companiesData = await Methods.getApprovedCompanies();
        const internsData = await Methods.getApprovedPrefactures(payDate, selectedCompanies);
        
        setSelectedInterns([]);
        internsData ? setInterns(internsData) : setInterns([]);
        setCompanies(companiesData);
        setIsLoading(false);
    };

    const handleSetSelectedCompanies = (_companies) => {
        let data = _companies.map(company => company._id);
        setSelectedCompanies(data);
    };

    const getCompanyName = (_companyId) => {
        for(let company of companies){
            if(_companyId === company._id) return company.business_name;
        };

        return "N/A";
    };

    const getCompanyPaymentPeriod = (_companyId) => {
        for(let company of companies){
            if(_companyId === company._id) return company.payment_period;
        };

        return "N/A";
    };

    const generateCSVData = () => {
        let data = selectedInterns.map((intern, index) => {
            return({
                "Compañía": getCompanyName(intern.id_company),
                "Nombres": intern.firstname,
                "Apellido Paterno": intern.father_lastname,
                "Apellido Materno": intern.mother_lastname,
                "Periodo de pago": getCompanyPaymentPeriod(intern.id_company),
                "Fecha de pago": moment(payDate[0]).format("D/MM/YYYY"),
                "Fecha a dispersar": moment(dateToDisperse).format("D/MM/YYYY"),
                "Banco": intern.bank,
                "Monto a dispersar": intern.dispersionAmount,
                "Estatus": intern.dispersionStatus,
                "": "" // This is a space required to separate both tables
            })
        });

        setCSVData(data);
    };

    return(
        <div className="pending">
        {isLoading 
        ? <p>Cargando...</p>
        : (
            <>
                <h4>Pendientes</h4>

                <div className="alter-table">
                    <Button className="disperse-button" onClick={() => setShowChangePeriodModal(!showChangePeriodModal)}>
                        <IoCalendarClearOutline/>
                        Cambiar período
                    </Button>
                    <Dropdown 
                        className="dropdown"
                        items={companies} 
                        title={"Compañía a consultar"} 
                        label="business_name"
                        setSelected={handleSetSelectedCompanies}
                        firstChecked
                    />
                    <CSVLink data={csvData} className="csv-button" onClick={() => generateCSVData()} filename={`Pendientes.csv`}>
                        <BsDownload/>
                        CSV
                    </CSVLink>
                </div>

                <Row>
                    <Col>
                        <ListPrefactures 
                            interns={interns} 
                            payDate={payDate}
                            setPayDate={setPayDate} 
                            selectedBank={selectedBank}
                            setSelectedBank={setSelectedBank}
                            dateToDisperse={dateToDisperse}
                            setDateToDisperse={setDateToDisperse}
                            getCompanyName={getCompanyName}
                            getCompanyPaymentPeriod={getCompanyPaymentPeriod}
                            setSelectedInterns={setSelectedInterns}
                        />
                    </Col>
                </Row>

                <ChangePeriod 
                    showChangePeriodModal={showChangePeriodModal} 
                    setShowChangePeriodModal={setShowChangePeriodModal} 
                    payDate={payDate} 
                    setPayDate={setPayDate} 
                />
            </>
            )
        }
        </div>
    );
};
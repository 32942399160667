
export default {
    
    test(workbook, values, configuration, getLabel, selectedBank, date, createdBy) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        const data = {
            'cols': ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL"],
            'worksheetName': "Reporte",
            'headerText': "Reporte"
        }

        let config = configuration.filter(configAux => configAux != 'logisticActions')

        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })

        

        let width = []
        config.forEach(item => {
            width.push({ width: 40 });
        });
        worksheetPicoUsoInfra.columns = width;

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'013365'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'ffffff'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){

            createBlueCell(
                "A1",
                "Fecha de generación:",
                worksheetName
            )
            createWhiteCell(
                "B1",
                date,
                "",
                worksheetName
            )


            createBlueCell(
                "C1",
                "Generado por:",
                worksheetName
            )
            createWhiteCell(
                "D1",
                createdBy,
                "",
                worksheetName
            )
        }

        function createTableAvail(worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            config.map((configItem, index) => {
                createBlueCell(
                    data.cols[index] + cellNumber,
                    getLabel(configItem),
                    worksheetName
                )
            });


            if(values.length){
                values.map((item, index) => {
                    cellNumber++
                    config.map((configItem, indexCol) => {
                        let key = getKeyByName(configItem);
                        createWhiteCell(
                            data.cols[indexCol] + cellNumber,
                            getFormatedValues(item[key]) || getFormatedValues(item[selectedBank]?.[key]) || '',
                            '',
                            worksheetName
                        )
                    });


                })
            }
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}


const getKeyByName = (key) => {
    let differentKeyName = {
        'logisticColorStatus': 'logisticStatus',
    } 

    return differentKeyName[key] || key
}

const getFormatedValues = (value) => {
    
    let valuesAux = {
        'true': 'Sí',
        'false': 'No'
    } 

    return valuesAux[JSON.stringify(value || '')] || value
}
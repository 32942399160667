import React from 'react'
import { Modal } from 'react-bootstrap';
const $ENV = "prod";
export const VideoPlayer = ({showVideoPlayer, setShowVideoPlayer}) => {

    const handleClose = () => {
        setShowVideoPlayer(!showVideoPlayer)
    }   

    return (
        <Modal 
            dialogClassName='modal-width' 
            contentClassName='modal-height' 
            show={showVideoPlayer} 
            centered
            onHide={() => handleClose()}>
            <video 
                className='video-player' 
                controls muted controlsList="nodownload" autoplay="autoplay">
                    <source 
                        src={`https://caintrabucket-${($ENV == 'prod') ? 'prod' : $ENV}.s3.amazonaws.com/mailImages/prefacturaTutorial.mp4`}
                    />
            </video>
        </Modal>
    )
}

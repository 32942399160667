import React, {useContext, useState} from 'react';
import {UserContext} from '../../context/UserContext';
import ListInterns from './components/ListInterns.jsx';
import { ListInvoiceRecords } from './components/ListInvoiceRecords';
import {Row, Col} from 'react-bootstrap';
import './Reports.css';
import {ListLogisticsRecods} from './components/ListLogisticsRecods';
import { ListInsuranceErrors } from './components/ListInsuranceErrors';
const axios = require("axios");

export default function Reports (){
    const context = useContext(UserContext);
    const id_company = context.user.company._id
    const [selectedClass, setSelectedClass] = useState(
        (context.user.data.isCaintraUser && !context.user.data.isSupervisor) ? {
            internsSection: 'selected', 
            invoiceSection: 'section',
            logisticSection: 'section',
            insuranceSection: 'section'
        }:
        {
            internsSection: 'selected', 
            logisticSection: 'section',
        }
    );

    const {internsSection, invoiceSection, logisticSection, insuranceSection} = selectedClass;

    const setSelection = (selected) => {
        let valuesAux = {...selectedClass}
        Object.keys(valuesAux).forEach(v => valuesAux[v] = 'section');
        setSelectedClass({
            ...valuesAux,
            [selected]: 'selected'
        })
    }

    return (
        <div className="reports prefecture">
            <div className='reports-header-container'>
                <div className="title">
                    <h4>Reportes</h4>
                </div>
                <div>
                     <Row className="row-selection">
                        <a href="#" className={internsSection} onClick = { () => {
                            setSelection('internsSection');
                        }}>
                            <p className={`text-${internsSection}`}>Practicantes</p>
                        </a>
                        {(context.user.data.isCaintraUser && !context.user.data.isSupervisor) && 
                            <a href="#" className={invoiceSection} onClick = { () => {
                                setSelection('invoiceSection');
                            }}>
                            <p className={`text-${invoiceSection}`}>Facturación</p>
                        </a>}
                        
                        <a href="#" className={logisticSection} onClick = { () => {
                            setSelection('logisticSection');
                        }}>
                            <p className={`text-${logisticSection}`}>Logística</p>
                        </a>
                        
                        {context.user.data.isCaintraUser && 
                            <a href="#" className={insuranceSection} onClick = { () => {
                                setSelection('insuranceSection');
                            }}>
                            <p className={`text-${insuranceSection}`}>Seguros</p>
                        </a>
                        }
                    </Row>
                </div>
            </div>
            {internsSection == 'selected' && <ListInterns context={context} id_company={id_company}/>}
            {invoiceSection == 'selected' && <ListInvoiceRecords context={context} id_company={id_company}/>}
            {logisticSection == 'selected' && <ListLogisticsRecods context={context} id_company={id_company}/>}
            {insuranceSection == 'selected' && <ListInsuranceErrors context={context} id_company={id_company}/>}
        </div>
    );
}
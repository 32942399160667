import React, {useEffect, useState, useContext} from 'react';
import {encrypt, decrypt} from '../../utils/crypto';
import {notify} from '../../utils/notify';
import Methods from '../../utils/https.js';
import {UserContext} from '../../context/UserContext';
import {Row, Col, OverlayTrigger, Tooltip, Form, Button, InputGroup} from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faSyncAlt, faChevronRight, faChevronLeft, faSearch} from '@fortawesome/free-solid-svg-icons';
import ListCompanies from './components/ListCompanies.jsx';
import CreateCompany from './components/CreateCompany.jsx';
import VerifyCompanyData from './components/VerifyCompanyData.jsx';
import Users from './components/Users.jsx';
import Advisors from './components/Advisors.jsx';
import CreateUser from './components/CreateUser.jsx';
import Roles from './components/Roles.jsx';
import ReactPaginate from "react-paginate";
import DeleteCompanyModal from './components/DeleteCompanyModal.jsx';
import UnsuscribeCompanyModal from './components/UnsuscribeCompanyModal.jsx';
import ManageDepartments from './components/ManageDepartments.jsx';
import { DropdownList } from "react-widgets";
import './Companies.css';
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import CostsCenter from '../CostsCenter/CostCenters';   
import Generalistas from "../Generalistas/Generalistas";
const axios = require("axios");
const URL = process.env.REACT_APP_SOCKET_URL;

export default function Companies (){
    const context = useContext(UserContext);
    const companiesArr = context.user.data.companies;
    const role = context.userRole;
    const [pendingCompanies, setPendingCompanies] = useState([]);
    const [rotateSync, setRotateSync] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [tab, setTab] = useState("listCompanies");
    const [currentCompanyId, setCurrentCompanyId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(undefined);
    const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState({});
    const [allCompaniesByPage, setAllCompaniesByPage] = useState([]);
    const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);
    const [showUnsuscribeCompanyModal, setShowUnsuscribeCompanyModal] = useState(false);
    const rotate = rotateSync ? "rotate" : "";
    const [query, setQuery] = useState("");
    const [isQueryFilter, setIsQueryFilter] = useState(false);
    const [currentLimit, setCurrentLimit] = useState(11);
    const [availableDepartments, setAvailableDepartments] = useState(undefined);
    const [adminCompanies, setAdminCompanies] = useState(context.user.data.companies);
    const currentCompany = context.user ? context.user.company.business_name : "";
    const isMirrorAdmin = context.user.data.mirrorAdmin || false;
    useEffect(() => {
        getCompanies();      
    }, [updateList]);

    const search = async (e) => {
        let text;

        if (!e) {
            const searchText = document.getElementById("search-text");
            text = searchText.value;
        } else {
            text = e.target.value;
        }
        text = text.toUpperCase();

        if(text === "") {
            await verifyRole();
            setQuery("");
            setIsQueryFilter(false);
            return;
        }

        await verifyRole(undefined, undefined, currentLimit, true, text);
        setQuery(text);
        setIsQueryFilter(true);
    }

    const getCompanies = async () => {
        setIsLoading(true);
        let _companiesByPage;

        if(role === "Administrador Caintra" || role === "Supervisor"){
            _companiesByPage = await getPagesLimit();
        }else{
            _companiesByPage = await getPagesLimitByUser(companiesArr);
        }

        if(context.can("approve", "Companies") && !isMirrorAdmin){
            const _pending = await Methods.getPendingCompanies();
            setPendingCompanies(_pending || []);
        };
        if(_companiesByPage) setAllCompaniesByPage(_companiesByPage);
        setIsLoading(false);
        setRotateSync(false);
    };

    const verifyRole = async (startKey, _page, _limit, isQuery, query) =>{
        let _companiesByPage;
        if(role === "Administrador Caintra" || role === "Supervisor"){
            _companiesByPage = await getPagesLimit(startKey, _page, _limit, isQuery, query);
       } else {
            _companiesByPage = await getPagesLimitByUser(companiesArr, startKey, _page, _limit, isQuery, query);
       };
       if(_companiesByPage) setAllCompaniesByPage(_companiesByPage);
       setUpdateList(updateList);
    }; 

    const getPagesLimit= async (startKey, _page, _limit, isQuery, query)=> {
        try {
            const page = _page || 1;
            const limit = _limit || 11;
            const data = {limit: limit};
            if(startKey) data.startKey = startKey;
            if(isQuery){
               data.isQuery = isQuery;
               data.query = query;
            }
            
            let response = await axios({
              url: `${URL}/companies/list/${page}`,
              method: "POST",
              data: data
            }); 
            const decrypted = decrypt(response.data.companies);
            const pages = response.data.pages; 
            const lastEvaluatedKey = response.data.lastEvaluatedKey;
            setTotalPages(pages);
            setLastEvaluatedKeys({...lastEvaluatedKeys, [page]: lastEvaluatedKey});       
            return JSON.parse(decrypted);
          } catch (e) {
            e.response && notify(e.response.data.message, "error");
          };
    }

    const getPagesLimitByUser= async (companiesArr, startKey, _page, _limit, isQuery, query)=> {
        try {
            const page = _page || 1;
            const limit = _limit || 11;
            const data = {
                limit: limit, 
                companiesArr: companiesArr
            };
            if(startKey) data.startKey = startKey;
            if(isQuery){
                data.isQuery = isQuery;
                data.query = query;
            }
            let response = await axios({
              url: `${URL}/companies/user/list/${page}`,
              method: "POST",
              data: data
            }); 
            const decrypted = decrypt(response.data.companies);
            const pages = response.data.pages; 
            const lastEvaluatedKey = response.data.lastEvaluatedKey;
            setTotalPages(pages);
            setLastEvaluatedKeys({...lastEvaluatedKeys, [page]: lastEvaluatedKey});     
            return JSON.parse(decrypted);
          } catch (e) {
            e.response && notify(e.response.data.message, "error");
          };
    }

    const handleChangePage = (_page, _limit) => {     
        if(_page > 1){
           verifyRole(lastEvaluatedKeys[_page-1]._id, _page, _limit, isQueryFilter, query);
        } else {
           verifyRole(undefined, undefined, _limit, isQueryFilter, query);
        };
    };

    return(
        <>
        {tab === "listCompanies" && 
            <div className="approve-orgs-and-comps">
                <h4>Gestión de tus compañías</h4>
                <h3 className="company">{currentCompany}</h3>

                <div className="alter-table">
                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Dar de Alta a Compañía</Tooltip>}
                    >
                        <div className="add-company" onClick={() => setTab("createCompany")}><FiPlus/></div>
                    </OverlayTrigger>

                    <div onClick={ () => search() } className="search-icon"><RiSearch2Line/></div>
                    <Form.Group controlId="search-bar" className="search-container">
                        <Form.Control 
                            id="search-text"
                            type="text" 
                            placeholder="Búsqueda de compañía" 
                            autoComplete={"off"}
                            onKeyDown={ (e) => e.keyCode == 13 && search(e) }
                            onInputCapture={ (e) => !e.target.value && search(e) }
                        />
                    </Form.Group>

                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                    >
                        <a href="#" className="reload-table" onClick={() => {setUpdateList(!updateList); setRotateSync(true)}}><IoReload className={rotate}/></a>
                    </OverlayTrigger>
                </div>

                <Row>
                    <ListCompanies 
                        companies={allCompaniesByPage} 
                        setTab={setTab} 
                        setCurrentCompanyId={setCurrentCompanyId} 
                        isLoading={isLoading} 
                        pendingCompanies={pendingCompanies} 
                        setUpdateList={setUpdateList} 
                        updateList={updateList}
                        showDeleteCompanyModal={showDeleteCompanyModal} 
                        setShowDeleteCompanyModal={setShowDeleteCompanyModal}
                        showUnsuscribeCompanyModal={showUnsuscribeCompanyModal} 
                        setShowUnsuscribeCompanyModal={setShowUnsuscribeCompanyModal}
                       
                    />
                </Row>

                <Row className="float-right">
                    <div className="interns-list-options float-right">
                        <p>Elementos por página:</p>
                        <DropdownList
                            data={[10, 20, 40, 60, 100]}
                            defaultValue={10}
                            onSelect={(e) => { setCurrentLimit(e); verifyRole(undefined, undefined, e+1, isQueryFilter, query);}}
                            containerClassName="limit-container"
                        />
                        <ReactPaginate
                            pageCount={totalPages || 1} 
                            containerClassName="pagination-container" 
                            previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                            nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                            onPageChange={(e) => { handleChangePage(e.selected+1, currentLimit);}}
                        /> 
                    </div>
                </Row>

                <DeleteCompanyModal 
                        setUpdateList={setUpdateList} 
                        setShowDeleteCompanyModal={setShowDeleteCompanyModal} 
                        showDeleteCompanyModal={showDeleteCompanyModal} 
                        currentCompanyId={currentCompanyId} 
                        updateList={updateList} 
                />

                <UnsuscribeCompanyModal 
                        setUpdateList={setUpdateList} 
                        showUnsuscribeCompanyModal={showUnsuscribeCompanyModal} 
                        setShowUnsuscribeCompanyModal={setShowUnsuscribeCompanyModal}
                        currentCompanyId={currentCompanyId} 
                />

            </div>
        }

        {tab === "createCompany" && 
        <CreateCompany 
            setTab={setTab} 
            updateList={updateList} 
            setUpdateList={setUpdateList}
        />}

        {tab === "CostCenters" &&
        <CostsCenter
            setTab={setTab}
            currentCompanyId={currentCompanyId}
            companyName={allCompaniesByPage.filter(company => company._id == currentCompanyId)[0].business_name}
        />}

        {tab === "Generalistas" &&
        <Generalistas
            setTab={setTab}
            currentCompanyId={currentCompanyId}
            companyName={allCompaniesByPage.filter(company => company._id == currentCompanyId)[0].business_name}
        />}
        
        {tab === "verifyCompanyData" && 
        <VerifyCompanyData 
            companies={allCompaniesByPage} 
            currentCompanyId={currentCompanyId} 
            setTab={setTab} 
            updateList={updateList} 
            setUpdateList={setUpdateList} 
            pendingCompanies={pendingCompanies}
        />}
        
        {tab === "Users" && 
        <Users 
            currentCompanyId={currentCompanyId}
            setTab={setTab}
            availableDepartments={availableDepartments}
            setAvailableDepartments={setAvailableDepartments}
            companies={allCompaniesByPage}
        />}

        {tab === "Advisors" && 
        <Advisors 
            currentCompanyId={currentCompanyId}
            setTab={setTab}
            companies={allCompaniesByPage}
        />}

        {tab === "createUser" &&
        <CreateUser
            currentCompanyId={currentCompanyId}
            updateList={updateList}
            setUpdateList={setUpdateList}
            setTab={setTab}
            availableDepartments={availableDepartments}
            setAvailableDepartments={setAvailableDepartments}
            adminCompanies={adminCompanies}
        />
        }

        {tab === "Roles" &&
        <Roles
            currentCompanyId={currentCompanyId}
            updateList={updateList}
            setUpdateList={setUpdateList}
            setTab={setTab}
        />
        } 
        { 
        tab == "Departments" &&
        <ManageDepartments 
            currentCompanyId={currentCompanyId}
            context={context} 
            setTab={setTab}
        />
        }
        </>
    );
};
import React, {useState} from 'react';
import {Modal, Image, Form, Button, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function DeleteCareerModal ({displayCareerModal, setDisplayCareerModal, currentCareerId, updateList, setUpdateList, currentCareerName, currentUniversity}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);
    const deleteCareer = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const confirmation_code = e.target.elements.confirmation.value;

        if(confirmation_code.toLowerCase().trim() != "eliminar permanentemente"){
            notify("La confirmación es incorrecta", "error");
            setIsLoading(false);
            return;
        };

        try {
            await axios({
                url: `${URL}/careers`,
                method: "DELETE",
                data: {id_career: currentCareerId, university_name: currentUniversity , career_name: currentCareerName}
            });

            setIsLoading(false);
            notify("Registro eliminado con éxito", "success");
            setUpdateList(!updateList);
            setDisplayCareerModal(!displayCareerModal);
        } catch (e) {
            setIsLoading(false);
            e.response && notify(e.response.data.message, "error");
        };
    };
    return(
        <Modal className="new-cc-modal" show={displayCareerModal} onHide={() => setDisplayCareerModal(!displayCareerModal)} centered>
            <Modal.Header>
                <p>Eliminar Registro</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={deleteCareer} autoComplete={"off"}>

                    <Form.Group controlId="confirmation">
                        <Form.Label>Escribe "eliminar permanentemente" para continuar</Form.Label>
                        <Form.Control type="text" required/>
                        <small>¡Estos cambios son irreversibles!</small>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Aceptar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayCareerModal(!displayCareerModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import { useState, useEffect, useContext } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { Table, Accordion, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BankAccordion } from './BankAccordion';
import { DateSelector } from '../../../custom/DateSelector/DateSelector';
import { getDataByBanks, getDispersionByEmailAndDate, getFormatedAmount, createExcel } from '../helpers';
import { notify } from '../../../utils/notify';
import { TableReport } from '../components/TableReport';
import { ConfirmDeteleFile } from './ConfirmDeteleFile';
import { UserContext } from '../../../context/UserContext';
import { IoReload } from 'react-icons/io5';
import { ConfirmDispersionTotalExpected } from './ConfirmDispersionTotalExpected';
import Methods from '../../../utils/https';
import moment from 'moment';
import { saveAs } from 'file-saver';
export const AmountReport = () => {

    const context = useContext(UserContext);
    const [accordionOpened, setAccordionOpened] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [currentData, setCurrentData] = useState([]);
    const [dataByBanks, setDataByBanks] = useState([]);
    const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [reloadPage, setReloadPage] = useState([]);
    const [updateList, setUpdateList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dispersionsExpected, setDispersionsExpected] = useState(false);
    const [showDispersionTotalExpected, setShowDipsersionTotalExpected] = useState();

    useEffect(() => {
        getDispersionsData();
        getTotalDispersionsExpected();
    }, [startDate, reloadPage, updateList]);  

    const getDispersionsData = async() => {
        setIsLoading(true);
        let currentData = await getDispersionByEmailAndDate(startDate);
        setDataByBanks([]);
        setCurrentData(currentData);
        setIsLoading(false);
    }

    const getTotalDispersionsExpected = async() => {
        let dispersionAux = await Methods.getDispersionNumber();
        setDispersionsExpected(dispersionAux.number || 0);
    }

    const reportsValidations = () => {


        if(!dataByBanks.length){
            notify('No hay archivos seleccionados', 'error');
            return false;
        }

        if( dataByBanks.length != dispersionsExpected){
            setShowDipsersionTotalExpected(true);
            return false;
        }

        return true;
    }

    const sendReport = async () => {
        let files = [];
        setIsLoading(true);

        for(let bankAux of dataByBanks){
            files = files.concat(bankAux.files);
        }

        try {
            let blobResult = await createExcel(dataByBanks, context.user.data.fullName);

            const blobToBase64 = blob => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            blobResult = await blobToBase64(blobResult);

            await Methods.sendAmountEmail({date: moment(startDate).startOf('day').format('DD/MM/YYYY'), url:files, excelFile: blobResult})
            notify("Reporte enviado correctamente", "success");
        } catch (error) {
            notify("Error al enviar reporte", "error");
        }

        setIsLoading(false);
    }

    const checkAdviser = (adviser) => {
        adviser.isChecked = !adviser.isChecked;
        if(adviser.banks.length && adviser.isChecked){
            adviser.total = 0;
            for(let bank of adviser.banks){
                adviser.total += bank.total;
                bank.isChecked = true;
            }
            let data = getDataByBanks(dataByBanks, adviser, 'add');
            setDataByBanks(data);
        }else{
            adviser.total = 0;
            for(let bank of adviser.banks){
                bank.isChecked = false;
            }
            let data = getDataByBanks(dataByBanks, adviser, 'remove');
            setDataByBanks(data);
        }       
        setCurrentData([...currentData]);
    }

    const downLoadExcel = async() => {
        let blob = await createExcel(dataByBanks, context.user.data.fullName);
        if(blob){
            saveAs(blob, 'Montos.xlsx');
        }
    }

    return (

        <div className='max-vh'>

            <div className='date-selector-container'>
                <DateSelector
                    setDateAux={setStartDate}
                    label={'Fecha de inicio'}
                    dateAux={startDate}
                />

                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                    >
                        <div className="reload-button" onClick={() => {setUpdateList(!updateList);}}><IoReload className={isLoading ? "rotate" : ""}/></div> 
                </OverlayTrigger>

                <Button className='save-button' onClick={ async() => {
                    let result = reportsValidations();
                    if(result){
                        await sendReport();
                    }}}>
                    Envío de correos
                </Button>

                <Button className='save-button' onClick={ async() => await downLoadExcel() }>
                    Descargar Excel
                </Button>
            </div>

            <motion.div 
                initial={{y: -30, opacity: 0}} 
                animate={{y: 0, opacity: 1, background: "#d7d7d7"}} 
                transition={{ duration: 0.4 }} 
                className="table-border"
            >
                <Table hover>
                    <thead>
                        <tr>
                            <th className='wi-110'>
                                <div style={{paddingLeft: 10}} className='check-container'>
                                    Asesora
                                </div>
                            </th>
                            <th className='wi-90' >Banco</th>
                            <th className='' >Empresa</th>
                            <th className='wi-110' >Total de practicantes</th>
                            <th className='wi-110' >Monto total</th>
                            <th className='wi-90'> </th>
                        </tr>
                    </thead>
                </Table>


                {currentData.map((adviser, eventKeyIndex) => {
                    return (   
                        <Accordion defaultActiveKey={String(eventKeyIndex)} key={eventKeyIndex}>
                            <Card>
                                <Card.Header>
                                    <div style={{paddingLeft: 10}} className='check-container'>
                                        <input
                                            type="checkbox"
                                            checked={adviser.isChecked}
                                            onChangeCapture={() => checkAdviser(adviser)}                                                           
                                        />
                                        <Accordion.Toggle 
                                            as={Button} 
                                            variant="link" 
                                            eventKey="0" 
                                                onClick={() => {
                                                    let accordionOpenedAux = accordionOpened
                                                    if(accordionOpenedAux.includes(eventKeyIndex)){
                                                        accordionOpenedAux = accordionOpenedAux.filter(id => id !== eventKeyIndex); 
                                                    }else{
                                                        accordionOpenedAux.push(eventKeyIndex);
                                                    }
                                                    setAccordionOpened([...accordionOpenedAux]);
                                                }                             
                                            }>
                                            <div>
                                                <div>
                                                    {accordionOpened.includes(eventKeyIndex) ?
                                                        <FontAwesomeIcon icon={faMinusSquare} /> : 
                                                        <FontAwesomeIcon icon={faPlusSquare} />
                                                    } 
                                                    {adviser.adviserName}
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </div>
                                    <div>
                                        <b>Total asesor ${getFormatedAmount(adviser.total)}</b>
                                    </div>
                                </Card.Header>

                                <BankAccordion
                                    banks={adviser.banks}
                                    adviser={adviser}
                                    currentData={currentData}
                                    setCurrentData={setCurrentData}
                                    setDataByBanks={setDataByBanks}
                                    dataByBanks={dataByBanks}
                                    setShowConfirmationDeleteModal={setShowConfirmationDeleteModal}
                                    setFilesToDelete={setFilesToDelete}
                                />
                            </Card>
                        </Accordion>
                    ); 
                })}
            </motion.div>    

            <ConfirmDeteleFile
                setShowConfirmationDeleteModal={setShowConfirmationDeleteModal}
                showConfirmationDeleteModal={showConfirmationDeleteModal}
                filesToDelete={filesToDelete}
                setFilesToDelete={setFilesToDelete}
                setReloadPage={setReloadPage}
                reloadPage={reloadPage}
            />

            <ConfirmDispersionTotalExpected
                dispersionsExpected={dispersionsExpected}
                showDispersionTotalExpected={showDispersionTotalExpected}
                setShowDipsersionTotalExpected={setShowDipsersionTotalExpected}
                sendReport={sendReport}
            />
        
            <div className='total-container'>
                <div className='footer-total'> 
                   
                    <p>Total: {getFormatedAmount( currentData.reduce((acc, obj) => {
                        return acc + parseFloat(obj.total || 0);
                    }, 0))}</p>
                </div>
            </div>

            {dataByBanks.length ? <TableReport
                dataByBanks={dataByBanks}
            /> : <></>}
        </div>
    )
}

import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
const moment = require('moment');
const uuid = require("uuid");

export const saveDispersionFileAndRecords = async (
    file, 
    data, 
    caintraUser, 
    dispersionDate, 
    invoiceNumber,
    dispersionType,
    selectedBank,
    pendingFilesId,
    existingFileId,
    fileName,
    dateToDisperse
) => {
    
    
    try{

        let path = `dispersion/records/${caintraUser._id}`;
        let fileId = uuid.v1(); 
        let extension = 'txt';
        let fileNameAux = fileName;
        let validBanks = ['Santander', 'Bancomer', 'Banorte'];

        if(selectedBank !== "Bancomer" && selectedBank !== "Santander"){
            fileNameAux = fileNameAux + `_${caintraUser.email}`;
        }
        
        let s3fileName = `${fileId}_${fileNameAux}.${extension}`;
        let presignedUrlData = {
            path: `${path}/${s3fileName}`,
            fileName: s3fileName,
            fileKey: "DispersionFile"
        }

        if(file.size > 10485760){
            notify(`El archivo ${file.name} pesa más de 10 MB`, "error");
            throw new Error('El archivo persa más de 10 MB');
        };
        
        await Methods.getPresignedUrlAndPutImage(presignedUrlData, file);
        
        let fileDateToSearch = moment(dateToDisperse);

        if(!validBanks.includes(selectedBank)){
            fileDateToSearch.subtract(1, 'day');
        }

        let companyData = getDataByCompany(
            data, 
            dispersionType,
            selectedBank, 
            fileDateToSearch.format('DD/MM/YYYY'), 
            invoiceNumber,
            presignedUrlData.path,
            caintraUser,
            fileName,
            fileId
        );

        await Methods.createDispersionRecords(companyData);

        await Promise.all([ 
            pendingFilesId && Methods.setActionToDisperseByFileId(pendingFilesId, 'delete'),
            existingFileId && Methods.setActionToDisperseByFileId(existingFileId, 'delete')
        ])
    }catch(error){
        notify('Hubo un error al generar los archivos de dispersión', 'error');
    }

}   


const getDataByCompany = (
    data,
    dispersionType,
    selectedBank,
    dispersionDate,
    invoiceNumber,
    invoiceS3UrlPath,
    caintraUser,
    fileName,
    fileId
) => {
    try{
        
        let companies = [];
        
        for(let internRecord of data) {
            let [company] = companies.filter(companyData => companyData.companyId == internRecord.company_id);
            if(company){
                company.internsDispersed += 1;
                company.totalDispersed += parseFloat(parseFloat(internRecord.total_amount).toFixed(2)) || 0;
            }else{
                companies.push({
                    companyId: internRecord.company_id,
                    internsDispersed: 1,
                    totalDispersed: parseFloat(parseFloat(internRecord.total_amount).toFixed(2)) || 0,
                    dispersionType: dispersionType,
                    bank: selectedBank,
                    payDay: dispersionDate,
                    invoiceNumber: invoiceNumber,
                    invoiceS3Url: invoiceS3UrlPath,
                    dispersedBy: caintraUser.email,
                    dispersedById: caintraUser._id,
                    fileId: fileId,
                    companyName: internRecord.companyName || '',
                    fileName: fileName
                });
            }
        }
        return companies
    }catch(error){
        throw new Error(error)
    }
}

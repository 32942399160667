import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "./Configurations.css";
import StampInvoice from './components/StampInvoice';
import Calendar from './components/Calendar';
import Apis from './components/Apis';
import Mails from './components/Mails';
import PatchNotes from './components/PatchNotes';

export const Configurations = () => {
    const [currentTab, setCurrentTab] = useState("stamp");

    return (
        <div className="configurations">
            <div className="title">
                <h4>Configuración</h4>
            </div>
            <Row className="row-selection">
                <a href="#" onClick={(e) => { setCurrentTab("stamp") }} ><p className={`${currentTab == "stamp" && "selected"}`}>Timbrado de Facturas</p></a>

                <a><p style={{ color: "lightgray" }}>|</p></a>

                <a href="#" onClick={(e) => { setCurrentTab("calendar") }}>
                    <p className={`${currentTab == "calendar" && "selected"}`}>Calendarios</p>
                </a>
                <a><p style={{ color: "lightgray" }}>|</p></a>
                <a href="#" onClick={(e) => { setCurrentTab("api") }}>
                    <p className={`${currentTab == "api" && "selected"}`}>API's</p>
                </a>
                <a><p style={{ color: "lightgray" }}>|</p></a>
                <a href="#" onClick={(e) => { setCurrentTab("mail") }}>
                    <p className={`${currentTab == "mail" && "selected"}`}>Correos</p>
                </a>
                <a><p style={{ color: "lightgray" }}>|</p></a>
                <a href="#" onClick={(e) => { setCurrentTab("notes") }}>
                    <p className={`${currentTab == "notes" && "selected"}`}>Notas de la version</p>
                </a>
            </Row>
        
            { currentTab == "stamp" &&
                <StampInvoice
                
                />
            }
            { currentTab == "calendar" &&
                <Calendar
                
                />
            }
            { currentTab == "api" &&
                <Apis
                
                />
            }
            { currentTab == "mail" &&
                <Mails
                
                />
            }
            { currentTab == "notes" &&
                <PatchNotes
                
                />
            }
        </div>
    );
};

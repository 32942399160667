import React, {useContext} from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {UserContext} from '../../context/UserContext';
import {Combobox} from "react-widgets";

export default function ChangeCompany({setShowChangeCompany, showChangeCompany, textCompanies, seeAsCompany, findCompanyId, currentCompanyId}){
    const context = useContext(UserContext);
    let currentCompanyName = context.user ? context.user.company.business_name : "" 
    
    const changeCompanyId = async () => {
        context.cleanDataByDashboard();
        if(currentCompanyId != ""){
            seeAsCompany(currentCompanyId);
        }
        setShowChangeCompany(false);
    }

    return(
        <Modal size='lg' show={showChangeCompany} centered className="unsuscribe-intern-modal">
            <Modal.Body className="form-companies">
                <Form>
                    <Modal.Header>
                        <h5>Seleccionar Compañía</h5>
                    </Modal.Header>

                    <Form>
                        <Combobox
                            data={textCompanies}
                            defaultValue={currentCompanyName}
                            onSelect={value => findCompanyId(value)}
                            filter='contains'
                        />
                    </Form>

                    <Row className="btn-container">
                        <Col md={4} className="col">
                            <Button className="cancel-btn" onClick={() => setShowChangeCompany(!showChangeCompany)}>
                                Cancelar
                            </Button>    
                        </Col>
                        <Col className="col">
                            <Button className="confirm-btn ml-2" variant="primary" onClick={() => changeCompanyId()}>
                                Cambiar
                            </Button> 
                        </Col>


                    </Row>         
                </Form>
            </Modal.Body>
        </Modal>
    );
};
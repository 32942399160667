
export default {
    

    test(workbook, interns, batchNumber, date) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        const data = {
                'cols': ["A","B","C","D","E"],
                'config' : [{  width: 32,  value: "curp",              text: "CURP DEL EMPLEADO",                    getData: (intern) => { return intern.curp }  },
                            {  width: 32,  value: "email",             text: "E-MAIL DEL EMPLEADO",                  getData: (intern) => { return intern.email }  },
                            {  width: 32,  value: "phone",             text: "CELULAR DEL EMPLEADO A 10 DIGITOS",    getData: (intern) => { return intern.phone }  },
                            {  width: 20,  value: "bancomerData",      text: "SUCURSAL GESTORA DE LA CUENTA",        getData: (intern) => { return intern.bancomerData.branchOffice } },
                            {  width: 32,  value: "bancomerData",      text: "NUMERO DE TDD",                        getData: (intern) => { return intern.bancomerData.logisticDebitCard } },
                        ],
                'worksheetName': "Alta Banorte",
                'headerText': "Alta Banorte"
        }

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'013365'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'ffffff'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A3",
                "No. de Contrato de Nómina:",
                worksheetName
            )
            createWhiteCell(
                "B3",
                "4203342360",
                "",
                worksheetName
            )

            createBlueCell(
                "A4",
                "Número de lote:",
                worksheetName
            )
            createWhiteCell(
                "B4",
                batchNumber,
                "",
                worksheetName
            )

            createBlueCell(
                "A5",
                "RFC de la Empresa:",
                worksheetName
            )
            createWhiteCell(
                "B5",
                "CIT4402264P8",
                "",
                worksheetName
            )

            createBlueCell(
                "A6",
                "Descripción:",
                worksheetName
            )
            createWhiteCell(
                "B6",
                "APERTURA DE CUENTAS",
                "",
                worksheetName
            )

            createBlueCell(
                "A7",
                "Fecha de aplicación (AAAA-MM-DD):",
                worksheetName
            )
            createWhiteCell(
                "B7",
                date,
                "",
                worksheetName
            )
        }

        function createTableAvail(worksheetName){
            let cellNumber = 11;

            createHeaders(worksheetName)

            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            if(interns.length){
                interns.forEach((intern, index) => {
                    cellNumber++
                    config.forEach((configItem) => {
                        createWhiteCell(
                            configItem.col + cellNumber,
                            configItem.getData(intern),
                            configItem.value,
                            worksheetName
                        )
                    });
                })
            }
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}

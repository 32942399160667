import React, { useEffect, useContext, useState } from "react";
import {UserContext} from '../../context/UserContext';
import { OverlayTrigger, Tooltip, Form, Dropdown, Table, Spinner } from "react-bootstrap";
import { motion } from "framer-motion";
import { RiSearch2Line } from 'react-icons/ri';
import { BsUpload, BsDownload, BsClipboardCheck, BsCircleFill, BsEnvelope} from 'react-icons/bs';
import "./Logistics.css";
import { FiPauseCircle, FiAlertCircle, FiCheckCircle, FiSend, FiPlus, FiClock} from "react-icons/fi";
import AddIntern from "./components/AddIntern";
import NextNumberBanorte from "./components/NextNumberBanorte";
import SetBatchBancomer from "./components/SetBatchBancomer";
import Methods from "../../utils/https";
import moment from 'moment'
import LoadingModal from '../../custom/LoadingModal/LoadingModal';
import { IoReload } from 'react-icons/io5';
import { notify } from "../../utils/notify";
import {saveAs} from 'file-saver';
import * as Excel from "exceljs";
import santanderFile from "./Excel/santanderFile";
import santanderLinkerFile from "./Excel/santanderLinkerFile";
import genericExcel from "./Excel/genericExcel";
import { UploadAccounts } from "./components/UploadAccounts";
import { InternRecords } from "./components/InternRecords";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faHistory } from '@fortawesome/free-solid-svg-icons';
import { DateTimePicker } from 'react-widgets';


export default function Logistcs() {
    const SEARCH_BY = ["fullName", "email", "curp", 'companyName', 'trackingNumber', 'accountNumber'];
    const context = useContext(UserContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [showAddIntern, setShowAddIntern] = useState(false);
    const [bankAux, setBankAux] = useState({selectedBank: "Bancomer", internDataBank: 'bancomerData'});
    const {selectedBank, internDataBank} = bankAux;
    const [data, setData] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [allInternsCheckedAux, setAllInternsCheckedAux] = useState(false);
    const [logisticOptions, setLogisticOptions] = useState([]);
    const [showBanorteFile, setShowBanorteFile] = useState(false);
    const [banorteInternsToEvaluate, setBanorteInternsToEvaluate] = useState([]);
    const [banorteLinkerMode, setBanorteLinkerMode] = useState(false);
    const [showBancomerFile, setShowBancomerFile] = useState(false);
    const [bancomerInternsToEvaluate, setBancomerInternsToEvaluate] = useState([]);
    const [showUploadAccount, setShowUploadAccounts] = useState(false);
    const [isSendingNotifications, setIsSendingNotifications] = useState(false);
    const [showInternLogisticRecord, setShowInternLogisticRecord] = useState(false);
    const [logisticRecords, setLogisticRecords] = useState(false);
    const [canEdit, setCanEdit] = useState(context.can("update", "Logistics"));
    const getBankData = (bank) => {
        let bankAux = {
            'Banorte': ['curp', 'firstname', 'father_lastname', 'mother_lastname', 'logisticStatus', 'logisticActions', 'logisticOrigin', 'created_at', 'companyName', 'periodType', 'ocupation',
            'street', 'ext_number', 'livingType', 'suburb', 'stateByZipCode', 'municipalityByZipCode','zip_code',
            'phone', 'gender', 'birthday', 'nationality', 'maritalStatus', 'schoolarshipAux', 'empolyeeType',
            'rfc', 'registerType', 'cardType', 'logisticDebitCard', 'productKey', 'bankCode', 'accountNumber','accountType', 'crCheckbook', 'scolarshipAmount', 
            'notificationCheck', 'shipment', 'logisticDateSent', 'trackingNumber'],
            'Santander': ['curp', 'fullName', 'logisticStatus', 'logisticActions', 'logisticOrigin', 'created_at', 'companyName', 'branchOffice', 'rfc', 'phone', 'email', 'gender', 'nationalitySantander', 'maritalStatusSantander', 'scolarshipAmount',
            'workplaceCode', 'accountNumber', 'notificationCheck', 'shipment', 'logisticDateSent', 'trackingNumber'],
            'Bancomer': ['curp', 'fullName', 'logisticStatus', 'logisticActions', 'logisticOrigin' , 'created_at', 'companyName', 'periodType', 'email', 'phone', 'branchOffice', 'logisticDebitCard', 'notificationCheck', 'shipment', 'logisticDateSent', 'trackingNumber', 'accountNumber'],
            'Interbancaria': ['curp', 'fullName', 'logisticStatus', 'logisticActions', 'logisticOrigin', 'created_at', 'companyName', 'periodType', 'email', 'bank', 'accountNumber']
        }

        let bankAuxLimited = {
            'Banorte': ['curp', 'firstname', 'father_lastname', 'mother_lastname', 'logisticStatus', 'logisticOrigin', 'created_at', 'companyName', 'periodType','logisticDebitCard','accountNumber', 'shipment', 'logisticDateSent', 'trackingNumber'],
            'Santander': ['curp', 'fullName', 'logisticStatus', 'logisticOrigin', 'created_at', 'companyName',  'accountNumber', 'shipment', 'logisticDateSent', 'trackingNumber'],
            'Bancomer': ['curp', 'fullName', 'logisticStatus', 'logisticOrigin' , 'created_at', 'companyName', 'periodType', 'logisticDebitCard', 'shipment', 'logisticDateSent', 'trackingNumber', 'accountNumber'],
            'Interbancaria': ['curp', 'fullName', 'logisticStatus', 'logisticOrigin', 'created_at', 'companyName', 'bank', 'accountNumber']
        }

        return canEdit ? bankAux[bank] : bankAuxLimited[bank]  || [];
    };

    const [banks, setBanks] = useState([
        {selectedBank:"Bancomer", internDataBank: 'bancomerData'},
        {selectedBank:"Banorte",  internDataBank: 'banorteData'},
        {selectedBank:"Santander",  internDataBank: 'santanderData'},
        {selectedBank:"Interbancaria",  internDataBank: 'internBankData'}
    ]);
    const [companySearch, setCompanySearch] = useState("");
    const [bankConfigurations, setBankConfigurations] = useState(getBankData('Bancomer'));
    const [companies, setCompanies] = useState([]);
    const [filterCompanies, setFilterCompanies] = useState([]);
    const [sortKeys, setSortKeys] = useState({
        'created_at': { 'sort': 'ascending'},
        'logisticStatus': { 'sort': 'ascending', 'searchQuery': ''},
        'shipment': { 'sort': 'ascending', 'searchQuery': ''},
        'trackingNumber': { 'sort': 'ascending', 'searchQuery': ''},
        'clabe': { 'sort': 'ascending' },
        'accountNumber': { 'sort': 'ascending' },
        'logisticDebitCard': { 'sort': 'ascending' }
    });

    const allStatus = ['Alta', 'Confirmado', 'Enviado', 'En proceso', 'Pausado', 'Retenido', 'Terminado', ];
    const [statusFilterSelected, setStatusFilterSelected] = useState(allStatus);
    const allShipmentTypes = ['', 'UPS', 'Teiker', 'Beetrack']
    const [shipmentFilter, setShipmentFilter] = useState(allShipmentTypes);

    useEffect(() => {
        getInterns();
    }, [reload])

    const getInterns = async () => {
        setIsLoading(true);
        let dateToEvaluate = moment().startOf('day').subtract(1, 'month');
        let [data, options] = await Promise.all([Methods.getInternsByLogisticRange(dateToEvaluate),  Methods.getLogisticOptions()]);
        let interns;
        if(context.user.data._permissions.Logistics.update){
            interns = data.totalInterns;
            setCompanies(data.companies);
        }else{
            interns = data.totalInterns.filter(intern => context.user.data.advisedCompanies.includes(intern.id_company));
            let companyAux = interns.map (int => {return {bank: int.bank.toLowerCase() , companyName: int.companyName}})
            setCompanies(companyAux)
        }
        setFilterCompanies([]);
        options.nationalitySantander.sort((a, b) => a.keyValue.localeCompare(b.keyValue));
        setLogisticOptions(options);
        if (interns.length) {
            interns.map(intern => {
                intern.fullName = intern.firstname + ' ' + intern.father_lastname + ' ' + intern.mother_lastname;
                intern.created_at = intern.created_at ? moment(intern.created_at).format('DD/MM/YYYY') : 'N/A';
                intern.isChecked = false;
                intern.gender = intern.gender ? intern.gender == 'H' ? 'M' : 'F' : ''
                if(!intern.banorteData) intern.banorteData = {};
                if(!intern.santanderData) intern.santanderData = {};
                if(!intern.bancomerData) intern.bancomerData = {};
                if(intern.bank.toLowerCase() == 'banorte'){
                    intern.banorteData.ocupation = intern.banorteData.ocupation || '14';
                    intern.banorteData.shortFirstName = intern.firstname?.split(' ')[0] || '';
                    intern.banorteData.livingType =  intern.banorteData.livingType || '01';
                    intern.banorteData.crCheckbook = intern.banorteData.crCheckbook || '2499';
                    intern.banorteData.maritalStatus = intern.banorteData.maritalStatus || 'S';
                    intern.banorteData.schoolarshipAux = intern.banorteData.schoolarshipAux || '009';
                    intern.banorteData.empolyeeType = intern.banorteData.empolyeeType || '03';
                    intern.banorteData.registerType = intern.banorteData.registerType || '01';
                    intern.banorteData.cardType = intern.banorteData.cardType || '3';
                    intern.banorteData.productKey = intern.banorteData.productKey || '0705';
                    intern.banorteData.bankCode = intern.banorteData.bankCode || '072';
                    intern.banorteData.nationality = intern.banorteData.nationality || '001';
                    intern.banorteData.accountType = intern.banorteData.accountType || '01';
                    intern.shortFirstName = intern.firstname?.split(' ')[0] || '';
                }
                if(intern.bank.toLowerCase() == 'bancomer'){
                    intern.bancomerData.branchOffice = intern.bancomerData.branchOffice || '0219';
                }
                if(intern.bank.toLowerCase() == 'santander'){
                    intern.gender = intern.gender == 'M' ? 'MASCULINO' : 'FEMENINO';
                    intern.santanderData.maritalStatusSantander = intern.santanderData.maritalStatusSantander || 'SOLTERO';
                    intern.santanderData.nationalitySantander = intern.santanderData.nationalitySantander || 'MEXICO';
                    intern.santanderData.branchOffice = intern.santanderData.branchOffice || context.user.company.branchOffice;
                }
                if(intern.zip_code){
                    setDataByZipCode(intern, options);
                }
                intern.shipment = intern.shipment || ''
                intern.logisticDateSent = intern.logisticDateSent || 'DD/MM/YYYY'
                intern.logisticDateSent = intern.logisticDateSent || 'DD/MM/YYYY'
                intern.notificationCheck = false;
            })
        }
        setData(interns);
        setDataFiltered(interns);
        setIsLoading(false);
    }

    const getLabels = (label) => {
        let labels = {
            'curp': 'CURP',
            'fullName': 'Nombre',
            'rfc': 'RFC',
            'adress': 'Dirección',
            'email': 'Email',
            'phone': 'Teléfono',
            'gender': 'Género',
            'nationality': 'Nacionalidad',
            'nationalitySantander': 'Nacionalidad',
            'birthday': 'Fecha nacimiento',
            'maritalStatus': 'Estado Civil',
            'maritalStatusSantander': 'Estado Civil',
            'created_at': 'Fecha de alta',
            'scolarshipAmount': 'Monto de beca',
            'logisticDebitCard': 'Tarjeta de débito',
            'clabe': 'Número de cuenta',
            'ocupation': 'Código de Nac. De Ocupación',
            'shortFirstName': 'Nombre Corto',
            'livingType': 'Tipo de vivienda',
            'suburb': 'Colonia',
            'stateByZipCode': 'Estado',
            'municipalityByZipCode': 'Del. O Municipio',
            'zip_code': 'Código Postal',
            'matiralStatus': 'Estado Civil',
            'schoolarshipAux': 'Escolaridad',
            'empolyeeType': 'Tipo de Empleado',
            'registerType': 'Tipo de Alta/Registro',
            'cardType': 'Tipo de Tarjeta',
            'productKey': 'Clave de Producto',
            'bankCode': 'Código de Banco',
            'crCheckbook': 'CR Chequera',
            'branchOffice': 'Sucursal',
            'workplaceCode': 'Centro de Trabajo',
            'companyName': 'Compañía',
            'periodType': 'Periodo',
            'street': 'Calle',
            'ext_number': 'Número',
            'firstname': 'Nombre',
            'father_lastname': 'Apellido Paterno',
            'mother_lastname': 'Apellido Materno',
            'accountNumber': 'No de Cuenta',
            'accountType': 'Tipo de Cuenta',
            'notificationCheck': 'Notificar', 
            'shipment': 'Paquetería',
            'trackingNumber': 'No de Guía',
            'logisticStatus': 'Estatus',
            'logisticOrigin': 'Origen',
            'logisticActions': 'Acciones',
            'bank': 'Banco',
            'internLogisticRecords': 'Historial de cambios',
            'logisticDateSent': 'Fecha de envío'
        }
        return labels[label] || ''
    }

    const listOptions = (option) => {
        let labels = {
            'nationality': logisticOptions.nationalityCode,
            'ocupation': logisticOptions.ocupationCode,
            'livingType': logisticOptions.livingTypeCode,
            'maritalStatus': logisticOptions.maritalStatus,
            'schoolarshipAux': logisticOptions.scholarshipCode,
            'maritalStatusSantander': logisticOptions.maritalStatusSantander,
            'nationalitySantander': logisticOptions.nationalitySantander,
            'shipment': ['', 'UPS', 'Teiker', 'Beetrack']
        }
        return labels[option] || [];
    }

    let keyByIntern = (intern, key) => {
        let typeOption = {
            'notificationCheck': checkOption(intern, key), 
            'shipment': selectOption(intern, key),
            'trackingNumber': inputOption(intern, key),
            'logisticStatus': statusValue(intern, key),
            'logisticOrigin': originValue(intern, key),
            'logisticActions': logisticActions(intern, key),
            'clabe': inputOption(intern, key)
        }
        return typeOption[key] || undefined;
    };

    const cssValidKeys = (key) => {
        let validKeys = {
            fullName: {
                width: 200
            },
            curp: {
                width: 235,
            }, 
            firstname: {
                width: 120,
            },
            father_lastname: {
                width: 120,
            },
            mother_lastname: {
                width: 120,
            },
            logisticStatus: {
                width: 130,
            },
            logisticActions:{
                width: 170,
            }
        }
        return validKeys[key] || undefined;
    }

    const selectOption = (intern, key) =>{
        return  <td className='min-height-table' style={{...cssValues(key)}}> <Form.Control key={`${key}-${intern._id}`} as="select" style={{ "minWidth": "170px" }} 
                onChange={e => { 
                    intern[key] = e.target.value;
                    setDataFiltered([...dataFiltered])
                    updateDataByIntern(intern, [{ updateKey: key, updateValue: intern[key] }]);
                }}
                disabled={!canEdit}
            >
                {listOptions(key).map(option => {
                    return (
                        <option id={option} selected={option == intern[key]} value={option}>
                            {option}
                        </option>
                    );
                })}
        </Form.Control>
        </td>
    }

    const inputOption = (intern, key) =>{
        return  <td className='min-height-table' style={{...cssValues(key)}}><Form.Control
            key={`${key}-${intern._id}`}
            value={intern[key]}
            onChangeCapture={e => {
                intern[key] = e.target.value;
                setDataFiltered([...dataFiltered]);
            }}
            disabled={!canEdit}
            className="input-text"
            onBlurCapture={ () => {
                let value = [{ updateKey: key, updateValue: intern[key] }]
                updateDataByIntern(intern, value);
            }}
        />
        </td>
    }

    const checkOption = (intern, key) => {
        
        return  <td className='min-height-table' style={{...cssValues(key)}}> 
        <Form.Check key={`${key}-${intern._id}`}
            style={{ display: "inline", marginRight: "10px" }}
            checked={intern[key]}
            defaultValue={intern[key]}
            disabled={ 
                (key == 'notificationCheck' && intern.logisticStatus == 'Alta') || !canEdit
            }
            onChange={ async () => {
                intern[key] = !intern[key]
                setDataFiltered([...dataFiltered]);
            }}
        />
        </td>
    }



    const statusValue = (item, key) => {
        return (
            <td style={{...cssValues(key)}}>
                <BsCircleFill className={item.logisticStatus == 'En proceso' ? 'in-process' : item.logisticStatus?.toLowerCase()} style={{ marginRight: "5px" }} />
                {item.logisticStatus}
            </td >
        )
    }

    const originValue = (item, key) => {
        return (
            <td style={{...cssValues(key)}}>
                <BsCircleFill className={item.logisticOrigin ? item.logisticOrigin.toLowerCase() : 'no-origin'} style={{ marginRight: "5px" }} />
                {item.logisticOrigin ? item.logisticOrigin : 'N/A'}
            </td >
        )
    }

    const logisticActions = (item, key) => {
       return canEdit ? (
                <td style={{...cssValues(key)}}>
                    <OverlayTrigger placement="top"  overlay={<Tooltip id="button-tooltip-2">Pausar</Tooltip>}>
                        <FiPauseCircle className="actions-icon pausado" onClick={() => { 
                            setNewState(item, 'Pausado');
                        }} />
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Confirmar</Tooltip>}>
                        <FiCheckCircle className="actions-icon confirmado" onClick={() => {
                            setNewState(item, 'Confirmado');

                            }} />
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Enviar</Tooltip>}>
                        <FiSend className="actions-icon enviado" onClick={() => { 
                            setNewState(item, 'Enviado');                                                           
                        }} />
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Retener</Tooltip>}>
                        <FiAlertCircle className="actions-icon retenido" onClick={() => {
                            setNewState(item, 'Retenido');                                                          
                        }} />
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Terminar</Tooltip>} >
                        <BsClipboardCheck className="actions-icon terminado" onClick={() => {
                            setNewState(item, 'Terminado');                                                         
                            }} />
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">En proceso</Tooltip>}>
                        <FiClock className="actions-icon in-process" onClick={() => { 
                            setNewState(item, 'En proceso');
                        }} />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id="button-tooltip-2">Historial</Tooltip>}>
                        <FiClock className="actions-icon" icon={faHistory} onClick={() => { 
                            getInternLogisticRecords(item);
                        }} />
                    </OverlayTrigger>
                </td>
        ): <td></td>;
    }

    const getInternLogisticRecords = async (intern) => {
        let result = await Methods.getInternLogisticRecords({internId:intern._id});
        if(result?.length){
            result.sort((a, b) => { return a.createdAtTimestamp > b.createdAtTimestamp ? -1 : 1 });
            result.map( record => { record.createdAt = moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')});
            setLogisticRecords(result);
            setShowInternLogisticRecord(!showInternLogisticRecord);
        }else{
            notify('No hay cambios para desplegar', 'error');
        }
    }   

    const createLogisticRecord = async(intern, clabe) => {
        let record = {
            authorEmail: context.user.data.email,
            authorName: context.user.data.fullName,
            internId: intern._id,
            internAccount: clabe,
            internCompanyId: intern.id_company,
            internFullName: intern.firstname + ' ' + intern.father_lastname + ' ' + intern.mother_lastname
        }
        await Methods.createInternLogisticRecord(record);
    }

    const setNewState = async (intern, newValue) =>{
        let checkDisabled = [
            'Terminado',
            'Pausado',
            'Retenido'
        ];
        let value = [ 
            { updateKey: "logisticStatus", updateValue: newValue },
            { updateKey: "lastmodifyLogisticDate", updateValue: moment().format()}
        ];
        const CLABE = !intern[internDataBank] ? '_' : intern[internDataBank]['accountNumber'] ? intern[internDataBank]['accountNumber'] : '_';
        if(newValue === "Confirmado") {
            value.push({ updateKey: "accountColorStatus", updateValue: "#000000" });
            value.push({ updateKey: "lastModifyAccount", updateValue: context.user.data.email });
            await Methods.updatePrefactureClabeByIntern(intern._id, "#000000" , context.user.data.email, CLABE);
            await createLogisticRecord(intern, CLABE);
            intern.accountColorStatus = "#000000";
        } 
        if(newValue === "Pausado"){
            value.push({ updateKey: "accountColorStatus", updateValue: "#B2B2B2" });
            value.push({ updateKey: "lastModifyAccount", updateValue: context.user.data.email });
            await Methods.updatePrefactureClabeByIntern(intern._id, "#B2B2B2" , context.user.data.email, CLABE);
            intern.accountColorStatus = "#B2B2B2";
        }
        updateDataByIntern(intern, value, false);
        intern.logisticStatus = newValue;
        if(checkDisabled.includes(newValue)){
            intern.isChecked = false;
        }
        setDataFiltered([...dataFiltered]);
    }

    const updateDataByIntern = async(intern, data, reloadPage) => {
        let dataIntern = {
            _id: intern._id,
            attributes: data
        }
        await Methods.updateIntern(dataIntern);
        if(reloadPage) setReload(!reload)
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const downloadFileByBank = () => {
        const dataAux = dataFiltered.filter(intern => intern.isChecked && selectedBank.toLowerCase() == intern.bank?.toLowerCase());
        for (let intern of dataAux) {
            for (let key in intern) {
                if(intern[key] != undefined && typeof intern[key] == "string"){
                    intern[key] = removeAccents(intern[key]);
                    if(key == 'suburb' || key == 'street'){
                        intern[key] = removePunctuationMarks(intern[key]);
                    }
                }
            }
        }
        
        if(!dataAux.length){
            notify('No hay registros por procesar', 'error');
            return;
        }

        if(selectedBank == 'Bancomer'){
            setBancomerInternsToEvaluate(dataAux);
            setShowBancomerFile(true);
        }

        if(selectedBank == 'Banorte'){
            setBanorteInternsToEvaluate(dataAux);
            setShowBanorteFile(true);
        }

        if(selectedBank == 'Santander'){
            const workbook = new Excel.Workbook();
            santanderFile.test(
                workbook,
                dataAux,
            );
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
                saveAs(blob, `altaSantander`);
            });
        }
    }

    const removePunctuationMarks = (value) => {
        return value?.replace(/\.|,/g, '');
    }

    const downloadLinkerFileByBank = () => {
        const dataAux = dataFiltered.filter(intern => intern.isChecked && selectedBank.toLowerCase() == intern.bank?.toLowerCase());
        for (let intern of dataAux) {
            for (let key in intern) {
                if(intern[key] != undefined && typeof intern[key] == "string"){
                    intern[key] = removeAccents(intern[key]);
                }
            }
        }
        
        if(!dataAux.length){
            notify('No hay registros por procesar', 'error');
            return;
        }

        if(selectedBank == 'Banorte'){
            setBanorteInternsToEvaluate(dataAux);
            setBanorteLinkerMode(true);
            setShowBanorteFile(true);
        }

        if(selectedBank == 'Santander' && filterCompanies.length != 0){
            const workbook = new Excel.Workbook();
            santanderLinkerFile.test(
                workbook,
                dataAux,
            );
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
                saveAs(blob, `ligadoSantander`);
            });
        }else if(selectedBank == 'Santander' && filterCompanies.length == 0){
            notify('Debes seleccionar una compañía', 'error');
        }
    }

    const downloadGenericExcel = () => {

        let dataToDownload = returnDataFiltered();
        const workbook = new Excel.Workbook();
        genericExcel.test(
            workbook,
            dataToDownload,
            bankConfigurations, 
            getLabels,
            internDataBank,
            moment().format(),
            context.user.data.fullName || ''
        );

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            saveAs(blob, `Reporte`);
        });
    }

    const setDataByZipCode = (intern, options) =>{
        if(options.zipCodeRange?.length){
            options.zipCodeRange.map(data => {
                if (
                    (data.zipCodeRangeStart <= parseInt(intern.zip_code) &&
                        parseInt(intern.zip_code) <= data.zipCodeRangeEnd) ||
                    (data.zipCodeRange2Start &&
                        data.zipCodeRange2Start <= parseInt(intern.zip_code) &&
                        parseInt(intern.zip_code) <= data.zipCodeRange2End)
                ) {
                    intern.municipalityByZipCode = data.municipalityCode;
                    intern.stateByZipCode = data.stateCode;
                }
            })
        }
    }     
        

    const setAllInternsChecked = () => {

        dataFiltered.filter((item) => {
            for (let keyValue of SEARCH_BY) {
                let internValue = item[keyValue]?.toLowerCase() || item[internDataBank][keyValue]?.toLowerCase();
                if (((searchQuery && internValue?.startsWith(searchQuery?.toLowerCase())) || !searchQuery) 
                    && selectedBank.toLowerCase() == item.bank?.toLowerCase() && item.logisticStatus == 'Alta') {
                    item.isChecked = !allInternsCheckedAux;
                }
            }
        })
        
        setAllInternsCheckedAux(!allInternsCheckedAux);

    }

    const updatableValues = (label) => {
        let values = {
            'logisticDebitCard': true, 
            'empolyeeType': true, 
            'registerType': true, 
            'cardType': true, 
            'productKey': true,
            'bankCode': true, 
            'crCheckbook': true,
            'branchOffice': true,
            'workplaceCode':true,
            'accountType': true,
            'accountNumber': true
        }
        return values[label] || false
    }

    const notifyInterns = async () => {
        let promises = [];
        let mail = (await Methods.getMail("logistica"))[0];
        setIsSendingNotifications(true);
        for(let intern of dataFiltered){
            if (intern.notificationCheck &&
                selectedBank.toLowerCase() == intern.bank?.toLowerCase() &&
                intern.shipment != "" && intern.trackingNumber) {
                let setCurrentSentDate = (intern.logisticDateSent == 'DD/MM/YYYY');
                let status = [
                    { updateKey: "logisticStatus", updateValue: 'Enviado' },
                    { updateKey: "lastmodifyLogisticDate", updateValue: moment().format()},
                    ...( setCurrentSentDate ? 
                        [{updateKey: "logisticDateSent", updateValue: moment().format('DD/MM/YYYY')}] : []
                    )
                ];

                if(setCurrentSentDate){
                    intern.logisticDateSent = moment().format('DD/MM/YYYY'); 
                }

                let links = {
                    'UPS': 'https://es-us.ups.com/track?loc=es_US&requester=ST/',
                    'Teiker': 'https://envios.teiker.mx/Rastreo',
                    'Beetrack': 'https://app.beetrack.com/widget/LxcpiVM9ncZju00EhrIxtw'
                }

                const internParams = {
                    to: [intern.email, mail.email],
                    subject: `¡Te hemos enviado un paquete! Practicantes CAINTRA`,
                    text: `
                    ¡Tu paquete está en camino! 
                    <br>
                    <br>
                    <span style="font-weight: bold"></span>¡Ingresa al siguiente link y da el seguimiento con tu guía de rastreo!
                    <br>
                    <br>
                    <a  style="color: #0080fd;" href='${links[intern.shipment]}'>${links[intern.shipment]}</a>
                    <br/>
                    No. de guía: <span  style="color: #0080fd;">${intern.trackingNumber}</span>
                    <br>
                    <br>
                    En caso de presentar problemas para acceder, por favor notifícalo al correo de atención: <span  style="color: #0080fd;">soporte.practicantes@caintra.org.mx.</span>
                    <br>
                     Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.
            `,
                };

                promises.push(updateDataByIntern(intern, status, false));
                promises.push(Methods.sendEmail(internParams));

                if (promises.length > 100) {
                    await Promise.all(promises);
                    promises = [];
                }

                intern.logisticStatus = 'Enviado';
                intern.notificationCheck = false;
            }
            
        }
        await Promise.all(promises);
        setDataFiltered([...dataFiltered]);
        setIsSendingNotifications(false);
    }

    const sortByKey = async (key) => {
        let direction = sortKeys[key].sort == 'ascending' ? 'descending' : 'ascending';
        sortKeys[key].sort = direction
        setSortKeys({...sortKeys});
        dataFiltered.sort(function(a,b){
            if(key != 'created_at'){
                let aAux = a[key] ? a[key] : a[internDataBank][key] || '';
                let bAux = b[key] ? b[key] : b[internDataBank][key] || '';
                return direction === 'ascending' ? aAux < bAux ? -1 : 1 : aAux < bAux ? 1 : -1;
            }
            let momentA = a.created_at.includes("/") ? moment(a.created_at,"DD/MM/YYYY").startOf("day"): moment(a.created_at.slice(0,10)).startOf("day");
            let momentB = b.created_at.includes("/") ? moment(b.created_at,"DD/MM/YYYY").startOf("day"): moment(b.created_at.slice(0,10)).startOf("day");
            return direction === 'ascending' ? momentA.isAfter(momentB) ? -1 : 1 : momentA.isAfter(momentB) ? 1 : -1;
        });

        setDataFiltered(dataFiltered);
    }

    const updateFilters = async (eventType, selectedValue, setAux) => {

        let currentStatusFilter = statusFilterSelected;
        let currentSelectedCompanies = filterCompanies;
        let curerntShipmentFiler = shipmentFilter;
        let valuesToUpdate = [];
        
        if(eventType == 'status'){
            valuesToUpdate = currentStatusFilter;
        } else if(eventType == 'company'){
            valuesToUpdate = currentSelectedCompanies;
        } else if(eventType == 'shipment'){
            valuesToUpdate = curerntShipmentFiler;
        }

        if(valuesToUpdate.includes(selectedValue)){
            valuesToUpdate = valuesToUpdate.filter(item => item !== selectedValue)
        }else{
            if(eventType == 'company' && filterCompanies.length > 0 && selectedBank == 'Santander'){
                notify('Solo puedes seleccionar una compañía', 'error');
                return;
            }else{
                valuesToUpdate.push(selectedValue);
            }
        }

        setAux(valuesToUpdate);        
        const internsFiltered = data.filter((item) => 
            companyFilter(item, eventType == 'company' ? valuesToUpdate : currentSelectedCompanies) && 
            statusFilter(item, eventType == 'status' ? valuesToUpdate : currentStatusFilter) &&
            shipmentFilterAux(item, eventType == 'shipment' ? valuesToUpdate : curerntShipmentFiler)
        );

        setDataFiltered(internsFiltered);
    };

    const statusFilter = (intern, currentStatusFilter) => {
        return currentStatusFilter.includes(intern.logisticStatus);
    }

    const companyFilter = (intern, currentSelectedCompanies) => {
        return currentSelectedCompanies.length ? currentSelectedCompanies.includes(intern.companyName) : true;
    }

    const shipmentFilterAux = (intern, shipmentFilters) => {
        return shipmentFilters.includes(intern.shipment);
    }

    const returnDataFiltered = () => {
        let dataFilteredBySearch = dataFiltered.filter((item) => {
            for (let keyValue of SEARCH_BY) {
                let internValue = item[keyValue]?.toLowerCase() || item[internDataBank][keyValue] ?.toLowerCase();
                if (((searchQuery && internValue?.toLowerCase().startsWith(searchQuery?.toLowerCase()))) || !searchQuery) {
                    return true;
                }
            }
            return false;
        });
        let filteredByBank = dataFilteredBySearch.filter((item) => { 
            let validBanks = ['santander', 'banorte', 'bancomer'];
            if(selectedBank == 'Interbancaria'){
                let idValid = validBanks.find(bankAux => bankAux == item.bank.toLowerCase())
                return idValid ? false : true;
            } else if(selectedBank != 'Interbancaria') {
                return selectedBank.toLowerCase() == item.bank?.toLowerCase();
            }
        });
        return filteredByBank;
    }

    const cssValues = (keyName, isHeader) => {  
        let cssValue = {}
        let keyResult = cssValidKeys(keyName);
        let currentIndex = bankConfigurations.indexOf(keyName);
        let leftValue = 0;
        if(keyResult){

            if(currentIndex != 0){
                for(let index = currentIndex - 1; index >= 0; index --){
                    let cssValue = cssValidKeys(bankConfigurations[index]);
                    if(cssValue) leftValue += cssValue.width;
                }
            }

            cssValue = {
                left: `${leftValue}px`,
                position: 'sticky ',
                background: `${(isHeader ? 'rgba(6, 209, 205, 1) !important' : 'rgb(255, 255, 255)')}`,
                whiteSpace: 'normal',
                zIndex: `${isHeader  ? '3' : '1 '}`,
                minWidth: `${keyResult.width}px`,
                flexWrap: 'wrap'
            }

            if(isHeader){
                cssValue = {
                    ...cssValue, 
                    top: '0px'
                }
            }
        }else{
            cssValue = {
                minWidth: '200px'
            }
        }

        return cssValue 
    }

    return (
        <motion.div initial={{ x: 30, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.25 }} className="prefecture reports logistics">
            <div className="title">
                <h4>Logística</h4>
            </div>

            <div className="alter-table logistics-search-container">
                { canEdit && <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Agregar Practicante</Tooltip>}
                >
                    <div className="add-university" onClick={() => { setShowAddIntern(!showAddIntern) }}><FiPlus /></div>
                </OverlayTrigger>}

                <div className="search mt-15">
                <div className="search-icon" title="Buscar"><RiSearch2Line/></div>
                    <Form.Group controlId="search-bar" className="search-container">
                        <Form.Control
                            type="text"
                            placeholder="Buscar"
                            autoComplete={"off"}

                            onInputCapture={async (e) => {
                                setSearchQuery(e.target.value);
                            }}
                        />
                    </Form.Group>
                </div>

                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                >
                    <a href="#" className="reload-table" onClick={() => setReload(!reload)}><IoReload /></a>
                </OverlayTrigger>

                <div className="options">
                    <Dropdown drop={"down"} style={{ margin: "0px", padding: "0px" }}>
                        <Dropdown.Toggle>{selectedBank}</Dropdown.Toggle>
                        <Dropdown.Menu className="filters-menu">
                            {banks.map(bank => 
                            <Dropdown.Item key={bank.selectedBank} onSelect={async () => { 
                                setBankConfigurations(getBankData(bank.selectedBank));
                                setDataFiltered(data);
                                setFilterCompanies([]);
                                setBankAux(bank); 
                                setAllInternsCheckedAux(false)}}
                            >{bank.selectedBank}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown drop={"down"} style={{ margin: "0px", padding: "0px" }}>
                        <Dropdown.Toggle>Filtrar por compañía</Dropdown.Toggle>
                        <Dropdown.Menu className="filters-menu filters-menu-companies">
                            <Form.Control
                                type="text"
                                placeholder="Buscar"
                                autoComplete={"off"}
                                onInputCapture={async (e) => {
                                    setCompanySearch(e.target.value);
                                }}
                            />
                            <div className="companies-search-container">
                                {companies.filter((companyAux, key) => {
                                    let isSameBank = selectedBank?.toLowerCase() === companyAux.bank?.toLowerCase();
                                    if(!companySearch) return isSameBank;
                                    return companyAux.companyName?.toLowerCase().trim().includes(companySearch?.toLowerCase().trim()) && isSameBank;
                                }).map((company, key) => {
                                    return(
                                        <Form.Check 
                                            type="checkbox" 
                                            key={key} 
                                            label={company.companyName} 
                                            onChangeCapture={() => 
                                                updateFilters('company', company.companyName, setFilterCompanies)
                                            } 
                                            checked={filterCompanies.includes(company.companyName)}
                                        />
                                    );
                                })}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    

                    <Dropdown drop={"down"} style={{ margin: "0px", padding: "0px" }}>
                        <Dropdown.Toggle>Estatus</Dropdown.Toggle>
                        <Dropdown.Menu className="filters-menu">
                            {allStatus.map(status => {
                                return <Form.Check type="checkbox" checked={statusFilterSelected.includes(status)} onClick={()=> updateFilters('status', status, setStatusFilterSelected)} label={status} />
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown drop={"down"} style={{ margin: "0px", padding: "0px" }}>
                        <Dropdown.Toggle>Paquetería</Dropdown.Toggle>
                        <Dropdown.Menu className="filters-menu">
                            {allShipmentTypes.map(item => {
                                return <Form.Check type="checkbox" checked={shipmentFilter.includes(item)} onClick={()=> updateFilters('shipment', item, setShipmentFilter)} label={item} />
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                {selectedBank != 'Interbancaria' && canEdit && <div className="Button" onClick={() => { downloadFileByBank() }}><BsDownload />Archivo de alta</div>}
                <div className="Button" onClick={() => {downloadGenericExcel()}}><BsDownload />Excel</div>
                {selectedBank != 'Interbancaria' && canEdit && <div className="Button" onClick={() => { setShowUploadAccounts(true) }}><BsUpload />Carga de datos</div>}
                {(selectedBank !== "Bancomer" && selectedBank != 'Interbancaria') && canEdit && <div className="Button" onClick={() => { downloadLinkerFileByBank() }}><BsDownload />Archivo de ligado</div>}
                {selectedBank != 'Interbancaria' && canEdit &&
                <div className={isSendingNotifications ? 'Button-disabled' : 'Button'} onClick={() => { notifyInterns() }} disabled={isSendingNotifications}>
                    {isSendingNotifications ? <Spinner className='snipper-class' animation="border" role="status" /> : <BsEnvelope />} Notificar
                </div>}

            </div >


                <div className="logistic-table-scroll">
                    <Table style={{"min-height": "550px"}}>
                        <thead>
                            <tr>
                                {bankConfigurations && bankConfigurations.map((key, index) => {
                                    if(key == 'curp'){
                                        return <th style={{...cssValues(key, true)}} className={cssValidKeys(key) ? 'force-change-background' : ''}>
                                        <Form.Check key={Math.random()}
                                            style={{ display: "inline", marginRight: "10px" }} 
                                            defaultChecked={allInternsCheckedAux}
                                            onChangeCapture={ e => { 
                                                setAllInternsChecked();
                                            }}
                                        />{getLabels(key)}</th>
                                    }else {
                                        return <th style={{...cssValues(key, true)}} className={cssValidKeys(key) ? 'force-change-background' : ''}> 
                                            {getLabels(key)} 
                                            {sortKeys[key] && <FontAwesomeIcon  size="lg" rotation={90}  icon={faExchangeAlt} pull={'right'}  onClick={() => sortByKey(key)}/>
                                        } </th>
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dataFiltered && returnDataFiltered().map((item, index) => {
                                    return (    
                                        <tr key={index}>
                                            {bankConfigurations.map(key => {
                                                if (key == "curp") {
                                                    return <td style={{...cssValues(key)}} >
                                                        <Form.Check key={`${key}-${item._id}`}
                                                            disabled={(item.logisticStatus != 'Alta' && item.logisticStatus != 'En proceso') || !canEdit}
                                                            style={{ display: "inline", marginRight: "10px" }} 
                                                            checked={item.isChecked}
                                                            defaultValue={item.isChecked}
                                                            onChange={e => { 
                                                                item.isChecked = !item.isChecked
                                                                setDataFiltered([...dataFiltered])
                                                            }}
                                                        />
                                                        {item[key]}
                                                    </td>
                                                }else if (key == "logisticDateSent"){
                                                    return <td style={{...cssValues(key)}} >
                                                            <DateTimePicker 
                                                                name="logisticDateSent" 
                                                                disabled={!canEdit} 
                                                                placeholder={item.logisticDateSent} 
                                                                editFormat={'DD/MM/YYYY'} 
                                                                format={'DD/MM/YYYY'} 
                                                                time={false} 
                                                                onChange={(date) => {
                                                                    item.logisticDateSent = moment(date).format("DD/MM/YYYY");
                                                                    setDataFiltered([...dataFiltered]);
                                                                    updateDataByIntern(item, [{updateKey: 'logisticDateSent', updateValue: moment(date).format("DD/MM/YYYY")}]);
                                                                }}/>

                                                            </td>
                                                }else if(keyByIntern(item, key)){
                                                    return keyByIntern(item, key);
                                                }else if (updatableValues(key)) {
                                                    return <td className='min-height-table' style={{...cssValues(key)}}>
                                                            <Form.Control
                                                                disabled={!canEdit}
                                                                key={`${key}-${item._id}`}
                                                                type={'number'}
                                                                value={key=='accountNumber' ?  (item[internDataBank]?.[key] ? item[internDataBank]?.[key] : item.clabe) : item[internDataBank]?.[key] }
                                                                onChangeCapture={e => {
                                                                    e.target.value = e.target.value.toString().slice(0, key == 'accountNumber' ? 
                                                                    selectedBank=='Santander' ? 11 : 18 : 16);
                                                                    if(!item[internDataBank]){
                                                                        item[internDataBank] = {}
                                                                    }
                                                                    
                                                                    if(key == 'accountNumber'){
                                                                        item[internDataBank]['accountNumberAux'] = item[internDataBank][key];
                                                                    }

                                                                    item[internDataBank][key] = e.target.value;

                                                                    setDataFiltered([...dataFiltered]);                                                              
                                                                }}
                                                                className="input-text"
                                                                style={{ "width": "220px" }}
                                                                onBlurCapture={e => {
                                                                    let value = [{ updateKey: internDataBank, updateValue: item[internDataBank] }]
                                                                    if(key == 'accountNumber'){
                                                                        if(item[internDataBank]['accountNumberAux'] != item[internDataBank][key]){
                                                                            value.push({ updateKey: 'logisticOrigin', updateValue: 'Autoservicio'});
                                                                            value.push({ updateKey: "accountColorStatus", updateValue: "#FF2D00" });
                                                                            value.push({ updateKey: 'clabe', updateValue: item[internDataBank][key]});
                                                                            item[internDataBank]['accountNumberAux'] = item[internDataBank][key];
                                                                        }                                                      
                                                                    }
                                                                    updateDataByIntern(item, value);
                                                                }}
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                    </td >
                                                } else if (listOptions(key).length) {
                                                    return <td className='min-height-table' style={{...cssValues(key)}}>
                                                        <Form.Control as="select" style={{ "minWidth": "170px" }} 
                                                            disabled={!canEdit}
                                                            onChange={e => { 
                                                                if(!item[internDataBank]){
                                                                    item[internDataBank] = {}
                                                                }
                                                                item[internDataBank][key] = e.target.value;
                                                                setDataFiltered([...dataFiltered])
                                                                updateDataByIntern(item, [{ updateKey: internDataBank, updateValue: item[internDataBank] }]);
                                                            }}>
                                                            {listOptions(key).map(option => {
                                                                return (
                                                                    <option key={option._id} id={option.title} selected={option.keyValue == item[internDataBank]?.[key]} value={option.keyValue}> {selectedBank=='Santander' ? option.keyValue : option.keyValue + ' - ' + option.name}</option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </td>
                                                } else {
                                                    return <td style={{...cssValues(key)}}>{item[key]}</td>;
                                                }
                                            })
                                            }
                                        </tr>
                                    )
                            })}
                        </tbody>
                    </Table>
                </div>
        
            <AddIntern
                setShowAddIntern={setShowAddIntern}
                showAddIntern={showAddIntern}
                setReload={setReload}
                reload={reload}
                context={context}
            />

            <NextNumberBanorte
                setShowBanorteFile={setShowBanorteFile}
                showBanorteFile={showBanorteFile}
                dataAux={banorteInternsToEvaluate}
                setBanorteLinkerMode={setBanorteLinkerMode}
                banorteLinkerMode={banorteLinkerMode}
                updateDataByIntern={updateDataByIntern}
            />

            <SetBatchBancomer
                setShowBancomerFile={setShowBancomerFile}
                showBancomerFile={showBancomerFile}
                dataAux={bancomerInternsToEvaluate}
            />

            <UploadAccounts
                showUploadAccount={showUploadAccount}
                setShowUploadAccounts={setShowUploadAccounts}
                selectedBank={selectedBank}
                setData={setDataFiltered}
                data={dataFiltered}
                internDataBank={internDataBank}
                context={context}
                createLogisticRecord={createLogisticRecord}
            />

            {showInternLogisticRecord && 
                <InternRecords
                    logisticRecords={logisticRecords}
                    setLogisticRecords={setLogisticRecords}
                    showInternLogisticRecord={showInternLogisticRecord}
                    setShowInternLogisticRecord={setShowInternLogisticRecord}
                />
            }

            {isLoading && <LoadingModal isLoading={isLoading} /> }
        </motion.div >
    )
}
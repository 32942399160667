import React from 'react';
import {Modal, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';

export default function SuccessModal({showSuccessModal}) {
    return(
        <Modal className="success-modal" show={showSuccessModal} centered>

            <Modal.Body>
                <h2> ¡Felicitaciones! <FontAwesomeIcon  icon={faCheckCircle}/> </h2>
                <p>¡Hemos recibido tu información satisfactoriamente!</p>
            </Modal.Body>
        </Modal>
    );
};
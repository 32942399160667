import React from 'react';
import { Modal, Image, Form, Button } from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';
const axios = require("axios");

export default function DeleteDepartmentModal({ displayDeleteDepartmentModal, setDisplayDeleteDepartmentModal, currentDepartmentId, updateList, setUpdateList, currentCompanyId }) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);

    const deleteDepartment = async (e) => {
        e.preventDefault();
        const confirmation_code = e.target.elements.confirmation.value;

        if (confirmation_code != "eliminar permanentemente") {
            notify("La confirmación es incorrecta", "error");
            return;
        };

        try {
            await axios({
                url: `${URL}/departments`,
                method: "DELETE",
                data: {
                    id_department: currentDepartmentId,
                    id_company: currentCompanyId,
                    departmentField: context.getDepartmentField(),
                    generalistaField: context.getGeneralistaField()
                }
            });

            notify(`${context.getDepartmentField(true)} eliminado con éxito`, "success");
            setUpdateList(!updateList);
            setDisplayDeleteDepartmentModal(!displayDeleteDepartmentModal);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setUpdateList(!updateList);
        };
    };

    return (
        <Modal className="new-cc-modal" show={displayDeleteDepartmentModal} onHide={() => setDisplayDeleteDepartmentModal(!displayDeleteDepartmentModal)} centered>
            <Modal.Header>
                <p>Eliminar {context.getDepartmentField()}</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={deleteDepartment}>

                    <Form.Group controlId="confirmation">
                        <Form.Label>Escribe "eliminar permanentemente" para continuar</Form.Label>
                        <Form.Control type="text" required />
                        <small>¡Estos cambios son irreversibles, todo Centro de Costos asociado con éste {context.getDepartmentField()} desaparecerá!</small>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayDeleteDepartmentModal(!displayDeleteDepartmentModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import moment from 'moment';
export default {
    

    test(workbook, companyInfo, interns, nextNumber, banorteLinkerMode) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        const name = banorteLinkerMode ? "Ligado Banorte" : "Alta Banorte";

        const data = {
            'cols': ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH"],
            'config' : [
                        {  width: 20,  value: "#",                  text: "#",                              getData: () => { return '' } },
                        {  width: 20,  value: "internNumber",       text: "Numero de empleado",             getData: () => { return '' } },                        
                        {  width: 40,  value: "firstname",          text: "Nombre",                         getData: (intern) => { return intern.firstname?.toUpperCase() || '' }  },
                        {  width: 30,  value: "fatherLastName",     text: "Apellido Paterno",               getData: (intern) => { return intern.father_lastname?.toUpperCase() || '' }  },
                        {  width: 30,  value: "motherLastName",     text: "Apellido Materno",               getData: (intern) => { return intern.mother_lastname?.toUpperCase() || '' }  },
                        {  width: 30,  value: "ocupation",          text: "Código de Nac. De Ocupación",    getData: (intern) => { return intern.banorteData.ocupation || '' }  },
                        {  width: 20,  value: "street",             text: "Calle",                          getData: (intern) => { return intern.street?.toString().toUpperCase() || ''}  },
                        {  width: 20,  value: "ext_number",         text: "Número",                         getData: (intern) => { return intern.ext_number?.toString().toUpperCase() || '' }  },
                        {  width: 20,  value: "livingType",         text: "Tipo de vivienda",               getData: (intern) => { return intern.banorteData.livingType|| '' }  },
                        {  width: 20,  value: "suburb",             text: "Colonia",                        getData: (intern) => { return intern.suburb?.toUpperCase() || ''}  },
                        {  width: 20,  value: "stateByZipCode",     text: "Estado",                         getData: (intern) => { return intern.stateByZipCode|| '' }  },
                        {  width: 20,  value: "municipalityByZipCode",    text: "Del. O Municipio",         getData: (intern) => { return intern.municipalityByZipCode || '' }  },
                        {  width: 20,  value: "zip_code",           text: "Codigo Postal",                  getData: (intern) => { return intern.zip_code || '' }  },
                        {  width: 20,  value: "phone",              text: "Telefono",                       getData: (intern) => { return intern.phone || '' }  },
                        {  width: 20,  value: "gender",             text: "Sexo",                           getData: (intern) => { return intern.gender || '' }  },
                        {  width: 20,  value: "birthday",           text: "Fecha Nacimiento",               getData: (intern) => { if(intern.birthday.includes('/')){ return moment(intern.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD') } return '' }  },
                        {  width: 20,  value: "nationality",        text: "Nacionalidad",                   getData: (intern) => { return intern.banorteData.nationality || '' }  },
                        {  width: 20,  value: "maritalStatus",      text: "Estado Civil",                   getData: (intern) => { return intern.banorteData.maritalStatus || '' }  },
                        {  width: 20,  value: "schoolarshipAux",    text: "Escolaridad",                    getData: (intern) => { return intern.banorteData.schoolarshipAux || '' }  },
                        {  width: 20,  value: "empolyeeType",       text: "Tipo de Empleado",               getData: (intern) => { return intern.banorteData.empolyeeType || '' }  },
                        {  width: 20,  value: "rfc",                text: "RFC",                            getData: (intern) => { return intern.rfc?.toUpperCase() || '' }  },
                        {  width: 20,  value: "curp",               text: "CURP",                           getData: (intern) => { return intern.curp?.toUpperCase() ||  '' }  },
                        {  width: 20,  value: "registerType",       text: "Tipo de Alta/Registro",          getData: (intern) => { return intern.banorteData.registerType || '' }  },
                        {  width: 20,  value: "cardType",           text: "Tipo de Tarjeta",                getData: (intern) => { return intern.banorteData.cardType || '' }  },
                        {  width: 20,  value: "logisticDebitCard",  text: "Numero de tarjeta",              getData: (intern) => { return intern.banorteData.logisticDebitCard || ''}  },
                        {  width: 20,  value: "productKey",         text: "Clave de Producto",              getData: (intern) => { return intern.banorteData.productKey || '' }  },
                        {  width: 20,  value: "bankCode",           text: "Código de Banco",                getData: (intern) => { return intern.banorteData.bankCode || '' }  },
                        {  width: 20,  value: "accountNumber",      text: "No de Cuenta",                   getData: (intern) => { return intern.banorteData.accountNumber || '' }  },
                        {  width: 20,  value: "accountType",        text: "Tipo de Cuenta",                 getData: (intern) => { return intern.banorteData.accountType || '' }  },
                        {  width: 20,  value: "crCheckbook",        text: "CR Chequera",                    getData: (intern) => { return intern.banorteData.crCheckbook || '' }  },
                        {  width: 20,  value: "scolarshipAmount",   text: "Sueldo",                         getData: (intern) => { return intern.scolarshipAmount || ''}  }
                    ],
                       
                        
            'worksheetName': name,
            'headerText': name
        }

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []

        config.forEach(item => {
            width.push({ width: item.width })
        });

        worksheetPicoUsoInfra.columns = width;

        function createHeaderCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'F4B083'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){

            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }


        function createTableAvail(interns, worksheetName){
            let cellNumber = 0;
            cellNumber++
            config.forEach((configItem) => {
                createHeaderCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            if(interns.length){
                interns.forEach((intern, index) => {
                    cellNumber++
                    config.forEach((configItem) => {
                        if(configItem.value == "#" || configItem.value == "internNumber"){
                            let numAux =  index + 1;
                            createWhiteCell(
                                configItem.col + cellNumber,
                                configItem.value == "internNumber" ? (parseInt(nextNumber) + parseInt(index)).toString() : numAux,
                                '',
                                worksheetName
                            )
                        }else{
                            createWhiteCell(
                                configItem.col + cellNumber,
                                configItem.getData(intern),
                                configItem.value,
                                worksheetName
                            )
                        }
                    });
                })
            }
        }   

        createTableAvail(interns, worksheetPicoUsoInfra)
        return workbook
    }
}

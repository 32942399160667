import React, {useContext, useState} from 'react';
import {Modal, Image, Form, Button, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { UserContext } from '../../../context/UserContext';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function DeleteCostCenterModal ({displayDeleteCostCenterModal, setDisplayDeleteCostCenterModal, currentCostCenterId, updateList, setUpdateList, currentDepartmentId, currentCompanyId}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);

    const context = useContext(UserContext);
    
    const deleteCostCenter = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const confirmation_code = e.target.elements.confirmation.value;

        if(confirmation_code.toLowerCase().trim() != "eliminar permanentemente"){
            notify("La confirmación es incorrecta", "error");
            setIsLoading(false);
            return;
        };

        try {
            await axios({
                url: `${URL}/costcenter`,
                method: "DELETE",
                data: {
                    id_costCenter: currentCostCenterId,
                    id_company: currentCompanyId, 
                    id_department: currentDepartmentId,
                    departmentField: context.getDepartmentField(),
                }
            });

            setIsLoading(false);
            notify("Centro de Costos eliminado con éxito", "success");
            setUpdateList(!updateList);
            setDisplayDeleteCostCenterModal(!displayDeleteCostCenterModal);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        };
    };

    return(
        <Modal className="new-cc-modal" show={displayDeleteCostCenterModal} onHide={() => setDisplayDeleteCostCenterModal(!displayDeleteCostCenterModal)} centered>
            <Modal.Header>
                <p>Eliminar Centro de Costos</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={deleteCostCenter} autoComplete={"off"}>

                    <Form.Group controlId="confirmation">
                        <Form.Label>Escribe "eliminar permanentemente" para continuar</Form.Label>
                        <Form.Control type="text" required/>
                        <small>¡Estos cambios son irreversibles!</small>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Aceptar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayDeleteCostCenterModal(!displayDeleteCostCenterModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
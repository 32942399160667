
import {notify} from '../utils/notify';
import Methods from './https';
import moment from 'moment';

const URL = process.env.REACT_APP_SOCKET_URL;
const axios = require("axios");

export const getInsuranceDataToSend = async (insuranceDataAux, currentInternData, currentCompany) => {

    const streetNumberCompany = currentCompany.street + " " + currentCompany.ext_number;
    const insuredName = currentInternData.firstname + " " + currentInternData.father_lastname + " " + currentInternData.mother_lastname; 
    const streetNumberIntern = currentInternData.street + " " + currentInternData.ext_number;
    const planType = getPlanType(insuranceDataAux.planType);
    const formatedDate = getFormatedDate(insuranceDataAux.contractStartDate.slice(0,10));
    const paymentType = '';
    let year = currentInternData.curp.substring(4,6);
    const month = currentInternData.curp.substring(6,8);
    const day = currentInternData.curp.substring(8,10);
    const birthDate = moment([year, parseInt(month)-1, day])._d;
    let gender = ""
    
    if(currentInternData.curp != "" && currentInternData.curp != "_"){
        gender = currentInternData.curp.charAt(10);
    }

    let duration = paymentType == "Mensual" ? "1" : insuranceDataAux.contractDuration;
   
    const data = {
        paymentType: paymentType,
        planType: planType,
        paymentStatus: 1,
        startValidity: formatedDate,
        duration: duration,
        font: 1,
        company: currentCompany.business_name,
        companyRfc: currentCompany.rfc,
        streetNumber: streetNumberCompany, 
        suburb: currentCompany.suburb,
        insuredName: insuredName,
        streetNumberIntern: streetNumberIntern,
        suburbIntern: currentInternData.suburb,
        college: currentInternData.college,
        scholarship: currentInternData.scholarship,
        curp: currentInternData.curp,
        berneficiary: insuranceDataAux.berneficiary,
        relationship: insuranceDataAux.relationship, 
        percent: insuranceDataAux.percent,
        berneficiarySecond: insuranceDataAux.berneficiarySecond || "",
        relationshipSecond: insuranceDataAux.relationshipSecond || "",
        percentSecond: insuranceDataAux.percentSecond.toString().replace(/\s+/g, '') || 0,
        phone: currentInternData.phone,
        career: currentInternData.career, 
        periodType: currentInternData.period_type,
        scolarshipAmount: currentInternData.scolarshipAmount,
        gender: gender,
        birthday: birthDate,
        email: currentInternData.email,
        currentInternId: currentInternData._id,
        currentInsuranceId: insuranceDataAux._id,
        insuranceAgreementId: currentInternData.insuranceAgreementId,
        internRfc: currentInternData.rfc,
        insuranceId: insuranceDataAux.insuranceId,
        organicLossesAux: insuranceDataAux.organicLosses,
        policyCostAux: insuranceDataAux.policyCost
        
    }
    return data;
}

export const deleteInsuranceIdAndPolicies = async (insurance, currentInternData, currentCompany, isInvalidation) => {
    try {

        let body = {
            insurance_id: insurance._id
        }
        if(insurance.insuranceId && insurance.insuranceId != "_"){

            const sendData = await getInsuranceDataToSend(insurance, currentInternData, currentCompany);
            
            if(sendData.internInsurance == "Baja" || sendData.internInsurance == "" ){
                notify("No cuentas con una póliza activa", "error");
                return;
            }

            await axios ({
                method: "POST",
                url: `${URL}/insurance/delete`,
                data: sendData,
            })

            if(isInvalidation){
                await Methods.deleteInsuranceLocal(body);
            }

            notify("Baja de poliza exitosa", "success");
        } else if(insurance.insuranceId && insurance.insuranceId == "_") {
            await Methods.deleteInsuranceLocal(body);
        }
    } catch (e){
        e.data && notify(e.data.response.message, "error");
    }
} 

export const deletePosteriorPolicies = async (policiesByIntern, action, inactiveDate, contractId, currentInternData, currentCompany) => {
        
    let isInvalidation = true;
    let contractEndDateAux = moment(inactiveDate);
    try {
        if(policiesByIntern && policiesByIntern.length){
            for(let policy of policiesByIntern){
                let contractStartDate = policy.contractStartDate.includes("/") ? moment(policy.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(policy.contractStartDate.slice(0,10)).startOf("day");
                let isCurrentContract = policy.contractId == contractId;
                let verifyDates = action == 'Inactivo' ? (moment(contractEndDateAux).isBefore(contractStartDate)) : true;
                if(isCurrentContract && verifyDates && !policy.isPaid){
                    
                    await deleteInsuranceIdAndPolicies(policy, currentInternData, currentCompany, isInvalidation);
                } else if(policy.isPaid){
                    notify("El registro con número de seguro " + policy.insuranceId + " no puede ser eliminada porque ya está pagada", "error");
                }
            }
        }

    }catch(error){
        notify('Ocurrío un error', 'error');
    } 
}

export const createInsurance = async (intern, company) => { 
    const streetNumberCompany = company.street + " " + company.ext_number;
    const insuredName = intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname; 
    const streetNumberIntern = intern.street + " " + intern.ext_number;
    const planType = getPlanType(intern.planType);
    const formatedDate = getFormatedDate(intern.contractStartDate.slice(0,10));
    let year = intern.curp.substring(4,6);
    const month = intern.curp.substring(6,8);
    const day = intern.curp.substring(8,10);
    const birthDate = moment([year, parseInt(month)-1, day])._d;
    let gender = ""
    
    if(intern.curp != "" && intern.curp != "_"){
        gender = intern.curp.charAt(10);
    }

    let duration = intern.paymentType == "Mensual" ? "1" : intern.contractDuration;

    const insuranceDataAux = {
        organicLosses: planType==1 ? "$400,000.00" : "$200,000.00",
        expenseReimbursement: planType==1 ? "$200,000.00" : "$100,000.00",
        policyCost: insuranceCost(planType, duration,intern.paymentType)
    }

    if(intern.percent != 100){
        if(intern.percent + parseInt(intern.percentSecond) == 100){
            if(!intern.berneficiarySecond && !intern.relationshipSecond){
                return false;
            } 
        } 
        
    }
   
    const data = {
        paymentType: intern.paymentType,
        planType: planType,
        paymentStatus: 1,
        startValidity: formatedDate,
        duration: duration,
        font: 1,
        company: company.business_name,
        companyRfc: company.rfc,
        streetNumber: streetNumberCompany, 
        suburb: company.suburb,
        insuredName: insuredName,
        streetNumberIntern: streetNumberIntern,
        suburbIntern: intern.suburb,
        college: intern.college,
        scholarship: intern.scholarship,
        curp: intern.curp,
        berneficiary: intern.berneficiary,
        relationship: intern.relationship, 
        percent: intern.percent,
        berneficiarySecond: intern.berneficiarySecond || "",
        relationshipSecond: intern.relationshipSecond || "",
        percentSecond: intern.percentSecond.toString().replace(/\s+/g, '') || 0,
        phone: intern.phone,
        career: intern.career, 
        periodType: intern.period_type,
        scolarshipAmount: intern.scolarshipAmount,
        gender: gender,
        birthday: birthDate,
        email: intern.email,
        currentInternId: intern._id,
        insuranceAgreementId: "",
        internRfc: intern.rfc,
        organicLossesAux: insuranceDataAux.organicLosses,
        policyCostAux: insuranceDataAux.policyCost,
        masiveUploads: true
    }

    if(intern.percent != "100" ){
        if(parseInt(intern.percent) + parseInt(intern.percentSecond)  == 100){
            if(intern.berneficiarySecond === "" || intern.relationshipSecond.length === ""){
                return false;
            } 
        }else{
            return false;
        } 
    }

    let insuranceIdAux = await Methods.sendInsurance(data);
    const dataInsurance = {
        _id:  intern.currentInsuranceId,
        idIntern: intern._id,
        idCompany: company._id,
        planType: intern.planType,
        policyCost: insuranceDataAux.policyCost,
        insuranceCompany: "AIG",
        expenseReimbursement: insuranceDataAux.expenseReimbursement,
        insurancePayment: intern.insurancePayment ,
        organicLosses: insuranceDataAux.organicLosses,
        contractStartDate: intern.contractStartDate,
        contractEndDate: intern.contractEndDate,
        contractDuration: duration,
        berneficiary: intern.berneficiary,
        berneficiarySecond: intern.berneficiarySecond || "",
        percent: intern.percent,
        percentSecond: intern.percentSecond || "",
        relationship: intern.relationship ,
        relationshipSecond: intern.relationshipSecond || "",
        paymentType: intern.paymentType ,
        creationDate: moment().startOf('day').format() || "",
        renewInsuranceDate: "",
        insuranceId: insuranceIdAux.data,
        isPaid: false,
        currentContractId: intern.contractValues.contractId || "",
        insuranceIdCreationDate: moment().format('DD/MM/YYYY'),
        insuranceIdCreationTimestamp: parseInt(moment().format('X'))
    }
    intern.insurance = insuranceIdAux.data
    await Methods.createInsuranceItem(intern, dataInsurance);
    const internValues = {
        _id: intern._id,
        attributes: [
            { updateKey:"currentInsuranceId", updateValue: dataInsurance._id},
            { updateKey:"insurance", updateValue: insuranceIdAux.data}
        ]
    }
    await Methods.updateIntern(internValues)
    return true;
}


const getPlanType = (value) => {
    const type = {
        "Plan 1": 1,
        "Plan 2": 2
    }
    return type[value] || "";
}

const getFormatedDate = (dateString) => {
    const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
    let result;
    let year;
    let month;
    let day;
    if(regexDate.test(dateString)){
        const newDateArray = dateString.split("-");
        year = newDateArray[0];
        month = addZeroToDate(newDateArray[1]);
        day = addZeroToDate(newDateArray[2]);
        result = month + "/" + day + "/"+ year;
    } else {        
        const newDateArray = dateString.split("/");
        month = addZeroToDate(newDateArray[0]);
        day = addZeroToDate(newDateArray[1]);
        result = day + "/" + month + "/" + newDateArray[2];
    }
    return result;
}

const addZeroToDate = (value) => {
    if(value.length == 1){
        return "0" + value;
    } else{
        return value;
    }
};

const insuranceCost = (planType, duration, paymentType) => {
    const valuesPlan1 = {
        0: "$150.86",
        1: "$150.86",
        2: "$241.38",
        3: "$349.14",
        4: "$439.66",
        5: "$525.86",
        6: "$594.83",
    }

    const valuesPlan2 = {
        0: "$68.97",
        1: "$68.97",
        2: "$133.62",
        3: "$189.66",
        4: "$250.00",
        5: "$310.34",
        6: "$370.69",
    }

    if(planType == 1){
        if(paymentType == "Mensual") return "$150.86";
        return valuesPlan1[duration] || 0.00;
    }else{
        if(paymentType == "Mensual") return "$68.97";
        return valuesPlan2[duration] || 0.00;
    }  
}

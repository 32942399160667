import moment from 'moment';
import React, {useEffect,useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import Methods from "../../../utils/https";
import { notify } from '../../../utils/notify';



export default function DeleteYear({showDeleteYear, setShowDeleteYear, dates, selectedYear, years, periodAux}){
    const  [datesToDelete, setDatesToDelete] = useState( []);
    const  [deleteIds, setDeleteIds] = useState([]);
    useEffect(()=>{
        checkYears()
    }, [showDeleteYear]);



    const checkYears = () => {
        let yearToDelete = years.filter(y => y.year === selectedYear)[0];
        let yearAux = dates?.filter(date => date.batch_id === yearToDelete.batch )
        let deleteAux = []
        for(let aux in yearAux){
            deleteAux.push(yearAux[aux]._id)
        }
        setDeleteIds(deleteAux)
        setDatesToDelete({end_date: yearAux[0]?.end_date, start_date: yearAux[yearAux.length - 1]?.start_date})
        }

    const verifyDeleteYear = async () => {
        let response = await Methods.getInvoiceRecordsByPeriod({period: periodAux.selectedPeriod , date: datesToDelete.start_date.slice(-4)})
        if(response.length != 0){
            notify("No se puede borrar el calendario por que hay una prefactura autorizada", "error");
            setShowDeleteYear(!showDeleteYear);
            return;
        }
        Methods.deletePeriodYear(deleteIds);
        notify(`El calendario ${periodAux.selectedPeriod} ${selectedYear} se borro correctamente`, "success");
        setShowDeleteYear(!showDeleteYear);
    }

    return(
        <Modal className="prefacture-modals" onHide={() => {}} show={showDeleteYear} centered>
            <Modal.Body>
                <Modal.Header>
                    <p>Seguro que quieres eliminar el año {selectedYear} ?</p>
                    <p> de {datesToDelete.start_date} hasta {datesToDelete.end_date}</p>
                </Modal.Header>
                <Button className="float-right ml-2" variant="primary" onClick={async () => {verifyDeleteYear()}}>
                    Confirmar
                </Button>
                <Button className="float-right" variant="danger" onClick={() => {setShowDeleteYear(!showDeleteYear);}}>
                    Cancelar
                </Button>
            </Modal.Body>
        </Modal>
    );
};
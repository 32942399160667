import React, { useState, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import {Modal, Button, Table} from 'react-bootstrap';
import readXlsxFile from 'read-excel-file';
import { notify } from '../../../utils/notify';
import Methods from '../../../utils/https';
import moment from 'moment';

export default function Upload({showUpdateExcel, setShowUpdateExcel, currentPeriod, approvedInterns, updateList, setUpdateList, setSetData}){

    const [data, setData] = useState([]);
    const [errors, setErrors] = useState("");
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [processed, setProcessed] = useState(false);
    const userFullName = `${context.user.data.fullName}`;
    const company = context.user.company;
    const role = context.user.data.role;
    const userEmail = context.user.data.email;

    const onChange = (e) => {
        setData([]);
        readXlsxFile(e.target.files[0]).then((rows) => { 
            if(rows[2][0] != "Plantilla Ajustes Masivos"){
                notify(`Se necesita el formato Plantilla Ajustes Masivos para esta sección`, "error");
                e.target.value = null;
                return;
            }
            setData(rows.slice(4));
        })
    };

    const updateIntern = async (data, intern) => {
        let startDate;
        let endDate;
        let alreadyExist = false;
        if(currentPeriod){
            startDate = moment(currentPeriod[0]).format("DD/MM/YYYY");
            endDate = moment(currentPeriod[1]).format("DD/MM/YYYY");
        }

        let value = Number(intern.newValueByAmount);
        if(isNaN(value)) {
            intern.error = "El incremento debe de ser un número";
            return;
        }

        let value2 = Number(intern.newValueByAmount2);
        if(isNaN(value2)) {
            intern.error = "El decremento debe de ser un número";
            return;
        }

        let daysValue = Number(intern.newValueByDay);
        if(isNaN(daysValue)) {
            intern.error = "El incremento en días debe de ser un número";
            return;
        }
        if (daysValue%0.5) {
            let days_new = Math.round(daysValue/.5)*.5;
            intern.error = `La cantidad de incremento en días se ha redondeado de ${daysValue} a ${days_new}`;
            daysValue = days_new;
        }

        let daysValue2 = Number(intern.newValueByDay2);
        if(isNaN(daysValue2)) {
            intern.error = "El decremento en días debe de ser un número";
            return;
        }
        if (daysValue2%0.5) {
            let days_new = Math.round(daysValue2/.5)*.5;
            intern.error = `La cantidad de decremento en días se ha redondeado de ${daysValue2} a ${days_new}`;
            daysValue2 = days_new;
        }

        daysValue -= daysValue2;
        value -= value2;

        if (daysValue !== 0 && value !== 0) {
            intern.error = "No puedes realizar ajustes de monto y días simultáneamente";
            return;
        }

        value = value !== 0 ? value : daysValue * (data.scolarshipAmount / 30);
        value = Math.round(value * 100) / 100;
        let adjustments = [];
        if(!data.adjustments?.length){
            adjustments.push({
                startDate: startDate,
                endDate: endDate,   
                adjustment: value,
                adjustmentDaysToPay: daysValue
            });
        }else{
            for(let adjutsment of data.adjustments){
                if(adjutsment.startDate == startDate && adjutsment.endDate == endDate){
                    adjutsment.adjustment = value;
                    adjutsment.adjustmentDaysToPay = daysValue;
                    alreadyExist = true;
                }
            }
            adjustments = data.adjustments
            if(!alreadyExist){
                adjustments.push({
                    startDate: startDate,
                    endDate: endDate,   
                    adjustment: value,
                    adjustmentDaysToPay: daysValue
                });
            } 
        }

        data = {    
            _id: data._id,
            attributes: [
                {updateKey: "adjustments", updateValue: adjustments},
            ]
        };
        data.adjustment = value;
        let record = {
            message: `Ajuste en el abono/descuento del practicante ${intern.firstname} ${intern.father_lastname} de $${data.adjustment} a $${value}.`,
            author: userFullName,
            role: role,
            companyId: company._id,
            createdAt: new Date(),
            authorEmail: userEmail,
        };

        await Methods.updateIntern(data);
        if(value != 0) {
            await Methods.createRecord(record);
        }
    }

    const getDataAdjust = async () => {
        let aux = [];
        let promises = [];
        for (let record of data) {
            let intern = {
                _id: record[0],
                firstname: record[1],
                father_lastname: record[2],
                mother_lastname: record[3],
                newValueByAmount: record[4],
                newValueByAmount2: record[5],
                newValueByDay: record[6],
                newValueByDay2: record[7],
                processed: false,
                error: "",
            }
            aux.push(intern);
        }
        let auxErrors = [];
        for (let intern of aux){
            promises.push(updateInternPromise(intern, auxErrors));
            if(promises.length > 200){
                await Promise.all(promises);
                promises = [];
            }
        }  

        await Promise.all(promises);
        promises = [];
        if(auxErrors.length){
            setErrors(auxErrors);
        }
    }

    const updateInternPromise = async(intern, auxErrors) => {
        const filtered = approvedInterns.filter(item => item._id == intern._id);
        if(filtered.length){
            await updateIntern(filtered[0], intern);
        } else {
            intern.error = "No se encontró el practicante en la prefactura actual"; 
        }
        if (intern.error) {
            auxErrors.push(intern);
        }
    }

    const submitData = async () => {
        setLoading(true);
        await getDataAdjust();
        setData([]);
        setLoading(false);
        setProcessed(true);
    }

    return(
        <Modal className="prefacture-modals" onHide={() => {}} show={showUpdateExcel} centered>
            <Modal.Body>
                {!processed && <Modal.Header>
                    <p>Subir Excel</p>
                </Modal.Header>}
                {!processed && <input type="file" accept=".xlsx, .xls, .csv" onChange={onChange} />}
                {!processed && !loading && 
                <Button className="float-right ml-2" variant="primary" onClick={submitData} disabled={!data.length}>
                    Confirmar
                </Button>}
                {!processed && !loading && 
                <Button className="float-right" variant="danger" onClick={() => {setShowUpdateExcel(!showUpdateExcel); setData([]);}}>
                    Cancelar
                </Button>}
                {!processed && loading && <p className='float-right'>Subiendo...</p>}

                {processed && errors && <Modal.Header>
                    <p>Errores</p>
                </Modal.Header>}
                {processed && !errors && <Modal.Header>
                    <p>No se encontraron errores</p>
                </Modal.Header>}
                {processed && errors && 
                <Table hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Error</th>
                        </tr>
                    </thead>

                    <tbody>
                        {errors ? errors.map((intern, index) => {
                            return (
                                <tr key={index}>
                                    <td>{intern._id}</td>
                                    <td>{intern.error}</td>
                                </tr>
                            )
                        }): <tr></tr> } 
                    </tbody>
                </Table>}
                {processed && 
                <Button className="float-right" variant="primary" onClick={() => {
                    setShowUpdateExcel(!showUpdateExcel); 
                    setData([]); 
                    setProcessed(false); 
                    setErrors("");
                    setUpdateList(!updateList);
                    setSetData(true);
                }}>
                    Continuar
                </Button>}
            </Modal.Body>
        </Modal>
    );
};
import React, {useState, useEffect, useContext} from "react";
import {motion} from "framer-motion";
import {Table, Row, Col, Card, OverlayTrigger, Tooltip, Popover, ListGroup, Accordion, Button, Form, InputGroup} from "react-bootstrap";
import { DropdownList } from "react-widgets";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSyncAlt, faEdit, faTrash, faCamera, faChevronLeft, faChevronRight, faSearch} from '@fortawesome/free-solid-svg-icons';
import NewCareerModal from "./components/NewCareerModal";
import NewFacultyModal from "./components/NewFacultyModal";
import NewUniversity from './components/NewUniversity';
import UpdateUniversity from './components/UpdateUniversity';
import UpdateCareerModal from './components/UpdateCareerModal';
import UpdateFacultyModal from './components/UpdateFacultyModal';
import DeleteUniversityModal from './components/DeleteUniversityModal';
import DeleteCareerModal from './components/DeleteCareerModal.jsx';
import DeleteFacultyModal from './components/DeleteFacultyModal.jsx';
import Methods from '../../utils/https';
import DefaultUser from '../../assets/default-user.png';
import { notify } from "../../utils/notify";
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import './Universities.css';
import { UserContext } from "../../context/UserContext";
import LoadingModal from '../../custom/LoadingModal/LoadingModal';
const axios = require("axios");

export default function Universities () {
    const context = useContext(UserContext);
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [updateList, setUpdateList] = useState(false);
    const [rotateSync, setRotateSync] = useState(false);
    const rotate = rotateSync ? "rotate" : "";
    const [displayNewUniversity, setDisplayNewUniversity] = useState(false);
    const [displayDeleteUniversityModal, setDisplayDeleteUniversityModal] = useState(false);
    const [displayUpdateUniversity, setDisplayUpdateUniversity] = useState(false);
    const [currentUniversityId, setcurrentUniversityId] = useState("");
    const [currentUniversity, setCurrentUniversity] = useState("");
    const [displayNewFacultyModal, setDisplayNewFacultyModal] = useState(false);
    const [displayUpdateFacultyModal, setDisplayUpdateFacultyModal] = useState(false);
    const [currentFaculty, setCurrentFaculty] = useState({})
    const [currentFacultyId, setCurrentFacultyId] = useState("");
    const [currentFacultyName, setCurrentFacultyName] = useState("");
    const [displayFacultyModal, setDisplayFacultyModal] = useState(false);
    const [displayNewCareerModal, setDisplayNewCareerModal] = useState(false);
    const [displayUpdateCareerModal, setDisplayUpdateCareerModal] = useState(false);
    const [currentCareerId, setCurrentCareerId] = useState("");
    const [currentCareerName, setCurrentCareerName] = useState("");
    const [displayCareerModal, setDisplayCareerModal] = useState(false);
    const [currentCareer, setCurrentCareer] = useState({});
    const [query, setQuery] = useState("");
    const [currentLimit, setCurrentLimit] = useState(10);
    const [universities, setUniversities] = useState([]);
    const [universitiesAux, setUniversitiesAux] = useState({
        allUniversities: undefined,
        totalPages: 0,
        maxPages: 3,
        currentLimit: 10
    });
    const [loadingUniversities, setLoadingUniversities] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [displayFaculty, setDisplayFaculty] = useState({});
    const [displayFacultyCareers, setDisplayFacultyCareers] = useState({});
    const {allUniversities, totalPages, maxPages} = universitiesAux;

    useEffect(() => {
        getUniversitiesByPage(currentLimit, query, maxPages);
    }, [updateList]);

    const getUniversitiesByPage = async (_limit, query, maxPages) => {
        setCurrentPage(0);
        const limit = _limit || 10;
        const maxPagesAux = maxPages || 3;
        const data = {
            limit: limit,
            maxPages: maxPagesAux
        };
        try {
            setLoadingUniversities(true);
            setIsLoading(true);

            if(query) data.query = query;
            
            const response = await axios({
                url: `${URL}/universities/list`,
                method: "POST",
                data: data
            });

            setUniversities(response.data.data[0])
            setUniversitiesAux({
                ...universitiesAux,
                allUniversities: response.data.data,
                totalPages: response.data.pages
            });

            setIsLoading(false);
            setLoadingUniversities(false); 
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setIsLoading(false);
            setLoadingUniversities(false);
            setUniversitiesAux({
                ...universitiesAux,
                allUniversities: [],
                totalPages: 0,
            });
        }
    };

    const getNextPages = async (lastEvaluatedKey) => {
        setLoadingUniversities(true);

        const data = {
            limit: currentLimit,
            maxPages: maxPages,
            startKey: lastEvaluatedKey._id,
            query: query
        };
        
        let response = await axios({
            url: `${URL}/universities/list`,
            method: "POST",
            data: data
        });

        if(response && response.data){
            let allUniversitiesAux = allUniversities.concat(response.data.data);
            let auxPages = totalPages + response.data.pages
            setUniversitiesAux({
                ...universitiesAux,
                allUniversities: allUniversitiesAux,
                totalPages: auxPages
            });
        }
        setLoadingUniversities(false);
    }

    const handleChangePage = (_page) => {
        setUniversities(allUniversities[_page])

        if(_page + 1 == allUniversities.length){
            getNextPages(allUniversities[_page][allUniversities[_page].length - 1]);
        }
    };

    const handleUniversityOptions = (option, university) => {
        switch (option) {
            case "create":
                setDisplayNewUniversity(!displayNewUniversity);
                break;
            case "update":
                setCurrentUniversity(university);
                setDisplayUpdateUniversity(!displayUpdateUniversity);
                break;
            case "delete":
                setCurrentUniversity(university);
                setDisplayDeleteUniversityModal(!displayDeleteUniversityModal);
                break;
            default:
                break;
        };
    };

    const handleOpenNewFaculty = (id_college, college) => {
        setcurrentUniversityId(id_college);
        setCurrentUniversity(college)
        setDisplayNewFacultyModal(!displayNewFacultyModal);
    };

    const handleFacultyOptions = (option, faculty_id, university_name, faculty_name) => {
        setCurrentFacultyId(faculty_id);
        setCurrentFacultyName(faculty_name);
        setCurrentUniversity(university_name);
        switch (option) {
            case "update":
                setDisplayUpdateFacultyModal(!displayUpdateFacultyModal);
                handleSetFaculty(faculty_id);
                break;
            case "delete":
                setDisplayFacultyModal(!displayFacultyModal);
                break;
            default:
                break;
        };
    };

    const handleSetFaculty = (id) => {
        let result;
        for(let university of universities){
            if(university.faculties){
                for(let Faculty of university.faculties){
                    if(Faculty._id === id) result = Faculty;
                };
            }
        };   
        setCurrentFaculty(result);
    };

    const handleOpenNewCareer = (id_college, id_faculty) => {
        setcurrentUniversityId(id_college);
        setCurrentFacultyId(id_faculty)
        setDisplayNewCareerModal(!displayNewCareerModal);
    };

    const handleCareerOptions = (option, career_id, university_name, career_name) => {
        setCurrentCareerId(career_id);
        setCurrentCareerName(career_name);
        setCurrentUniversity(university_name);
        switch (option) {
            case "update":
                setDisplayUpdateCareerModal(!displayUpdateCareerModal);
                handleSetCareer(career_id);
                break;
            case "delete":
                setDisplayCareerModal(!displayCareerModal);
                break;
            default:
                break;
        };
    };

    const handleSetCareer = (id) => {
        let result;
        for(let university of universities){
            if(university.faculties)
                for(let faculties of university.faculties)
                    if(faculties.careers)
                        for(let career of faculties.careers)
                            if(career._id === id) result = career;
        };
        setCurrentCareer(result);
    };


    const handleDisplayFaculty = (e, index) => {
        if(e){
            if(displayFaculty[index] && displayFaculty[index] == true){
                delete displayFaculty[index]
                setDisplayFaculty({...displayFaculty})
            }else{
                setDisplayFaculty({[index]: true})
            }
        }
    }

    const handleDisplayFacultyCarrers = (e, faculty) => {
        if(e){
            if(displayFacultyCareers[faculty._id] && displayFacultyCareers[faculty._id] == true){
                delete displayFacultyCareers[faculty._id]
                setDisplayFacultyCareers({...displayFacultyCareers})
            }else{
                setDisplayFacultyCareers({[faculty._id]: true})
            }
        }
    }

    const generateCareersTable = (university, faculty, cindex) => {
        
        if(!displayFacultyCareers[faculty._id]){
            return <></>;
        }

        if(!faculty.careers || faculty.careers.length == 0){
            return <></>;
        };
        return(
            <Accordion.Collapse eventKey={String(cindex)} className="accordion-colapse">
                <div className="table table-universities table-careers" id="table-universities">
                    <div className="row row-university thead-row-universities">
                        <div className="col col-head-university">Carrera</div>
                        <div className="col-1 col-head-university options">Opciones</div>
                    </div>
                    {faculty.careers.map((career, ccindex) => {
                        return(
                            <div className="university-info-table">
                                <div className="row row-university-info">
                                    <div className="col col-university-info">
                                        <div className="college-name career">
                                            {career._name}
                                        </div>
                                    </div>
                                    <div className="col-1 col-university-info options">
                                        <div className="options center-container">
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Editar Carrera</Tooltip>}
                                            >
                                                <a href="#" onClick={() => {handleCareerOptions("update", career._id, university._name, career._name)}}><FontAwesomeIcon icon={faEdit}/></a>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Eliminar Carrera</Tooltip>}
                                            >
                                                <a href="#" onClick={() => {handleCareerOptions("delete", career._id, university._name, career._name)}}><FontAwesomeIcon icon={faTrash}/></a>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Accordion.Collapse>
        )
    };

    const generateFacultiesTable = (university, universityId, index) =>{
        if(!displayFaculty[universityId]) return <></>
        if(!university.faculties || university.faculties.length == 0){
            return <></>;
        };
        return(
                <Accordion.Collapse eventKey={String(universityId)} className="accordion-colapse">
                    <div className="table table-universities table-faculties" id="table-universities">
                        <Accordion>
                                <div className="row row-university thead-row-universities">
                                    <div className="col col-head-university">{context.getFacultadField(true)}</div>
                                    <div className="col col-head-university">Información del contacto</div>
                                    <div className="col-1 col-head-university options">Opciones</div>
                                </div>
                                {university.faculties.map((faculty, cindex) => {
                                    return(
                                        <div className="university-info-table">
                                            <div className="row row-university-info">
                                                <div className="col col-university-info">
                                                    <div className="college-name">
                                                        <Accordion.Toggle as={Button} variant="link" eventKey={String(cindex)} onClick={(e)=> {handleDisplayFacultyCarrers(e, faculty)}}>
                                                            {faculty._name}
                                                        </Accordion.Toggle>
                                                    </div>
                                                </div>
                                                <div className="col col-university-info">
                                                    <div className="college-info">
                                                        <div>
                                                            {faculty.contactName} {faculty.fatherLastName} {faculty.motherLastName}
                                                        </div>
                                                        <div>
                                                            {faculty.email}
                                                        </div>
                                                        <div>
                                                            {faculty.phone}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-1 col-university-info options">
                                                    <div className="options center-container">
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Nueva Carrera</Tooltip>}
                                                        >
                                                            <a href="#" onClick={() => {handleOpenNewCareer(university._id, faculty._id)}}><FontAwesomeIcon icon={faPlusCircle}/></a>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Editar {context.getFacultadField()}</Tooltip>}
                                                        >
                                                            <a href="#" onClick={() => {handleFacultyOptions("update", faculty._id, university._name, faculty._name)}}><FontAwesomeIcon icon={faEdit}/></a>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Eliminar {context.getFacultadField()}</Tooltip>}
                                                        >
                                                            <a href="#" onClick={() => {handleFacultyOptions("delete", faculty._id, university._name, faculty._name)}}><FontAwesomeIcon icon={faTrash}/></a>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </div>
                                            {displayFacultyCareers[faculty._id] && displayFacultyCareers[faculty._id] == true && generateCareersTable(university, faculty, cindex)}
                                        </div>
                                    )
                                })}
                                
                            
                        </Accordion>
                    </div>
                </Accordion.Collapse>
        )

    };

    const generateUniversitiesTable = () =>{
        if(!universities || universities.length == 0){
            return <></>;
        };
        return (
            <div className="table table-universities" id="table-universities">
                <Accordion>
                    <div className="row row-university thead-row-universities">
                        <div className="col-1 col-head-university logo">Logo</div>
                        <div className="col col-head-university">Universidad</div>
                        <div className="col col-head-university">Información</div>
                        <div className="col-1 col-head-university options">Opciones</div>
                    </div>
                    {universities.map((university, index) => {
                        return(
                            <div className="university-info-table">
                                <div className="row row-university-info">
                                    <div className="col-1 col-university-info logo">
                                        <div className="profile-photo" style={{backgroundImage: university.logo_url != "" ? `url(${university.logo_url})` : `url(${DefaultUser})`}}>
                                            <a href="#" className="profile-photo-camera">
                                                <Form.Group className="files">
                                                    <a href="#">
                                                        <Form.File 
                                                        onChange={(e) => {
                                                                e.preventDefault();
                                                                if(e.target.files[0]){ handleUploadUniversityLogo(university._id, e.target.files[0], "University_Logo", "University Logo", undefined, true)
                                                                };
                                                            }
                                                        }
                                                        id={university._id}
                                                        label={<FontAwesomeIcon icon={faCamera}/>}
                                                        />
                                                        </a>
                                                </Form.Group>
                                            </a>
                                        </div>   
                                    </div>
                                    <div className="col col-university-info">
                                        <div className="college-name">
                                                <Accordion.Toggle as={Button} variant="link" eventKey={String(university._id)} onClick={(e)=> {handleDisplayFaculty(e, university._id)}}>
                                                    {university._name}
                                                </Accordion.Toggle>
                                        </div>
                                    </div>
                                    <div className="col col-university-info">
                                        <div className="college-info">
                                            <div>
                                                {university.contactName} {university.fatherLastName} {university.motherLastName}
                                            </div>
                                            <div>
                                                {university.email}
                                            </div>
                                            <div>
                                                {university.phone}
                                            </div>
                                            <div>
                                                {university.sign ? `El contacto firma por todas las ${context.getFacultadField(false, true)}` : `El contacto NO firma por todas las ${context.getFacultadField(false, true)}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 col-university-info options">
                                        <div className="options center-container">
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Nueva {context.getFacultadField()}</Tooltip>}
                                            >
                                                <a href="#" onClick={() => {handleOpenNewFaculty(university._id, university)}}><FontAwesomeIcon icon={faPlusCircle}/></a>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Editar Universidad</Tooltip>}
                                            >
                                                <a href="#" onClick={() => {handleUniversityOptions("update", university)}}><FontAwesomeIcon icon={faEdit}/></a>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Eliminar Universidad</Tooltip>}
                                            >
                                                <a href="#" onClick={() => {handleUniversityOptions("delete", university)}}><FontAwesomeIcon icon={faTrash}/></a>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                                {(displayFaculty[university._id] && displayFaculty[university._id] == true) && generateFacultiesTable(university, university._id, index)}
                            </div>
                        )
                    })}                            
                </Accordion>
            </div>
        )
    };


    const getExtension = (fileName) => {
        let basename = fileName.split(/[\\/]/).pop();
        let pos = basename.lastIndexOf(".");      
  
        if (basename === "" || pos < 1){
            return "";
        };
  
        return basename.slice(pos + 1);            
    };

    const handleUploadUniversityLogo = async (universityId, photo) => {
        try {
            let file = new FormData(); 
            const fileExtension = getExtension(photo.name);
            const fileName = `picture_${universityId}.${fileExtension}`;
            const extensions = ["png", "jpg", "jpeg"];
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            const base64file = await toBase64(photo);

            if(!extensions.includes(fileExtension)){
                notify("¡La foto de perfil debe estar en formato PNG, JPG o JPEG!", "error");
                return;
            };

            if(photo.size > 10485760){
                notify(`El archivo ${photo.name} pesa más de 10 MB`, "error");
                return;
            };

            file.append("file", base64file);
            file.append("path", `universities/docs/${universityId}/${fileName}`);
            file.append("_id", universityId);
            await axios({
                url: `${URL}/universities/logo/`,
                method: "POST",
                data: file
            });

            notify("¡Foto de Perfil cambiada con éxito!", "success");
            setUpdateList(!updateList);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        }
    };


    return(
        <motion.div initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }} className="universities">
            <div className="title">
                <h4>Administración de Universidades</h4>
            </div>
            <div className="alter-table">
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Registrar Universidad</Tooltip>}
                >
                    <div className="add-university" onClick={() => {setDisplayNewUniversity(!displayNewUniversity)}}><FiPlus/></div>
                </OverlayTrigger>

                <div className="search-icon"><RiSearch2Line/></div>
                <Form.Group controlId="search-bar" className="search-container">
                    <Form.Control 
                        type="text" 
                        placeholder="Búsqueda de universidad" 
                        autoComplete={"off"}
                        onKeyDown={ async (e) => { 
                            if(e.keyCode == 13){
                                setDisplayFaculty({});
                                await getUniversitiesByPage(currentLimit, e.target.value, maxPages);
                            }
                        }}

                        onInputCapture={async (e) => {
                            if(e.target.value === "") {
                                setQuery("");
                                setDisplayFaculty({});
                                await getUniversitiesByPage(currentLimit, e.target.value, maxPages);
                                return;
                            }
                            setQuery(e.target.value);
                        }}
                    />
                </Form.Group>

                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                >
                    <a className="reload-table" href="#" style={{fontSize: "25px"}} 
                        onClick={() => 
                        {setUpdateList(!updateList); 
                            setRotateSync(true)}}
                    >
                        <IoReload className={rotate}/>
                    </a>
                </OverlayTrigger>
            </div>
            
            {!isLoading  &&
                <div className = "universities-table">
                    {generateUniversitiesTable()}
                </div>
            }

            {loadingUniversities && <LoadingModal isLoading={loadingUniversities}/>}

            <div className="interns-list-options float-right">
                <p>Elementos por página:</p>
                <DropdownList
                    data={[10, 20, 40]}
                    defaultValue={10}
                    onSelect={(e) => { 
                        setCurrentLimit(e); 
                        getUniversitiesByPage(e+ 1, query, maxPages);
                    }}
                    containerClassName="limit-container"
                />
                <ReactPaginate
                    pageCount={totalPages || 1} 
                    forcePage={currentPage}
                    containerClassName="pagination-container" 
                    previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                    nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                    onPageChange={ (e) => { 
                        handleChangePage(e.selected);
                        setCurrentPage(e.selected);
                    }}
                /> 
            </div>

            <NewCareerModal 
                currentUniversityId={currentUniversityId} 
                currentFacultyId = {currentFacultyId}
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                displayNewCareerModal={displayNewCareerModal} 
                setDisplayNewCareerModal={setDisplayNewCareerModal} 
            />

            <NewFacultyModal 
                currentUniversityId={currentUniversityId}
                currentUniversity={currentUniversity} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                displayNewFacultyModal={displayNewFacultyModal} 
                setDisplayNewFacultyModal={setDisplayNewFacultyModal} 
            />

            <NewUniversity 
                displayNewUniversity={displayNewUniversity} 
                setDisplayNewUniversity={setDisplayNewUniversity} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
            />

            <DeleteUniversityModal 
                displayDeleteUniversityModal={displayDeleteUniversityModal} 
                setDisplayDeleteUniversityModal={setDisplayDeleteUniversityModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentUniversity={currentUniversity}
            />

            <UpdateUniversity 
                displayUpdateUniversity={displayUpdateUniversity} 
                setDisplayUpdateUniversity={setDisplayUpdateUniversity} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentUniversity={currentUniversity} 
            />

            <UpdateCareerModal 
                currentCareerId={currentCareerId} 
                displayUpdateCareerModal={displayUpdateCareerModal} 
                setDisplayUpdateCareerModal={setDisplayUpdateCareerModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentCareer={currentCareer} 
            />

            <UpdateFacultyModal 
                currentFacultyId={currentFacultyId} 
                displayUpdateFacultyModal={displayUpdateFacultyModal} 
                setDisplayUpdateFacultyModal={setDisplayUpdateFacultyModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentFaculty={currentFaculty}
                currentUniversity = {currentUniversity}
            />

            <DeleteCareerModal 
                currentCareerId={currentCareerId} 
                displayCareerModal={displayCareerModal} 
                setDisplayCareerModal={setDisplayCareerModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentCareerName = {currentCareerName}
                currentUniversity = {currentUniversity}
            />

            <DeleteFacultyModal 
                currentFacultyId={currentFacultyId} 
                displayFacultyModal={displayFacultyModal} 
                setDisplayFacultyModal={setDisplayFacultyModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentFacultyName = {currentFacultyName}
                currentUniversity = {currentUniversity}
            />
        </motion.div>
    );
};
import React, { useState, useEffect } from 'react'
import { Table, Row, Col, Button, Form, Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { motion } from 'framer-motion';
import Methods from '../../../utils/https';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight, faDownload} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import DatePicker from "react-datepicker";
import ExcelCSV from "../Excel/DownloadLogisticReport";
import * as Excel from "exceljs";
import {saveAs} from 'file-saver';
import { IoReload } from 'react-icons/io5';

export const ListLogisticsRecods =  ({context, id_company}) => { 
    const [availableKeys, setAvailableKeys] = useState([
        { name: "fullName", label: 'Nombre del practicante'},
        { name: "curp", label: 'CURP'},
        { name: "logisticStatus", label: 'Estatus de logística'},
        { name: "created_at" , label: 'Fecha de alta'},
        { name: "shipment", label: 'Paquetería'},
        { name: "trackingNumber", label: 'No de Guía'},
        { name: "logisticDateSent", label: 'Fecha de envío'}
      ]);
  
      const [filterValues, setFilterValues] = useState([
        { status: 'Alta', show: true },
        { status: 'Confirmado', show: true },
        { status: 'Enviado', show: true },
        { status: 'En proceso', show: true },
        { status: 'Pausado', show: true },
        { status: 'Retenido', show: true },
        { status: 'Terminado', show: true },

      ])
        const [startDate, setStartDate] = useState(new Date());
      const [endDate, setEndDate] = useState(new Date());
      const [currentPage, setCurrentPage] = useState(0);
      const [rotateSync, setRotateSync] = useState(false);
      const rotate = rotateSync ? "rotate" : "";
      const [companiesGroup, setCompaniesGroup] = useState([]);
      const [interns, setInterns] = useState([]);
      const [filteredInterns, setFilteredInterns] = useState([]);
      const pages = useState(1);
  
      useEffect(() => {
        firstCharge();
      }, []);
      
      const firstCharge = async () => {   
        await getCompanies();
        getReports();
      }
    const getReports = async(startDateAux, endDateAux) => { //cambiar para que traiga los reportes de logistica
        setRotateSync(true);
        let result = await getReportData(startDateAux, endDateAux)
        setInterns(result)
        setFilteredInterns(result)
        setRotateSync(false);
    }
      

      const getCompanies = async () => {
        if(context.user.company.groupCompaniesRFC){
            let result = await Methods.getCompaniesFromGroup(context.user.company.groupCompaniesRFC,context.user.company.rfc)
            for(let res of result){
                res.show = true;
            }
            setCompaniesGroup(result )
        }else{
            setCompaniesGroup([{_id:context.user.company._id , business_name: context.user.company.business_name, show: true}])
        }
       
      }
      
      const getReportData = async (startDateAux, endDateAux) => {
        let dateToEvaluate= {
            startDate: parseInt(moment(startDateAux || startDate).startOf('day').format('X')),
            endDate:  parseInt(moment(endDateAux || endDate).endOf('day').format('X'))};
        let status = ['Alta','Confirmado','Enviado','En proceso','Pausado','Retenido','Terminado'];
            return await Methods.getLogisticsReport({dateToEvaluate: dateToEvaluate,status: status,companies: companiesGroup});
      }

      const handleChangeFilter = async (filter) => {
        setCurrentPage(0);
        if(filter){
          filter.show = !filter.show;
          setFilterValues([...filterValues]);
        }
        changeFilteredData();
      }

      const handleChangeFilterCompany = async (filter) => {
        setCurrentPage(0);
        if(filter){
          filter.show = !filter.show;
          setCompaniesGroup([...companiesGroup]);
        }
        changeFilteredData();
      }

      const changeFilteredData = () => {
        let companyAux = companiesGroup.filter(company => company.show == true).map(filtered => filtered._id)
        let statusAux = filterValues.filter(status => status.show == true).map(filtered => filtered.status)
        let internAux = interns.filter(i => companyAux.includes(i.id_company) && statusAux.includes(i.logisticStatus) )
        setFilteredInterns(internAux)
      };

    const DatePickerAux = ({dateAux, label, setAux, isStartDate}) => {
        return (
        <>

            {label}  <Button className="default-button date-btn"
            style={{backgroundImage: "none", backgroundColor: "#1B3769", marginLeft: "5px"}}
            >
            <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
            <DatePicker
                selected={dateAux}
                onChange={
                    async (date) => {
                        setAux(date);
                        setCurrentPage(0);
                        await getReports(
                        isStartDate ? date : undefined, 
                        !isStartDate ? date : undefined
                        );
                    }
                }
                placeholderText={label}
                dateFormat='dd MMM yyyy'
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
            />  
            </Button> 
        </>        
        );
    };

    return (
        <div>
            <Col>
              <Row>
                <Col md={12} xs={12} sm={12} className='col-reports'>
                  <div className="options">
                        <Dropdown drop={"down"}>
                              <Dropdown.Toggle
                                  className="drop-down-filters"
                                  id="dropdown-custom-components"
                              >
                                 Status
                              </Dropdown.Toggle>
                              <Dropdown.Menu style={{margin: "0px", padding: "0px"}}>
                              { filterValues.map( (filter) => { 
                                  return ( 
                                    <div className='check-container'>
                                      <Form.Check 
                                        className="checkbox" 
                                        type="checkbox"                                       
                                        checked={filter.show} 
                                        onChange={ () => { 
                                          handleChangeFilter(filter);
                                        }}
                                      />
                                      {filter.status} 
                                    </div>
                                  );
                                })
                              }
                                
                              </Dropdown.Menu>
                          </Dropdown>
                          {companiesGroup.length > 1 && <Dropdown drop={"down"}>
                              <Dropdown.Toggle
                                  className="drop-down-filters"
                                  id="dropdown-custom-components"
                              >
                                 Compañias
                              </Dropdown.Toggle>
                              <Dropdown.Menu style={{margin: "0px", padding: "0px"}}>
                              { companiesGroup.map( company => { 
                                  return ( 
                                    <div className='check-container'>
                                      <Form.Check 
                                        className="checkbox" 
                                        type="checkbox"                                       
                                        checked={company.show} 
                                        onChange={ () => { 
                                          handleChangeFilterCompany(company);
                                        }}
                                      />
                                      {company.business_name} 
                                    </div>
                                  );
                                })
                              }
                                
                              </Dropdown.Menu>
                          </Dropdown>}
                        <DatePickerAux dateAux={startDate} label={'Desde la fecha:'} setAux={setStartDate} isStartDate={true}/>
                        <DatePickerAux dateAux={endDate} label={'Hasta la fecha:'} setAux={setEndDate}/>
                        <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                        >
                            <a href="#" className="reload-table-grey" onClick={async () => {await getReports()}}><IoReload className={rotate}/></a>
                      </OverlayTrigger>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12} sm={12} className='col-reports'>
                <div className="options">
                  <Button className="default-button" onClick={() =>{
                          const workbook = new Excel.Workbook();                         
                          
                          ExcelCSV.test(
                              workbook,
                              filteredInterns,
                              context.user.data.fullName
                          );
  
                          workbook.xlsx.writeBuffer().then((data) => {
                            const blob = new Blob([data], {
                              type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                            });
                            saveAs(blob, `ReportesLogistíca.xlsx`);
                          });
                      }}>
                      <FontAwesomeIcon className="inner-text" icon={faDownload}/>
                      Descargar Excel
                  </Button>
                </div>    
                </Col>
              </Row>
              <Row>
                <Col className='col-reports'>
                    <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ duration: 0.4 }} style={{width: '95%'}}>                     
                        <div className="table-scroll">
                            <Table hover>
                                <thead>
                                  { availableKeys.map(keyItem => {
                                      return <td style={{textAlign: 'center'}}>{keyItem.label}</td>
                                  })}
                                </thead>
                                {filteredInterns.length ? <tbody>
                                    {filteredInterns.map((item, index) => {
                                      return <tr>
                                          {availableKeys.map(keyItem => {
                                              return <td style={{minWidth: '200px'}}>{ item[keyItem.name] }</td>
                                          })}
                                      </tr>
                                    })}
                                </tbody> : <tbody></tbody>}
                            </Table>
                        </div>
                        <ReactPaginate
                            pageCount={pages} 
                            forcePage={currentPage}
                            containerClassName="pagination-container" 
                            previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                            nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                            onPageChange={(e) => { 
                                setCurrentPage(e.selected);
                            }}
                        />
                    </motion.div>
                </Col>
              </Row>
            </Col>
        </div>
      )
}

import React from 'react'
import { Button, Modal, Table, Row, Col} from 'react-bootstrap';
export const InternRecords = ({
    logisticRecords,
    setLogisticRecords,
    showInternLogisticRecord,
    setShowInternLogisticRecord
}) => {

    const handleClose = () => {
        setLogisticRecords([]);
        setShowInternLogisticRecord(!showInternLogisticRecord)
    } 

    const currentRecordHeader = [
        {   
            key: 'createdAt',
            keyName: 'Fecha de actualización'
        },
        {   
            key: 'authorName',
            keyName: 'Nombre de autor'
        },
        {   
            key: 'authorEmail',
            keyName: 'Email de autor'
        },
        {   
            key: 'internAccount',
            keyName: 'Cuenta de practicante'
        },
        {   
            key: 'internFullName',
            keyName: 'Nombre del practicante',
            minWidth: '300px'
        }
    ]


    return (
        <Modal 
            className="logistics-modals" 
            dialogClassName='modal-width'
            contentClassName='modal-height' 
            onHide={() => {handleClose()}} 
            show={showInternLogisticRecord} 
            centered
        >
            <Modal.Body>
                <Modal.Header>
                    <p>Historial de cambios</p>
                </Modal.Header>
                    <Row>
                        <Col>
                            <div className='logistic-accounts-table'>
                                <Table>
                                    <thead>
                                        <tr>
                                        {currentRecordHeader.map( item => {
                                            return (
                                                <th key={item.key} 
                                                style={{ 
                                                    minWidth: item.minWidth ? `${item.minWidth}` : '200px',
                                                    fontWeight: '600'}}
                                                >{item.keyName}</th>
                                            )})
                                        }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { logisticRecords.map((record, index) => {
                                            return (
                                                <tr key={index}>
                                                    {currentRecordHeader.map( item =>{
                                                        return (
                                                            <td key={item.key + index}>
                                                                {record[item.key] ||''}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
            <Button 
                className="center" 
                variant="danger" 
                onClick={() => {handleClose()}}
                style={{marginBottom: '20px'}}
            >
                Cerrar 
            </Button>
        </Modal>
    )
}

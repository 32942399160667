import React from 'react'
import { Form } from 'react-bootstrap';
import { notify } from '../../../utils/notify';
import defaultUser from '../../../assets/default-user.png';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Methods from '../../../utils/https';
export const InternProfilePicture = ({intern, context, setUpdateList, updateList}) => {
  
  const getExtension = (fileName) => {
      let basename = fileName.split(/[\\/]/).pop();
      let pos = basename.lastIndexOf(".");

      if (basename === "" || pos < 1){
          return "";
      };

      return basename.slice(pos + 1);
  };

  const handleChangeProfilePhoto = async (internId, photo) => {
        if (!context.can("update", "Interns")) {
            notify(
                "No tienes acceso a esta funcionalidad, solicítalo a un administrador",
                "error"
            );
            return;
        }

        try {

            const fileExtension = getExtension(photo.name);
            const fileName = `picture_${internId}.${fileExtension}`;
            const extensions = ["png", "jpg", "jpeg"];
            const path = `interns/docs/${internId}/${fileName}`;

            if (!extensions.includes(fileExtension)) {
                notify(
                    "¡La foto de perfil debe estar en formato PNG, JPG o JPEG!",
                    "error"
                );
                return;
            }

            if (photo.size > 10485760) {
                notify(`El archivo ${photo.name} pesa más de 10 MB`, "error");
                return;
            }

            let data = {
                path: path,
                fileName: fileName,
                fileKey: photo.key,
            };

            await Methods.getPresignedUrlAndPutImage(data, photo);
            await Methods.updateProfilePictureUrl(path, internId);
            setUpdateList(!updateList);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        }
    };

  return (
      <div className="profile-photo" style={{backgroundImage: intern.profile_picture != "" || intern.profile_picture != "N/A" ? `url(${intern.profile_picture})` : `url(${defaultUser})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
        <a href="#" className="profile-photo-camera">
          <Form.Group className="files">
              <a href="#">

                  <Form.File
                  onChange={(e) => {
                          e.preventDefault();
                          if(e.target.files[0]) { 
                              handleChangeProfilePhoto(intern._id, e.target.files[0]); 
                          }
                      }
                  }
                  id={intern._id}
                  label={<FontAwesomeIcon icon={faCamera}/>}
                  />
                </a>
          </Form.Group>
        </a>
      </div> 
  )
}

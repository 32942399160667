import React, {useState} from 'react';
import {Form, Button, InputGroup} from  'react-bootstrap';
import {getPoolConfig} from '../../../utils/getPoolConfig';
import {CognitoUser}  from 'amazon-cognito-identity-js';
import {notify} from '../../../utils/notify';
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

export default function ForgotPassword ({setCurrentTab}) {
    const [stage, setStage] = useState(1);
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [seePass, setSeePass] = useState(false);
    const [seeConfirmPass, setConfirmPass] = useState(false);
    const Pool = getPoolConfig();

    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool: Pool
        });
    }

    const sendCode = event => {
        event.preventDefault();
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);

        if(!regex.test(email)){
            notify("El correo ingresado no es válido. Inténtalo nuevamente", "error");
            return;
        }

        getUser().forgotPassword({
            onSucces: data => {},
            onFailure: err => {
                switch (err.code) {
                    case "UserNotFoundException":
                        notify("El correo ingresado no ha sido registrado aún", "error");
                        break;
                
                    default:
                        notify(err.code, "error");
                        break;
                }
            },
            inputVerificationCode: data => {
                notify("El código de verificación ha sido enviado. Revisa tu correo.", "info")
                setStage(2);
            }
        });
    };

    const resetPassword = event => {
        event.preventDefault();

        const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\w<>{}()\[\]'"+\-=*/\\,.;:!@#$%^&¡!¿?~`|]{8,24}$/);

        if(!regex.test(password)){
            notify("Tu contraseña debe tener al menos 8 caracteres, una letra en mayúscula y un número", "error");
            return;
        }

        if(password != confirmPassword){
            notify("Las contraseñas no coinciden", "error");
            return;
        };

        getUser().confirmPassword(code, password, {
            onSuccess:  data => {
                notify("Tu contraseña ha sido cambiada exitosamente.", "success");
                setCurrentTab("login");
            },
            onFailure: err => {
                switch (err.code) {
                    case "CodeMismatchException":
                        notify("El código de verificación es inválido. Intenta nuevamente", "error");
                        break;

                    default:
                        notify(err.code);
                        break;
                }
            }
        });
    };

    const getSeePass = () => 
    !seePass ? 
        <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>;


    const getConfirmPass = () =>
    !seeConfirmPass ?
        <IoEyeOffOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>;

    return(
        <div style={{marginTop: "5%"}}>
        {stage == 1 &&  (
            <Form style={{width: "90 vh"}} onSubmit={sendCode}>
                <Form.Group controlId="email">
                    <h5>Olvidé mi contraseña</h5>
                    <Form.Control required onChange={(event) => setEmail(event.target.value)} type="text" placeholder="Correo" />
                </Form.Group>

                <Button type="submit">
                    Enviar
                </Button>
            </Form>
        )}

        {stage == 2 && (
            <Form onSubmit={resetPassword}>
                <h5>Restaura tu contraseña</h5>
                <Form.Group controlId="code">
                    <Form.Control required onChange={(event) => setCode(event.target.value)} type="text" placeholder="Código enviado a tu correo" />
                </Form.Group>
                <Form.Group controlId="password">
                    <InputGroup>
                        <Form.Control required onChange={(event) => setPassword(event.target.value)} type={!seePass ? "password" : "text"} placeholder="Escribe tu contraseña" />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {getSeePass()}
                                </InputGroup.Text>
                            </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    <InputGroup>
                        <Form.Control required onChange={(event) => setConfirmPassword(event.target.value)} type={!seeConfirmPass ? "password" : "text"} placeholder="Confirma tu contraseña" />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {getConfirmPass()}
                                </InputGroup.Text>
                            </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <Button  type="submit">
                    Enviar
                </Button>
            </Form>
        )}
        </div>
    );
};
import React, { useState } from 'react';
import {Modal, Button, Form, Table} from 'react-bootstrap';
import moment from 'moment';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';

export default function AddIntern({showAddIntern, setShowAddIntern, setReload, reload, context}){
    const [isCurpError, setIsCurpError] = useState(true);
    const [curpErrorLabel, setCurpErrorLabel] = useState("Campo vacío");
    const [processed, setProcessed] = useState(false);
    const [data, setData] = useState(null)
    const [modalSize, setModalSize] = useState('');
    const [indexSelected, setIndexSelected] = useState(-1);
    const [selected, setSelected] = useState(null);


    const handleClose = () => {
        setShowAddIntern(!showAddIntern);
        setProcessed(false);
        setData(null);
        setIsCurpError(true);
        setCurpErrorLabel("Campo vacío");
        setModalSize('');
        setIndexSelected(-1);
        setSelected(null);
    }

    const handleSelect = (index, intern) => {
        setIndexSelected(index);
        setSelected(intern);
    }

    const handleUpdate = async () => {

        if (selected.internStatus != 'Activo' && selected.internStatus != 'Pendiente') {
            notify('Practicante no está Activo o Pendiente','error');
            return;
        }

        let dataIntern = {
            _id: selected._id,
            attributes: [{updateKey: "logisticStatus", updateValue: "Alta"}, { updateKey: "logisticTimestamp", updateValue: parseInt(moment().format("X"))}]
        }
        if(selected.accountColorStatus) {
            dataIntern.attributes.push({ updateKey: "accountColorStatus", updateValue: "#B2B2B2" });
            dataIntern.attributes.push({ updateKey: "lastModifyAccount", updateValue: context.user.data.email });
            await Methods.updatePrefactureClabeByIntern(selected._id, "#B2B2B2" , context.user.data.email, selected.clabe);
        }
        await Methods.updateIntern(dataIntern);
        handleClose();
        setReload(!reload);
    }

    const getInterns = async (e) => {
        e.preventDefault();
        let data = await Methods.getInternsByCurp(e.target.elements.curp.value);
        if(data.data.total){
            for(let intern of data.data.interns) {
                intern.fullname = intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname;
                let result = await Methods.getCompanies([intern.id_company]);
                intern.company = result[0].business_name;
            }
            setData(data.data.interns);
            setModalSize('lg');
        }
        setProcessed(true);
    };

    const getColor = (index) => {
        if(indexSelected === index) {
            return "rgba(205, 200, 200)";
        }
        return "";
    }

    const curpValidation = async (curp) => {
        const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
        const isValid = curp.match(regex);
        if (curp.length > 0) {
            if (curp.length != 18 && curp.length > 0) {
                setIsCurpError(true);
                setCurpErrorLabel("El CURP debe tener 18 caracteres");
            } else {
                if (isValid) {
                    setIsCurpError(false);
                    setCurpErrorLabel("");
                } else {
                    setIsCurpError(true);
                    setCurpErrorLabel("El formato del CURP no es válido");
                }
            }
        } else {
            setIsCurpError(true);
            setCurpErrorLabel("Campo vacío");
        }
    };

    return(
        <Modal className="logistics-modals" onHide={handleClose} size={modalSize} show={showAddIntern} centered>
            <Modal.Body>
                {!processed &&
                    <Form onSubmit={getInterns} autoComplete={"off"}>
                        <Modal.Header>
                            <p>Agregar Practicante</p>
                        </Modal.Header>
                        <Form.Group controlId="curp">
                            <Form.Control type="text"
                            onChange={(e) => {curpValidation(e.target.value);}}
                            placeholder={"CURP"} required/>
                            {isCurpError && <Form.Label className="error-label" > {curpErrorLabel}</Form.Label>}
                        </Form.Group>

                        <Button className="float-right ml-2" variant="primary" type="submit" disabled={isCurpError}>
                            Consultar
                        </Button> 
                        
                        <Button className="float-right" variant="danger" onClick={handleClose}>
                            Cancelar
                        </Button>             
                    </Form>
                }
                {processed && data &&
                    <>
                        <Modal.Header>
                        <p>Seleccionar Practicante</p>
                        </Modal.Header>

                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    <th>Empresa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data ? data.map((intern, index) => {
                                    return (
                                        <tr key={index} onClick={() => handleSelect(index, intern)} style={{"backgroundColor":getColor(index)}}>
                                            <td>{intern.fullname}</td>
                                            <td>{intern.email}</td>
                                            <td>{intern.company}</td>
                                        </tr>
                                    )
                                }): <tr></tr> } 
                            </tbody>
                        </Table>
                        <Button className="float-right ml-2" variant="primary" onClick={handleUpdate} disabled={!selected}>
                            Agregar
                        </Button> 
                        
                        <Button className="float-right" variant="danger" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </>
                }
                {processed && !data &&
                    <>
                        <Modal.Header>
                        <p>No se encontraron practicantes</p>
                        </Modal.Header>

                        <Button className="float-center" variant="primary" onClick={handleClose}>
                            Aceptar
                        </Button> 
                    </>
                }
            </Modal.Body>
        </Modal>
    );
};
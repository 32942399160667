import { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { Table, Accordion, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsTrash, BsDownload } from "react-icons/bs";
import { getFormatedAmount, updateReportByBank } from '../helpers';
import { notify } from '../../../utils/notify';

const axios = require("axios");

export const BankAccordion = ({
    banks,
    adviser,
    currentData,
    setCurrentData,
    setDataByBanks,
    dataByBanks,
    setShowConfirmationDeleteModal,
    setFilesToDelete
}) => {

    const setBankCheked = (bank) => {
        let adviserAux = {...adviser, bankSelected: bank};
        bank.isChecked = !bank.isChecked;
        adviser.total = 0;

        if(adviser.isChecked && !(bank.isChecked)){
            adviser.isChecked = false;
        }

        for(let bankAux of adviser.banks){
            if(bankAux.isChecked){
                adviser.total += bankAux.total;
            }
        }

        if(!(adviser.banks.find(bank => bank.isChecked))){
            adviser.total = 0;
        }

        if(!(adviser.banks.find(bank => !bank.isChecked))){
            adviser.isChecked = true;
        }

        let data = updateReportByBank(dataByBanks, adviserAux, bank.isChecked ? 'add' : 'remove');

        setDataByBanks(data);
        setCurrentData([...currentData]);
    }

    const filesToDelete = (bankData) => {
        let filesId = [];
        for(let company of bankData.companies){
            filesId.push(company.dispersionFileId);
        }
        setFilesToDelete([...filesId]);
    }

    const downloadTxt = async(bankData) => {
    
        let urlAux = bankData?.companies[0]?.fileUrl;
        let fileName = bankData?.companies[0]?.fileName || 'dispersion';
        let fileExtension = bankData?.companies[0]?.fileExtension || '';

        try{  

            let responseTxt = await axios({
                url: urlAux,
                method: "GET",
            });

            const data = new Blob([responseTxt.data], { type: "text/plain" });
            const url = URL.createObjectURL(data);
            let elementAux = document.createElement("a");
            elementAux.download = `${fileName}.${fileExtension}`;
            elementAux.href = url;
            document.body.appendChild(elementAux);
            elementAux.click();
            document.body.removeChild(elementAux);

        }catch(error){
            notify("Ocurrió algo inesperado al hacer la descarga del archivo", "error");
        }
    }

    const [accordionOpened, setAccordionOpened] = useState([]);
    return ( 
        <Accordion.Collapse eventKey={'0'}>
            <Accordion>
                {banks.map((bankData, index) => {
                    return (
                        <Card>
                            <Card.Header>
                                <div style={{paddingLeft: 100}} className='check-container'>
                                    <input
                                        type="checkbox"
                                        checked={bankData.isChecked}
                                        onChangeCapture={() => setBankCheked(bankData)}                                                           
                                    />
                                    <Accordion.Toggle 
                                        as={Button} 
                                        variant="link" 
                                        eventKey={String(index)}
                                        onClick={() => {
                                            let accordionOpenedAux = accordionOpened
                                            if(accordionOpenedAux.includes(index)){
                                                accordionOpenedAux = accordionOpenedAux.filter(id => id !== index); 
                                            }else{
                                                accordionOpenedAux.push(index);
                                            }
                                            setAccordionOpened([...accordionOpenedAux]);
                                        }}                    
                                        
                                    >
                                        <div>
                                            <div>
                                                {accordionOpened.includes(index) ? 
                                                    <FontAwesomeIcon icon={faMinusSquare} /> : 
                                                    <FontAwesomeIcon icon={faPlusSquare} />
                                                } 
                                                {bankData.bankName}
                                                - {bankData.fileName}
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                </div>
                                <div className='delete-bank'>
                                    <b>Total por banco ${getFormatedAmount(bankData.isChecked ? bankData.total : 0)}</b>

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">Eliminar archivo</Tooltip>}
                                    > 
                                        <BsTrash 
                                            color="red" 
                                            style={{marginBottom: '0px', marginLeft: '10px'}} 
                                            className='trash-icon'
                                            onClick={()=> { 
                                                filesToDelete(bankData);
                                                setShowConfirmationDeleteModal(true);
                                            }}
                                        />
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">Descargar archivo</Tooltip>}
                                    >
                                        <BsDownload 
                                            style={{marginLeft: '20px'}} 
                                            className='trash-icon'
                                            onClick={()=> { 
                                                downloadTxt(bankData)
                                            }}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse defaultActiveKey={String(index)} eventKey={String(index)}>
                                <Card.Body>
                                    <Table>
                                        <tbody>
                                            {bankData.companies?.map(company => {
                                                return(
                                                    <tr>
                                                        <td className='wi-90'> </td>
                                                        <td className='wi-90'> </td>
                                                        <td>{company.companyName}</td>
                                                        <td className='wi-110 center-text'>{company.totalInterns}</td>
                                                        <td className='wi-110 center-text'> {getFormatedAmount(company.totalAmount)}</td>
                                                        <td className='wi-70'>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })}
            </Accordion>
        </Accordion.Collapse>
    )
}





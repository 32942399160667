import React from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function UpdateDepartmentModal ({displayUpdateNotification, setDisplayUpdateNotification, currentNotificationId, updateList, setUpdateList, currentCareer}) {
    const URL = process.env.REACT_APP_SOCKET_URL;

    const updateNotification = async (e) => {
        e.preventDefault();
        
        const name = e.target.elements.title.value;
        const description = e.target.elements.description.value;
        const notification = {
            attributes: [],
            notification_id: currentNotificationId
        };

        name != "" && notification.attributes.push({updateKey: "name", updateValue: name, expressionAttributeName: "#name"});
        description != "" && notification.attributes.push({updateKey: "description", updateValue: description})
        
        if(name == "" && description == ""){
            notify("No hay cambios para realizar", "error");
            return;
        };
        if(name == ""){
            notify("El título no puede estar vacío", "error");
            return;
        }
        if(description == ""){
            notify("La descripción no puede estar vacía", "error");
            return;
        } 
        try {
            await axios({
                url: `${URL}/notifications`,
                method: "PATCH",
                data: notification
            });

            notify("Aviso actualizado exitosamente", "success");
            setUpdateList(!updateList);
            setDisplayUpdateNotification(!displayUpdateNotification);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        };
    };

    return(
        <Modal className="new-cc-modal" show={displayUpdateNotification} onHide={() => setDisplayUpdateNotification(!displayUpdateNotification)} centered>
            <Modal.Header>
                <p>Actualizar Aviso</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={updateNotification}>
                <Form.Group controlId="title">
                        <Form.Label>Título</Form.Label>
                        <Form.Control type="text" required defaultValue={currentCareer.name}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" required defaultValue={currentCareer.description}/>
                    </Form.Group>
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Editar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayUpdateNotification(!displayUpdateNotification)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React, { useState } from 'react';
import { Modal, Image, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';


import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';

const axios = require("axios");

export default function NewCostCenterModal({ displayNewCostCenterModal, setDisplayNewCostCenterModal, currentDepartmentId, updateList, setUpdateList, currentCompanyId }) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(UserContext);

    const newCostCenter = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const cost_center = {
            id_department: currentDepartmentId,
            title: e.target.elements.title.value,
            key: e.target.elements.key.value,
            companyId: currentCompanyId,
            departmentField: context.getDepartmentField(),
        };

        try {
            await axios({
                url: `${URL}/costcenter`,
                method: "POST",
                data: cost_center
            });

            notify(`"${cost_center.title}" registrado exitosamente como Centro de Costos`, "success");
            setUpdateList(!updateList);
            setIsLoading(false);
            setDisplayNewCostCenterModal(!displayNewCostCenterModal);
        } catch (e) {
            setIsLoading(false);
            e.response && notify(e.response.data.message, "error");
        };
    };

    const validateEntryKey = (e) => {
        e.charCode == 13 && e.preventDefault()
    }


    return (
        <Modal className="new-cc-modal" show={displayNewCostCenterModal} onHide={() => setDisplayNewCostCenterModal(!displayNewCostCenterModal)} centered>
            <Modal.Header>
                <p>Nuevo Centro de Costos</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={newCostCenter} autoComplete={"off"}>
                    <Row>
                        <Col>
                            <Form.Group controlId="title">
                                <Form.Label>Nombre del {context.getDepartmentField()} / Área</Form.Label>
                                <Form.Control onKeyPress={validateEntryKey} type="text" required />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group controlId="key">
                                <Form.Label>Clave del Centro de Costo</Form.Label>
                                <Form.Control onKeyPress={validateEntryKey} type="text" required />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Registrar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayNewCostCenterModal(!displayNewCostCenterModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
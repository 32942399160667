import React, { useState } from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import moment from 'moment';

export default function ChangePeriod({showChangePeriodModal, setShowChangePeriodModal, payDate, setPayDate}) {
    const [periodStartDate, periodEndDate] = payDate;
    const [specificMonth, setSpecificMonth] = useState(undefined);
    const [specificYear, setSpecificYear] = useState(undefined);

    const getSubsequentPeriod = (times) => {
        const currentPeriodMonth = new Date(periodEndDate).getMonth() + 1;
        const currentPeriodEndDay = new Date(periodEndDate).getDate();
        const currentYear = new Date(periodEndDate).getFullYear();

        if(times > 0){
            const [_lastPeriodStartDate, _lastPeriodEndDate] = getSubsequentPeriod(times - 1);
            const _lastPeriodEndDay = new Date(_lastPeriodEndDate).getDate();

            if(_lastPeriodEndDay === 15){
                const newPeriodStartDate = new Date(currentYear, currentPeriodMonth, 15);
                const newPeriodEndDate = new Date(currentYear, currentPeriodMonth, 0);
                return [newPeriodStartDate, newPeriodEndDate];
            };

            const newPeriodStartDate = new Date(currentYear, currentPeriodMonth, 0);
            const newPeriodEndDate = new Date(currentYear, currentPeriodMonth, 15);
            return [newPeriodStartDate, newPeriodEndDate];
        };

        if(currentPeriodEndDay === 15){
            const newPeriodEndDate = new Date(currentYear, currentPeriodMonth + times, 0);
            return [periodEndDate, newPeriodEndDate];
        };

        const newPeriodStartDate = new Date(currentYear, currentPeriodMonth + times, 0);
        const newPeriodEndDate = new Date(currentYear, currentPeriodMonth + times, 15);
        
        return [newPeriodStartDate, newPeriodEndDate];
    };

    const getActualPeriod = () => {
        const actualStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const actualEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 15);

        return [actualStartDate, actualEndDate];
    };

    const getActualPeriodString = () => {
        const [actualStartDate, actualEndDate] = getActualPeriod();
        const formattedStartDate = moment(actualStartDate).format("D/MM/YYYY");
        const formattedEndDate = moment(actualEndDate).format("D/MM/YYYY");
        return `${formattedStartDate} al ${formattedEndDate}`;
    };

    const handleChangePeriod = ([startDate, endDate]) => {
        setPayDate([startDate, endDate]);
        setShowChangePeriodModal(!showChangePeriodModal);
    };

    const setSpecificPeriod = (startDay) => {
        if(!specificMonth) return;
        if(!specificYear) return;

        const _year = parseInt(specificYear),
              _day = parseInt(startDay),
              _month = parseInt(specificMonth);

        const _startDate = new Date(_year, _month, _day);
        let _endDate;

        if(_day === 1) _endDate = new Date(_year, _month, 15);
        if(_day === 15) _endDate = new Date(_year, _month + 1, 0);

        handleChangePeriod([_startDate, _endDate]);
    };

    return(
        <Modal className="change-period" show={showChangePeriodModal} centered onHide={() => setShowChangePeriodModal(!showChangePeriodModal)}>
            <Modal.Body>
                <div className="content">
                    <h5>Cambio de periodo</h5>
                    <p>Actual</p>
                    <Button className="default-button" onClick={() => {handleChangePeriod(getActualPeriod())}}>{getActualPeriodString()}</Button>
                    
                    <p>Buscar en periodos anteriores</p>
                    
                    <Form>
                        <Form.Group controlId="year" style={{width: "80px"}}>
                            <Form.Control as="select" onChangeCapture={(el) => setSpecificYear(el.target.value)}>
                                <option selected hidden>Año</option>
                                <option value={2021}>2021</option>
                                <option value={2022}>2022</option>
                                <option value={2023}>2023</option>
                                <option value={2024}>2024</option>
                                <option value={2025}>2025</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="month" style={{width: "120px"}}>
                            <Form.Control as="select" onChangeCapture={(el) => setSpecificMonth(el.target.value)}>
                                <option selected hidden>Mes</option>
                                <option value={0}>Enero</option>
                                <option value={1}>Febrero</option>
                                <option value={2}>Marzo</option>
                                <option value={3}>Abril</option>
                                <option value={4}>Mayo</option>
                                <option value={5}>Junio</option>
                                <option value={6}>Julio</option>
                                <option value={7}>Agosto</option>
                                <option value={8}>Septiembre</option>
                                <option value={9}>Octubre</option>
                                <option value={10}>Noviembre</option>
                                <option value={11}>Diciembre</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="period" style={{width: "170px"}}>
                            <Form.Control as="select" onChangeCapture={(el) => setSpecificPeriod(el.target.value)}>
                                <option selected hidden>Periodo</option>
                                <option value={1}>Primero</option>
                                <option value={15}>Segundo</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
import React, {useState} from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import RegisterUser from './components/RegisterUser.jsx';
import ValidateEmail from './components/ValidateEmail.jsx';
import LogoCaintra from '../../assets/caintra_logo_login.png';
import InternsLogo from '../../assets/logo_interns.png';
import './Register.css';
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { ImWhatsapp } from 'react-icons/im';

export default function Register(){
    const [validateEmailDisplay, setValidateEmailDisplay] = useState(false);
    const [emailToValidate, setEmailToValidate] = useState("");
    const [seePass, setSeePass] = useState(false);

    
    const getSeePass = () => 
        !seePass ? 
            <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>
        : 
            <IoEyeOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>;

    return(
        <div className="login">
            <div className="blurer">
                <Row style={{height: "100%"}}>
                    <Col className="form-container">
                        <div className="form-box">
                            <Row className="row-logo">
                                <Col>
                                <div className="logos-container">
                                            <Image src={InternsLogo} className="InternsLogo"/>
                                            <a  href="#" className="divider"></a>
                                            <Image src={LogoCaintra} className="logoCaintra"/>
                                        </div>
                                </Col>
                            </Row>

                            {!validateEmailDisplay && <RegisterUser setValidateEmailDisplay={setValidateEmailDisplay} setEmailToValidate={setEmailToValidate}/> }

                            { validateEmailDisplay && <ValidateEmail emailToValidate={emailToValidate} /> }
                        </div>
                    </Col>
                    <Col md={7} className="background-container">
                    </Col>
                </Row>
            </div>
        </div>
    );
};
import DatePicker from "react-datepicker";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import './DateSelector.css';

export const DateSelector = ({
    setDateAux,
    label = '', 
    dateAux
}) => {
    
    return (
        <div className="custom-date-picker">
            {label}  
            <Button 
                className="default-button date-btn"
                style={{
                    backgroundImage: "none", 
                    backgroundColor: "#1B3769",
                    marginLeft: "5px"
                }}
            >
            <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
            <DatePicker
                selected={dateAux}
                onChange={
                    async (date) => {
                        setDateAux(date);
                    }
                }
                placeholderText={label}
                dateFormat='dd MMM yyyy'
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
            />  
            </Button> 
        </div>   
    )
}

import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "./Amounts.css";
import AmountUsers from './components/AmountUsers';
import { AmountReport } from './components/AmountReport';

export const Amounts = () => {
    const [currentTab, setCurrentTab] = useState("report");
    const [selectedClass, setSelectedClass] = useState(
        {
        reportSection: 'selected', 
        userSection: 'section'
        }
    );
    const {reportSection, userSection} = selectedClass;
    return (
        <div className="amounts">
            <div className="title">
                <h4>Montos</h4>
            </div>
            <Row className="row-selection">
                <a href="#" 
                    className={reportSection} 
                    onClick={() => { 
                        setSelectedClass({
                            reportSection: 'selected', 
                            userSection: 'section'
                        })
                    }} >
                    <p className={`text-${reportSection}`}>Montos Dispersados</p>
                </a>
                <a href="#" 
                    className={userSection} 
                    onClick={() => { 
                        setSelectedClass({
                            reportSection: 'section', 
                            userSection: 'selected'
                        }) 
                    }}>
                    <p className={`text-${userSection}`}>Correos</p>
                </a>

            </Row>
            
            { reportSection == "selected" &&
                <AmountReport
                
                />
            }
            { userSection == "selected" &&
                <AmountUsers
                
                />
            }
        </div>
    );
};

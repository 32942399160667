import React, { useState, useEffect, useContext} from 'react';
import {Modal, Form, Button} from 'react-bootstrap';
import { UserContext } from '../../../context/UserContext';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';


const uuid = require("uuid");

export default function NewDepartmentModal ({showNewDepartmentModal, setShowNewDepartmentModal, setUpdateInfo, updateInfo, currentCompanyId}) {
    const [showCostCenter, setShowCostCenter] = useState(false);

    const context = useContext(UserContext);

    let departmentField = context.getDepartmentField();

    const newDepartment = async (e) => {
        e.preventDefault();
        const department = {
            title: e.target.elements.department.value,
            id_company: currentCompanyId,
            id_department: uuid.v1(),
            departmentField: departmentField
        };

        let costCenter;

        if(showCostCenter){
            costCenter = {
                title: e.target.elements.title.value,
                companyId: currentCompanyId,
                key: e.target.elements.key.value,
                id_department: department.id_department,
                departmentField,
            };
        }

        try{
            await Methods.createDepartment(department);
            showCostCenter && await Methods.createCostCenter(costCenter);
            setUpdateInfo(!updateInfo);
            setShowNewDepartmentModal(!showNewDepartmentModal);
            setShowCostCenter(false);
        } catch (e) {
            setShowNewDepartmentModal(!showNewDepartmentModal);
        };
    };

    const handleClose = () => {
        setShowNewDepartmentModal(!showNewDepartmentModal);
        setShowCostCenter(false);
    }



    return(
        <Modal className="new-cc-modal" show={showNewDepartmentModal} onHide={() => handleClose()} centered>
            <Modal.Body>
                <Form onSubmit={newDepartment}>

                    <Form.Group>
                        <Form.Label className='modal-header-styled'>Nuevo {context.getDepartmentField()}</Form.Label>
                    </Form.Group>

                    <Form.Group controlId="department">
                        <Form.Control type="text" required  placeholder={`Nombre del ${context.getDepartmentField()}`} className='input-cc'/>
                    </Form.Group>

                    {showCostCenter &&
                        <>  
                            <Form.Label className='modal-header-styled'>Centro de costos</Form.Label>
                            <Form.Group controlId="title">
                                <Form.Control type="text" required  placeholder="Área" className='input-cc'/>
                            </Form.Group>

                            <Form.Group controlId="key">
                                <Form.Control type="text" required  placeholder="Clave del Centro de Costo" className='input-cc'/>
                            </Form.Group>
                        </>
                    }

                    {!context.user.company.isITESMCompany &&
                        <div className='checkClass'>
                            <Form.Check 
                                type="checkbox"
                                onChange={event => setShowCostCenter(event.target.checked)}
                                className='check-box-cc'
                            />
                            Agregar nuevo centro de costos 
                        </div>
                    }   

                    <div className='center-buttons'>
                        <Button  variant="danger" type="button" onClick={() => handleClose()}>
                            Cancelar
                        </Button>

                        <Button  variant="primary" type="submit">
                            Registrar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
import React, {useEffect, useState, useContext}from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Form, Row, Col, Button,  Tooltip, OverlayTrigger, InputGroup, Table} from 'react-bootstrap';
import { faEye, faTimesCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { notify } from '../../../utils/notify';
import {UserContext} from '../../../context/UserContext';
import Methods from '../../../utils/https';
import moment from 'moment';
import LoadingModal from '../../../custom/LoadingModal/LoadingModal.jsx'
import { deleteInsuranceIdAndPolicies } from '../../../utils/insuranceMethods';
const uuid = require("uuid");




const axios = require("axios");

export default function InternMedicalInsurance({currentInternId, currentInternData, setCurrentTab, updatedFromPrefacture, setUpdateInfo, updateInfo, setFormChanged, isLoadingInternData, internContracts, currentInternContract}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);
    const role = context.userRole;

    const [currentInsuranceData, setCurrentInsuranceData] = useState({});
    const [organicLosses, setOrganicLosses] = useState("");
    const [expenseReimbursement, setSxpenseReimbursement] = useState("");
    const [policyCost, setPolicyCost] = useState("");
    const [planSelected, setPlanSelected] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [currentCompany, setCurrentCompany] = useState("");
    const [percent, setPercent] = useState(0);
    const [percentSecond, setPercentSecond] = useState(0);
    const [internPolicies, setInternPolicies] = useState([]);
    const [updateInsuranceInfo, setUpdateInsuranceInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPaymentType, setCurrentPaymentType] = useState('');

    useEffect(() => {
        getData();
    }, [updateInsuranceInfo])

    useEffect(() => {
        setCurrentInsuranceInfo();
    }, [updateInsuranceInfo])

    const setCurrentInsuranceInfo = async () => {
        await getInternInsurance()
    }

    const getData = async () => {
        setIsLoading(true)
        await setInternPolicies([]);
        await Promise.all([getCompanyById(), getAllInternPolicies()])
        setIsLoading(false)
    }

    const newInsurance = () => {
        let months;
        if(currentInternData.contractDuration){
            months = currentInternData.contractDuration
            
        }
        if(months == "0"){
            months = "1";
        }
          
        return {
            _id:  uuid.v1(),
            idIntern: currentInternData._id,
            idCompany: currentInternData.id_company,
            planType: currentInternData.planType || "",
            policyCost: "",
            insuranceCompany: "AIG",
            expenseReimbursement: "",
            insurancePayment: currentInternData.insurancePayment,
            organicLosses: "",
            contractStartDate: currentInternData.contractStartDate,
            contractEndDate: currentInternData.contractEndDate,
            contractDuration: months,
            berneficiary: currentInternData.berneficiary || "",
            berneficiarySecond: currentInternData.berneficiarySecond || "",
            percent: currentInternData.percent || "",
            percentSecond: currentInternData.percentSecond || "",
            relationship: currentInternData.relationship || "",
            relationshipSecond: currentInternData.relationshipSecond || "",
            paymentType: currentInternData.paymentType || "",
            creationDate: new Date() || "",
            renewInsuranceDate: "",
            insuranceId: "_",
        };
    }

    const getInternInsurance = async () => {
        try {
            let response = await axios({
                url: `${URL}/insurance/user`,
                method: "POST",
                data: {intern_id: currentInternId, currentInsuranceId: currentInternData.currentInsuranceId || currentInternData.currentInsuranceDocumentId}
            });
            let insurance = response.data.insurance[0] || newInsurance();
            await verifyPaymentType(insurance);
            setCurrentInsuranceData(insurance);
            setCurrentPaymentType(insurance.paymentType)
            await loadValues(insurance);

        } catch (e){
            e.data && notify(e.data.response.message, "error");
        }
    }

    const verifyPaymentType = async (data) => { 
        if(!data.planType && !data.paymentType){
            data.planType = "Plan 1";
            data.paymentType = "Total";
        }
    }
    
    const getCompanyById = async () => {
        const result =  await Methods.getCompanies([currentInternData.id_company]);   
        setCurrentCompany(result[0]);
    }

    const getDataToSend = async (insurance) => {
        let insuranceDataAux = currentInsuranceData;
        for(let item of internPolicies){
            if((item.insuranceId == insurance || item._id == insurance) && item._id != insuranceDataAux._id) insuranceDataAux = item;
        }
        const streetNumberCompany = currentCompany.street + " " + currentCompany.ext_number;
        const insuredName = currentInternData.firstname + " " + currentInternData.father_lastname + " " + currentInternData.mother_lastname; 
        const streetNumberIntern = currentInternData.street + " " + currentInternData.ext_number;
        const planType = getPlanType(insuranceDataAux.planType);
        const formatedDate = getFormatedDate(insuranceDataAux.contractStartDate.slice(0,10));
        let gender = ""
        if(currentInternData.curp != "" && currentInternData.curp != "_"){
            gender = currentInternData.curp.charAt(11);
        }
        let duration = paymentType == "Mensual" ? "1" : insuranceDataAux.contractDuration;
       
        const data = {
            paymentType: paymentType,
            planType: planType,
            paymentStatus: 1,
            startValidity: formatedDate,
            duration: duration,
            font: 1,
            company: currentCompany.business_name,
            companyRfc: currentCompany.rfc,
            streetNumber: streetNumberCompany, 
            suburb: currentCompany.suburb,
            insuredName: insuredName,
            streetNumberIntern: streetNumberIntern,
            suburbIntern: currentInternData.suburb,
            college: currentInternData.college,
            scholarship: currentInternData.scholarship,
            curp: currentInternData.curp,
            berneficiary: insuranceDataAux.berneficiary,
            relationship: insuranceDataAux.relationship, 
            percent: insuranceDataAux.percent,
            berneficiarySecond: insuranceDataAux.berneficiarySecond || "",
            relationshipSecond: insuranceDataAux.relationshipSecond || "",
            percentSecond: insuranceDataAux.percentSecond.toString().replace(/\s+/g, '') || 0,
            phone: currentInternData.phone,
            career: currentInternData.career, 
            periodType: currentInternData.period_type,
            scolarshipAmount: currentInternData.scolarshipAmount,
            gender: gender,
            insurance: insurance,
            email: currentInternData.email,
            internInsurance: insurance,
            currentInternId: currentInternId,
            currentInsuranceId: insuranceDataAux._id,
            insuranceAgreementId: currentInternData.insuranceAgreementId,
            internRfc: currentInternData.rfc
        }
        return data;
    }

    const sendInsuranceData = async (policy, ignoreInactive) => {
        if(policy.insuranceId && !policy.insuranceId.includes("_") && !ignoreInactive) {
            notify("Este registro ya cuenta con número de póliza", "error");
            return
        }
        let now = moment().startOf("day");
        let contractMoment = policy.contractStartDate.includes("/") ? moment(policy.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(policy.contractStartDate.slice(0,10)).startOf("day");

        if(contractMoment.diff(now, 'days') >= 30){
            notify("Este registro aún no entra en fecha de activación", "error");
            return
        }
        const sendData = await getDataToSend(policy._id);
        if(await verifyDataToSend(sendData)){
            return;
        }

        if(sendData.internInsurance != "Baja" || sendData.internInsurance != "" || ignoreInactive){
            try{
                await axios ({
                    method: "POST",
                    url: `${URL}/insurance/send`,
                    data: sendData
                });
                
                notify("Documentación generada con éxito", "success");
                setUpdateInsuranceInfo(!updateInsuranceInfo);
                setUpdateInfo(!updateInfo);
            }catch(err){
                err.data && notify(err.data.response.message, "error");
            }
        } else {
            notify("Ya cuentas con una póliza activa y no tiene cambios", "error");
        }
    }

    const downloadInsurance =  async (insurance) => { 
        let api = (await Methods.getApiData("segutrends"))[0].apiLink;
        const sendData = await getDataToSend(insurance);
        if(sendData.internInsurance == "Baja" || sendData.internInsurance == "" ){
            notify("No cuentas con una póliza activa", "error");
        } else {
            window.open(`${api}/caintraPolizas/impresionPolizaAccidente.asp?tipoSalida=2&Poliza=${sendData.insurance}`);
        }
    }


    const deleteInsurance = async (insurance) => { 
        const sendData = await getDataToSend(insurance);
        if(sendData.internInsurance == "Baja" || sendData.internInsurance == "" ){
            notify("No cuentas con una póliza activa", "error");
            return;
        }
        try {
            const response = await axios ({
                method: "POST",
                url: `${URL}/insurance/delete`,
                data: sendData,
            });
            notify(response.data.message, "success");
        } catch (e){
            e.response && notify(e.response.data.message, "error");
        }
        setUpdateInsuranceInfo(!updateInsuranceInfo);
        setUpdateInfo(!updateInfo);
    }
    const loadValues =  async (data) => {
        const months = parseInt(data.contractDuration);
        setOrganicLosses(data.organicLossesRenovation || data.organicLosses || "$400,000.00");
        setSxpenseReimbursement(data.expenseReimbursementRenovation || data.expenseReimbursement || "$200,000.00");
        setPolicyCost(data.policyCostRenovation || data.policyCost || await insurancePlan1(months, "Total"));
        setPlanSelected(data.planTypeRenovation || data.planType || "Plan 1");
        setPaymentType(data.paymentTypeRenovation || data.paymentType || "Total");
        setPercent(data.percentRenovation || data.percent || 0);
        setPercentSecond(data.percentSecondRenovation || data.percentSecond || 0);
    }

    const getPlanType = (value) => {
        const type = {
            "Plan 1": 1,
            "Plan 2": 2
        }
        return type[value] || "";
    }

    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        if(regexDate.test(dateString)){
            result = dateString;
        }else{ 
            const newDateArray = dateString.split("/");
            const day = addZeroToDate(newDateArray[0]) 
            const month = addZeroToDate(newDateArray[1]) 
            result = newDateArray[2] + "-" + month + "-" + day;
        }
        return result;
    }

    const addZeroToDate =  (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };

    const verifyDataToSend = (data) => {
        let internKeys = ["duration", "insuredName", "streetNumberIntern", "suburbIntern", "college", "scholarship",
        "curp", "phone", "career", "scolarshipAmount", "email", "currentInternId", "internRfc"];
        let internErrors = 0;

        let insuranceKeys = ["paymentStatus", "planType", "startValidity", "font", "berneficiary", "relationship",
        "percent", "secondBerneficiary", "secondRelationship", "secondPercent", "periodType", ""];
        let insuranceErrors = 0;

        let companyKeys = ["company", "companyRfc", "streetNumber", "suburb"];
        let companyErrors = 0;


        for (let key in data){
            if(internKeys.includes(key) && (!data[key] || data[key] == '_')) internErrors++
            if(insuranceKeys.includes(key) && (!data[key])) insuranceErrors++
            if(companyKeys.includes(key) && (!data[key])) companyErrors++
        };


        if(!data.secondBerneficiary && (data.secondPercent == 0 || !data.secondPercent)){
            insuranceErrors = insuranceErrors - 3;
        }


        internErrors > 0 && notify("Todavía no llenas la información necesaria en el practicante para continuar", "error");
        insuranceErrors > 0 && notify("Todavía no llenas la información necesaria en el seguro del practicante para continuar", "error");
        companyErrors > 0 && notify("Todavía no llenas la información necesaria en la compañía para continuar", "error");

        if((internErrors + insuranceErrors + companyErrors) > 0){
            return true;
        }
        return false;
    }
    const onPlanChange = (e) => {
        e.preventDefault(); 
        setPaymentType("Total");
        
        currentInsuranceData.paymentType = "Total";
        const optionIndex = e.target.selectedIndex;
        const plan = e.target.children[optionIndex].value;
        let totalPolicy = "";
        const months = parseInt(currentInsuranceData.contractDuration);
        setPlanSelected(plan);
        if(plan == "Plan 1"){
            currentInsuranceData.organicLosses = "$400,000";
            setOrganicLosses("$400,000.00");
            setSxpenseReimbursement("$200,000.00");
            totalPolicy = insurancePlan1(months, paymentType);
            setPolicyCost(totalPolicy)
        }else if (plan == "Plan 2"){
            currentInsuranceData.organicLosses = "$200,000";
            setOrganicLosses("$200,000.00");
            setSxpenseReimbursement("$100,000.00");
            totalPolicy = insurancePlan2(months, paymentType);
            setPolicyCost(totalPolicy)
        }
    }


    const onPaymentTypeChange = (e)=> {
        e.preventDefault(); 
        const optionIndex = e.target.selectedIndex;
        const paymentTypeSelected = e.target.children[optionIndex].value;
        setPaymentType(paymentTypeSelected);
        let total = "";
        if(paymentTypeSelected == "Mensual"){
            currentInsuranceData.contractDuration = "1";
            currentInsuranceData.contractEndDate = moment(currentInsuranceData.contractStartDate, "DD/MM/YYYY").add(1, "month").subtract(1, "day").format("DD/MM/YYYY");

        } else {
            currentInsuranceData.contractDuration = currentInternData.contractDuration;
            currentInsuranceData.contractEndDate = currentInternData.contractEndDate;
        }
        const months = parseInt(currentInsuranceData.contractDuration);
        if(planSelected == "" && planSelected != undefined){
            notify("Para calcular el costo de póliza debes seleccionar un plan", "error");
            return;
        }
        if(planSelected == "Plan 1"){
            total = insurancePlan1(months, paymentTypeSelected);
            setPolicyCost(total)
        }else{
            total = insurancePlan2(months, paymentTypeSelected);
            setPolicyCost(total)
        }
    }

    const insurancePlan1 = (months, paymentType) => {
        const values = {
            0: "$150.86",
            1: "$150.86",
            2: "$241.38",
            3: "$349.14",
            4: "$439.66",
            5: "$525.86",
            6: "$594.83",
        }
        if(paymentType == "Mensual") return "$150.86";
        return values[months] || 0.00;
    }


    const insurancePlan2 = (months, paymentType ) => {
        const values = {
            0: "$68.97",
            1: "$68.97",
            2: "$133.62",
            3: "$189.66",
            4: "$250.00",
            5: "$310.34",
            6: "$370.69",
        }
        if(paymentType == "Mensual") return "$68.97";
        return values[months] || 0.00;
    }

    const roundPercentage = (valueToBeUpdated, percentCase) => {
        if(valueToBeUpdated == 100 || valueToBeUpdated == "100"){
            if(percentCase == "percent"){
                setPercent(valueToBeUpdated);
            } else {
                setPercentSecond(valueToBeUpdated)
            }
        }
        let newValue = parseInt(valueToBeUpdated);
        let unitAux = newValue % 10;

        if(unitAux >= 5 || newValue < 10){
            newValue = Math.ceil((newValue+1) / 10) * 10;
        } else {
            newValue = Math.floor((newValue+1) / 10) * 10;
        }
        if(percentCase == "percent"){
            setPercent(newValue);
            if(percentSecond > 0){
                setPercentSecond(100-newValue)
            }
        } else {
            setPercentSecond(newValue)
            if(percent > 0){
                setPercent(100-newValue)
            }
        }
    };

    const setSecondBeneficiary = (name) => {
        if(name == "" || name == undefined){
            setPercentSecond(0);
        } else {
            setPercentSecond(100-percent)
        }
    }


    const updateInternInsurance = async (e) => {
        let promises = [];
        e.preventDefault();
        setFormChanged(false);
        if(!context.can("update", "Interns")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        if(parseFloat(percent) + parseFloat(percentSecond) != 100){
            notify("La suma de los porcentajes debe ser igual a 100%", "error");
            return;
        }
        let data = {
            _id: currentInsuranceData._id,
            attributes: []
        };
        const availableKeys = ["_id", "idIntern", "idCompany", "planType", "policyCost", "insuranceCompany", "expenseReimbursement", "insurancePayment", "organicLosses", "contractDuration", "berneficiary", "berneficiarySecond", "percent", "percentSecond", "relationship", "relationshipSecond", "paymentType"];
        for(let index = 0; index < e.target.elements.length; ++index){
            const key = e.target.elements[index].id;
            const value = e.target.elements[index].value;
            if(availableKeys.includes(key) && value != ""){

                if(key === "state" || key === "group" || key == "percent"){
                    data.attributes.push({
                        updateKey: key, updateValue: value, expressionAttributeName: `#${key}`
                    });
                } else {
                    data.attributes.push({
                        updateKey: key, updateValue: value
                    });
                };
            };
        };
        
        let dataIntern = {
            _id: currentInternId,
            attributes: []
        }

        const payment = e.target.elements.insurancePayment.value || currentInsuranceData.insurancePayment;
        let valuePayment = e.target.elements.policyCost.value || currentInsuranceData.policyCost;
        valuePayment = parseInt(valuePayment.substring(1,10));
        if(payment == "Empresa"){
            dataIntern.attributes.push({updateKey: "company_insurance_amount", updateValue: valuePayment});
            dataIntern.attributes.push({updateKey: "intern_insurance_amount", updateValue: 0});
        }else if(payment == "Practicante"){
            dataIntern.attributes.push({updateKey: "intern_insurance_amount", updateValue: valuePayment});
            dataIntern.attributes.push({updateKey: "company_insurance_amount", updateValue: 0});
        }

        if(paymentType == "Mensual"){
            let momentAux = moment(currentInsuranceData.contractStartDate, "DD/MM/YYYY").add(1, "month").subtract(1, "day");
            data.attributes.push({
                updateKey: "contractDuration", updateValue: "1"
            });
            data.attributes.push({
                updateKey: "contractEndDate", updateValue: momentAux.format("DD/MM/YYYY")
            });
        }
        
        if(data.attributes.length == 0){
            notify("No hay cambios por realizar", "error");
            return;
        }


        let {updateInsurance, insurancesToDelete} = await setNewDatesAndDeleteByPaymentType(data.attributes, promises);
        data.attributes.push({updateKey: "idIntern", updateValue: currentInsuranceData.idIntern});
        try{
            data.attributes = getMensualAttributes(data.attributes);
            promises.push(Methods.updateInsurance(data));
            updateInsurance && await deleteAndResendInsurance(data);
            let insurancesToUpdate = getInsurancesToUpdate();

            if(insurancesToUpdate.length){
                for(let insuranceToUpdate of insurancesToUpdate){
                    let result = insurancesToDelete.find(id => id == insuranceToUpdate._id)
                    if(!result){
                        let pendingInsurance = {...data}
                        pendingInsurance._id = insuranceToUpdate._id;
                        pendingInsurance.attributes = setAttributesToUpdateInProcess(insuranceToUpdate, data.attributes);
                        promises.push(Methods.updateInsurance(pendingInsurance));
                    }
                }
            }

            await Promise.all(promises);
            await Methods.updateIntern(dataIntern);

            notify("Los datos se actualizaron correctamente", "success");
            setUpdateInsuranceInfo(!updateInsuranceInfo);
            setUpdateInfo(!updateInfo);
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
        };
    }

    const setAttributesToUpdateInProcess = (insurance, attributesToUpdate) => {
        let attributesAux = [];
        const authorizedKeys = {
            'insurancePaymentRenovation': 'insurancePayment',
            'planTypeRenovation': 'planType',
            'paymentTypeRenovation': 'paymentType',
            'berneficiaryRenovation': 'berneficiary',
            'percentRenovation': 'percent',
            'berneficiarySecondRenovation': 'berneficiarySecond',
            'percentSecondRenovation': 'percentSecond',
            'relationshipRenovation': 'relationship' ,
            'relationshipSecondRenovation': 'relationshipSecond'
        }
        let names = {
            'percent': '#percent'
        }

        let contractStatus = (internContracts.find( contract => contract._id === insurance.contractId))?.contractStatus;
        if(contractStatus && contractStatus == 'En proceso'){
            for(let attribute of attributesToUpdate){
                if(authorizedKeys[attribute.updateKey]){
                    let values = {
                        updateKey: authorizedKeys[attribute.updateKey],
                        updateValue: attribute.updateValue
                    }
                    if(names[authorizedKeys[attribute.updateKey]]) values.expressionAttributeName = names[authorizedKeys[attribute.updateKey]];
                    attributesAux.push(values);
                }
            }
            return attributesAux;
        }else{
            return attributesToUpdate
        }
    }

    


    const setNewDatesAndDeleteByPaymentType = async (dataArray, promises) => {
        let updateInsurance = true;
        let insurancesToDelete = [];
        if(paymentType != currentPaymentType){
            if(paymentType == 'Total' && currentInternContract.internContractDuration) {
                
                let canUpdateInactiveInsurance = moment().startOf('day').diff(moment(currentInternContract.internStartDate, 'DD/MM/YYYY'), 'days') < 7;

                if(currentInternContract.contractStatus == 'En proceso'){
                    dataArray.push({
                        updateKey: "contractDuration", updateValue: currentInternContract.internContractDuration
                    });
                    dataArray.push({
                        updateKey: "contractEndDate", updateValue: currentInternContract.internEndDate
                    });
                }
    
                if(currentInternContract.contractStatus == 'Activo' || currentInternContract.contractStatus == 'Pendiente' || (currentInternContract.contractStatus == 'Inactivo' && canUpdateInactiveInsurance)){
                    let isInvalidation = true;
                    let policiesAux = [];
                    for(let policy of internPolicies){

                        if(policy.contractId == currentInternContract._id){
                            policiesAux.push(policy);
                        }
                    }
        
                    policiesAux = sortPolicies(policiesAux);

                    if(moment().startOf('day').diff(getMomentDate(policiesAux[0].contractStartDate), 'days') < 7 ){
                        policiesAux.pop();
                        for(let policies of policiesAux){
                            insurancesToDelete.push(policies._id)
                            promises.push(deleteInsuranceIdAndPolicies(policies, currentInternData, currentCompany, isInvalidation));
                        }
                    }else if(currentInternContract.internContractDuration){
                        let auxCost =  planSelected == "Plan 1" ? insurancePlan1(currentInternContract.internContractDuration, "Total") : insurancePlan2(currentInternContract.internContractDuration, "Total");
                        dataArray.push({
                            updateKey: "policyCostAux", updateValue: auxCost
                        });
                    }

                    dataArray.push({
                        updateKey: "contractDuration", updateValue: currentInternContract.internContractDuration
                    });
    
                    dataArray.push({
                        updateKey: "contractEndDate", updateValue: currentInternContract.internEndDate
                    });
                    return {updateInsurance, insurancesToDelete}
                }

                if(currentInternContract.contractStatus == 'Inactivo' && !canUpdateInactiveInsurance){
                    updateInsurance = false;
                    return {updateInsurance, insurancesToDelete}
                }
            }

            if(paymentType == 'Mensual' && currentInternContract.internContractDuration && (currentInternContract.contractStatus == 'Activo' || currentInternContract.contractStatus == 'Pendiente')){
                if(moment().startOf('day').diff(getMomentDate(currentInsuranceData.contractStartDate), 'days') < 7 ){
                    let isInvalidation = false;
                    await deleteInsuranceIdAndPolicies(currentInsuranceData, currentInternData, currentCompany, isInvalidation);
                    
                    let insuranceData = {
                        policyId: currentInsuranceData._id,
                        totalDuration: currentInternContract.internContractDuration,
                        contractStartDate: currentInternContract.internStartDate,
                        contractEndDate: currentInternContract.internEndDate,
                        contractId: currentInternContract._id,
                        isPending: false,
                        renewContractUpdates: dataArray
                    }
                    promises.push(Methods.setPolicies(insuranceData));
                }
                updateInsurance = false;
                return {updateInsurance, insurancesToDelete}
            }
        }  
        return {updateInsurance, insurancesToDelete};
    }

    const getMomentDate = (date) => {
        return date.includes("/") ? moment(date,"DD/MM/YYYY").startOf("day"): moment(date.slice(0,10)).startOf("day");
    }
    
    const getMensualAttributes = (attributes) => {
        let result = [];
        let currentInsuranceStart = currentInsuranceData.contractStartDate.includes("/") ? moment(currentInsuranceData.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(currentInsuranceData.contractStartDate.slice(0,10)).startOf("day");
        let difference = moment().startOf('day').diff(currentInsuranceStart, 'days');
        
        let auxData = {
            'planType': 'planTypeRenovation',
            'policyCost': 'policyCostRenovation',
            'insurancePayment': 'insurancePaymentRenovation',
            'organicLosses': 'organicLossesRenovation',
            'berneficiary': 'berneficiaryRenovation',
            'berneficiarySecond': 'berneficiarySecondRenovation',
            'percent': 'percentRenovation',
            'percentSecond': 'percentSecondRenovation',
            'paymentType': 'paymentTypeRenovation',
            'expenseReimbursement': 'expenseReimbursementRenovation',
            'relationship': 'relationshipRenovation',
            'relationshipSecond': 'relationshipSecondRenovation'
        }

        for(let attribute of attributes){
            if(difference < 7){
                result.push(attribute)
            }else{
                if(attribute.updateKey != 'contractDuration' && attribute.updateKey != 'contractEndDate' && attribute.updateValue){
                    if(auxData[attribute.updateKey]){
                        let newAttribute = {
                            updateKey: auxData[attribute.updateKey],
                            updateValue:  attribute.updateValue
                        }
                        result.push(newAttribute)
                    }
                }
            }
        }
        return result;
    }

    const sortPolicies = (policies) => {
        let aux = [];
        for(let policyAux of policies){
            if(policyAux.contractStartDate){
               aux.push(policyAux);
            }
        }
        aux.sort(function(a,b){
            let momentA = a.contractStartDate.includes("/") ? moment(a.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(a.contractStartDate.slice(0,10)).startOf("day");
            let momentB = b.contractStartDate.includes("/") ? moment(b.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(b.contractStartDate.slice(0,10)).startOf("day");

            return momentA.isAfter(momentB) ? -1 : 1;
        });
        return aux;
    }

    const getAllInternPolicies = async () => {
        try{
            if(currentInsuranceData.insurance != "Baja")
            {
                let policies = await Methods.getPolicyById(currentInternId);
                let arrPolicies = sortPolicies(policies.policy)
                if(policies) setInternPolicies(arrPolicies);
            }
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
        }
    };

    const getInsurancesToUpdate = () => { 
        let insurancesToUpdateByDate = [];
        let currentInsuranceStart = currentInsuranceData.contractStartDate.includes("/") ? moment(currentInsuranceData.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(currentInsuranceData.contractStartDate.slice(0,10)).startOf("day");
        
        for(let policy of internPolicies){
            let contractStartDate = policy.contractStartDate.includes("/") ? moment(policy.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(policy.contractStartDate.slice(0,10)).startOf("day");
            if(moment(currentInsuranceStart).isBefore(contractStartDate)){
                insurancesToUpdateByDate.push(policy);
            }
        }

        return insurancesToUpdateByDate 
    } 

    const deleteAndResendInsurance = async (data) => { 
        let isInvalidation = false;
        let ignoreInactive = true;
        const currentDate = moment(); 
        const insuranceStartDate = currentInsuranceData.contractStartDate.includes("/") ? moment(currentInsuranceData.contractStartDate,"DD/MM/YYYY").startOf("day"): moment(currentInsuranceData.contractStartDate.slice(0,10)).startOf("day"); 
        let difference = currentDate.diff(insuranceStartDate, 'days');
        if(currentInsuranceData.insuranceId && !currentInsuranceData.insuranceId.includes("_") &&  difference < 7){
            await deleteInsuranceIdAndPolicies(currentInsuranceData, currentInternData, currentCompany, isInvalidation);
            setData(data.attributes, currentInsuranceData);
            await sendInsuranceData(currentInsuranceData, ignoreInactive);
        }
    } 

    const setData = (data, insuranceDataAux) => {
        for(let newData of data){
            insuranceDataAux[newData.updateKey] = newData.updateValue;
        }
    }



    const renderInternPolicies= () => {

        return(
            <div className="table-container">
                <Table hover>
                    <thead>
                        <tr>
                            <th>Número de póliza</th>
                            <th>Fecha Inicio</th>
                            <th>Fecha Fin</th>
                            <th>Tipo de plan</th>
                        </tr>
                    </thead>
                    
                    <tbody> 
                    {   internPolicies && internPolicies.length ? internPolicies.map((policy, index) => {
                            if(policy){
                            if ((role !== "Administrador Caintra" && role !== "Supervisor") && ("fabiola.luevano@autozone.com".localeCompare(context.user.data.email) !==0 && "magdalena.giner@autozone.com".localeCompare(context.user.data.email) !== 0 && "violeta.venegas@autozone.com".localeCompare(context.user.data.email)!==0 && "norma.gonzalez@autozone.com".localeCompare(context.user.data.email)!==0)  ) {
                                return ( 
                                    <tr key={index}>
                                        <td className='center-text' >{policy.insuranceId || "Sin póliza"}</td>
                                        <td className='center-text' >{policy.contractStartDate.slice(0,10) || "Sin fecha"}</td>
                                        <td className='center-text' >{policy.contractEndDate.slice(0,10) || "Sin fecha"}</td> 
                                        <td className='center-text' >{policy.planType || "Sin tipo"}</td>
                                        <td className='center-text' >
                                            
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Descargar Póliza</Tooltip>}>
                                            <FontAwesomeIcon className='on-click-event' onClick={async () => downloadInsurance(policy.insuranceId)} icon={faEye} style={{fontSize: "20px"}}/>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )
                               
                              
                              }
                            else if("fabiola.luevano@autozone.com".localeCompare(context.user.data.email) ===0 || "magdalena.giner@autozone.com".localeCompare(context.user.data.email) === 0 || "violeta.venegas@autozone.com".localeCompare(context.user.data.email)===0 || "norma.gonzalez@autozone.com".localeCompare(context.user.data.email)===0 ){
                                return ( 
                                    <tr key={index}>
                                        <td className='center-text' >{policy.insuranceId || "Sin póliza"}</td>
                                        <td className='center-text' >{policy.contractStartDate.slice(0,10) || "Sin fecha"}</td>
                                        <td className='center-text' >{policy.contractEndDate.slice(0,10) || "Sin fecha"}</td> 
                                        <td className='center-text' >{policy.planType || "Sin tipo"}</td>
                                        <td className='center-text' >
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Generar Póliza</Tooltip>}>
                                            <FontAwesomeIcon className='on-click-event' onClick={async () => {sendInsuranceData(policy)}}icon={faCheckCircle} style={{fontSize: "20px", color: "#06D1CD"}}/>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Descargar Póliza</Tooltip>}>
                                            <FontAwesomeIcon className='on-click-event' onClick={async () => downloadInsurance(policy.insuranceId)} icon={faEye} style={{fontSize: "20px"}}/>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )

                            }
                            else{
                                return ( 
                                    <tr key={index}>
                                        <td className='center-text' >{policy.insuranceId || "Sin póliza"}</td>
                                        <td className='center-text' >{policy.contractStartDate.slice(0,10) || "Sin fecha"}</td>
                                        <td className='center-text' >{policy.contractEndDate.slice(0,10) || "Sin fecha"}</td> 
                                        <td className='center-text' >{policy.planType || "Sin tipo"}</td>
                                        <td className='center-text' >
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Generar Póliza</Tooltip>}>
                                            <FontAwesomeIcon className='on-click-event' onClick={async () => {sendInsuranceData(policy)}}icon={faCheckCircle} style={{fontSize: "20px", color: "#06D1CD"}}/>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Descargar Póliza</Tooltip>}>
                                            <FontAwesomeIcon className='on-click-event' onClick={async () => downloadInsurance(policy.insuranceId)} icon={faEye} style={{fontSize: "20px"}}/>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )
                              }
                            }
                        }) : <tr></tr> 
                    }
                    </tbody>
                </Table>
            </div>
        );
    };

    return(
        <motion.div
        initial={{opacity: 0}}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}>
            <Row>
                <Form style={{width: "100%", margin: "10px", height: "100%"}} onSubmit={updateInternInsurance} className="scroll">
                    <Row>
                        <h6 style={{color: "black", fontSize: "18px", marginLeft: "14px"}}>Información requerida</h6>
                    </Row>

                    <Row>
                        <Col md={4} xs={12} sm={12}>

                            <Form.Group controlId="contractStartDate">
                                <Form.Label>Fecha inicio</Form.Label>
                                <Form.Control type="text" readOnly={true} defaultValue={currentInsuranceData.contractStartDate} onChange={e => setFormChanged(true)}/>
                            </Form.Group>

                            <Form.Group controlId="insurancePayment">
                                <Form.Label>Cobrar Póliza a</Form.Label>
                                <Form.Control as="select" onChange={e => setFormChanged(true)}>
                                    <option disabled selected hidden>{currentInsuranceData.insurancePaymentRenovation || currentInsuranceData.insurancePayment || ""}</option>
                                    <option>Empresa</option>
                                    <option>Practicante</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="organicLosses">
                                <Form.Label>Pérdidas orgánicas</Form.Label>
                                <div key={organicLosses}>
                                    <Form.Control type="text" readOnly={true}  defaultValue={organicLosses} onChange={e => setFormChanged(true)}/>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="policyCost">
                                <Form.Label>Costo Póliza</Form.Label>
                                <Form.Control type="text" readOnly={true} defaultValue={policyCost} onChange={e => setFormChanged(true)}/>
                            </Form.Group>
                        
                        </Col>

                        <Col md={4} xs={12} sm={12}>

                            <Form.Group controlId="contractEndDate">
                                <Form.Label>Fecha término</Form.Label>
                                <Form.Control type="text" readOnly={true} defaultValue={currentInsuranceData.contractEndDate} onChange={e => setFormChanged(true)}/>
                            </Form.Group>


                            <Form.Group controlId="insuranceCompany">
                                <Form.Label>Aseguradora</Form.Label>
                                <Form.Control type="text" readOnly={true} defaultValue={currentInsuranceData.insuranceCompany} onChange={e => setFormChanged(true)}/>
                            </Form.Group>

                            <Form.Group controlId="expenseReimbursement">
                                <Form.Label>Reembolso gastos</Form.Label>
                                <Form.Control type="text" readOnly={true} defaultValue={expenseReimbursement} onChange={e => setFormChanged(true)}/>
                            </Form.Group>

                        </Col>
                        <Col md={4} xs={12} sm={12}>

                            <Form.Group controlId="contractDuration">
                                <Form.Label>Meses</Form.Label>
                                <Form.Control type="text" readOnly={true} defaultValue={currentInsuranceData.contractDuration} onChange={e => setFormChanged(true)}/>
                            </Form.Group>

                            <Form.Group controlId="planType">
                                <Form.Label>Plan</Form.Label>
                                <Form.Control as="select" onChange = {(e)=> {onPlanChange(e); setFormChanged(true);}}>
                                    <option disabled selected hidden>{currentInsuranceData.planTypeRenovation || currentInsuranceData.planType || ""}</option>
                                    <option value="Plan 1">Plan 1</option>
                                    <option value="Plan 2">Plan 2</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="paymentType">
                                <Form.Label>Tipo de cobro</Form.Label>
                                <Form.Control as="select" onChange = {(e)=> {onPaymentTypeChange(e); setFormChanged(true);}}>
                                    <option disabled selected hidden>{currentInsuranceData.paymentTypeRenovation || currentInsuranceData.paymentType || ""}</option>
                                    <option>Total</option>
                                    <option>Mensual</option>
                                </Form.Control>
                            </Form.Group>

                        </Col>
                    </Row>
                                
                    <Row>
                        <Col md={4} xs={12} sm={12}>
                            <Form.Group controlId="berneficiary">
                                <Form.Label>Beneficiario 1</Form.Label>
                                <Form.Control type="text" defaultValue={currentInsuranceData.berneficiaryRenovation || currentInsuranceData.berneficiary} onChange={e => setFormChanged(true)}/>
                            </Form.Group>

                            <Form.Group controlId="berneficiarySecond">
                                <Form.Label>Beneficiario 2</Form.Label>
                                <Form.Control onChange={(e) => {setSecondBeneficiary(e.target.value); setFormChanged(true);}} type="text" defaultValue={currentInsuranceData.berneficiarySecondRenovation || currentInsuranceData.berneficiarySecond}/>
                            </Form.Group>
                        </Col>
                        <Col md={4} xs={12} sm={12}>
                            <Form.Group controlId="percent">
                                <Form.Label>Porcentaje 1</Form.Label>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">El porcentaje debe ser múltiplo de 10</Tooltip>}
                                    >
                                        <div key={percent}>
                                            <InputGroup className="mb-2">
                                                <Form.Control type="number" defaultValue={percent} 
                                                    onChange={(e) => {setTimeout(() => {roundPercentage(e.target.value, "percent")},2000); setFormChanged(true);}} 
                                                />
                                                <InputGroup.Text>%</InputGroup.Text>   
                                            </InputGroup>
                                        </div>
                                    </OverlayTrigger>
                            </Form.Group>

                            <Form.Group controlId="percentSecond">
                                <Form.Label>Porcentaje 2</Form.Label>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">El porcentaje debe ser múltiplo de 10</Tooltip>}
                                    >
                                        <div key={percentSecond}>
                                            <InputGroup className="mb-2">
                                                <Form.Control type="number" defaultValue={percentSecond}
                                                    onChange={(e) => {setTimeout(() => {roundPercentage(e.target.value,"percentSecond")}, 2000); setFormChanged(true);}}   
                                                />
                                                <InputGroup.Text>%</InputGroup.Text>   
                                            </InputGroup>
                                        </div>
                                    </OverlayTrigger>    
                            </Form.Group>
                        </Col>
                        <Col md={4} xs={12} sm={12}>   
                            <Form.Group controlId="relationship">
                                <Form.Label>Parentesco 1</Form.Label>
                                <Form.Control as="select" onChange={e => setFormChanged(true)}>
                                    <option disabled selected hidden>{currentInsuranceData.relationshipRenovation || currentInsuranceData.relationship || ""}</option>
                                    <option>Padre</option>
                                    <option>Madre</option>
                                    <option>Tutor</option>
                                    <option>Hermano</option>
                                    <option>Amistad</option>
                                    <option>Otro</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="relationshipSecond">
                                <Form.Label>Parentesco 2</Form.Label>
                                <Form.Control as="select" onChange={e => setFormChanged(true)}>
                                    <option disabled selected hidden>{currentInsuranceData.relationshipSecondRenovation || currentInsuranceData.relationshipSecond || ""}</option>
                                    <option>Padre</option>
                                    <option>Madre</option>
                                    <option>Tutor</option>
                                    <option>Hermano</option>
                                    <option>Amistad</option>
                                    <option>Otro</option>
                                </Form.Control>
                            </Form.Group> 
                        </Col>

                    </Row>

                    <Form.Label>
                        Histórico de seguros
                    </Form.Label>
                    {renderInternPolicies()}

                    <Row>
                        <Col className="mt-4">

                            <Button className="float-left ml-2" hidden variant="primary" style={{background: "red"}} onClick={()=>{deleteInsurance(currentInsuranceData)}}>
                                Eliminar Póliza
                            </Button>

                            <Button className="float-right ml-2" variant="primary" type="submit">
                                Guardar Cambios
                            </Button>

                        </Col>
                    </Row>
                </Form>
            </Row>

            {isLoading || isLoadingInternData && <LoadingModal isLoading={isLoading || isLoadingInternData}/>}
        </motion.div>
    );
};

import React, {useEffect, useState, useContext} from 'react';
import {UserContext} from '../../context/UserContext';
import {useParams} from 'react-router-dom';
import {Row, Col, Image, Form, Button, Spinner, Tooltip, OverlayTrigger, Container} from 'react-bootstrap';
import InternsLogo from '../../assets/logo.svg';
import PrivacyImage from '../../assets/privacy_girl.png'
import LogoImage from '../../assets/logo_interns.png'
import LogoCaintra from '../../assets/caintra_logo_login.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faInstagram, faFacebook, faTwitter} from '@fortawesome/free-brands-svg-icons';
import iconFacebook from '../../assets/fb-icon.PNG';
import iconInstagram from '../../assets/insta-icon.png';
import iconTwitter from '../../assets/twitter-icon.png';
import {notify} from '../../utils/notify';
import './Privacy.css';
import {useHistory} from 'react-router-dom';
import Methods from '../../utils/https';

export default function Privacy(){

    const context = useContext(UserContext);
    const currentCompany = context.user ? context.user.company.business_name : "";

return (
    <div className="privacy">
        <Row style={{height: "100%"}} >
                <Col className="background-container">
                    <Row className="row-logo">
                        <Col>
                            
                        </Col>
                    </Row>
                    <Row className="d-flex flex-column">
                        <h4><strong>Privacidad</strong></h4>
                        <h4 STYLE="color: #1B3769">{currentCompany}</h4>
                    </Row>
                    <div className='container-img'>
                        <img className='privacy-img' src={PrivacyImage} alt="Privacidad" />
                            <Container>
                                <div>
                                <small>Usted es lo más importante para la CÁMARA DE LA INDUSTRIA DE TRANSFORMACIÓN DE NUEVO LEÓN (en adelante “CAINTRA”) y, 
                                    en congruencia con nuestros valores la protección de sus datos personales, su privacidad y confianza, 
                                    son nuestra prioridad. Para asegurarnos de ello, protegemos su información mediante la continua revisión de nuestros procesos 
                                    internos de protección tanto física como electrónica. </small>
                                    </div>
                                    <br />
                                    <div>
                                    <small>Por lo anterior, queremos compartir con usted el presente Aviso de Privacidad de Datos Personales (“Aviso de Privacidad”), 
                                        en apego a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la Ley).</small>
                                        </div>
                                    <br />
                                    <div>
                                        <small>
                                        Usted como titular de la información, tiene a su disposición, en todo momento, este Aviso de Privacidad en nuestra 
                                        página de Internet: <a HREF="https://www.caintra.org.mx/aviso-de-privacidad/">https://www.caintra.org.mx/aviso-de-privacidad/</a>
                                        </small>
                                    </div>
                                    <br />
                                    <div>
                                        <small>
                                        Se entenderá que usted, como titular, consiente tácitamente el tratamiento de sus datos personales conforme a lo enunciado 
                                        en el presente aviso de privacidad, cuando habiéndose puesto a su disposición, no manifieste su oposición.
                                        </small>
                                    </div>
                                    
                            </Container>  
                        </div>  
                        <div>
                            <h4>
                                Síguenos en nuestras redes
                            </h4>
                            <Col className="logos">
                                <img src={iconFacebook} onClick={() => window.open("https://www.facebook.com/PracticantesCaintra", '_blank').focus()} style={{height: "80px", width: "80px", fontSize: "50px", marginRight: "20px"}}/> 
                                <img src={iconInstagram} onClick={() => window.open("https://www.instagram.com/practicantescaintra/", '_blank').focus()} style={{height: "80px", width: "80px", fontSize: "50px", marginRight: "20px"}}/> 
                                <img src={iconTwitter} onClick={() => window.open("https://twitter.com/caintra_nl", '_blank').focus()} style={{height: "80px", width: "80px", fontSize: "50px"}}/> 
                            </Col>
                        </div>
                        <footer>
                            <hr />
                            <div className='footer-content'>
                            <img className='logo' src={LogoImage} alt="Privacidad" />
                            <img className='logo' src={LogoCaintra} alt="Privacidad" />
                            <span className='text-foot'>
                                CAINTRA Nuevo León: Av. Parque Fundidora 501 L-95A <br/>Col. Obrera, Monterrey, N.L. C.P. 64010
                            </span>
                            </div>
                        
                        </footer>
                </Col>
            </Row>
            
    </div>
);
}
import React from 'react'
import { Button, Modal, Table, Row, Col} from 'react-bootstrap';
import moment from 'moment';
export const HistoryRecords = ({
    historyRecords,
    setHistoryRecords,
    setShowHistoryRecords,
    showHistoryRecords,
    setCompanyIdRecordsToSearch
}) => {

    const handleClose = () => {
        setHistoryRecords([]);
        setCompanyIdRecordsToSearch('');
        setShowHistoryRecords(!showHistoryRecords);
    } 

    const currentRecordHeader = [
        {   
            key: 'createdAtFormated',
            keyName: 'Fecha de actualización',
            specialFormat: (date) => {
                return moment(date).format('DD/MM/YYYY hh:mm:ss')
            }
        },
        {   
            key: 'action',
            keyName: 'Movimiento'
        },
        {   
            key: 'desactivationComments',
            keyName: 'Comentarios'
        },
        {   
            key: 'createdBy',
            keyName: 'Usuario'
        },
    ]


    return (
        <Modal 
            className="history-modal"
            dialogClassName='modal-width-companies'
            contentClassName='modal-height-companies' 
            onHide={() => {handleClose()}} 
            show={showHistoryRecords} 
            centered
        >
            <Modal.Body>
                <Modal.Header>
                    <p>Historial de movimientos</p>
                </Modal.Header>
                    <Row>
                        <Col>
                            <div className='history-modal-table'>
                                <Table>
                                    <thead>
                                        <tr>
                                        {currentRecordHeader.map( item => {
                                            return (
                                                <th key={item.key} 
                                                    className='header-table'
                                                    style={{ 
                                                        minWidth: item.minWidth ? `${item.minWidth}` : '200px',
                                                        fontWeight: '700'
                                                    }}
                                                >{item.keyName}</th>
                                            )})
                                        }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { historyRecords.map((record, index) => {
                                            return (
                                                <tr key={index}>
                                                    {currentRecordHeader.map( item =>{
                                                        return (
                                                            <td key={item.key + index}>
                                                                <div className='center-content font-content'>
                                                                    {item.specialFormat ? item.specialFormat(record[item.key]) :  (record[item.key] || '')}
                                                                </div>
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
            <div className='close-button-container'>
                <Button 
                    className="" 
                    variant="danger" 
                    onClick={() => {handleClose()}}
                    style={{marginBottom: '20px'}}
                >Cerrar</Button>
            </div>
        </Modal>
    )
}

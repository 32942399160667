import React, { useEffect, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretUp, faCaretDown, faBell} from '@fortawesome/free-solid-svg-icons';
import onClickOutside from 'react-onclickoutside';
import {Nav, Toast, Alert, Card, Container, Row, Col, Image, Tabs, Tab} from 'react-bootstrap';
import './DropdownNotification.css';
import { VscBell } from 'react-icons/vsc';
import notificationIcon from '../../assets/IconsCaintra/notification.svg';
const axios = require("axios");

function DropdownNotification({title = "N/A", items, label = "", setSelected, firstChecked = false, context, sidebarExtended}){
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen); getNotifications();};
    const [checked, setChecked] = useState([]);
    const [notifications, setNotifications] = useState("");
    let notificationsByUser = [];
    DropdownNotification.handleClickOutside = () => setIsOpen(false);
    useEffect(() => {
        firstChecked && setFirstChecked();
        getNotifications();
        
    }, []);
    const getNotifications = async () => {
        const URL = process.env.REACT_APP_SOCKET_URL;
        try {
            let _notifications = await axios({
                url: `${URL}/notifications`,
                method: "PUT",
            });
            getNotificationsByUser(_notifications.data.data);
            setNotifications(_notifications.data.data);
        } catch (e) {
            setNotifications("");
        };
    };
    const setFirstChecked = () => {
        setChecked([items[0]]);
    };
    const getNotificationsByUser = async (data) =>{
        data.forEach(element => {
            if(element.user == context._id || element.user == context._role){
                notificationsByUser.push(element);
            }
        });
    }

    const getDayDiference = (difference)=>{
        const dayDifference = {
            1: 'Hace 1 día',
        }
        return dayDifference[difference] || `Hace ${difference} días`;
    }

    const getDate = (notificationInfo) =>{
        const notificationDate = new Date(notificationInfo);
        const today = new Date();
        const diff = today.getTime() - notificationDate.getTime();
        const dayDifference = Math.round(diff/(1000*60*60*24));
        const monthDifference = Math.round((diff/(1000*60*60*24))/31);
        const yearDifference = Math.round(((diff/(1000*60*60*24))/31)/12);
        const hoursDiference = Math.round(diff/(1000*60*60));
        const minutesDiference = Math.round(diff/(1000*60));
        if(yearDifference == 0){
            if(monthDifference == 0){
                if(dayDifference == 0){
                   return verifyHours(hoursDiference, minutesDiference);
                } else{
                   return getDayDiference(dayDifference);
                }
            }else{
                return getMonthDiference(monthDifference);
            }
        } else {
            return "Hace más de un año";
        }
    }

    const verifyHours = (hoursDiference, minutesDiference)=> {
        if(hoursDiference == 0){
                if(minutesDiference == 0){
                    return "Justo ahora"
                } else {
                   return getMinutesDifference(minutesDiference);
                }
        }else{
            return getHoursDifference(hoursDiference);   
        }
    }

    const getHoursDifference = (difference) =>{
            const hoursDiference = {
                1: 'Hace 1 hora',
            }
            return hoursDiference[difference] || `Hace ${difference} horas` ;
    }

    const getMinutesDifference = (difference) =>{
        const minutesDiference = {
            1: 'Hace 1 minuto',
        }
        return minutesDiference[difference] || `Hace ${difference} minutos` ;  

    }

    const getMonthDiference = (difference) =>{
        const monthDifference = {
            1: 'Hace 1 mes',
        }
        return monthDifference[difference] || `Hace ${difference} meses`;
    } 

    return(
        <div className="dd-wrapper-noti">
            <div
                tabIndex={0}
                className="dd-header-noti"
                role="button"
                onKeyPress={() => toggle()}
                onClick={() => toggle()}
                style={{textAlign: sidebarExtended ? 'left' : 'center', color: '#454545'}}
            >
            <div className="dd-action">
                <img src={notificationIcon} style={{marginRight: sidebarExtended ? '10px' : '0'}} size={25}/>
            </div>
            <div>
            {title}
            </div>

            </div>
            {isOpen && (    
                <ul className="dd-list card-text lu-container">
                    <Tabs defaultActiveKey="home" id="noanim-tab-example" className="text-title tab-title">
                        <Tab eventKey="home" title="Notificaciones">
                            { notifications.length > 0 ? notifications.map((notification, index)=> { 
                                if(notification._user == context._id || notification._user == context._role ){ 
                                    return(
                                        <li>
                                            <Card className="text-center border-0">
                                                <Card.Body className="card-body">
                                                    <Card.Title className="text-title" >{notification._name}</Card.Title>
                                                    <Card.Text>
                                                        <p className="text-description"> {notification.description} </p>
                                                    </Card.Text>
                                                    <Card.Text className="text-muted footer-text">
                                                    <a className="footer-text">  {getDate(notification.date)}</a>
                                                    </Card.Text>
                                                    <hr className="card-divider" />
                                                </Card.Body>
                                            </Card>
                                        </li>
                                    );
                                } 
                            }):<li>
                                    <Card className="text-center border-0">
                                    <Card.Body className="card-body">
                                        <Card.Text>
                                            <p className="text-description">No hay notificaciones</p>
                                        </Card.Text>
                                    </Card.Body>
                                    </Card>
                                </li> 
                            }
                        </Tab>
                        <Tab eventKey="profile" title="Avisos">
                        { notifications.length > 0 ? notifications.map((notification, index)=> { 
                                    if(notification._user == "all"){ 
                                        return(
                                            <li>
                                            <Card className="text-center border-0">
                                                <Card.Body className="card-body">
                                                    <Card.Title className="text-title" >{notification._name}</Card.Title>
                                                    <Card.Text>
                                                        <p className="text-description"> {notification.description} </p>
                                                    </Card.Text>
                                                    <Card.Text className="text-muted footer-text">
                                                    <a className="footer-text">  {getDate(notification.date)}</a>
                                                    </Card.Text>
                                                    <hr className="card-divider" />
                                                </Card.Body>
                                            </Card>
                                            </li>
                                        );
                                    }
                                }) : <li>
                                        <Card className="text-center border-0">
                                            <Card.Body className="card-body">
                                                <Card.Text>
                                                    <p className="text-description">No hay avisos</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </li> 
                            }
                        </Tab>
                    </Tabs>                    
                </ul>
                
                
                )
            }
        </div>
    )
};

const clickOutsideConfig = {
    handleClickOutside: () => DropdownNotification.handleClickOutside,
};

export default onClickOutside(DropdownNotification, clickOutsideConfig);
import React, {useState} from 'react'
import readXlsxFile from 'read-excel-file';
import {Form, Button, Modal, Table, Row, Col, Spinner} from 'react-bootstrap';
import { notify } from '../../../utils/notify';
import Methods from "../../../utils/https";
import moment from 'moment'
export const UploadAccounts = ({showUploadAccount, setShowUploadAccounts, selectedBank, setData, data, internDataBank, context, createLogisticRecord}) => {
    const [dataAux, setDataAux] = useState({interns: [], indexValues: {}});
    const [errors, setErrors] = useState([]);
    const [isValidFile, setIsValidFile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessFinished, setIsProcessFinished] = useState(false);

    let indexAux = {
        'Banorte': {start: 1, columsNameIndex: 0},
        'Santander': {start: 1, columsNameIndex: 0},
    }
    
    const updateInternByProcess = async(intern, accountNumber) => {

        intern[internDataBank]['accountNumber'] = accountNumber.toString(); 
        
        let value = {
            _id: intern._id,
            attributes: [
                { updateKey: "logisticStatus", updateValue: 'Confirmado' },
                { updateKey: "lastmodifyLogisticDate", updateValue: moment().format()},
                { updateKey: internDataBank, updateValue: intern[internDataBank] },
                { updateKey: 'clabe', updateValue: accountNumber.toString()},
                { updateKey: "accountColorStatus", updateValue: "#000000" },
                { updateKey: "lastModifyAccount", updateValue: context.user.data.email }
            ]
        }
        await Methods.updatePrefactureClabeByIntern(intern._id, "#000000" , context.user.data.email, accountNumber.toString());
        await Methods.updateIntern(value);
        await createLogisticRecord(intern, accountNumber.toString());
    }

    const processData = async() => {
        setIsLoading(true);
        let internsToUpdate = [];
        let errors = [];
        let setInterns = false;
        setIsProcessFinished(false)

        if(!dataAux.interns){
            notify('No hay registros por procesar', 'error');
            return;
        }

        let internsLogistics = data.filter((item) => {
            if (selectedBank.toLowerCase() == item.bank?.toLowerCase()) {
                return true;
            }
            return false;
        });

        for(let recordAux of dataAux.interns){
            let curp = selectedBank == "Bancomer" ?  recordAux.substring(2, 20) : recordAux[dataAux.indexValues.curpIndex];
            let accountNumber = selectedBank == "Bancomer" ?  recordAux.substring(189, 199) : recordAux[dataAux.indexValues.accountNumberIndex];
            if(curp != null){
                let internFound = internsLogistics.find( i => i.curp == curp);
                if(!internFound){
                    errors.push({curp: curp, errorLabel: 'No se encontró al practicante en el listado actual'});
                } else if (selectedBank == "Santander" && accountNumber != null && accountNumber.toString().trim().length != 11) {
                    errors.push({curp: curp, errorLabel: 'El tamaño de un número de cuenta es distinto a 11 dígitos'});
                } else if(accountNumber != null){
                    internsToUpdate.push(updateInternByProcess(internFound, accountNumber));
                    internFound.logisticStatus = 'Confirmado';
                    internFound[internDataBank]['accountNumber'] = accountNumber.toString();
                    internFound.accountColorStatus =  "#000000";
                    setInterns = true;
                }
            }

            if(internsToUpdate.length > 100){
                await Promise.all(internsToUpdate);
                internsToUpdate = [];
            }
        }

        setErrors(errors);
        await Promise.all(internsToUpdate);
        setInterns && setData([...data]);

        if(errors.length > 0){
            setIsLoading(false);
            setIsProcessFinished(true);
        }else{
            setIsLoading(false);
            setIsProcessFinished(true);
            handleClose();
            notify('Registros procesados exitosamente', 'success');
        }   
    }

    const onUpload = (e) => {
        let auxData = [];
        let indexColumnAux = {}; 
        let arrayAux = [];
        if(selectedBank == "Bancomer"){
            let reader = new FileReader();
            let lines;
            reader.readAsText(e.target.files[0], "UTF-8");
            reader.onload = function (evt) {
                lines = evt.target.result.split(/[\r\n]+/g);
                lines = lines.filter((item) => { return item.startsWith('02'); })
                if(!lines.length) {
                    uploadError('Archivo no correspondiente');
                    return;
                }
                setDataAux({
                    indexValues: indexColumnAux, 
                    interns: lines
                });
                setIsValidFile(true);
            }
            reader.onerror = function (evt) {
                uploadError('Archivo no correspondiente');
                return;
            }
        } else {
            if(!indexAux[selectedBank]) return;
            readXlsxFile(e.target.files[0]).then((rows) => {
                if(!rows[indexAux[selectedBank].columsNameIndex]) {
                    uploadError('Archivo no correspondiente');
                    return;
                }
                
                arrayAux = [...rows[indexAux[selectedBank].columsNameIndex]];
                indexColumnAux.accountNumberIndex = arrayAux.indexOf("No de Cuenta") == -1 ? arrayAux.indexOf("NO DE CUENTA") : arrayAux.indexOf("No de Cuenta");
                
                if(!indexColumnAux.accountNumberIndex || indexColumnAux.accountNumberIndex == -1 ){
                    uploadError('El archivo no cuenta con la columna "No de Cuenta"');
                    return;
                }

                indexColumnAux.curpIndex = arrayAux.indexOf("CURP");
                auxData = rows.slice(indexAux[selectedBank].start);
                setDataAux({
                    indexValues: indexColumnAux, 
                    interns: auxData
                });
                setIsValidFile(true);
            });
        }
    }

    const uploadError = (label) => {
        notify(label, 'error');
        setIsValidFile(false);
    }


    const handleClose = () => {
        setShowUploadAccounts(false);
        setErrors([]);
        setDataAux({interns: [], indexValues: {}});
        setIsValidFile(false);
        setIsProcessFinished(false);
    } 
    
    return (
        <Modal className="prefacture-modals" onHide={() => {handleClose()}} show={showUploadAccount} centered>
            <Modal.Body>
                <Modal.Header>
                    <p>Subir archivo</p>
                </Modal.Header>
                {!isProcessFinished && selectedBank !== "Bancomer" && <input type="file" accept=".xlsx, .xls, .csv" onChange={onUpload}/> }
                {!isProcessFinished && selectedBank === "Bancomer" && <input id="fileForUpload" type="file" accept=".exp" onChange={onUpload}/> }
                
                {(!isLoading && errors.length > 0) &&
                    <Row>
                        <Col>
                            <div className='upload-accounts-table'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <td>
                                                CURP
                                            </td>
                                            <td>
                                                ERROR
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { errors.map((error, index) => {
                                            return <tr key={index}>
                                                <td>{error.curp}</td>
                                                <td>{error.errorLabel}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                }

                {isLoading && <Spinner animation="border" role="status"></Spinner>}

                {!isProcessFinished && 
                    <Button className="float-right ml-2" disabled={!isValidFile} variant="primary" onClick={async () => {processData()}}>
                        Confirmar
                    </Button> 
                }

                <Button className="float-right" variant="danger" onClick={() => {handleClose()}}>
                    {!isProcessFinished ? 'Cancelar' : 'Finalizar'}
                </Button>
            </Modal.Body>
        </Modal>
    )
}

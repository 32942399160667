import React, {useState, useContext} from 'react';
import {Accordion, Card, Button, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp, faUser, faPen, faTrashAlt, faPlusCircle, faPenAlt, faTags, faUserTag} from '@fortawesome/free-solid-svg-icons';
import AssignRole from './AssignRole.jsx';
import DeleteRole from './DeleteRole.jsx';
import ChangePermissions from './ChangePermissions.jsx';
import {motion} from 'framer-motion';
import {UserContext} from '../../../context/UserContext';
import { notify } from '../../../utils/notify.js';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BiEdit, BiCheckShield } from 'react-icons/bi';
import { RiUserAddLine } from 'react-icons/ri';

export default function ListRoles({users, roles, updateRoles, setUpdateRoles, isCaintraCompany}){
    const [accordionOpened, setAccordionOpened] = useState(0);
    const [openAssignRole, setOpenAssignRole] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [currentRole, setCurrentRole] = useState("");
    const [openDeleteRole, setOpenDeleteRole] = useState(false);
    const [openChangePermissions, setOpenChangePermissions] = useState(false);
    const context = useContext(UserContext);

    const handleAssignUser = (user) => {
        if(!context.can("update", "Roles")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        setCurrentUser(user);
        setOpenAssignRole(true);
    };

    const handleChangePermissions = (role) => {
        if(!context.can("view", "Roles")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };
        
        setCurrentRole(role);
        setOpenChangePermissions(true);
    };

    const handleOpenDeleteRole = (roleId, roleLabel) => {
        if(!context.can("delete", "Roles")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };
        const data = {
            roleId: roleId,
            users: []
        };

        users.map(user => {
            if(user._role === roleLabel) data.users.push(user._id);
        });
        
        setCurrentRole(data);
        setOpenDeleteRole(true);
    };

    const getUserCount = (role) => {
        let count = 0;
        users.map(user => (user._role === role) && count++);
        return count;
    };

    return(
        <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ duration: 0.4 }} style={{width: "100%"}}>
            <Row className="height-roles">
                <Col md={6}>
                    <Accordion defaultActiveKey="0" className="roles-list">
                        <Card key="0">
                            <Card.Header>
                                <Accordion.Toggle as={Button} onClick={() => setAccordionOpened(accordionOpened == 0 ? -1 : 0)} variant="link" eventKey="0">
                                    <FontAwesomeIcon icon={accordionOpened == 0 ? faChevronCircleUp : faChevronCircleDown} /> Sin Asignar - {getUserCount("Sin Asignar")}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {users.map(user => {
                                        if(user._role === "Sin Asignar"){
                                            return(
                                                <div className="user-options">
                                                    <p><FontAwesomeIcon icon={faUser} /> {user.fullName}</p>
                                                    <FontAwesomeIcon icon={faPlusCircle} className="option" role="button" onClick={() => handleAssignUser(user)}/>
                                                </div>
                                            );
                                        };
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {roles.map((role, eventKeyIndex) => {
                            return(
                                <Card key={eventKeyIndex}>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} onClick={() => setAccordionOpened(accordionOpened == eventKeyIndex+1 ? -1 : eventKeyIndex+1)} variant="link" eventKey={`"${eventKeyIndex+1}"`}>
                                        <FontAwesomeIcon icon={accordionOpened == eventKeyIndex+1 ? faChevronCircleUp : faChevronCircleDown} /> {role.label} - {getUserCount(role.label)}
                                        </Accordion.Toggle>

                                        <div className="options">
                                            <FontAwesomeIcon icon={faTags} className="option" role="button" onClick={() => handleChangePermissions(role)}/>
                                            {role.label != "ADMINISTRADOR" && <FontAwesomeIcon role="button" icon={faTrashAlt} onClick={() => handleOpenDeleteRole(role._id, role.label)}/>}
                                        </div>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey={`"${eventKeyIndex+1}"`}>
                                        <Card.Body>
                                            {users.map(user => {
                                                if(user._role === role.label){
                                                    return(
                                                        <div className="user-options">
                                                            <p><FontAwesomeIcon icon={faUser} /> {user.fullName}</p>

                                                            <div>
                                                                <FontAwesomeIcon icon={faUserTag} className="option" role="button" onClick={() => handleAssignUser(user)}/>
                                                            </div>
                                                            
                                                        </div>
                                                    );
                                                };
                                            })}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                </Col>
            </Row>
            <AssignRole 
                currentUser={currentUser}
                openAssignRole={openAssignRole}
                setOpenAssignRole={setOpenAssignRole}
                roles={roles}
                updateRoles={updateRoles}
                setUpdateRoles={setUpdateRoles}
            />

            <ChangePermissions 
                currentRole={currentRole}
                openChangePermissions={openChangePermissions}
                setOpenChangePermissions={setOpenChangePermissions}
                updateRoles={updateRoles}
                setUpdateRoles={setUpdateRoles}
                users={users}
                isCaintraCompany={isCaintraCompany}
            />

            <DeleteRole 
                openDeleteRole={openDeleteRole}
                setOpenDeleteRole={setOpenDeleteRole}
                updateRoles={updateRoles}
                setUpdateRoles={setUpdateRoles}
                currentRole={currentRole}
            />
        </motion.div>
    );
};
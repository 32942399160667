import React, {useContext} from 'react';
import './App.css';
import Register from './pages/Register/Register.jsx';
import DocumentationRequest from './pages/DocumentationRequest/DocumentationRequest.jsx';
import Login from './pages/Login/Login.jsx';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Amplify from 'aws-amplify';
import {ToastContainer} from 'react-toastify';
import {UserContext} from './context/UserContext';
import Dashboard from './pages/Dashboard/Dashboard.jsx';
import Interns from './pages/Interns/Interns.jsx';
import Layout from './layouts/Layout.jsx';
import Prefacture from './pages/Prefacture/Prefacture.jsx';
import Reports from './pages/Reports/Reports.jsx';
import Companies from './pages/Companies/Companies.jsx';
import RegisterCompany from './pages/RegisterCompany/RegisterCompany.jsx';
import Dispersion from './pages/Dispersion/Dispersion.jsx';
import Pending from './pages/Pending/Pending.jsx';
import Universities from './pages/Universities/Universities.jsx';
import Logistics from "./pages/Logistics/Logistics.jsx";
import Groups from './pages/Groups/Groups';
import Privacy from './pages/Privacy/Privacy.jsx';
import Notifications from './pages/Notifications/Notifications.jsx';
import Uploads from './pages/Uploads/Uploads.jsx';
import {Help} from './pages/Help/Help.jsx';
import {Amounts} from './pages/Amounts/Amounts.jsx';
import {Configurations} from './pages/ConfigurationPage/Configurations.jsx';
import {getPoolData} from './utils/getPoolConfig.js';
import {CompaniesSuspension} from './pages/CompaniesSuspension/CompaniesSuspension'
let poolConfig = getPoolData();

Amplify.configure({
  Auth:{
    mandatorySignId: true,
    region: poolConfig.Region,
    userPoolId: poolConfig.UserPoolId,
    userPoolWebClientId: poolConfig.ClientId
  }
});

function App() {

  const context = useContext(UserContext);

  return (
    <Router>
      <Switch>
          <Route exact path="/">
            {context.token ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
          </Route>

          <Route exact path="/register">
            {context.token ? <Redirect to="/dashboard"/> : <Register />}
          </Route>

          <Route exact path="/login">
            {context.token ?  <Redirect to="/dashboard"/> : <Login />}
          </Route>

          <Route exact path="/dashboard">
            {context.token ? <Layout component={<Dashboard />} componentName={"Dashboard"} /> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/interns">
            {context.token ? <Layout component={<Interns />} componentName={"Interns"} /> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/prefacture">
            {context.token ? <Layout component={<Prefacture />} componentName={"Prefacture"} /> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/reports">
            {context.token ? <Layout component={<Reports />}  componentName={"Reports"}/> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/internship/:id">
            {context.token ? <Redirect to="/dashboard" /> : <DocumentationRequest />}
          </Route>

          <Route exact path="/register/company/:id?">
            {context.token ? <Redirect to="/dashboard" /> : <RegisterCompany />}
          </Route>

          <Route exact path="/companies">
            {context.token ? <Layout component={<Companies />} componentName={"Companies"} /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/dispersion">
            {context.token ? <Layout component={<Dispersion />} componentName={"Dispersion"} /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/dispersion/pending">
            {context.token ? <Layout component={<Pending />} componentName={"Pending"}/> : <Redirect to="/" />}
          </Route>

          <Route exact path="/universities">
            {context.token ? <Layout component={<Universities />}componentName={"Universities"} /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/logistics">
            {context.token ? <Layout component={<Logistics />}componentName={"Logistics"} /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/notifications">
            {context.token ? <Layout component={<Notifications />} componentName={"Notifications"} /> : <Redirect to="/" />}
          </Route>
          
          <Route exact path="/uploads">
            {context.token ? <Layout component={<Uploads />} componentName={"Uploads"} /> : <Redirect to="/" />}
          </Route>
          
          <Route exact path="/privacy">
            {context.token ? <Layout component={<Privacy />}  componentName={"Privacy"}/> : <Redirect to="/"/>}
          </Route>
          
          <Route exact path="/groups">
            {context.token ? <Layout component={<Groups />}  componentName={"Groups"}/> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/configurations">
            {context.token ? <Layout component={<Configurations />}  componentName={"Configurations"}/> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/amounts">
            {context.token ? <Layout component={<Amounts />}  componentName={"Amounts"}/> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/help">
            {context.token ? <Layout component={<Help />}  componentName={"Help"}/> : <Redirect to="/"/>}
          </Route>

          <Route exact path="/companies/suspension">
            {context.token ? <Layout component={<CompaniesSuspension />}  componentName={"CompaniesSuspension"}/> : <Redirect to="/"/>}
          </Route>

          <Route path="/static/media/*">
            <Redirect to="/"/>
          </Route>
          
          <Route path="*">
            <Redirect to="/" />
          </Route>
        
      </Switch>
      <ToastContainer />
    </Router>
  );
}

export default App;
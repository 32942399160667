import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCloudDownloadAlt, faCloudUploadAlt, faBan, faExclamationTriangle, faFileDownload, faTrashAlt, faEdit} from '@fortawesome/free-solid-svg-icons';
import {Form, Table } from 'react-bootstrap';
import { notify } from '../../../utils/notify';
import Methods from '../../../utils/https';
const axios = require("axios");

export default function InternDocumentsTable ({internDocs, currentInternData, currentInternId, setUpdateInfo, updateInfo, canEditIntern}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const docNames = ["Carátula Estado de Cuenta", "Comprobante de Estudios", "Acta de Nacimiento", "Identificación Oficial", "Comprobante de Domicilio", "CURP", "Convenio Firmado"];
    let existingDocs = [];
    let isCompleted = [];
    
    const requiredDocs = [
        {key: "Caratula Estado de Cuenta", name: "Caratula-Estado-de-Cuenta"},
        {key: "Comprobante de Estudios", name: "Comprobante-de-Estudios"},
        {key: "Acta de Nacimiento", name: "Acta-de-Nacimiento"},
        {key: "Identificacion Oficial", name: "Identificacion-Oficial"},
        {key: "Comprobante de Domicilio", name: "Comprobante-de-Domicilio"},
        {key: "CURP", name: "Curp-De-Practicante"},
        {key: "Convenio Firmado", name: "Convenio-Firmado"}
    ];

    requiredDocs.map(required => {
        const key = required.key;

        internDocs.length > 0 && internDocs.map(doc => {
            if(doc.key === key){
                required.url = doc.url;
                required._id = doc._id;
                required.fileName = doc.name;
            }
        })

        existingDocs.push(required);
    });

    existingDocs.forEach(element =>{
        if(element.url && element.url != ""){
            isCompleted.push(element)
        }
    })

    const verifyDocuments = async (e) =>{
        existingDocs.forEach(element =>{
            if(element.url && element.url != ""){
                isCompleted.push(element)
            }
        })
        if(isCompleted.length == 6){
            createNotification(e);
        }
    }

    const getExtension = (fileName) => {
        let basename = fileName.split(/[\\/]/).pop();
        let pos = basename.lastIndexOf(".");      

        if (basename === "" || pos < 1){
            return "";
        };

        return basename.slice(pos + 1);            
    };

    const createNotification = async () => {
        const notification = {
            name: "Documentación de practicante",
            user: currentInternData.created_by,
            description:`Se completó la documentación del practicante ${currentInternData.firstname} ${currentInternData.father_lastname}`,
            type: " ",
            date: new Date()
        };
        try{
            
            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });

        } catch (error) {
        };
    };


    const updateInternDoc = async (e, fileName, oldFileId, fileKey) => {
        e.preventDefault();
        const _file = e.target.files[0];
        const fileExtension = getExtension(_file.name);
        const filename = `${fileName}.${fileExtension}`;

        let data = new FormData();
        
        if(_file.size > 10485760){
            notify(`El archivo ${file.name} pesa más de 10 MB`, "error");
            return;
        };


        let files = Array();
        let file = {"filename": filename, "fileKey": fileKey};
        files.push(file);
        data.append("files", JSON.stringify(files));
        data.append("path", `interns/docs/${currentInternId}`);
        data.append("intern_id", currentInternId);     
        data.append("newFileName", filename);
        data.append("oldFilePath", `interns/docs/${currentInternId}/${filename}`);
        data.append("oldFileId", oldFileId);


        let headers = {
            "Content-Type": await verifyDocumentTypeHeader(fileExtension),
            "Access-Control-Allow-Origin" : "*",
            "Access-Control-Allow-Methods": "*"
        }

        try{

            await axios({
                url: `${URL}/interns/docs/`,
                method: "PATCH",
                data: data
            });

            let response = await axios({
                url: `${URL}/interns/docs/${currentInternId}/`,
                method: "POST",
                data: data
            });

            await axios({
                url: response.data[0].presignedPUTURL,
                method: "PUT",
                data: _file, 
                headers : headers
            });

            notify("El archivo se actualizó correctamente", "success");
            setUpdateInfo(!updateInfo);
        } catch(e) {
            e.response && notify(e.response.data.message, "error");
        };
    };

    const verifyDocumentTypeHeader = async (type) =>{
        let typeHeader = {
            "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "doc": "application/msword",
            "txt": "text/plain",
            "text": "text/plain"
        }
        return typeHeader[type] || "image/*"
    }

    const uploadInternDoc = async (e, NewfileName, fileKey) => {
        e.preventDefault();
        
        try{
            const _file = e.target.files[0];
            let fileToUpload = _file;
            const fileExtension = getExtension(_file.name);
            const filename = `${NewfileName}.${fileExtension}`;

            let data = new FormData();

            let files = Array();
            let file = {"filename": filename, "fileKey": fileKey};
            files.push(file);
            data.append("files", JSON.stringify(files));
            data.append("path", `interns/docs/${currentInternId}`);
            data.append("intern_id", currentInternId);     

            if(_file.size > 10485760){
                notify(`El archivo ${_file.name} pesa más de 10 MB`, "error");
                return;
            };

            let response = await axios({
                url: `${URL}/interns/docs/${currentInternId}/`,
                method: "POST",
                data: data
            });

            let headers = {
                "Content-Type": await verifyDocumentTypeHeader(fileExtension),
                "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Methods": "*"
            }

            await axios({
                url: response.data[0].presignedPUTURL,
                method: "PUT",
                data: fileToUpload, 
                headers : headers
            });

            notify(`El archivo "${filename}" se subió correctamente`, "success");

            await updateDocumentationStatus(filename, fileKey);

            setUpdateInfo(!updateInfo);
        } catch (error) {
            error.response && notify(error.response.data.message, "error");
        };

    };

    const updateDocumentationStatus = async (filename, fileKey) => {

        let data = {
            _id: currentInternId,
            attributes: []
        };

        let existingKeys = [];

        const requiredDocs = [
            {key: "Comprobante de Estudios", name: "Comprobante-de-Estudios"},
            {key: "Acta de Nacimiento", name: "Acta-de-Nacimiento"},
            {key: "Identificacion Oficial", name: "Identificacion-Oficial"},
            {key: "Comprobante de Domicilio", name: "Comprobante-de-Domicilio"},
            {key: "CURP", name: "Curp-De-Practicante"},
        ];

        internDocs.push({name: filename, key: fileKey});

        if(currentInternData.documentationStatus == 'En espera'){
            requiredDocs.map(required => {
                const key = required.key;
    
                internDocs.length > 0 && internDocs.map(doc => {
                    if(doc.key === key){
                        existingKeys.push(doc);
                    }
                })
            });
            
            if(existingKeys.length == 5){
                data.attributes.push({updateKey: 'documentationStatus', updateValue: 'Entregada'});
                await Methods.updateIntern(data);
            }
        }       

    }
    
    return(
        <div className="table-container">
            <Table>
                <thead>
                    <tr>
                        <th>Documento</th>
                        <th>Nombre</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                
                <tbody>
                    {
                        existingDocs.map((doc, docIndex) => {
                            if(doc.url){
                                return(
                                    <tr key={docIndex}>
                                        <td ><h6 className='document-name'>{docNames[docIndex]}</h6></td>
                                        <td className='center-text'>{doc.fileName}</td>
                                        <td  style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{margin: "auto", display: "flex", flexDirection: "row"}}>    
                                                <a href={doc.url} key={doc.key} target="_blank"><FontAwesomeIcon icon={faCloudDownloadAlt}/></a>
                                                {canEditIntern() &&
                                                <Form.File
                                                    hidden
                                                    onChange={(e) => {
                                                            e.preventDefault(); 
                                                            updateInternDoc(e, doc.name, doc._id, doc.key);
                                                            verifyDocuments(e);
                                                        }
                                                    }
                                                    id={`${doc.key}_file`}
                                                    label={<FontAwesomeIcon className="form-file-label center-text" icon={faCloudUploadAlt}/>}
                                                />
                                                }
                                            
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }else {
                                return(
                                    <tr>
                                        <td><h6 className='document-name' >{docNames[docIndex]}</h6></td>
                                        <td style={{textAlign: "center"}}><FontAwesomeIcon color="orange" icon={faExclamationTriangle}/></td>
                                        <td style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{margin: "auto", display: "flex", flexDirection: "row"}}> 
                                                {canEditIntern() 
                                                ? <Form.File
                                                    hidden
                                                    onChange={(e) => {
                                                            e.preventDefault(); 
                                                            uploadInternDoc(e, doc.name, doc.key);    
                                                            verifyDocuments(e);                                               
                                                        }
                                                    }
                                                    id={`${doc.key}_file`}
                                                    label={<FontAwesomeIcon className="form-file-label center-text" icon={faCloudUploadAlt}
                                                    />}
                                                />
                                                : <FontAwesomeIcon color="orange" icon={faBan} style={{height: "1.65rem"}}/>
                                            }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
    
};

import React from 'react';
import { Modal, Image, Form, Button } from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';

import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';

const axios = require("axios");



export default function NewDepartmentModal({ displayNewDepartmentModal, setDisplayNewDepartmentModal, updateList, setUpdateList, currentCompanyId }) {

    const context = useContext(UserContext);

    const URL = process.env.REACT_APP_SOCKET_URL;

    const newDepartment = async (e) => {
        e.preventDefault();
        const department = {
            title: e.target.elements.title.value,
            id_company: currentCompanyId,
            departmentField: context.getDepartmentField(),
        };

        try {

            await axios({
                url: `${URL}/departments`,
                method: "POST",
                data: department
            });

            notify(`${department.title} agregado exitosamente como ${context.getDepartmentField()}`, "success");
            setUpdateList(!updateList);
            setDisplayNewDepartmentModal(!displayNewDepartmentModal);
        } catch (e) {
            e.response && notify(e.response.data.message);
        };
    };

    const validateEntryKey = (e) => {
        e.charCode == 13 && e.preventDefault()
    }


    return (
        <Modal className="new-cc-modal" show={displayNewDepartmentModal} onHide={() => setDisplayNewDepartmentModal(!displayNewDepartmentModal)} centered>
            <Modal.Header>
                <p>Nuevo {context.getDepartmentField()}</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={newDepartment}>
                    <Form.Group controlId="title">
                        <Form.Label>Título</Form.Label>
                        <Form.Control onKeyPress={validateEntryKey} type="text" required />
                    </Form.Group>


                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Registrar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayNewDepartmentModal(!displayNewDepartmentModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
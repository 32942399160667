import React, { useRef, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import internStatusUserDashboard from "../../../assets/IconsCaintra/internStatusUserDashboard.svg";
import { DownloadDashboard } from "../components/DownloadDashboard";
import {Spinner} from 'react-bootstrap';
export const InternByStatusDashboard = ({
    periodSelected,
    periodSelectedToCompare,
    moment,
    dataByMonths,
    allMonthsData,
    sortByMonth,
    isLoadingData,
    refsAux,
    allStatusExcelConfigurations,
    monthsToDownload
}) => {
    let seriesDataEmpty = [
        {
            name: "Activo",
            data: [0, 0, 0, 0, 0, 0],
        },
        {
            name: "Pendiente",
            data: [0, 0, 0, 0, 0, 0],
        },
        {
            name: "En proceso",
            data: [0, 0, 0, 0, 0, 0],
        },
        {
            name: "Comparación activo",
            data: [0, 0, 0, 0, 0, 0],
        }, 
        {
            name: "Comparación pendiente",
            data: [0, 0, 0, 0, 0, 0],
        }, 
        {
            name: "Comparación en proceso",
            data: [0, 0, 0, 0, 0, 0],
        }
    ];
    
    const [seriesData, setSeriesData] = useState(seriesDataEmpty);
    const [categories, setCategories] = useState([0, 0, 0, 0, 0, 0]);
    const [tickAmountMax, setTickAmountMax] = useState(1);
    const [isProcessingData, setIsProcessingData] = useState(true);
    const exportRef = useRef();
    const dateRef = useRef();
    const downloadRef = useRef();

    useEffect(() => {
        getDataByMonthAndCompany();
    }, [dataByMonths]);

    useEffect(() => {
        refsAux.componentRef.current[3] = exportRef.current;
        refsAux.dateRef.current[3] = dateRef.current;
        refsAux.downloadRef.current[3] = downloadRef.current;
    }, [exportRef.current, dateRef.current, downloadRef.current]);

    const getDataByMonthAndCompany = async () => {
        setIsProcessingData(true);
        let allStatusAux = {
            Activo: [],
            Pendiente: [],
            "En proceso": [],  
            "Comparación activo": [],
            "Comparación pendiente": [],
            "Comparación en proceso": []
        };
        let months = []
        let tickAmount = 1;
        let totalAux = [...seriesDataEmpty];
        let [allCurrentStatusAux] = getDataByStatusAndPeriod(dataByMonths.currentData, periodSelected);
        let [monthsToShow] = getDataByMonth(allCurrentStatusAux, periodSelected, false);
        if(dataByMonths.dataToCompare.length){
            let [allStatusAuxToCompare] = getDataByStatusAndPeriod(
                dataByMonths.dataToCompare, periodSelectedToCompare, true
            );
            let [monthsToShowAux] = getDataByMonth(allStatusAuxToCompare, periodSelectedToCompare, true);
            monthsToShow = sortByMonth(monthsToShow, monthsToShowAux);
        }

        monthsToShow.map((data => {
            allStatusAux.Activo.push(data.Activo);
            allStatusAux.Pendiente.push(data.Pendiente);
            allStatusAux['En proceso'].push(data['En proceso']);
            allStatusAux["Comparación activo"].push(data["Comparación activo"]);
            allStatusAux['Comparación pendiente'].push(data['Comparación pendiente']);
            allStatusAux["Comparación en proceso"].push(data["Comparación en proceso"]);
            months.push(data.monthName)
        }));

        totalAux.map((status) => {
          let maxValue = Math.max(...allStatusAux[status.name]);
          status.data = allStatusAux[status.name];
          tickAmount = maxValue > tickAmount ? maxValue : tickAmount;
        });

        if(!periodSelectedToCompare){
            let noCompareValues = ["Activo", "Pendiente", "En proceso"];
            totalAux = totalAux.filter(data => noCompareValues.includes(data.name));        
        }
        else{
            for(let items in totalAux) {
                for(let i =0 ; i<10 ; i++){
                    totalAux[items].data.pop();
                }
                
            }
            for(let i =0 ; i<10 ; i++){
                months.pop();
            }
        }
        

        
   
        setTickAmountMax(tickAmount);
        setCategories(months);
        setSeriesData(totalAux);
        setIsProcessingData(false);
    };


    const getDataByStatusAndPeriod = (data, period, isPeriodToCompare) => {
        let allStatusAux = {
            Activo: Array(6).fill(0),
            Pendiente: Array(6).fill(0),
            "En proceso": Array(6).fill(0),
            'Comparación activo':  Array(6).fill(0),
            'Comparación pendiente':  Array(6).fill(0),
            'Comparación en proceso': Array(6).fill(0)
        };

        let labelsToPeriod = {
            Activo: 'Comparación activo', 
            Pendiente: 'Comparación pendiente',
            "En proceso": 'Comparación en proceso',
        }

        for (let companyData of data) {
            for (let companyMonthData of companyData) {
                companyMonthData?.allStatus?.map((statusAux) => {
                    let statusValue = allStatusAux[
                        isPeriodToCompare ? labelsToPeriod[statusAux.status] : statusAux.status
                    ];
                    if (statusValue) {
                        statusValue[companyMonthData.month - period] +=
                            statusAux.total;
                    }
                });
            }
        }

        return [allStatusAux];
    };

    const getDataByMonth = (data, periodSelected, isPeriodToCompare) => {
        let indexToDelete = [];
        let monthsToShow = [];
        let currentDate = moment().startOf('day').subtract(periodSelected - 1, 'month');
        [...Array.from({ length: 6 }, (_, i) => i + 1)].map((period) => {
            monthsToShow.push({
                monthName: moment(currentDate).subtract(period - 1, "month").format("MMM YYYY"),
                month: moment(currentDate).subtract(period - 1, "month").format('M'),
                timeStamp: parseInt(moment(currentDate).subtract(period - 1, "month").format('X')),
                Activo: isPeriodToCompare ? 0 : data.Activo[period - 1],
                Pendiente: isPeriodToCompare ? 0 : data.Pendiente[period - 1],
                'En proceso': isPeriodToCompare ? 0 : data['En proceso'][period - 1],                   
                'Comparación activo': isPeriodToCompare ? data['Comparación activo'][period - 1] : 0,
                'Comparación pendiente': isPeriodToCompare ? data['Comparación pendiente'][period - 1] : 0,
                'Comparación en proceso': isPeriodToCompare ? data['Comparación en proceso'][period - 1] : 0    
            });
        });
        
        return [
            monthsToShow,
            indexToDelete
        ]
    }

    let options = {
        series: seriesData,
        options: {
            chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: "13px",
                                fontWeight: 900,
                            },
                        },
                    },
                },
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            grid: {
                borderColor: "#EBEBEB",
                strokeDashArray: 3,
            },
            title: {
                text: "",
            },
            xaxis: {
                categories: categories,
                labels: {
                    formatter: function (val) {
                        return Math.round(val);
                    },
                },
                ...(tickAmountMax == 1 && { tickAmount: tickAmountMax }),
            },
            colors: ["#67e09c", "#00AEEF", "#FEB019", "#E883C8", "#FF5678", "#0B5FF3"],
            yaxis: {
                title: {
                    text: undefined,
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return Math.round(val);
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: 40,
            },
        },
    };

    return (
        <div className="dashboard-container intern-status" ref={exportRef}>
            <div className="title-icon-container">
                <div className="title-dashboard">
                    <div className="title-gender-container">
                        <img src={internStatusUserDashboard} size={30} />
                        <p>Estatus de practicantes</p>
                    </div>
                    <p style={{ display: "none" }} ref={dateRef}>
                        {moment()
                            .startOf("day")
                            .subtract(periodSelected - 1, "month")
                            .format("MMMM YYYY")}
                    </p>
                    <DownloadDashboard
                        refDashboard={exportRef}
                        fileName={"Estatus_de_practicantes"}
                        excelConfigurations={allStatusExcelConfigurations}
                        excelValues={allMonthsData}
                        dateRef={dateRef}
                        periodSelected={periodSelected}
                        currentRef={downloadRef}
                        monthsToDownload={monthsToDownload}
                    />
                </div>
            </div>

            {!isProcessingData && !isLoadingData ?
                <ReactApexChart
                    options={options.options}
                    series={options.series}
                    type="bar"
                    height={500}
                /> :
                <Spinner 
                    animation="border" 
                    role="status" 
                    className={'loading-screen'}
                />
            }
        </div>
    );
};

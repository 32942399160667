import React, {useEffect, useState, useContext} from 'react';
import {Modal, Button, Form, Image, Spinner, Row, Col} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {UserContext} from '../../../context/UserContext';


export default function CreateRole({currentCompanyId, updateRoles, setUpdateRoles, showCreateRole, setShowCreateRole}){
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(undefined);
    const context = useContext(UserContext);
    const isCaintraUser = context.user.data.isCaintraUser;
    const [isCaintraCompany, setIsCaintraCompany] = useState(false);
    const [isAdminRole, setIsAdminRole] = useState(false);
    const [isGroupRole, setIsGroupRol] = useState(false);


    useEffect(() => {
        getCompanyById(currentCompanyId);
    }, []);  
    
    const getCompanyById = async (id) => {
        const result =  await Methods.getCompanies([id]); 
        setIsCaintraCompany(result.isCaintraCompany);  
        setCompany(result[0]);
    }

    const updateValue = async (value) => {
        await setIsAdminRole(value)
    }

    const updateUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const data = {
            companyId: currentCompanyId,
            label: e.target.elements.label.value,
            description: e.target.elements.description.value,
            _permissions: {
                "Dashboard": {
                    "view": true,
                },
                "Interns": {
                    "view": isAdminRole,
                    "approve": isAdminRole,
                    "create": isAdminRole,
                    "update": isAdminRole,
                    "delete": isAdminRole,
                },
                "Departments": {
                    "view": isAdminRole,
                    "create": isAdminRole,
                    "update": isAdminRole,
                    "delete": isAdminRole,
                },
                "Prefacture": {
                    "view": isAdminRole,
                    "approve": isAdminRole,
                    "update": isAdminRole,
                },
                "Dispersion": {
                    "view": isCaintraCompany,
                    "disperse": isCaintraCompany && isAdminRole,
                },
                "Pending": {
                    "view": isCaintraCompany,
                },
                "Companies": {
                    "view": isAdminRole,
                    "create": isAdminRole,
                    "update": isAdminRole,
                    "delete": isAdminRole && isCaintraCompany,
                    "approve": isAdminRole && isCaintraCompany,
                },
                "Reports": {
                    "view": isAdminRole
                },
                "Users": {
                    "view": isAdminRole,
                    "create": isAdminRole,
                    "update": isAdminRole,
                    "delete": isAdminRole,
                },
                "Roles": {
                    "view": isAdminRole,
                    "create": isAdminRole,
                    "update": isAdminRole,
                    "delete": isAdminRole,
                },
                "Universities": {
                    "view": isCaintraCompany,
                    "create": isCaintraCompany,
                    "update": isCaintraCompany,
                    "delete": isCaintraCompany,
                    "approve": isCaintraCompany,
                },
                "Notifications": {
                    "view": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "approve": false,
                }
            },
            isAdminRole: isAdminRole,
            isGroupRole: isGroupRole
        };

        if(data.label.trim() === ""){
            notify("El nombre del rol no puede estar vacío", "error");
            setIsLoading(false);
            return;
        };

        if(data.description.trim() === ""){
            notify("La descripción no puede estar vacía", "error");
            setIsLoading(false);
            return;
        };

        await Methods.createRole(data, true);
        setIsLoading(false);
        setUpdateRoles(!updateRoles);
        setShowCreateRole(false);
    };

    return(
        <Modal className="prefacture-modals" show={showCreateRole} onHide={() => setShowCreateRole(!showCreateRole)} centered>

            <Modal.Body>
                <p>NUEVO ROL</p>
                <Form onSubmit={updateUser} autoComplete={"off"}>
                    <Form.Group controlId="label">
                        <Form.Label>Nombre del rol</Form.Label>
                        <Form.Control type="text" placeholder="Introduce el nombre" />
                    </Form.Group>

                    <Form.Group controlId="description">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control type="text" placeholder="Introduce la descripción" />
                    </Form.Group>

                    <Row>
                        <Col className='roles-check-container '> 
                            <Form.Group controlId="isAdminRole" className='roles-check-center'>
                                <Form.Label>Rol Administrador</Form.Label>
                                <Form.Check type="checkbox" style={{width: "5%"}} 
                                    onChange={event => updateValue(event.target.checked)} 
                                />
                            </Form.Group>

                            <Form.Group controlId="isGroupRole" className='roles-check-center'>
                                <Form.Label>Rol Grupo</Form.Label>
                                <Form.Check type="checkbox" style={{width: "5%"}} 
                                    onChange={() => setIsGroupRol(!isGroupRole)} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Button className="btn-register float-right" variant="primary" type="submit">
                                {isLoading ? <Spinner animation="border" /> : "Crear Rol"}
                            </Button>

                            <Button className="float-right mr-3" variant="danger" onClick={() => setShowCreateRole(!showCreateRole)}>
                                Cancelar
                            </Button>  
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
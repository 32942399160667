import React, {useContext, useState} from 'react';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import {Modal, Button, Form, Image, Row, Col} from 'react-bootstrap';
import {notify} from "../../../utils/notify";
import {DateTimePicker} from 'react-widgets';
import {UserContext} from '../../../context/UserContext';
import { deletePosteriorPolicies } from '../../../utils/insuranceMethods';
import moment from 'moment';
const axios = require("axios");

export default function UnsuscribeIntern({setUpdateList, setShowUnsuscribeInternModal, showUnsuscribeInternModal, currentInternId, updateList, currentInternName, currentIntern}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);
    const [date, setDate] = useState(new Date());
    const setMomentDate = (date) => {
        if(date.includes("/")) return moment(date, "DD/MM/YYYY") 
        return moment(date);
    }
    const handleUnsuscribeIntern = async (e) => {
        e.preventDefault();

        if(!context.can("delete", "Interns")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        const motive = "Baja";
        const description = e.target.elements.description.value;

        if(motive.trim() == ""){
            notify("El motivo de la baja no puede estar vacío", "error");
            return;
        };

        if(description.trim() == ""){
            notify("La descripción de la baja no puede estar vacía", "error");
            return;
        };
        let unsuscribeDate = moment().startOf('day');

        
        const attributesToBeUpdated = {
            _id: currentInternId,
            attributes: []
        }

        const currentContractData = {
            _id: currentIntern.currentContractId,
            attributes: [
                {updateKey: "unsuscribeMotive", updateValue: motive},
                {updateKey: "unsuscribeDescription", updateValue: description},
                {updateKey: "unsuscribeDate", updateValue: moment(date).startOf('day').format()}
            ]
        };

        if(unsuscribeDate.diff(moment(date).startOf('day'), 'days') > 0 && currentIntern.internStatus == "Activo"){
            attributesToBeUpdated.attributes.push({updateKey: "internStatus", updateValue: "Inactivo"});
            attributesToBeUpdated.lastStatus = "Activo";
            currentContractData.attributes.push({updateKey: "contractStatus", updateValue: "Inactivo"});
        };

        let body = {};
        body = {
            intern_id: currentIntern._id,
            currentInsuranceId: false
        }


        const notification = {
            name: `Baja de practicante ${currentInternName}`,
            user: context.user.data.fullName,
            description: `Motivo: ${motive}. Descripción: ${description}. Usuario: ${context.user.data.email}` ,
            type: "Información",
            date: new Date()
        };

        try {

            if(context.user.company.interns_medical_insurance == "Sí"){
                let [company] = await Methods.getCompanies([currentIntern.id_company]);
                const insuranceByIntern = await Methods.getInternInsurance(body);
                let currentDate = moment(date).startOf('day').format();
                await deletePosteriorPolicies(insuranceByIntern.insurance, 'Inactivo', currentDate, currentIntern.currentContractId, currentIntern, company);
            }
            
            await axios({
                url: `${URL}/interns`,
                method: "PATCH",
                data: attributesToBeUpdated
            });

            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });

            await Methods.updateContractById(currentContractData);

            setShowUnsuscribeInternModal(!showUnsuscribeInternModal);
            setUpdateList(!updateList);


            notify("El practicante fue dado de baja satisfactoriamente", "success");
        } catch (e) {
            notify(e.response.data.message, "error");
        };
    
    };

    return (
        <Modal className="new-cc-modal" show={showUnsuscribeInternModal} onHide={() => setShowUnsuscribeInternModal(!showUnsuscribeInternModal)} centered>
            <Modal.Header>
                <h5>Dar de baja a practicante</h5>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleUnsuscribeIntern}>
                    <Row>
                        <Col>
                            <Form.Group controlId="unsuscribeDate">
                                <Form.Label>Selecciona la fecha de baja</Form.Label>
                                <DateTimePicker className="uns-date-picker" name="unsuscribeDate" format={'DD/MM/YYYY'} editFormat={'DD/MM/YYYY'} 
                                value={date} onChange={setDate} time={false} />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Form.Group controlId="description">
                        <Form.Label>Escribe el porqué</Form.Label>
                        <Form.Control className="description-input" type="text" autoComplete={"off"} placeholder="Escribe el porqué" required/>
                    </Form.Group>
                    
                    <div className='center-buttons'>
                        <Button variant="danger" type="button" onClick={() => setShowUnsuscribeInternModal(!showUnsuscribeInternModal)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Confirmar
                        </Button> 
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
export const DownloadDispersionFile = ({
    setFileName, 
    selectedBank,
    startDate,
    handleClose,
    txtLink
}) => {
    return (
        <Modal.Body>
            <h2> ¡Hecho! <FontAwesomeIcon  icon={faCheckCircle}/> </h2>
            <p>¡Ya puedes descargar tu documentación!</p>
            
            <a className="btn btn-primary" href={txtLink} download={`${setFileName(selectedBank, startDate)}.${selectedBank === "Banorte" || selectedBank === "Otros" ? "PAG" : "txt"}`} onClick={() => handleClose()}>
                    Descargar
                </a> 
        </Modal.Body>
    )
}

import React from 'react';
import {Modal, Form, Button} from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';
const uuid = require("uuid");

export default function CreateLeaderUserModal ({showCreateLeaderModal, setShowCreateUserModal, currentCompanyId, regexEmail, currentDepartmentId, setUpdateInfo, updateInfo}) {
    const context = useContext(UserContext)
    const createUser = async (event) => {
        event.preventDefault();
        let leaderEmail = event.target.elements.leaderEmail.value;
        
        let user = {
            fullName: event.target.elements.fullName.value,
            email: leaderEmail,
            companies: [currentCompanyId],
            role: "Sin Asignar",
            currentCompanyId: currentCompanyId,
            isCaintraUser: false,
            principalCompany: currentCompanyId,
            sendEmail: false,
            departmentId: currentDepartmentId,
            createPassword: true
        };
        
        
        if(!regexEmail.test(leaderEmail)){
            notify("El correo ingresado no es válido", "error"); 
            return;
        } 

        if(!currentDepartmentId && !context.user.company.isITESMCompany){
            notify(`Debes seleccionar un ${context.getDepartmentField()} para continuar el registro`, "error"); 
            return;
        } 
        try{
            if(context.user.company.isITESMCompany){
                let name = user.fullName;
                let email = user.email; 
                await Methods.createGeneralista({ name: name, email: email })
            }else{
                await Methods.adminRegisterUser(user);
            }
            setUpdateInfo(!updateInfo);
            setShowCreateUserModal(!showCreateLeaderModal);
        }catch(error){
            setShowCreateUserModal(!showCreateLeaderModal);
        }
    }

    return(
        <Modal className="new-cc-modal" show={showCreateLeaderModal} onHide={() => {setShowCreateUserModal(!showCreateLeaderModal)}} centered>

            <Modal.Body>
                <Form onSubmit={createUser}>
                    <Form.Label className='modal-header-styled'>Nuevo {context.getGeneralistaField()}</Form.Label>

                    <Form.Group controlId="fullName">
                        <Form.Control type="text" required  placeholder={`Nombre completo del ${context.getGeneralistaField()}`} className='input-cc'/>
                    </Form.Group>

                    <Form.Group controlId="leaderEmail">
                        <Form.Control type="text" required  placeholder={`Correo del ${context.getGeneralistaField()}`} className='input-cc'/>
                    </Form.Group>

                    <div className='center-buttons'>
                        <Button className="float-right mt-4" variant="danger" type="button" onClick={() => setShowCreateUserModal(!showCreateLeaderModal)} >
                            Cancelar
                        </Button>

                        <Button className="float-right mt-4 ml-2" variant="primary" type="submit">
                            Confirmar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
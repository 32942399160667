import React from 'react';
import {Modal, Form, Button} from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';

import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';

export default function NewCostCenterModal ({showNewCostCenterModal, setShowNewCostCenterModal, currentDepartmentId, setUpdateInfo, updateInfo, currentCompanyId, isITESMCompany}) {
    const context = useContext(UserContext);
    
    const newCostCenter = async (e) => {
        e.preventDefault(); 
        if(!currentDepartmentId){
            notify(`Debes seleccionar un ${context.getDepartmentField()} para continuar el registro`, 'error');
            return;
        }
        const cost_center = {
            id_department: currentDepartmentId,
            title: e.target.elements.title.value,
            key: e.target.elements.key.value,
            companyId: currentCompanyId,
            departmentField: context.getDepartmentField(),
        };

        try {
            if(isITESMCompany){
                await Methods.createCostCenterTec({ name: cost_center.title });
            }else{
                await Methods.createCostCenter(cost_center);
            }
            setUpdateInfo(!updateInfo);
            setShowNewCostCenterModal(!showNewCostCenterModal);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        };
    };

    return(
        <Modal className="new-cc-modal" show={showNewCostCenterModal} onHide={() => setShowNewCostCenterModal(!showNewCostCenterModal)} centered>
            <Modal.Body>
                <Form onSubmit={newCostCenter} autoComplete={"off"}>
                    
                    <Form.Label className='modal-header-styled'>Nuevo centro de costos</Form.Label>
                    <Form.Group controlId="title">
                        <Form.Control type="text" required  placeholder="Área" className='input-cc'/>
                    </Form.Group>

                    <Form.Group controlId="key">
                        <Form.Control type="text" required  placeholder="Clave del Centro de Costo" className='input-cc'/>
                    </Form.Group>

                    <div className='center-buttons'>
                        <Button className="float-right" variant="danger" type="button" onClick={() => setShowNewCostCenterModal(!showNewCostCenterModal)}>
                            Cancelar
                        </Button>

                        <Button className="float-right ml-2" variant="primary" type="submit">
                            Registrar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
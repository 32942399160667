import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export const DeleteFileConfirmation = ({
    onConfirmAction,
    label,
    onCancelAction,
    fileValues
}) => {
    return (
        <Modal.Body>
            <h3>¡Importante!</h3>
            <p style={{'fontWeight': '500'}}>{label}</p>
            <div className="btn-container">
                <Button 
                    className="float-right ml-2 mt-3" 
                    variant="primary"
                    onClick={() => {
                        onConfirmAction({
                            ...fileValues,
                            exist: false
                        });
                    }}
                >
                    Continuar
                </Button> 

                <Button 
                    className="float-right mt-3"
                    variant="danger" 
                    onClick={() => {
                        onCancelAction();
                    }}>
                    Cancelar
                </Button>
            </div>   
        </Modal.Body>
    )
}

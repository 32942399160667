import React, {useContext} from 'react';
import {Modal, Button, Form, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {UserContext} from '../../../context/UserContext';
import moment from 'moment';
const axios = require("axios");

export default function RollBackPrefacture({isApproved, setIsApproved, prefacture, period, showRollBackPrefacture, setShowRollBackPrefacture, updateList, setUpdateList, prefactureInterns, setIsPrefactureDetail, setPrefactureData, setIsEditable, setIsSending, allPrefactureQMInterns, canRollBackFirstQMPeriod, isConsolidationPrefacture, consolidationValidations}) {
    const context = useContext(UserContext);
    const company = context.user.company;
    const isCaintraUser = context.user.data.isCaintraUser;
    const userFullName = `${context.user.data.fullName}`;
    const userEmail = context.user.data.email;
    const role = context.user.data.role;
    const [startDate, endDate] = period;
    const formattedStartDate = moment(startDate).format("D/MM/YYYY");
    const formattedEndDate = moment(endDate).format("D/MM/YYYY");
    let internsToRollBack = ((consolidationValidations.isSecondQMPeriod || consolidationValidations.isSecondConsolidationPeriod) || isConsolidationPrefacture ) ? allPrefactureQMInterns : prefactureInterns;
    const rollBackPrefacture = async (e) => {
        e.preventDefault();
        let periodType = getFormatedPayment(context.user.company.payment_period);
        if((periodType == "Quincenal-Mensual" || periodType == "Quincenal-Mensual C" || consolidationValidations.isFirstConsolidationPeriod) &&
         !canRollBackFirstQMPeriod && (!consolidationValidations.isSecondQMPeriod && !consolidationValidations.isSecondConsolidationPeriod)){
            notify("No se puede realizar rollback si se tiene el segundo periodo autorizado ", "error");
            return;
        }

        let someoneIsDispersed = internsToRollBack.filter(internDisp => (internDisp.dispersionStatus == "Dispersado"));  
        
        if(someoneIsDispersed.length){
            notify("Esta prefactura ya ha sido dispersada", "error");
            return;
        }

        var today = new Date;
        
        if(!isCaintraUser && (startDate.getDate() < 15 && today.getDate() > 11 && today.getMonth() >= startDate.getMonth())) {
            notify("No se puede realizar rollback después de la fecha de corte", "error");
            return;
        }

        if(!isCaintraUser && (startDate.getDate() >= 15 && today.getDate() > 24 && today.getMonth() >= startDate.getMonth())) {
            notify("No se puede realizar rollback después de la fecha de corte", "error");
            return;
        }

        if(!context.can("approve", "Prefacture")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };
        const motive = e.target.elements.motive.value;

        const record = {
            message: `Roll-back de prefactura del periodo ${formattedStartDate} al ${formattedEndDate} motivo: ${motive}`,
            author: userFullName,
            role: role,
            companyId: company._id,
            createdAt: new Date(),
            authorEmail: userEmail,
        };

        await Methods.createRecord(record);
        await Methods.rollBackPrefacture(internsToRollBack);
        setIsPrefactureDetail(false);
        setIsEditable(true)
        setPrefactureData({
            totalCost: 0,
            administrativeExpense: 0,
            totalAdministrativeExpense: 0,
            subtotalNoInsurance: 0,
            insuranceCompanyData: 0,
            debitCompany: 0,
            subtotalInsurance: 0,
            creditCompany: 0,
            subtotalCredits: 0,
            _total: 0,
            iva: 0
        });
        setShowRollBackPrefacture(!showRollBackPrefacture);
        setIsApproved(!isApproved);
        setUpdateList(!updateList);
        setIsSending(false);
    };

    const getFormatedPayment = (payment_period)=>{
        const formatedPayment = {
            "CATORCENAL": 'Catorcenal',
            "QUINCENAL": 'Quincenal',
            "QUINCENAL MENSUAL":'Quincenal-Mensual',
            "MENSUAL 15": 'Mensual 15',
            "MENSUAL 30": 'Mensual 30',
            "MENSUAL 15 BAT": 'Mensual 15 BAT'
        }
        return formatedPayment[payment_period] || payment_period;
    }

    return(
        <Modal className="prefacture-modals" show={showRollBackPrefacture} onHide={() => setShowRollBackPrefacture(!showRollBackPrefacture)} centered>
            <Modal.Body>
                <Form onSubmit={rollBackPrefacture} autoComplete={"off"}>
                    <p>Estás a punto de dar roll-back a la prefactura</p>
                    <small>¡Recuerda revisar todos los datos y documentos antes de continuar!</small>

                    <Form.Group style={{marginTop: "20px"}} controlId="motive">
                        <Form.Control type="text" placeholder='Escribe el motivo aquí' required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setShowRollBackPrefacture(!showRollBackPrefacture)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};
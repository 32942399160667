import React, {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../../context/UserContext';
import Methods from '../../../utils/https';
import {Button, Row, Col, Form} from 'react-bootstrap';
import {motion} from 'framer-motion';
import {notify} from '../../../utils/notify';
import { BiArrowBack } from 'react-icons/bi';
import DefaultRoles from '../components/DefaultRoles'
import { decrypt } from "../../../utils/crypto";

const axios = require('axios');

export default function CreateCompany ({setTab, updateList, setUpdateList}) {
    const context = useContext(UserContext);
    const userId = context.user.data._id;
    const companies = context.user.data.companies;
    const [disable, setDisable] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhoneSec, setErrorPhoneSec] = useState(false);
    const [errorEmailSec, setErrorEmailSec] = useState(false);
    const [errorPhoneThr, setErrorPhoneThr] = useState(false);
    const [errorEmailThr, setErrorEmailThr] = useState(false);
    const [labelPhone, setLabelPhone] = useState("");
    const [labelEmail, setLabelEmail] = useState("");
    const [labelPhoneSec, setLabelPhoneSec] = useState("");
    const [labelEmailSec, setLabelEmailSec] = useState("");
    const [labelPhoneThr, setLabelPhoneThr] = useState("");
    const [labelEmailThr, setLabelEmailThr] = useState("");
    const [showAgent, setShowAgent] = useState(false);
    const [labelAgent, setLabelAgent] = useState("");
    const [errorAgent, setErrorAgent] = useState(false);
    const [errorRfc, setErrorRfc] = useState(false);
    const [labelErrorRFC, setLabelRFC] = useState("");
    const [currentState, setCurrentState] = useState("");
    const [currentSuburbs, setCurrentSuburbs] = useState([]);
    const [currentMunicipality, setCurrentMunicipality] = useState("");
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);

    const [formData, setFormData] = useState({
        business_name: "",
        corporative: "",
        line_of_business: "",
        number: "",
        municipality: "",
        zip_code: "",
        administrative_expense: "",
        _type: "Física",
        commercial_reason: "",
        social_reason: "",
        rfc: "",
        street: "",
        suburb: "",
        state: "",
        interns_medical_insurance: "Sí",
        insurance_payment: "Empresa",
        internship_bank: "Ninguno",
        payment_period: "Catorcenal",
        additional_data_prompt: "Sí",
        additional_data: "",
        legal_rep_fullname: "",
        legal_rep_phone: "",
        legal_rep_email: "",
        legal_rep_position: "",
        legal_rep_department: "",
        org_admin_fullname: "",
        org_admin_phone: "",
        org_admin_position: "",
        org_admin_email: "",
        org_admin_department: "",
        billing_contact_fullname: "",
        billing_contact_phone: "",
        billing_contact_email: "",
        billing_contact_position: "",
        billing_contact_department: "",
        int_number: "", 
        ext_number: "",
        client_number: ""
    });

    const [factureAditionalOptions, setFactureAditionalOptions] = useState([]) 
    const [factureAux, setFactureAux ] = useState({
        currentFacturePaymentType: undefined,
        currentCFDI: undefined,
        currentFacturePaymentMethod: undefined,
        currentFactureAgent: undefined,
        defaultValueFacturePayment: undefined
    })

    const {currentFacturePaymentType, currentCFDI, currentFacturePaymentMethod, currentFactureAgent} = factureAux


    useEffect(() => {
        getFactureOptions();
    }, []);

    const getFactureOptions = async() => {
        let prefactureOptionsAux = await Methods.getPrefactureOptions();
        if(prefactureOptionsAux.length){
            setFactureAditionalOptions(prefactureOptionsAux);
            setFactureAux({
                currentFacturePaymentType: setDefaulFactureOptionValue('facturePaymentType', prefactureOptionsAux),
                currentCFDI: setDefaulFactureOptionValue('factureCFDI', prefactureOptionsAux),
                currentFacturePaymentMethod: setDefaulFactureOptionValue('facturePaymentMethod', prefactureOptionsAux),
                currentFactureAgent: setDefaulFactureOptionValue('factureAgent', prefactureOptionsAux)
            });

            setFormData({
                ...formData, 
                facturePaymentType: setDefaulFactureOptionValue('facturePaymentType', prefactureOptionsAux, 'id'),
                factureCFDI: setDefaulFactureOptionValue('factureCFDI', prefactureOptionsAux, 'id'),
                facturePaymentMethod: setDefaulFactureOptionValue('facturePaymentMethod', prefactureOptionsAux, 'id'),
                factureAgent: setDefaulFactureOptionValue('factureAgent', prefactureOptionsAux, 'id')
            })
        }
    }


    const setDefaulFactureOptionValue = (type, prefactureOptionsAux, dataValue) => {
        for(let option of prefactureOptionsAux){
            if(option.type == type){
                if(dataValue == 'id'){
                    return option._id
                }
                return option;
            }
        }
    }

    const verifyRfc = async () => {
        const rfcVerificator =  new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/);
        const rfc = formData.rfc.toString();
        if(rfc == "XAXX010101000" || rfc == "XEXX010101000"){
            if (formData._type == "Moral") {
                setErrorRfc(false);
                setLabelRFC("");
            } else {
                setErrorRfc(true);
                setLabelRFC("El RFC genérico solo aplica para personas morales");
            }
        } else if(rfc.length > 0){
            if(formData._type == "Física"){
                if(rfc.length == 13){
                if(!rfcVerificator.test(formData.rfc)){
                    setErrorRfc(true);
                    setLabelRFC("El formarto del RFC ingresado es incorrecto");
                    
                } else {
                    setErrorRfc(false);
                    setLabelRFC("");       
                }
                } else {
                    setErrorRfc(true);
                    setLabelRFC("El RFC para personas físicas debe ser de 13 caracteres");
                }
            } else {
                if(rfc.length == 12 ){
                    if(!rfcVerificator.test(formData.rfc)){
                        setErrorRfc(true);
                        setLabelRFC("El formarto del RFC ingresado es incorrecto");

                    } else {
                        setErrorRfc(false);
                        setLabelRFC("");
                    }
                } else {
                    setErrorRfc(true);
                    setLabelRFC("El RFC para personas morales debe ser de 12 caracteres");
                }
            }  
        } else {
            setErrorRfc(false);
            updateKey("verifyData", false); 
        }
    };

    const getDataByZipCode = async (currentCP) => {
        let api = (await Methods.getApiData("sepomex"))[0];
        api.apiKey = JSON.parse(decrypt(api.apiKey))
        try {
             let response = await axios({
                 url: `${api.apiLink}${currentCP}?token=${api.apiKey}`,
                 method: "GET",
             });
             await setZipCodeData(response.data);
         } catch (e) {
             e.data && notify(e.data.response.message, "error");
         };
     }
 
     const setZipCodeData = async (data) => {
         let suburb = []; 
         if(data.length > 0){
             setCurrentMunicipality(data[0].response.municipio);
             setCurrentState(data[0].response.estado);
             await data.forEach(element =>{
                 const data = element.response.asentamiento;
                 suburb.push(data);
             });
         }
         setCurrentSuburbs(suburb);
     }
 
    const createNotification = async () => {
        const URL = process.env.REACT_APP_SOCKET_URL;
        const notification = {
            name: "Aprobación de empresas",
            user: "Administrador Caintra",
            description:`La empresa "${formData.business_name}" está en espera de aprobación`,
            type: " ",
            date: new Date()
        };
        try{
            
            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });

        } catch (error) {
            
        };
    };

    const verifyDataForm = async (setError, labelError, typeError, formDataName, errorN) => {

        if(typeError == "Email"){
          if(formDataName.length > 0){
            if(!regexEmail.test(formDataName)){
                await setError(true);
                await labelError("El correo no es válido");
            } else {
                await setError(false);
                await labelError("");
            }
          } else {
            await  setError(false);
          }
        } 
        if(typeError == "Phone"){
            if(formDataName.length > 0){
                if(formDataName.length == 10){
                    await setError(false)
                    await labelError("")
                }
                if(formDataName.length < 10 && formDataName.length >= 1){
                    await setError(true);
                    await labelError("El número de teléfono debe tener 10 dígitos");
                }
            } else {
                await setError(false);
                await labelError("El número de teléfono debe tener 10 dígitos");
            }
        }    
    };

    const handleFactureAgent = async (e) => {
        if(e.target.value != ""){
            let response = await Methods.getContpaqAgent(e.target.value);
            if(response != null){
                setLabelAgent(response.name);
                updateKey(e.target.id, e.target.value);
                setErrorAgent(false);
            } else {
                setLabelAgent("El numero de agente no existe en Contpaq")
                updateKey(e.target.id, "_");
                setErrorAgent(true);
            }
            setShowAgent(true);
        } else {
            updateKey(e.target.id, "_");
        }
    }

    const verifyAdditionalData = async() =>{
        if(formData.additional_data_prompt == "No"){
            document.getElementById('additional_data').disabled = true;
        }else{
            document.getElementById('additional_data').disabled = false;
        }
    };

    const updateKey = (keyToBeUpdated, valueToBeUpdated) => {

        let _data = formData;

        for(let key in _data){
            if(key === keyToBeUpdated){
                formData[key] = valueToBeUpdated;
            };
        };

        setFormData(_data);
    };

    const createDefaultRoles = async (companyId) => {
        const adminRoles = {key:"ADMINISTRADOR", value: DefaultRoles.AdminRole()};
        const groupRoles = {key:"GRUPO", value: DefaultRoles.GroupRole()};
        const companyRoles = {key:"EMPRESA", value: DefaultRoles.CompanyRole()};
        const corporativeRoles =  {key:"CORPORATIVO", value: DefaultRoles.CorporativeRole()}
        const rolesArray = [adminRoles, groupRoles, companyRoles, corporativeRoles]

        for(let index of rolesArray){
            let data = {
                companyId: companyId,
                label: index.key,
                description: `Roles predeterminados para ${index.key}` ,
                _permissions: index.value
            }
            await Methods.createRole(data, false);
        }
    }

    const handleCreateCompany = async () => {
        if(errorAgent){
            notify("El numero de agente no existe en Contpaq" , "error");
            setDisable(false);
            return;
        }
        if(formData.additional_data_prompt == "Sí" && formData.additional_data == ""){
            notify("No hay ningún dato adicional para la factura" , "error");
            setDisable(false);
            return;
        }
        if(errorPhone){
            notify("El número de teléfono ingresado en representante legal debe tener 10 dígitos" , "error");
            setDisable(false);
            return;
        }
        if(errorEmail){
            notify("El correo ingresado en representante legal no es válido" , "error");
            setDisable(false);
            return;
        }
        if(errorPhoneSec){
            notify("El número de teléfono ingresado en contacto principal de la empresa debe tener 10 dígitos" , "error");
            setDisable(false);
            return;
        }
        if(errorEmailSec){
            notify("El correo ingresado en contacto principal de la empresa no es válido" , "error");
            setDisable(false);
            return;
        }
        if(errorPhoneThr){
            notify("El número de teléfono ingresado en contacto para pago de factura debe tener 10 dígitos" , "error");
            setDisable(false);
            return;
        }
        if(errorEmailThr){
            notify("El correo ingresado en contacto para pago de factura no es válido" , "error");
            setDisable(false);
            return;
        }

        if(formData.business_name === ""){
            notify("¡Tienes que introducir al menos el nombre de la empresa", "error");
            setDisable(false);
            return;
        };

        if(formData.rfc == "" || formData.rfc == undefined){
            notify("La empresa requiere de un RFC para ser creada", "error");
            setDisable(false);
            return;
        }

        if(formData.client_number == "" || formData.client_number == undefined){
            notify("La empresa requiere de un número de cliente", "error");
            setDisable(false);
            return;
        }

        
        formData.municipality = currentMunicipality;
        formData.state = currentState;
        createNotification();

        let users = await Methods.getCaintraAdminUsers();
        let emailParams;
        if(users){
            let usersEmails = [];
            for (let index of users) {
                usersEmails.push(index.email);
            }

            emailParams = {
                to: usersEmails,
                subject: `Creación de compañia ${formData.business_name}`,
                text: `La compañía ${formData.business_name} fue creada, esta en espera de su aprobación.`
            }
        }

        const response = await Methods.createCompany(formData, userId, companies);
        if (response !== false && users){
            await Methods.notifyCompanyCreation(emailParams);
            createDefaultRoles(response.data._id)
        }
        await context.onLoad(context.user.data.email, context.token, true, true);
        setUpdateList(!updateList);
        setTab("listCompanies");
    };

    return(
        <motion.div initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }} className="company-form">
            <div className="title-container">
                <BiArrowBack onClick={() => setTab("listCompanies")}/> <h4>Alta de compañía</h4>
            </div>
            <Row>
                <Col>
                    <Form className="form-overflow" autoComplete={"off"}>
                        <Row>
                            <Col>
                                <p>Datos Generales</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="client_number">
                                    <Form.Control type="text" placeholder="Número de cliente" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.client_number} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="business_name">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre de la empresa"
                                        onChange={(e) => {
                                            e.target.value = e.target.value.toUpperCase();
                                            updateKey(e.target.id, e.target.value)
                                        }}
                                        defaultValue={formData.business_name}
                                    />
                                </Form.Group>
                                <Form.Group controlId="social_reason">
                                    <Form.Control type="text" placeholder="División comercial" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.social_reason}/>
                                </Form.Group>
        
                                <Form.Group controlId="corporative">
                                    <Form.Control type="text" placeholder="Corporativo" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.corporative}/>
                                </Form.Group>

                                <Form.Group controlId="line_of_business">
                                    <Form.Control type="text" placeholder="Giro" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.line_of_business}/>
                                </Form.Group>

                                <Form.Group controlId="street">
                                    <Form.Control type="text" placeholder="Calle" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.street}/>
                                </Form.Group>

                                <Form.Group controlId="int_number">
                                    <Form.Control type="text" placeholder="Número interno de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.int_number} />
                                </Form.Group>

                                <Form.Group controlId="municipality">
                                    <Form.Control type="text" placeholder="Municipio o delegación de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={currentMunicipality}/>
                                </Form.Group>

                                <Form.Group controlId="state">
                                    <Form.Control autoComplete={"off"} autocomplete="nope" type="text" placeholder="Estado de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={currentState}/>
                                </Form.Group>
                            </Col>


                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="_type">
                                    <Form.Control className="input" as="select" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyRfc();}}>
                                        <option hidden value>Tipo</option>
                                        <option selected={formData._type == "Física"}>Física</option>
                                        <option selected={formData._type == "Moral"}>Moral</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="commercial_reason">
                                    <Form.Control type="text" placeholder="Nombre Comercial" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.commercial_reason}/>
                                </Form.Group>

                                <Form.Group controlId="rfc">
                                    <Form.Control type="text" placeholder="Introduce la clave única (RFC)" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyRfc();}} defaultValue={formData.rfc} />
                                    { 
                                        errorRfc && <Form.Label className="error-label" > {labelErrorRFC} </Form.Label>
                                    }
                                </Form.Group>

                                <Form.Group controlId="payment_period">
                                    <Form.Control className="input" as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                        <option hidden value>Periodo de dispersión del practicante</option>
                                        <option selected={formData.payment_period == "Catorcenal"}>Catorcenal</option>
                                        <option selected={formData.payment_period == "Quincenal"}>Quincenal</option>
                                        <option selected={formData.payment_period == "Quincenal-Mensual"}>Quincenal-Mensual</option>
                                        <option selected={formData.payment_period == "Quincenal-Mensual C"}>Quincenal-Mensual C</option>
                                        <option selected={formData.payment_period == "Quincenal 2"}>Quincenal 2</option>
                                        <option selected={formData.payment_period == "Mensual 15"}>Mensual 15</option>
                                        <option selected={formData.payment_period == "Mensual 30"}>Mensual 30</option>
                                        <option selected={formData.payment_period == "Mensual 15 BAT"}>Mensual 15 BAT</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="ext_number">
                                    <Form.Control type="text" placeholder="Número externo de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.ext_number} />
                                </Form.Group>

                                <Form.Group controlId="zip_code">
                                    <Form.Control type="text" 
                                    onInput = {async (e) =>{
                                        e.target.value = (0,e.target.value).toString().slice(0,5)
                                        if(e.target.value.length == 5){
                                            await getDataByZipCode(e.target.value)
                                        }
                                    }}
                                    placeholder="Código postal de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.zip_code} />
                                </Form.Group>
                                                                        
                                <Form.Group controlId="suburb">
                                    <Form.Control className="input" as="select" placeholder="Colonia" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                    {currentSuburbs.map( element =>{
                                        return(
                                            <option>{element}</option>
                                        );
                                    })}
                                    </Form.Control>
                                </Form.Group>

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p>Características del Servicio</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="interns_medical_insurance">
                                    <Form.Label>
                                        ¿Desea contratar una póliza de seguro para los practicantes?
                                    </Form.Label>
                                    <Form.Control className="input" as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                        <option selected={formData.interns_medical_insurance == "Sí"}>Sí</option>
                                        <option selected={formData.interns_medical_insurance == "No"}>No</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="internship_bank">
                                    <Form.Label>Banco para depósito de beca</Form.Label>
                                    <Form.Control className="input" as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                        <option selected={formData.internship_bank == "Ninguno"}>Ninguno</option>
                                        <option selected={formData.internship_bank == "Banorte"}>Banorte</option>
                                        <option selected={formData.internship_bank == "Bancomer"}>Bancomer</option>
                                        <option selected={formData.internship_bank == "Santander"}>Santander</option>
                                        <option selected={formData.internship_bank =="Banamex"}>Banamex</option>
                                        <option selected={formData.internship_bank =="HSBC"}>HSBC</option>
                                        <option selected={formData.internship_bank =="Afirme"}>Afirme</option>
                                        <option selected={formData.internship_bank =="Banjio"}>Banjio</option>
                                        <option selected={formData.internship_bank =="Banjercito"}>Banjercito</option>
                                        <option selected={formData.internship_bank =="Bancoppel"}>Bancoppel</option>
                                        <option selected={formData.internship_bank =="Inbursa"}>Inbursa</option>
                                        <option selected={formData.internship_bank =="Scotiabank"}>Scotiabank</option>
                                        <option selected={formData.internship_bank =="Banco Azteca"}>Banco Azteca</option>
                                        <option selected={formData.internship_bank =="Banregio"}>Banregio</option>
                                        <option selected={formData.internship_bank =="Bancomext"}>Bancomext</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="insurance_payment">
                                    <Form.Label>Cobro de Seguro</Form.Label>
                                    <Form.Control className="input" as="select" placeholder="¿Empresa o practicante?" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.insurance_payment}>
                                        <option selected={formData.insurance_payment == "Empresa"}>Empresa</option>
                                        <option selected={formData.insurance_payment == "Practicante"}>Practicante</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="additional_data_prompt">
                                    <Form.Label>¿La factura requiere algún dato adicional?</Form.Label>
                                    <Form.Control   className="input" as="select" placeholder="¿Sí o no?" onChange={(e) => {updateKey(e.target.id, e.target.value);  verifyAdditionalData();}} defaultValue={formData.additional_data_prompt}>
                                    <option selected={formData.additional_data_prompt == "No"}>No</option>
                                        <option selected={formData.additional_data_prompt == "Sí"}>Sí</option>
                                    
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="additional_data">
                                    <Form.Label>¿Cuál?</Form.Label>
                                    <Form.Control type="text" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.additional_data} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="requireSife">
                                    <Form.Label>
                                        ¿Desea contratar la firma electrónica para los practicantes?
                                    </Form.Label>

                                    <Form.Control className="input"  as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                        <option disabled selected hidden> {formData.requireSife || 'No'}</option>
                                        <option selected={formData.requireSife == 'Sí'}>Sí</option>
                                        <option selected={formData.requireSife == 'No'}>No</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="requirePurchaseOrder">
                                    <Form.Label>
                                        ¿Necesita ingresar la OC antes de enviar su factura a timbrar?
                                    </Form.Label>

                                    <Form.Control className="input"  as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                        <option disabled selected hidden> {formData.requirePurchaseOrder || 'No'}</option>
                                        <option selected={formData.requirePurchaseOrder == 'Sí'}>Sí</option>
                                        <option selected={formData.requirePurchaseOrder == 'No'}>No</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="facturePaymentType">
                                    <Form.Label>
                                        Forma de pago
                                    </Form.Label>

                                    <Form.Control className="input" as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                        <option disabled selected hidden> {currentFacturePaymentType?.concept || ''}</option>
                                        {factureAditionalOptions.length && factureAditionalOptions.map((element, index) =>{
                                            if(element.type == 'facturePaymentType'){
                                                return(
                                                    <option id={index} value={element._id}  selected={formData.facturePaymentType == element._id}>{`${element.satKey} - ${element.concept}` }</option>
                                                );
                                            }
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="factureCFDI">
                                    <Form.Label>Uso de CFDI</Form.Label>
                                    <Form.Control className="input" as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                        <option disabled selected hidden> {currentCFDI?.concept || ''}</option>
                                        {factureAditionalOptions.length && factureAditionalOptions.map((element, index) =>{
                                            if(element.type == 'factureCFDI'){
                                                return(
                                                    <option id={index} value={element._id} selected={formData.factureCFDI == element._id}>{`${element.satKey} - ${element.concept}` }</option>
                                                );
                                            }
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="facturePaymentMethod">
                                    <Form.Label>
                                        Método de pago
                                    </Form.Label>

                                    <Form.Control className="input" as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                        <option disabled selected hidden> {currentFacturePaymentMethod?.concept || ''}</option>
                                        {factureAditionalOptions.length && factureAditionalOptions.map((element, index) =>{
                                            if(element.type == 'facturePaymentMethod'){
                                                return(
                                                    <option id={index} value={element._id}  selected={formData.facturePaymentMethod == element._id}>{`${element.satKey} - ${element.concept}` }</option>
                                                );
                                            }
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={6} xs={12} sm={12}>
                                <Form.Group controlId="factureAgent">
                                    <Form.Label>No de agente ejecutivo</Form.Label>
                                    <Form.Control type="text" onChange={(e) => handleFactureAgent(e)} defaultValue={"_"} />
                                    {
                                    showAgent && <Form.Label className="agent"> {labelAgent} </Form.Label>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p>Representante Legal</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="legal_rep_fullname">
                                    <Form.Control type="text" placeholder="Nombre completo del representante legal" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.legal_rep_fullname} />
                                </Form.Group>

                                <Form.Group controlId="legal_rep_phone">
                                    <Form.Control type="number" 
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }} 
                                    placeholder="Teléfono (10 dígitos)" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorPhone, setLabelPhone, "Phone", formData.legal_rep_phone, errorPhone);}} defaultValue={formData.legal_rep_phone} />
                                    {
                                        errorPhone && <Form.Label className="error-label" > {labelPhone} </Form.Label>
                                    }
                                </Form.Group>

                                <Form.Group controlId="legal_rep_email">
                                    <Form.Control type="email"
                                    placeholder="Correo electrónico del representante legal" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorEmail, setLabelEmail, "Email", formData.legal_rep_email, errorEmail); }} defaultValue={formData.legal_rep_email} /> 
                                    {
                                        errorEmail && <Form.Label className="error-label" > {labelEmail} </Form.Label>
                                    }                                   
                                </Form.Group>
                            </Col>

                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="legal_rep_position">
                                    <Form.Control type="text" placeholder="Puesto del representante legal" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.legal_rep_position}/>
                                </Form.Group>
                                <Form.Group controlId="legal_rep_department">
                                    <Form.Control type="text" placeholder={`${context.getDepartmentField(true)} del representante legal`} onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.legal_rep_department}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p>Contacto Principal de la Empresa con Caintra (Manejará el Sistema y se encargará de los Practicantes)</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="org_admin_fullname">
                                    <Form.Control type="text" placeholder="Nombre completo del administrador" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.org_admin_fullname}/>
                                </Form.Group>
            
                                <Form.Group controlId="org_admin_phone">
                                    <Form.Control type="number" 
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    placeholder="Teléfono del administrador" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorPhoneSec, setLabelPhoneSec, "Phone", formData.org_admin_phone, errorPhoneSec);}} defaultValue={formData.org_admin_phone}/>
                                    {
                                        errorPhoneSec && <Form.Label className="error-label" > {labelPhoneSec} </Form.Label>
                                    }
                                </Form.Group>

                                <Form.Group controlId="org_admin_email">
                                    <Form.Control type="email" placeholder="Correo del administrador" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorEmailSec, setLabelEmailSec, "Email", formData.org_admin_email, errorEmailSec);}} defaultValue={formData.org_admin_email}/>
                                    { 
                                        errorEmailSec && <Form.Label className="error-label" > {labelEmailSec}</Form.Label>
                                    }
                                </Form.Group>
                            </Col>

                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="org_admin_position">
                                    <Form.Control type="text" placeholder="Puesto del administrador" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.org_admin_position}/>
                                </Form.Group>
                                <Form.Group controlId="org_admin_department">
                                    <Form.Control type="text" placeholder={`${context.getDepartmentField(true)} del administrador`} onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.org_admin_department}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p>Contacto para pago de factura</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="billing_contact_fullname">
                                    <Form.Control type="text" placeholder="Nombre del contacto" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.billing_contact_fullname}/>
                                </Form.Group>
            
                                <Form.Group controlId="billing_contact_phone">
                                    <Form.Control type="number" 
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                    }}
                                    placeholder="Teléfono" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorPhoneThr, setLabelPhoneThr, "Phone", formData.billing_contact_phone, errorPhoneThr); }} defaultValue={formData.billing_contact_phone}/>
                                    { 
                                        errorPhoneThr && <Form.Label className="error-label" > {labelPhoneThr} </Form.Label>
                                    }   
                                </Form.Group>

                                <Form.Group controlId="billing_contact_email">
                                    <Form.Control type="email" placeholder="Correo del contacto" onChange={(e) => {updateKey(e.target.id, e.target.value);   verifyDataForm(setErrorEmailThr, setLabelEmailThr, "Email", formData.billing_contact_email, errorEmailThr);}} defaultValue={formData.billing_contact_email}/>
                                    { 
                                        errorEmailThr && <Form.Label className="error-label" > {labelEmailThr} </Form.Label>
                                    }   
                                </Form.Group>
                            </Col>

                            <Col md={6} sm={12} xs={12}>
                                <Form.Group controlId="billing_contact_position">
                                    <Form.Control type="text" placeholder="Puesto del contacto" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.billing_contact_position}/>
                                </Form.Group>
                                <Form.Group controlId="billing_contact_department">
                                    <Form.Control type="text" placeholder={`${context.getDepartmentField(true)} del contacto`} onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.billing_contact_department}/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <div className="btn-container">
                <Button className="float-right mt-4" variant="primary" type="button" disabled={disable} onClick={() => {setDisable(true);handleCreateCompany();}}>
                    Crear compañía
                </Button>
            </div>
         </motion.div>
    );
};
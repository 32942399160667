import React, {useState} from 'react';
import {Modal, Button, Form, Image, Spinner, Row, Col} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';

export default function DenyUserModal({currentUser, openDenyUserModal, setOpenDenyUserModal, updateList, setUpdateList}){
    const [isLoading, setIsLoading] = useState(false);

    const denyUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const confirmation = e.target.elements.confirmation.value;
        const reason = e.target.elements.reason.value;
        const motive = e.target.elements.motive.value;

        if(confirmation.trim().toLowerCase() != "confirmar"){
            notify("La palabra de confirmación es incorrecta, por favor intenta nuevamente", "error");
            setIsLoading(false);
            return;
        };

        if(motive.trim().toLowercase === ""){
            notify("Escribe un motivo válido por el cual estás rechazando el acceso a este usuario", "error");
            setIsLoading(false);
            return;
        };

        if(reason.trim().toLowercase === ""){
            notify("Escribe una razón válida por la cual estás rechazando el acceso a este usuario", "error");
            setIsLoading(false);
            return;
        };

        const emailParams = {
            to: currentUser.email,
            subject: `PRACTICANTES CAINTRA - Usuario Rechazado por ${motive}`,
            text: `Tu usuario ha sido rechazado debido a: ${reason}, `,
            userName: currentUser.fullName
        };

        await Methods.sendEmail(emailParams);
        await Methods.deleteUser(currentUser._id, currentUser.email);

        setIsLoading(false);
        setUpdateList(!updateList);
        setOpenDenyUserModal(false);
    };

    return(
        <Modal className="prefacture-modals" show={openDenyUserModal} onHide={() => setOpenDenyUserModal(!openDenyUserModal)} centered>

            <Modal.Body>                
                <Form onSubmit={denyUser} autoComplete={"off"}>
                    <Row className="d-flex flex-column mb-2">
                        <p>Rechazar a {currentUser.fullName}</p>
                        <small>Se enviará un correo para notificar al usuario</small>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group controlId="confirmation">
                                <Form.Label>Confirmación</Form.Label>
                                <Form.Control type="text" placeholder='Escribe "confirmar" aquí' required/>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId="motive">
                                <Form.Label>Motivo</Form.Label>
                                <Form.Control type="text" placeholder='Escribe el motivo aquí' required/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="reason">
                        <Form.Label>Razón</Form.Label>
                        <Form.Control type="text" placeholder='Escribe el porqué aquí' required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Confirmar"}
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setOpenDenyUserModal(!openDenyUserModal)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    )
}
import React, {useState, useEffect} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {DateTimePicker} from 'react-widgets';
import moment from 'moment';
import { notify } from '../../../utils/notify';
import { decrypt } from '../../../utils/crypto';
import { CompanyInternsTable } from './CompanyInternsTable';


export default function ListPrefactures({
    interns,
    payDate, 
    setPayDate,
    selected, 
    setSelected, 
    nextNumber,
    setNextNumber,
    selectedBank,
    setSelectedBank,
    dateToDisperse,
    setDateToDisperse,
    setSomeoneIsAlreadyDispersed,
    forceUpdate,
    getCompanyPaymentPeriod,
    companies,
    selectedCompanies,
    setCurrentPayDay,
    updateInternList,
    searchQuery
}){
    const [startDate, endDate] = payDate;
    const [filteredInterns, setFilteredInterns] = useState(undefined);

    useEffect(() => {
        getInternsByBank(selectedBank, searchQuery);
    }, [interns, updateInternList, searchQuery]);

    const getInternsByBank = async (currentBank, searchQuery) => {
        let bank = currentBank.toLowerCase();
        let filtered;
        setSelected([]);
        const banks = ["santander", "bancomer", "banorte", "ninguno"];


        await setChanges(interns);

        if(bank == "Otros"){
            filtered = interns.filter(intern => (!banks.includes(intern.bank.toLowerCase())));
        } else {
            filtered = interns.filter(intern => intern.bank.toLowerCase() == bank && intern.bank.toLowerCase() != "Ninguno");
        }

        if(searchQuery){
            filtered = interns.filter(intern => { if(intern.clabe && intern.clabe.includes(searchQuery)) return true })
        }

        filtered = filtered.filter(intern => { if(!intern.isConsolidationPrefacture)  return true});

        const internsByCompany = [];

        companies.map(company => {
            const _interns = filtered.filter(intern => company._id == intern.company_id);

            for(let _intern of _interns){
                _intern.companyName = company.business_name
            }

            (selectedCompanies.includes(company._id) && company.internship_bank.toLowerCase() == bank) && internsByCompany.push({
                companyName: company.business_name,
                interns: _interns,
                isSelected: false,
                isDisabled: company.isDisabled,
                dateToEndableDispersion: company.dateToEndableDispersion
            });
        });

        internsByCompany.sort(function(a, b){
            if(a.companyName < b.companyName) { return -1; }
            if(a.companyName > b.companyName) { return 1; }
            return 0;
        })


        let selectedAux = [];
        internsByCompany.forEach(company => {
            let disabledCompany = canCompanyDisperse(company);
            
            company.interns.forEach(intern => {
                intern.isSelected = false;
                intern.disabledCompany = disabledCompany;
                selectedAux.push(intern);
            });
        });
     
        
        
        let someoneIsDispersed = selectedAux.filter(internDisp => (internDisp.dispersionStatus == "Dispersado"));  
        let notSelectedAux = [];

        
        if(someoneIsDispersed.length){
            
            notSelectedAux = selectedAux.filter(internDisp => (internDisp.dispersionStatus == "Dispersado" || internDisp.total_amount == 0) || internDisp.disabledCompany === false);
          
            selectedAux = selectedAux.filter(internDisp => (
                internDisp.dispersionStatus != "Dispersado" && 
                internDisp.total_amount != 0 && 
                (internDisp.accountColorStatus == undefined || internDisp.accountColorStatus == '#000000'
            )));
        }else{
       
            notSelectedAux = notSelectedAux.filter(internDisp => (
                !internDisp.disabledCompany ||
                internDisp.dispersionStatus == "Dispersado" || 
                !internDisp.clabe || 
                internDisp.clabe == "N/A" || 
                internDisp.clabe == "-" || 
                internDisp.clabe == "_" || 
                internDisp.total_amount == 0 || 
                internDisp.disabledCompany === false
                
            ));
            selectedAux = selectedAux.filter(internDisp => (
                internDisp.disabledCompany &&
                internDisp.dispersionStatus != "Dispersado" && 
                internDisp.clabe && 
                internDisp.clabe != "-" && 
                internDisp.clabe != "_" && 
                internDisp.total_amount != 0 
                && (internDisp.accountColorStatus == undefined || internDisp.accountColorStatus == '#000000')
            ));  
        }
        
        notSelectedAux.length && notSelectedAux.forEach(internAux => {
            internAux.isSelected = false;
        });

     
        internsByCompany.length && internsByCompany.forEach(internAux => {
            for(let i=0 ; i<internAux.interns.length;i++){

                if( internAux.interns[i].disabledCompany === false ){
                    internAux.interns[i].isSelected = false;
                }
            }
        });
        
      
        selectedAux.length && selectedAux.forEach(internAux => {
            internAux.isSelected = true;
          
            if(!searchQuery){
                updateSelected(internAux, searchQuery, true);
            }else{
                updateSelected(internAux, searchQuery, false);
            }
        });

        setSelectedBank(currentBank);
        setFilteredInterns(internsByCompany);
        setSomeoneIsAlreadyDispersed(false);
        setSelected(selectedAux);
    };


    const canCompanyDisperse = (company) => {
        if(company.isDisabled) return false;

        let dispersionByDate = company.dateToEndableDispersion ? moment(company.dateToEndableDispersion, 'DD/MM/YYYY').isSameOrBefore(moment()) : true;
        
        return dispersionByDate;
    }

    const setChanges = async (interns) => {

        let currentBankAdjustments = localStorage.getItem("internAdjustments");
        if (!currentBankAdjustments) return;
        let decrypted = decrypt(currentBankAdjustments)
        let currentBankAdjustmentsAux = JSON.parse(JSON.parse(decrypted));
        currentBankAdjustments = currentBankAdjustmentsAux || [];
        if(interns.length && currentBankAdjustments && currentBankAdjustments.length){
            currentBankAdjustments.map( intern => {
                for(let account of interns){
                    if(intern._id == account._id){
                        account.clabe = intern.newClabeAdjustment
                    }
                }
            });  
        }
    };

    const handleChangePayDate = (date) => {
        setCurrentPayDay(moment(date).format("DD/MM/YYYY"));
        const newStartDate = new Date(date);
        const day = newStartDate.getDate();
        const year = newStartDate.getFullYear();
        const month = newStartDate.getMonth();
        let newEndDate;

        if(day >= 15) newEndDate = new Date(year, month, 0);
        if(day < 15) newEndDate = new Date(year, month, 15);

        setPayDate([newStartDate, newEndDate]);
    };

    const isSelected = (_id) => {
        for(let item of selected){
            if(item._id == _id) return true;
        };
    };

    const checkInterns = (intern, selectedIntern) => {
        return intern.clabe && selectedIntern.clabe && intern.clabe.length > 0 && intern._id != selectedIntern._id && intern.clabe === selectedIntern.clabe && intern.clabe != "N/A" && intern.clabe != "_" && intern.clabe != "-"
    }

    const updateSelected = (intern, searchQuery, keepLastUpdate) => {

        if((intern.isSelected && searchQuery) || (keepLastUpdate && intern.isSelected && !searchQuery)){
            return;
        }

        if(intern.accountColorStatus && intern.accountColorStatus != '#000000') {
            notify("No se puede seleccionar una cuenta que no ha sido validada por Logística." , "error");
            return;
        }

        for(let selectedIntern of selected){
            if(checkInterns(intern, selectedIntern)){
                notify("Cuenta o clabe interbancaria existente. Favor de revisar la información." , "error");
                return;
            }
        }

        if(isSelected(intern._id)){
            intern.isSelected = false;
            const filtered = selected.filter(item => item._id !== intern._id);
            setSelected(filtered);

            if(filtered.length == 0){
                setSomeoneIsAlreadyDispersed(false);
                return;
            };

            filtered.map(_intern => _intern.dispersionStatus == "Dispersado" ? setSomeoneIsAlreadyDispersed(true) : setSomeoneIsAlreadyDispersed(false));

            return;
        };
        intern.isSelected = true;
        intern.dispersionStatus == "Dispersado" && setSomeoneIsAlreadyDispersed(true);
        setSelected([...selected, intern]);
    };

    const preventMax = (e) => {
        if (nextNumber.length == 2) {
            e.preventDefault();
        } else if (nextNumber.length > 2) {
            setNextNumber(99);
        }
    }

    return(
        <Col style={{height: "auto", maxHeight: "75vh" ,overflowX: "auto", paddingLeft: 0}}>
            <Row>
                <Col md={12} xs={12} sm={12}>
                    <div className="options">
                        <Form.Group className="input" controlId="payDate">
                            <DateTimePicker placeholder="Fecha de Pago" value={startDate} onSelect={(date) => handleChangePayDate(date)} time={false} format={"DD/MM/YYYY"} />
                        </Form.Group>

                        <Form.Group controlId="costCenter">
                            <Form.Control as="select" onChange={(e) => getInternsByBank(e.target.value)}>
                                <option value={null} hidden>Banco</option>
                                <option value="Santander">Santander</option>
                                <option value="Bancomer">Bancomer</option>
                                <option value="Banorte">Banorte</option>
                                <option value="Banamex">Banamex</option>
                                <option value="HSBC">HSBC</option>
                                <option value="Afirme">Afirme</option>
                                <option value="Banjio">Banjio</option>
                                <option value="Banjercito">Banjercito</option>
                                <option value="Bancoppel">Bancoppel</option>
                                <option value="Inbursa">Inbursa</option>
                                <option value="Scotiabank">Scotiabank</option>
                                <option value="Banco Azteca">Banco Azteca</option>
                                <option value="Banregio">Banregio</option>
                                <option value="Bancomext">Bancomext</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="input" controlId="payDate">
                            <DateTimePicker placeholder="Fecha a dispersar" value={dateToDisperse} onCurrentDateChange={(date) => setDateToDisperse(date)} time={false} format={"DD/MM/YYYY"} />
                        </Form.Group>
                        
                        <Form.Group controlId="number">
                            <Form.Control placeholder="Número siguiente" type="number" value={nextNumber} min={90} max={99} autoComplete={"off"} onChange={(e) => setNextNumber(e.target.value)} onKeyPress={(e) => preventMax(e)}/>
                        </Form.Group>
                    </div>
                </Col>
            </Row>
            
            {filteredInterns && 
                <Row className='list-interns-container'>
                    <Col>
                        <CompanyInternsTable
                            filteredInterns={filteredInterns}
                            setSelected={setSelected}
                            selected={selected}
                            setSomeoneIsAlreadyDispersed={setSomeoneIsAlreadyDispersed}
                            forceUpdate={forceUpdate}
                            searchQuery={searchQuery}
                            updateSelected={updateSelected}
                            getCompanyPaymentPeriod={getCompanyPaymentPeriod}
                            startDate={startDate}
                            dateToDisperse={dateToDisperse}
                        />
                    </Col>
                </Row>
            }
        </Col>
    );
};
import { getLiberationEmailText } from "./liberationEmailText";
import { getSuspensionEmailText } from "./suspensionEmailText"
import Methods from "../../../utils/https";
import { notify } from "../../../utils/notify";
export const sendNotifications = async (
    companies,
    selectedPayments
) => {

    const emailAdvisers = [];
    const ccp = ['angie.martinez@caintra.org.mx', 'blanca.arciva@caintra.org.mx', 'mazani.abad@caintra.org.mx', 'brenda.vazquez@caintra.org.mx', 'frida.delgado@caintra.org.mx', 'maria.cesena@caintra.org.mx', 'cesar.alvarez@caintra.org.mx', 'lilia.rivera@caintra.org.mx', 'ana.rodriguez@caintra.org.mx', 'facturacionycobranza@caintra.org']
    
    if(companies?.length == 0){
        notify('No hay compañías seleccionadas', 'error');
        return;
    }
   
    for(let company of companies){

        try{

            let emailsToNotify = [];

            emailAdvisers.forEach( item =>{
                emailsToNotify.push(item);
            });
            if(company.billing_contact_email?.trim() != ''){
                emailsToNotify.push(company.billing_contact_email.trim());
            }
            if(company.org_admin_email?.trim() != ''){
                emailsToNotify.push(company.org_admin_email.trim());
            }

            for(let index in company.factureContacts){
                emailsToNotify.push(company.factureContacts[index].email.trim()); 
            }

            emailsToNotify.push("facturacionycobranza@caintra.org");
          
           if(company?.factureContacts){
            company?.factureContacts.map( (item) =>{
              
                if(item.email?.trim() !=''){
                   
                    emailsToNotify.push(item.email);    
                }
            });
           }
            
          
          

            let emailParams = {
                to: emailsToNotify,
                ccp: ccp,
                subject: '',
                text: '',
                userName: 'delete',
                prefactureUrl : [],
                prefactureUrlXml : []
            }
    
            
            if(company.isDisabled){
                let payments = selectedPayments.filter(selectedPayment => selectedPayment.companyId == company._id) || [];
                emailParams.subject = 'Aviso de suspensión'
                emailParams.text = getSuspensionEmailText(payments, company.business_name);
               
                for(let i =0 ; i< payments.length ; i++){
                    emailParams.prefactureUrl[i] =  payments[i].prefactureUrl;
                    emailParams.prefactureUrlXml[i] =  payments[i].prefactureUrlXml;
                }
                console.log(emailParams);
                 
            }else{
                emailParams.subject = 'Aviso de liberación'
                emailParams.text = getLiberationEmailText(company.dateToEndableDispersion, company.business_name)
            }
            
       
            await Methods.sendEmail(emailParams);

        }catch(error){
            notify(`Problema al realizar el envío, empresa ${company.business_name}`+ error, 'success');
        }
    }

    notify('Notificaciones enviadas', 'success');
}

import Methods from "../../../utils/https"
import { getFormatedAmount } from "./getFormatedAmount";
export const updateCompanyValue = async(
    companySelected,
    companiesArray, 
    setCompanies,
    allCompanies,
    context,
    keyToUpdate
) => {
    

    setCompanies({
        allCompanies: allCompanies,
        companiesByPage: companiesArray
    });
    const attributesToBeUpdated = {
        _id: companySelected._id,
        attributes: [
            {updateKey: keyToUpdate, updateValue: companySelected[keyToUpdate]},
        ]
    };
    

    await Methods.updateCompany(attributesToBeUpdated);

    let companyRecordLabel = {
        'isDisabled': companySelected.isDisabled ? 'Suspención de empresa' : 'Liberación de empresa',
        'amountToEnable': `Actualización de monto a: ${getFormatedAmount(companySelected.amountToEnable)}`,
        'dateToEndableDispersion': `Actualización fecha de liberación: ${companySelected.dateToEndableDispersion}`
    }

    if(companyRecordLabel[keyToUpdate]){

        const historyRecord = {
            companyId: companySelected._id, 
            desactivationComments: companySelected.desactivationComments,
            createdBy: context.user.data.fullName,
            createdByEmail: context.user.data.email,
            action: companyRecordLabel[keyToUpdate]
        }
    
        await Methods.createDesactivationHistoryRecord(historyRecord)
    };

}
import React, { useState, useEffect} from 'react';
import { Button, Form } from "react-bootstrap";
import Methods from "../../../utils/https";
import { encrypt, decrypt } from "../../../utils/crypto";


export default function Apis (){
    const [sife, setSife] = useState({
        link: "",
        key: ""
    });
    const [segutrends, setSegutrends] = useState({
        link: "",
        key: ""
    });
    const [sepomex, setSepomex] = useState({
        link: "",
        key: ""
    });
    const [contpaq, setContpaq] = useState({
        link: "",
        key: ""
    });

    useEffect(()=>{
        getApi();
    }, []);

    const getApi = async () => {
        let result = await Methods.getApiData();
        for(let aux of result){
            if(aux.app === "sife"){
                setSife({link: aux.apiLink, key: JSON.parse(decrypt(aux.apiKey)), keyAux: getKeyAux(JSON.parse(decrypt(aux.apiKey)))})
            }else if(aux.app === "segutrends"){
                setSegutrends({link: aux.apiLink, key: JSON.parse(decrypt(aux.apiKey)), keyAux: getKeyAux(JSON.parse(decrypt(aux.apiKey)))})
            }else if(aux.app === "sepomex"){
                setSepomex({link: aux.apiLink, key: JSON.parse(decrypt(aux.apiKey)), keyAux: getKeyAux(JSON.parse(decrypt(aux.apiKey)))})
            }else if(aux.app === "contpaq"){
                setContpaq({link: aux.apiLink, key: JSON.parse(decrypt(aux.apiKey)), keyAux: getKeyAux(JSON.parse(decrypt(aux.apiKey)))})
            }
        }
    }

    const getKeyAux = (key) =>{
        if(key.length > 10){
            return key.slice(0, 10)
        }else{
            return key;
        }
    }

    const sendSifeData = (event) => {
        event.preventDefault();
        let link = event.target[0].value
        let key;
        if(sife.keyAux === event.target[1].value){
            key = encrypt(sife.key)
            setSife({link: link, key: sife.key, keyAux: sife.keyAux})
        }else{
            key = encrypt(event.target[1].value)
            setSife({link: link, key: key, keyAux: getKeyAux(key)})
            
        }
        Methods.saveApiData({link: link, key: key, app: "sife"})
    }
    const sendSegutrendsData = (event) => {
        event.preventDefault();
        let link = event.target[0].value
        let key;
        if(segutrends.keyAux === event.target[1].value){
            key = encrypt(segutrends.key)
            setSegutrends({link: link, key: segutrends.key, keyAux: segutrends.keyAux})
        }else{
            key = encrypt(event.target[1].value)
            setSegutrends({link: link, key: key, keyAux: getKeyAux(key)})
            
        }
        Methods.saveApiData({link: link, key: key, app: "segutrends"})
        
    }
    const sendSepomexData = (event) => {
        event.preventDefault();
        let link = event.target[0].value
        let key;
        if(sepomex.keyAux === event.target[1].value){
            key = encrypt(sepomex.key)
            setSepomex({link: link, key: sepomex.key, keyAux: sepomex.keyAux})
        }else{
            key = encrypt(event.target[1].value)
            setSepomex({link: link, key: key, keyAux: getKeyAux(key)})
            
        }
        Methods.saveApiData({link: link, key: key, app: "sepomex"})
    }
    const sendContpaqData = (event) => {
        event.preventDefault();
        let link = event.target[0].value
        let key;
        if(contpaq.keyAux === event.target[1].value){
            key = encrypt(contpaq.key)
            setContpaq({link: link, key: contpaq.key, keyAux: contpaq.keyAux})
        }else{
            key = encrypt(event.target[1].value)
            setContpaq({link: link, key: key, keyAux: getKeyAux(key)})
            
        }
        Methods.saveApiData({link: link, key: key, app: "contpaq"})
    }

    return(
        <div className="api-container">
            <div style={{padding: "15px"}}>
                
                <Form onSubmit={sendSifeData}>
                    <Form.Label >SIFE</Form.Label>
                    <div className="api-group">
                        <Form.Group >
                            <Form.Label>API</Form.Label>
                            <Form.Control id="sifeLink" className="api" type="text" placeholder="API Link" style={{width: "550px"}} defaultValue={sife.link}/>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>API Key</Form.Label>
                            <Form.Control id="sifeKey" className="api" type="text" placeholder="API Key" style={{width: "200px"}} defaultValue={sife.keyAux}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testApi("sife")} >Probar</Button>
                        </div>
                    </div>
                </Form>
            </div>
            <div style={{padding: "15px"}}>
                <Form onSubmit={sendSegutrendsData}>
                    <Form.Label>Segutrends</Form.Label>
                    <div className="api-group">
                        <Form.Group>
                            <Form.Label>API </Form.Label>
                            <Form.Control id ="segutrendsApi" className="api" type="text" placeholder="API Link" style={{width: "550px"}} defaultValue={segutrends.link}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label >API Key</Form.Label>
                            <Form.Control id ="segutrendsKey" className="api" type="text" placeholder="API Key" style={{width: "200px"}} defaultValue={segutrends.keyAux}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testApi("segutrends")}>Probar</Button>
                        </div>
                    </div> 
                </Form>
            </div>
            <div style={{padding: "15px"}}>
                <Form onSubmit={sendSepomexData}>
                    <Form.Label>Sepomex</Form.Label>
                    <div className="api-group">
                        <Form.Group>
                            <Form.Label>API</Form.Label>
                            <Form.Control id="sepomexApi" className="api" type="text" placeholder="API Link" style={{width: "550px"}} defaultValue={sepomex.link}/>
                        </Form.Group>    
                        <Form.Group>
                            <Form.Label>API Key</Form.Label>
                            <Form.Control id="sepomexKey" className="api" type="text" placeholder="API Key" style={{width: "200px"}} defaultValue={sepomex.keyAux}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testApi("sepomex")}>Probar</Button>
                        </div>
                    </div>
                </Form>
            </div>
            <div style={{padding: "15px"}}>
                <Form onSubmit={sendContpaqData}>
                    <Form.Label>Contpaq</Form.Label>
                    <div className="api-group">
                        <Form.Group>
                            <Form.Label>API</Form.Label>
                            <Form.Control id="contpaqApi" className="api" type="text" placeholder="API Link" style={{width: "550px"}} defaultValue={contpaq.link}/>
                        </Form.Group>    
                        <Form.Group>   
                            <Form.Label>API Key</Form.Label>
                            <Form.Control id="contpaqKey" className="api" type="text" placeholder="API Key" style={{width: "200px"}} defaultValue={contpaq.keyAux}/>
                        </Form.Group>
                        <div>
                            <Button className="ButtonTest" type="submit">Guardar</Button>
                            <Button className="ButtonTest" onClick={async () => await Methods.testApi("contpaq")}>Probar</Button>
                        </div>
                    </div>  
                </Form>
            </div>
        </div>
    );
}
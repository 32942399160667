import React, {useEffect, useState, useRef} from 'react'
import ReactApexChart from 'react-apexcharts';
import Methods from '../../../utils/https';
import masculine from "../../../assets/IconsCaintra/masculine.svg";
import femenine from "../../../assets/IconsCaintra/femenine.svg";
import {DownloadDashboard} from '../components/DownloadDashboard';
import historyDashboard from "../../../assets/IconsCaintra/historyDashboard.svg";
import {Spinner} from 'react-bootstrap';
import moment from 'moment';
export const GenderDashboard = ({
    selectedCompanies,
    periodSelected,
    periodSelectedToCompare,
    getPeriodString,
    context,
    monthsToDownload,
    refsAux,
    setGenderExcelValues
}) => {
    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
    );
    const [genderM, setGenderM] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0 ,0 ,0]);
    const [genderH, setGenderH] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0 ,0 ,0]);
    const [maxValue, setMaxValue] = useState(0)
    const categories = [
        "30",
        "29",
        "28",
        "27",
        "26",
        "25",
        "24",
        "23",
        "22",
        "21",
        "20",
        "19",
        "18",
        "17",
        "16",
    ];
    const [excelValues, setExcelValues] = useState([{}])
    const [total, setTotal] = useState({
        H:0,
        M:0,
        comparisonM: 0,
        comparisonH: 0
    }); 
    const [totalToCompare, setTotalToCompare] = useState({ 
        maxPositiveIndex:0,
        maxNegativeIndex: 0,
        genderH: 0,
        genderM: 0,
        genderHcomparison: 0,
        genderMcomparison: 0,
        genderHTotal: 0,
        genderMTotal: 0
    });
    const exportRef = useRef(null);
    const [excelConfigurations, setExcelConfigurations] = useState([
        {key: 'month', label: 'Mes'}, 
        {key: 'gender', label: 'Sexo'},
        {key: '16', label: '16'},
        {key: '17', label: '17'},
        {key: '18', label: '18'},
        {key: '19', label: '19'},
        {key: '20', label: '20'},
        {key: '21', label: '21'},
        {key: '22', label: '22'},
        {key: '23', label: '23'},
        {key: '24', label: '24'},
        {key: '25', label: '25'},
        {key: '26', label: '26'},
        {key: '27', label: '27'},
        {key: '28', label: '28'},
        {key: '29', label: '29'},
        {key: '30', label: '30'},
        {key: 'total', label: 'total'}
    ]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const dateRef = useRef();
    const downloadRef = useRef();

    useEffect(() => {
        getAndSetAllData();
    }, [periodSelected, selectedCompanies, periodSelectedToCompare]);


    useEffect(() => {
        getExcelData();
    }, [periodSelected, selectedCompanies, monthsToDownload]);


    useEffect(() => {
        refsAux.componentRef.current[0] = exportRef.current;
        refsAux.dateRef.current[0] = dateRef.current;
        refsAux.downloadRef.current[0] = downloadRef.current;
    }, [exportRef.current, dateRef.current, downloadRef.current]);


    const getAndSetAllData = async () => {
        setIsLoading(true);
        
        let [currentGenderTotal, currentMaxValue, currentGenderH, currentGenderM, dataByCurrentMonth] = await getDataByMonthAndCompany(periodSelected);
        
        if(periodSelectedToCompare){
            let [compareGenderTotal, compareMaxValue, compareGenderH, compareGenderM] = await getDataByMonthAndCompany(periodSelectedToCompare);
            currentGenderTotal = {
                M: currentGenderTotal.M,
                H: currentGenderTotal.H,
                comparisonM: compareGenderTotal.M,
                comparisonH: compareGenderTotal.H
            }
            let genderHSubtracted = substractValues(currentGenderH, compareGenderH);
            let genderMSubtracted = substractValues(currentGenderM, compareGenderM);
            let maxPositiveIndex = Math.max(...currentGenderH, ...currentGenderM, ...compareGenderM, ...compareGenderH);
            let maxNegativeIndex = Math.min(...genderHSubtracted, ...genderMSubtracted);

            setTotalToCompare({
                maxPositiveIndex: maxPositiveIndex < 0 ? 0 : maxPositiveIndex,
                maxNegativeIndex: maxNegativeIndex,
                genderH: currentGenderH,
                genderM: currentGenderM,
                genderHComparasion: compareGenderH,
                genderMComparasion: compareGenderM,
                genderHTotal: genderHSubtracted,
                genderMTotal: genderMSubtracted
            });
        }

        setTotal(currentGenderTotal);
        setMaxValue(parseInt(currentMaxValue));
        setGenderH(changeToNegative(currentGenderH));
        setGenderM(currentGenderM);
        setIsLoading(false);
    }

    const getExcelData = async() => {
        setIsLoadingExcel(true);
        let promises = [];
        for(let selectedCompany of selectedCompanies){
            promises.push(Methods.getGenderDataByCompanyMonth(selectedCompany, parseInt(periodSelected), monthsToDownload));
        }

        let results = await Promise.all(promises);
        let excelValuesAux = [];

        for(let company of results){
            let companyExcelValue = {}
            let companyAux = context?.user?.data?.companiesByName?.find(
                (companyAux) => companyAux._id === company[0].companyId
            );

            companyExcelValue.name = companyAux?.business_name;
            companyExcelValue.values = [];

            for(let dataByMonth of company){
                let genderHValues = {
                    month: getPeriodString(dataByMonth.month), 
                    gender: 'H',
                    total: dataByMonth?.dataSortedH?.reduce((a, b) => a + b, 0) || 0
                }

                let genderMValues = {
                    month: getPeriodString(dataByMonth.month), 
                    gender: 'M',
                    total: dataByMonth?.dataSortedM?.reduce((a, b) => a + b, 0) || 0
                }

                for(let i = 0; i < 15; i++){
                    genderHValues[(30 - i).toString()] = dataByMonth?.dataSortedH?.[i] || 0;
                    genderMValues[(30 - i).toString()] = dataByMonth?.dataSortedM?.[i] || 0;
                }

                companyExcelValue.values.push(genderHValues);
                companyExcelValue.values.push(genderMValues);
            }
            excelValuesAux.push(companyExcelValue);
        }
        setExcelValues(excelValuesAux);
        setGenderExcelValues({
            excelValues: excelValuesAux,
            excelConfigurations: excelConfigurations
        });
        setIsLoadingExcel(false);
    }

    const substractValues = (current, toSubstract) => {
        let result = current.map((item, index) => {
            return item - toSubstract[index];
        })
        return result.reverse()
    }

    const getDataByMonthAndCompany = async(periodSelectedAux) => {
        
        let promises = [];
        let genderHAux = [];
        let genderMAux = [];
        for(let selectedCompany of selectedCompanies){
            promises.push(Methods.getGenderDataByCompanyMonth(selectedCompany, parseInt(periodSelectedAux)));
        }
        let results = await Promise.all(promises);
        for(let companyData of results){
            for(let monthData of companyData){
                if(monthData?.dataByAge){
                    for(let i = 0; i < 15; i++){
                        genderMAux[i] = genderMAux[i] ? genderMAux[i] + monthData.dataSortedM[i] : monthData.dataSortedM[i];
                        genderHAux[i] = genderHAux[i] ? genderHAux[i] + monthData.dataSortedH[i] : monthData.dataSortedH[i];
                    }
                }
            }
        }
        
        let value = Math.max(...genderHAux, ...genderMAux);

        return [
            {
                H: genderHAux?.reduce((a, b) => a + b, 0) || 0,
                M: genderMAux?.reduce((a, b) => a + b, 0) || 0
            },
            value,
            genderHAux,
            genderMAux
        ]
    }
    
    const changeToNegative = (arr) => {
        if(periodSelectedToCompare){
            return arr.reduce((acc, val) => {
                const negative = val <= 0 ? val : val * 1;
                return acc.concat(negative);
             }, []);
        }
        else{

            return arr.reduce((acc, val) => {
               const negative = val <= 0 ? val : val * -1;
               return acc.concat(negative);
            }, []);
        }
    };

    const getTickAmount = (maxNumber) => {
        let result = [];
        for (let i = 2; i < maxNumber; i++) {
            let res = maxNumber % i;
            if (res === 0) {
                result.push(i);
            }
        }

        if (result.length === 0) {
            return 12;
        } else {
            result.sort((a, b) => {
                return b - a;
            });
            for (let number of result) {
                if (number <= 10 && number % 2 == 0 && number >=4 ) {
                    return number;
                }
            }
            return 12;
        }
    };

    const tickAmountAux = (maxValueAux) => {
        let amount = {
            0: 1,
            1: 2,
            2: 4,
            3: 6,
            4: 8, 
            5: 8,
            6: 6,
            7: 6,
            8: 8,
            9: 6,
            10: 12
        }
        return amount[maxValueAux] || 0;
    }
   
    let genderAge = {
        series: [
            {
                name: "Masculino",
                data: genderH,
            },
            {
                name: "Femenino",
                data: genderM,
            }
        ],
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false
            },
        },
        colors: ["#00AEEF", "#E883C8"],
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: "100%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },

        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            borderColor: '#EBEBEB',
            strokeDashArray: 3,
        },
        yaxis: {
            min: -maxValue,
            max: maxValue,
            title: {
                text: 'Edad',
            },
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return 'Edad: ' + val;
                },
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val);
                },
            },
        },
        xaxis: {
            categories: categories,
            tickAmount: maxValue > 10 ? getTickAmount(maxValue) : tickAmountAux(maxValue),
            title: {
                text: 'Cantidad',
            },
            labels: {
                formatter: function (val) {
                    return Math.abs(Math.round(val));
                },
            },
        },
        legend: {
            show: false,
        }
    };

    let genderAgeLine = {
        series: [
            {
                name: "Hombres " + getPeriodString(periodSelected),
                data: totalToCompare.genderH,
            },
            {
                name: "Hombres " + getPeriodString(periodSelectedToCompare),
                data: totalToCompare.genderHComparasion,
            },
            
            {
                name: "Mujeres " + getPeriodString(periodSelected),
                data: totalToCompare.genderM,
            },
            {
                name: "Mujeres " + getPeriodString(periodSelectedToCompare),
                data: totalToCompare.genderMComparasion,
            },
            
        ],
        chartOptions: {
            chart: {
                type: 'bar',
                height: 100,
                distributed: true,
                stacked : false
            },
            plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: '100%',
                  dataLabels: {
                    position: 'top',
                    style: {
                        colors: ['#333']
                    },
                    offsetX: 30
                  },
                  
                }
                
              },
              dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                  fontSize: '12px',
                  colors: ['#fff']
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },

              tooltip: {
                enabled: true,
                followCursor: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                  let arrow1 = "";
                  let arrow2 = "";
                  if(w["globals"]["initialSeries"][0].data[dataPointIndex] -w["globals"]["initialSeries"][1].data[dataPointIndex] > 0){
                    arrow1 = "https://cdn-icons-png.flaticon.com/512/3148/3148312.png";
                  }
                  else if(w["globals"]["initialSeries"][0].data[dataPointIndex] -w["globals"]["initialSeries"][1].data[dataPointIndex] < 0){
                    arrow1 = "https://cdn-icons-png.flaticon.com/512/5548/5548112.png";
                 }
                 else{
                    arrow1 = "https://cdn-icons-png.flaticon.com/512/9068/9068779.png ";
                 }
       
                  if(w["globals"]["initialSeries"][2].data[dataPointIndex] -w["globals"]["initialSeries"][3].data[dataPointIndex]>0){
                    arrow2 = "https://cdn-icons-png.flaticon.com/512/3148/3148312.png";
                  }
                  else if(w["globals"]["initialSeries"][2].data[dataPointIndex] -w["globals"]["initialSeries"][3].data[dataPointIndex]<0){
                    arrow2 = "https://cdn-icons-png.flaticon.com/512/5548/5548112.png";
                 }
                 else{
                    arrow2 = "https://cdn-icons-png.flaticon.com/512/9068/9068779.png ";
                 }
                    return `

                    <div class="row header">
                        <div class="col">Tipo</div>
                        <div class="col">Periodo</div>
                        <div class="col">Cantidad</div>
                        <div class="col">Periodo</div>
                        <div class="col">Cantidad</div>
                        <div class="col">Resultado</div>
                    </div>
                    <div class="row">
                        <div class="col"><img width="10" src="/static/media/masculine.d155c221.svg" ></div>
                        <div class="col">${w["globals"]["initialSeries"][0].name}:</div>
                        <div class="col"><strong>${w["globals"]["initialSeries"][0].data[dataPointIndex]}</strong></div>
                        <div class="col">Menos (-) ${w["globals"]["initialSeries"][1].name}:</div>
                        <div class="col"><strong>${w["globals"]["initialSeries"][1].data[dataPointIndex]}</strong></div>
                        <div class="col">  = <strong>${w["globals"]["initialSeries"][0].data[dataPointIndex] -w["globals"]["initialSeries"][1].data[dataPointIndex]} <img width="10" src="${arrow1}"></strong></div>
                    </div>

                    <div class="row">
                        <div class="col"><img width="13" src="/static/media/femenine.ae011d4a.svg" ></div>
                        <div class="col">${w["globals"]["initialSeries"][2].name}:</div>
                        <div class="col"><strong>${w["globals"]["initialSeries"][2].data[dataPointIndex]}</strong></div>
                        <div class="col">Menos (-) ${w["globals"]["initialSeries"][3].name}:</div>
                        <div class="col"><strong>${w["globals"]["initialSeries"][3].data[dataPointIndex]}</strong></div>
                        <div class="col">  = <strong>${w["globals"]["initialSeries"][2].data[dataPointIndex] -w["globals"]["initialSeries"][3].data[dataPointIndex]} <img width="10" src="${arrow2}"></strong></div>
                    </div>


                    
                    `
                  }
          
              },
         
            colors: [ '#00a3f2', '#8fdefc', '#fe5dc5', '#ffb5e3' ],
           
           
            
            xaxis: {
                title: {
                    text: "Cantidad",
                },
                categories: [30,29,28,27,26,25,24,23,22,21,20,19,18,17,16],
            },
            yaxis: {
                title: {
                    text: "Edad",
                },
                position: "bottom",
                min: 0,
                max: totalToCompare.maxPositiveIndex,
                labels: {
                    formatter: function (val) {
                        return Math.round(val);
                    },
                },
            },
        
           
        }
    };

    return (
        <div className="dashboard-container" ref={exportRef}>
            <div className='title-icon-container'>
              <div className='title-dashboard'> 
                <div className='title-gender-container'>
                    <img src={historyDashboard} className='history-icon' size={25}/>
                    <p>Relación demográfica de practicantes</p>
                </div>
                <p style={{'display': 'none'}} ref={dateRef} className={'date-title'}>
                    {getPeriodString(periodSelected)} 
                    {periodSelectedToCompare && ' comparado con ' + getPeriodString(periodSelectedToCompare)}
                </p>
                <DownloadDashboard 
                    refDashboard={exportRef} 
                    fileName={'Relación_demográfica'} 
                    customStyle={{
                        marginRight: '35px'
                    }}
                    excelConfigurations={excelConfigurations}
                    excelValues={excelValues}
                    periodSelected={periodSelected}
                    dateRef={dateRef}
                    isLoadingExcel={isLoadingExcel}
                    currentRef={downloadRef}
                    monthsToDownload={monthsToDownload}
                />
              </div>
                <div className={ (periodSelectedToCompare && !isLoading) ? 
                    'icons-container comparsion-icons' : 'icons-container'}
                >
                <div className='icon-text-container'>
                    {periodSelectedToCompare && !isLoading ? 
                        <div className='difference-gender-container-men'>
                            <p>{total.H}</p>
                            <p>{total.comparisonH}</p>
                            <p>{total.H - total.comparisonH}</p>
                        </div> : <p>{total.H}</p>
                    }
                    <img src={masculine} className='masculine-icon' size={30}/> 
                </div>
                {periodSelectedToCompare && <div className='icon-text-container' style={{marginLeft: '10px', marginRight: '10px'}}>
                    <div className='difference-gender-container'>
                        <p>{getPeriodString(periodSelected)}</p>
                        <p>{getPeriodString(periodSelectedToCompare)}</p>
                        <p>Diferencia</p>
                    </div>
                </div>}
                <div className='icon-text-container'>
                    <img src={femenine} className='femenine-icon' size={30}/>
                    {periodSelectedToCompare && !isLoading ?
                        <div className='difference-gender-container-women'>
                            <p>{total.M}</p>
                            <p>{total.comparisonM}</p>
                            <p>{total.M - total.comparisonM}</p>
                        </div> : <p>{total.M}</p>
                    }
                </div>
              </div>
            </div>

            {!isLoading ?
                <ReactApexChart
                    options={ periodSelectedToCompare ?  genderAgeLine.chartOptions : genderAge}
                    series={periodSelectedToCompare ? genderAgeLine.series : genderAge.series} 
                    type={periodSelectedToCompare ? 'bar': 'bar'}
                    height={600} 
                    className="gender-dashboard"
                /> : 
                <Spinner 
                    animation="border" 
                    role="status" 
                    className={'loading-screen'}
                />
            }   
        </div>
    )
}



import { useState, useEffect}from 'react'
import { DatePickerSection } from './DatePickerSection';
import { Row, Col, OverlayTrigger, Tooltip, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from "react-paginate";
import { IoReload } from 'react-icons/io5';
import { motion } from 'framer-motion';
import Methods from '../../../utils/https';
import moment from 'moment';
import {saveAs} from 'file-saver';
import excelByItems from '../../../utils/excelByItems';
import * as Excel from "exceljs";

export const ListInsuranceErrors = ({
    context
}) => {
    const [availableKeys, setAvailableKeys] = useState(
        [
            { name: "insuranceId",  key: "insuranceId", label: 'Id de seguro'},
            { name: "internName",  key: "internName", label: 'Nombre del practicante'},
            { name: "companyName",  key: "companyName", label: 'Compañía'},
            { name: "dateOfDetection", key: "dateOfDetection", label: 'Día de detección'},
            { name: "description", key: "description", label: 'Descripción'},
            { name: "errors", key: "errors", label: "Errores"},
            { name: "insuranceStartDate", key: "insuranceStartDate", label: 'Inicio del seguro'},
            { name: "insuranceEndDate",  key: "insuranceEndDate", label: 'Fin del seguro'}
        ]
    );
    
    const [ startDate, setStartDate ] = useState(new Date());
    const [ endDate, setEndDate ] = useState(new Date());
    const [ rotateSync, setRotateSync ] = useState(true);
    const [ selectedPage, setSelectedPage ] = useState(0);
    const [ reloadPage, setReloadPage ] = useState(false);
    const [ errorsData, setErrorsData ] = useState({
        allErrors: [],
        totalPages: 0,
        dataByPage: []
    });
    const {allErrors, totalPages, dataByPage} = errorsData
    const rotate = rotateSync ? "rotate" : "";
    
    useEffect(() => {
        getInsuranceErrors();
    }, [startDate, endDate, reloadPage]);
      
    const getInsuranceErrors = async() => {
        setRotateSync(true);
        setSelectedPage(0);
        let result = await Methods.getInsuranceErrors({
            startDate: parseInt(moment(startDate).startOf('date').format('X')),
            endDate: parseInt(moment(endDate).endOf('date').format('X'))
        });

        let errorsChunk = getChunkArrays([...result], 10);

        setErrorsData({
            allErrors: result,
            dataByPage: errorsChunk,
            totalPages: errorsChunk.length
        })
        setRotateSync(false);
    }

    const getChunkArrays = (array, size) => {
        let chunks = [];
        while (array.length){
            chunks.push(array.splice(0, size));
        }
        return chunks;
    }

    const displayOptions = (options) => {
        return <ul>
            {options?.map(option => 
                <li style={{overflowErap:' break-word'}}>{option}</li>
            )}
        </ul> 
    }

    return (
    <div>
        <Col>
            <Row>
                <Col md={12} xs={12} sm={12} className='col-reports'>
                    <div className="options">
                        <DatePickerSection 
                            dateAux={startDate} 
                            label={'Desde la fecha:'} 
                            setAux={setStartDate} 
                        />
                        <DatePickerSection 
                            dateAux={endDate}
                            label={'Hasta la fecha:'} 
                            setAux={setEndDate}
                        />
                        <OverlayTrigger
                            placement="top"
                            overlay={ 
                                <Tooltip id="button-tooltip-2">Recargar lista</Tooltip>
                            }
                        >
                            <a href="#" 
                                className="reload-table-grey" 
                                onClick={async () => { 
                                    setReloadPage(!reloadPage)
                                }}
                            >
                            <IoReload className={rotate}/></a>
                        </OverlayTrigger>
                        <Button className="default-button" onClick={() => {
                            const workbook = new Excel.Workbook();                         
                                excelByItems.test(
                                    workbook,
                                    [{
                                        name: 'Errores en seguros',
                                        values: allErrors
                                    }]
                                    ,
                                    availableKeys,
                                    moment().format(),
                                    context.user.data.fullName,
                                );
        
                                workbook.xlsx.writeBuffer().then((data) => {
                                    const blob = new Blob([data], {
                                    type:
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                    });
                                    saveAs(blob, `ReporteSeguros.xlsx`);
                                });
                            }}>
                            <FontAwesomeIcon className="inner-text" icon={faDownload}/>
                            Descargar Excel
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
            </Row> 
            <Row>
            <Col className='col-reports'>
                    <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ duration: 0.4 }} style={{width: '95%'}}>                     
                        <div className="table-scroll">
                            <Table hover>
                                <thead>
                                  { availableKeys?.map(keyItem => {
                                      return <td style={{textAlign: 'center'}}>{keyItem.label}</td>
                                  })}
                                </thead>
                                {dataByPage.length ? <tbody>
                                    {dataByPage[selectedPage]?.map((item, index) => {
                                      return <tr>
                                          {availableKeys?.map(keyItem => {
                                              return <td style={{minWidth: '200px'}}>
                                                    {  keyItem.name == 'errors' ?  
                                                        displayOptions(item[keyItem.name]):
                                                        item[keyItem.name] 
                                                    }
                                                </td>
                                          })}
                                      </tr>
                                    })}
                                </tbody> : <tbody></tbody>}
                            </Table>
                        </div>
                        <ReactPaginate
                            pageCount={totalPages} 
                            forcePage={selectedPage}
                            containerClassName="pagination-container" 
                            previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                            nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                            onPageChange={(e) => { 
                                setSelectedPage(e.selected);
                            }}
                        />
                    </motion.div>
                </Col>
            </Row>
        </Col>
    </div>
    )
}

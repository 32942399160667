import React, { useEffect, useState } from 'react';
import {Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretUp, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import onClickOutside from 'react-onclickoutside';
import './Dropdown.css';


let labelSeleccion = "SELECCION LIBRE";

function Dropdown({title = "N/A", items, label = "", setSelected, firstChecked = false, className = ''}){
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [checked, setChecked] = useState([]);
    Dropdown.handleClickOutside = () => setIsOpen(false);

    useEffect(() => {
        firstChecked && setFirstChecked();
    }, []);

    const setFirstChecked = () => {
        if(label == "business_name"){
            setChecked(items);
            setSelected(items);
        }
        else{
            setChecked([items[0]]);
        }
    };

    const setAllChecked = () => {
        setSelected([...items]);
            setChecked([...items]);
    };

    const isChecked = (value, dig) => {
        if(dig===1 ){
            if(labelSeleccion === "DESMARCAR TODO"){
                return false;
            }
            else if(labelSeleccion === "SELECCIONAR TODO"){
                return true; 
            }
        }
        else{
            for(let item of checked){
                if(item[label] == value[label]) return true;
            };
        }
        
      
            
    };

    const udpateSelected = (value) => {
        
        if(isChecked(value)){
            const filtered = checked.filter(item => item[label] !== value[label]);
            setSelected(filtered);
            setChecked(filtered);
            return;
        };

        setSelected([...checked, value]);
        setChecked([...checked, value]);
    };

    const unChecked = () => {
        const filtered = checked.filter(item => item[label] !== "NOss");
            setSelected(filtered);
            setChecked(filtered);
    
    };

    const udpateSelectedAll = (value) => {
        
        
        if(labelSeleccion === "SELECCIONAR TODO"){
            setAllChecked();
            labelSeleccion = "SELECCION LIBRE";
        }
        else if(labelSeleccion === "DESMARCAR TODO"){
            
            labelSeleccion = "SELECCIONAR TODO";
        }
        else{
            unChecked();
            labelSeleccion = "DESMARCAR TODO";
        }
    };

    return(
        <div className={`dd-wrapper ${className}`}>
            <div
                tabIndex={0}
                className="dd-header"
                role="button"
                onKeyPress={() => toggle()}
                onClick={() => toggle()}
            >
                <div className="dd-header-title">
                    <p>{title}</p>
                </div>

                <div className="dd-action">
                    <FontAwesomeIcon style={{fontSize: "15px", marginLeft: "5px"}} icon={isOpen ? faCaretUp : faCaretDown} />
                </div>
            </div>

            {isOpen && (
                <ul className="dd-list">
                    {items.map((item, index) => {
                        return(
                            <li>   
                                {(index==0) && 
                                (<Row className="myRow">
                                    <Col md={1}>
                                    <input
                                           
                                            type="checkbox"
                                            checked={isChecked(item, 0)}
                                            onChangeCapture={() => udpateSelectedAll(item)}
                                        />
                                    </Col>
                                    <Col>
                                        <label>
                                            {labelSeleccion}
                                        </label>
                                    </Col>
                                </Row>)
                                }
                                <Row className='dispersionSelectCompanyRow'>
                                    <Col md={1}>
                                        <input
                                            type="checkbox"
                                            checked={isChecked(item, 1)}
                                            onChangeCapture={() => udpateSelected(item)}
                                        />
                                    </Col>
                                    <Col>
                                        <label>
                                            {item[label]}
                                        </label>
                                    </Col>
                                </Row>
                            </li>
                        )
                    })}
                </ul> 
            )}
        </div>
    )
};

const clickOutsideConfig = {
    handleClickOutside: () => Dropdown.handleClickOutside,
};

export default onClickOutside(Dropdown, clickOutsideConfig);
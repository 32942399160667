import React from 'react';
import {Form, Button, Modal} from 'react-bootstrap';
import {saveAs} from 'file-saver';
import * as Excel from "exceljs";
import bancomerFile from "../Excel/bancomerFile";

export default function SetBatchBancomer({setShowBancomerFile, showBancomerFile, dataAux}) {
    const workbook = new Excel.Workbook();
    
    const createDocument = (e) => {
        e.preventDefault();
        
        let batchNumber = e.target.elements.batchNumber.value;
        let date = e.target.elements.date.value;
        bancomerFile.test(
            workbook,
            dataAux,
            batchNumber,
            date
        );
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            saveAs(blob, `altaBancomer`);
        });
        handleClose();
    };

    const handleClose = () => { 
        setShowBancomerFile(false);
    };

    return (
        <Modal className="new-cc-modal" show={showBancomerFile} onHide={() => handleClose()} centered>
            <Modal.Header>
                <p>Ingresa el número de lote y fecha de aplicación</p>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={createDocument}>
                    <Form.Group controlId="batchNumber">
                        <Form.Control type="number" required placeholder='Número de lote' className='input-cc'/>
                    </Form.Group>

                    <Form.Group controlId="date">
                        <Form.Control type="text" required placeholder='Fecha de aplicación AAAA-MM-DD' className='input-cc'/>
                    </Form.Group>

                    <div className='center-buttons'>
                        <Button className="float-right" variant="danger" type="button" onClick={() => handleClose()}>
                            Cancelar
                        </Button>
                        <Button className="float-right ml-2" variant="primary" type='submit'>
                            Confirmar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
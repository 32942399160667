import React, {useContext, createRef, useState, useEffect} from 'react';
import {Table, Form} from 'react-bootstrap';
import { notify } from '../../../utils/notify';
import moment from 'moment';
import {UserContext} from '../../../context/UserContext';
import Methods from '../../../utils/https';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from "react-paginate";
import { Options } from './Options';
import { decrypt } from "../../../utils/crypto";
import { faPlusCircle, faSyncAlt, faEdit, faTrash, faCamera, faChevronLeft, faChevronRight, faSearch} from '@fortawesome/free-solid-svg-icons';
const axios = require("axios");



export default function ListInterns ({interns, mode, totalSelected, setTotalSelected, formChanged, setFormChanged, setUniversities, universities, isITESMCompany,setDepartments,departments, costCentersTec, setCostCentersTec, generalistTec, setGeneralistTec, allPages,  currentInternsByPage, setCurrentInternsByPage, setCurrentPage, currentPage}) {
    const tableRef = createRef();
    const context = useContext(UserContext);
    const URL = process.env.REACT_APP_SOCKET_URL;

    const updateSelected = (intern) => {
        if(intern.isSelected){
            intern.isSelected = false;
            setFormChanged(!formChanged);
            return;
        };
        intern.isSelected = true;
        setFormChanged(!formChanged);
    };

    const selectedError = (intern) => {
        if(intern.isSelected && !intern.processed)
            notify("Deselecciona el practicante para poder editarlo", "error");
    }

    const updateTotalSelected = () => {
        if(!totalSelected) {
            for (let chunk of interns){
                for(let intern of chunk) {
                    if(!intern.isSelected){
                        intern.isSelected = true;
                    }
                }
            }
        } else {
            for (let chunk of interns){
                for(let intern of chunk) {
                    if(intern.isSelected){
                        intern.isSelected = false;
                    }
                }
            }
        }
        setFormChanged(!formChanged);
        setTotalSelected(!totalSelected);
    };

    const getDataByZipCode = async (intern, zip) => {
        let suburbsAux = [];
        intern.suburbs = [];
        intern.suburb = "";
        let api = (await Methods.getApiData("sepomex"))[0];
        api.apiKey = JSON.parse(decrypt(api.apiKey))
        try {
             let response = await axios({
                url: `${api.apiLink}${zip}?token=${api.apiKey}`,
                method: "GET",
             });
            intern.municipality = response.data[0].response.municipio
            intern._state = response.data[0].response.estado
            await response.data.forEach(element => {
                const data = element.response.asentamiento;
                suburbsAux.push(data);
            });
            intern.suburbs = suburbsAux;
            intern.suburbs.sort();
            setCurrentInternsByPage([...currentInternsByPage]);
            return intern.suburbs;
        } catch (e) {
            notify("C.P. inexistente", "error");
        };
        setFormChanged(!formChanged);
    }

    const setNewLeader = (intern, newLeaderId) => {

        if (isITESMCompany) {
            const [generalist] = generalistTec.filter(element => element._id == newLeaderId);
            if (generalist) {
                intern.directBoss_name = generalist.name;
                intern.directBoss_email = generalist.email;
            }
        }else{
            let currentDepartment = departments[intern.indexD]
            let [currentLeaderData] = currentDepartment?.leader?.filter(leaderAux => leaderAux._id == newLeaderId);

            if (currentLeaderData) {
                intern.directBoss_name = currentLeaderData.fullName;
                intern.directBoss_email = currentLeaderData.email;
            }
        }
        setFormChanged(!formChanged);
    }

    const updateInternDepartment = (intern, index) => {
        intern.department = {
            department_name: departments[index].title,
            department_id: departments[index]._id,
        };
        if(!isITESMCompany){
            intern.directBoss_name = "";
            intern.directBoss_email = "";
            intern.indexD = index;
            intern.cost_center = "";
            if (departments[index].leader?.length) {
                intern.directBoss_email = departments[index].leader[0].email;
                intern.directBoss_name = departments[index].leader[0].fullName;
            } else {
                notify(`Este ${context.getDepartmentField()} no cuenta con usuarios ${context.getGeneralistaField()}`, "error");
            }
        }
        setFormChanged(!formChanged);
    }

    const updateInternCC = (intern, index) => {
        intern.cost_center = {
            cost_center_name: isITESMCompany ? costCentersTec[index].name : departments[intern.indexD].costCenters[index].title,
            cost_center_id: isITESMCompany ? costCentersTec[index]._id : departments[intern.indexD].costCenters[index]._id,
        };
        setFormChanged(!formChanged);
    }

    const handleChangeEndDate = (intern, e) => {
        intern.contractEndDate = e.target.value;
        const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        if(intern.contractEndDate.match(regex)){
            let evaluatedDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            let evaluatedStartDate = moment(intern.contractStartDate, "DD/MM/YYYY");
            let totalDuration = Math.ceil(evaluatedDate.diff(evaluatedStartDate,"months", true));
            intern.contractDuration = totalDuration;
            intern.disabledDuration = true;
        } else {
            intern.disabledDuration = false;
            intern.contractDuration = 1;
        }
        setFormChanged(!formChanged);
    }

    const handleChangeApprovalDate = (intern, e) => {
        intern.contractApprovalDate = e.target.value;
        const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        if(intern.contractApprovalDate && intern.contractApprovalDate.match(regex)){
            intern.approveIntern = true;
            intern.isRetroactivePayment = true;
        } else {
            intern.approveIntern = false;
            intern.isRetroactivePayment = false;
        }
        setFormChanged(!formChanged);
    }

    const handleChangeStartDate = (intern, e) => {
        intern.contractStartDate = e.target.value;
        const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        if(intern.contractStartDate.match(regex) && intern.contractEndDate.match(regex)){
            let evaluatedDate = moment(intern.contractStartDate, "DD/MM/YYYY");
            let evaluatedEndDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            let totalDuration = Math.ceil(evaluatedEndDate.diff(evaluatedDate,"months", true));
            intern.contractDuration = totalDuration;
            intern.disabledDuration = true;
        } else {
            intern.disabledDuration = false;
            intern.contractDuration = 1;
        }
        setFormChanged(!formChanged);
    }

    const getDepartments = async () => {
        try {
            let response = await axios({
                url: `${URL}/departments`,
                method: "PUT",
                data: { id_company: context.user.company._id, departmentField: context.getDepartmentField() }
            });
            verifyDepartments(response.data.data);
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
            setDepartments([]);
        };
    };

    const verifyDepartments = async (departments) => {
        let availableDepartments = [];
        await departments.forEach(element => {
            availableDepartments.push(element);
        });
        await setDepartments(availableDepartments);
    }


    const getData = async () => {
        let promises = [getDepartments()];
        if (isITESMCompany) {
            promises.push(Methods.getCostCentersTec())
            promises.push(Methods.getGeneralistas())
            let response = await Promise.all(promises);
            setCostCentersTec(response[1]);
            response[2].sort((a, b) => a.name.localeCompare(b.name));
            setGeneralistTec(response[2]);
        }else{
            await Promise.all(promises);
        }

    }

    useEffect(() => {
        getData();
    }, []);

    if((mode === "create" || mode === "modify") && context.can('create', 'Uploads')){
        return(
            <>
                <div style={{height: "75vh", overflowY:"scroll"}}>
                    <div className="table-container">
                    {formChanged}
                        <div className='create'>
                            <Table hover ref={tableRef}>
                                <thead>
                                {currentInternsByPage?.length > 0 &&
                                    <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    disabled={!currentInternsByPage?.length}
                                                    checked={totalSelected}
                                                    onChange={() => updateTotalSelected()}
                                                />
                                            </th>
                                            <th>CURP</th>
                                            <th>Nombres</th>
                                            <th>Apellido Paterno</th>
                                            <th>Apellido Materno</th>
                                            <th>Inicio del convenio</th>
                                            <th>Finalización del convenio</th>
                                            <th>Fecha de aprobación</th>
                                            <th>Meses de convenio</th>
                                            <th>Correo</th>
                                            <th>Monto de la Beca</th>
                                            <th>{context.getDepartmentField(true)}</th>
                                            <th>Centro de Costos</th>
                                            <th>{context.getGeneralistaField(true)}</th>
                                            <th>Correo de {context.getGeneralistaField()}</th>
                                            <th>Proyecto</th>
                                            <th>Hora de entrada</th>
                                            <th>Hora de salida</th>
                                            <th>Calle</th>
                                            <th>No. Exterior</th>
                                            <th>No. Interior</th>
                                            <th>Código Postal</th>
                                            <th>Colonia</th>
                                            <th>Fecha de nacimiento</th>
                                            <th>Teléfono</th>
                                            <th>Universidad</th>
                                            <th>{context.getFacultadField(true)}</th>
                                            <th>Carrera</th>
                                            <th>Ciclo Escolar</th>
                                            <th>Matrícula</th>
                                            <th>Grado</th>
                                            <th>Escolaridad</th>
                                            <th>Cuenta</th>
                                            <th>Grupo/Salón</th>
                                            <th>LinkedIn</th>
                                            <th>Instagram</th>
                                            {isITESMCompany && <th>Nomina</th>}
                                            {isITESMCompany && <th>Lider</th>}
                                            {isITESMCompany && <th>Posicion</th>}
                                            {isITESMCompany && <th>Programa</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Plan</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Cobrar Póliza a</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Tipo de Cobro</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Beneficiario 1</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Porcentaje 1</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Parentesco 1</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Beneficiario 2</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Porcentaje 2</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Parentesco 2</th>}
                                            <th>Resultado</th>
                                    </tr>
                                    }
                                </thead>

                                <tbody>
                                    {currentInternsByPage ? currentInternsByPage.map((intern, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={intern.isSelected}
                                                        onChange={() => updateSelected(intern)}
                                                        disabled={intern.processed}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.curp}
                                                        onChange={e => {intern.curp = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"220px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.firstname}
                                                        onChange={e => {intern.firstname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"185px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.father_lastname}
                                                        onChange={e => {intern.father_lastname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.mother_lastname}
                                                        onChange={e => {intern.mother_lastname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.contractStartDate}
                                                        onBlur={e => {handleChangeStartDate(intern, e)}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.contractEndDate}
                                                        onBlur={e => {handleChangeEndDate(intern, e)}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.contractApprovalDate}
                                                        onBlur={e => {handleChangeApprovalDate(intern, e)}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>
                                                {intern.disabledDuration && 
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={true}
                                                        type="text"
                                                        defaultValue={intern.contractDuration}
                                                        className="input-text"
                                                        style={{"width":"85px"}}
                                                    />
                                                </td>}
                                                {!intern.disabledDuration &&
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed || intern.disabledDuration} as="select" readOnly={true} onChange={(e) => {intern.contractDuration = e.target.value}} className="input-text" style={{"width":"85px"}}>
                                                        <option disabled selected hidden>{intern.contractDuration || ""}</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                    </Form.Control>
                                                </td>
                                                }
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.email}
                                                        onChange={e => {intern.email = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"230px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.scolarshipAmount}
                                                        onChange={e => {intern.scolarshipAmount = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"130px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={async (e) => { updateInternDepartment(intern, e.target.value); intern.departmentError=false;}} className="input-text" style={{"width":"150px", "backgroundColor": (intern.departmentError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option disabled selected hidden id={intern.department.department_name} value={intern.department} >{intern.department.department_name || ""}</option>
                                                        {departments.length > 0 ? departments.map((department, index) => {
                                                            if (department) {
                                                                return (
                                                                    <option key={index} id={department.title} value={index}>{department.title}</option>
                                                                )
                                                            }
                                                        }) : <option disabled>No hay {context.getDepartmentField(false, true)}</option>}
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {updateInternCC(intern, e.target.value); intern.ccError=false;}} className="input-text" style={{"width":"150px", "backgroundColor": (intern.ccError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option disabled selected hidden>{intern.cost_center.cost_center_name || ""}</option>
                                                        {isITESMCompany ?
                                                            costCentersTec && costCentersTec.length > 0 ? costCentersTec.map((k, index) => <option key={k._id} id={k.name} value={index}>{k.name}</option>) : <option disabled>No hay centro de costos</option>
                                                            :
                                                            departments.map(department => {
                                                                if (department._id === (intern.department.department_id)) {
                                                                    if (department.costCenters && department.costCenters.length > 0) {
                                                                        return department.costCenters.map((costCenter, index) => {
                                                                            if(costCenter){
                                                                                return (
                                                                                    <option key={index} id={costCenter.title} value={index}>{costCenter.title}</option>
                                                                                )
                                                                            }
                                                                        })
                                                                    } else {
                                                                        return (<option disabled>No hay centro de costos</option>)
                                                                    }
                                                                }
                                                            })

                                                        }
                                                        {(!intern.department.department_name) && <option disabled>Aun no seleccionas un {context.getDepartmentField()}</option>}
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {setNewLeader(intern, e.target.value); intern.generalistError = false;}} className="input-text" style={{"width":"330px", "backgroundColor": (intern.generalistError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                    <option disabled selected hidden>{intern.directBoss_name || ""}</option>
                                                        {isITESMCompany ?
                                                            generalistTec && generalistTec.length > 0 ? generalistTec.map(k => <option key={k._id} id={k._id} value={k._id} selected={k.name == intern.directBoss_name}>{k.name}</option>) : <option disabled>No hay {context.getGeneralistaField(false, true)}</option>
                                                            :
                                                            (departments.length) ? departments.map(department => {
                                                            if (department._id === (intern.department.department_id)) {
                                                                return (
                                                                    department.leader?.length ? department.leader.map(leader => {
                                                                        return (
                                                                            <option key={leader._id} value={leader._id} selected={leader.fullName == intern.directBoss_name}>{leader.fullName}</option>
                                                                        )
                                                                    }) : <option disabled>No hay {context.getGeneralistaField(false, true)}</option>
                                                                )
                                                            }
                                                            }) : <option disabled>No hay {context.getGeneralistaField(false, true)}</option>
                                                        }
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                    key={`${Math.floor((Math.random() * 1000))}-min`}
                                                    className="input-text"
                                                    style={{"width":"230px"}}
                                                    readOnly={true}
                                                    type="email"
                                                    autoComplete="none"
                                                    disabled
                                                    defaultValue={intern.directBoss_email}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.projectName}
                                                        onChange={e => {intern.projectName = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"130px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.entry_time}
                                                        onChange={e => {intern.entry_time = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"100px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.depurate_time}
                                                        onChange={e => {intern.depurate_time = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"100px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.street}
                                                        onChange={e => {intern.street = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"150px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.ext_number}
                                                        onChange={e => {intern.ext_number = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"90px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.int_number}
                                                        onChange={e => {intern.int_number = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"90px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.zip_code}
                                                        onInput={async (e) => {
                                                            e.target.value = (0, e.target.value).toString().slice(0, 5)
                                                            intern.zip_code = e.target.value;
                                                            if (e.target.value.length == 5) {
                                                                await getDataByZipCode(intern, e.target.value);
                                                            }
                                                        }}
                                                        className="input-text"
                                                        style={{"width":"90px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Options key={intern._id} intern={intern} getDataByZipCode={getDataByZipCode}/>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.birthday}
                                                        onChange={e => {intern.birthday = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="number"
                                                        autoComplete="none"
                                                        onInput={(e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                        }}
                                                        defaultValue={intern.phone}
                                                        onChange={e => {intern.phone = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {
                                                        setFormChanged(!formChanged);
                                                        intern.faculty = e.target.value == "Otro" ? "Otro" : "";
                                                        intern.career = e.target.value == "Otro" ? "Otro" : "";
                                                        intern.college = e.target.value;
                                                        intern.collegeError = false;
                                                        }} className="input-text" style={{"width":"500px", "backgroundColor": (intern.collegeError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option disabled selected hidden>{intern.college}</option>
                                                        {universities.length > 0 ? universities.map((university, index) => {
                                                        if (!university.isHistorical) {
                                                            return (
                                                                <option key={index} id={university._id} value={university._name}>{university._name}</option>
                                                            )
                                                        }
                                                        }) : <option disabled>No hay universidades</option>}
                                                        <option value="Otro">Otro</option>
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {
                                                        const {name, region} = JSON.parse(e.target.value);
                                                        setFormChanged(!formChanged);
                                                        intern.faculty = name;
                                                        intern.region = region;
                                                        intern.career = "";
                                                        intern.facultyError = false;
                                                        }} className="input-text" style={{"width":"150px", "backgroundColor": (intern.facultyError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option selected hidden>{intern.faculty || ""}</option>
                                                        {universities.map(university => {
                                                            if (intern.college && university._name === (intern.college)) {
                                                                if (university.faculties && university.faculties.length > 0) {
                                                                    return university.faculties.map((faculty, index) => {
                                                                        return (
                                                                            <option key={index} id={faculty._id} value={JSON.stringify({name: faculty._name, region: faculty.region})}>{faculty._name}</option>
                                                                        )
                                                                    })
                                                                } else {
                                                                    return (<option disabled value="">No hay {context.getFacultadField(false, true)}</option>)
                                                                }
                                                            }
                                                        })}
                                                        {(!intern.college || intern.collegeError) && <option disabled>Aun no seleccionas una universidad</option>}
                                                        {intern.college == "Otro" && <option value="Otro" disabled>No hay {context.getFacultadField(false, true)}</option>}
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {
                                                        intern.career = e.target.value;
                                                        intern.careerError = false;
                                                        setFormChanged(!formChanged);
                                                        }} className="input-text" style={{"width":"500px", "backgroundColor": (intern.careerError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option selected hidden>{intern.career || ""}</option>
                                                        {universities.map(university => {
                                                            if (intern.college && university._name === (intern.college)) {
                                                                if (university.faculties && university.faculties.length > 0) {
                                                                    return university.faculties.map(faculty => {
                                                                        if (intern.faculty && faculty._name === (intern.faculty)) {
                                                                            if (faculty.careers && faculty.careers.length > 0) {
                                                                                return faculty.careers.map((career, index) => {
                                                                                    return (
                                                                                        <option key={index} id={career._name} value={career._name}>{career._name}</option>
                                                                                    )
                                                                                })
                                                                            } else {
                                                                                return (<option disabled value="">No hay carreras</option>)
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })}
                                                        {(!intern.faculty || intern.facultyError) && <option disabled>Aún no hay selección de {context.getFacultadField()}</option>}
                                                        {intern.college == "Otro" && <option value="Otro" disabled>No hay carreras</option>}
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {intern.period_type = e.target.value}} className="input-text" style={{"width":"150px"}}>
                                                        <option disabled selected hidden>{intern.period_type || ""}</option>
                                                        <option>Semestral</option>
                                                        <option>Tetramestral</option>
                                                        <option>Trimestral</option>
                                                        <option>Cuatrimestral</option>
                                                        <option>Bimestral</option>
                                                        <option>Mensual</option>
                                                        <option>Pentamestral</option>
                                                        <option>Anual</option>
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.school_enrollment}
                                                        onChange={e => {intern.school_enrollment = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {intern.grade = e.target.value}} className="input-text" style={{"width":"70px"}}>
                                                        <option disabled selected hidden>{intern.grade || ""}</option>
                                                        <option>1°</option>
                                                        <option>2°</option>
                                                        <option>3°</option>
                                                        <option>4°</option>
                                                        <option>5°</option>
                                                        <option>6°</option>
                                                        <option>7°</option>
                                                        <option>8°</option>
                                                        <option>9°</option>
                                                        <option>10°</option>
                                                        <option>11°</option>
                                                        <option>12°</option>
                                                        <option>Pendiente</option>
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {intern.scholarship = e.target.value}} className="input-text" style={{"width":"160px"}}>
                                                        <option disabled selected hidden>{intern.scholarship || ""}</option>
                                                        <option>Profesional</option>
                                                        <option>Preparatoria</option>
                                                        <option>Carrera Técnica</option>
                                                        <option>Maestría</option>
                                                        <option>Doctorado</option>
                                                    </Form.Control>
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.clabe}
                                                        onChange={e => {intern.clabe = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"150px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern._group}
                                                        onChange={e => {intern._group = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.linkedin}
                                                        onChange={e => {intern.linkedin = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"130px"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.instagram}
                                                        onChange={e => {intern.instagram = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"130px"}}
                                                    />
                                                </td>
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.nomina}
                                                        onBlur={e => {intern.nomina = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"150px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.leaderTec}
                                                        onBlur={e => {intern.leaderTec = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.position}
                                                        onBlur={e => {intern.position = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {intern.program = e.target.value}} className="input-text" style={{"width":"230px"}}>
                                                        
                                                        <option>Embajador Tec</option>
                                                        <option>Embajador Tecmilenio</option>
                                                        <option>Experiencias Tec</option>
                                                        <option>Experiencias Tecmilenio</option>
                                                        <option>Novus</option>
                                                        <option>Practicante Externo</option>
                                                        <option>Storyteller</option>
                                                        <option>Teaching Assistant</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.planType = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.planType || ""}</option>
                                                        <option>Plan 1</option>
                                                        <option>Plan 2</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.insurancePayment = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.insurancePayment || ""}</option>
                                                        <option>Empresa</option>
                                                        <option>Practicante</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.paymentType = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.paymentType || ""}</option>
                                                        <option>Mensual</option>
                                                        <option>Total</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.berneficiary}
                                                        onBlur={e => {intern.berneficiary = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.percent}
                                                        onBlur={e => {intern.percent = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.relationship = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.relationship || ""}</option>
                                                        <option>Padre</option>
                                                        <option>Madre</option>
                                                        <option>Tutor</option>
                                                        <option>Hermano</option>
                                                        <option>Amistad</option>
                                                        <option>Otro</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.berneficiarySecond}
                                                        onBlur={e => {intern.berneficiarySecond = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.percentSecond}
                                                        onBlur={e => {intern.percentSecond = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.relationshipSecond = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.relationshipSecond || ""}</option>
                                                        <option>Padre</option>
                                                        <option>Madre</option>
                                                        <option>Tutor</option>
                                                        <option>Hermano</option>
                                                        <option>Amistad</option>
                                                        <option>Otro</option>
                                                    </Form.Control>
                                                </td>}
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                    key={`${Math.floor((Math.random() * 1000))}-min`}
                                                    className="input-text"
                                                    style={{"width":"500px"}}
                                                    type="text"
                                                    defaultValue={intern.error}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }): <tr></tr> }
                                    <tr className="spacer"></tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <ReactPaginate
                    pageCount={ allPages || 1}
                    forcePage={ currentPage }
                    containerClassName="pagination-container"
                    previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                    nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                    onPageChange={ (e) => {
                        setCurrentInternsByPage(interns[e.selected]);
                        setCurrentPage(e.selected);
                    }}
                />
            </>
        );
    } else if (mode == "renew" && context.can('update', 'Uploads')) {
        return(
            <>
                <div style={{height: "75vh", overflowY:"scroll"}}>
                    <div className="table-container">
                    {formChanged}
                        <div>
                            <Table hover ref={tableRef}>
                                <thead>
                                {currentInternsByPage?.length > 0 &&
                                    <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    disabled={!currentInternsByPage?.length}
                                                    checked={totalSelected}
                                                    onChange={() => updateTotalSelected()}
                                                />
                                            </th>
                                            <th>CURP</th>
                                            {isITESMCompany && <th>Nombres</th>}
                                            {isITESMCompany && <th>Apellido Paterno</th>}
                                            {isITESMCompany && <th>Apellido Materno</th>}
                                            {isITESMCompany && <th>Matrícula</th>}
                                            <th>Inicio del convenio</th>
                                            <th>Finalización del convenio</th>
                                            <th>Fecha de Aprobación</th>
                                            <th>Meses de convenio</th>
                                            {isITESMCompany && <th>Correo</th>}
                                            <th>Monto de la Beca</th>
                                            <th>Grado</th>
                                            {isITESMCompany && <th>VP-VR</th>}
                                            {isITESMCompany && <th>Centro de Costos</th>}
                                            {isITESMCompany && <th>{context.getGeneralistaField(true)}</th>}
                                            {isITESMCompany && <th>Correo de {context.getGeneralistaField()}</th>}
                                            {isITESMCompany && <th>Proyecto</th>}
                                            {isITESMCompany && <th>Lider</th>}
                                            {isITESMCompany && <th>Nómina</th>}
                                            {isITESMCompany && <th>Posicion</th>}
                                            {isITESMCompany && <th>Programa</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Plan</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Cobrar Póliza a</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Tipo de Cobro</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Beneficiario 1</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Porcentaje 1</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Parentesco 1</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Beneficiario 2</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Porcentaje 2</th>}
                                            {context.user.company.interns_medical_insurance == "Sí" && <th>Parentesco 2</th>}
                                            <th>Resultado</th>
                                    </tr>
                                }
                                </thead>

                                <tbody>
                                    {currentInternsByPage ? currentInternsByPage.map((intern, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={intern.isSelected}
                                                        onChange={() => updateSelected(intern)}
                                                        disabled={intern.processed}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.curp}
                                                        onChange={e => {intern.curp = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"220px"}}
                                                    />
                                                </td>
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.firstname}
                                                        onChange={e => {intern.firstname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"185px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.father_lastname}
                                                        onChange={e => {intern.father_lastname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany &&  <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.mother_lastname}
                                                        onChange={e => {intern.mother_lastname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.school_enrollment}
                                                        onChange={e => {intern.school_enrollment = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>}
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.contractStartDate}
                                                        onBlur={e => {handleChangeStartDate(intern, e)}}
                                                        className="input-text"
                                                        style={{"width":"auto"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.contractEndDate}
                                                        onBlur={e => {handleChangeEndDate(intern, e)}}
                                                        className="input-text"
                                                        style={{"width":"auto"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.contractApprovalDate}
                                                        onBlur={e => {handleChangeApprovalDate(intern, e)}}
                                                        className="input-text"
                                                        style={{"width":"auto"}}
                                                    />
                                                </td>
                                                {intern.disabledDuration && 
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={true}
                                                        type="text"
                                                        defaultValue={intern.contractDuration}
                                                        className="input-text"
                                                        style={{"width":"85px"}}
                                                    />
                                                </td>}
                                                {!intern.disabledDuration &&
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed || intern.disabledDuration} as="select" readOnly={true} onChange={(e) => {intern.contractDuration = e.target.value}} className="input-text" style={{"width":"115%"}}>
                                                        <option disabled selected hidden>{intern.contractDuration || ""}</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                    </Form.Control>
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.email}
                                                        onChange={e => {intern.email = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"230px"}}
                                                    />
                                                </td>}
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.scolarshipAmount}
                                                        onChange={e => {intern.scolarshipAmount = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"auto"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {intern.grade = e.target.value}} className="input-text" style={{"width":"auto", "marginRight":"0px"}}>
                                                        <option disabled selected hidden>{intern.grade || ""}</option>
                                                        <option>1°</option>
                                                        <option>2°</option>
                                                        <option>3°</option>
                                                        <option>4°</option>
                                                        <option>5°</option>
                                                        <option>6°</option>
                                                        <option>7°</option>
                                                        <option>8°</option>
                                                        <option>9°</option>
                                                        <option>10°</option>
                                                        <option>11°</option>
                                                    </Form.Control>
                                                </td>
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={async (e) => { updateInternDepartment(intern, e.target.value); intern.departmentError=false;}} className="input-text" style={{"width":"150px", "backgroundColor": (intern.departmentError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option disabled selected hidden id={intern.department.department_name} value={intern.department} >{intern.department.department_name || ""}</option>
                                                        {departments.length > 0 ? departments.map((department, index) => {
                                                            if (department) {
                                                                return (
                                                                    <option key={index} id={department.title} value={index}>{department.title}</option>
                                                                )
                                                            }
                                                        }) : <option disabled>No hay {context.getDepartmentField(false, true)}</option>}
                                                    </Form.Control>
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {updateInternCC(intern, e.target.value); intern.ccError=false;}} className="input-text" style={{"width":"150px", "backgroundColor": (intern.ccError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                        <option disabled selected hidden>{intern.cost_center.cost_center_name || ""}</option>
                                                        {
                                                            costCentersTec && costCentersTec.length > 0 ? costCentersTec.map((k, index) => <option key={k._id} id={k.name} value={index}>{k.name}</option>) : <option disabled>No hay centro de costos</option>
                                                        }
                                                    </Form.Control>
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {setNewLeader(intern, e.target.value); intern.generalistError = false;}} className="input-text" style={{"width":"330px", "backgroundColor": (intern.generalistError ? "rgba(215, 82, 82, 0.8)" : "#eceef1")}}>
                                                    <option disabled selected hidden>{intern.directBoss_name || ""}</option>
                                                        {
                                                            generalistTec && generalistTec.length > 0 ? generalistTec.map(k => <option key={k._id} id={k._id} value={k._id} selected={k.name == intern.directBoss_name}>{k.name}</option>) : <option disabled>No hay {context.getGeneralistaField(false, true)}</option>
                                                        }
                                                    </Form.Control>
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                    key={`${Math.floor((Math.random() * 1000))}-min`}
                                                    className="input-text"
                                                    style={{"width":"230px"}}
                                                    readOnly={true}
                                                    type="email"
                                                    autoComplete="none"
                                                    disabled
                                                    defaultValue={intern.directBoss_email}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.projectName}
                                                        onChange={e => {intern.projectName = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"130px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.leaderTec}
                                                        onBlur={e => {intern.leaderTec = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                     key={`${Math.floor((Math.random() * 1000))}-min`}
                                                     disabled={intern.isSelected || intern.processed}
                                                     type="text"
                                                     defaultValue={intern.clabe}
                                                     onBlur={e => {intern.clabe = e.target.value}}
                                                     className="input-text"
                                                     style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.position}
                                                        onBlur={e => {intern.position = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control disabled={intern.isSelected || intern.processed} as="select" readOnly={true} onChange={(e) => {intern.program = e.target.value}} className="input-text" style={{"width":"230px"}}>
                                                        <option disabled selected hidden>{intern.program || ""}</option>
                                                        <option>Embajador Tec</option>
                                                        <option>Embajador Tecmilenio</option>
                                                        <option>Experiencias Tec</option>
                                                        <option>Experiencias Tecmilenio</option>
                                                        <option>Novus</option>
                                                        <option>Practicante Externo</option>
                                                        <option>Storyteller</option>
                                                        <option>Teaching Assistant</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.planType = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.planType || ""}</option>
                                                        <option>Plan 1</option>
                                                        <option>Plan 2</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.insurancePayment = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.insurancePayment || ""}</option>
                                                        <option>Empresa</option>
                                                        <option>Practicante</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.paymentType = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.paymentType || ""}</option>
                                                        <option>Mensual</option>
                                                        <option>Total</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.berneficiary}
                                                        onBlur={e => {intern.berneficiary = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.percent}
                                                        onBlur={e => {intern.percent = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.relationship = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.relationship || ""}</option>
                                                        <option>Padre</option>
                                                        <option>Madre</option>
                                                        <option>Tutor</option>
                                                        <option>Hermano</option>
                                                        <option>Amistad</option>
                                                        <option>Otro</option>
                                                    </Form.Control>
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.berneficiarySecond}
                                                        onBlur={e => {intern.berneficiarySecond = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.percentSecond}
                                                        onBlur={e => {intern.percentSecond = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"180px"}}
                                                    />
                                                </td>}
                                                {context.user.company.interns_medical_insurance == "Sí" && <td onClick={() => {selectedError(intern)}}>
                                                <Form.Control  disabled={intern.isSelected || intern.processed}  as="select" readOnly={true}  onChange={(e) => {intern.relationshipSecond = e.target.value}} className="input-text" style={{"width":"230px"}} >
                                                        <option disabled selected hidden>{intern.relationshipSecond || ""}</option>
                                                        <option>Padre</option>
                                                        <option>Madre</option>
                                                        <option>Tutor</option>
                                                        <option>Hermano</option>
                                                        <option>Amistad</option>
                                                        <option>Otro</option>
                                                    </Form.Control>
                                                </td>}
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                    key={`${Math.floor((Math.random() * 1000))}-min`}
                                                    className="input-text"
                                                    style={{"width":"500px"}}
                                                    type="text"
                                                    disabled
                                                    defaultValue={intern.error}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }): <tr></tr> }
                                    <tr className="spacer"></tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <ReactPaginate
                    pageCount={ allPages || 1}
                    forcePage={ currentPage }
                    containerClassName="pagination-container"
                    previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                    nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                    onPageChange={ (e) => {
                        setCurrentInternsByPage(interns[e.selected]);
                        setCurrentPage(e.selected);
                    }}
                />
            </>
        );
    } else if (mode == "unsubscribe" && context.can('delete', 'Uploads')) {
        return(
            <>
                <div style={{height: "75vh", overflowY:"scroll"}}>
                    <div className="table-container">
                    {formChanged}
                        <div>
                            <Table hover ref={tableRef}>
                                <thead>
                                {currentInternsByPage?.length > 0 &&
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                disabled={!currentInternsByPage?.length}
                                                checked={totalSelected}
                                                onChange={() => updateTotalSelected()}
                                            />
                                        </th>
                                        <th>CURP</th>
                                        {isITESMCompany && <th>Matrícula</th>}
                                        {isITESMCompany && <th>Nombres</th>}
                                        {isITESMCompany && <th>Apellido Paterno</th>}
                                        {isITESMCompany && <th>Apellido Materno</th>}
                                        <th>Fecha de Baja</th>
                                        <th>Motivo</th>
                                        <th>Resultado</th>
                                    </tr>
                                }
                                </thead>

                                <tbody>
                                    {currentInternsByPage ? currentInternsByPage.map((intern, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={intern.isSelected}
                                                        onChange={() => updateSelected(intern)}
                                                        disabled={intern.processed}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.curp}
                                                        onChange={e => {intern.curp = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"220px"}}
                                                    />
                                                </td>
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.school_enrollment}
                                                        onChange={e => {intern.school_enrollment = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"110px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.firstname}
                                                        onChange={e => {intern.firstname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"185px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany && <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.father_lastname}
                                                        onChange={e => {intern.father_lastname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>}
                                                {isITESMCompany &&  <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.mother_lastname}
                                                        onChange={e => {intern.mother_lastname = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"120px"}}
                                                    />
                                                </td>}
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.unsuscribeDate}
                                                        onChange={e => {intern.unsuscribeDate = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"auto"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                        key={`${Math.floor((Math.random() * 1000))}-min`}
                                                        disabled={intern.isSelected || intern.processed}
                                                        type="text"
                                                        defaultValue={intern.unsuscribeMotive}
                                                        onChange={e => {intern.unsuscribeMotive = e.target.value}}
                                                        className="input-text"
                                                        style={{"width":"auto"}}
                                                    />
                                                </td>
                                                <td onClick={() => {selectedError(intern)}}>
                                                    <Form.Control
                                                    key={`${Math.floor((Math.random() * 1000))}-min`}
                                                    className="input-text"
                                                    style={{"width":"500px"}}
                                                    type="text"
                                                    disabled
                                                    defaultValue={intern.error}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }): <tr></tr> }
                                    <tr className="spacer"></tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <ReactPaginate
                    pageCount={ allPages || 1}
                    forcePage={ currentPage }
                    containerClassName="pagination-container"
                    previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                    nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                    onPageChange={ (e) => {
                        setCurrentInternsByPage(interns[e.selected]);
                        setCurrentPage(e.selected);
                    }}
                />
            </>
        );
    }
    return (<></>);


};
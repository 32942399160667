import React, {useContext, useState, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import {Row, Button} from 'react-bootstrap';
import { BsUpload, BsDownload } from 'react-icons/bs';
import { TiArrowSync } from "react-icons/ti";
import { motion } from 'framer-motion';
import * as Excel from "exceljs";
import {saveAs} from 'file-saver';
import ExcelCSV from "./Excel/DownloadXLSX";
import './Uploads.css';
import Methods from '../../utils/https';
import LoadingModal from '../../custom/LoadingModal/LoadingModal';
import moment from 'moment';
import UploadExcel from './components/UploadExcel.jsx';
import SendInsurance from './components/SendInsurance.jsx';
import ListInterns from './components/ListInterns.jsx';
import { deletePosteriorPolicies, createInsurance } from '../../utils/insuranceMethods';
import  TemplatePDF from '../Interns/components/TemplatePDF.jsx';
import { pdf } from '@react-pdf/renderer';
import { sendITESMdocs } from '../../utils/signatureMethods';
const uuid = require("uuid");
const $ENV = 'prod';
const axios = require("axios");
const URL = process.env.REACT_APP_SOCKET_URL;


export default function Uploads () {
    const [mode, setMode] = useState("");
    const [totalSelected, setTotalSelected] = useState(false);
    const [showUpdateExcel, setShowUpdateExcel] = useState(false);
    const [showSendInsurance, setShowSendInsurance] = useState(false);
    const [loadingInsurance, setLoadingInsurance] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [costCentersTec, setCostCentersTec] = useState(null);
    const [generalistTec, setGeneralistTec] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [allPages, setAllPages] = useState(1);
    const [currentInternsByPage, setCurrentInternsByPage] = useState([]);
    const context = useContext(UserContext);
    const requireSife = context.user.company.requireSife == 'Sí' ? true : false;
    const currentCompany = context.user ? context.user.company.business_name : "";
    const [processedIntern , setProcessedIntern] = useState(0);
    const [sentIntern , setSentIntern] = useState(0);
    const [internsSelected , SetInternsSelected] = useState(0);
    const [internsLength, setInternsLength] = useState(0);
    const [canUploadDocument, setCanUploadDocument] = useState(
        context.can('create', 'Uploads') || context.can('update', 'Uploads') || context.can('delete', 'Uploads') || false
    )
    const [universities, setUniversities] = useState([])

    let currentId = "";
    let duplicated = "";
    const [interns, setInterns] = useState([]);
    const [isITESMCompany, setIsITESMCompany] = useState(context.user.company.isITESMCompany || false)
    const maxMonthsContract = context.user.company.isITESMCompany ? 12 : 6;
    const maxMonths = isITESMCompany ?  ['1','2','3','4','5','6','7','8','9','10','11','12'] : ['1','2','3','4','5','6'];
    const sifeEmailUrl = () => {
        const sife = {
            "dev": "https://dev.sife.io/",
            "prod": "https://sife.io/"
        }
    
        return sife[$ENV || "dev"] || sife.dev;
    }

    useEffect(() => {
        if(!context.universities && !context.isLoadingUniversities){
            getUniversitiesAux();
            setUniversities(context.universities);
        }else if(!context.universities && context.isLoadingUniversities){
            setUniversities(context.universities);
        }else if(context.universities.length){
            setIsLoading(false);
            setUniversities(context.universities);
        } 
    }, [context.isLoadingUniversities]);

    const getUniversitiesAux = async () => {
        await context.getUniversitiesAux();
    }
    const getNewDate = (intern) => {
        let startDate = moment(intern.contractStartDate, "DD/MM/YYYY").toDate();
        let newDate = moment(intern.contractStartDate, "DD/MM/YYYY").toDate();
        if(parseInt(intern.contractDuration) != 0){
            newDate.setMonth(newDate.getMonth() + parseInt(intern.contractDuration));
            newDate.setDate(newDate.getDate() - 1);
            if(startDate.getDate() > 28 && newDate.getMonth() == 2){
                let dateAux = new Date(newDate.getFullYear(), 2, 0);
                newDate = dateAux;
            }   
            newDate.setHours(0,0,0,0);
            intern.contractEndDate = newDate.toLocaleDateString('en-GB');
        }
    };

    const curpValidation = (intern) => {
        const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
        const isValid = intern.curp.match(regex);
        if (intern.curp.length > 0) {
            if (intern.curp.length != 18) {
                intern.error = "El CURP debe tener mínimo 18 caracteres";
                return true;
            } else {
                if (isValid) {
                    let year = intern.curp.substring(4, 6);
                    const month = intern.curp.substring(6, 8);
                    const day = intern.curp.substring(8, 10);
                    if (year > 40) {
                        year = 19 + year;
                    }
                    else {
                        year = 20 + year;
                    }
                    const calculatedDate = moment([year, parseInt(month) - 1, day]);
                    intern.gender = intern.curp.charAt(10);
                    intern.age = moment().diff(calculatedDate, "years");
                    intern.rfc = intern.curp.substring(0, 10);
                    return false;
                } else {
                    intern.error = "El formato del CURP no es válido";
                    return true;
                }
            }
        } 
        return true;
    };

    const verifyErrors = (intern) => {
        //my changes
        if(isITESMCompany){
            const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
            let contractStartDateAux;
            if(intern.contractStartDate=="Invalid date"){
                let fecha = moment();
                intern.contractStartDate = fecha.format("DD/MM/YYYY");
                
            }
            else{
                contractStartDateAux = moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day');
            }

            let currentDateAuxSevenDays = moment().startOf('day');
            currentDateAuxSevenDays.subtract(6,'days');

            let evaluatedDate;
            if(intern.contractEndDate=="Invalid date"){
                let fecha = moment();
                let nuevaFecha = fecha.add(6,"days");
                intern.contractEndDate = nuevaFecha.format("DD/MM/YYYY");
                evaluatedDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            }
            else{
                contractStartDateAux = moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day');
                evaluatedDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            }
            
            let maxLength = 10;

            if(intern.bank){
                if (/^(Santander)$/i.test(intern.bank)) maxLength = 11;
                else if (!/^(Bancomer|Banorte)$/i.test(intern.bank)) maxLength = 18;
            }

            let dif;
            let durationAux;
       
            if(intern.contractEndDate != "Invalid date"){
                let evaluatedEndDate = moment(intern.contractEndDate, "DD/MM/YYYY");
                dif = evaluatedEndDate.diff(moment().startOf('day'), 'days');
                durationAux = Math.ceil(evaluatedDate.diff(moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day'), "months", true));
            }
            else{
                dif=1;
            }

            if(intern.departmentError || intern.ccError || intern.generalistError || intern.collegeError || intern.facultyError || intern.careerError) {
                intern.error = "Algunos datos son incorrectos";
                return true;
            }

            if(dif <= 0){
                intern.error= "La fecha finalización del convenio debe ser mayor a la fecha actual";
                return true;
            }

            if(intern.contractDuration <= 0 && intern.contractDuration !== ''){
                intern.error = "La fecha de fin del convenio debe ser mayor a la fecha de inico del convenio";
                return true;
            }

            if(intern.contractDuration > maxMonthsContract && intern.contractDuration !== ''){
                intern.error = `La fecha fin no puede ser mayor a ${maxMonthsContract} meses de la fecha actual`;
                return true;
            }
            if(intern.contractEndDate !== "Invalid date"){
                if(currentDateAuxSevenDays.diff(contractStartDateAux, 'days') > 0 && context.user.company.interns_medical_insurance == "Sí"){
                    intern.error = "La fecha inicio del convenio no debe ser 7 días menor que la fecha actual";
                    return true;
                }
            }

            if(!emailRegex.test(intern.email) && intern.email !== ''){
                intern.error = "El correo electrónico es inválido, corrígelo e intenta nuevamente";
                return true;
            };

            if(!emailRegex.test(intern.directBoss_email) && intern.directBoss_email !== ''){
                intern.error = `El correo ingresado en ${context.getGeneralistaField()} no es válido`;
                return true;
            };

            if (intern.phone && String(intern.phone).length != 10 && intern.phone !== '') {
                intern.error = "El teléfono ingresado en información proporcionada por el practicante debe tener 10 dígitos";
                return true;
            }
            if(intern.contractEndDate !== "Invalid date"){
                if(durationAux > maxMonthsContract){
                    intern.error = `La duración no puede ser mayor a ${maxMonthsContract} meses`;
                    return true;
                }
            }

            if(intern.clabe && String(intern.clabe).length != maxLength && intern.clabe !== ''){
                intern.error = `El número de cuenta ingresado debe tener ${maxLength} dígitos`;
                return true;
            }

            if(curpValidation(intern)) {
                return true;
            }

            return false;
        }
        else{
            const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
            let contractStartDateAux = moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day');
            let currentDateAuxSevenDays = moment().startOf('day');
            currentDateAuxSevenDays.subtract(6,'days');
            let evaluatedDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            let maxLength = 10;
                if (/^(Santander)$/i.test(intern.bank)) maxLength = 11;
                else if (!/^(Bancomer|Banorte)$/i.test(intern.bank)) maxLength = 18;
            
            let evaluatedEndDate = moment(intern.contractEndDate, "DD/MM/YYYY");
            const dif = evaluatedEndDate.diff(moment().startOf('day'), 'days');
            let durationAux = Math.ceil(evaluatedDate.diff(moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day'), "months", true));

            if(intern.departmentError || intern.ccError || intern.generalistError || intern.collegeError || intern.facultyError || intern.careerError) {
                intern.error = "Algunos datos son incorrectos";
                return true;
            }

            if(dif <= 0){
                intern.error= "La fecha finalización del convenio debe ser mayor a la fecha actual";
                return true;
            }

            if(intern.contractDuration <= 0){
                intern.error = "La fecha de fin del convenio debe ser mayor a la fecha de inico del convenio";
                return true;
            }

            if(intern.contractDuration > maxMonthsContract){
                intern.error = `La fecha fin no puede ser mayor a ${maxMonthsContract} meses de la fecha actual`;
                return true;
            }

            if(currentDateAuxSevenDays.diff(contractStartDateAux, 'days') > 0 && context.user.company.interns_medical_insurance == "Sí"){
                intern.error = "La fecha inicio del convenio no debe ser 7 días menor que la fecha actual";
                return true;
            }

            if(!emailRegex.test(intern.email)){
                intern.error = "El correo electrónico es inválido, corrígelo e intenta nuevamente";
                return true;
            };

            /* if(!emailRegex.test(intern.directBoss_email)){
                intern.error = `El correo ingresado en ${context.getGeneralistaField()} no es válido`;
                return true;
            }; */

            if (intern.phone && String(intern.phone).length != 10) {
                intern.error = "El teléfono ingresado en información proporcionada por el practicante debe tener 10 dígitos";
                return true;
            }

            if(durationAux > maxMonthsContract){
                intern.error = `La duración no puede ser mayor a ${maxMonthsContract} meses`;
                return true;
            }

            if(intern.clabe && String(intern.clabe).length != maxLength){
                intern.error = `El número de cuenta ingresado debe tener ${maxLength} dígitos`;
                return true;
            }

            if(curpValidation(intern)) {
                return true;
            }

            return false;

        }
    }

    const sendDocs = async (contractToEvaluate) => {
        let universityData = contractToEvaluate.collegeContact;

        
        let data = {
            name: contractToEvaluate.internFirstName + " " + contractToEvaluate.internFatherLastname + " " + contractToEvaluate.internMotherLastname,
            email: contractToEvaluate.email
        };

        let internDataToUpdate = {
            _id: contractToEvaluate.internId,
            attributes: [
                { updateKey: "signatureStatus", updateValue: 'Pendiente' },
                { updateKey: "lastSignatureReminder", updateValue: moment().format('DD/MM/YYYY') }
            ]
        }


        let promises = [];
        let universityLogo = "";
        let signatureUniversity = "";
        let signatureIntern = "";
        let docName = contractToEvaluate.rfcAux + ' ' + (isITESMCompany ? contractToEvaluate.schoolEnrollment : '')
        + ' ' + contractToEvaluate.contractStartDate + ' ' + contractToEvaluate.contractEndDate + ' ' + moment().format('HH:mm');
        try {

            let blob = await createSignatureDocument(contractToEvaluate, signatureIntern, universityLogo, signatureUniversity, context.user.company.logo_url);
            
            if (blob) {
                let phone = contractToEvaluate.internPhone ||  "";
                let phoneLada = "52" + phone;
                let phoneNumber = parseInt(phoneLada);
                let signers = [{
                    name: contractToEvaluate.internFirstName || " ",
                    lastNameP: contractToEvaluate.internFatherLastname || " ",
                    lastNameM: contractToEvaluate.internMotherLastname || " ",
                    email: contractToEvaluate.email,
                    phone: phoneNumber,
                    rfc: "",
                }]
                if (universityData.email) {
                    phone = universityData.phone || universityData.internPhone ||  "";
                    phoneLada = "52" + phone;
                    phoneNumber = parseInt(phoneLada);
                    signers.push({
                        name: universityData.firstname || " ",
                        lastNameP: universityData.father_lastname || " ",
                        lastNameM: universityData.mother_lastname || " ",
                        email: universityData.email || universityData.internEmail,
                        phone: phoneNumber,
                        rfc: "",
                    });
                }
                if(context.user.company.org_admin_email){
                    phone = context.user.company.org_admin_phone ||  "";
                    phoneLada = "52" + phone;
                    phoneNumber = parseInt(phoneLada);
                    signers.push({
                        name: context.user.company.org_admin_fullname || " ",
                        lastNameP: context.user.company.org_admin_fullname || " ",
                        lastNameM: context.user.company.org_admin_fullname || " ",
                        email: context.user.company.org_admin_email,
                        phone: phoneNumber,
                        rfc: "",
                    });
                }
                console.log(contractToEvaluate, blob, contractToEvaluate._id || uuid.v1() , false, docName, signers);
                promises.push(sendSignature(contractToEvaluate, blob, contractToEvaluate._id|| contractToEvaluate.contractId, false, docName, signers))
                promises.push(Methods.notifyIntern(data));
                promises.push(Methods.updateIntern(internDataToUpdate));
            }


            await Promise.all(promises);
        } catch (e) {
            contractToEvaluate.error = 'Error en envío de firma';
        }
    }

    const createSignatureDocument = async (internContractData, signatureIntern, universityLogo, signatureUniversity, companyLogo) => {
        const doc = <TemplatePDF internContractData={internContractData} companyLogo={companyLogo} signatureIntern={signatureIntern} universityLogo={universityLogo} signatureUniversity={signatureUniversity} context={context} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = asPdf.toBlob();
        return blob;

    }

    const sendSignature = async (data, blob, key, universitySignature, docName,signers) => {
        try {
            
            let blobBuffer = await blob.arrayBuffer()
            let buffer = Buffer.from(blobBuffer);
            
            await axios({
                method: "POST",
                url: `${URL}/send/signature`,
                data: {
                    signers:signers,
                    rfcUser: false,
                    docName: docName,
                    docId: key,
                    doc: buffer,
                    hash: key,
                    URL: URL + "/create/signature"
                }
            });
            
            if (universitySignature) {
                await axios({
                    method: "POST",
                    url: `${URL}/signature/notification/university`,
                    data: {
                        email: data.email,
                        name: data.contactName || ""
                    }
                });
            }

            return true;
        } catch (e) {
            return false;
        }
    }

    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const getInternsByClabe = async (intern) => {
        if (intern.clabe !== "" && intern.clabe !== "_") {
           
            duplicated = await Methods.getInternsByClabe(intern.clabe);
            for (let data of duplicated.data.interns) {
                if (data._id == intern._id) {
                    return false;
                }
            }
            if (duplicated && duplicated.data.total > 0) {
                intern.error = "Cuenta o clabe interbancaria existente. Favor de revisar la información.";
                return true;
            }
        }
        return false;
    };

    const getInternsByCurp = async (intern) => {
        let duplicatedCompany = false;
        if (intern.curp !== "" && intern.curp !== "_") {
            
            duplicated = await Methods.getInternsByCurp(intern.curp);
            currentId = duplicated?.data?.interns[0]?._id;  
            for (let data of duplicated.data.interns) {
                if (data._id == intern._id) {
                    return false;
                }
                if (data.id_company == intern.id_company) duplicatedCompany = true;
            }
            if (duplicatedCompany) {
                intern.error = "No se puede guardar porque el CURP ya está dado de alta en la empresa.";
                return true;
            }
        }
        return false;
    };

    const getInternsByEmail = async (intern) => {
        if (intern.email !== "" && intern.email !== "_") {
            let duplicated;
            duplicated = await Methods.getInternsByEmail(intern.email);
            for (let data of duplicated.data.interns) {
                if (data._id == intern._id) {
                    return false;
                }
            }
            if (duplicated && duplicated.data.total > 0) {
                intern.error = "El correo de este practicante ha sido registrado previamente";
                return true;
            }
        }
        return false;
    };

    const getAllUsersByEmail = async (intern) => {
        if (intern.email !== "" && intern.email !== "_") {
            let duplicated;
            duplicated = await Methods.getAllUsersByEmail(intern.email);
            for (let data of duplicated.data.users) {
                if (data._id == intern._id) {
                    return false;
                }
            }
            if (duplicated && duplicated.data.total > 0) {
                intern.error = "El correo de este practicante ha sido registrado previamente como usuario";
                return true;
            }
        }
        return false;
    };

    const handleNewIntern = async (intern, type) => {
        console.log("CAMBIOS", type)
        let exist = false;
        try{
            //MY CHANGES
            console.log(intern);
            console.log(context);
            if(isITESMCompany){
                await getInternsByCurp(intern);
                let internTmp = duplicated.data.interns[0];
                const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
                intern.surveyKey = uuid.v1();
                intern.currentInsuranceId = uuid.v1();
                intern.requireInsurance = context.user.company.interns_medical_insurance == "Sí" ? true : false;
                intern.contractEndDate = intern.contractEndDate ? intern.contractEndDate : internTmp.contractEndDate;
                intern.contractStartDate = intern.contractStartDate ? intern.contractStartDate : internTmp.contractStartDate;
                intern.depurate_time = (intern.depurate_time && intern.depurate_time !== 'Invalid date' && intern.depurate_time !== 'am' && intern.depurate_time!=='HH:mm am' && intern.depurate_time!=='HH:mm pm') ? intern.depurate_time : internTmp.depurate_time;
                intern.entry_time = (intern.entry_time && intern.entry_time !== 'Invalid date' && intern.entry_time !== 'am' && intern.entry_time!=='HH:mm am' && intern.entry_time!=='HH:mm pm')  ? intern.entry_time : internTmp.entry_time;  
                intern.emailCase = 'Alta';
                intern.id_company = context.user.company._id;
                intern.bank = context.user.company.internship_bank;
                intern.created_by = context.user.data._id;
                intern.insurancePayment = context.user.company.insurance_payment;
                intern.createdByEmail = context.user.data.email;
                intern.createdByFullName = context.user.data.fullName;
                intern.cost_center_id = intern?.cost_center?.cost_center_id ? intern?.cost_center?.cost_center_id : internTmp.cost_center_id;
                intern.cost_center_name = intern?.cost_center?.cost_center_name ? intern?.cost_center?.cost_center_name : internTmp.const_center_name;
                intern.department_id = intern?.department?.department_id ? intern?.department?.department_id : internTmp.department_id;
                intern.department_name = intern?.department?.department_name? intern?.department?.department_name : internTmp.department_name;
                intern.clabe = String(intern?.clabe) ? String(intern?.clabe) : internTmp?.clabe;
                /* if(!intern.disabledDuration) {
                    getNewDate(intern);
                } */
                
                intern.firstname = intern.firstname ? intern.firstname.toUpperCase() : internTmp.firstname;
                
                intern.father_lastname = intern.father_lastname ? intern.father_lastname.toUpperCase() : internTmp.fatherLastName;
                intern.mother_lastname = intern.mother_lastname ? intern.mother_lastname.toUpperCase() : internTmp.motherLastName;
                intern.email = intern.email ? intern.email.toLowerCase() : internTmp.email;
                intern.scolarshipAmount = intern.scolarshipAmount ? (parseFloat(intern.scolarshipAmount) || 0).toFixed(2) : internTmp.scolarshipAmount; 
                intern.entry_time = (intern.entry_time && intern.entry_time !== 'Invalid date' && intern.entry_time !== 'am') ? intern.entry_time.substring(0,8) : internTmp.entry_time;
                intern.depurate_time = (intern.depurate_time && intern.depurate_time !== 'Invalid date' && intern.depurate_time !== 'am') ? intern.depurate_time.substring(0,8) : internTmp.depurate_time;      
                if(intern?.birthday !== undefined && intern?.birthday !== "Invalid date"){
                    intern.birthday = intern?.birthday.match(regex) ? intern?.birthday : internTmp.birthday;
                }
                else{
                    intern.birthday = "";
                }

           
                if(verifyErrors(intern)) return;
                if(type!== "modify"){
                    if (await getInternsByClabe(intern)) {
                        return;}
                    }
                if (await getInternsByCurp(intern)){
                    exist = true;
                    console.log(currentId);
                    if(currentId !== undefined){
                        intern._id = currentId;
                    }
                    currentId = undefined;
                    //return;
                } 
                if(type!== "modify"){
                    if (await getInternsByEmail(intern)) return;
                    if (await getAllUsersByEmail(intern)) return;
                }
                intern.approveIntern = (intern.contractApprovalDate && intern.contractApprovalDate != 'Invalid date' ) ? true : false;
                if (intern.approveIntern){
                    intern.contractApprovalDateAux = moment(intern.contractApprovalDate, 'DD/MM/YYYY').format();
                    let statusAux = moment(intern.contractStartDate, 'DD/MM/YYYY').isSameOrBefore(moment()) ? 'Activo' : 'Pendiente';
                    intern.internStatus = statusAux;
                    intern.contractStatus = statusAux;
                    intern.forceApprove = true;
                    setContractValues(intern, statusAux);
                }
                const urlStartProcess = `${window.location.origin}/internship/${intern.surveyKey}`;
                if(intern.requireInsurance){
                    if(await createInsurance(intern, context.user.company)){
                        intern.error = "OK";
                        intern.processed = true ;
                        intern.createdInsurance = true;
                    }else{
                        intern.error = "Error en datos de seguro";
                        intern.processed = false;
                        return;
                    }
                }
                if(intern.email ==""){
                    intern.email = "chbrant7@gmail.com";
                }
                const emailParams = {
                    to: intern.email,
                    subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
                    text: `¡Bienvenido ${intern.firstname} ${intern.father_lastname} ${intern.mother_lastname}! <br/> 
        
                    Estás a punto de iniciar el primer paso hacía tu desarrollo profesional.
                    <br/>
        
                    <span style="font-weight: bold;">¡Ingresa ahora y contesta el formulario de prácticas!</span>
                    <br/>
        
                    Link Web: <a href="${urlStartProcess}">${urlStartProcess}</a>
                    <br/>
                    Consulta el archivo adjunto que te será de ayuda para conocer los beneficios de <span style="color: #0080fd;">Practicantes CAINTRA</span>
                    <br/>
        
                    <a href="https://caintrabucket-prod.s3.amazonaws.com/mailImages/ToolKit.pdf">ToolKit</a>
                    <br/>
                    En caso de presentar problemas para acceder, por favor notifícalo al correo de atención:
                    <br/>
                    <span style="color: #0080fd;"><a href="mailto:soporte.practicantes@caintra.org.mx">soporte.practicantes@caintra.org.mx</a></span>
                    <br>
                    Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.
           
                    `,
                 
                };
                /* const emailParams = {
                    to: intern.email,
                    subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
                    text: `Estás a punto de iniciar el primer paso hacia tu desarrollo profesional.
                    <br/><br/> Por favor ingresa a este link para comenzar tu proceso: <a href="${urlStartProcess}">${urlStartProcess}</a>
                    <br/><br/> En caso de presentar problemas para accesar por favor notificalo mediante el correo  de atención: <a href="mailto:practicantes@caintra.org.mx">practicantes@caintra.org.mx</a>
                    ${requireSife ? `<br/><br/> Tambien crea una cuenta en <a href="${sifeEmailUrl()}">${sifeEmailUrl()}</a> para poder firmar tus documentos posteriormente` : ""}`,
                    internName: intern.firstname
                }; */
                if(type !== "modify"){
                    
                    if(await Methods.createInternUploads(intern, emailParams)) return;
                }
                else{
                    if(exist){
                        console.log("MODIFICANDO")
                        let dataIntern = {
                            _id : intern._id,
                            attributes : []
                        };
                        for(let item in intern){
                            if(item !== "_id" && item!== "error"  && item!== "processed" && item!=="index" && intern[item] !=='' && intern[item] !== undefined && item !== "contractValues" ) {
                                dataIntern.attributes.push({updateKey : item, updateValue : intern[item]});
                            }
                        }
                        console.log(dataIntern)
                        if(await Methods.updateIntern(dataIntern)) return;

                    }
                    else{
                        console.log("AÑADIENDO")
                        if(await Methods.createInternUploads(intern, emailParams)) return;
                    }
                
                }
    
                if(requireSife && intern.forceApprove){
                    await sendDocs(intern.contractValues);
                    if(isITESMCompany) await sendITESMdocs(intern.contractValues)
                }
                await Methods.massiveUploadNotification({_id: intern._id, emailCase: intern.emailCase});
                intern.error = "OK";
                intern.processed = true;

            }
            else{

                const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
                intern.surveyKey = uuid.v1();
                intern.currentInsuranceId = uuid.v1();
                intern.requireInsurance = context.user.company.interns_medical_insurance == "Sí" ? true : false;
                intern.emailCase = 'Alta';
                intern.id_company = context.user.company._id;
                intern.bank = context.user.company.internship_bank;
                intern.created_by = context.user.data._id;
                intern.insurancePayment = context.user.company.insurance_payment;
                intern.createdByEmail = context.user.data.email;
                intern.createdByFullName = context.user.data.fullName;
                intern.cost_center_id = intern.cost_center.cost_center_id;
                intern.cost_center_name = intern.cost_center.cost_center_name;
                intern.department_id = intern.department.department_id;
                intern.department_name = intern.department.department_name;
                intern.clabe = String(intern.clabe);
                if(!intern.disabledDuration) {
                    getNewDate(intern);
                }
                intern.firstname = intern.firstname ? intern.firstname.toUpperCase() : "";
                intern.father_lastname = intern.father_lastname ? intern.father_lastname.toUpperCase() : "";
                intern.mother_lastname = intern.mother_lastname ? intern.mother_lastname.toUpperCase() : "";
                intern.email = intern.email ? intern.email.toLowerCase() : "";
                intern.scolarshipAmount = (parseFloat(intern.scolarshipAmount) || 0).toFixed(2)
                intern.entry_time = (intern.entry_time && intern.entry_time != 'Invalid date' ) ? intern.entry_time.substring(0,8) : ''
                intern.depurate_time = (intern.depurate_time && intern.depurate_time != 'Invalid date') ? intern.depurate_time.substring(0,8) : '' 

                if(intern?.birthday !== undefined && intern?.birthday !== "Invalid date"){
                    intern.birthday = intern?.birthday.match(regex) ? intern?.birthday : "";
                }    
                else{
                    intern.birthday = "";
                }

                if(verifyErrors(intern)) return;
                if (await getInternsByClabe(intern)) return;
                if (await getInternsByCurp(intern)) return;
                if (await getInternsByEmail(intern)) return;
                if (await getAllUsersByEmail(intern)) return;
                intern.approveIntern = (intern.contractApprovalDate && intern.contractApprovalDate != 'Invalid date' ) ? true : false;
                if (intern.approveIntern){
                    intern.contractApprovalDateAux = moment(intern.contractApprovalDate, 'DD/MM/YYYY').format();
                    let statusAux = moment(intern.contractStartDate, 'DD/MM/YYYY').isSameOrBefore(moment()) ? 'Activo' : 'Pendiente';
                    intern.internStatus = statusAux;
                    intern.contractStatus = statusAux;
                    intern.forceApprove = true;
                    setContractValues(intern, statusAux);
                }
                const urlStartProcess = `${window.location.origin}/internship/${intern.surveyKey}`;
                if(intern.requireInsurance){
                    if(await createInsurance(intern, context.user.company)){
                        intern.error = "OK";
                        intern.processed = true ;
                        intern.createdInsurance = true;
                    }else{
                        intern.error = "Error en datos de seguro";
                        intern.processed = false;
                        return;
                    }
                }
                const emailParams = {
                    to: intern.email,
                    subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
                    text: `¡Bienvenido ${intern.firstname} ${intern.father_lastname} ${intern.mother_lastname}! <br/> 
        
                    Estás a punto de iniciar el primer paso hacía tu desarrollo profesional.
                    <br/>
        
                    <span style="font-weight: bold;">¡Ingresa ahora y contesta el formulario de prácticas!</span>
                    <br/>
        
                    Link Web: <a href="${urlStartProcess}">${urlStartProcess}</a>
                    <br/>
                    Consulta el archivo adjunto que te será de ayuda para conocer los beneficios de <span style="color: #0080fd;">Practicantes CAINTRA</span>
                    <br/>
        
                    <a href="https://caintrabucket-prod.s3.amazonaws.com/mailImages/ToolKit.pdf">ToolKit</a>
                    <br/>
                    En caso de presentar problemas para acceder, por favor notifícalo al correo de atención:
                    <br/>
                    <span style="color: #0080fd;"><a href="mailto:soporte.practicantes@caintra.org.mx">soporte.practicantes@caintra.org.mx</a></span>
                    <br>
                     Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.
           
                    `,
                 
                };
                /* const emailParams = {
                    to: intern.email,
                    subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
                    text: `Estás a punto de iniciar el primer paso hacia tu desarrollo profesional.
                    <br/><br/> Por favor ingresa a este link para comenzar tu proceso: <a href="${urlStartProcess}">${urlStartProcess}</a>
                    <br/><br/> En caso de presentar problemas para accesar por favor notificalo mediante el correo  de atención: <a href="mailto:practicantes@caintra.org.mx">practicantes@caintra.org.mx</a>
                    ${requireSife ? `<br/><br/> Tambien crea una cuenta en <a href="${sifeEmailUrl()}">${sifeEmailUrl()}</a> para poder firmar tus documentos posteriormente` : ""}`,
                    internName: intern.firstname
                }; */
    
                if(await Methods.createInternUploads(intern, emailParams)) return;
    
                if(requireSife && intern.forceApprove){
                    await sendDocs(intern.contractValues);
                    if(isITESMCompany) await sendITESMdocs(intern.contractValues)
                }
                await Methods.massiveUploadNotification({_id: intern._id, emailCase: intern.emailCase});
                intern.error = "OK";
                intern.processed = true;
            }
        }catch(e){
            intern.error = "Datos erróneos " + e;
            intern.processed = false;
        }
    };

    const verifyCurp = async (intern) => {
        intern.data = "";

        if (intern.curp !== "" && intern.curp !== "_" && intern.curp) {
            let internAux = await Methods.getInternsByCurp(intern.curp, context.user.company._id || '');
            
            if(internAux.data.total == 0){
                intern.error = "No hay registros que coincidan con esta curp";
                return true;
            }

            if(mode == 'renew' && (internAux.data.interns[0].internStatus == "En proceso" || internAux.data.interns[0].internStatus == 'Pendiente')){
                intern.error = "No se puede renovar este practicante debido a su estatus actual";
                return true;
            }

            intern.data = internAux.data.interns[0];
            intern._id = intern.data._id;

        } else {
            intern.error = "CURP obligatoria";
            return true;
        }
    };

    const verifyErrorsRenew = async (intern) => {
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
        if(await verifyCurp(intern)) return true;
        if(intern.departmentError || intern.ccError || intern.generalistError || intern.collegeError || intern.facultyError || intern.careerError) {
            intern.error = "Algunos datos son incorrectos";
            return true;
        }
        if(isITESMCompany && !emailRegex.test(intern.email)){
            intern.error = "El correo electrónico es inválido, corrígelo e intenta nuevamente";
            return true;
        };
        if(isITESMCompany && !emailRegex.test(intern.directBoss_email)){
            intern.error = `El correo ingresado en ${context.getGeneralistaField()} no es válido`;
            return true;
        };

        let evaluatedEndDate = moment(intern.contractEndDate, "DD/MM/YYYY");
        const dif = evaluatedEndDate.diff(moment().startOf('day'), 'days');

        if(dif <= 0){
            intern.error= "La fecha finalización del convenio debe ser mayor a la fecha actual";
            return true;
        }

        if(intern.contractDuration <= 0){
            intern.error = "La fecha de fin del convenio debe ser mayor a la fecha de inico del convenio";
            return true;
        }

        if(intern.contractDuration > maxMonthsContract){
            intern.error = `La fecha fin no puede ser mayor a ${maxMonthsContract} meses de la fecha de inicio`;
            return true;
        }

        let internContractData = intern.data ? await Methods.getAllInternContracts(intern.data._id) : [];

        if (internContractData.length) {
            for (let contract of internContractData) {
                if (contract.contractStatus == "En proceso" || contract.contractStatus == 'Pendiente'){
                    intern.error = "El prácticante tiene un convenio en proceso";
                    return true;
                }
            }
        }

        let currentContract = internContractData?.find(contract => contract._id == intern.data.currentContractId);
        let startDate;
        let startDateToEvaluate;
        let notifyText;
        let currentDate = moment().startOf('day');

        intern.currentContract = currentContract;
        intern.scolarshipAmount = (parseFloat(intern.scolarshipAmount) || 0).toFixed(2)

        if(intern.data.internStatus == "Activo" && !currentContract?.unsuscribeDate){
            startDateToEvaluate = moment(intern.data.contractEndDate, "DD/MM/YYYY").startOf('day');
            startDate = moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day');
            notifyText = "El inicio del convenio debe ser mayor a la fecha de fin de convenio actual";
            if(startDate.diff(startDateToEvaluate, 'days') <= 0){
                intern.error = notifyText;
                return true;
            }
        }else{
            startDateToEvaluate = moment(intern.data.contractStartDate, "DD/MM/YYYY").startOf('day');
            startDate = moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day');
            notifyText = "El inicio del convenio debe ser mayor a la fecha de fin de convenio actual";
            if(currentContract?.unsuscribeDate){
                startDateToEvaluate = moment(currentContract?.unsuscribeDate).startOf('day');
                notifyText = "El inicio del convenio debe ser mayor a la fecha de baja de practicante";
            }
            if(currentContract.contractStatus == 'Invalidado'){
                startDateToEvaluate = moment(intern.data.contractStartDate, "DD/MM/YYYY").subtract(6, 'day').startOf('day');
                notifyText = "La fecha de inicio no puede ser 7 días menor que el inicio del convenio invalidado";
            }
            if(startDate.diff(startDateToEvaluate, 'days') <= 0){
                intern.error = notifyText;
                return true;
            }
        }
        startDate = moment(intern.contractStartDate, "DD/MM/YYYY").startOf('day');
        if(startDate.diff(currentDate, 'days') > 30){
            intern.error = 'El inicio de convenio no puede ser mayor a 30 días a partir de hoy';
            return true
        }
        if(!intern.data.college || !intern.data.faculty || !intern.data.career){
            intern.error = "El practicante no cuenta con datos escolares";
            return true;
        }
        return false;
    };

    const setUniversityContact = (currentUniversity, currentFaculty) => {
        if (currentUniversity == "Otro") return {};
        if (!currentUniversity) return {};
        let universitySelected;
        let universityContact = {};
        for (let university of universities) {
            if (university._name == currentUniversity) {
                universitySelected = university;
            }
        }
        if (universitySelected?.sign) {
            universityContact = {
                phone: universitySelected.phone,
                email: universitySelected.email,
                firstname: universitySelected.contactName,
                father_lastname: universitySelected.fatherLastName,
                mother_lastname: universitySelected.motherLastName
            }
        } else {
            if (!universitySelected) return {};
            for (let faculty of universitySelected.faculties) {
                if (faculty._name == currentFaculty) {
                    universityContact = {
                        phone: faculty.phone,
                        email: faculty.email,
                        firstname: faculty.contactName,
                        father_lastname: faculty.fatherLastName,
                        mother_lastname: faculty.motherLastName
                    }
                }
            }
        }
        if (universitySelected?.logo_url) universityContact.logo = universitySelected?.logo_url;
        return universityContact;
    }

    const renewContract = async ({renewUnsuscribedInternData, intern, contractApprovalDate}) => {
        let result = await Methods.getCompanies([intern.data.id_company]);
        let companyData = result[0];
        let universityData = {};

        if (intern.data.college) {
            universityData = await setUniversityContact(intern.data.college, intern.data.faculty);
        }

        let data = {
            contractId: intern.newContractId || uuid.v1(),
            internId: intern.data._id,
            collegeName: intern.data.college,
            collegeContact: universityData || "",
            schoolEnrollment: intern.school_enrollment || intern.data.school_enrollment,
            internScholarship: intern.data.scholarship,
            internCareer: intern.data.career || "",
            internFaculty: intern.data.faculty,
            internStreet: intern.data.street,
            internExtNumber: intern.data.ext_number,
            internSuburb: intern.data.suburb,
            internMunicipality: intern.data.municipality,
            internPhone: intern.data.phone,
            email: intern.email || intern.data.email,
            contractEndDate: renewUnsuscribedInternData ? renewUnsuscribedInternData[0].updateValue : intern.data.contractEndDate,
            contractStartDate: renewUnsuscribedInternData ? renewUnsuscribedInternData[1].updateValue : intern.data.contractStartDate,
            internContractDuration: renewUnsuscribedInternData ? renewUnsuscribedInternData[2].updateValue : intern.data.contractDuration,
            internAmount: intern.scolarshipAmount,
            companyName: companyData.business_name,
            companyAdminPhone: companyData.org_admin_phone,
            companyAddress: companyData.street + " " + companyData.ext_number + " " + companyData.zip_code + " " + companyData.suburb + " " + companyData.municipality + " " + companyData.state,
            isSifeDocument: requireSife,
            createdByFullName: context.user.data.fullName,
            createdByEmail: context.user.data.email,
            directBoss: intern.directBoss_name || intern.data.directBoss_name,
            departmentName: intern.data.department_name,
            id_company: companyData._id,
            companyId: companyData._id,
            internFirstName: intern.firstname || intern.data.firstname,
            internFatherLastname: intern.father_lastname || intern.data.father_lastname,
            internMotherLastname: intern.mother_lastname || intern.data.mother_lastname,
            curp: intern.data.curp,
            lastContractId: intern.currentContract?._id,
            lastContract: intern.currentContract,
            isLastContractInvalid: false,
            isFutureRenovation: renewUnsuscribedInternData ? renewUnsuscribedInternData[5].updateValue : false,
            contractStatus: renewUnsuscribedInternData ? renewUnsuscribedInternData[4].updateValue : 'En proceso',
            contractApprovalDate: contractApprovalDate ? moment(contractApprovalDate, 'DD/MM/YYYY') : "",
            dateChanged: false,
            currentInsuranceId: "-",
            isRetroactivePayment: intern.isRetroactivePayment || false,
            rfcAux: intern.data.rfc,
            requireInsurance: intern.requireInsurance ? true : false
        }
        if(requireSife && data.contractStatus != 'En proceso'){
            data.isSifeDocument = requireSife;
            data.signatureStatus = 'Pendiente';
            data.lastSignatureReminder = moment().format('DD/MM/YYYY');
        } 
        await Methods.renewIntern(data);

        if(data.contractStatus != 'En proceso' && requireSife) {
            await sendDocs(data);   
        }
    };

    const setContractValues = (intern) => {

        let universityData = {};
        if (intern.college) {
            universityData = setUniversityContact(intern.college, intern.faculty);
        }

        intern.contractValues = {
            internId: intern._id,
            collegeName: intern.college,
            collegeContact: universityData || "",
            schoolEnrollment: intern.school_enrollment,
            internScholarship: intern.scholarship,
            internCareer: intern.career || "",
            internFaculty: intern.faculty,
            internStreet: intern.street,
            internExtNumber: intern.ext_number,
            internSuburb: intern.suburb,
            internMunicipality: intern.municipality,
            internPhone: intern.phone,
            email: intern.email,
            contractEndDate: intern.contractEndDate,
            contractStartDate: intern.contractStartDate,
            internContractDuration: intern.contractDuration,
            internAmount: intern.scolarshipAmount,
            companyName: context.user.company.business_name,
            companyAdminPhone: context.user.company.org_admin_phone,
            companyAddress: context.user.company.street + " " + context.user.company.ext_number + " " + context.user.company.zip_code + " " + context.user.company.suburb + " " + context.user.company.municipality + " " + context.user.company.state,
            isSifeDocument: false,
            createdByFullName: context.user.data.fullName,
            createdByEmail: context.user.data.email,
            directBoss: intern.directBoss_name,
            departmentName: intern.department_name,
            id_company: context.user.company._id,
            companyId: context.user.company._id,
            internFirstName: intern.firstname,
            internFatherLastname: intern.father_lastname,
            internMotherLastname: intern.mother_lastname,
            curp: intern.curp,
            lastContractId: intern.currentContract?._id,
            lastContract: intern.currentContract,
            isLastContractInvalid: false,
            isFutureRenovation: false,
            dateChanged: false,
            currentInsuranceId: "-",
            internStatus: intern.internStatus,
            contractStatus: intern.contractStatus,
            contractApprovalDate: intern.contractApprovalDateAux,
            isRetroactivePayment: intern.isRetroactivePayment || false,
            contractId: uuid.v1(),
            rfcAux: intern.rfc,
            requireInsurance: intern.requireInsurance ? true : false
        }

        if(requireSife){
            intern.contractValues.isSifeDocument = requireSife;
            intern.contractValues.signatureStatus = 'Pendiente';
            intern.contractValues.lastSignatureReminder = moment().format('DD/MM/YYYY');
        } 

    }

    const handleRenewIntern = async (intern) => {
        try{
            if(isITESMCompany) {
                intern.cost_center_id = intern.cost_center.cost_center_id;
                intern.cost_center_name = intern.cost_center.cost_center_name;
                intern.firstname = intern.firstname ? intern.firstname.toUpperCase() : "";
                intern.father_lastname = intern.father_lastname ? intern.father_lastname.toUpperCase() : "";
                intern.mother_lastname = intern.mother_lastname ? intern.mother_lastname.toUpperCase() : "";
                intern.email = intern.email ? intern.email.toLowerCase() : "";
            }
            if(await verifyErrorsRenew(intern)) return;
            if (isITESMCompany && await getInternsByEmail(intern)) return;
            if (isITESMCompany && await getAllUsersByEmail(intern)) return;
            if(!intern.disabledDuration) {
                getNewDate(intern);
            }
            let isFutureRenovation = intern.currentContract.contractStatus == "Activo";
            let approveInternStatus = intern.approveIntern ? (moment(intern.contractStartDate, 'DD/MM/YYYY').isSameOrBefore(moment()) && !isFutureRenovation ? 'Activo' : 'Pendiente' ) : 'En proceso';
            let contractApprovalDate = intern.contractApprovalDate || ''
            let data = {
                _id: intern.data._id,
                attributes: [
                    { updateKey: "contractEndDate", updateValue: intern.contractEndDate },
                    { updateKey: "contractStartDate", updateValue: intern.contractStartDate },
                    { updateKey: "contractDuration", updateValue: intern.contractDuration},
                    { updateKey: "scolarshipAmount", updateValue: intern.scolarshipAmount },
                    { updateKey: "internStatus", updateValue: approveInternStatus},
                    { updateKey: "isFutureRenovation", updateValue: isFutureRenovation}
                ],
                emailCase:"Renovacion"
            };
            let contractIdAux = uuid.v1();
            intern.newContractId = contractIdAux;
            if(intern.data.requireInsurance){
                let internDataAux ={
                    firstname: intern.data.firstname,
                    father_lastname: intern.data.father_lastname,
                    mother_lastname: intern.data.mother_lastname,
                    street: intern.data.street,
                    ext_number: intern.data.ext_number,
                    planType: intern.planType,
                    contractStartDate: intern.contractStartDate,
                    contractEndDate: intern.contractEndDate,
                    curp: intern.curp,
                    paymentType: intern.paymentType,
                    contractDuration: intern.contractDuration,
                    percent: intern.percent,
                    berneficiary: intern.berneficiary,
                    relationship: intern.relationship,
                    percentSecond: intern.percentSecond.toString().replace(/\s+/g, '') || 0,
                    berneficiarySecond: intern.berneficiarySecond || "",
                    relationshipSecond: intern.relationshipSecond || "",
                    suburb: intern.data.suburb,
                    college: intern.college,
                    scholarship: intern.data.scholarship,
                    phone: intern.data.phone,
                    career: intern.data.career, 
                    periodType: intern.data.period_type,
                    scolarshipAmount: intern.scolarshipAmount,
                    email: intern.data.email,
                    currentInternId: intern.data._id,
                    currentInsuranceId: uuid.v1(),
                    rfc: intern.data.rfc,
                    _id: intern.data._id,
                    renew: true,
                    insurancePayment: intern.insurancePayment,
                    requireInsurance: intern.data.requireInsurance,
                    contractValues: {contractId: contractIdAux}
                }
                if(await createInsurance(internDataAux, context.user.company)){
                    intern.error = "OK";
                    intern.processed = true ;
                    intern.createdInsurance = true;
                }else{
                    intern.error = "Error en datos de seguro";
                    intern.processed = false;
                    return;
                }
            }
            await renewContract({renewUnsuscribedInternData: data.attributes, intern, contractApprovalDate});
            if(intern.currentContract.contractStatus == "Inactivo" || intern.currentContract.contractStatus == "Invalidado"){
                let internData = data;
                internData.attributes.pop();
                internData.lastStatus = "Inactivo";
                if(await Methods.updateInternUploads(intern,internData)) return;
            }

            data = {
                _id: intern.data._id,
                attributes: [
                    { updateKey: "grade", updateValue: intern.grade },
                ],
                emailCase: (intern.currentContract.contractStatus == 'Activo' || intern.currentContract.contractStatus == 'Pendiente' ) ? 'Update' : undefined
            };
            if(isITESMCompany){
                data.attributes.push({ updateKey: "firstname", updateValue: intern.firstname || intern.data.firstname });
                data.attributes.push({ updateKey: "father_lastname", updateValue: intern.father_lastname || intern.data.father_lastname });
                data.attributes.push({ updateKey: "mother_lastname", updateValue: intern.mother_lastname || intern.data.mother_lastname });
                data.attributes.push({ updateKey: "school_enrollment", updateValue: intern.school_enrollment || intern.data.school_enrollment });
                data.attributes.push({ updateKey: "email", updateValue: intern.email || intern.data.email });
                data.attributes.push({ updateKey: "cost_center_name", updateValue: intern.cost_center_name || intern.data.cost_center_name });
                data.attributes.push({ updateKey: "cost_center_id", updateValue: intern.cost_center_id || intern.data.cost_center_id });
                data.attributes.push({ updateKey: "directBoss_name", updateValue: intern.directBoss_name || intern.data.directBoss_name });
                data.attributes.push({ updateKey: "directBoss_email", updateValue: intern.directBoss_email || intern.data.directBoss_email });
                data.attributes.push({ updateKey: "projectName", updateValue: intern.projectName || intern.data.projectName });
                data.attributes.push({ updateKey: "leaderTec", updateValue: intern.leaderTec || intern.data.leaderTec });
                data.attributes.push({ updateKey: "position", updateValue: intern.position || intern.data.position, expressionAttributeName: `#${"position"}`});
                data.attributes.push({ updateKey: "program", updateValue: intern.program || intern.data.program });
            }
            if(await Methods.updateInternUploads(intern,data)) return;

            intern.error = "OK";
            intern.processed = true;

        }catch(e){
            intern.error = "Error en datos del practicante";
            intern.processed = false;
        }
    };

    const compareDates = (oldDate) => {
        let oldMoment =  moment(oldDate.substring(0,10))
        let newMoment =  moment()
        let diff = newMoment.diff(oldMoment,"days");
        return diff > 7
    }

    const verifyErrorsUnsubscribe = async (intern) => {
        if(await verifyCurp(intern)) return true;
        let internContractData = intern.data ? await Methods.getAllInternContracts(intern.data._id) : [];
        let currentContract = internContractData?.find(contract => contract._id == intern.data.currentContractId);
        intern.currentContract = currentContract;
        intern.contracts = internContractData;

        if (currentContract.contractStatus == "Invalidado" || currentContract.contractStatus == "Inactivo") {
            intern.error = "Esta acción no esta disponible para el convenio actual del practicante";
            return true;
        }

        if (currentContract.contractStatus == "En proceso") {
            intern.error = "No puedes dar de baja un convenio si aún no esta activo";
            return true;
        }

        let start =  moment(currentContract.internStartDate, "DD/MM/YYYY");
        let finish =  moment(currentContract.internEndDate, "DD/MM/YYYY");
        let dateSelectedAux = moment(intern.unsuscribeDate, "DD/MM/YYYY").startOf('day');

        if(dateSelectedAux.isAfter(finish)){
            intern.error = "La fecha de baja no puede ser posterior al final del convenio ";
            return true;
        }

        if(dateSelectedAux.isBefore(start)){
            intern.error = "La fecha de baja no puede ser anterior al inicio del convenio ";
            return true;
        }

        if(intern.unsuscribeMotive.trim() == ""){
            intern.error = "El motivo de la baja no puede estar vacío";
            return true;
        };

        if(currentContract.unsuscribeDate && compareDates(currentContract.unsuscribeDate)){
            intern.error = " No se puede modificar este campo si ya pasaron 7 días después de la última baja ";
            return true;
        }

        return false;
    };

    const verifyPendingContract = intern => {
        for (let contract of intern.contracts) {
            if (contract.contractStatus == 'Pendiente' && intern.currentContract._id != contract._id) return true;
        }
        return false;
    }

    const getLastContract = intern => {
        let startDate = moment(intern.currentContract.internStartDate, "DD/MM/YYYY").startOf('day');
        for (let contract of intern.contracts) {
            let contractEndDate = contract.unsuscribeDate ? moment(contract.unsuscribeDate).startOf('day') : moment(contract.internEndDate, "DD/MM/YYYY").startOf('day')
            if (contract.contractStatus != 'Invalidado' && startDate.isAfter(contractEndDate) && contract._id != intern.currentContract._id) return contract;
        }
    }

    const setLastSignatureStatus = async (intern) => {
        let internData = {
            _id: intern.currentContract.internId
        }
        if (requireSife) {
            if(!verifyPendingContract(intern)) {
                let lastContract = getLastContract(intern);
                if (lastContract && lastContract.signatureStatus && lastContract.lastSignatureReminder) {
                    internData.attributes = [{ updateKey: "signatureStatus", updateValue: lastContract.signatureStatus },
                    { updateKey: "lastSignatureReminder", updateValue: lastContract.lastSignatureReminder }]
                } else {
                    internData.attributes = [{ updateKey: "signatureStatus", updateValue: '' },
                    { updateKey: "lastSignatureReminder", updateValue: ''}]
                };
                await Methods.updateIntern(internData)
            }
        } 
    }

    const sortPolicies = (policies) => {
        policies.sort(function (a, b) {
            let momentA = a.contractStartDate.includes("/") ? moment(a.contractStartDate, "DD/MM/YYYY").startOf("day") : moment(a.contractStartDate.slice(0, 10)).startOf("day");
            let momentB = b.contractStartDate.includes("/") ? moment(b.contractStartDate, "DD/MM/YYYY").startOf("day") : moment(b.contractStartDate.slice(0, 10)).startOf("day");

            return momentA.isAfter(momentB) ? -1 : 1;
        });
        return policies;
    }

    const replacePolicies = async (intern) => {
        let newInsuranceData;
        let actualPolicies = await Methods.getPolicyById(intern.data._id);
        if(actualPolicies.policy.length){
            let sortedPolicies = sortPolicies(actualPolicies.policy)
            let contractSortedPolicies = sortedPolicies.filter(policy => {
                return policy.contractId == intern.currentContract._id
            })
            if(!contractSortedPolicies.length) return;
            let lastPolicy = contractSortedPolicies[contractSortedPolicies.length-1];
            let momentAux = new moment(intern.unsuscribeDate)
            newInsuranceData = {
                policyId: lastPolicy._id,
                unsubscribe: momentAux.format("DD/MM/YYYY"),
            }
            await Methods.resupplyPolicies(newInsuranceData);
        }
    }

    const handleUnsubscribeIntern = async (intern) => {
        if(isITESMCompany) {
            intern.firstname = intern.firstname ? intern.firstname.toUpperCase() : "";
            intern.father_lastname = intern.father_lastname ? intern.father_lastname.toUpperCase() : "";
            intern.mother_lastname = intern.mother_lastname ? intern.mother_lastname.toUpperCase() : "";
        }

        if(await verifyErrorsUnsubscribe(intern)) return;
        let currentContractData = {
            _id: intern.currentContract._id,
            attributes: [],
            approved: false,
            sendDocument: false,
            startDate: intern.currentContract.internStartDate,
            duration: intern.currentContract.internContractDuration,
            currentInsurance: intern.data.insurance
        }

        let internData = {
            _id: intern.currentContract.internId,
            attributes: []
        };

        let dateSelectedAux = moment(intern.unsuscribeDate, "DD/MM/YYYY").startOf('day');
        let currentDate = moment().startOf('day');

        if(currentDate.diff(dateSelectedAux) > 0){
            currentContractData.attributes.push({updateKey: "contractStatus", updateValue: "Inactivo"})
            internData.attributes.push({updateKey: "internStatus", updateValue: "Inactivo"});
            internData.lastStatus = intern.internStatus;
        }

        if(isITESMCompany){
            internData.attributes.push({ updateKey: "firstname", updateValue: intern.firstname || intern.data.firstname });
            internData.attributes.push({ updateKey: "father_lastname", updateValue: intern.father_lastname || intern.data.father_lastname });
            internData.attributes.push({ updateKey: "mother_lastname", updateValue: intern.mother_lastname || intern.data.mother_lastname });
            internData.attributes.push({ updateKey: "school_enrollment", updateValue: intern.school_enrollment || intern.data.school_enrollment });
        }

        currentContractData.attributes.push({updateKey: "unsuscribeDate", updateValue: dateSelectedAux.format()})
        currentContractData.attributes.push({updateKey: "unsuscribeMotive", updateValue: intern.unsuscribeMotive});
        currentContractData.attributes.push({updateKey: "unsuscribedBy", updateValue: context.user.data.email});

        const notification = {
            name: "Baja de Convenio",
            user: context.user.data.fullName,
            description: `Motivo: ${"Baja de Convenio"}. Descripción: ${intern.unsuscribeMotive}. Usuario: ${context.user.data.email}` ,
            type: "Información",
            date: new Date()
        };

        try{
            if(context.user.company.interns_medical_insurance == "Sí"){
                let policies = await Methods.getPolicyByIdUploads(intern,intern.data._id);
                if(policies == []) return;
                let sortedPolicies = [];
                if (policies.policy.length){
                    sortedPolicies = sortPolicies(policies.policy)
                }
                await setLastSignatureStatus(intern);
                await deletePosteriorPolicies(sortedPolicies, "Inactivo", intern.unsuscribeDate, intern.currentContract._id, intern.data, currentCompany);
                await replacePolicies(intern);
            }

            if(await Methods.updateInternUploads(intern,internData)) return;

            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });

            if(await Methods.updateContractByIdUploads(intern,currentContractData)) return;
            intern.error = "OK";
            intern.processed = true;
        }catch(e){
            intern.error = "ERROR";
            intern.processed = false;
            e.data && (intern.error = e.data.response.message);
        }
    };

    const checkIfInsurance = () => {
        let selected = 0;
        setSentIntern(0);
        setLoadingInsurance(false);
        for (let chunk of interns){
            for(let intern of chunk) {
                if(intern.isSelected && intern.error != "OK") {
                   selected++;
                }
            }
        }
        SetInternsSelected(selected);
        if(context.user.company.interns_medical_insurance == "Sí"){
            setIsLoading(false);
            setShowSendInsurance(true);
        }else{
            processData();
        }
    }

    const processData = async () => {
        context.user.company.interns_medical_insurance == "Sí" ? setIsLoading(false) : setIsLoading(true);
        if(mode == "create") {
            let promiseAllAux = [];
            let insuranceCount = 0;
            for (let chunk of interns){
                for(let intern of chunk) {
                    if(intern.isSelected && !intern.processed) {
                        promiseAllAux.push(handleNewIntern(intern));
                    }
                    if(promiseAllAux.length > (context.user.company.interns_medical_insurance == "Sí" ? 0 : 200 )){
                        setSentIntern(insuranceCount++);
                        await Promise.all(promiseAllAux);
                        promiseAllAux = [];
                    }
                }
            }
            await Promise.all(promiseAllAux);
        } else if(mode == "renew") {
            let promiseAllAux = [];
            let insuranceCount = 0;
            for (let chunk of interns){
                for(let intern of chunk) {
                    if(intern.isSelected && !intern.processed) {
                        promiseAllAux.push(handleRenewIntern(intern));
                    }
                    if(promiseAllAux.length > (context.user.company.interns_medical_insurance == "Sí" ? 0 : 200 )){
                        setSentIntern(insuranceCount++);
                        await Promise.all(promiseAllAux);
                        promiseAllAux = [];
                    }
                }
            }
            await Promise.all(promiseAllAux);
        } else if(mode == "unsubscribe") {
            let promiseAllAux = [];
            for (let chunk of interns){
                for(let intern of chunk) {
                    if(intern.isSelected && !intern.processed) {
                        promiseAllAux.push(handleUnsubscribeIntern(intern));
                    }
                    if(promiseAllAux.length > 200){
                        await Promise.all(promiseAllAux);
                        promiseAllAux = [];
                    }
                }
            }
            await Promise.all(promiseAllAux);
        }
        //MODIFICACIONES
        else if(mode === "modify") {
            let promiseAllAux = [];
            let insuranceCount = 0;
            for (let chunk of interns){
                console.log(2)
                for(let intern of chunk) {
                    if(intern.isSelected && !intern.processed) {
                        promiseAllAux.push(handleNewIntern(intern, "modify"));
                    }
                    if(promiseAllAux.length > (context.user.company.interns_medical_insurance == "Sí" ? 0 : 200 )){
                        setSentIntern(insuranceCount++);
                        await Promise.all(promiseAllAux);
                        promiseAllAux = [];
                    }
                }
            }
            console.log(1)
            await Promise.all(promiseAllAux);
        }
        setFormChanged(!formChanged);
        setIsLoading(false);
        setShowSendInsurance(false);
        let count = 0;
        for (let chunk of interns){
            for(let intern of chunk) {
                if(intern.error === "OK") {
                   count ++;
                }
            }
        }
        setProcessedIntern( count );
    }

    const handleChangeMode = (mode) => {
        setInterns([]);
        setCurrentPage(0);
        setAllPages(1);
        setCurrentInternsByPage([]);
        setMode(mode);
    };

    const saveName = {
        'create' : `AltasPlantilla.xlsx`,
        'renew' : `RenovacionesPlantilla.xlsx`,
        'unsubscribe': `BajasPlantilla.xlsx`,
        'modify': `ModificacionPlantilla.xlsx`,
    };

    const getClassName = (selected) => {
        if(mode == selected) {
            return "selected";
        } else {
            return "section";
        }
    }

    return (
        <div className="uploads">
            <motion.div id="uploads" initial={{x: -30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>
                <h4 class="h4">Cargas Masivas</h4>
                <h3 className="company">{currentCompany}</h3>
                <div className="alter-table">
                    <Row className="row-selection">
                        { context.can('create', 'Uploads') && <a href="#" onClick={(e) => {handleChangeMode("create")}} className={getClassName("create")} ><p className={`${mode == "create" && "color-white"}`}>Altas</p></a> }
                        { (context.can('update', 'Uploads') && context.can('create', 'Uploads')) && <a className='middle'><p style={{color: "lightgray"}}>|</p></a> }
                        { context.can('update', 'Uploads') && 
                            <a href="#" onClick={(e) => {handleChangeMode("renew")}} className={getClassName("renew")}>
                                <p className={`${mode == "renew" && "color-white"}`}>Renovaciones</p>
                            </a>
                        }
                        {(context.can('delete', 'Uploads') && (context.can('create', 'Uploads') || context.can('update', 'Uploads'))) && <a className='middle'><p style={{color: "lightgray"}}>|</p></a>}
                        {context.can('delete', 'Uploads') && 
                        <a href="#" onClick={(e) => {handleChangeMode("unsubscribe")}} className={getClassName("unsubscribe")}>
                            <p className={`${mode == "unsubscribe" && "color-white"}`}>Bajas</p>
                        </a>}

                        {(context.can('delete', 'Uploads') && (context.can('create', 'Uploads') || context.can('update', 'Uploads'))) && <a className='middle'><p style={{color: "lightgray"}}>|</p></a>}
                        {context.can('delete', 'Uploads') && 
                        <a href="#" onClick={(e) => {handleChangeMode("modify")}} className={getClassName("modify")}>
                            <p className={`${mode == "modify" && "color-white"}`}>Modificaciones</p>
                        </a>}
                    </Row>
                    <div style={{"marginRight":"35px"}}>
                        {canUploadDocument && mode && 
                            <Button className="Button" onClick={() => {
                                const workbook = new Excel.Workbook();
                                ExcelCSV.test(
                                    workbook,
                                    {
                                        companyName: context.user.company.business_name,
                                        companyRFC: context.user.company.rfc,
                                        companyId: context.user.company._id,
                                        isITESMCompany: isITESMCompany,
                                        interns_insurance: context.user.company.interns_medical_insurance
                                    },
                                    mode
                                );
                                workbook.xlsx.writeBuffer().then((data) => {
                                    const blob = new Blob([data], {
                                    type:
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                                    });
                                    saveAs(blob, saveName[mode]);
                                });
                            }}>
                                <BsDownload />
                                Plantilla
                            </Button>
                        }
                        { canUploadDocument && mode && 
                            <Button className="Button" onClick={() => { setShowUpdateExcel(!showUpdateExcel)}}>
                                <BsUpload />
                                Carga
                            </Button>
                        }
                        { canUploadDocument && mode && <Button className="Button" onClick={() => {checkIfInsurance()}}>
                            <TiArrowSync />
                            Procesar Datos
                        </Button>
                        }
                    </div>
                </div>
                { canUploadDocument && mode &&<div>
                Altas procesadas ( {processedIntern} / {internsLength} )
                </div>
                }
                <ListInterns 
                    interns={interns}
                    mode={mode}
                    totalSelected={totalSelected}
                    setTotalSelected={setTotalSelected}
                    formChanged={formChanged}
                    setFormChanged={setFormChanged}
                    setUniversities={setUniversities}
                    setDepartments={setDepartments}
                    setCostCentersTec={setCostCentersTec}
                    costCentersTec={costCentersTec}
                    departments={departments}
                    setGeneralistTec={setGeneralistTec}
                    generalistTec={generalistTec}
                    universities={universities}
                    isITESMCompany={isITESMCompany}
                    allPages={allPages}
                    currentInternsByPage={currentInternsByPage}
                    setCurrentInternsByPage={setCurrentInternsByPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            </motion.div>
            <UploadExcel
                mode={mode}
                showUpdateExcel={showUpdateExcel}
                setShowUpdateExcel={setShowUpdateExcel}
                setInterns={setInterns}
                isITESMCompany={isITESMCompany}
                costCentersTec={costCentersTec}
                departments={departments}
                generalistTec={generalistTec}
                universities={universities}
                setAllPages={setAllPages}
                setCurrentInternsByPage={setCurrentInternsByPage}
                setInternsLength={setInternsLength}
                context={context}
            />
            <SendInsurance
                sentIntern={sentIntern}
                internsSelected={internsSelected}
                showSendInsurance={showSendInsurance}
                setshowSendInsurance={setShowSendInsurance}
                processData={processData}
                loadingInsurance={loadingInsurance}
                setLoadingInsurance={setLoadingInsurance}

            />
            {isLoading && <LoadingModal isLoading={isLoading} />}
        </div>
    );
};
import React, {useState, useEffect, useContext, useMemo} from 'react';
import Methods from '../../utils/https';
import {UserContext} from '../../context/UserContext';
import {Row, Col, Button, Form} from 'react-bootstrap';
import ListPrefactures from './components/ListPrefactures.jsx';
import Disperse from './components/Disperse.jsx';
import CustomDropdown from '../../custom/Dropdown/Dropdown.jsx';
import moment from 'moment';
import './Dispersion.css';
import { IoIosCheckmarkCircleOutline, IoMdUndo } from 'react-icons/io';
import { RiSearch2Line } from 'react-icons/ri';
import {motion} from 'framer-motion';
import { notify } from '../../utils/notify';
import { DispersionTypeDropdown } from './components/DispersionTypeDropdown';

export default function Dispersion(){
    const [companies, setCompanies] = useState([]);
    const [interns, setInterns] = useState([]);
    const [payDate, setPayDate] = useState([
        new Date(), 
        new Date(new Date().getFullYear(), new Date().getMonth(), 15)
    ]);
    const [selectedInterns, setSelectedInterns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const context = useContext(UserContext);
    const companiesArr = context.user.data.companies;
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [showDisperse, setShowDisperse] = useState(false);
    const [nextNumber, setNextNumber] = useState(90);
    const [selectedBank, setSelectedBank] = useState("Selecciona un banco");
    const [dateToDisperse, setDateToDisperse] = useState(new Date());
    const [updateList, setUpdateList] = useState(false);
    const [someoneIsAlreadyDispersed, setSomeoneIsAlreadyDispersed] = useState(false);
    const [currentPayDay, setCurrentPayDay] = useState(moment().format("DD/MM/YYYY"));
    const [updateInternList, setUpdateInternList] = useState(false);
    const [dispersionTypeSelected, setDispersionTypeSelected] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState("");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        getAllCompanies();
    }, []);

    useEffect(() => {
        getApprovedPrefactures();
    }, [payDate, selectedCompanies, updateList, currentPayDay, selectedBank]);

    const getAllCompanies = async() => {
        let companiesData = await Methods.getApprovedCompanies();
        companiesData = companiesData.filter(company => companiesArr.includes(company._id));
        setCompanies(companiesData);
    }
    
    
    const getApprovedPrefactures = async () => {

        let promises = [];
        let internsData = [];
        let result = [];
        let selectedCompaniesAux = [];

        if(!companies.length){
            selectedCompaniesAux = await Methods.getApprovedCompanies();
            selectedCompaniesAux = await selectedCompaniesAux.filter(company => companiesArr.includes(company._id));
        }else{
            selectedCompaniesAux = companies;
        }

        selectedCompaniesAux = selectedCompaniesAux.filter(company => {
            let sameBank = company?.internship_bank?.toLowerCase() == selectedBank?.toLowerCase();
            let sameSelectedCompanies = selectedCompanies.length ? selectedCompanies.includes(company._id) : true
            return sameBank && sameSelectedCompanies;
        });

        if(selectedCompaniesAux){
            
            for(let company of selectedCompaniesAux){
                promises.push(Methods.getApprovedPrefactures(currentPayDay, [company._id]));
            }
           
            result = await Promise.all(promises);

            for(let resultCompanies of result){
                if(resultCompanies && resultCompanies.length){
                    for(let company of resultCompanies){
                        internsData.push(company);
                    }
                }
            }
           
        }

        setSelectedInterns([]);
        setSomeoneIsAlreadyDispersed(false);
        internsData ? setInterns(internsData) : setInterns([]);
        setIsLoading(false);
        setDispersionTypeSelected(undefined);
    };

    const handleSetSelectedCompanies = (_companies) => {
        let data = _companies.map(company => company._id);
        setSelectedCompanies(data);
    };

    const getCompanyName = (_companyId) => {
        for(let company of companies){
            if(_companyId === company._id) return company.business_name;
        };
        return "N/A";
    };

    const getCompanyPaymentPeriod = (_companyId) => {
        for(let company of companies){
            if(_companyId === company._id) return company.payment_period;
        };
        return "N/A";
    };

    const getTotalAmount = () => {
        let amount = 0;
        
        //selectedInterns.map(item => amount += item.isSelected ? (Number.parseFloat(item.total_amount ? item.total_amount.toFixed(2) : 0)): 0);

        selectedInterns.map(item => (item.isSelected===true && item.disabledCompany===true) ? item : null);
        selectedInterns.map(item => amount += item.isSelected ? (Number.parseFloat(item.total_amount ? item.total_amount.toFixed(2) : 0)): 0);
        return (parseFloat(amount) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const finishDispersion = async() => {
        let records = await Methods.getDispersionPendingRecorsdByEmail(context.user.data.email);
        if(!records.length){
            notify('No tienes registros por terminar', 'error');
        }else{
            await Methods.setActionToDisperseByFileId(records[0].fileId, 'updateStatus', 'Finished');
            notify('Dispersión terminada con exito', 'success');
        }
    }

    return(
        <div className="dispersion">
                <div className="title">
                    <h4>Dispersión</h4>
                </div>

                <div className="alter-table options">
                    <Button disabled={selectedInterns.length == 0} 
                        className="disperse-button" 
                        onClick={() => {
                            if(dispersionTypeSelected){
                                setShowDisperse(!showDisperse)
                            }else{
                                notify('Debes seleccionar un tipo de dispersión', 'error');
                            }
                        }}
                    >
                        {someoneIsAlreadyDispersed 
                        ? <IoMdUndo/>
                        : <IoIosCheckmarkCircleOutline/>}

                        {someoneIsAlreadyDispersed ? "Reprocesar" : "Dispersar"}
                    </Button>
                    
                    <DispersionTypeDropdown
                        payDate={payDate}
                        setDispersionTypeSelected={setDispersionTypeSelected}
                        dispersionTypeSelected={dispersionTypeSelected}
                    />

                    <div className="search">
                        <div className="search-icon" title="Buscar">
                            <RiSearch2Line/>
                        </div>
                        <Form.Group controlId="search-bar" className="search-container">
                            <Form.Control
                                type="text"
                                placeholder="Búsqueda por cuenta"
                                autoComplete={"off"}
                                onInputCapture={
                                    (e) => {
                                        setSearchQuery(e.target.value);
                                    }
                                }
                            />
                        </Form.Group>
                    </div>

                    {!isLoading && <CustomDropdown
                        className="change-company"
                        items={companies.sort((a, b) => { return a.business_name < b.business_name ? -1 : 1 })}
                        title={"Compañía a consultar"} 
                        label="business_name"
                        setSelected={handleSetSelectedCompanies}
                        firstChecked
                    /> }

                    <Button
                        className="disperse-button" 
                        onClick={async() => 
                            await finishDispersion()                                      
                        }
                    >Terminar
                    </Button>

                </div>
 
                {!isLoading && <Row className='list-interns-container'>
                    <Col className='screen-select-fixed'>
                        <ListPrefactures 
                            interns={interns}  
                            payDate={payDate} 
                            setPayDate={setPayDate} 
                            companies={companies}
                            selected={selectedInterns}
                            setSelected={setSelectedInterns}
                            nextNumber={nextNumber}
                            setNextNumber={setNextNumber}
                            selectedBank={selectedBank}
                            setSelectedBank={setSelectedBank}
                            dateToDisperse={dateToDisperse}
                            setDateToDisperse={setDateToDisperse}
                            setSomeoneIsAlreadyDispersed={setSomeoneIsAlreadyDispersed}
                            getCompanyName={getCompanyName}
                            getCompanyPaymentPeriod={getCompanyPaymentPeriod}
                            selectedCompanies={selectedCompanies}
                            currentPayDay={currentPayDay}
                            setCurrentPayDay={setCurrentPayDay}
                            updateInternList={updateInternList}
                            searchQuery={searchQuery}
                            forceUpdate={forceUpdate}
                        />
                    </Col>
                </Row>}

                <Disperse 
                    showDisperse={showDisperse}
                    setShowDisperse={setShowDisperse}
                    selectedInterns={selectedInterns}
                    payDate={payDate}
                    nextNumber={nextNumber}
                    selectedBank={selectedBank}
                    dateToDisperse={dateToDisperse}
                    updateList={updateList}
                    setUpdateList={setUpdateList}
                    someoneIsAlreadyDispersed={someoneIsAlreadyDispersed}
                    setSelected={setSelectedInterns}
                    dispersionTypeSelected={dispersionTypeSelected}
                    currentPayDay={currentPayDay}
                />

                
                <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1, background: "#d7d7d7"}} transition={{ duration: 0.4 }} className="table-border">
                <p className="float-right" style={{margin: "10px", fontWeight: "bolder"}}>Monto total seleccionado: $ {getTotalAmount()}</p>
                </motion.div>
                
        </div>
    );
};
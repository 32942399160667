import React from "react";
import {Text, View, StyleSheet, Document, Page, Image } from "@react-pdf/renderer";
import moment from "moment";
import caintraLogo from '../../../assets/caintra_logo_agreement.png';
import logoAgreement from '../../../assets/caintra_interns.png';

let cuotaModeloDual = 0;
let subtotalAbono = 0;
const currencyFormat = (value) => {
  return "$" + parseFloat(value || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString();
};


const styles = StyleSheet.create({
  informationTable: {
    display: "table",
    width: "83%",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10
  },
  informationtableCol: {
    paddingLeft: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0,
    height: 15
  },

  informationtableColData: {
    paddingLeft: 2,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0,
    height: 15
  },

  informationTableText: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 6,
    margin: "auto",
    fontWeight: "400",
    color: "#121213"
  },

  informationTableTextData: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 6,
    margin: "auto",
    fontWeight: "400",
    color: "#1B3769"
  },

  detailsTable: {
    display: "table",
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: "#E9EDF3",
    marginBottom: 10,
    marginTop: 20
  },

  detailstableCol: {
    width: "50%",
    paddingLeft: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0,
    height: 13
  },

  detailsTableText: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 6,
    margin: "auto",
    fontWeight: "400",
    color: "#121213",
    width: "100%"
  },

  detailsTableTextLeft: {
    textAlign: 'left',
    color: "#535A63",
    paddingLeft: 5
  },

  detilsTableTextRight: {
    textAlign: 'right',
    paddingRight: 5
  },

  borderDetails: {
    backgroundColor: "#E6EAF0",
    borderColor: "#E9EDF3",
    borderTopWidth: 1
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: "#E9EDF3"
  },

  tableBody: {
    paddingBottom: 10
  },
  tableRow: {
    width: "100%",
    margin: "auto",
    flexDirection: "row"
  },

  tableRowHeader: {
    backgroundColor: "#E9EDF3"
  },
  caintraLogo: {
    position: 'absolute',
    width: '25px',
    height: '25px',
    left: '776px',
    top: '2px'
  },
  caintraInterns: {
    width: '90px',
    height: '27px',
    left: '675px',
    top: '-28px'
  },
  tableColHeader: {
    width: "100%",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0,
  },

  tableBodyText: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 6,
    margin: "auto",
    fontWeight: "400"
  },

  tableBodyTextName: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 6,
    fontWeight: "400"
  },

  tableHeaderText: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 6,
    margin: "auto",
    fontWeight: "400",
    color: "#535A63"
  },

  tableColNumber: {
    width: "220px",
    height: 18
  },

  tableColNumberHeader: {
    width: "220px",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#E9EDF3",
    borderLeftWidth: 0,
  },

  tableColName: {
    width: "1300px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0
  },
  tableColNameHeader: {
    width: "1300px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0
  },

  tableColCostCenter: {
    width: "1400px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0
  },
  tableColCostCenterHeader: {
    width: "1400px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0
  },

  tableColDays: {
    width: "250px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0
  },
  tableColDaysHeader: {
    width: "250px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#E9EDF3",
    borderTopWidth: 0
  },

  tableColBank: {
    width: "600px"
  },

  body: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});
const getFormatedDate = (dateString) => {
  const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
  let result;
  let year;
  let month;
  let day;
  if (regexDate.test(dateString)) {
    const newDateArray = dateString.split("-");
    year = newDateArray[0];
    month = addZeroToDate(newDateArray[1]);
    day = addZeroToDate(newDateArray[2]);
    result = month + "/" + day + "/" + year;
  } else {
    const newDateArray = dateString.split("/");
    month = addZeroToDate(newDateArray[0]);
    day = addZeroToDate(newDateArray[1]);
    result = day + "/" + month + "/" + newDateArray[2];
  }
  return result;
}

const addZeroToDate = (value) => {
  if (value && value.length == 1) {
    return "0" + value;
  } else {
    return value;
  }
};


const getPeriodString = (period, lastPeriod, isConsolidationPrefacture) => {
  let [startDate, endDate] = period;
  let a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
  let b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
  if (isConsolidationPrefacture && lastPeriod) {
    a = moment(getFormatedDate(lastPeriod.start_date)).format('D/MM/YYYY');
  }

  return <h5>Del {a} al {b}</h5>;
};

const sliceIntoChunks = (arr, chunkSize, start = 0) => {
  const res = [];
  for (let i = start; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}


export default function DownloadPDF({ interns, dates, prefacture, companyInfo, isConsolidationPrefacture, lastPeriod, filters }) {
  console.log(companyInfo)
  const filterIncludes = (value) => {
    for (let index in filters) {
      if (filters[index].value == value) return true;
    };
    return false;
  };
  const itemsPerPage = 28;
  const itemsForFirstPage = 24;
  const chunkInterns = sliceIntoChunks(interns, itemsPerPage, itemsForFirstPage);
  /* MODELO DUAL */
  if(companyInfo.companyName.toUpperCase().includes("DUAL")){
    return (
      <Document>
        <Page orientation={"landscape"} style={styles.body}>
          <View style={styles.tableBody}>
            <View style={styles.informationTable}>
  
              <View style={[styles.tableRow, styles.tableRowHeader]}>
                <View style={[styles.tableRow, styles.tableRowHeader]}>
                  <View style={styles.informationtableCol}>
                    <Text style={styles.informationTableText}>
                      No de Cliente:
                    </Text>
                  </View>
  
                  <View style={styles.informationtableColData}>
                    <Text style={styles.informationTableTextData}>{companyInfo.clientNumber}</Text>
                  </View>
  
                  <View style={styles.informationtableCol}>
                    <Text style={styles.informationTableText}>
                      Razón Factura:
                    </Text>
                  </View>
  
                  <View style={styles.informationtableColData}>
                    <Text style={styles.informationTableTextData}>
                      {companyInfo.companySocialReason}
                    </Text>
                  </View>
  
  
                  <View style={styles.informationtableCol}>
                    <Text style={styles.informationTableText}>
                      División Comercial:
                    </Text>
                  </View>
  
                  <View style={styles.informationtableColData}>
                    <Text style={styles.informationTableTextData}>
                      {companyInfo.corporative}
                    </Text>
                  </View>
  
                  <View style={styles.informationtableCol}>
                    <Text style={styles.informationTableText}>Usuario:</Text>
                  </View>
  
                  <View style={styles.informationtableColData}>
                    <Text style={styles.informationTableTextData}>
                      {companyInfo.userName}
                    </Text>
                  </View>
                </View>
              </View>
  
              <View style={[styles.tableRow, styles.tableRowHeader]}>
                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>Empresa:</Text>
                </View>
  
                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {companyInfo.companyName}
                  </Text>
                </View>
  
                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>Período:</Text>
                </View>
  
                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {getPeriodString(dates, lastPeriod, isConsolidationPrefacture)}
                  </Text>
                </View>
  
                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>Total de Practicantes:</Text>
                </View>
  
                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {interns ? interns.length : 0}
                  </Text>
                </View>
  
                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>Fecha de Creación:</Text>
                </View>
  
                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {moment().format('DD/MM/YYYY HH:mm:ss')}
                  </Text>
                </View>
              </View>
  
              <Image source={logoAgreement} style={styles.caintraInterns} />
              <Image source={caintraLogo} style={styles.caintraLogo} />
  
  
            </View>
  
  
  
  
  
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableRowHeader]}>
                <View style={[styles.tableCol, styles.tableColNumberHeader]}>
                  <Text style={styles.tableHeaderText}>#</Text>
                </View>
                {filterIncludes("clabe") && <View style={styles.tableCol}>
                  <Text style={styles.tableHeaderText}>Cuenta</Text>
                </View>}
                {filterIncludes("dueAmount") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Neto</Text>
                </View>}
                <View style={styles.tableColNameHeader}>
                  <Text style={styles.tableHeaderText}>Nombre Practicante</Text>
                </View>
                {filterIncludes("bank") && <View style={[styles.tableColBankHeader, styles.tableColBank]}>
                  <Text style={styles.tableHeaderText}>Banco</Text>
                </View>}
                {filterIncludes("costCenter") && <View style={styles.tableColCostCenterHeader}>
                  <Text style={styles.tableHeaderText}>Centro de Costos</Text>
                </View>}
                {filterIncludes("startDate") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Fecha de Inicio</Text>
                </View>}
                {filterIncludes("endDate") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Fecha de Término</Text>
                </View>}
                {filterIncludes("daysToPay") && <View style={styles.tableColDaysHeader}>
                  <Text style={styles.tableHeaderText}>Días</Text>
                </View>}
                {filterIncludes("scolarshipAmount") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Monto de Beca</Text>
                </View>}
                {filterIncludes("adjustment") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Ajustes</Text>
                </View>}
                {filterIncludes("companyInsurance") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Seguro Empresa</Text>
                </View>}
                {filterIncludes("internsInsurance") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Seguro Practicante</Text>
                </View>}
                {filterIncludes("modelodual") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Cuota admin MD</Text>
                </View>}
                {filterIncludes("amountToPay") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Monto a Dispersar</Text>
                </View>}
                {filterIncludes("adminG") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>G. Admin</Text>
                </View>}
                {filterIncludes("subtotal") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>SubTotal</Text>
                </View>}
                {filterIncludes("vat") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>IVA</Text>
                </View>}
                {filterIncludes("internTotal") && <View style={styles.tableColHeader}>
                  <Text style={styles.tableHeaderText}>Total de Practicantes</Text>
                </View>}
              </View>
  
              {interns ? interns.slice(0, itemsForFirstPage).map((intern, index) => {
                let bank = intern.clabe?.toString();
                let bank2;
                let bank3;


                let totalInterns = interns.length;
                let paidDual  = 0 ;
                let totalDual = 0;
                let daystoPaid = intern.daysToPay || intern.pay_days || 0;
              
                if(totalInterns <= 10 ){
                  paidDual = 1599.00;
                }
                else if(totalInterns >10 && totalInterns <= 24){
                    paidDual = 1499.00;
                }
                else if(totalInterns> 24){
                    paidDual = 1299.00;
                } 
                totalDual = (paidDual/30) * daystoPaid ;
                //console.log(interns.length, totalDual, paidDual, daystoPaid);
                cuotaModeloDual += totalDual;

                console.log("CUOTA:", cuotaModeloDual, " " , index, intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname)
                let gadminval = `${(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense)}`;
                gadminval = parseInt(gadminval);
                gadminval = parseFloat(gadminval / 100);
                let adming = intern.total_amount * gadminval;
                let subtotal = intern.total_amount + adming + intern.company_insurance_amount + totalDual;
                let vat = subtotal * 0.16;
                let internTotal = subtotal * 1.16;
                if (intern.clabe?.length > 10) {
                  bank2 = bank.substring(0, 10)
                  bank3 = bank.substring(10)
                }


                

                return (
                  <View key={index} style={styles.tableRow}>
                    <View style={[styles.tableCol, styles.tableColNumber]}>
                      <Text style={styles.tableBodyText}>{index + 1}</Text>
                    </View>
                    {filterIncludes("clabe") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}> {bank?.length > 10 ? bank2 + " " + bank3 : bank}</Text>
                    </View>}
                    {filterIncludes("dueAmount") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                    </View>}
                    <View style={styles.tableColName}>
                      <Text style={styles.tableBodyText}>
                        {intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname}
                      </Text>
                    </View>
                    {filterIncludes("bank") && <View style={[styles.tableCol, styles.tableColBank]}>
                      <Text style={styles.tableBodyText}>{intern.bank}</Text>
                    </View>}
                    {filterIncludes("costCenter") && <View style={styles.tableColCostCenter}>
                      <Text style={styles.tableBodyText}>{intern.cost_center_name || "N/A"}</Text>
                    </View>}
                    {filterIncludes("startDate") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{intern.contractStartDate}</Text>
                    </View>}
                    {filterIncludes("endDate") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{intern.contractEndDate}</Text>
                    </View>}
                    {filterIncludes("daysToPay") && <View style={styles.tableColDays}>
                      <Text style={styles.tableBodyText}>{(intern.daysToPay || intern.pay_days || 0).toString()}</Text>
                    </View>}
                    {filterIncludes("scolarshipAmount") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(intern.scolarshipAmount)}</Text>
                    </View>}
                    {filterIncludes("adjustment") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(intern.adjustment)}</Text>
                    </View>}
                    {filterIncludes("companyInsurance") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(intern.company_insurance_amount)}</Text>
                    </View>}
                    {filterIncludes("internsInsurance") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(intern.intern_insurance_amount)}</Text>
                    </View>}
                    {filterIncludes("modelodual") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(totalDual)}</Text>
                    </View>}
                    {filterIncludes("amountToPay") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                    </View>}
                    {filterIncludes("adminG") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(adming)}</Text>
                    </View>}
                    {filterIncludes("subtotal") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(subtotal)}</Text>
                    </View>}
                    {filterIncludes("vat") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(vat)}</Text>
                    </View>}
                    {filterIncludes("internTotal") && <View style={styles.tableCol}>
                      <Text style={styles.tableBodyText}>{currencyFormat(internTotal)}</Text>
                    </View>}
                  </View>);
              }) : <View style={styles.tableCol}>
                <Text style={styles.tableBodyText}></Text>
              </View>
              }
            </View>
          </View>
  
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
        {
          chunkInterns.length > 0 && chunkInterns.map((chunk, ind) => {
            return (
              <Page orientation={"landscape"} style={styles.body}>
                <View style={styles.tableBody}>
                  <View style={styles.table}>
                    {
                      chunk.map((intern, index) => {
                        let bank = intern.clabe?.toString();
                        let bank2;
                        let bank3;



                        let totalInterns = interns.length;
                        let paidDual  = 0 ;
                        let totalDual = 0;
                        let daystoPaid = intern.daysToPay || intern.pay_days || 0;
                      
                        if(totalInterns <= 10 ){
                          paidDual = 1599.00;
                        }
                        else if(totalInterns >10 && totalInterns <= 24){
                            paidDual = 1499.00;
                        }
                        else if(totalInterns> 24){
                            paidDual = 1299.00;
                        } 
                        totalDual = paidDual/30 * daystoPaid ;
                        console.log(interns.length, totalDual, paidDual, daystoPaid);
                        cuotaModeloDual+= totalDual;
                        let gadminval = `${(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense)}`;
                        gadminval = parseInt(gadminval);
                        gadminval = parseFloat(gadminval / 100);
                        let adming = intern.total_amount * gadminval;
                        let subtotal = intern.total_amount + adming + intern.company_insurance_amount;
                        let vat = subtotal * 0.16;
                        let internTotal = subtotal * 1.16;
                        if (intern.clabe?.length > 10) {
                          bank2 = bank.substring(0, 10)
                          bank3 = bank.substring(10)
                        }
                        return (
                          <View style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.tableColNumber]}>
                              <Text style={styles.tableBodyText}>{index + 1 + (itemsForFirstPage + ind * itemsPerPage)}</Text>
                            </View>
                            {filterIncludes("clabe") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}> {bank?.length > 10 ? bank2 + " " + bank3 : bank}</Text>
                            </View>}
                            {filterIncludes("dueAmount") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                            </View>}
                            <View style={styles.tableColName}>
                              <Text style={styles.tableBodyText}>
                                {intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname}
                              </Text>
                            </View>
                            {filterIncludes("bank") && <View style={[styles.tableCol, styles.tableColBank]}>
                              <Text style={styles.tableBodyText}>{intern.bank}</Text>
                            </View>}
                            {filterIncludes("costCenter") && <View style={styles.tableColCostCenter}>
                              <Text style={styles.tableBodyText}>
                                {intern.cost_center_name || "N/A"}
                                </Text>
                            </View>}
                            {filterIncludes("startDate") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{intern.contractStartDate}</Text>
                            </View>}
                            {filterIncludes("endDate") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{intern.contractEndDate}</Text>
                            </View>}
                            {filterIncludes("daysToPay") && <View style={styles.tableColDays}>
                              <Text style={styles.tableBodyText}>{(intern.daysToPay || intern.pay_days || 0).toString()}</Text>
                            </View>}
                            {filterIncludes("scolarshipAmount") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(intern.scolarshipAmount)}</Text>
                            </View>}
                            {filterIncludes("adjustment") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(intern.adjustment)}</Text>
                            </View>}
                            {filterIncludes("companyInsurance") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(intern.company_insurance_amount)}</Text>
                            </View>}
                            {filterIncludes("internsInsurance") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(intern.intern_insurance_amount)}</Text>
                            </View>}
                            {filterIncludes("modelodual") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(totalDual)}</Text>
                            </View>}
                            {filterIncludes("amountToPay") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                            </View>}
                            {filterIncludes("adminG") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(adming)}</Text>
                            </View>}
                            {filterIncludes("subtotal") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(subtotal)}</Text>
                            </View>}
                            {filterIncludes("vat") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(vat)}</Text>
                            </View>}
                            {filterIncludes("internTotal") && <View style={styles.tableCol}>
                              <Text style={styles.tableBodyText}>{currencyFormat(internTotal)}</Text>
                            </View>}
                          </View>);
                      })
                    }
                  </View>
                </View>
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            )
          })
        }
        {
          <Page orientation={"landscape"} style={styles.body}>
            <View style={styles.tableBody}>
              <View style={styles.detailsTable}>
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Monto a Dispersar:
                    </Text>
                  </View>
                  <View style={[styles.detailstableCol, styles.tableRowHeader]}>
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(prefacture.totalCost)}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      {((prefacture.administrativeExpensePrefacture==0  ? 10 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10 : prefacture.administrativeExpense) )+ "% G.A:"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat((prefacture.totalCost) * ((prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) ))}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Subtotal sin Seguro:
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(prefacture.totalCost* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) + prefacture.totalCost)}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Seguro Empresa:
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(prefacture.insuranceCompanyData)}
                    </Text>
                  </View>
                </View>

                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Cuota Admon Modelo Dual:
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(cuotaModeloDual)}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Adeudo Empresa:
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(prefacture.debitCompany)}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Subtotal con Seguro:
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(prefacture.totalCost* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) + prefacture.totalCost + cuotaModeloDual +prefacture.insuranceCompanyData)}
                    </Text>
                  </View>
                </View>

                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Descuento(GA+Seguro)`(prefacture.totalCost)* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100)) + prefacture.insuranceCompanyData  `:
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat((prefacture.insuranceCompanyData !=0 ? prefacture.insuranceCompanyData - 0.01 : 0) + (prefacture.totalCost)* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) ) }
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Abono Empresa
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat(prefacture.creditCompany)}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Subtotal con Abono
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                    
                      
                      {
                      currencyFormat((prefacture.totalCost* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) + prefacture.totalCost + cuotaModeloDual +prefacture.insuranceCompanyData) - ((prefacture.insuranceCompanyData !=0 ? prefacture.insuranceCompanyData - 0.01 : 0) + (prefacture.totalCost)* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) )) 
                      }
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      I.V.A.
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat( ((prefacture.totalCost* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) + prefacture.totalCost + cuotaModeloDual +prefacture.insuranceCompanyData) - ((prefacture.insuranceCompanyData !=0 ? prefacture.insuranceCompanyData - 0.01 : 0) + (prefacture.totalCost)* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) ) )* (0.16))}
                    </Text>
                  </View>
                </View>
  
                <View style={[styles.tableRow]}>
                  <View style={[styles.detailstableCol]}>
                    <Text
                      style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                    >
                      Total Final
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.detailstableCol,
                      styles.tableRowHeader,
                      styles.borderDetails,
                    ]}
                  >
                    <Text
                      style={[styles.detailsTableText, styles.detilsTableTextRight]}
                    >
                      {currencyFormat((prefacture.totalCost* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) + prefacture.totalCost + cuotaModeloDual +prefacture.insuranceCompanyData) - ((prefacture.insuranceCompanyData !=0 ? prefacture.insuranceCompanyData - 0.01 : 0) + (prefacture.totalCost)* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) ) +((prefacture.totalCost* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture/100 || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) + prefacture.totalCost + cuotaModeloDual +prefacture.insuranceCompanyData) - ((prefacture.insuranceCompanyData !=0 ? prefacture.insuranceCompanyData - 0.01 : 0) + (prefacture.totalCost)* (prefacture.administrativeExpensePrefacture==0  ? 10/100 : prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense ==0 ? 10/100 : prefacture.administrativeExpense/100) ) )* (0.16) )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        }
      </Document>
    );
  }
  else{

  return (
    <Document>
      <Page orientation={"landscape"} style={styles.body}>
        <View style={styles.tableBody}>
          <View style={styles.informationTable}>

            <View style={[styles.tableRow, styles.tableRowHeader]}>
              <View style={[styles.tableRow, styles.tableRowHeader]}>
                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>
                    No de Cliente:
                  </Text>
                </View>

                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>{companyInfo.clientNumber}</Text>
                </View>

                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>
                    Razón Factura:
                  </Text>
                </View>

                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {companyInfo.companySocialReason}
                  </Text>
                </View>


                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>
                    División Comercial:
                  </Text>
                </View>

                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {companyInfo.corporative}
                  </Text>
                </View>

                <View style={styles.informationtableCol}>
                  <Text style={styles.informationTableText}>Usuario:</Text>
                </View>

                <View style={styles.informationtableColData}>
                  <Text style={styles.informationTableTextData}>
                    {companyInfo.userName}
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.tableRow, styles.tableRowHeader]}>
              <View style={styles.informationtableCol}>
                <Text style={styles.informationTableText}>Empresa:</Text>
              </View>

              <View style={styles.informationtableColData}>
                <Text style={styles.informationTableTextData}>
                  {companyInfo.companyName}
                </Text>
              </View>

              <View style={styles.informationtableCol}>
                <Text style={styles.informationTableText}>Período:</Text>
              </View>

              <View style={styles.informationtableColData}>
                <Text style={styles.informationTableTextData}>
                  {getPeriodString(dates, lastPeriod, isConsolidationPrefacture)}
                </Text>
              </View>

              <View style={styles.informationtableCol}>
                <Text style={styles.informationTableText}>Total de Practicantes:</Text>
              </View>

              <View style={styles.informationtableColData}>
                <Text style={styles.informationTableTextData}>
                  {interns ? interns.length : 0}
                </Text>
              </View>

              <View style={styles.informationtableCol}>
                <Text style={styles.informationTableText}>Fecha de Creación:</Text>
              </View>

              <View style={styles.informationtableColData}>
                <Text style={styles.informationTableTextData}>
                  {moment().format('DD/MM/YYYY HH:mm:ss')}
                </Text>
              </View>
            </View>

            <Image source={logoAgreement} style={styles.caintraInterns} />
            <Image source={caintraLogo} style={styles.caintraLogo} />


          </View>





          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableRowHeader]}>
              <View style={[styles.tableCol, styles.tableColNumberHeader]}>
                <Text style={styles.tableHeaderText}>#</Text>
              </View>
              {filterIncludes("clabe") && <View style={styles.tableCol}>
                <Text style={styles.tableHeaderText}>Cuenta</Text>
              </View>}
              {filterIncludes("dueAmount") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Neto</Text>
              </View>}
              <View style={styles.tableColNameHeader}>
                <Text style={styles.tableHeaderText}>Nombre Practicante</Text>
              </View>
              {filterIncludes("bank") && <View style={[styles.tableColBankHeader, styles.tableColBank]}>
                <Text style={styles.tableHeaderText}>Banco</Text>
              </View>}
              {filterIncludes("costCenter") && <View style={styles.tableColCostCenterHeader}>
                <Text style={styles.tableHeaderText}>Centro de Costos</Text>
              </View>}
              {filterIncludes("startDate") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Fecha de Inicio</Text>
              </View>}
              {filterIncludes("endDate") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Fecha de Término</Text>
              </View>}
              {filterIncludes("daysToPay") && <View style={styles.tableColDaysHeader}>
                <Text style={styles.tableHeaderText}>Días</Text>
              </View>}
              {filterIncludes("scolarshipAmount") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Monto de Beca</Text>
              </View>}
              {filterIncludes("adjustment") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Ajustes</Text>
              </View>}
              {filterIncludes("companyInsurance") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Seguro Empresa</Text>
              </View>}
              {filterIncludes("internsInsurance") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Seguro Practicante</Text>
              </View>}
              {filterIncludes("amountToPay") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Monto a Dispersar</Text>
              </View>}
              {filterIncludes("adminG") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>G. Admin</Text>
              </View>}
              {filterIncludes("subtotal") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>SubTotal</Text>
              </View>}
              {filterIncludes("vat") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>IVA</Text>
              </View>}
              {filterIncludes("internTotal") && <View style={styles.tableColHeader}>
                <Text style={styles.tableHeaderText}>Total de Practicantes</Text>
              </View>}
            </View>

            {interns ? interns.slice(0, itemsForFirstPage).map((intern, index) => {
              let bank = intern.clabe?.toString();
              let bank2;
              let bank3;
              let gadminval = `${(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense)}`;
              gadminval = parseInt(gadminval);
              gadminval = parseFloat(gadminval / 100);
              let adming = intern.total_amount * gadminval;
              let subtotal = intern.total_amount + adming + intern.company_insurance_amount;
              let vat = subtotal * 0.16;
              let internTotal = subtotal * 1.16;
              if (intern.clabe?.length > 10) {
                bank2 = bank.substring(0, 10)
                bank3 = bank.substring(10)
              }
              return (
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, styles.tableColNumber]}>
                    <Text style={styles.tableBodyText}>{index + 1}</Text>
                  </View>
                  {filterIncludes("clabe") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}> {bank?.length > 10 ? bank2 + " " + bank3 : bank}</Text>
                  </View>}
                  {filterIncludes("dueAmount") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                  </View>}
                  <View style={styles.tableColName}>
                    <Text style={styles.tableBodyText}>
                      {intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname}
                    </Text>
                  </View>
                  {filterIncludes("bank") && <View style={[styles.tableCol, styles.tableColBank]}>
                    <Text style={styles.tableBodyText}>{intern.bank}</Text>
                  </View>}
                  {filterIncludes("costCenter") && <View style={styles.tableColCostCenter}>
                    <Text style={styles.tableBodyText}>{intern.cost_center_name || "N/A"}</Text>
                  </View>}
                  {filterIncludes("startDate") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{intern.contractStartDate}</Text>
                  </View>}
                  {filterIncludes("endDate") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{intern.contractEndDate}</Text>
                  </View>}
                  {filterIncludes("daysToPay") && <View style={styles.tableColDays}>
                    <Text style={styles.tableBodyText}>{(intern.daysToPay || intern.pay_days || 0).toString()}</Text>
                  </View>}
                  {filterIncludes("scolarshipAmount") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(intern.scolarshipAmount)}</Text>
                  </View>}
                  {filterIncludes("adjustment") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(intern.adjustment)}</Text>
                  </View>}
                  {filterIncludes("companyInsurance") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(intern.company_insurance_amount)}</Text>
                  </View>}
                  {filterIncludes("internsInsurance") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(intern.intern_insurance_amount)}</Text>
                  </View>}
                  {filterIncludes("amountToPay") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                  </View>}
                  {filterIncludes("adminG") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(adming)}</Text>
                  </View>}
                  {filterIncludes("subtotal") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(subtotal)}</Text>
                  </View>}
                  {filterIncludes("vat") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(vat)}</Text>
                  </View>}
                  {filterIncludes("internTotal") && <View style={styles.tableCol}>
                    <Text style={styles.tableBodyText}>{currencyFormat(internTotal)}</Text>
                  </View>}
                </View>);
            }) : <View style={styles.tableCol}>
              <Text style={styles.tableBodyText}></Text>
            </View>
            }
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      {
        chunkInterns.length > 0 && chunkInterns.map((chunk, ind) => {
          return (
            <Page orientation={"landscape"} style={styles.body}>
              <View style={styles.tableBody}>
                <View style={styles.table}>
                  {
                    chunk.map((intern, index) => {
                      let bank = intern.clabe?.toString();
                      let bank2;
                      let bank3;
                      let gadminval = `${(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense)}`;
                      gadminval = parseInt(gadminval);
                      gadminval = parseFloat(gadminval / 100);
                      let adming = intern.total_amount * gadminval;
                      let subtotal = intern.total_amount + adming + intern.company_insurance_amount;
                      let vat = subtotal * 0.16;
                      let internTotal = subtotal * 1.16;
                      if (intern.clabe?.length > 10) {
                        bank2 = bank.substring(0, 10)
                        bank3 = bank.substring(10)
                      }
                      return (
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCol, styles.tableColNumber]}>
                            <Text style={styles.tableBodyText}>{index + 1 + (itemsForFirstPage + ind * itemsPerPage)}</Text>
                          </View>
                          {filterIncludes("clabe") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}> {bank?.length > 10 ? bank2 + " " + bank3 : bank}</Text>
                          </View>}
                          {filterIncludes("dueAmount") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                          </View>}
                          <View style={styles.tableColName}>
                            <Text style={styles.tableBodyText}>
                              {intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname}
                            </Text>
                          </View>
                          {filterIncludes("bank") && <View style={[styles.tableCol, styles.tableColBank]}>
                            <Text style={styles.tableBodyText}>{intern.bank}</Text>
                          </View>}
                          {filterIncludes("costCenter") && <View style={styles.tableColCostCenter}>
                            <Text style={styles.tableBodyText}>
                              {intern.cost_center_name || "N/A"}
                              </Text>
                          </View>}
                          {filterIncludes("startDate") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{intern.contractStartDate}</Text>
                          </View>}
                          {filterIncludes("endDate") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{intern.contractEndDate}</Text>
                          </View>}
                          {filterIncludes("daysToPay") && <View style={styles.tableColDays}>
                            <Text style={styles.tableBodyText}>{(intern.daysToPay || intern.pay_days || 0).toString()}</Text>
                          </View>}
                          {filterIncludes("scolarshipAmount") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(intern.scolarshipAmount)}</Text>
                          </View>}
                          {filterIncludes("adjustment") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(intern.adjustment)}</Text>
                          </View>}
                          {filterIncludes("companyInsurance") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(intern.company_insurance_amount)}</Text>
                          </View>}
                          {filterIncludes("internsInsurance") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(intern.intern_insurance_amount)}</Text>
                          </View>}
                          {filterIncludes("amountToPay") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(intern.total_amount)}</Text>
                          </View>}
                          {filterIncludes("adminG") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(adming)}</Text>
                          </View>}
                          {filterIncludes("subtotal") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(subtotal)}</Text>
                          </View>}
                          {filterIncludes("vat") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(vat)}</Text>
                          </View>}
                          {filterIncludes("internTotal") && <View style={styles.tableCol}>
                            <Text style={styles.tableBodyText}>{currencyFormat(internTotal)}</Text>
                          </View>}
                        </View>);
                    })
                  }
                </View>
              </View>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          )
        })
      }
      {
        <Page orientation={"landscape"} style={styles.body}>
          <View style={styles.tableBody}>
            <View style={styles.detailsTable}>
              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Monto a Dispersar:
                  </Text>
                </View>
                <View style={[styles.detailstableCol, styles.tableRowHeader]}>
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.totalCost)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    {(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense) + "% G.A:"}
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.totalAdministrativeExpense)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Subtotal sin Seguro:
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.subtotalNoInsurance)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Seguro Empresa:
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.insuranceCompanyData)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Adeudo Empresa:
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.debitCompany)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Subtotal con Seguro:
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.subtotalInsurance)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Abono Empresa
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.creditCompany)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Subtotal con Abono
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture.subtotalCredits)}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    I.V.A.
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(parseFloat(prefacture.subtotalCredits * (0.16)))}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={[styles.detailstableCol]}>
                  <Text
                    style={[styles.detailsTableText, styles.detailsTableTextLeft]}
                  >
                    Total Final
                  </Text>
                </View>
                <View
                  style={[
                    styles.detailstableCol,
                    styles.tableRowHeader,
                    styles.borderDetails,
                  ]}
                >
                  <Text
                    style={[styles.detailsTableText, styles.detilsTableTextRight]}
                  >
                    {currencyFormat(prefacture._total)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      }
    </Document>
  );
}
}


export default {
    

    test(workbook, interns) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        let num = 1;

        const dropDown = (label) => {
            let values = {
                'gender': true, 
                'nationalitySantander': true, 
                'maritalStatusSantander': true, 
            }
            return values[label] || false
        }

        const dropDownValues = {
            'gender': ['"FEMENINO,MASCULINO,"'],
            'nationalitySantander': ['$T$1:$T$267'],
            'maritalStatusSantander': ['"SOLTERA,SOLTERO,UNION LIBRE,CASADA,CASADO,DIVORCIADA,DIVORCIADO,VIUDA,VIUDO"'],
        }

        const NATIONALITY = ['MEXICO', 'ESTADOS UNIDOS', 'AFGHANISTAN', 'ACHIPIELAGO DE SVALBARD', 'ALBANIA', 'ALEMANIA', 'ANDORRA', 'ANGOLA', 'ANGUILLA', 'ANTARTIDA', 'ANTIGUA Y BARBUDA', 'ANTILLAS NEERLANDESAS', 'ARABIA SAUDITA', 'ARGELIA', 'ARGENTINA', 'ARMENIA', 'ARUBA', 'ASCENCION', 'AUSTRALIA', 'AUSTRIA', 'AZERBAIYAN', 'AZORES ISLAS', 'BAHAMAS', 'BAHRAIN', 'BANGLADESH', 'BARBADOS', 'BELARUS', 'BELGICA', 'BELICE', 'BENIN', 'BERMUDAS', 'BHUTAN', 'BIRMANIA', 'BOLIVIA', 'BONAIRE', 'BOSNIA Y HERZEGOVINA', 'BOTSWANA', 'BRASIL', 'BRUNEI', 'BULGARIA', 'BURKINA FASO', 'BURUNDI',
        'CABO VERDE', 'CAMBOYA', 'CAMERUN', 'CAMPIONE D ITALIA', 'CANADA', 'CHAD', 'CHILE', 'CHINA', 'CHIPRE', 'CLIPPERTON', 'COLOMBIA', 'COMORAS', 'CONGO', 'COREA DEL NORTE', 'COREA DEL SUR', 'COSTA DE MARFIL', 'COSTA RICA', 'CROACIA', 'CUBA', 'CURAZAO', 'DIEGO GARCIA', 'DINAMARCA', 'DOMINICA', 'ECUADOR', 'EGIPTO', 'EL SALVADOR', 'EMIRATOS ARABES UNIDOS', 'ERITEA', 'ESCOCIA', 'ESLOVENIA', 'ESPANA', 'ESTONIA', 'ETIOPIA', 'FILIPINAS', 'FINLANDIA', 'FRANCIA', 'GABON', 'GAMBIA', 'GEORGIA', 'GEORGIA DEL SUR E ISLAS SANDWICH DEL', 'GHANA', 'GIBRALTAR', 'GRANADA', 'GRECIA', 'GROENLANDIA', 'GUADALUPE', 'GUAM', 'GUATEMALA', 'GUAYANA FRANCESA', 'GUERNSEY AND JERSEY ISLANDS', 'GUINEA', 'GUINEA ECUATORIAL', 'GUYANA', 'HAITI', 'HONDURAS', 'HONG KONG', 'HUNGRIA', 'INDIA', 'INDONESIA', 'INGLATERRA', 'IRAK', 'IRAN', 'IRLANDA', 'ISLA BOUVET', 'ISLA CHRISTMAS', 'ISLA DEL HOMBRE', 'ISLA NORFOLK', 'ISLA QESHM', 'ISLANDIA', 'ISLAS ALAND', 'ISLAS CAIMAN', 'ISLAS CANARIAS', 'ISLAS COCOS O KELLING', 'ISLAS COOK', 'ISLAS DEL CANAL', 'ISLAS FEROE', 'ISLAS FIJI', 'ISLAS HEARD Y MCDONALD','ISLAS MALVINAS', 'ISLAS MARIANAS', 'ISLAS MARSHALL', 'ISLAS MENORES ALEJADAS DE LOS EU', 'ISLAS PACIFICO', 'ISLAS TURCAS Y CAICOS', 'ISLAS VIRGENES BRITANICAS', 'ISLAS VIRGENES DE LOS ESTADOS UNIDOS', 'ISRAEL', 'ITALIA', 'JAMAICA', 'JAPON', 'JERSEY', 'JORDANIA', 'KAZAJSTAN', 'KENIA', 'KIRGUIZISTAN', 'KIRIBATI', 'KUWAIT', 'LABUAN', 'LAOS', 'LESOTHO', 'LETONIA', 'LIBANO', 'LIBERIA', 'LIBIA', 'LIECHTENSTEIN', 'LITUANIA', 'LUXEMBURGO', 'MACAO', 'MACEDONIA', 'MADAGASCAR', 'MADEIRA', 'MALASIA', 'MALAWI', 'MALDIVAS', 'MALI', 'MALTA', 'MARRUECOS', 'MARTINICA', 'MAURICIO', 'MAURITANIA', 'MAYOTTE', 'MICRONESIA', 'MOLDAVIA', 'MONACO', 'MONGOLIA', 'MONSERRAT', 'MONTENEGRO', 'MOZAMBIQUE', 'MYANMAR', 'NAMIBIA', 'NAURU', 'NEPAL', 'NICARAGUA', 'NIGER', 'NIGERIA', 'NIUE', 'NO INFORMADO', 'NORUEGA',
        'NUEVA CALEDONIA', 'NUEVA ZELANDA', 'OMAN', 'PAISES BAJOS', 'PAKISTAN', 'PALAU', 'PANAMA', 'PAPUA NUEVA GUINEA', 'PARAGUAY', 'PERU', 'PITCAIRN', 'POLINESIA FRANCESA -TAHITI-', 'POLONIA', 'PORTUGAL', 'PUERTO RICO', 'QATAR', 'REINO UNIDO', 'REPUBLICA CENTROAFRICANA', 'REPUBLICA CHECA', 'REPUBLICA DEMOCRATICA DEL CONGO', 'REPUBLICA DOMINICANA', 'REPUBLICA ESLOVACA', 'REUNION', 'RUANDA', 'RUMANIA', 'RUSIA', 'SABA', 'SAHARA OCCIDENTAL', 'SAINT MARTIN', 'SALOMON', 'SAMOA AMERICANA', 'SAMOA OCCIDENTAL', 'SAN CRISTOBAL Y NIEVES', 'SAN EUSTAQUIO', 'SAN MARINO', 'SAN PEDRO Y MIQUELON', 'SAN VICENTE Y GRANADI', 'SANTA ELENA', 'SANTA LUCIA', 'SANTO TOME Y PRINCIPE', 'SENEGAL', 'SERBIA (YUGOSLAVIA)', 'SEYCHELLES', 'SIERRA LEONA', 'SINGAPUR', 'SIRIA', 'SOMALIA', 'SRI LANKA', 'SUDAFRICA', 'SUDAN', 'SUECIA', 'SUIZA', 'SURINAM', 'SVALBARD Y JAN MAYEN', 'SWAZILAND', 'SWAZILANDIA', 'TAILANDIA', 'TAIWAN', 'TANZANIA', 'TAYIKISTAN', 'TERRITORIO BRITANICO DEL OCEANO INDIC', 'TERRITORIOS AUSTRALES FRANCESES', 'TERRITORIOS PALESTINOS', 'TIMOR ORIENTAL', 'TOGO', 'TOKELAU', 'TONGA', 'TRIESTE', 'TRINIDAD Y TOBAGO', 'TRISTAN DE ACUNA', 'TUNEZ', 'TURKMENISTAN', 'TURQUIA', 'TUVALO', 'UCRANIA', 'UGANDA', 'URUGUAY', 'UZBEKISTAN', 'VANUATO', 'VATICANO', 'VENEZUELA', 'VIETNAM', 'WALLIS Y FUTUNA', 'YEMEN', 'YIBUTI', 'ZAMBIA', 'ZIMBABUE'];

        const data = {
                'cols': ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S"],
                'config' : [{  width: 32,  value: "#",              text: "NÚMERO DE EMPLEADO",                      getData: () => { return num++}  },
                            {  width: 32,  value: "fatherLastName",              text: "PRIMER APELLIDO",                      getData: (intern) => { return intern.father_lastname}  },
                            {  width: 32,  value: "motherLastName",              text: "SEGUNDO APELLIDO",                      getData: (intern) => { return intern.mother_lastname}  },
                            {  width: 32,  value: "firstname",              text: "NOMBRE(S)",                      getData: (intern) => { return intern.firstname}  },
                            {  width: 32,  value: "branchOffice",              text: "NÚMERO DE SUCURSAL",                      getData: (intern) => { return intern.santanderData.branchOffice}  },
                            {  width: 32,  value: "rfc",              text: "RFC",                      getData: (intern) => { return intern.rfc}  },
                            {  width: 32,  value: "gender",              text: "SEXO",                      getData: (intern) => { return intern.gender}  },
                            {  width: 32,  value: "nationalitySantander",              text: "NACIONALIDAD",                      getData: (intern) => { return intern.santanderData.nationalitySantander}  },
                            {  width: 32,  value: "maritalStatusSantander",              text: "ESTADO CIVIL",                      getData: (intern) => { return intern.santanderData.maritalStatusSantander}  },
                            {  width: 32,  value: "phone",              text: "CELULAR",                      getData: (intern) => { return intern.phone}  },
                            {  width: 32,  value: "email",              text: "CORREO ELECTRÓNICO",                      getData: (intern) => { return intern.email}  },
                            {  width: 32,  value: "created_at",              text: "FECHA DE INGRESO A LA EMPRESA",                      getData: (intern) => { return intern.created_at}  },
                            {  width: 32,  value: "street",              text: "DOMICILIO DE OFICINA",                      getData: () => { return ""}  },
                            {  width: 32,  value: "suburb",              text: "COLONIA",                      getData: () => { return ""}  },
                            {  width: 32,  value: "zipcode",              text: "CÓDIGO POSTAL",                      getData: () => { return ""}  },
                            {  width: 32,  value: "scolarshipAmount",              text: "INGRESO MENSUAL NETO",                      getData: (intern) => { return intern.scolarshipAmount}  },
                            {  width: 32,  value: "workplaceCode",              text: "ID DE CENTRO DE TRABAJO",                      getData: (intern) => { return intern.santanderData.workplaceCode}  },
                            {  width: 32,  value: "curp",              text: "CURP",                      getData: (intern) => { return intern.curp}  },
                            {  width: 32,  value: "accountNumber",              text: "NO DE CUENTA",                      getData: (intern) => { return intern.santanderData.accountNumber}  },
                        ],
                'worksheetName': "Alta Santander",
                'headerText': "Alta Santander"
        }

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        width.push({ width: 0 })
        worksheetPicoUsoInfra.columns = width;

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ff0000'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'ffffff'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){
            if (dropDown(key)){
                worksheetName.getCell(startCell).dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: dropDownValues[key],
                    fgColor:{argb:'FFFFFFFF'}
                };
            } else {
                worksheetName.getCell(startCell).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFFFFFFF'}
                }
            }
            worksheetName.getCell(startCell).value = text;
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createTableAvail(worksheetName){
            let cellNumber = 1;
            for (let n of NATIONALITY) {
                createWhiteCell(
                    "T" + cellNumber++,
                    n,
                    "",
                    worksheetName
                )
            }

            cellNumber = 1;

            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            if(interns.length){
                interns.forEach((intern, index) => {
                    cellNumber++
                    config.forEach((configItem) => {
                        createWhiteCell(
                            configItem.col + cellNumber,
                            configItem.getData(intern),
                            configItem.value,
                            worksheetName
                        )
                    });
                })
            }
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}

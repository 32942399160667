import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Image, Spinner } from 'react-bootstrap';
export const Options = ({ intern, getDataByZipCode }) => {

    const [options, setOptions] = useState(intern.suburbs || []);
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    let optionsAux = [];
    useEffect(() => {
        setOptions(options);
    }, [reload]);

    if (isLoading) {
        return <div className='center-container'>
            <Spinner animation="border" role="status" />
        </div>
    }

    return (
        <Form.Control key={intern._id} disabled={intern.isSelected || intern.processed} as="select" readOnly={true}
            onChange={(e) => { intern.suburb = e.target.value }}
            className="input-text" style={{ "width": "150px" }}
            onClick={async () => {
                setOptions([]);
                if (!intern.suburbs?.length) {
                    setIsLoading(true);
                    optionsAux = await getDataByZipCode(intern, intern.zip_code);
                    setOptions(optionsAux);
                    setIsLoading(false);
                    setReload(true);
                } else {
                    setIsLoading(true);
                    setOptions(intern.suburbs);
                    setIsLoading(false);
                }
            }}
        >  
            {options?.length ? options.map(element => {
                return (
                    <option selected={intern.suburb ? intern.suburb == element : false}>{element}</option>
                );
            }) : <option disabled selected hidden>{intern.suburb || ''}</option>}
        </Form.Control>

    )
}
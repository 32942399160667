import React, { useState, useEffect } from 'react';
import { DateTimePicker } from 'react-widgets';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { notify } from "../../../utils/notify";
import { motion } from "framer-motion";
import Methods from '../../../utils/https';
import { BiArrowBack } from 'react-icons/bi';
import LoadingModal from '../../../custom/LoadingModal/LoadingModal.jsx';
import moment from 'moment';
import { decrypt } from "../../../utils/crypto";
const axios = require("axios");
const uuid = require("uuid");

export default function UpdateRenewContract({ setCurrentTab, context, contractToUpdateRenew, contractToUpdateZipCode }) {
    const currentCompany = context.user ? context.user.company.business_name : "";
    const [contractEndDate, setContractEndDate] = useState(new Date());
    const [contractStartDate, setContractStartDate] = useState(new Date());
    const [universitySelected, setUniversitySelected] = useState("");
    const [facultySelected, setFacultySelected] = useState("");
    const [currentSuburbs, setCurrentSuburbs] = useState([]);
    const [currentMunicipality, setCurrentMunicipality] = useState("");
    const [currentLeaderEmail, setCurrentLeaderEmail] = useState("");
    const [currentLeaderName, setCurrentLeaderName] = useState("");
    const [isCurpError, setIsCurpError] = useState(false);
    const [curpErrorLabel, setCurpErrorLabel] = useState("");
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [currentCP, setCurrentCP] = useState('');
    const [reloadInfo, setReloadInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [currentDepartmentName, setCurrentDepartmentName] = useState("");
    const dataToEvaluate = ["collegeName", "departmentName", "directBoss", "internAmount", "internCareer", "internCurp", "internEmail", "internExtNumber", "internFaculty", "internFatherLastname", "internFirstName", "internMotherLastname", "internMunicipality", "internPhone", "internScholarship", "internStreet", "internSuburb", "schoolEnrollment", "zipCode"];
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);

    useEffect(() => {
        setInfo();
    }, [reloadInfo]);


    const setInfo = async () => {
        setIsLoading(true);
        setUniversities(context.universities);
        let [contract] = await Promise.all([Methods.getContractById(contractToUpdateRenew._id), getDepartments(contractToUpdateRenew.companyId)]);
        (contract.zipCode || contractToUpdateZipCode) && getDataByZipCode(contract.zipCode || contractToUpdateZipCode);
        setCurrentCP(contract.zipCode || contractToUpdateZipCode)
        setContractEndDate(new Date(getFormatedDate(contract.internEndDate)));
        setContractStartDate(new Date(getFormatedDate(contract.internStartDate)));
        setCurrentMunicipality(contract.internMunicipality);
        setCurrentLeaderEmail(contract.directBoss_name);
        setCurrentLeaderName(contract.directBoss);
        setCurrentDepartmentName(contract.departmentName || "");
        setUniversitySelected(contract.collegeName || "");
        setFacultySelected(contract.internFaculty || "");
        setIsLoading(false);
    }


    const updateContract = async (e) => {
        e.preventDefault();
        let data = {
            _id: contractToUpdateRenew._id,
            attributes: [],
            emailCase: "Update"
        };


        for (let index = 0; index < e.target.elements.length; ++index) {
            const key = e.target.elements[index].id;
            let value = e.target.elements[index].value;
            if (dataToEvaluate.includes(key) && contractToUpdateRenew[key] !== value && value) {
                if (key == "departmentName") {
                    value = await getDepartmentName(value);
                }
                data.attributes.push({
                    updateKey: key, updateValue: value
                });
            };
        };

        await setContact(data.attributes);

        if (verifyErrors(data.attributes)) return;

        await Methods.updateContractById(data);
        setReloadInfo(reloadInfo)
        notify("Cambios realizados correctamente", "success");
    }

    const getDepartments = async (id_company) => {
        try {
            let response = await axios({
                url: `${URL}/departments`,
                method: "PUT",
                data: {
                    id_company: id_company,
                    departmentField: context.getDepartmentField(),
                }
            });

            verifyDepartments(response.data.data);
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
            setDepartments([]);
        };
    };

    const verifyDepartments = async (departments) => {
        let availableDepartments = [];
        await departments.forEach(element => {
            if (element.leaderId) {
                availableDepartments.push(element);
            }
        });
        await setDepartments(availableDepartments);;
    }


    const getDepartmentName = async (departmentId) => {
        let result = {}
        await departments.forEach(element => {
            if (departmentId == element._id) {
                result = element;
            }
        });
        return result?.title;
    }

    const setContact = async (data) => {
        let university;
        let faculty;
        let verify = false
        let result = {}
        for (let keyValue of data) {
            if (keyValue.updateKey == "collegeName") {
                university = keyValue.updateValue;
                verify = true;
            }
            if (keyValue.updateKey == "internFaculty") {
                faculty = keyValue.updateValue;
                verify = true;
            }
        }
        if (!faculty && !university && !verify) return;
        if (verify) result = await getUnivesityContact(university || contractToUpdateRenew.collegeName, faculty ? faculty : contractToUpdateRenew.internFaculty);
        if (result?.email) {
            data.push({ updateKey: "collegeContact", updateValue: result.email })
        } else {
            data.push({ updateKey: "collegeContact", updateValue: "" })
        }
    }

    const getUnivesityContact = async (currentUniversity, currentFaculty) => {
        if (currentUniversity == "Otro" || !currentUniversity) return {};
        let universitySelected;
        let universityContact = {};
        for (let university of universities) {
            if (university._name === currentUniversity) {
                universitySelected = university;
            }
        }

        if (universitySelected?.sign) {
            universityContact = {
                phone: universitySelected?.phone,
                email: universitySelected?.email,
            }
        } else {
            if (!universitySelected) return {};
            for (let faculty of universitySelected?.faculties) {
                if (faculty._name == currentFaculty) {
                    universityContact = {
                        phone: faculty.phone,
                        email: faculty.email,
                        contactName: faculty.contactName
                    }
                }
            }
        }
        return universityContact;
    }


    const verifyErrors = (dataToUpdate) => {

        if (isPhoneError) {
            notify("El teléfono ingresado en información proporcionada por el practicante debe tener 10 dígitos", "error");
            return true
        }

        if (isEmailError) {
            notify("El número de cuenta ingresado debe tener 10 dígitos", "error");
            return true
        }

        if (isCurpError) {
            notify("El CURP no es valido", "error")
            return true
        }

        if (dataToUpdate.length == 0) {
            notify("No hay cambios por realizar", "error");
            return true
        }

        return false;
    }


    const getDataByZipCode = async (currentCP) => {
        let api = (await Methods.getApiData("sepomex"))[0];
        api.apiKey = JSON.parse(decrypt(api.apiKey))
        try {
             let response = await axios({
                url: `${api.apiLink}${currentCP}?token=${api.apiKey}`,
                method: "GET",
             });
            await setZipCodeData(response.data);
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
        };
    }


    const setZipCodeData = async (data) => {
        let suburb = [];
        if (data.length > 0) {
            setCurrentMunicipality(data[0].response.municipio);
            await data.forEach(element => {
                const data = element.response.asentamiento;
                suburb.push(data);
            });
            suburb.sort();
        }
        setCurrentSuburbs(suburb);
    }


    const canUpdateContract = () => {
        let currentDate = moment().startOf('day');
        let contractStartDateAux = moment(getFormatedDate(contractToUpdateRenew.internStartDate)).startOf('day');
        return (contractToUpdateRenew.contractStatus == "En proceso" && !contractToUpdateRenew.contractApprovalDate && currentDate.diff(contractStartDateAux, 'days') <= 6)
    }

    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if (regexDate.test(dateString)) {
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            result = month + "/" + day + "/" + year;
        } else {
            const newDateArray = dateString.split("/");
            month = addZeroToDate(newDateArray[0]);
            day = addZeroToDate(newDateArray[1]);
            result = day + "/" + month + "/" + newDateArray[2];
        }
        return result;
    }

    const addZeroToDate = (value) => {
        if (value.length == 1) {
            return "0" + value;
        } else {
            return value;
        }
    };


    const setLeader = async (department) => {
        setCurrentLeaderEmail("");
        setCurrentLeaderName("");
        let isLeader;
        await departments.forEach(element => {
            if (element.leader && department == element._id) {
                isLeader = element;
            }
        });
        if (isLeader) {
            let data = isLeader.leader;
            let name = data.fullName;
            setCurrentLeaderEmail(data.email);
            setCurrentLeaderName(name);
            setCurrentDepartmentName(isLeader.title);
        } else {
            notify(`Este ${context.getDepartmentField()} no cuenta con usuario ${context.getGeneralistaField()}`, "error");
        }
    }

    const curpValidation = async (curp) => {
        const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
        const isValid = curp.match(regex);
        if (curp.length > 0) {
            if (curp.length != 18 && curp.length > 0) {
                setIsCurpError(true);
                setCurpErrorLabel("El CURP debe tener mínimo 18 caracteres");
            } else {
                if (isValid) {
                    setIsCurpError(false);
                    setCurpErrorLabel("");
                } else {
                    setIsCurpError(true);
                    setCurpErrorLabel("El formato del CURP no es válido");
                }
            }
        } else {
            setIsCurpError(false);
            setCurpErrorLabel("");
        }
    };

    const verifyPhoneError = async (phone) => {
        if (phone.length > 0) {
            if (phone.length == 10) {
                setIsPhoneError(false);
            }
            if (phone.length < 10 && phone.length >= 1) {
                setIsPhoneError(true);
            }
        } else {
            setIsPhoneError(false);
        }
    }

    const verifyEmail = async (email) => {
        if (email.length > 0) {
            if (!regexEmail.test(email)) {
                setIsEmailError(true)
            } else {
                setIsEmailError(false)
            }
        } else {
            setIsEmailError(true)
        }
    }


    return (
        <motion.div initial={{ x: 30, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.25 }} className="new-intern" onSubmit={updateContract}>
            <div className="title-container">
                <BiArrowBack onClick={() => setCurrentTab("verifyInternDocs")} />
                <div>
                    <h4>Renovación de Contrato</h4>
                    <h3 className="company">{currentCompany}</h3>
                </div>
            </div>
            <Form>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="internFirstName">
                            <Form.Label>Nombre (s)</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internFirstName} placeholder="Nombre (s)" onChange={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internFatherLastname">
                            <Form.Label>Apellido Paterno</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internFatherLastname} placeholder="Apellido Paterno" onChange={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internMotherLastname">
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internMotherLastname} placeholder="Apellido Materno" onChange={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internCurp">
                            <Form.Label>CURP</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internCurp} placeholder="CURP" onChange={(e) => { curpValidation(e.target.value) }} />
                            {isCurpError && <Form.Label className="error-label">{curpErrorLabel}</Form.Label>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="internStartDate">
                            <Form.Label>Inicio del Convenio</Form.Label>
                            <DateTimePicker disabled={!canUpdateContract()} name="internStartDate" editFormat={'DD/MM/YYYY'} format={'DD/MM/YYYY'} onCurrentDateChange={(date) => { setContractStartDate(date) }} time={false} value={contractStartDate} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internEndDate">
                            <Form.Label>Término del Convenio</Form.Label>
                            <DateTimePicker disabled name="internEndDate" editFormat={'DD/MM/YYYY'} format={'DD/MM/YYYY'} onCurrentDateChange={(date) => { setContractEndDate(date) }} time={false} value={contractEndDate} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internContractDuration">
                            <Form.Label>Duración de Contrato</Form.Label>
                            <Form.Control disabled className="input" type="text" defaultValue={contractToUpdateRenew.internContractDuration} placeholder="Duración de Contrato" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="collegeName">
                            <Form.Label>Universidad</Form.Label>
                            <Form.Control
                                disabled={!canUpdateContract()}
                                className="input"
                                as="select"
                                onChange={(e) => {
                                    setUniversitySelected(e.target.value);
                                    contractToUpdateRenew.internFaculty = e.target.value == "Otro" ? "Otro" : "";
                                    contractToUpdateRenew.internCareer = e.target.value == "Otro" ? "Otro" : "";
                                }}>
                                <option disabled selected hidden>{contractToUpdateRenew.collegeName || ""}</option>
                                {universities.length > 0 ? universities.map((university, index) => {
                                    if (!university.isHistorical) {
                                        return (
                                            <option key={index} id={university._id} value={university._name}>{university._name}</option>
                                        )
                                    }
                                }) : <option disabled>No hay universidades</option>}
                                <option value="Otro">Otro</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="schoolEnrollment">
                            <Form.Label>Matrícula</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.schoolEnrollment} placeholder="Matrícula" />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <Form.Group controlId="internScholarship">
                            <Form.Label>Escolaridad</Form.Label>
                            <Form.Control as="select" disabled={!canUpdateContract()} readOnly={true} className="input" >
                                <option disabled selected hidden>{contractToUpdateRenew.internScholarship || ""}</option>
                                <option>Profesional</option>
                                <option>Preparatoria</option>
                                <option>Carrera Técnica</option>
                                <option>Maestría</option>
                                <option>Doctorado</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internFaculty">
                            <Form.Label>{context.getFacultadField(true)}</Form.Label>
                            <Form.Control
                                disabled={!canUpdateContract()}
                                as="select"
                                className="input"
                                onChange={(e) => {
                                    setFacultySelected(e.target.value);
                                    contractToUpdateRenew.internCareer = "";
                                }}>
                                <option selected hidden>{contractToUpdateRenew.internFaculty || ""}</option>
                                {universities.map(university => {
                                    if (university._name === (universitySelected || contractToUpdateRenew.collegeName)) {
                                        if (university.faculties && university.faculties.length > 0) {
                                            return university.faculties.map((faculty, index) => {
                                                return (
                                                    <option key={index} id={faculty._name} value={faculty._name}>{faculty._name}</option>
                                                )
                                            })
                                        } else {
                                            return (<option disabled value="">No hay {context.getFacultadField(false, true)}</option>)
                                        }
                                    }
                                })}
                                {(!contractToUpdateRenew.collegeName && !universitySelected) && <option disabled>Aun no seleccionas una universidad</option>}
                                {contractToUpdateRenew.internFaculty == "Otro" && <option value="Otro" disabled>No hay {context.getFacultadField(false, true)}</option>}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internCareer">
                            <Form.Label>Carrera</Form.Label>
                            <Form.Control
                                disabled={!canUpdateContract()}
                                as="select"
                                className="input">
                                <option disabled selected hidden>{contractToUpdateRenew.internCareer || ""}</option>
                                {universities && universities.map(university => {
                                    if (university._name === (universitySelected || contractToUpdateRenew.collegeName)) {
                                        if (university.faculties && university.faculties.length > 0) {
                                            return university.faculties.map(faculty => {
                                                if (faculty._name === (facultySelected || contractToUpdateRenew.internFaculty)) {
                                                    if (faculty.careers && faculty.careers.length > 0) {
                                                        return faculty.careers.map((career, index) => {
                                                            return (
                                                                <option key={index} id={career._name} value={career._name}>{career._name}</option>
                                                            )
                                                        })
                                                    } else {
                                                        return (<option disabled value="">No hay carreras</option>)
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })}
                                {(!contractToUpdateRenew.internFaculty && !facultySelected) && <option disabled>Aun no seleccionas una {context.getFacultadField()}</option>}
                                {contractToUpdateRenew.internCareer == "Otro" && <option value="Otro" disabled>No hay carreras</option>}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="internStreet">
                            <Form.Label>Calle</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internStreet} placeholder="Calle" />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internExtNumber">
                            <Form.Label>Número Exterior</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internExtNumber} placeholder="Número Exterior" />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="zipCode">
                            <Form.Label>Código Postal</Form.Label>
                            <Form.Control type="text"
                                disabled={!canUpdateContract()}
                                onInput={async (e) => {
                                    e.target.value = (0, e.target.value).toString().slice(0, 5)
                                    setCurrentCP(e.target.value)
                                    if (e.target.value.length == 5) {
                                        await getDataByZipCode(e.target.value);
                                    }
                                }}
                                value={currentCP} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internSuburb">
                            <Form.Label>Colonia</Form.Label>
                            <Form.Control readOnly={true} disabled={!canUpdateContract()} as="select" className='input' onClick={() => (contractToUpdateRenew.zipCode && !currentSuburbs.length) && getDataByZipCode(contractToUpdateRenew.zipCode)}>
                                <option disabled selected hidden>{contractToUpdateRenew.internSuburb || ""}</option>
                                {currentSuburbs.map(element => {
                                    return (
                                        <option>{element}</option>
                                    );
                                })}
                                {(!contractToUpdateRenew.internSuburb && !currentSuburbs.length) && <option disabled>Se requiere un código postal valido</option>}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internMunicipality">
                            <Form.Label>Municipio</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={currentMunicipality} placeholder="Municipio" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="internPhone">
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internPhone} placeholder="Télefono"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                                onChange={(e) => { verifyPhoneError(e.target.value) }}
                            />
                            {isPhoneError && <Form.Label className="error-label" >El número de teléfono debe tener 10 dígitos</Form.Label>}

                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="internEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={contractToUpdateRenew.internEmail} placeholder="Email"
                                onChange={(e) => { verifyEmail(e.target.value) }}
                            />
                            {isEmailError && <Form.Label className="error-label" > El correo no es válido</Form.Label>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Form.Group controlId="internAmount">
                            <Form.Label>Beca</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control disabled={!canUpdateContract()} type="number" step=".01" defaultValue={contractToUpdateRenew.internAmount} />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="departmentName">
                            <Form.Label>{context.getDepartmentField()}</Form.Label>
                            <Form.Control as="select" disabled={!canUpdateContract()} className='input' onChange={(e) => { setLeader(e.target.value) }}>
                                <option disabled selected hidden>{currentDepartmentName || ""}</option>
                                {departments.length > 0 ? departments.map((department, index) => {
                                    if (department) {
                                        return (
                                            <option key={index} id={department.title} value={department._id}>{department.title}</option>
                                        )
                                    }
                                }) : <option disabled>No hay {context.getDepartmentField()}</option>}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="directBoss">
                            <Form.Label>{context.getGeneralistaField(true)}</Form.Label>
                            <Form.Control disabled={!canUpdateContract()} className="input" type="text" defaultValue={currentLeaderName} placeholder={context.getGeneralistaField(true)} />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="btn-container">
                    {canUpdateContract() &&
                        <Button className="btn-confirm mt-4" variant="primary" type="submit">
                            Guardar Cambios
                        </Button>
                    }
                </div>
            </Form>
            {isLoading && <LoadingModal isLoading={isLoading} />}



        </motion.div>
    );
};
import { Modal, Button, Spinner } from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import { useState } from 'react';

export const ConfirmDeteleFile = ({
    showConfirmationDeleteModal,
    setShowConfirmationDeleteModal,
    filesToDelete,
    setFilesToDelete,
    setReloadPage,
    reloadPage
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const deleteFiles = async() => {
        setIsLoading(true);
        
        try{
            for(let id of filesToDelete){
                if(id) await Methods.setActionToDisperseByFileId(id, 'delete');
            }
            notify('Archivos eliminados', 'success');

        }catch(error){
            notify('No se pudo realizar la acción', 'error')   
        }

        setIsLoading(false);
        handleClose();
        setReloadPage(!reloadPage)
    }

    const handleClose = () => {
        setShowConfirmationDeleteModal(!showConfirmationDeleteModal);
        setFilesToDelete([]);
    }


    return (
        <Modal className="company-modals" show={showConfirmationDeleteModal} onHide={() => handleClose()} centered>
            <Modal.Body>
                <p>Estás a punto de eliminar todos los regsitros pertenecientes a este banco...</p>
                <small>¡Recuerda revisar todos los datos y documentos antes de continuar!</small>
                <br />

                <Button className="float-right ml-2" variant="primary" onClick={() => deleteFiles()}>
                {!isLoading 
                    ? "Confirmar" 
                    : <Spinner animation="border" role="status">
                    </Spinner>
                }
                </Button> 

                <Button className="float-right" variant="danger" onClick={() => setShowConfirmationDeleteModal(!showConfirmationDeleteModal)}>
                    Cancelar
                </Button>             
            </Modal.Body>
        </Modal>
    )
}





import moment from 'moment';
export default {

    test(workbook, moduleTitle, interns, dates, days, prefacture, filters, prefactureInfo, isConsolidationPrefacture, lastPeriod) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()
        let gadminval = `${(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense)}`;
        gadminval = parseInt(gadminval);
        gadminval = parseFloat(gadminval/100);

        let totalInterns = interns.length;
        let paidDual  = 0 ;
      
        if(totalInterns <= 10 ){
            paidDual = 1599.00;
        }
        else if(totalInterns >10 && totalInterns <= 24){
            paidDual = 1499.00;
        }
        else if(totalInterns> 24){
            paidDual = 1299.00;
        } 
     


        const cols = ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S", "T", "U"];
        let config = [ //cols & config, same length
            {  width: 5,  value: "#",         text: "#",                               getData: (intern) => { return intern.firstname || "" } },
            {  width: 23,  value: "firstname",         text: "Nombre",                  getData: (intern) => { return intern.firstname || "" }  },
            {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: (intern) => { return intern.father_lastname || "" }  },
            {  width: 17,  value: "motherLastName",    text: "Apellido Materno",        getData: (intern) => { return intern.mother_lastname || "" }  },
            {  width: 11,  value: "bank",              text: "Banco",                   getData: (intern) => { return intern.bank || "" }  },
            {  width: 18,  value: "clabe",             text: "Cuenta",                  getData: (intern) => { return intern.clabe || "" }  },
            {  width: 12,  value: "dueAmount",         text: "Neto",                    getData: (intern) => { return currencyFormat(intern.total_amount) }  },
            {  width: 23,  value: "costCenter",        text: "Centro de Costos",        getData: (intern) => { return intern.cost_center_name || "" }  },
            {  width: 18,  value: "startDate",         text: "Inicio de Convenio",      getData: (intern) => { return intern.contractStartDate || "" }  },
            {  width: 18,  value: "endDate",           text: "Final de Convenio",       getData: (intern) => { return intern.contractEndDate || "" }  },
            {  width: 5,  value: "daysToPay",         text: "Días",                    getData: (intern) => { return (intern.daysToPay || intern.pay_days) || ""}  },
            {  width: 12,  value: "scolarshipAmount",  text: "Monto de Beca",           getData: (intern) => { return currencyFormat(intern.scolarshipAmount) }  },
            {  width: 12,  value: "adjustment",        text: "Ajuste",                  getData: (intern) => { return currencyFormat(intern.adjustment) }  },
            {  width: 12,  value: "companyInsurance",  text: "Seguro de Empresa",       getData: (intern) => { return currencyFormat(intern.company_insurance_amount) }  },
            {  width: 12,  value: "internsInsurance",  text: "Seguro de Practicantes",  getData: (intern) => { return currencyFormat(intern.intern_insurance_amount) }  },
            {  width: 12,  value: "modelodual",  text: "Cuota admin MD",  getData: (intern) => { return currencyFormat(paidDual/30 * (intern.daysToPay || intern.pay_days || 0)) }  },
            {  width: 12,  value: "amountToPay",       text: "Monto de Pago",           getData: (intern) => { return currencyFormat(intern.total_amount) }  },
            {  width: 12,  value: "adminG",            text: "G. Admin",                getData: (intern) => { return currencyFormat(intern.total_amount * gadminval) }  },
            {  width: 12,  value: "subtotal",          text: "SubTotal",                getData: (intern) => { return currencyFormat(intern.total_amount + ((intern.total_amount * gadminval) + intern.company_insurance_amount)) +(paidDual/30 * (intern.daysToPay || intern.pay_days || 0)) }  },
            {  width: 12,  value: "vat",               text: "IVA",                     getData: (intern) => { return currencyFormat(((intern.total_amount + ((intern.total_amount * gadminval) + intern.company_insurance_amount)) +(paidDual/30 * (intern.daysToPay || intern.pay_days || 0)) ) * 0.16)}},
            {  width: 12,  value: "internTotal",       text: "Total de Practicantes",   getData: (intern) => { return currencyFormat((intern.total_amount + (paidDual/30 * (intern.daysToPay || intern.pay_days || 0))+ (intern.total_amount * gadminval) + intern.company_insurance_amount) *1.16) }  },
            
        ];
        filters.push({ value: '#', label: '#'});

        function filterIncludes(configItem){
            for(let index in filters){
                if(filters[index].value == configItem.value) return true;
            };
            return false;
        }
        config = config.filter((configItem) => filterIncludes(configItem))


        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet("Lista de Practicantes", {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, worksheetName, tryToConvertNumber){
            const parsed = tryToConvertNumber ? parseFloat(text) : text
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            if(isNaN(parsed)){
                worksheetName.getCell(startCell).value = text;
            }else{
                worksheetName.getCell(startCell).value = parsed;
            }
            if (typeof text === 'string' || text instanceof String){
                if(text.includes("/") || text.includes("-") || text.includes("%"))
                {
                    worksheetName.getCell(startCell).value = text;
                }
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "B1",
                "No de Cliente:",
                worksheetName
            )
            createWhiteCell(
                "C1",
                prefactureInfo.clientNumber  || "",
                worksheetName
            )

            createBlueCell(
                "D1",
                "Razón Factura: ",
                worksheetName
            )

            createWhiteCell(
                "E1",
                prefactureInfo.companySocialReason  || "",
                worksheetName
            )

            createBlueCell(
                "F1",
                "División Comercial:",
                worksheetName
            )
            worksheetName.mergeCells("G1" + ":" + "H1")
            createWhiteCell(
                "G1",
                "",
                worksheetName
            )

            createBlueCell(
                "I1",
                "Usuario:",
                worksheetName
            )
            worksheetName.mergeCells("J1" + ":" + "K1")
            createWhiteCell(
                "J1",
                prefactureInfo.userName  || "",
                worksheetName
            )
            createBlueCell(
                "B2",
                "Empresa:",
                worksheetName
            )

            worksheetName.mergeCells("C2" + ":" + "E2")
            createWhiteCell(
                "C2",
                prefactureInfo.companyName || "",
                worksheetName
            )

            createBlueCell(
                "F2",
                "Período:",
                worksheetName
            )

            worksheetName.mergeCells("G2" + ":" + "H2")
            createWhiteCell(
                "G2",
                getPeriodString(dates, lastPeriod, isConsolidationPrefacture),
                worksheetName
            )

            createBlueCell(
                "I2",
                "Fecha de Creación:",
                worksheetName
            )

            worksheetName.mergeCells("J2" + ":" + "K2")
            createWhiteCell(
                "J2",
                moment().format('DD/MM/YYYY HH:mm:ss'),
                worksheetName
            )

            createBlueCell(
                "B3",
                "Total de Practicantes:",
                worksheetName
            )
            createWhiteCell(
                "C3",
                interns ? interns.length : 0,
                worksheetName
            )


        }


        const currencyFormat = (value) => {
            //return parseFloat(value || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            return value;
        };

        const getPeriodString = (period, lastPeriod, isConsolidationPrefacture) => {
            let [startDate, endDate] = period;
            let a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
            let b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
            if(isConsolidationPrefacture && lastPeriod){
              a = moment(getFormatedDate(lastPeriod.start_date)).format('D/MM/YYYY');
            }
            return `Del ${a} al ${b}`;
        };

        const getFormatedDate = (dateString) => {
            const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
            let result;
            let year;
            let month;
            let day;
            if(regexDate.test(dateString)){
                const newDateArray = dateString.split("-");
                year = newDateArray[0];
                month = addZeroToDate(newDateArray[1]);
                day = addZeroToDate(newDateArray[2]);
                result = month + "/" + day + "/"+ year;
            } else {        
                const newDateArray = dateString.split("/");
                month = addZeroToDate(newDateArray[0]);
                day = addZeroToDate(newDateArray[1]);
                result = day + "/" + month + "/" + newDateArray[2];
            }
            return result;
          }
          
          const addZeroToDate =  (value) => {
            if(value && value.length == 1){
                return "0" + value;
            } else{
                return value;
            }
          };
        function createTableAvail(series, worksheetName){
            let cellNumber = 5;

            createHeaders(worksheetName)


            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                "1.- Lista de Practicantes",
                worksheetName
            );
            cellNumber++

            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });

            if(series.length){
                series.forEach((intern, index) => {
                    cellNumber++
                    config.forEach((configItem) => {
                        if(configItem.value == "#"){
                            createWhiteCell(
                                configItem.col + cellNumber,
                                index + 1,
                                worksheetName
                            )
                        }else{
                            createWhiteCell(
                                configItem.col + cellNumber,
                                configItem.getData(intern),
                                worksheetName,
                                configItem.value == 'costCenter' ? false : true
                            )
                        }
                    });
                });
            } else {
                cellNumber++
                worksheetName.mergeCells(config.at(0).col + cellNumber + ":" + config.at(-1).col + cellNumber)
                createWhiteCell(
                    config.at(0).col + cellNumber,
                    "No Hay Información",
                    worksheetName
                )
            }

            const startTitle = config.at(-4).col,
                  endTitle   = config.at(-3).col,
                  startValue = config.at(-2).col,
                  endValue   = config.at(-1).col;


            cellNumber += 2
            worksheetName.mergeCells(startTitle + cellNumber + ":" + endTitle + cellNumber);
            createBlueCell(startTitle + cellNumber, "Concepto", worksheetName);

            worksheetName.mergeCells(startValue + cellNumber + ":" + endValue + cellNumber);
            createBlueCell(startValue + cellNumber, "Monto", worksheetName);


            const totalsTableCreation = (title, value) => {
                cellNumber++

                worksheetName.mergeCells(startTitle + cellNumber + ":" + endTitle + cellNumber);
                createWhiteCell(startTitle + cellNumber, title, worksheetName);

                worksheetName.mergeCells(startValue + cellNumber + ":" + endValue + cellNumber);
                createWhiteCell(startValue + cellNumber, value, worksheetName);
            }

            let totalDual = 0
            interns.forEach((intern) => {
                let dias = intern.daysToPay || intern.pay_days || 0;
                console.log(paidDual,intern.daysToPay , intern.pay_days,totalDual,dias)
                totalDual += (paidDual/30) *  dias;
            })

            const administrative = (prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense) == 0 ? 10 :(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense);
            const textGAdmin = `${(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense) == 0 ? 10 :(prefacture.administrativeExpensePrefacture || prefacture.administrativeExpense) } % G. Admin`;
            totalsTableCreation("Monto a Pagar",        currencyFormat(prefacture.totalCost));
            totalsTableCreation(textGAdmin,             currencyFormat(((administrative/100)* prefacture.totalCost).toFixed(2)));
            totalsTableCreation("Subtotal sin Seguro",  currencyFormat( ( parseFloat( (administrative/100)* prefacture.totalCost) + parseFloat(prefacture.totalCost) ).toFixed(2))  );
            totalsTableCreation("Seguro Empresa",       currencyFormat(prefacture.insuranceCompanyData));
            totalsTableCreation("Cuota admon Modelo Dual",       currencyFormat(totalDual));
            totalsTableCreation("Adeudos Empresa",      currencyFormat(prefacture.debitCompany));
            totalsTableCreation("Subtotal con Seguro",  currencyFormat((( parseFloat( (administrative/100)* prefacture.totalCost) + parseFloat(prefacture.totalCost) ) + totalDual +prefacture.insuranceCompanyData).toFixed(2)));
            totalsTableCreation("Descuento(GA+Seguro)",        currencyFormat((((administrative/100)* prefacture.totalCost) + prefacture.insuranceCompanyData).toFixed(2)));
            totalsTableCreation("Abono Empresa",        currencyFormat(prefacture.creditCompany));
            totalsTableCreation("Subtotal con Abonos",  currencyFormat(  ((( parseFloat( (administrative/100)* prefacture.totalCost) + parseFloat(prefacture.totalCost) ) + totalDual) -(((administrative/100)* prefacture.totalCost) )).toFixed(2)));
            totalsTableCreation("I.V.A",                currencyFormat( (((( parseFloat( (administrative/100)* prefacture.totalCost) + parseFloat(prefacture.totalCost) ) + totalDual) -(((administrative/100)* prefacture.totalCost) )) * (0.16)).toFixed(3) ));
            totalsTableCreation("Total Final",          currencyFormat(  ((((( parseFloat( (administrative/100)* prefacture.totalCost) + parseFloat(prefacture.totalCost) ) + totalDual) -(((administrative/100)* prefacture.totalCost) ))) + ((((( parseFloat( (administrative/100)* prefacture.totalCost) + parseFloat(prefacture.totalCost) ) + totalDual) -(((administrative/100)* prefacture.totalCost))) * (0.16)))).toFixed(3) ));
        }   

        createTableAvail(interns, worksheetPicoUsoInfra)
        return workbook
    }
}

import React, { useState} from 'react';
import {Form, Row, Col, Button, Modal, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import {DateTimePicker} from 'react-widgets';
import Methods from '../../../utils/https';
import moment from 'moment';
import {setITESMSignedDocs} from '../../../utils/signatureMethods';


export default function RenewIntern({
    internData,
    setShowRenewIntern,
    showRenewIntern,
    contractStartDate,
    contractEndDate,
    setDuration,
    duration,
    renewContractStartDate,
    renewContractEndDate,
    setRenewContractEndDate,
    setRenewContractStartDate,
    currentInternId,
    setUpdateInfo,
    updateInfo,
    renewContract,
    currentInternContract,
    isNewCurrentContractIntern,
    newInternContractData,
    dataToUpdateByIntern,
    setIsNewCurrentContractIntern,
    requireInsurance,
    isCaintraUser,
    setInternDataAux,
    internDataAux,
    isITESMCompany,
    requireSife
}) {
    const [canEditEndDate, setCanEditEndDate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const maxMonthsContract = isITESMCompany ? 12 : 6;
    const maxMonths = isITESMCompany ?  ['1','2','3','4','5','6','7','8','9','10','11','12'] : ['1','2','3','4','5','6'];
    const [requireInsuranceAux, setRequireInsuranceAux] = useState(requireInsurance);
    const [insuranceChange, setInsuranceChange] = useState(false);
    const newDepartment = async (e) => {
        let insuranceAux = insuranceChange ? requireInsuranceAux : requireInsurance;
        let promises = [];
        setIsLoading(true);
        e.preventDefault();

        if(duration == 0){
            let startDate = moment(renewContractStartDate);
            let endDate = moment(renewContractEndDate);
            let totalDuration = Math.ceil(endDate.diff(moment(startDate),"months", true));
            duration = totalDuration;
            
            if(totalDuration > maxMonthsContract){
                notify(`La fecha fin no puede ser mayor a ${maxMonthsContract} meses de la fecha actual`, "error");
                return;
            }
        }
   
        
        if(verifyErrors(internData, contractStartDate, renewContractStartDate, contractEndDate)){
            setIsLoading(false);
            return;
        } 

        let data = {
            _id: currentInternId,
            lastStatus: (isNewCurrentContractIntern || currentInternContract.contractStatus == "Inactivo") ?  currentInternContract.contractStatus : undefined,
            attributes: [
                { updateKey: "contractEndDate", updateValue: e.target.elements.contractenddate.value },
                { updateKey: "contractStartDate", updateValue: e.target.elements.contractstartdate.value },
                { updateKey: "contractDuration", updateValue: duration},
                { updateKey: "internStatus", updateValue: "En proceso"},
                { updateKey: "isFutureRenovation", updateValue: (isNewCurrentContractIntern || currentInternContract.contractStatus == "Inactivo" || currentInternContract.contractStatus == "Invalidado") ? false : true }
            ],
            emailCase:"Renovacion"
        };
        

        if(verifyInternCollege()){
            setIsLoading(false);
            return;
        }

        internData.contractEndDate = e.target.elements.contractenddate.value;
        internData.contractStartDate = e.target.elements.contractstartdate.value;
        let day = internData.contractStartDate.slice(0,2);
        let month = internData.contractStartDate.slice(3,5);
        let year = internData.contractStartDate.slice(6,10);
        let dateAux = year + "-" + month + "-" + day;
        dateAux = dateAux.replace('/', '-');
        dateAux = dateAux;

        if(isNewCurrentContractIntern){
            await updateInternContract(dataToUpdateByIntern, data);
        }

        await renewContract({isLastContractInvalid: false, lastContract: isNewCurrentContractIntern ? newInternContractData : undefined, renewUnsuscribedInternData: data.attributes, requireInsuranceAux: insuranceAux});

        if(currentInternContract.contractStatus == "Inactivo" || currentInternContract.contractStatus == "Invalidado"){
            let internData = data;
            internData.attributes.pop();
            promises.push(Methods.updateIntern(internData));
        }

        promises.push(Methods.updateIntern({
            _id: currentInternId,
            attributes: [
                { updateKey: "requireInsurance", updateValue: insuranceAux }
            ]
        }));

        if(isITESMCompany && requireSife){
            promises.push(setITESMSignedDocs(currentInternId));
        }

        await Promise.all(promises);
        setInternDataAux({...internDataAux, requireInsurance: insuranceAux});

        notify("Convenio generado con éxito", "success");
        handleClose();
        setIsNewCurrentContractIntern(false);
        setIsLoading(false)
        setUpdateInfo(!updateInfo);
        sendEmail(internData);
    };



    const sendEmail = async (intern) => {
        const emailParams = {
            to: intern.email,
            subject: "Renovación de convenio",
            text: `¡Hola ${intern.firstname} ${intern.father_lastname} ${intern.mother_lastname}! <br/> 

            Tu convenio ha sido renovado.
            <br/>

            <span style="font-weight: bold;">Te invitamos a descargarlo en nuestra plataforma  <span style="color: #0080fd;">grow.</span></span>
            <br/>

            <span style="font-weight: bold;">¡Ingresa ahora!</span>
            <br/>

            <span style="font-weight: bold;">Link Web: CAINTRA (growpracticantes.com)</span>
            <br/>
            

            En caso de presentar problemas para acceder, por favor notifícalo al correo de atención:
            <br/>
            <span style="color: #0080fd;"><a href="mailto:soporte.practicantes@caintra.org.mx">soporte.practicantes@caintra.org.mx</a></span>
            <br>
             Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.
   
            `,
        };

        await Methods.sendExternalEmail(intern, emailParams);

    }


    const updateInternContract = async(dataToUpdateByIntern, dataToEvaluate) => {


        for(let dataKey of dataToEvaluate.attributes){ 
            let result = dataToUpdateByIntern.attributes.find(object => object.updateKey == dataKey.updateKey)
            if(result){
                for(let internValue of dataToUpdateByIntern.attributes){
                    if(internValue.updateKey == dataKey.updateKey ){
                        internValue.updateValue = dataKey.updateValue;
                    }
                }
            }else{
                if(dataKey.updateKey != "isFutureRenovation"){
                    dataToUpdateByIntern.attributes.push({ updateKey: dataKey.updateKey, updateValue: dataKey.updateValue })
                }
            }
        }

        await Methods.updateIntern(dataToUpdateByIntern);
    }

    const verifyErrors = (internData, contractStartDate, renewContractStartDate, contractEndDate) => {
        let startDate;
        let startDateToEvaluate;
        let notifyText;
        let currentDate = moment().startOf('day');

        if(internData.internStatus == "Activo" && !currentInternContract?.unsuscribeDate){
            startDateToEvaluate = moment(contractEndDate).startOf('day');
            startDate = moment(renewContractStartDate).startOf('day');
            notifyText = "El inicio del convenio debe ser mayor a la fecha de fin de convenio actual";
            if(startDate.diff(startDateToEvaluate, 'days') <= 0){
                notify(notifyText, "error");
                return true;
            }
        }else{
            startDateToEvaluate = moment(contractStartDate).startOf('day');
            startDate = moment(renewContractStartDate).startOf('day');
            notifyText = "El inicio del convenio debe ser mayor a la fecha de fin de convenio actual";
            if(currentInternContract?.unsuscribeDate){
                startDateToEvaluate = moment(currentInternContract?.unsuscribeDate).startOf('day');
                notifyText = "El inicio del convenio debe ser mayor a la fecha de baja de practicante";
            }
            if(currentInternContract.contractStatus == 'Invalidado'){
                startDateToEvaluate = moment(contractStartDate).subtract(6, 'day').startOf('day');
                notifyText = "La fecha de inicio no puede ser 7 días menor que el inicio del convenio invalidado";
            }
            if(startDate.diff(startDateToEvaluate, 'days') <= 0){
                notify(notifyText, "error");
                return true;
            }
        }
        startDate = moment(renewContractStartDate).startOf('day');
        if(startDate.diff(currentDate, 'days') > 30){
            notify('El inicio de convenio no puede ser mayor a 30 días a partir de hoy', 'error');
            return true
        }

        return false;
    }

    const getInternUnsuscribeDate = (date) => { 
        return date ? moment(date).format("DD/MM/YYYY") : "";
    }
    
    const verifyInternCollege = () => {
        if(!internData.college || !internData.faculty || !internData.career){
            notify("Aun no llenas los datos necesarios para continuar el proceso", "error");
            return true;
        }
        return false;
    }

    const generateNewEndDate = (event) => {
        event.preventDefault();
        const months = event.target.value;
        let date = new Date(renewContractStartDate);
        setCanEditEndDate(parseInt(months) == 0);
        date.setHours(0, 0, 0);
        date.setMonth(date.getMonth() + parseInt(months));
        date.setDate(date.getDate() - 1);
        
        setRenewContractEndDate(date);
    };

    const getNewDate = (date) => {
        let newDate = new Date(date);
        newDate.setHours(0, 0, 0);

        setRenewContractEndDate(newDate);

        if(duration != 0){
            newDate.setMonth(newDate.getMonth() + parseInt(duration));
            newDate.setDate(newDate.getDate() - 1);

            if(date.getDate() > 28 && newDate.getMonth() == 2){
                let dateAux = new Date(newDate.getFullYear(), 2, 0);
                newDate = dateAux;
            }   
            newDate.setHours(0,0,0,0);
        }
    };

    const handleClose = () => {
        setShowRenewIntern(!showRenewIntern);
        setCanEditEndDate(true);
    }

    return(
        <Modal className="new-cc-modal" show={showRenewIntern} onHide={() => handleClose()} centered>
            <Modal.Header>
                <Image src={InternsLogo} className="logoCaintra" />
                <p>Renovación de practicante</p>
            </Modal.Header>
                <Modal.Body> 
                    <>
                        <Form onSubmit={newDepartment}>
                            <Row>
                                <Col>
                                    <Form.Group controlId="contractduration">
                                        <Form.Label>Duración del convenio</Form.Label>
                                        <Form.Control as="select" onChange={(e)=> {setDuration(e.target.value); generateNewEndDate(e);}}>
                                            <option value="0">Por determinar</option>
                                            {maxMonths.map(months => {
                                                return <option value={months}> {months} {months != '1' ? 'meses' : 'mes'} </option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    { (currentInternContract?.unsuscribeDate && currentInternContract.contractStatus != 'Invalidado') &&
                                        <Form.Label>
                                            Fecha de baja del último convenio: { getInternUnsuscribeDate(currentInternContract.unsuscribeDate) }
                                        </Form.Label>
                                    }
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                                <Form.Group controlId="contractstartdate">
                                    <Form.Label>Inicio del convenio</Form.Label>
                                    <DateTimePicker name="contractstartdate"  editFormat={'DD/MM/YYYY'} format={'DD/MM/YYYY'} onChange={(e)=>{getNewDate(e); setRenewContractStartDate(e);}} time={false} value={renewContractStartDate}/>
                                </Form.Group>
                            </Col>
                            <Col>    
                                <Form.Group controlId="contractenddate">
                                    <Form.Label>Término de convenio</Form.Label>
                                    <DateTimePicker disabled={!canEditEndDate} name="contractenddate" editFormat={'DD/MM/YYYY'} format={'DD/MM/YYYY'} onChange={(e)=>{setRenewContractEndDate(e);}}  time={false} value={renewContractEndDate} />
                                </Form.Group>
                            </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {(isCaintraUser && !requireInsurance) && 
                                        <div className='checkClass' style={{marginBottom: '20px'}}>  
                                            <Form.Check 
                                                onChange={() => {
                                                    setRequireInsuranceAux(!requireInsuranceAux);
                                                    setInsuranceChange(true);
                                                }}
                                                type="checkbox"
                                                checked={requireInsuranceAux}
                                            />
                                            <Form.Label style={{margin: '0'}}> El practicante requiere seguro </Form.Label>
                                        </div> 
                                    } 
                                </Col>
                            </Row> 

                            <Button className="float-right ml-2" variant="primary" type="submit" disabled={isLoading}>
                                Renovar
                            </Button>
                            <Button className="float-right" variant="danger" type="button" onClick={() => handleClose()}>
                                Cancelar
                            </Button> 
                        </Form>
                    </>
                </Modal.Body>
        </Modal>
    )
};
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Row, Col, Card, OverlayTrigger, Tooltip, Popover, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlusCircle, faSyncAlt, faEdit, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import NewCostCenterModal from "./NewCostCenterModal";
import NewDepartmentModal from './NewDepartmentModal';
import UpdateDepartmentModal from './UpdateDepartmentModal';
import UpdateCostCenterModal from './UpdateCostCenterModal';
import DeleteDepartmentModal from './DeleteDepartmentModal';
import DeleteCostCenterModal from './DeleteCostCenterModal.jsx';
import { notify } from "../../../utils/notify";
import { BiArrowBack } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
const axios = require("axios");

export default function ManageDepartments({ currentCompanyId, context, setTab }) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const companyId = context.user.company._id;
    const [isLoading, setIsLoading] = useState(true);
    const [departments, setDepartments] = useState(undefined);
    const [updateList, setUpdateList] = useState(false);
    const [rotateSync, setRotateSync] = useState(false);
    const rotate = rotateSync ? "rotate" : "";
    const [currentDepartmentId, setCurrentDepartmentId] = useState("");
    const [currentCostCenterId, setCurrentCostCenterId] = useState("");
    const [displayNewCostCenterModal, setDisplayNewCostCenterModal] = useState(false);
    const [displayUpdateCostCenterModal, setDisplayUpdateCostCenterModal] = useState(false);
    const [displayDeleteCostCenterModal, setDisplayDeleteCostCenterModal] = useState(false);
    const [displayNewDepartmentModal, setDisplayNewDepartmentModal] = useState(false);
    const [displayDeleteDepartmentModal, setDisplayDeleteDepartmentModal] = useState(false);
    const [displayUpdateDepartmentModal, setDisplayUpdateDepartmentModal] = useState(false);
    const [currentCostCenter, setCurrentCostCenter] = useState({});

    useEffect(() => {
        getDepartments();
    }, [updateList]);

    const getDepartments = async () => {
        try {
            let _departments = await axios({
                url: `${URL}/departments`,
                method: "PUT",
                data: {
                    id_company: currentCompanyId,
                    departmentField: context.getDepartmentField(),
                }
            });

            setDepartments(_departments.data.data);
            setIsLoading(false);
            setRotateSync(false);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setRotateSync(false);
            setIsLoading(false);
            setDepartments([]);
        };
    };

    const handleCostCenterOptions = (option, costcenter_id, department_id) => {
        setCurrentCostCenterId(costcenter_id);

        switch (option) {
            case "update":
                setDisplayUpdateCostCenterModal(!displayUpdateCostCenterModal);
                handleSetCostCenter(costcenter_id);
                break;
            case "delete":
                setCurrentDepartmentId(department_id);
                setDisplayDeleteCostCenterModal(!displayDeleteCostCenterModal);
                break;
            default:
                break;
        };
    };

    const handleOpenNewCostCenter = (department_id) => {
        setCurrentDepartmentId(department_id);
        setDisplayNewCostCenterModal(!displayNewCostCenterModal);
    };

    const handleSetCostCenter = (id) => {
        let result;

        for (let department of departments) {
            for (let costCenter of department.costCenters) {
                if (costCenter._id === id) result = costCenter;
            };
        };

        setCurrentCostCenter(result);
    };

    const handleDepartmentOptions = (option, department_id) => {
        if (!context.can(option, "Departments")) {
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        switch (option) {
            case "create":
                setDisplayNewDepartmentModal(!displayNewDepartmentModal);
                break;
            case "update":
                setCurrentDepartmentId(department_id);
                setDisplayUpdateDepartmentModal(!displayUpdateDepartmentModal);
                break;
            case "delete":
                setCurrentDepartmentId(department_id);
                setDisplayDeleteDepartmentModal(!displayDeleteDepartmentModal);
                break;
            default:
                break;
        };
    };

    const generateDepartmentsCards = () => {
        if (!departments || departments.length == 0) {
            return <></>;
        };
        return departments.map((department, index) => {
            return (
                <Card style={{ width: '20rem' }} key={index}>
                    <Card.Header>
                        <Row>
                            <Col md={6} xs={6} sm={6}>
                                <div className="float-left ml-3 mt-1">
                                    <p>{department.title}</p>
                                </div>
                            </Col>
                            <Col md={6} xs={6} sm={6}>
                                <div className="float-right mt-2 mr-3">

                                    <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-positioned-top`}>
                                                <Popover.Title as="h3">{department.title}</Popover.Title>
                                                <Popover.Content>
                                                    {department.description}
                                                </Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <a href="#"><FontAwesomeIcon icon={faInfoCircle} /></a>
                                    </OverlayTrigger>

                                    {!context.user.company.isITESMCompany &&
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Nuevo Centro de Costos</Tooltip>}
                                        >
                                            <a href="#" onClick={() => { handleOpenNewCostCenter(department._id) }}><FontAwesomeIcon icon={faPlusCircle} /></a>
                                        </OverlayTrigger>
                                    }

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">Editar {context.getDepartmentField()}</Tooltip>}
                                    >
                                        <a href="#" onClick={() => { handleDepartmentOptions("update", department._id) }}><FontAwesomeIcon icon={faEdit} /></a>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">Eliminar {context.getDepartmentField()}</Tooltip>}
                                    >
                                        <a href="#" onClick={() => { handleDepartmentOptions("delete", department._id) }}><FontAwesomeIcon icon={faTrash} /></a>
                                    </OverlayTrigger>

                                </div>

                            </Col>
                        </Row>
                    </Card.Header>
                    
                    {!context.user.company.isITESMCompany && <Card.Body className="card-department-body">
                        <ListGroup>
                            {department.costCenters.length ? department.costCenters.map((element, ccIndex) => {
                                if (element) {
                                    return (
                                        <ListGroup.Item key={ccIndex}>
                                            <Row>
                                                <Col md={6} xs={6} sm={6}>
                                                    <h5>{element.title}</h5>
                                                    <small>{element._key}</small>
                                                </Col>
                                                <Col md={6} xs={6} sm={6}>
                                                    <div className="float-right">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            placement="top"
                                                            overlay={
                                                                <Popover id={`popover-positioned-top`}>
                                                                    <Popover.Title as="h3">{element.title}</Popover.Title>
                                                                    <Popover.Content>
                                                                        {element.description}
                                                                    </Popover.Content>
                                                                </Popover>
                                                            }
                                                        >
                                                            <a href="#"><FontAwesomeIcon icon={faInfoCircle} /></a>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="button-tooltip-2">Editar Centro de Costos</Tooltip>}
                                                        >
                                                            <a href="#" onClick={() => { handleCostCenterOptions("update", element._id) }}><FontAwesomeIcon icon={faEdit} /></a>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="button-tooltip-2">Eliminar Centro de Costos</Tooltip>}
                                                        >
                                                            <a href="#" onClick={() => { handleCostCenterOptions("delete", element._id, department._id) }}><FontAwesomeIcon icon={faTrash} /></a>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    );
                                }

                            }) : <Row></Row>}
                        </ListGroup>
                    </Card.Body>}
                </Card>
            );
        });
    };

    return (
        <motion.div initial={{ x: 30, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.25 }} className="manage-departments">
            <div className="title-container">
                <BiArrowBack onClick={() => setTab("listCompanies")} className="back" /> <h4>Administración de {context.getDepartmentField(false, true)}</h4>
                <div className="users-options">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">Registrar {context.getDepartmentField()}</Tooltip>}
                    >
                        <div onClick={() => { handleDepartmentOptions("create") }}><FiPlus /></div>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                    >
                        <div className="reload-table" onClick={() => { setUpdateList(!updateList); setRotateSync(true) }}><IoReload className={rotate} /></div>
                    </OverlayTrigger>
                </div>
            </div>

            {!isLoading &&

                <div className="grid scroll">
                    {generateDepartmentsCards()}
                </div>

            }

            <NewCostCenterModal
                currentDepartmentId={currentDepartmentId}
                updateList={updateList}
                setUpdateList={setUpdateList}
                displayNewCostCenterModal={displayNewCostCenterModal}
                setDisplayNewCostCenterModal={setDisplayNewCostCenterModal}
                currentCompanyId={currentCompanyId}
            />

            <NewDepartmentModal
                displayNewDepartmentModal={displayNewDepartmentModal}
                setDisplayNewDepartmentModal={setDisplayNewDepartmentModal}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentCompanyId={currentCompanyId}
            />

            <DeleteDepartmentModal
                displayDeleteDepartmentModal={displayDeleteDepartmentModal}
                setDisplayDeleteDepartmentModal={setDisplayDeleteDepartmentModal}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentDepartmentId={currentDepartmentId}
                currentCompanyId={currentCompanyId}
            />

            <UpdateDepartmentModal
                displayUpdateDepartmentModal={displayUpdateDepartmentModal}
                setDisplayUpdateDepartmentModal={setDisplayUpdateDepartmentModal}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentDepartmentId={currentDepartmentId}
            />

            <UpdateCostCenterModal
                currentCostCenterId={currentCostCenterId}
                displayUpdateCostCenterModal={displayUpdateCostCenterModal}
                setDisplayUpdateCostCenterModal={setDisplayUpdateCostCenterModal}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentCostCenter={currentCostCenter}
            />

            <DeleteCostCenterModal
                currentCostCenterId={currentCostCenterId}
                displayDeleteCostCenterModal={displayDeleteCostCenterModal}
                setDisplayDeleteCostCenterModal={setDisplayDeleteCostCenterModal}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentDepartmentId={currentDepartmentId}
                currentCompanyId={currentCompanyId}
            />
        </motion.div>
    );
};
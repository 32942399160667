import React, {useState, useContext} from 'react';
import {Modal, Button, Form, Image, Spinner, Table} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import {UserContext} from '../../../context/UserContext';
import { notify } from '../../../utils/notify.js';


export default function AssignRole({currentRole, openChangePermissions, setOpenChangePermissions, updateRoles, setUpdateRoles, isCaintraCompany}){
    const [isLoading, setIsLoading] = useState(false);
    const [updatedRole, setupdatedRole] = useState(undefined);
    const context = useContext(UserContext);
    const role = context.userRole;
    const isCaintraUser = context.user.data.isCaintraUser;
    const isAdmin = role.toLowerCase().includes("admin") || role.includes("Administrador") ;
    const canEdit = context.can("update", "Roles");
    const adminRole = currentRole.isAdminRole || false;

    const allPermissions = [
        {permission: 'view', label: 'Ver'},
        {permission: 'create', label: 'Crear'},
        {permission: 'update', label: 'Editar'},
        {permission: 'delete', label: 'Eliminar'},
        {permission: 'approve', label: 'Aprobar'},
        {permission: 'adjust', label: 'Ajustes'},
        {permission: 'moving', label: 'Mudanza'},
        {permission: 'disperse', label: 'Dispersar', requiredValidation: true},
        {permission: 'rollback', label: 'Roll-Back', requiredValidation: true},
        {permission: 'cancelInvoice', label: 'Cancelar', requiredValidation: true} 
    ];

    const validations = () => {
        return ((role === "Administrador Caintra" || ( isCaintraUser && isAdmin)) && isCaintraCompany );
    }

    let permissionsAux = [
        {
            roleName: 'Dashboard',
            actions: [],
            roleLabel: 'Dashboard',
            canEditRole: canEdit
        },
        {
            roleName: 'Interns',
            actions: ['view', 'create', 'update', 'delete', 'approve'],
            roleLabel: 'Practicantes',
            canEditRole: canEdit
        },
        {
            roleName: 'Departments',
            actions: ['view', 'create', 'update', 'update', 'delete'],
            roleLabel: context.getDepartmentField(true, true),
            canEditRole: canEdit
        },
        {
            roleName: 'Prefacture',
            actions: ['view', 'create', 'update', 'delete', 'approve', 'adjust', 'rollback', 'cancelInvoice'],
            roleLabel: 'Prefactura',
            canEditRole: canEdit
        },
        {   
            canViewRole: (
                (role === "Administrador Caintra" ||  (isCaintraUser && isAdmin)) 
                && isCaintraCompany
            ),
            roleName: 'Dispersion',
            actions: ['view', 'update', 'disperse'],
            roleLabel: 'Dispersión',
            canEditRole: canEdit
        },
        {
            canViewRole: (
                (role === "Administrador Caintra" ||  (isCaintraUser && isAdmin)) 
                && isCaintraCompany
            ),
            roleName: 'Pending',
            actions: ['view', 'update'],
            roleLabel: 'Pendientes',
            canEditRole: canEdit
        },
        {
            roleName: 'Companies',
            actions: ['view', 'create', 'update', 'delete', 'approve'],
            roleLabel: 'Compañías',
            canEditRole: canEdit
        },
        {
            roleName: 'Roles',
            actions: ['view', 'create', 'update', 'delete'],
            roleLabel: 'Roles',
            canEditRole: canEdit && adminRole
        },
        {
            roleName: 'Users',
            actions: ['view', 'create', 'update', 'delete'],
            roleLabel: 'Usuarios',
            canEditRole: canEdit
        },
        {
            roleName: 'Reports',
            actions: ['view'],
            roleLabel: 'Reportes',
            canEditRole: canEdit
        },
        {
            canViewRole: (role === "Administrador Caintra" || (isCaintraUser)),
            roleName: 'Universities',
            actions: ['view', 'create', 'update', 'delete', 'approve'],
            roleLabel: 'Universidades',
            canEditRole: canEdit
        },
        {   
            canViewRole: (role === "Administrador Caintra" || (isCaintraUser)),
            roleName: 'Logistics',
            actions: ['view', 'update'],
            roleLabel: 'Logística',
            canEditRole: canEdit
        },
        {
            canViewRole: (role === "Administrador Caintra" || (isCaintraUser)),
            roleName: 'Groups',
            actions: ['view', 'create', 'update', 'delete', 'approve'],
            roleLabel: 'Grupos',
            canEditRole: canEdit
        },
        {
            canViewRole: (role === "Administrador Caintra" || (isCaintraUser)),
            roleName: 'Uploads',
            actions: ['view', 'create', 'update', 'delete'],
            roleLabel: 'Cargas masivas',
            canEditRole: canEdit
        },
        {
            canViewRole: (role === "Administrador Caintra" || (isCaintraUser)),
            roleName: 'Configurations',
            actions: ['view'],
            roleLabel: 'Configuración',
            canEditRole: canEdit
        },
        {
            canViewRole: (role === "Administrador Caintra" || (isCaintraUser)),
            roleName: 'Amounts',
            actions: ['view'],
            roleLabel: 'Montos',
            canEditRole: canEdit
        }
    ]



    const userCan = (module, action) => {
        return currentRole._permissions[module]?.[action];
    };

    const updatePermission = (module, action, value) => {
        let newRole = currentRole;
        if(!newRole._permissions[module]){
            newRole._permissions = {...newRole._permissions, [module]: {}}
        }
        if(!newRole._permissions[module][action]){
            newRole._permissions[module] = {...newRole._permissions[module], [action] : value}
        }
        newRole._permissions[module][action] = value;
        setupdatedRole(newRole);
    };

    const updateRole = async () => {
        setIsLoading(true);

        if(!context.can("update", "Roles")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            setIsLoading(false);
            return;
        };

        const roleData = {
            _id: currentRole._id,
            attributes: [
                {
                    updateKey: "_permissions",
                    updateValue: updatedRole._permissions, 
                    expressionAttributeName: "#_permissions"
                }
            ]
        };
        await Methods.updateRole(roleData);

        for(let user of currentRole.users){
            if(user._role == currentRole.label){
                const userData = {
                    _id: user._id,
                    attributes: [
                        {
                            updateKey: "_permissions",
                            updateValue: updatedRole._permissions, 
                            expressionAttributeName: "#_permissions"
                        }
                    ]
                };
                await Methods.updateUser(userData);
            }
        }
        context.onLoad(context.user.data.email, context.token, true);
        setIsLoading(false);
        setOpenChangePermissions(false);
        setTimeout(() => setUpdateRoles(!updateRoles), 550);
    };

    return(
        <Modal className="companies-modals change-permissions-modal" show={openChangePermissions} onHide={() => {}} centered>

            <Modal.Body>
                <p>Cambiar permisos del rol {currentRole.label}</p>
                {currentRole._permissions && 
                    <Table responsive className="mt-2">
                        <thead>
                            <tr>
                                <th class="th-header">Módulo</th>
                                {allPermissions.map((role)=>{
                                    let show = (role.requiredValidation && validations()) 
                                    || !role.requiredValidation;
                                    return show && <th class="th-header">{role.label} </th> 
                                })}
                            </tr>
                        </thead>
                        <tbody>

                            { permissionsAux.map((role)=>{
                                let canViewRole = (role.hasOwnProperty('canViewRole') && role.canViewRole || !role.hasOwnProperty('canViewRole'))
                                if(canViewRole){
                                    return (
                                        <tr>
                                            <td>{role.roleLabel}</td>
                                            {allPermissions.map((item) => {
                                                let action = role.actions.includes(item.permission) && role.canEditRole 
                                                let show = (item.requiredValidation && validations()) || !item.requiredValidation;
                                                if(show) return <td>
                                                    <Form.Check 
                                                        type="checkbox" disabled={action ? false : true} 
                                                        defaultChecked={ 
                                                            userCan(role.roleName, item.permission)
                                                        }
                                                        onChange={ event => 
                                                            updatePermission(role.roleName, item.permission, event.target.checked)
                                                        }
                                                    />
                                                </td>
                                                })
                                            }
                                        </tr>
                                    )
                                }})
                            }
     
                        </tbody>
                    </Table>
                }
                <div className="btn-container">
                    <Button className="float-right ml-2 mt-3" variant="primary" disabled={!updatedRole} onClick={() => updateRole()}>
                        {isLoading ? <Spinner animation="border" /> : "Confirmar"}
                    </Button> 

                    <Button className="float-right mt-3" variant="danger" onClick={() => {setOpenChangePermissions(!openChangePermissions); setupdatedRole(undefined)}}>
                        Cancelar
                    </Button>
                </div>
                        
            </Modal.Body>
        </Modal>
    );
};
import React, {useEffect, useState, useContext} from 'react';
import {Row, Col, Form, Button, Spinner, OverlayTrigger, Tooltip, Accordion, Card} from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import CreateRole from './CreateRole.jsx';
import ListRoles from './ListRoles.jsx';
import DefaultRoles from '../components/DefaultRoles'
import {UserContext} from '../../../context/UserContext';
import { FiPlus } from 'react-icons/fi';
import { BiArrowBack } from 'react-icons/bi';


export default function Roles({currentCompanyId, setTab, setUpdateList, updateList}){
    const [roles, setRoles] = useState(undefined);
    const [users, setUsers] = useState(undefined);
    const [updateRoles, setUpdateRoles] = useState(false);
    const [showCreateRole, setShowCreateRole] = useState(false);
    const [isCaintraCompany, setIsCaintraCompany] = useState(false);

    const context = useContext(UserContext);


    useEffect(() => {
        getCompanyUsersAndRoles();
        getCurrentCompany(currentCompanyId);
    }, [updateRoles]);

    const getCurrentCompany = async (id) => {
       let currentCompany = await Methods.getCompanies([id]);
       setIsCaintraCompany(currentCompany[0].isCaintraCompany);
    }

    const setDefaultRoles = async (historicRoles) => {

        const adminRoles = {key:"ADMINISTRADOR", value: DefaultRoles.AdminRole()};
        const groupRoles = {key:"GRUPO", value: DefaultRoles.GroupRole()};
        const companyRoles = {key:"EMPRESA", value: DefaultRoles.CompanyRole()};
        const corporativeRoles =  {key:"CORPORATIVO", value: DefaultRoles.CorporativeRole()}

        if(historicRoles == undefined || historicRoles.length < 4){
            let rolesArray = [adminRoles, groupRoles, companyRoles, corporativeRoles]
            for(let index of rolesArray){
                let data = {
                    companyId: currentCompanyId,
                    label: index.key,
                    description: `Roles predeterminados para ${index.key}` ,
                    _permissions: index.value
                }
                await Methods.createRole(data, false);
            }
        } 
    }

    const setUsersRoles = (companyUsers, companyRoles) => {
        for(let user of companyUsers){
            for(let role of companyRoles){
                if(user._role == role.label && currentCompanyId == user.principalCompany){
                    role.users.push(user)
                }
            }
        }
        companyRoles && setRoles(companyRoles);
    }

    const getCompanyUsersAndRoles = async () => {
        const dataRoles = await Methods.getRoles(currentCompanyId);
        for(let role of dataRoles){
            role.users = [];
        }
        dataRoles && setRoles(dataRoles);
        setDefaultRoles(dataRoles);

        const dataUsers = await Methods.getApprovedUsersByCompany(currentCompanyId);
        let usersAux = [];
        if(dataUsers.length){
            for(let userAux of dataUsers){
                if(userAux.principalCompany == currentCompanyId) usersAux.push(userAux)
            }
        }



        usersAux && setUsers(usersAux);
        setUsersRoles(usersAux,dataRoles);
    };

    const handleCreateNewRole = () => {
        if(!context.can("create", "Roles")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        setShowCreateRole(true)
    };

    return(
        <motion.div className="roles" initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>
            <div className="title-container">
                <BiArrowBack onClick={() => setTab("listCompanies")} className="back"/> <h4>Gestión de Roles</h4>
                <div className="users-options">
                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Nuevo Rol</Tooltip>}
                    >
                        <div onClick={() => {handleCreateNewRole()}}><FiPlus/></div>
                    </OverlayTrigger>
                </div>
            </div>

            <Row>
                {(users && roles) &&
                 <ListRoles 
                    users={users}
                    roles={roles}
                    setUpdateRoles={setUpdateRoles}
                    updateRoles={updateRoles}
                    isCaintraCompany={isCaintraCompany}
                />}
            </Row>

            <CreateRole
                currentCompanyId={currentCompanyId}
                updateRoles={updateRoles}
                setUpdateRoles={setUpdateRoles}
                showCreateRole={showCreateRole}
                setShowCreateRole={setShowCreateRole}
            />
        </motion.div>
    );
};
import React, {useContext, useState} from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { UserContext } from '../../../context/UserContext';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function UpdateDepartmentModal ({displayUpdateUniversity, setDisplayUpdateUniversity, currentUniversity, updateList, setUpdateList}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorPhoneSec, setErrorPhoneSec] = useState(false);
    const [labelPhoneSec, setLabelPhoneSec] = useState("");
    const [errorEmailSec, setErrorEmailSec] = useState(false);
    const [labelEmailSec, setLabelEmailSec] = useState("");
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
    const updateUniversity = async (e) => {
        e.preventDefault();
        const collegeName = e.target.elements.collegeName.value.toUpperCase();
        const contactName = e.target.elements.contactName.value
        const fatherLastName = e.target.elements.fatherLastName.value
        const motherLastName = e.target.elements.motherLastName.value
        const email = e.target.elements.email.value
        const phone = e.target.elements.phone.value
        const sign = e.target.elements.sign.checked

        const university = {
            attributes: [],
            id_university: currentUniversity._id
        };

        if(collegeName.trim() == ""){
            notify("El nombre de la institución no puede estar vacío", "error");
            setIsLoading(false);    
            return;
        };

        collegeName != "" && university.attributes.push({updateKey: "_name", updateValue: collegeName, expressionAttributeName: "#_name"});
        university.attributes.push({updateKey: "contactName", updateValue: contactName});
        university.attributes.push({updateKey: "fatherLastName", updateValue: fatherLastName});
        university.attributes.push({updateKey: "motherLastName", updateValue: motherLastName});
        university.attributes.push({updateKey: "email", updateValue: email});
        university.attributes.push({updateKey: "phone", updateValue: phone});
        university.attributes.push({updateKey: "sign", updateValue: sign});
        
        try {
            await axios({
                url: `${URL}/universities`,
                method: "PATCH",
                data: university
            });

            notify("Universidad actualizada exitosamente", "success");
            setUpdateList(!updateList);
            setDisplayUpdateUniversity(!displayUpdateUniversity);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        };
    };

    const verifyEmail = async (setError, labelError, formDataName) => {
        if(formDataName.length > 0){
            if(!regexEmail.test(formDataName)){
                await setError(true);
                await labelError("El correo no es válido");
            } else {
                await setError(false);
                await labelError("");
            }
        } else {
            await  setError(false);
        }
    }

    const verifyPhone = async (setError, labelError, formDataName) => {
        if(formDataName.length > 0){
            if(formDataName.length == 10){
                await setError(false)
                await labelError("")
            }
            if(formDataName.length < 10 && formDataName.length >= 1){
                await setError(true);
                await labelError("El número de teléfono debe tener 10 dígitos");
            }
        } else {
            await setError(false);
            await labelError("El número de teléfono debe tener 10 dígitos");
        }
    }

    const verifyDataForm = async (setError, labelError, typeError, formDataName, errorN) => {
        if(typeError == "Email"){
            if(formDataName.length > 0){
                if(!regexEmail.test(formDataName)){
                    await setError(true);
                    await labelError("El correo no es válido");
                } else {
                    await setError(false);
                    await labelError("");
                }
            } else {
                await  setError(false);
            }
        }
        if(typeError == "Phone"){
            if(formDataName.length > 0){
                if(formDataName.length == 10){
                    await setError(false)
                    await labelError("")
                }
                if(formDataName.length < 10 && formDataName.length >= 1){
                    await setError(true);
                    await labelError("El número de teléfono debe tener 10 dígitos");
                }
            } else {
                await setError(false);
                await labelError("El número de teléfono debe tener 10 dígitos");
            }
        }    
    };

    return(
        <Modal className="new-cc-modal" show={displayUpdateUniversity} onHide={() => setDisplayUpdateUniversity(!displayUpdateUniversity)} centered>
            <Modal.Header>
                <p>Actualizar Universidad</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={updateUniversity}>
                    <Form.Group controlId="collegeName">
                        <Form.Label>Nombre de la Institución</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nombre de la institución"
                            defaultValue={currentUniversity._name}
                            required
                            onChange={e => e.target.value = e.target.value}
                        />
                    </Form.Group>
                    <Form.Group controlId="contactName">
                        <Form.Label>Nombre(s)</Form.Label>
                        <Form.Control type="text" placeholder="Nombre(s) del contacto" defaultValue={currentUniversity.contactName}/>
                    </Form.Group>
                    <Form.Group controlId="fatherLastName">
                        <Form.Label>Apellido Paterno</Form.Label>
                        <Form.Control type="text" placeholder="Apellido paterno del contacto" defaultValue={currentUniversity.fatherLastName}/>
                    </Form.Group>
                    <Form.Group controlId="motherLastName">
                        <Form.Label>Apellido Materno</Form.Label>
                        <Form.Control type="text" placeholder="Apellido materno del contacto" defaultValue={currentUniversity.motherLastName}/>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text"
                            defaultValue={currentUniversity.email}
                            placeholder="Introduce el correo del contacto" onChange={(e) => {verifyEmail(setErrorEmailSec, setLabelEmailSec, e.target.value)}}
                        />
                        { 
                            errorEmailSec && <Form.Label className="error-label" > {labelEmailSec}</Form.Label>
                        }
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Teléfono (10 dígitos)</Form.Label>
                        <Form.Control
                            type="number"
                            defaultValue={currentUniversity.phone}
                            onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                            }}
                            placeholder="Introduce el teléfono del contacto"
                            onChange={(e) => { verifyPhone(setErrorPhoneSec, setLabelPhoneSec, e.target.value)}}
                        />
                        {
                            errorPhoneSec && <Form.Label className="error-label" > {labelPhoneSec} </Form.Label>
                        }
                    </Form.Group>
                    <Form.Group controlId="sign">
                        <Form.Check className="checkbox" type="checkbox" label={`El usuario firmará por cada ${context.getFacultadField()}`} defaultChecked={currentUniversity.sign}/>
                    </Form.Group>
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Actualizar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayUpdateUniversity(!displayUpdateUniversity)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
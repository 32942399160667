
export default {

    test(workbook, companyInfo, mode) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        const itesmValues = [
        {  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
        {  width: 20,  value: "firstname",         text: "Nombre(s)",               getData: () => { return "" }  },
        {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: () => { return "" }  },
        {  width: 20,  value: "motherLastName",    text: "Apellido Materno",        getData: () => { return "" }  },
        {  width: 20,  value: "startDate",         text: "Inicio de Convenio",      getData: () => { return "DD/MM/YYYY" }  },
        {  width: 20,  value: "endDate",           text: "Finalización del convenio",getData: () => { return "DD/MM/YYYY" }  },
        {  width: 20,  value: "contractApprovalDate", text: "Fecha de Aprobación",     getData: () => { return "DD/MM/YYYY" }  },
        {  width: 20,  value: "months",            text: "Meses del convenio",      getData: () => { return "list" }  },
        {  width: 20,  value: "email",             text: "Correo",                   getData: () => { return "" }  },
        {  width: 20,  value: "scolarshipAmount",  text: "Monto de Beca",           getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "VP-VR",                   getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Centro de Costos",        getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Generalista",             getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Correo de generalista	",  getData: () => { return "" }  },
        {  width: 20,  value: "project",           text: "Proyecto",                getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Hora de entrada",         getData: () => { return "HH:mm a.m." }  },
        {  width: 20,  value: "bank",              text: "Hora de salida",          getData: () => { return "HH:mm p.m." }  },
        {  width: 20,  value: "bank",              text: "Calle",                   getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "No. Exterior",            getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "No. Interior",            getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Código Postal",           getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Fecha de nacimiento",     getData: () => { return "DD/MM/YYYY" }  },
        {  width: 20,  value: "bank",              text: "Teléfono",                getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Universidad",             getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Campus",                  getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Carrera",                 getData: () => { return "" }  },
        {  width: 20,  value: "period_type",       text: "Ciclo Escolar",           getData: () => { return "list" }  },
        {  width: 20,  value: "bank",              text: "Matrícula",               getData: () => { return "" }  },
        {  width: 20,  value: "grade",             text: "Grado",                   getData: () => { return "list" }  },
        {  width: 20,  value: "scholarship",       text: "Escolaridad",             getData: () => { return "list" }  },
        {  width: 20,  value: "clabe",             text: "Cuenta",                  getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Grupo/Salón",             getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "LinkedIn",                getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Instagram",               getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Nomina",               getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Lider",               getData: () => { return "" }  },
        {  width: 20,  value: "bank",              text: "Posicion",               getData: () => { return "" }  },
        {  width: 20,  value: "program",              text: "Programa",               getData: () => { return "list" }  },];

        const itesmRenewValues = [  
            {  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
            {  width: 20,  value: "firstname",         text: "Nombre(s)",               getData: () => { return "" }  },
            {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: () => { return "" }  },
            {  width: 20,  value: "motherLastName",    text: "Apellido Materno",        getData: () => { return "" }  },
            {  width: 20,  value: "grade",             text: "Matrícula",                getData: () => { return "" }  },
            {  width: 20,  value: "startDate",         text: "Inicio de Convenio",      getData: () => { return "DD/MM/YYYY" }  },
            {  width: 20,  value: "endDate",           text: "Finalización del convenio", getData: () => { return "DD/MM/YYYY" }  },
            {  width: 20,  value: "contractApprovalDate",   text: "Fecha de Aprobación", getData: () => { return "DD/MM/YYYY" }  },
            {  width: 20,  value: "months",            text: "Meses del convenio",      getData: () => { return "list" }  },
            {  width: 20,  value: "email",             text: "Correo",                   getData: () => { return "" }  },
            {  width: 20,  value: "scolarshipAmount",  text: "Monto de Beca",           getData: () => { return "" }  },
            {  width: 20,  value: "grade",             text: "Grado",                   getData: () => { return "list" }  },
            {  width: 20,  value: "department_name",   text: "VP-VR",                   getData: () => { return "list" }  },
            {  width: 20,  value: "bank",              text: "Centro de Costos",        getData: () => { return "" }  },
            {  width: 20,  value: "bank",              text: "Generalista",             getData: () => { return "" }  },
            {  width: 20,  value: "program",           text: "Proyecto",               getData: () => { return "" }  },
            {  width: 20,  value: "bank",              text: "Lider",               getData: () => { return "" }  },
            {  width: 20,  value: "clabe",             text: "Nómina",                   getData: () => { return "list" }  },
            {  width: 20,  value: "bank",              text: "Posicion",               getData: () => { return "" }  },
            {  width: 20,  value: "program",           text: "Programa",               getData: () => { return "list" }  },
        ]

        const itesmUnsubscribeValues = [
        {  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
        {  width: 20,  value: "grade",             text: "Matrícula",                getData: () => { return "" }  },
        {  width: 20,  value: "firstname",         text: "Nombre(s)",               getData: () => { return "" }  },
        {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: () => { return "" }  },
        {  width: 20,  value: "motherLastName",    text: "Apellido Materno",        getData: () => { return "" }  },
        {  width: 20,  value: "unsuscribeDate",         text: "Fecha de Baja",      getData: () => { return "DD/MM/YYYY" }  },
        {  width: 20,  value: "unsuscribeMotive",            text: "Motivo",      getData: () => { return "" }  },
        ]

        const dropDown = {
            'period_type': ['"Semestral,Tetramestral, Trimestral ,Cuatrimestral,Bimestral, Mensual, Pentamestral, Anual"'],
            'grade': ['"1°, 2°, 3°, 4°, 5°, 6°, 7°, 8°, 9°, 10°, 11°, 12°, Pendiente"'],
            'scholarship': ['"Profesional,Preparatoria,Carrera Técnica,Maestría, Doctorado"'],
            'planType': ['"Plan 1,Plan 2"'],
            'insurancePayment': ['"Empresa,Practicante"'],
            'paymentType': ['"Total,Mensual"'],
            'relationship': ['"Padre,Madre,Tutor,Hermano, Amistad, Otro"'],
            'months': companyInfo.isITESMCompany  ? ['"1,2,3,4,5,6,7,8,9,10,11,12"'] : ['"1,2,3,4,5,6"'],
            'program': ['"Embajador Tec,Embajador Tecmilenio,Experiencias Tec,Experiencias Tecmilenio,Novus,Practicante Externo,Storyteller,Teaching Assistant"']
        }

        const data = {
            'create': {
                'cols': ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH","AI", "AJ", "AK","AL"],
                'config' : companyInfo.isITESMCompany ? itesmValues : 
                            [{  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
                            {  width: 20,  value: "firstname",         text: "Nombre(s)",               getData: () => { return "" }  },
                            {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: () => { return "" }  },
                            {  width: 20,  value: "motherLastName",    text: "Apellido Materno",        getData: () => { return "" }  },
                            {  width: 20,  value: "startDate",         text: "Inicio de Convenio",      getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "endDate",           text: "Finalización del convenio",getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "contractApprovalDate", text: "Fecha de Aprobación",     getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "months",            text: "Meses del convenio",      getData: () => { return "list" }  },
                            {  width: 20,  value: "email",             text: "Correo",                  getData: () => { return "" }  },
                            {  width: 20,  value: "scolarshipAmount",  text: "Monto de Beca",           getData: () => { return "" }  },
                            {  width: 20,  value: "project",           text: "Proyecto",                getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Hora de entrada",         getData: () => { return "HH:mm a.m." }  },
                            {  width: 20,  value: "bank",              text: "Hora de salida",          getData: () => { return "HH:mm p.m." }  },
                            {  width: 20,  value: "bank",              text: "Calle",                   getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "No. Exterior",            getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "No. Interior",            getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Código Postal",           getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Fecha de nacimiento",     getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "bank",              text: "Teléfono",                getData: () => { return "" }  },
                            {  width: 20,  value: "period_type",       text: "Ciclo Escolar",           getData: () => { return "list" }  },
                            {  width: 20,  value: "bank",              text: "Matrícula",               getData: () => { return "" }  },
                            {  width: 20,  value: "grade",             text: "Grado",                   getData: () => { return "list" }  },
                            {  width: 20,  value: "scholarship",       text: "Escolaridad",             getData: () => { return "list" }  },
                            {  width: 20,  value: "clabe",             text: "Cuenta",                  getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Grupo/Salón",             getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "LinkedIn",                getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Instagram",               getData: () => { return "" }  }
                        ],
                'worksheetName': "Altas Masivas",
                'headerText': "Plantilla Altas Masivas"
            },
            'modify': {
                'cols': ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH","AI", "AJ", "AK","AL"],
                'config' : companyInfo.isITESMCompany ? itesmValues : 
                            [{  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
                            {  width: 20,  value: "firstname",         text: "Nombre(s)",               getData: () => { return "" }  },
                            {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: () => { return "" }  },
                            {  width: 20,  value: "motherLastName",    text: "Apellido Materno",        getData: () => { return "" }  },
                            {  width: 20,  value: "startDate",         text: "Inicio de Convenio",      getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "endDate",           text: "Finalización del convenio",getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "contractApprovalDate", text: "Fecha de Aprobación",     getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "months",            text: "Meses del convenio",      getData: () => { return "list" }  },
                            {  width: 20,  value: "email",             text: "Correo",                  getData: () => { return "" }  },
                            {  width: 20,  value: "scolarshipAmount",  text: "Monto de Beca",           getData: () => { return "" }  },
                            {  width: 20,  value: "project",           text: "Proyecto",                getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Hora de entrada",         getData: () => { return "HH:mm a.m." }  },
                            {  width: 20,  value: "bank",              text: "Hora de salida",          getData: () => { return "HH:mm p.m." }  },
                            {  width: 20,  value: "bank",              text: "Calle",                   getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "No. Exterior",            getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "No. Interior",            getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Código Postal",           getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Fecha de nacimiento",     getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "bank",              text: "Teléfono",                getData: () => { return "" }  },
                            {  width: 20,  value: "period_type",       text: "Ciclo Escolar",           getData: () => { return "list" }  },
                            {  width: 20,  value: "bank",              text: "Matrícula",               getData: () => { return "" }  },
                            {  width: 20,  value: "grade",             text: "Grado",                   getData: () => { return "list" }  },
                            {  width: 20,  value: "scholarship",       text: "Escolaridad",             getData: () => { return "list" }  },
                            {  width: 20,  value: "clabe",             text: "Cuenta",                  getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Grupo/Salón",             getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "LinkedIn",                getData: () => { return "" }  },
                            {  width: 20,  value: "bank",              text: "Instagram",               getData: () => { return "" }  }
                        ],
                'worksheetName': "Modificaciones Masivas",
                'headerText': "Plantilla Modificaciones Masivas"
            },
            'renew': {
                'cols': ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S", "T", "U"],
                'config' : companyInfo.isITESMCompany ? itesmRenewValues : 
                            [{  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
                            {  width: 20,  value: "startDate",         text: "Inicio de Convenio",      getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "endDate",           text: "Finalización del convenio",getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "contractApprovalDate", text: "Fecha de Aprobación",  getData: () => { return "DD/MM/YYYY" }  },
                            {  width: 20,  value: "months",            text: "Meses del convenio",      getData: () => { return "list" }  },
                            {  width: 20,  value: "scolarshipAmount",  text: "Monto de Beca",           getData: () => { return "" }  },
                            {  width: 20,  value: "grade",             text: "Grado",                   getData: () => { return "list" }  },
                            ],
                'worksheetName': "Renovaciones Masivas",
                'headerText': "Plantilla Renovaciones Masivas"
            },
            'unsubscribe': {
                'cols': ["A","B","C","D","E","F","G"],
                'config' : companyInfo.isITESMCompany ? itesmUnsubscribeValues : 
                    [{  width: 20,  value: "curp",              text: "CURP",                    getData: () => { return "" }  },
                    {  width: 20,  value: "unsuscribeDate",         text: "Fecha de Baja",      getData: () => { return "DD/MM/YYYY" }  },
                    {  width: 20,  value: "unsuscribeMotive",            text: "Motivo",      getData: () => { return "" }  },
                    ],
                'worksheetName': "Bajas Masivas",
                'headerText': "Plantilla Bajas Masivas",
            }
        }

        if (companyInfo.interns_insurance == "Sí" && (mode == 'create' || mode == 'renew')) {
            const insuranceData= 
                [{  width: 20,  value: "planType",          text: "Plan",                    getData: () => { return "list" }  },
                {  width: 20,  value: "insurancePayment",  text: "Cobrar Póliza a",         getData: () => { return "list" }  },
                {  width: 20,  value: "paymentType",       text: "Tipo de Cobro",           getData: () => { return "list" }  },
                {  width: 20,  value: "bank",              text: "Beneficiario 1",          getData: () => { return "" }  },
                {  width: 20,  value: "bank",              text: "Porcentaje 1",            getData: () => { return "" }  },
                {  width: 20,  value: "relationship",      text: "Parentesco 1",            getData: () => { return "list" }  },
                {  width: 20,  value: "bank",              text: "Beneficiario 2",          getData: () => { return "" }  },
                {  width: 20,  value: "bank",              text: "Porcentaje 2",            getData: () => { return "" }  },
                {  width: 20,  value: "relationship",      text: "Parentesco 2",            getData: () => { return "list" }  }];
            switch (mode) { 
                case 'create':
                    data.create.config =  [...data.create.config, ...insuranceData];
                    break;
                case 'renew':
                    data.renew.config =  [...data.renew.config, ...insuranceData];
                    break;
            }
        }
        const cols = data[mode].cols;
        let config = data[mode].config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data[mode].worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){
            if (text == "list"){
                worksheetName.getCell(startCell).dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: dropDown[key],
                    fgColor:{argb:'FFFFFFFF'}
                };
            } else {
                worksheetName.getCell(startCell).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFFFFFFF'}
                }
                worksheetName.getCell(startCell).value = text;
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A1",
                "Nombre de Empresa",
                worksheetName
            )
            createWhiteCell(
                "B1",
                companyInfo.companyName  || "",
                "",
                worksheetName
            )

            createBlueCell(
                "C1",
                "RFC",
                worksheetName
            )

            createWhiteCell(
                "D1",
                companyInfo.companyRFC  || "",
                "",
                worksheetName
            )

            createBlueCell(
                "E1",
                "ID",
                worksheetName
            )
            createWhiteCell(
                "F1",
                companyInfo.companyId  || "",
                "",
                worksheetName
            )
        }

        function createTableAvail(worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                data[mode].headerText,
                worksheetName
            );
            cellNumber++
            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            
            let rows = 50;

            while(rows-- > 0){
                cellNumber++
                config.forEach((configItem) => {
                    createWhiteCell(
                        configItem.col + cellNumber,
                        configItem.getData(),
                        configItem.value,
                        worksheetName
                    )
                });
            }
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}

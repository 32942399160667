import React, {useState} from 'react';
import {Modal, Image, Form, Button, Row, Col, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';

import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';
const axios = require("axios");



export default function UpdateCostCenterModal ({displayUpdateCostCenterModal, setDisplayUpdateCostCenterModal, currentCostCenterId, updateList, setUpdateList, currentCostCenter}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);

    const context = useContext(UserContext);
    const updateCostCenter = async (e) => {
        e.preventDefault();
        setIsLoading(true);    
        const title = e.target.elements.title.value;
        const key = e.target.elements.key.value;

        const cost_center = {
            attributes: [],
            id_costCenter: currentCostCenterId,
            departmentField: context.getDepartmentField(),
        };

        if(title.trim() == ""){
            notify("El título no puede estar vacío", "error");
            setIsLoading(false);    
            return;
        };

        if(key.trim() == ""){
            notify("La clave no puede estar vacía", "error");
            setIsLoading(false);    
            return;
        };
        
        title != currentCostCenter.title && cost_center.attributes.push({updateKey: "title", updateValue: title});
        key != currentCostCenter.key && cost_center.attributes.push({updateKey: "_key", updateValue: key, expressionAttributeName:"#_key"});
        
        try {
            await axios({
                url: `${URL}/costcenter`,
                method: "PATCH",
                data: cost_center
            });

            setIsLoading(false);    
            notify("Centro de Costos actualizado exitosamente", "success");
            setUpdateList(!updateList);
            setDisplayUpdateCostCenterModal(!displayUpdateCostCenterModal);
        } catch (error) {
            setIsLoading(false);    
            error.response && notify(error.response.data.message, "error");
        }
    };

    const validateEntryKey = (e) => {
        e.charCode == 13 && e.preventDefault()
    }

    return(
        <Modal className="new-cc-modal" show={displayUpdateCostCenterModal} onHide={() => setDisplayUpdateCostCenterModal(!displayUpdateCostCenterModal)} centered>
            <Modal.Header>
                <p>{currentCostCenter.title}</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={updateCostCenter} autoComplete={"off"}>
                <Row>
                        <Col>
                            <Form.Group controlId="title">
                                <Form.Label>Nombre del {context.getDepartmentField()} / Área</Form.Label>
                                <Form.Control onKeyPress={validateEntryKey} type="text" required defaultValue={currentCostCenter.title}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>

                        <Col>
                            <Form.Group controlId="key">
                                <Form.Label>Clave del Centro de Costo</Form.Label>
                                <Form.Control onKeyPress={validateEntryKey} type="text" required defaultValue={currentCostCenter._key}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Actualizar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayUpdateCostCenterModal(!displayUpdateCostCenterModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React, { useEffect, useState, createRef } from "react";
import { Table, Dropdown, Button } from "react-bootstrap";
import Methods from "../../../utils/https";
import "../Configurations.css";
import { BsUpload, BsDownload, BsTrash } from 'react-icons/bs';
import ExcelCSV from "../Excel/DownloadXLSX";
import * as Excel from "exceljs";
import {saveAs} from 'file-saver';
import UploadExcel from "./UploadExcel"
import DeleteYear from "./DeleteYear"
import { notify } from '../../../utils/notify';
import moment from 'moment-timezone';


export default function Calendar () {
    const [periodAux, setPeriodAux] = useState({selectedPeriod:"Quincenal"});
    const [showUpdateExcel, setShowUpdateExcel] = useState(false);
    const tableRef = createRef();
    const [period, setPeriod] = useState([
        {selectedPeriod:"Quincenal"},
        {selectedPeriod:"Quincenal 2"},
        {selectedPeriod:"Catorcenal"},
        {selectedPeriod:"Mensual 30"},
        {selectedPeriod:"Mensual 15 BAT"},
        {selectedPeriod:"Mensual 15"}
    ]);
    const [dates, setDates] = useState([]);
    const [showDeleteYear, setShowDeleteYear] = useState(false);
    const [selectedYear, setSelectedYear] = useState("");
    const [years, setYears] = useState([]);

    useEffect(()=>{
        prueba();

    },[periodAux,showDeleteYear,showUpdateExcel]);

    const sortByYear = (sortedDate) =>{
        let dateAux = [];
        for(let d of sortedDate){
            if(!dateAux.filter( y => y.year === d.start_date.slice(-4)).length){
               dateAux.push({year: d.start_date.slice(-4), batch: d.batch_id})
            }
        }
        if(!selectedYear || dateAux.filter(date => date.year == selectedYear).length == 0) setSelectedYear(dateAux[0]?.year);
        dateAux.unshift({year: parseInt(dateAux[0]?.year) +1, batch: undefined});
        setYears(dateAux)
    }

    const getDates = async () => {
        try {
            let dateAux = await Methods.getAllPeriods(periodAux.selectedPeriod);
            dateAux.sort(function(a,b){
                let momentA = a.start_date.includes("/") ? moment(a.start_date,"DD/MM/YYYY").startOf("day"): moment(a.start_date.slice(0,10)).startOf("day");
                let momentB = b.start_date.includes("/") ? moment(b.start_date,"DD/MM/YYYY").startOf("day"): moment(b.start_date.slice(0,10)).startOf("day");
    
                return momentA.isAfter(momentB) ? -1 : 1;
            });
            sortByYear(dateAux);
            setDates(dateAux);
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
            setDates([]);
        };
    };

    const prueba = async () => {
        await getDates();
    }
    
    return (
        <div>
            <div className="alter-table">
                <div className="options">
                    <Dropdown  drop={"down"}>
                        <Dropdown.Toggle>{periodAux.selectedPeriod}</Dropdown.Toggle>
                        <Dropdown.Menu className="filters-menu">
                            {period.map(period => 
                            <Dropdown.Item key={period.selectedPeriod} onSelect={async () => { 
                                setPeriodAux(period);
                            }}
                            >{period.selectedPeriod}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown  drop={"down"}>
                        <Dropdown.Toggle>{selectedYear}</Dropdown.Toggle>
                        <Dropdown.Menu className="filters-menu">
                            {years.map(years => 
                            <Dropdown.Item key={years.year} onSelect={async () => { 
                                setSelectedYear(years.year);
                            }}
                            >{years.year}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    <Button className="Button" onClick={() => {
                        const workbook = new Excel.Workbook();
                        ExcelCSV.test(
                            workbook,
                            periodAux.selectedPeriod);
                        workbook.xlsx.writeBuffer().then((data) => {
                            const blob = new Blob([data], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                            });
                            saveAs(blob, `CalendariosPlantilla.xlsx`);
                        });
                    }}>
                        <BsDownload />
                        Plantilla
                    </Button>
                    <Button className="Button" onClick={() => {setShowUpdateExcel(!showUpdateExcel)}}>
                        <BsUpload />
                        Carga
                    </Button>
                    <Button className="Button" onClick={() => {setShowDeleteYear(!showDeleteYear)}}>
                        <BsTrash />
                        Borrar año
                    </Button>
                </div>
            </div>
            <div className="table-container" >
                <Table hover ref={tableRef}>
                    <thead>
                        <tr>
                            <th>Fecha de Inicio</th>
                            <th>Fecha de Termino</th>
                            <th>Fecha de Pago</th>
                            <th>Limite de autorización</th>
                            <th>Consolidado</th>
                            <th>Primer Consolidado</th>
                            <th>Segundo Consolidado</th>
                            <th>Forzar aprobado</th>
                        </tr>
                    </thead>

                    <tbody>
                        {dates ? dates.filter(dateAux2 => dateAux2.start_date.slice(-4) === selectedYear).map((info, index) => {
                            return(
                            <tr key={index}>
                                <td>
                                    {info.start_date}
                                </td>
                                <td>
                                    {info.end_date}
                                </td>
                                <td>
                                    {info.payment_date}
                                </td>
                                <td>
                                    {info.limit_authorization}
                                </td>
                                <td>
                                    <input
                                        className="input"
                                        type="checkbox"  
                                        checked={info.isConsolidationPeriod}                                                                 
                                        disabled="true"
                                    />
                                </td>
                                <td>
                                    <input
                                        className="input"
                                        type="checkbox"  
                                        checked={info.isFirstConsolidationPeriod}                                                                 
                                        disabled="true"
                                    />
                                </td>
                                <td>
                                    <input
                                       className="input"
                                        type="checkbox"  
                                        checked={info.isSecondConsolidationPeriod}                                                                 
                                        disabled="true"
                                    />
                                </td>
                                <td>
                                    <input
                                        className="input"
                                        type="checkbox"  
                                        checked={info.forceApprovePeriod}                                                                 
                                        disabled="true"
                                    />
                                </td>
                            </tr>

                        )}): <tr></tr>}
                    </tbody>
                </Table>
            </div>
            
            <UploadExcel
            showUpdateExcel= {showUpdateExcel}
            setShowUpdateExcel= {setShowUpdateExcel}
            dates={dates}
            periodAux={periodAux}
            />

            <DeleteYear
            showDeleteYear= {showDeleteYear}
            setShowDeleteYear= {setShowDeleteYear}
            dates= {dates}
            selectedYear= {selectedYear}
            years= {years}
            periodAux= {periodAux}
            />
        </div>
    );
};
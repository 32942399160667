import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export default function DeleteItemModal(props) {
    const [value, setValue] = React.useState("");

    function closeHandler() {
        props.hideModal();
        setValue("");
    }

    return <Modal className="new-cc-modal" show={props.displayModal} onHide={closeHandler} centered>
        <Modal.Header>
            <p>¿Estas seguro que quieres eliminar este generalista?</p>
        </Modal.Header>

        <Modal.Body>
            <p>{props.selectedItem.name}</p>

            <Form onSubmit={event => {
                event.preventDefault();
                props.deleteItem(props.selectedItem._id);
            }}>
                <Form.Group controlId="confirmation">
                    <Form.Label>Escribe "eliminar permanentemente" para continuar</Form.Label>
                    <Form.Control type="text" required value={value} onChange={event => setValue(event.target.value)} />
                </Form.Group>

                <Button className="float-right ml-2" variant="primary" type="submit" disabled={value !== "eliminar permanente"}>
                    Confirmar
                </Button>
                <Button className="float-right" variant="danger" type="button" onClick={closeHandler}>
                    Cancelar
                </Button>
            </Form>
        </Modal.Body>
    </Modal>
};
import { Modal, Button, Spinner} from 'react-bootstrap';
import { useState } from 'react';

export const ConfirmDispersionTotalExpected = ({
    dispersionsExpected,
    showDispersionTotalExpected,
    setShowDipsersionTotalExpected,
    sendReport
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => {
        setShowDipsersionTotalExpected(!showDispersionTotalExpected)
    }

    const sendReports = async() => {
        setIsLoading(true);
        try{
            await sendReport();
        }catch(error){
            console.log(error)
        }

        setIsLoading(false);
        handleClose();
    }

    return (
        <Modal className="company-modals" show={showDispersionTotalExpected} onHide={() => {
            handleClose()
        }} centered>
            <Modal.Body>
                <p>El número de dispersiones esperadas por periodo son <b>{JSON.stringify(dispersionsExpected)}</b></p>
                <p>¿Deseas continuar con el envío?</p>
                <br />

                <Button className="float-right ml-2" variant="primary" onClick={
                    async() => {
                        await sendReports();
                    }
                }>
                {!isLoading 
                    ? "Confirmar" 
                    : <Spinner animation="border" role="status">
                    </Spinner>
                }
                </Button> 

                <Button className="float-right" variant="danger" onClick={() => {
                    handleClose()
                }}>
                    Cancelar
                </Button>             
            </Modal.Body>
        </Modal>
    )
}

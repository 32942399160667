import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {Row, Col, Image, Form, Button, Spinner, Tooltip, OverlayTrigger, InputGroup} from 'react-bootstrap';
import InternsLogo from '../../assets/logo.svg';
import {DateTimePicker} from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {notify} from '../../utils/notify';
import './DocumentationRequest.css';
import SuccessModal from './components/SuccessModal';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import Methods from '../../utils/https';
import clip from '../../assets/IconsCaintra/clip.svg';
import { decrypt } from "../../utils/crypto";
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

const axios = require("axios");
const $ENV = window.location.origin;

export default function DocumentationRequest(){
    const key = useParams().id;
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [intern, setIntern] = useState(undefined);
    const [birthCertificateIcon, setBirthCertificateIcon] = useState(faPlusCircle);
    const [ineIcon, setIneIcon] = useState(faPlusCircle);
    const [bankAccountStatusIcon, setBankAccountStatusIcon] = useState(faPlusCircle);
    const [proofOfResidenceIcon, setProofOfResidenceIcon] = useState(faPlusCircle);
    const [kardexIcon, setKardexIcon] = useState(faPlusCircle);
    const [proofOfResidenceFileName, setProofOfResidenceFileName] = useState("");
    const [birthCertificateFileName, setBirthCertificateFileName] = useState("");
    const [universities, setUniversities] = useState([]);
    const [kardexFileName, setKardexFileName] = useState("");
    const [curpFileName, setCurpFileName] = useState("");
    const [curpIcon, setCurpIcon] = useState(faPlusCircle);
    const [ineFileName, setIneFileName] = useState("");
    const [bankAccountStatusFileName, setBankAccountStatusFileName] = useState("");
    const [files, setFiles] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [userHasBankAccount, setUserHasBankAccount] = useState(false);
    const [isUploadingFiles, setIsUploadingFiles] = useState(false);
    const [age, setAge] = useState("");
    const [birthDate, setBirthDate] = useState(new Date());
    const [universitySelected, setUniversity] = useState("");
    const [currentState, setCurrentState] = useState("");
    const [currentSuburbs, setCurrentSuburbs] = useState([]);
    const [currentMunicipality, setCurrentMunicipality] = useState("");
    const [facultySelected, setFacultySelected] = useState("");
    const [currentPercent, setCurrentPercent] = useState(undefined);
    const [currentSecondPercent, setCurrentSecondPercent] = useState(undefined);
    const [isMainBank, setIsMainBank] = useState(true);
    const [company, setCompany] = useState(undefined);
    const [userHasAcceptedPrivacy, setUserHasAcceptedPrivacy] = useState(undefined);
    const [requireSife, setRequireSife] = useState(false);


    const history = useHistory();
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
    const [formValidate, setFormValidate] = useState({
    phone: "",
    email: "",
    rfc: "",
    email_leader: "",
    curp: "",
    percent: 0,
    percentSecond: 0,
    label_error_insurance: "La suma de los porcentajes debe ser igual a 100%",
    label_error_email: "El correo no es válido",
    label_error_phone: "El número de teléfono debe tener 10 dígitos",
    label_error_rfc: "El RFC debe tener 10 o 13 dígitos",
    label_error_curp: "El formato del CURP no es válido",
    label_error_curp_min: "El CURP debe tener mínimo 18 caracteres",
    is_insurance_error: false,
    is_phone_error: false,
    is_rfc_error: false,
    is_email_error: false,
    is_email_leader_error: false
    });
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isRfcError, setIsRfcError] = useState(false);
    const [isCurpError, setIsCurpError] = useState(false);
    const [isInsuranceError, setIsInsuranceError] = useState(false);
    const [curpErrorLabel, setCurpErrorLabel] = useState(false);
    const [rfcErrorLabel, setRfcErrorLabel] = useState(false)
    const [currentContract, setCurrentContract] = useState(undefined);
    const [rfc, setRfc] = useState("");
    const [seePass, setSeePass] = useState(false);
    const gradeArray = ["1°", "2°", "3°","4°", "5°", "6°", "7°", "8°", "9°", "10°", "11°", "12°","Pendiente"];

    const getSeePass = () =>
    !seePass ?
        <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} className="input-icon see-password"/>
    :
        <IoEyeOutline onClick={() => { setSeePass(!seePass) }} className="input-icon see-password"/>;

    const curpValidation = async (curp) =>{
        const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
        const isValid = curp.match(regex);
        if(curp.length > 0){
            if(curp.length != 18 && curp.length > 0 ){
                setIsCurpError(true);
                setCurpErrorLabel("El CURP debe tener mínimo 18 caracteres");
            }else {
                if(isValid){
                    setIsCurpError(false);
                    setCurpErrorLabel("");
                    setRfc(curp.substring(0,10));
                    let year = curp.substring(4,6);
                    const month = curp.substring(6,8);
                    const day = curp.substring(8,10);
                    if(year > 40){
                        year=19+year;
                    }
                    else{
                        year=20+year;
                    }
                    const calculatedDate = moment([year, parseInt(month)-1, day]);
                    setBirthDate(calculatedDate._d);
                    setAge(moment().diff(calculatedDate, "years"));
                } else{
                    setIsCurpError(true);
                    setCurpErrorLabel("El formato del CURP no es válido");
                }
            }
        }else{
            setIsCurpError(false);
            setCurpErrorLabel("");
        }
    };

    const rfcValidation = async (rfc) =>{
        setRfc(rfc);
        if(rfc.length > 0){
            if((rfc.length != 10 && rfc.length != 13) && rfc.length > 0){
                setIsRfcError(true);
                setRfcErrorLabel("El RFC debe ser de 10 o 13 caracteres");
            }else{
                if(/^[a-zA-Z]+$/.test(rfc.substring(0,4)) && /^\d+$/.test(rfc.substring(5,10))){
                    setIsRfcError(false);
                    setRfcErrorLabel("");
                }
                else{
                    setIsRfcError(true);
                    setRfcErrorLabel("El formato del RFC no es válido");
                }
            }
        }else{
            setIsRfcError(false);
            setRfcErrorLabel("")
        }
    }
    const verifyDataForm = async (key, typeError, formDataName, updateValue) =>{
        if(typeError == "Email"){
            if(formDataName.length > 0){
                if(!regexEmail.test(formDataName)){
                    updateKey(key, true);
                    updateValue(true);
                } else {
                    updateKey(key, false);
                    updateValue(false);
                }
            } else {
                updateKey(key, false)
                updateValue(false);
            }
        }
        if(typeError == "Phone"){
            if(formDataName.length > 0){
                if(formDataName.length == 10){
                    updateKey(key, false);
                    updateValue(false);
                }
                if(formDataName.length < 10 && formDataName.length >= 1){
                    updateKey(key,true);
                    updateValue(true);
                }
            } else {
                updateKey(key,false);
                updateValue(false);
            }
        }
        if(typeError == "Rfc"){
            if(formDataName.length > 0){
                if((formDataName.length == 10 || formDataName.length == 13) && /^[a-zA-Z]+$/.test(formDataName.substring(0,4)) && /^\d+$/.test(formDataName.substring(4))){
                    updateKey(key, false);
                    updateValue(false);
                }
                if(formDataName.length < 10 || formDataName.length > 13 || (formDataName.length > 10 && formDataName.length < 13)){
                    updateKey(key,true);
                    updateValue(true);
                }
                }  else{
                    updateKey(key,false);
                    updateValue(false);
                }

        }
    };

    const roundPercentage = (valueToBeUpdated) => {
        if(valueToBeUpdated == 100 || valueToBeUpdated == "100"){
            return valueToBeUpdated;
        }
        let newValue = parseInt(valueToBeUpdated);
        let unitAux = newValue % 10;

        if(unitAux >= 5 || newValue < 10){
            newValue = Math.ceil((newValue+1) / 10) * 10;
        } else {
            newValue = Math.floor((newValue+1) / 10) * 10;
        }

        return newValue;
    };

    const setSecondBeneficiary = (name) => {
        if(name == "" || name == undefined){
            setCurrentSecondPercent(0);
        } else {
            setCurrentSecondPercent(100-currentPercent)
            updateFormValues("percentSecond", 100-currentPercent);
        }
    }

    const returnIsMainBank = (selectedBank) => {
        let currenBankLowerCase = selectedBank?.toLowerCase();
        if(currenBankLowerCase == "banorte" || currenBankLowerCase == "bancomer" || currenBankLowerCase == "santander"){
            return true;
        }else{
            return false;
        }
    }


    const updateKey = (keyToBeUpdated, valueToBeUpdated) => {
        let percentAux = 0;

        if(keyToBeUpdated == "percentSecond" || keyToBeUpdated == "percent"){
            let varAux = roundPercentage(valueToBeUpdated);
            valueToBeUpdated = varAux;
            percentAux = (100-valueToBeUpdated)
            if(keyToBeUpdated == "percent"){
                setCurrentPercent(valueToBeUpdated);
                updateFormValues("percent", valueToBeUpdated);
                if(currentSecondPercent > 0){
                    setCurrentSecondPercent(percentAux)
                    updateFormValues("percentSecond", percentAux);
                }
            } else {
                setCurrentSecondPercent(valueToBeUpdated);
                updateFormValues("percentSecond", valueToBeUpdated);
                if(currentPercent > 0){
                    setCurrentPercent(percentAux)
                    updateFormValues("percent", percentAux);
                }
            }
        }else{
            updateFormValues(keyToBeUpdated, valueToBeUpdated);
        }

    };

    const updateFormValues = (keyToBeUpdated, valueToBeUpdated) => {
        let _data = formValidate;
        for(let keyData in _data){
            if(keyData === keyToBeUpdated){
                formValidate[keyData] = valueToBeUpdated;
            }
        }
        setFormValidate(_data);
    };

    useEffect(() => {
        validateKey();
        getUniversities();
    }, []);

    const getCompanyById = async (id) => {
        const [currentCompany] =  await Methods.getCompanies([id]);
        setCompany(currentCompany);
        setRequireSife(currentCompany.requireSife == 'Sí' ? true : false);
    }

    const getCurrentContract = async (id) => {
        const result =  await Methods.getInternContract(id);
        result && setCurrentContract(result);
    }

    const validateKey = async () => {
        try{
            let _intern = await axios({
                url: `${URL}/interns/key/${key}`,
                method: "PUT",
                data: {surveyKey: key}
            });
            _intern && setIntern(_intern.data.user);
            _intern.data.user.currentContractId && await getCurrentContract(_intern.data.user.currentContractId);
            await getCompanyById(_intern.data.user.id_company);
            setIsLoading(false);
        } catch(e) {
            e.response && notify(e.response.data.message, "error");
            history.push("/");
            setIsLoading(false);
        }
    };

    const getUniversities = async () =>{
        try{
            let response = await axios({
                url: `${URL}/universities`,
                method: "PUT",
            });

            const url = response.data.data;
            let dataFile = await axios(
                {
                    url: url,
                    method: 'GET',
                    responseType: 'application/json'
                }
            );
            let dataJSON = dataFile.data;

            setUniversities(dataJSON);
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
            setUniversities([]);
        }
    };
    const getInsuranceData = async () =>{
        try{
            let response = await axios({
                url: `${URL}/insurance/user`,
                method: "POST",
                data: {intern_id: intern._id}
            });
            const data = response.data.insurance[0]
            return data._id
        } catch (e) {
            e.data && notify(e.data.response.message, "error");
        }
    };
    const getExtension = (fileName) => {
        let basename = fileName.split(/[\\/]/).pop();
        let pos = basename.lastIndexOf(".");

        if (basename === "" || pos < 1){
            return "";
        };

        return basename.slice(pos + 1);
    };

    const validateCURP = (curp) => {
        const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
        const isValid = curp.match(regex);

        if (!isValid){
            notify("Tu CURP no es válida", "error")
            return false;
        };

        return true;
    };

    const getDataByZipCode = async (currentCP) => {
        let api = (await Methods.getApiData("sepomex"))[0];
        api.apiKey = JSON.parse(decrypt(api.apiKey))
        try {
             let response = await axios({
                 url: `${api.apiLink}${currentCP}?token=${api.apiKey}`,
                 method: "GET",
             });
             await setZipCodeData(response.data);
         } catch (e) {
             e.data && notify(e.data.response.message, "error");
         };
    }

    const setZipCodeData = async (data) => {
        let suburb = [];
        if(data.length > 0){
            setCurrentMunicipality(data[0].response.municipio);
            setCurrentState(data[0].response.estado);
            await data.forEach(element =>{
                const data = element.response.asentamiento;
                suburb.push(data);
            });
        suburb.sort();
        }
        setCurrentSuburbs(suburb);
    }

    const getAgeByCurp = (curp) => {
        if(curp.length != 18) return;
        const today = new Date();

        let _date = [];

        for (let index = 4; index < curp.length-8; index++) {
            _date.push(curp[index]);
        };

        let _reversedDate = [];

        let count = 0;
        for(let index = _date.length-1; index >= 0; index--){
            if(count % 2 == 0){
                _reversedDate.push(_date[index-1]);
            } else {
                _reversedDate.push(_date[index+1]);
            };

            if(count % 2 != 0 && index >= 1){
                _reversedDate.push("-");
            }
            count++;
        };

        let _birthday = [];
        const year = parseInt([_reversedDate[6], _reversedDate[7]].join(""));
        const currentYear = parseInt(today.getFullYear().toString().slice(-2));

        for (let index = 0; index < _reversedDate.length; index++) {
            _birthday.push(_reversedDate[index]);

            if(index == _reversedDate.length-3){
                if(year <= 99 && year > currentYear){
                    _birthday.push("19");
                } else {
                    _birthday.push("20");
                };
            };
        };

        const fullBirthday = _birthday.join("");
        const date = new Date(fullBirthday.replace(/(\d{2})[-/](\d{2})[-/](\d+)/, "$2/$1/$3"));
        let age = today.getFullYear() - date.getFullYear();
        const monthDifference = today.getMonth() - date.getMonth();
        const dayDifference = today.getDay() - date.getDay();

        if(monthDifference > 0 || (dayDifference > 0 || dayDifference < 0)){
            age > 0 && age--;
        } else if(monthDifference == 0 && dayDifference == 0){
            age == 0 && age++;
        };

        const todayAge = new Date();
        let ageYear = todayAge.getFullYear() - date.getFullYear();
        const m = todayAge.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && todayAge.getDate() < date.getDate())) {
            ageYear--;
        }

        !isNaN(ageYear) && setAge(ageYear);

        return ageYear;
    };

    const blockInvalidChar = (e) => {
        ['e', 'E', '+', '-', '`',].includes(e.key) && e.preventDefault();
    };

    const gotoPrivacy = () =>{
        window.open(`https://www.caintra.org.mx/aviso-de-privacidad/`)
    };

    const getInternsByClabe = async (data) => {
        if(data.target.elements.clabe.value !== "" && data.target.elements.clabe.value !== "_"){
            let duplicated;
            duplicated = await Methods.getInternsByClabe(data.target.elements.clabe.value);
            if (duplicated.data.total > 0) {
                notify("Cuenta o clabe interbancaria existente. Favor de revisar la información." , "error");
                return true;
            }
        }
        return false;
    };

    const getInternsByCurp = async (data) => {
        let duplicatedCompany = false;
        if (data.target.elements.curp.value !== "" && data.target.elements.curp.value !== "_") {
            let duplicated;
            duplicated = await Methods.getInternsByCurp(data.target.elements.curp.value);
            for (let data of duplicated.data.interns) {
                if (data._id == intern._id) {
                    return false;
                }
                if (data.id_company == intern.id_company) duplicatedCompany = true;
            }
            if (duplicatedCompany) {
                notify("No se puede guardar porque el CURP ya está dado de alta en la empresa.", "error");
                return true;
            }
        }
        return false;
    };

    const handleSendDocs = async (e) => {
        e.preventDefault();
        let contractData;
        let gender = ""
        let day = addZeroToDate(birthDate.getDate());
        let month = addZeroToDate((birthDate.getMonth()+1));
        let year = birthDate.getFullYear();
        let birthday = day+'/'+month+'/'+year;
        let sifeData;

        setIsUploadingFiles(true);
        let currentErrors = await verifyErrors(formValidate, e)
        if(currentErrors){
            setIsUploadingFiles(false);
            return;
        }

        if(e.target.elements.curp.value != "" && e.target.elements.curp.value != "_"){
            gender = e.target.elements.curp.value.charAt(10);
        }

        const attributesToBeUpdated = {
            _id: intern._id,
            attributes: [
                {updateKey: "curp", updateValue: e.target.elements.curp.value || ""},
                {updateKey: "gender", updateValue: gender || ""},
                {updateKey: "birthday", updateValue: birthday || ""},
                {updateKey: "clabe", updateValue: e.target.elements.clabe.value || "_"},
                {updateKey: "age", updateValue: getAgeByCurp(e.target.elements.curp.value)},
                {updateKey: "bank", updateValue: company.internship_bank || ""},
                {updateKey: "phone", updateValue: e.target.elements.phone.value},
                {updateKey: "surveyKey", updateValue: "/"},
                {updateKey: "_state", updateValue: e.target.elements.state.value, expressionAttributeName: "#_state"},
                {updateKey: "college", updateValue: e.target.elements.college.value},
                {updateKey: "school_enrollment", updateValue: e.target.elements.school_enrollment.value},
                {updateKey: "street", updateValue: e.target.elements.street.value},
                {updateKey: "suburb", updateValue: e.target.elements.suburb.value},
                {updateKey: "municipality", updateValue: e.target.elements.municipality.value},
                {updateKey: "career", updateValue: e.target.elements.career.value},
                {updateKey: "faculty", updateValue: e.target.elements.faculty.value},
                {updateKey: "grade", updateValue: e.target.elements.grade.value},
                {updateKey: "int_number", updateValue: e.target.elements.int_number.value},
                {updateKey: "zip_code", updateValue: e.target.elements.zip_code.value},
                {updateKey: "period_type", updateValue: e.target.elements.period_type.value},
                {updateKey: "_group", updateValue: e.target.elements.group.value, expressionAttributeName: "#_group"},
                {updateKey: "ext_number", updateValue: e.target.elements.ext_number.value},
                {updateKey: "linkedin", updateValue: e.target.elements.linkedin.value},
                {updateKey: "instagram", updateValue: e.target.elements.instagram.value},
                {updateKey: "scholarship", updateValue: e.target.elements.scholarship.value},
                {updateKey: "rfc", updateValue: e.target.elements.rfc.value},
            ],
            updateClabe: false
        };

        validateFacultyRegion(attributesToBeUpdated);

        if(e.target.elements.clabe.value){
            attributesToBeUpdated.attributes.push(
                {updateKey: "accountColorStatus", updateValue: '#28B463'},
                {updateKey: "lastModifyAccount", updateValue: 'Practicante'}
            );
            attributesToBeUpdated.updateClabe = true;
        }

        if(requireSife){
            let error = verifyPassword(e, attributesToBeUpdated.attributes)

            if(error){
                setIsUploadingFiles(false);
                return;
            };

            sifeData = {
                tempPass: e.target.elements.password.value,
                email: intern.email,
                firstname: intern.firstname,
                lastnameFather: intern.father_lastname,
                lastnameMother: intern.mother_lastname
            }
        }

        let docs = new FormData();
        let _errors = 0;

        let filesArray = Array();
        let filesToLoad = [];
        for (const file in files){
            const _file = files[file].file;

            let fileExtension = getExtension(files[file].file.name);
            let fileName = `${files[file].name}.${fileExtension}`;
            filesToLoad.push({"filename": fileName, "file": _file, "fileExtension": fileExtension});
            if(files[file].file.size > 10485760){
                notify(`El archivo ${files[file].name} no puede pesar más de 10 MB`, "error");
                _errors++;
            };
            filesArray.push({"filename": fileName, "fileKey": files[file].key});
        }

        const idInsurance = await getInsuranceData();

        let dataInsurance = {
            _id: idInsurance,
            attributes: []
        };

        dataInsurance.attributes = await getTotalAttributes(e.target.elements);

        if(_errors > 0){
            setIsUploadingFiles(false);
            return;
        };

        if(files.length > 0){
            docs.append("files", JSON.stringify(filesArray));
            docs.append("path", `interns/docs/${intern._id}`);
            docs.append("intern_id", intern._id);
        };

        if(currentContract){
            contractData = await getCurrentContractDataToUpdate(attributesToBeUpdated);
            if(contractData.attributes.length){
                if(currentContract.contractStatus == "Activo"){
                    notify("No puedes realizar cambios si tienes un convenio activo", "error");
                    return;
                }
            }
        };

        try {

            if(files.length > 0){
                let result = await axios({
                    url: `${URL}/interns/docs/${intern._id}/`,
                    method: "POST",
                    data: docs
                });

                for(let filesAux of filesToLoad){
                    let dataToSend = result.data.filter(item => item.fileName == filesAux.filename);
                    if(dataToSend.length){
                        await axios({
                            url: dataToSend[0].presignedPUTURL,
                            method: "PUT",
                            data: filesAux.file,
                            headers : {
                                "Content-Type": await verifyDocumentTypeHeader(filesAux.fileExtension),
                                "Access-Control-Allow-Origin" : "*",
                                "Access-Control-Allow-Methods": "*"
                            }
                        });
                    }
                }

                await verifyDocumentationStatus(filesArray, attributesToBeUpdated);
            }

            await Promise.all([
                Methods.updateIntern(attributesToBeUpdated), 
                Methods.updateContractById(contractData), updateInsurance(dataInsurance), 
                requireSife && Methods.registerSifeInternByEmail(sifeData),
                attributesToBeUpdated.updateClabe && Methods.updatePrefactureClabeByIntern(attributesToBeUpdated._id,  '#B2B2B2', 'Practicante', e.target.elements.clabe.value)
            ]);
            
            setIsUploadingFiles(false);
            setShowSuccessModal(true);
            setTimeout(() => {
                history.push("/");
            }, 4500);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setIsUploadingFiles(false);
        };

    };

    const validateFacultyRegion = (data) =>{
        let universityAux = universities.find(university => university._name === universitySelected);
        let facultyAux = universityAux.faculties.find(faculty => faculty._name === facultySelected);
        if(facultyAux && 'region' in facultyAux ){
            data.attributes.push({updateKey: "region", updateValue: facultyAux.region, expressionAttributeName: '#region'});
        }
    }
    const verifyErrors = async (formValidate, e) => {

        if(company.interns_medical_insurance == "Sí"){
            if(parseFloat(formValidate.percent) + parseFloat(formValidate.percentSecond) != 100){
                notify("La suma de los porcentajes debe ser igual a 100%", "error");
                return true;
            }
        }

        if(!validateCURP(e.target.elements.curp.value)){
            return true;
        };

        if(isPhoneError){
            notify("El número de teléfono debe tener 10 dígitos", "error");
            return true;
        };

        if(isRfcError){
            notify("El RFC debe tener 10 o 13 dígitos, empezar con letras y termianr con números", "error")
            return true;
        };

        let errors = await getInternsByClabe(e);
        errors = await getInternsByCurp(e);
        if(errors){
            return true;
        };

    }

    const addZeroToDate = (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };

    const verifyDocumentationStatus = async(docs, attributesToBeUpdated) => {
        let existingKeys = [];

        const requiredDocs = [
            {key: "Comprobante de Estudios", name: "Comprobante-de-Estudios"},
            {key: "Acta de Nacimiento", name: "Acta-de-Nacimiento"},
            {key: "Identificacion Oficial", name: "Identificacion-Oficial"},
            {key: "Comprobante de Domicilio", name: "Comprobante-de-Domicilio"},
            {key: "CURP", name: "Curp-De-Practicante"},
        ];

        requiredDocs.map(required => {
            const key = required.key;

            docs.length > 0 && docs.map(doc => {
                if(doc.fileKey === key){
                    existingKeys.push(doc);
                }
            })
        });

        if(existingKeys.length == 5){
            attributesToBeUpdated.attributes.push({updateKey: "documentationStatus", updateValue: "Entregada"})
        }

    }

    const verifyPassword = (e, dataToInsert) => {
        let password = e.target.elements.password.value;
        let passwordConfirm = e.target.elements.passwordConfirm.value;

        if(password.length < 8 || password.length > 126){
            notify("La contraseña debe tener al menos 8 caracteres", "error");
            return true;
        }

        if(password !== passwordConfirm){
            notify("Las contraseñas no coinciden", "error");
            return true;
        }

        dataToInsert.push({updateKey: "password", updateValue: password});
    }

    const verifyDocumentTypeHeader = async (type) =>{
        let typeHeader = {
            "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "doc": "application/msword",
            "txt": "text/plain",
            "text": "text/plain"
        }
        return typeHeader[type] || "image/*"
    }

    const getTotalAttributes = async (elements) =>{

        let attributes = [];

        const availableKeys = ["berneficiary", "berneficiarySecond", "percent", "percentSecond", "relationship", "relationshipSecond"];
        for(let index = 0; index < elements.length; ++index){
            const key = elements[index].id;
            const value = elements[index].value;

            if(availableKeys.includes(key) && value != ""){
                if(key === "_state" || key === "group" || key == "percent"){
                    attributes.push({
                        updateKey: key, updateValue: value, expressionAttributeName: `#${key}`
                    });
                } else {
                    attributes.push({
                        updateKey: key, updateValue: value
                    });
                };
            };
        };

        return attributes;
    }

    const updateInsurance = async (dataInsurance) => {
        await axios({
            url: `${URL}/insurance/user/request`,
            method: "PATCH",
            data: dataInsurance
        });
    }

    const getCurrentContractDataToUpdate = async (dataToEvaluate) => {
        let data = {
            _id: currentContract._id,
            attributes: [],
            emailCase: (intern.internStatus == 'Activo' || intern.internStatus == 'Pendiente' ) ? 'Update' : undefined
        }

        let contractValuesArray = ["college", "school_enrollment", "career", "street", "ext_number", "suburb",
        "municipality", "phone", "email", "contractStartDate", "contractEndDate", "scolarshipAmount", "department_name",
        "firstname", "father_lastname", "mother_lastname", "curp", "scholarship", "directBoss_name", "faculty"];

        if(currentContract.contractStatus == "Invalidado" || currentContract.contractStatus == "Baja"){
           return;
        }

        for(let value of dataToEvaluate.attributes){
            let isContractValue = contractValuesArray.find(objet => objet == value.updateKey);
            if(isContractValue){
                pushValueToUpdate(isContractValue, data, value.updateValue);
            }
        }
        await pushFacultyValues(data);
        await pushCompanyValues(data);
        return data
    }

    const pushValueToUpdate = (isContractValue, data, valueToUpdate) => {
        let value = {
            "college": "collegeName",
            "school_enrollment": "schoolEnrollment",
            "career": "internCareer",
            "street": "internStreet",
            "ext_number": "internExtNumber",
            "suburb": "internSuburb",
            "municipality": "internMunicipality",
            "phone": "internPhone",
            "email": "internEmail",
            "contractStartDate": "internStartDate",
            "contractEndDate": "internEndDate",
            "scolarshipAmount": "internAmount",
            "department_name": "departmentName",
            "firstname": "internFirstName",
            "father_lastname": "internFatherLastname",
            "mother_lastname": "internMotherLastname",
            "curp": "internCurp",
            "scholarship": "internScholarship",
            "directBoss_name": "directBoss",
            "faculty": "internFaculty"
        }

        if(currentContract[value[isContractValue]] != valueToUpdate){
            data.attributes.push({
                updateKey: value[isContractValue], updateValue: valueToUpdate
            });
        }
    }

    const pushCompanyValues = async (data) => {
        let companyData = company;
        let companyName = companyData.business_name;
        let companyAdminPhone = companyData.org_admin_phone;
        let companyAddress = companyData.street + " " + companyData.ext_number + " " +  companyData.zip_code + " " + companyData.suburb + " " + companyData.municipality + " " + companyData.state
        if(currentContract.companyName != companyName) data.attributes.push( { updateKey: "companyName", updateValue: companyName});
        if(currentContract.companyAdminPhone != companyAdminPhone) data.attributes.push( { updateKey: "companyAdminPhone", updateValue: companyAdminPhone});
        if(currentContract.companyAddress != companyAddress) data.attributes.push( { updateKey: "companyAddress", updateValue: companyAddress});
    }

    const pushFacultyValues = async (data) => {
        let collegeSeleted = currentContract.collegeName;
        let facultySelected;
        for(let value of data.attributes){
            if(value.updateKey == "collegeName"){
                collegeSeleted = value.updateValue;
            }
            if(value.updateKey == "internFaculty"){
                facultySelected = value.updateValue;
            }
        }

        let collegeContact = await setUniversityContact(collegeSeleted, facultySelected);
        if(collegeContact && collegeContact.email){
            if(currentContract.collegeContact && currentContract.collegeContact != collegeContact.email){
                data.attributes.push({updateKey: "collegeContact", updateValue: collegeContact.email});
            }else if(!currentContract.collegeContactEmail){
                data.attributes.push({updateKey: "collegeContact", updateValue: collegeContact.email});
            }
        }
    }

    const setUniversityContact = async (currentUniversity, currentFaculty) => {
        if(currentUniversity == "Otro") return {}
        let universitySelected;
        let universityContact;
        for(let university of universities){
            if(university._name == currentUniversity){
                universitySelected = university;
            }
        }
        if(universitySelected.sing){
            universityContact = {
                phone: universitySelected.phone,
                email: universitySelected.email,
            }
        }else{
            for(let faculty of universitySelected.faculties){
                if(faculty._name == currentFaculty){
                    universityContact = {
                        phone: faculty.phone,
                        email: faculty.email,
                        contactName: faculty.contactName
                    }
                }
            }
        }
        if(universitySelected.logo_url) universityContact.logo = universitySelected.logo_url;
        return universityContact;
    }


    return(
        <div className="documentation-request">
            <Row style={{height: "100%"}} >
                <Col className="background-container">
                    <Row className="row-logo">
                        <Col>
                            <a href="/landing"><Image src={InternsLogo} className="InternsLogo float-right"/></a>
                        </Col>
                    </Row>
                    <Row>

                    {(intern && company && !isLoading)
                    &&   <Form onSubmit={handleSendDocs}>
                            <div className="form-card">
                                <Row className="d-flex flex-column">
                                    <h4><strong>Solicitud de Ingreso a Prácticas</strong></h4>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="curp">
                                            <Form.Label>
                                                CURP
                                            </Form.Label>
                                            <Form.Control onChange={(e) => {getAgeByCurp(e.target.value); updateKey("curp", e.target.value); curpValidation(formValidate.curp);}} required minLength="18" type="text" placeholder="Escribe tu número de identificación" />
                                            {isCurpError && <Form.Label className="error-label" > {curpErrorLabel}</Form.Label>
                                            }
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="birthday">
                                            <Form.Label>Fecha de nacimiento</Form.Label>
                                            <DateTimePicker name="birthday" className='date-picker-styled' editFormat={'DD/MM/YYYY'} format={'DD/MM/YYYY'}  onChange={(date) => {setBirthDate(date); setAge(moment().diff(date, "years"));}} onCurrentDateChange={(date) => {setBirthDate(date); setAge(moment().diff(date, "years"));}} time={false} value={birthDate} />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="age">
                                            <Form.Label>
                                                Edad
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="Edad" value={age} readOnly/>
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="rfc">
                                                <Form.Label>RFC</Form.Label>
                                                <Form.Control required minLength='10' maxLength='13' type="text" placeholder="RFC" value={rfc}
                                                onChange={(e) => {e.target.value = e.target.value.toUpperCase(); updateKey("rfc", e.target.value); rfcValidation(formValidate.rfc);}}   />
                                                {isRfcError && <Form.Label className="error-label"> {rfcErrorLabel}</Form.Label>}
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="phone">
                                            <Form.Label>
                                                Teléfono
                                            </Form.Label>
                                            <Form.Control required minLength="10"
                                            onInput = {(e) =>{
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                            }}
                                            onChange={(e) => {updateKey("phone", e.target.value); verifyDataForm("is_phone_error", "Phone",formValidate.phone ,setIsPhoneError);}}
                                            type="number" placeholder="Escribe tu número de teléfono" />
                                            {isPhoneError && <Form.Label className="error-label" > {formValidate.label_error_phone}</Form.Label>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="street">
                                            <Form.Label>
                                                Calle
                                            </Form.Label>
                                            <Form.Control required type="text" placeholder="Calle" />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="ext_number">
                                            <Form.Label>
                                                No. Exterior
                                            </Form.Label>
                                            <Form.Control required type="text" placeholder="Número exterior" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="int_number">
                                            <Form.Label>
                                                No. Interior
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="Número interior" />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="zip_code">
                                            <Form.Label>
                                                Código Postal
                                            </Form.Label>
                                            <Form.Control required type="text"
                                            onInput = {async (e) =>{
                                                e.target.value = (0,e.target.value).toString().slice(0,5)
                                                if(e.target.value.length == 5){
                                                    await getDataByZipCode(e.target.value);
                                                }
                                            }}
                                            placeholder="Tu código postal" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="suburb">
                                            <Form.Label>
                                                Colonia
                                            </Form.Label>
                                            <Form.Control required as="select" placeholder="Elige tu colonia">
                                            {currentSuburbs.map( element =>{
                                                return(
                                                    <option>{element}</option>
                                                );
                                            })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>   

                                    <Col md={4}>
                                        <Form.Group controlId="municipality">
                                            <Form.Label>
                                                Municipio
                                            </Form.Label>
                                            <Form.Control required type="text" placeholder="El municipio donde vives" defaultValue={currentMunicipality} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="state">
                                            <Form.Label>
                                                Estado
                                            </Form.Label>
                                            <Form.Control required type="text" placeholder="El estado en donde vives" defaultValue={currentState} />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="college">
                                            <Form.Label>Universidad</Form.Label>
                                            <Form.Control as="select" onChange={async (e) => await setUniversity(e.target.value)}>
                                                <option value="" disabled selected defaultValue>Selecciona una universidad</option>
                                                {universities.length > 0 ? universities.map((university, index) => {
                                                    if(!university.isHistorical){
                                                        return(
                                                            <option key={index} id={university._id} value={university._name}>{university._name}</option>
                                                        )
                                                    }
                                                }) : <option disabled>No hay universidades</option>}
                                                <option value="Otro">Otro</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="faculty">
                                            <Form.Label>{company.isITESMCompany == true ? "Campus" : "Facultad"}</Form.Label>
                                            <Form.Control required as="select" onChange={async (e) => await setFacultySelected(e.target.value)}>
                                            <option value="" disabled selected defaultValue>Selecciona una {company.isITESMCompany == true ? "un campus" : "una facultad"}</option>
                                            {universities.map(university => {
                                                if(university._name === universitySelected){
                                                    if(university.faculties && university.faculties.length > 0){
                                                        return university.faculties.map((faculty, index) => {     
                                                            return(
                                                                <option key={index} id={faculty._name} value={faculty._name}>{faculty._name}</option>
                                                            )
                                                        })
                                                    }else {
                                                        return (<option disabled value="">No hay {company.isITESMCompany == true ? "campus" : "facultades"}</option>)
                                                    }
                                                } 
                                            })}
                                            <option value="Otro">Otro</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="career">
                                            <Form.Label>
                                                Carrera
                                            </Form.Label>
                                            <Form.Control required as="select">
                                            <option value="" disabled selected defaultValue>Selecciona una carrera</option>
                                            {universities.map(university => {
                                                if(university._name === universitySelected){
                                                    if(university.faculties && university.faculties.length > 0){
                                                        return university.faculties.map(faculty => {
                                                            if(faculty._name === facultySelected){
                                                                if(faculty.careers && faculty.careers.length > 0){
                                                                    return faculty.careers.map((career, index) => {
                                                                            return(
                                                                                <option key={index} id={career._name} value={career._name}>{career._name}</option>
                                                                            )
                                                                    })
                                                                }else {
                                                                    return (<option disabled value="">No hay carreras</option>)
                                                                }
                                                            }
                                                        })
                                                    }
                                                }
                                            })}
                                            <option value="Otro">Otro</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="period_type">
                                            <Form.Label>
                                                Ciclo Escolar
                                            </Form.Label>
                                            <Form.Control as="select">
                                            <option>Semestral</option>
                                            <option>Tetramestral</option>
                                            <option>Trimestral</option>
                                            <option>Cuatrimestral</option>
                                            <option>Bimestral</option>
                                            <option>Mensual</option>
                                            <option>Pentamestral</option>
                                            <option>Anual</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="scholarship">
                                            <Form.Label>
                                                Escolaridad
                                            </Form.Label>
                                            <Form.Control as="select" readOnly={true} >
                                                        <option>Profesional</option>
                                                        <option>Preparatoria</option>
                                                        <option>Carrera Técnica</option>
                                                        <option>Maestría</option>
                                                        <option>Doctorado</option>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
    
                                <Row>            
                                    <Col md={4}>
                                        <Form.Group controlId="grade">
                                            <Form.Label>
                                                Grado
                                            </Form.Label>
                                            <Form.Control required as="select" placeholder="El grado en el que te encuentras">
                                            {gradeArray.map( element =>{
                                                return(
                                                    <option>{element}</option>
                                                );
                                            })
                                            }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                   
                                    <Col md={4}>
                                        <Form.Group controlId="group">
                                            <Form.Label>
                                                Grupo/Salón
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="Grupo/Salón en el que te encuentras" />
                                        </Form.Group>
                                    </Col>
                                        
                                    <Col md={4}>
                                        <Form.Group controlId="school_enrollment">
                                            <Form.Label>
                                                Matrícula
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="La matrícula de tu escuela" />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="linkedin">
                                                <Form.Label>LinkedIn</Form.Label>
                                                <Row>
                                                    <Col sm={1} className="d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faLinkedinIn} style={{fontSize: "25px"}}/>
                                                    </Col>
                                                    <Col >
                                                    <Form.Control type="text" placeholder="Escribe tu perfil de LinkedIn" />
                                                    </Col>
                                                </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="instagram">
                                            <Form.Label>Instagram</Form.Label>
                                            <Row>
                                                <Col sm={1} className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={faInstagram} style={{fontSize: "25px"}}/>
                                                </Col>
                                                <Col >
                                                <Form.Control type="text" placeholder="Escribe tu perfil de Instagram"/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                { requireSife &&
                                    <div style={{marginTop: '10px'}}>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                        <Form.Label>
                                                        <b>La contraseña da acceso a SIFE.io para firmar electrónicamente tus documentos</b>
                                                        </Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <Form.Group controlId="password">
                                                    <Form.Label>Contraseña</Form.Label>
                                                    <InputGroup>
                                                    <Form.Control type={!seePass ? "password" : "text"}  placeholder="Contraseña" style={{backgroundColor: '#f1f1f1 !important'}}/>
                                                        <InputGroup.Append>
                                                            <InputGroup.Text style={{border: 'none'}}>
                                                                {getSeePass()}
                                                            </InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="passwordConfirm">
                                                    <Form.Label>Confirma tu contraseña</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control type={!seePass ? "password" : "text"}  placeholder="Confirma tu contraseña" style={{backgroundColor: '#f1f1f1 !important'}}/>
                                                        <InputGroup.Append>
                                                            <InputGroup.Text style={{border: 'none'}}>
                                                                {getSeePass()}
                                                            </InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {company.interns_medical_insurance == 'Sí' &&
                                <>
                                    <Row>
                                        <Col md={4} xs={12} sm={12}>
                                            <Form.Group controlId="berneficiary">
                                                <Form.Label>Beneficiario 1</Form.Label>
                                                <Form.Control required type="text"/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} sm={12}>
                                            <Form.Group controlId="percent">
                                                <Form.Label>Porcentaje 1</Form.Label>
                                                <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">El porcentaje debe ser múltiplo de 10</Tooltip>}
                                                >
                                                <div key={currentPercent}>
                                                    <Form.Control required type="number" defaultValue={currentPercent}
                                                        onChange={(e) => {setTimeout(() => {updateKey("percent", e.target.value)},2000);}}
                                                    />
                                                </div>
                                            </OverlayTrigger>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} sm={12}>
                                            <Form.Group controlId="relationship">
                                                <Form.Label>Parentesco 1</Form.Label>
                                                    <Form.Control as="select" required>
                                                        <option value="" disabled selected defaultValue>Selecciona una opción</option>
                                                        <option>Padre</option>
                                                        <option>Madre</option>
                                                        <option>Tutor</option>
                                                        <option>Hermano</option>
                                                        <option>Amistad</option>
                                                        <option>Otro</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xs={12} sm={12}>
                                        <Form.Group controlId="berneficiarySecond">
                                            <Form.Label>Beneficiario 2</Form.Label>
                                            <Form.Control onChange={(e) => setSecondBeneficiary(e.target.value)} type="text"/>
                                        </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} sm={12}>
                                            <Form.Group controlId="percentSecond">
                                            <Form.Label>Porcentaje 2</Form.Label>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">El porcentaje debe ser múltiplo de 10</Tooltip>}
                                                >
                                                <div key={currentSecondPercent}>
                                                    <Form.Control type="number" defaultValue={currentSecondPercent}
                                                        onChange={(e) => {setTimeout(() => {updateKey("percentSecond", e.target.value)},2000);}}
                                                    />
                                                </div>
                                            </OverlayTrigger>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} sm={12}>   
                                            <Form.Group controlId="relationshipSecond">
                                            <Form.Label>Parentesco 2</Form.Label>
                                                <Form.Control as="select">
                                                    <option value="" disabled selected defaultValue>Selecciona una opción</option>
                                                    <option>Padre</option>
                                                    <option>Madre</option>
                                                    <option>Tutor</option>
                                                    <option>Hermano</option>
                                                    <option>Amistad</option>
                                                    <option>Otro</option>
                                                </Form.Control>
                                            </Form.Group> 
                                        </Col>
                                    </Row>
                                </>}                                     
                                <Row>
                                    <Col md={4} xs={12}>
                                        <Form.Group controlId="bank">
                                            <Form.Label>¿Tienes {returnIsMainBank(company?.internship_bank) ? "cuenta bancaria" : "CLABE interbancaria"} en la siguiente institución financiera?</Form.Label>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="button-tooltip-currentInternData">Este dato es definido por la compañía</Tooltip>}
                                                >
                                                    <Form.Control readOnly defaultValue={company.internship_bank} type="text">
                                                    </Form.Control>
                                                </OverlayTrigger>
                                            <Form.Text className="text-muted">
                                                Si no dispones de una tarjeta, puedes omitir este apartado
                                            </Form.Text>
                                            <Form.Check
                                                label={`Tengo una ${returnIsMainBank(company?.internship_bank) ? "cuenta bancaria" : "CLABE interbancaria"}`}
                                                onChange={() => setUserHasBankAccount(!userHasBankAccount)}
                                                type="checkbox"
                                                style={{marginBottom: "12px"}}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4} xs={12}>
                                        <Form.Group hidden={!userHasBankAccount} controlId="clabe">
                                           {returnIsMainBank(company?.internship_bank) && <Form.Label>
                                                Cuenta bancaria
                                            </Form.Label>}
                                            {!returnIsMainBank(company?.internship_bank) && <Form.Label>
                                                CLABE interbancaria
                                            </Form.Label>}
                                            <Form.Control type="number"
                                                onKeyDown={blockInvalidChar}
                                                onClick={ (e) =>{
                                                    notify("Recuerda ingresar tu cuenta bancaria con cuidado para evitar errores. Es importante que verifiques los números y dígitos para que puedas recibir la beca de forma segura y sin contratiempos.", "warning")
                                                }}
                                                onInput = {(e) =>{
                                                    let maxLength = 10;
                                                    if(  /^(santander)$/i.test(company.internship_bank.toLowerCase())) maxLength = 11;
                                                    else if( !/^(bancomer|banorte)$/i.test(company.internship_bank.toLowerCase())) maxLength = 18;
                                                    e.target.value = e.target.value.toString().slice(0,maxLength);
                                                }}
                                                placeholder={`Proporciona tu ${returnIsMainBank(company?.internship_bank) ? "cuenta bancaria" : "CLABE interbancaria"}`} onChange={(e) => {updateKey("clabe", e.target.value);}}/>
                                            <Form.Text className="text-muted">
                                                Recuerda que tus datos están protegidos bajo encriptación de extremo a extremo
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                                <Form.Label>
                                                   <b>Comparte la siguiente papelería para generación de expediente digital</b>
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    Los documentos deben de ser (txt, text, doc, docx, pdf, png, jpg, jpeg)
                                                </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>

                                </Row>

                                <Row>
                                {userHasBankAccount &&
                                    <Col md={4} xs={12}>
                                        <Form.Group hidden={!userHasBankAccount} controlId="bankaccountstatus" className="files">
                                            <div className='header-container'>
                                                <div>
                                                    <Form.Label className='no-margin'>
                                                       <b>Carátula Estado de Cuenta</b>
                                                    </Form.Label>
                                                    <br />
                                                    <small>
                                                        {
                                                            bankAccountStatusFileName ? (bankAccountStatusFileName.length > 22  ? bankAccountStatusFileName.substring(0,22) + "..." : bankAccountStatusFileName) :
                                                            "Sin documentos"
                                                        }
                                                    </small>
                                                </div>
                                                <div>
                                                    <img src={clip}/>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <Button className="upload-container">
                                                        <Form.File
                                                        onChange={(e) => {
                                                                e.preventDefault();
                                                                if(e.target.files[0]){
                                                                    setBankAccountStatusIcon(faCheckCircle);
                                                                    setBankAccountStatusFileName(e.target.files[0].name);
                                                                    setFiles([...files, {name: "Caratula-Estado-de-Cuenta", file: e.target.files[0], key: "Caratula Estado de Cuenta"}]);
                                                                };
                                                            }
                                                        }
                                                        id="bankaccountstatus"
                                                        label={bankAccountStatusFileName ? "Actualizar documento" : "Subir documento"}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                }
                                    <Col md={4} xs={12}>
                                        <Form.Group className="files">
                                            <div className='header-container'>
                                                <div>
                                                    <Form.Label className='no-margin'>
                                                       <b>Identificación Oficial</b>
                                                    </Form.Label>
                                                    <br />
                                                    <small>
                                                        {
                                                            ineFileName ? (ineFileName.length > 22  ? ineFileName.substring(0,22) + "..." : ineFileName) :
                                                            "Sin documentos"
                                                        }
                                                    </small>
                                                </div>
                                                <div>
                                                    <img src={clip}/>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <Button className="upload-container">
                                                        <Form.File
                                                            onChange={(e) => {
                                                                    e.preventDefault();
                                                                    if(e.target.files[0]){
                                                                        setIneIcon(faCheckCircle);
                                                                        setIneFileName(e.target.files[0].name);
                                                                        setFiles([...files, {name: "Identificacion-Oficial", file: e.target.files[0], key: "Identificacion Oficial"}]);
                                                                    };
                                                                }
                                                            }
                                                            id="ine"
                                                            label={ineFileName ? "Actualizar documento" : "Subir documento"}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </Form.Group>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Group className="files">
                                            <div className='header-container'>
                                                <div>
                                                    <Form.Label className='no-margin'>
                                                       <b>Comprobante de Domicilio</b>
                                                    </Form.Label>
                                                    <br />
                                                    <small>
                                                        {
                                                            proofOfResidenceFileName ? (proofOfResidenceFileName.length > 22  ? proofOfResidenceFileName.substring(0,22) + "..." : proofOfResidenceFileName) :
                                                            "Sin documentos"
                                                        }
                                                    </small>
                                                    <br />
                                                </div>
                                                <div>
                                                    <img src={clip}/>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <Button className="upload-container">
                                                        <Form.File
                                                        onChange = {(e) => {
                                                                e.preventDefault();
                                                                if(e.target.files[0]){
                                                                    setProofOfResidenceIcon(faCheckCircle);
                                                                    setProofOfResidenceFileName(e.target.files[0].name || "");
                                                                    setFiles([...files, {name: "Comprobante-de-Domicilio", file: e.target.files[0], key: "Comprobante de Domicilio"}]);
                                                                };
                                                            }
                                                        }
                                                        id="proofofresidence"
                                                        label={proofOfResidenceFileName ? "Actualizar documento" : "Subir documento"}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Group className="files">
                                            <div className='header-container'>
                                                <div>
                                                    <Form.Label className='no-margin'>
                                                    <b>Acta de Nacimiento</b>
                                                    </Form.Label>
                                                    <br />
                                                    <small>
                                                        {
                                                            birthCertificateFileName ? (birthCertificateFileName.length > 22  ? birthCertificateFileName.substring(0,22) + "..." : birthCertificateFileName) :
                                                            "Sin documentos"
                                                        }
                                                    </small>
                                                    <br />
                                                </div>
                                                <div>
                                                    <img src={clip}/>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <Button className="upload-container">
                                                        <Form.File
                                                        onChange={(e) => {
                                                                e.preventDefault();
                                                                if(e.target.files[0]){
                                                                    setBirthCertificateIcon(faCheckCircle);
                                                                    setBirthCertificateFileName(e.target.files[0].name);
                                                                    setFiles([...files, {name: "Acta-de-Nacimiento", file: e.target.files[0], key: "Acta de Nacimiento"}])
                                                                }
                                                            }
                                                        }
                                                        id="birthcertificate"
                                                        label={birthCertificateFileName ?  "Actualizar documento" : "Subir documento"}
                                                        />

                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Group className="files">
                                                <div className='header-container'>
                                                    <div>
                                                        <Form.Label className='no-margin'>
                                                        <b>Comprobante de Estudios</b>
                                                        </Form.Label>
                                                        <br />
                                                        <small>
                                                        {
                                                            kardexFileName ? (kardexFileName.length > 22  ? kardexFileName.substring(0,22) + "..." : kardexFileName) :
                                                            "Sin documentos"
                                                        }
                                                        </small>
                                                        <br />
                                                    </div>
                                                    <div>
                                                        <img src={clip}/>
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col>
                                                        <Button className="upload-container">
                                                            <Form.File
                                                            onChange={(e) => {
                                                                    e.preventDefault();
                                                                    if(e.target.files[0]){
                                                                        setKardexIcon(faCheckCircle);
                                                                        setKardexFileName(e.target.files[0].name);
                                                                        setFiles([...files, {name: "Comprobante-De-Estudios", file: e.target.files[0], key: "Comprobante de Estudios"}]);
                                                                    };
                                                                }
                                                            }
                                                            id="kardex"
                                                            label={kardexFileName ? "Actualizar documento" : "Subir documento"}
                                                            />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Form.Group className="files">
                                            <div className='header-container'>
                                                <div>
                                                    <Form.Label className='no-margin'>
                                                        <b>CURP</b>
                                                    </Form.Label>
                                                    <br />
                                                    <small>
                                                        {
                                                            curpFileName ? (curpFileName.length > 22  ? curpFileName.substring(0,22) + "..." : curpFileName) :
                                                            "Sin documentos"
                                                        }
                                                    </small>
                                                    <br />
                                                </div>
                                                <div>
                                                    <img src={clip}/>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <Button className="upload-container">
                                                        <Form.File
                                                        onChange={(e) => {
                                                                e.preventDefault();
                                                                if(e.target.files[0]){
                                                                    setCurpIcon(faCheckCircle);
                                                                    setCurpFileName(e.target.files[0].name);
                                                                    setFiles([...files, {name: "Curp-De-Practicante", file: e.target.files[0], key: "CURP"}]);
                                                                };
                                                            }
                                                        }
                                                        id="CURP"
                                                        label={curpFileName ? "Actualizar documento" : "Subir documento"}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                               <Row>
                               <Col md={12}>
                                   <Row style={{paddingLeft: "20px"}}>
                                        <Form.Check
                                        onChange={() => setUserHasAcceptedPrivacy(!userHasAcceptedPrivacy)}
                                        type="checkbox"
                                        style={{marginBottom: "12px"}}
                                        />
                                        Al hacer click en la casilla de verificación, aceptas nuestro<a style={{marginLeft: "5px"}} href='#' onClick={() => gotoPrivacy()}> aviso de privacidad </a>
                                    </Row>
                                </Col>
                               </Row>
                               <Row>
                               <Col>
                                    <Button disabled={!userHasAcceptedPrivacy}  variant="primary" type="submit">
                                        {isUploadingFiles
                                        ?   <Spinner animation="border" role="status">
                                                <span className="sr-only"></span>
                                            </Spinner>
                                        : "Enviar"}
                                    </Button>
                                </Col>
                               </Row>
                            </div>
                        </Form>
                    }
                    </Row>
                </Col>
            </Row>

            <SuccessModal showSuccessModal={showSuccessModal}/>
        </div>
    );
}
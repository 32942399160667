import moment from "moment";
import Methods from "../../../utils/https"
import { notify } from "../../../utils/notify";

export const getDispersionByEmailAndDate = async(startDate) => {
    const validBanks = ['Santander', 'Bancomer', 'Banorte'];
    const startDateX = parseInt(moment(startDate).startOf('day').format('X'));
    const endDateX = parseInt(moment(startDate).endOf('day').format('X'));
    const users = await Methods.getUsersByCompany(
        '311', 
        true, 
        '#id, email, fullName, #role, #permissions'
    )
    let promises = [];
    for(let user of users){
        promises.push(Methods.getDispersionByEmailAndDate(
            user,
            startDateX,
            endDateX
        ));
    }

    let result = await Promise.all(promises);

    let contactsData = [];
    for(let contact of result){
        let contactAux = contact;
        contactAux.banks = [];
        contactAux.adviserName = contactAux.contact.fullName;
        contactAux.adviserEmail = contactAux.contact.email;
        contactAux.isChecked = false;
        contactAux.total = 0;
        if(contactAux.dispersions.length){
            for(let dispersion of contactAux.dispersions){

                if(!validBanks.includes(dispersion.bank)){
                    dispersion.realBankName = dispersion.bank;
                    dispersion.bank = 'Interbancario';
                }
                
                let bankData = contactAux.banks.find(bank => (dispersion.bank + ' ' + dispersion.dispersionType)  == bank.bankName);
                if(bankData){
                    bankData.companies = [...bankData.companies, {
                        companyName: dispersion.companyName,
                        totalInterns: dispersion.internsDispersed,
                        totalAmount: dispersion.totalDispersed
                    }];
                    bankData.total = bankData.total + dispersion.totalDispersed;
                }else{
                    contactAux.banks.push({
                        bankName: dispersion.bank + ' ' + dispersion.dispersionType,
                        simpleBankName: dispersion.bank,
                        companies: [{
                            companyName: dispersion.companyName,
                            totalInterns: dispersion.internsDispersed,
                            totalAmount: dispersion.totalDispersed,
                            dispersionFileId: dispersion.fileId,
                            filePath: dispersion.s3Path,
                            fileExtension: dispersion.fileExtension,
                            fileUrl: dispersion.invoiceS3Url,
                            fileName: dispersion.fileName
                        }],
                        fileName: dispersion.fileName || '',
                        isChecked: false,
                        total: dispersion.totalDispersed
                    });
                }
            }
            contactsData.push(contactAux);
        }
        
    }
    if(!(contactsData.length)){
        notify('No se encontraron registros en la fechas seleccionadas', 'error');
    }
    return contactsData;
}

import React, {useState} from 'react'
import { useEffect } from 'react'
import './ProgressBar.css'
export const ProgressBar = ({requiredKeys, data, isChanged}) => {

    useEffect(() => {
        getDataPercent();
    }, [isChanged]);

    const getDataPercent = () => {
        
        let currentCount = 0;
        for(let key of requiredKeys){
            if(data[key] && data[key] != '_' && data[key] != ' '){
                currentCount += 1; 
            }
        };
        
        return ((currentCount * 100) / requiredKeys.length).toFixed(2);
    }

    const newStyle = {
        opacity: 1,
        width: `${getDataPercent()}%`
    }
    
    return (
            <div className="progress">
                <div className="progress-done" style={newStyle}>
                    {getDataPercent()}%
                </div>
            </div>
    );
}
export const updateReportByBank = (dataToUpdate, adviserData, action) => {
    let dataToUpdateAux = [...dataToUpdate];

    let bankCheked = adviserData.bankSelected;
    let currentDataBank = dataToUpdateAux?.find(bankAux => bankAux.bankName == bankCheked.bankName);
    let addBank = false;
    let filesId = [];

    for(let company of bankCheked.companies){
        if(!(filesId.find(file => file.dispersionFileId == company.dispersionFileId))){
            filesId.push({
                dispersionFileId: company.dispersionFileId,
                filePath: company.filePath,
                fileExtension: company.fileExtension,
                fileName: company.fileName
            });
        }
    }

    if(!currentDataBank){
        currentDataBank = {};
        currentDataBank.total = 0;
        currentDataBank.advisers = [];
        currentDataBank.bankName = bankCheked.bankName;
        if(bankCheked.simpleBankName == 'Banorte') currentDataBank.fileName = bankCheked.fileName
        currentDataBank.files = [];
        addBank = true;
    }

    currentDataBank.total = currentDataBank.total + (action == 'add' ? (+ bankCheked.total ) : (- bankCheked.total));


    if(action == 'add'){
        currentDataBank.advisers.push({
            adviserName: adviserData.adviserName,
            totalByBank: bankCheked.total,
            adviserEmail: adviserData.adviserEmail
        });

        currentDataBank.files = [...currentDataBank.files, ...filesId];

    }else{
        
        currentDataBank.advisers = currentDataBank?.advisers?.filter(adviserAux => 
            adviserAux.adviserEmail != adviserData.adviserEmail
        );

        currentDataBank.files = currentDataBank?.files?.filter(id => !(filesId.includes(id)));
        
        currentDataBank.files = currentDataBank?.files?.filter(
            element => filesId.indexOf(element) == -1
        );
    }

    if(addBank) dataToUpdateAux.push(currentDataBank);
    
    dataToUpdateAux = dataToUpdateAux.filter(bankData => bankData.total > 0);
    return dataToUpdateAux;
}
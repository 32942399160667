


export default class DefaultRoles {

    static NewUser () {
        return {
            "Dashboard": {
                "view": true,
            },
            "Interns": {
                "view": false,
                "approve": false,
                "create": false,
                "update": false,
                "delete": false,
            },
            "Departments": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
            },
            "Prefacture": {
                "view": false,
                "create": false,
                "update": false,
                "approve": false,
            },
            "Dispersion": {
                "view": false,
                "disperse": false,
            },
            "Pending": {
                "view": false,
                "approve": false,
            },
            "Companies": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
            },
            "Reports": {
                "view": false
            },
            "Users": {
                "view": false,
                "create": false,
                "update": false,
            },
            "Roles": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
            },
            "Universities": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Notifications": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Uploads": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false
            }
        }
    }

    static AdminRole () {
        return {
            "Dashboard": {
                "view": true,
            },
            "Interns": {
                "view": true,
                "approve": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Departments": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Prefacture": {
                "view": true,
                "create": true,
                "update": true,
                "approve": true,
            },
            "Dispersion": {
                "view": false,
                "disperse": false,
            },
            "Pending": {
                "view": false,
                "approve": false,
            },
            "Companies": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Reports": {
                "view": true
            },
            "Users": {
                "view": true,
                "create": true,
                "update": true,
            },
            "Roles": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Universities": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Notifications": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Uploads": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false
            }
        }
    }

    static GroupRole () {
        return {
            "Dashboard": {
                "view": true,
            },
            "Interns": {
                "view": true,
                "approve": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Departments": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Prefacture": {
                "view": true,
                "create": false,
                "update": true,
                "delete": false,
                "approve": true,
            },
            "Dispersion": {
                "view": false,
                "update": false,
                "disperse": false,
            },
            "Pending": {
                "view": false,
                "update": false,
                "approve": false,
            },
            "Companies": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Reports": {
                "view": true
            },
            "Users": {
                "view": true,
                "create": true,
                "update": true,
            },
            "Roles": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Universities": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Notifications": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Uploads": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false
            }
        }
    }

    static CompanyRole () {
        return {
            "Dashboard": {
                "view": true,
            },
            "Interns": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
                "approve": true,
            },
            "Departments": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Prefacture": {
                "view": true,
                "create": false,
                "update": false,
                "delete": false,
                "approve": true,
            },
            "Dispersion": {
                "view": false,
                "update": false,
                "disperse": false,
            },
            "Pending": {
                "view": false,
                "update": false,
                "approve": false,
            },
            "Companies": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
            },
            "Reports": {
                "view": true
            },
            "Users": {
                "view": true,
                "create": true,
                "update": true,
            },
            "Roles": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Universities": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Notifications": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Uploads": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false
            }
        }
    }

    static CorporativeRole () {
        return {
            "Dashboard": {
                "view": true,
            },
            "Interns": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
                "approve": true,
            },
            "Departments": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Prefacture": {
                "view": true,
                "create": false,
                "update": true,
                "delete": false,
                "approve": true,
            },
            "Dispersion": {
                "view": false,
                "update": false,
                "disperse": false,
            },
            "Pending": {
                "view": false,
                "update": false,
                "approve": false,
            },
            "Companies": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Reports": {
                "view": true
            },
            "Users": {
                "view": true,
                "create": true,
                "update": true,
            },
            "Roles": {
                "view": true,
                "create": true,
                "update": true,
                "delete": true,
            },
            "Universities": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Notifications": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false,
                "approve": false,
            },
            "Uploads": {
                "view": false,
                "create": false,
                "update": false,
                "delete": false
            }
        }
    }

}
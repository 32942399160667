import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form, Image, Spinner, Row, Col } from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import { UserContext } from '../../../context/UserContext';

const axios = require("axios");

export default function EditUserModal({ currentUser, openEditUserModal, setOpenEditUserModal, updateList, setUpdateList, currentDepartment, currentDepartmentId, currentCompanyId, valueLeader, setValueLeader, setLeader, leader , unsuscribe}) {
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(UserContext);
    const canEdit = context.can("update", "Users");
    const [departmentId, setDepartmentId] = useState(undefined);
    const [departments, setDepartments] = useState(undefined);
    const URL = process.env.REACT_APP_SOCKET_URL;

    useEffect(() => {
        getDepartments();
    }, [updateList]);

    const getDepartments = async () => {
        try {

            let _departments = await axios({
                url: `${URL}/departments`,
                method: "PUT",
                data: {
                    id_company: currentCompanyId,
                    departmentField: context.getDepartmentField(),
                }
            });

            setDepartments(_departments.data.data);

        } catch (error) {
            setDepartments(undefined);
        };
    };

    const verifyLeader = async (value) => {
        setLeader(false);
        if (value == "Si") {
            if (!departments) {
                leader = false;
                setValueLeader("No")
                setLeader(false)
                notify(`Esta compañía no cuenta con ${context.getDepartmentField(false, true)} para asignar`, "error");
            } else {
                leader = true;
                setLeader(true);
                setValueLeader("Si")
            }
        } else {
            leader = false;
            setValueLeader("No")
            setLeader(false)
        }
    }

    const updateUser = async (e) => {
        e.preventDefault();

        if (!canEdit) {
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        setIsLoading(true);

        let userData = {
            fullName: e.target.elements.fullName.value,
            email: e.target.elements.email.value,
        };

        if (departmentId) {
            userData.departmentId = departmentId
        }

        let dataToBeUpdated = {
            _id: currentUser._id,
            attributes: [],
            userEmail: currentUser.email,
        };

        for (let key in userData) {
            if (userData[key] != currentUser[key]) {
                if (key == "departmentId" && currentDepartmentId) dataToBeUpdated.lastDepartmentId = currentDepartmentId
                dataToBeUpdated.attributes = [...dataToBeUpdated.attributes, { updateKey: key, updateValue: userData[key] }]
            };
        };

        if (valueLeader == "No" && currentDepartmentId) {
            await Methods.deleteLeaderKey(currentUser._id, currentDepartmentId, context.getGeneralistaField());
        }

        if (dataToBeUpdated.attributes.length == 0 && !dataToBeUpdated.keyToDelte && !(valueLeader == "No" && currentDepartmentId)) {
            setIsLoading(false);
            notify("No hay ningún cambio por realizar en este usuario", "error");
            return;
        };

        await Methods.updateUser(dataToBeUpdated);
        setIsLoading(false);
        setUpdateList(!updateList);
        setOpenEditUserModal(false);
    };

    const updateUserStatus = async (e) => {
        e.preventDefault();
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth()+1;
        const year = currentDate.getFullYear();
        const formatDate = day+"-"+month+"-"+year;
   
       
        if (!canEdit) {
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        setIsLoading(true);

        let userData = {
            fullName: e.target.elements.fullName.value,
            email: e.target.elements.email.value,
        };

        if (departmentId) {
            userData.departmentId = departmentId
        }
        
        let dataToBeUpdated = {
            _id: currentUser._id,
            attributes: [{updateKey: 'isApproved', updateValue: false},{updateKey: 'unsubscribed', updateValue: true}, {updateKey: 'unsubscribedDate', updateValue: formatDate}],
            userEmail: currentUser.email,
        };
       
        if (dataToBeUpdated.attributes.length == 0 && !dataToBeUpdated.keyToDelte && !(valueLeader == "No" && currentDepartmentId)) {
            setIsLoading(false);
            notify("No hay ningún cambio por realizar en este usuario", "error");
            return;
        };

        await Methods.updateUser(dataToBeUpdated);
        setIsLoading(false);
        setUpdateList(!updateList);
        setOpenEditUserModal(false);
    };

    const validateEntryKey = (e) => {
        e.charCode == 13 && e.preventDefault()
    }

    if(unsuscribe[0]){
        return (
            <Modal className="prefacture-modals" show={openEditUserModal} onHide={() => setOpenEditUserModal(!openEditUserModal)} centered>
    
                <Modal.Body>
                    <p>Inhabilitar usuario: {currentUser.fullName}</p>
                    <Form onSubmit={updateUserStatus} autoComplete={"off"}>
                        <Row>
                            <Col style={{ margin: `20px` }}>
                                <h6>Esta seguro que desea inhabilitar al usuario?</h6>

                                <Form.Group controlId="fullName">
                                    <Form.Label>Nombre Completo</Form.Label>
                                    <Form.Control onKeyPress={validateEntryKey} readOnly={true} type="text" placeholder="Introduce tu nombre" defaultValue={currentUser.fullName} />
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <Form.Label>
                                        Correo Electrónico
                                    </Form.Label>
                                    <Form.Control onKeyPress={validateEntryKey} readOnly={true} type="email" placeholder="ejemplo@ejemplo.com" defaultValue={currentUser.email} />
                                </Form.Group>
                               
                            </Col>
                        </Row>
    
                        <Row>
                            <Col>
                                <Button className="btn-register float-right" disabled={!canEdit} variant="primary" type="submit">
                                    {isLoading ? <Spinner animation="border" /> : "Actualizar"}
                                </Button>
    
                                <Button className="float-right mr-3" variant="danger" onClick={() => setOpenEditUserModal(!openEditUserModal)}>
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
    else{

        return (
            <Modal className="prefacture-modals" show={openEditUserModal} onHide={() => setOpenEditUserModal(!openEditUserModal)} centered>
    
                <Modal.Body>
                    <p>Actualizar datos de: {currentUser.firstName}</p>
                    <Form onSubmit={updateUser} autoComplete={"off"}>
                        <Row>
                            <Col>
                                <Form.Group controlId="fullName">
                                    <Form.Label>Nombre Completo</Form.Label>
                                    <Form.Control onKeyPress={validateEntryKey} readOnly={!canEdit} type="text" placeholder="Introduce tu nombre" defaultValue={currentUser.fullName} />
                                </Form.Group>
    
                                <Form.Group controlId="isLeader" className="company-form select">
                                    <Form.Label>
                                        {context.getGeneralistaField(true)}
                                    </Form.Label>
                                    <Form.Control as="select" defaultValue={valueLeader} onChange={(e) => { e.preventDefault(); verifyLeader(e.target.value); }}>
                                        <option>Si</option>
                                        <option>No</option>
                                    </Form.Control>
                                </Form.Group>
                                {leader &&
                                    <Form.Group controlId="department" className="company-form select">
                                        <Form.Label>{context.getDepartmentField(true)}</Form.Label>
                                        <Form.Control as="select" onChange={(e) => { setDepartmentId(e.target.value) }}>
                                            <option disabled selected>{currentDepartment}</option>
                                            {departments && departments.map(element => {
                                                return (
                                                    <option value={element._id}>{element.title}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                }
                                <Form.Group controlId="email">
                                    <Form.Label>
                                        Correo Electrónico
                                    </Form.Label>
                                    <Form.Control onKeyPress={validateEntryKey} readOnly={!canEdit} type="email" placeholder="ejemplo@ejemplo.com" defaultValue={currentUser.email} />
                                </Form.Group>
                            </Col>
                        </Row>
    
                        <Row>
                            <Col>
                                <Button className="btn-register float-right" disabled={!canEdit} variant="primary" type="submit">
                                    {isLoading ? <Spinner animation="border" /> : "Actualizar"}
                                </Button>
    
                                <Button className="float-right mr-3" variant="danger" onClick={() => setOpenEditUserModal(!openEditUserModal)}>
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        )

    }
    
}

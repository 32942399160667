import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-toastify/dist/ReactToastify.css';
import {UserProvider} from './context/UserContext';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-step-progress/dist/index.css';

moment.locale('en');
momentLocalizer();

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

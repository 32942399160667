import React, {useState, useContext, useEffect} from 'react';
import {Modal, Form, Button, Row, Col, InputGroup} from 'react-bootstrap';
import Methods from '../../../utils/https';
import {DateTimePicker} from 'react-widgets';
import { notify } from '../../../utils/notify';
import { UserContext } from '../../../context/UserContext';
import { deletePosteriorPolicies } from '../../../utils/insuranceMethods';
import moment from 'moment';
import './UpdateIntern.css';

export default function UpdateIntern({showUpdateInternModal, setShowUpdateInternModal, dataToBeUpdated, updateList, setUpdateList, setData, setSetData}) {
    const context = useContext(UserContext);
    const company = context.user.company;
    const role = context.user.data.role;
    const userFullName = `${context.user.data.fullName}`;
    const userEmail = context.user.data.email;
    const {intern, valueToBeUpdated, currentPeriod} = dataToBeUpdated;
    const setMomentDate = (date) => {
        if(date.includes("/")) return moment(date, "DD/MM/YYYY") 
        return moment(date);
    }
    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            result = month + "/" + day + "/"+ year;
        } else {        
            const newDateArray = dateString.split("/");
            month = addZeroToDate(newDateArray[0]);
            day = addZeroToDate(newDateArray[1]);
            result = day + "/" + month + "/" + newDateArray[2];
        }
        return result;
    }
    const addZeroToDate =  (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };
    const [contractEndDate, setContractEndDate] = useState(new Date());
    const [costCenters, setCostCenters] = useState([]);
    const [adjustmentAmount, setAdjustmentAmount ] = useState([]);
    const [adjustmentDay, setAdjustmentDay ] = useState([]);
    const [adjustmentByAmount, setAdjustmentByAmount ] = useState([true]);


    useEffect(() => {
        (valueToBeUpdated === "cost_center_id" && showUpdateInternModal) && getCostCenters();
    }, [showUpdateInternModal]);

    const updateIntern = async (event, action) => {
        let elements;
        let updateContract = false;
        let unsuscribeIntern = false;
        if (event) {
            event.preventDefault();
            elements = event.target.elements;
        } else{
            const form = document.forms['adjustmentForm'];
            const getValue = (id) => {
                let response;
                try {
                    response = form[id].value;
                    if (id != 'motive' && response) {
                        response = response * (action == '--' ? -1:1);
                    }
                } catch (error) {}
                return response;
            }

            elements = {
                newValue:          { value: getValue('newValue') },
                newValueByAmount:  { value: getValue('newValueByAmount') },
                newValueByDay:     { value: getValue('newValueByDay') },
                motive:            { value: getValue('motive') },
            }
        }
        let days;

        if(valueToBeUpdated != "contractEndDate"){
            days = elements.newValueByDay.value;
            if (days && days%0.5) {
                let days_new = Math.round(days/.5)*.5;
                notify(`La cantidad de dias se ha redondeado de ${days} a ${days_new}`, "error");
                days = days_new;
            }
        }
        
        const motive = elements.motive?.value;
        let value, data, record;

        if(valueToBeUpdated != "adjustment" && motive.trim() === ""){
            notify("El motivo no puede estar vacío", "error");
            return;
        };

        if(valueToBeUpdated === "scolarshipAmount"){
            value = elements.newValue.value;
            
            data = {
                _id: intern._id,
                attributes: [
                    {updateKey: "scolarshipAmount", updateValue: value}
                ]
            };
            record = {
                message: `Ajuste en el monto de beca del practicante ${intern.firstname} ${intern.father_lastname} de $${intern.scolarshipAmount} a $${value}. Motivo: "${motive}"`,
                author: userFullName,
                role: role,
                companyId: company._id,
                createdAt: new Date(),
                authorEmail: userEmail,
            };
        };

        if(valueToBeUpdated === "contractEndDate"){
            value = elements.contractEndDate.value
            let unsuscribeDate = moment(value, "DD/MM/YYYY").format();
            let currentInternContractDate = new Date(getFormatedDate(intern.currentContractData.internEndDate));
            let currentInternContractStart = new Date(getFormatedDate(intern.currentContractData.internStartDate));
            let currentContractEndDate = new Date(getFormatedDate(value));
            let currentDate = new Date();

            currentInternContractStart.setHours(0);
            currentContractEndDate.setHours(0);
            currentDate.setHours(0)
            
            if(currentContractEndDate > currentInternContractDate){
                notify("Solo puedes seleccionar una fecha menor a la fecha actual de término de convenio", "error");
                return;
            }

            if(currentInternContractStart >= currentContractEndDate){
                notify("Solo puedes seleccionar una fecha mayor a la fecha actual de inicio de convenio", "error");
                return;
            }

            data = {
                _id: intern.currentContractData._id,
                attributes: [
                    {updateKey: "unsuscribeDate", updateValue: unsuscribeDate}
                ]
            };

            let body = {
                intern_id: intern._id,
                currentInsuranceId: false
            }

            if(context.user.company.interns_medical_insurance == "Sí"){
                const insuranceByIntern = await Methods.getInternInsurance(body);
                await deletePosteriorPolicies(insuranceByIntern.insurance, 'Inactivo', unsuscribeDate, intern.currentContractId, intern, company);
            }
            
            if(currentDate > currentContractEndDate){
                data.attributes.push({updateKey: "contractStatus", updateValue: "Inactivo"});
                unsuscribeIntern = true;
            }
    
            record = {
                message: `Ajuste en la fecha de término del practicante ${intern.firstname} ${intern.father_lastname} de ${intern.contractStartDate} a ${value}. Motivo: "${motive}"`,
                author: userFullName,
                role: role,
                companyId: company._id,
                createdAt: new Date(),
                authorEmail: userEmail,
            };
            updateContract = true;
        };

        if(valueToBeUpdated === "cost_center_id"){
           const [costCenterId, costCenterTitle] = JSON.parse(elements.costCenter.value);

            data = {
                _id: intern._id,
                attributes: [
                    {updateKey: "cost_center_id", updateValue: costCenterId},
                    {updateKey: "cost_center_name", updateValue: costCenterTitle}
                ]
            };
    
            record = {
                message: `Ajuste en el centro de costos del practicante ${intern.firstname} ${intern.father_lastname} de ${intern.cost_center_name != "" ? intern.cost_center_name : "N/A"} a "${costCenterTitle}". Motivo: "${motive}"`,
                author: userFullName,
                role: role,
                companyId: company._id,
                createdAt: new Date(),
                authorEmail: userEmail,
            };
        };

        if(valueToBeUpdated === "adjustment"){
            let startDate;
            let endDate;
            let alreadyExist = false;
            if(currentPeriod){
                startDate = moment(currentPeriod[0]).format("DD/MM/YYYY");
                endDate = moment(currentPeriod[1]).format("DD/MM/YYYY");
            }
            value = adjustmentByAmount ? elements.newValueByAmount.value : days * (intern.scolarshipAmount / 30);
            value = Math.round(value * 100) / 100;
            let adjustments = [];
            if(!intern.adjustments?.length){
                adjustments.push({
                    startDate: startDate,
                    endDate: endDate,   
                    adjustment: value,
                    adjustmentDaysToPay: days
                });
            }else{
                for(let adjutsment of intern.adjustments){
                    if(adjutsment.startDate == startDate && adjutsment.endDate == endDate){
                        adjutsment.adjustment = value;
                        adjutsment.adjustmentDaysToPay = days;
                        alreadyExist = true;
                    }
                }
                adjustments = intern.adjustments
                if(!alreadyExist){
                    adjustments.push({
                        startDate: startDate,
                        endDate: endDate,   
                        adjustment: value,
                        adjustmentDaysToPay: days
                    });
                } 
            }

            data = {    
                _id: intern._id,
                attributes: [ 
                    {updateKey: "adjustments", updateValue: adjustments},
                ]
            };
            intern.adjustment = value;
            record = {
                message: `Ajuste en el abono/descuento del practicante ${intern.firstname} ${intern.father_lastname} de $${intern.adjustment} a $${value}.`,
                author: userFullName,
                role: role,
                companyId: company._id,
                createdAt: new Date(),
                authorEmail: userEmail,
            };
            setAdjustmentAmount(0);
            setAdjustmentDay(0);
        };

        updateContract && await updateInternContract(intern, data, unsuscribeIntern);
        await Methods.updateIntern(data);
        setUpdateList(!updateList);
        await Methods.createRecord(record);
        setShowUpdateInternModal(false);
        setSetData(true);
        notify("Datos actualizados exitosamente", "success");
    };


    const updateInternContract = async (intern, data, unsuscribeIntern) => {
       
        if(unsuscribeIntern) {
            let internDataToUpdate = {
                _id: intern._id,
                lastStatus: intern.internStatus,
                attributes: [
                    {updateKey: "internStatus", updateValue: "Inactivo"}
                ]  
            }
            await Methods.updateIntern(internDataToUpdate);
        }

        await Methods.updateContractById(data);
    };

    const getAdjustmentString = () => {
        switch (valueToBeUpdated) {
            case "contractEndDate":
                return "fecha de baja";
            case "scolarshipAmount":
                return "monto de beca";
            case "cost_center_id":
                return "centro de costos";
            case "adjustment":
                return "ajuste"
        };
    };

    const getCostCenters = async () => {
        const data = await Methods.getCostCenters(intern.department_id, context.getDepartmentField());
        setCostCenters(data);
    };

    const handleAdjustmentValue = (e, option) => {
        if(option == "byDays"){
            setAdjustmentByAmount(false);
            setAdjustmentDay(e.target.value);
        }

        else if(option == "byAmount"){
            setAdjustmentByAmount(true);
            setAdjustmentAmount(e.target.value);
        }
    }
    const getFormatedPayment = (payment_period)=>{
        const formatedPayment = {
            "CATORCENAL": 'Catorcenal',
            "QUINCENAL": 'Quincenal',
            "QUINCENAL MENSUAL":'Quincenal-Mensual',
            "MENSUAL 15": 'Mensual 15',
            "MENSUAL 30": 'Mensual 30',
            "MENSUAL 15 BAT": 'Mensual 15 BAT'
        }
        return formatedPayment[payment_period] || payment_period;
    }

    const getDaysToPay = () => {
        const type = getFormatedPayment(context.user.company.payment_period);
        const params = {
            "Catorcenal": 14,
            "Quincenal": 15,
            "Quincenal 2": 15,
            "Mensual": 30,
            "Mensual 15": 30,
            "Mensual 30": 30,
            "Quincenal-Mensual": 15,
            "Quincenal-Mensual C": 15,
            "Mensual 15 BAT": 30
        };
        
        return params[type];
    };

    return(
        <>
        {intern && <Modal className="prefacture-modals" show={showUpdateInternModal} onHide={() => setShowUpdateInternModal(!showUpdateInternModal)} centered>
            <Modal.Body>
                <Form onSubmit={updateIntern} autoComplete={"off"} id="adjustmentForm">
                    <Row>
                        <p style={{color: "black", textAlign: "left", fontSize: "15px"}}>Ajuste de {getAdjustmentString()}</p>
                    </Row>

                    <Row>
                        { valueToBeUpdated == "contractEndDate" && 
                                <Col>
                                    <Form.Group style={{marginTop: "20px"}} controlId="contractEndDate">
                                        <Form.Label>Selecciona la fecha de baja</Form.Label>
                                        <DateTimePicker name="contractEndDate" format={'DD/MM/YYYY'} editFormat={'DD/MM/YYYY'} value={contractEndDate} onCurrentDateChange={(date) => setContractEndDate(date)} time={false} />
                                    </Form.Group>  
                                </Col>
                        }
                    </Row>
                    <Row>
                        <Col>
                            {valueToBeUpdated == "scolarshipAmount"  &&
                            <Form.Group style={{marginTop: "20px"}} controlId="actual">
                                <Form.Label>Actual</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control type="text" defaultValue={intern[valueToBeUpdated] != "" ? intern[valueToBeUpdated] : "N/A"} readOnly/>
                                </InputGroup>
                            </Form.Group>}
                            
                            {valueToBeUpdated == "cost_center_id" && 
                            <Form.Group  style={{marginTop: "20px"}} controlId="actual">
                                <Form.Label>Actual</Form.Label>
                                <Form.Control type="text" defaultValue={intern.cost_center_name != "" ? intern.cost_center_name : "N/A"} readOnly/>
                            </Form.Group>}
                            
                            {valueToBeUpdated == "adjustment"  &&
                            <Form.Group style={{marginTop: "20px"}} controlId="newValueByAmount">
                                <Form.Label>Monto</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder="Ajuste"
                                        onKeyPress={ (e) => {e.charCode == 45 && e.preventDefault()} }
                                        onChange={(e) => handleAdjustmentValue(e,"byAmount")}
                                        disabled={adjustmentDay != 0}
                                    />
                                </InputGroup>
                            </Form.Group>}
                        </Col>

                        <Col>
                            {valueToBeUpdated == "scolarshipAmount" &&
                            <Form.Group style={{marginTop: "20px"}} controlId="newValue">
                                <Form.Label>Nuevo</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder="Ajuste"
                                        onKeyPress={ (e) => {e.charCode == 45 && e.preventDefault()} }
                                    />
                                </InputGroup>
                            </Form.Group>}

                            {valueToBeUpdated == "cost_center_id" && 
                            <Form.Group style={{marginTop: "20px"}} controlId="costCenter">
                                <Form.Label>Nuevo</Form.Label>
                                <Form.Control as="select">
                                    {(costCenters && costCenters.length > 0) ? costCenters.map((costCenter, index) => {
                                        return(
                                            <option key={index} value={JSON.stringify([costCenter._id, costCenter.title])}>{costCenter.title}</option>
                                        )
                                    }) : <option disabled>No hay Centro de Costos disponibles</option>}
                                </Form.Control>
                            </Form.Group>
                            }

                            {valueToBeUpdated == "adjustment" &&
                            <Form.Group style={{marginTop: "20px"}} controlId="newValueByDay">
                                <Form.Label>Días</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="/^\d+\.\d{2,2}$/"
                                    placeholder="Ajuste"
                                    onKeyPress={ (e) => {e.charCode == 45 && e.preventDefault()} }
                                    onChange={(e) => handleAdjustmentValue(e,"byDays")}
                                    disabled={adjustmentAmount != 0 }
                                />
                            </Form.Group>}
                        </Col>
                    </Row>

                    {valueToBeUpdated != "adjustment" && 
                    <Form.Group controlId="motive">
                        <Form.Label>Escribe el porqué</Form.Label>
                        <Form.Control type="text" placeholder="Escribe el porqué" required/>
                    </Form.Group>}

                    <Button className="float-left" variant="danger" onClick={() => setShowUpdateInternModal(!showUpdateInternModal)}>
                        Cancelar
                    </Button>

                    {(valueToBeUpdated != "cost_center_id" && valueToBeUpdated != "contractEndDate") &&
                    <Button className="float-right ml-2" variant="primary" onClick={() => updateIntern(null, '++')} style={{background: "green"}}>
                        Incrementar
                    </Button>}

                    {(valueToBeUpdated != "cost_center_id" && valueToBeUpdated != "contractEndDate") &&
                    <Button className="float-right ml-2" variant="primary" onClick={() => updateIntern(null, '--')} style={{background: "red"}}>
                        Decrementar
                    </Button>}

                    {(valueToBeUpdated == "cost_center_id" || valueToBeUpdated == "contractEndDate") &&
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button>}
                </Form>
            </Modal.Body>
        </Modal>
        }
        </>
    );
};
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DropdownNotification from "../../custom/DropdownNotification/DropdownNotification";

import { HiChevronDoubleRight } from "react-icons/hi";
import { decrypt } from "../../utils/crypto";
import ChatBot from '../components/ChatBot';

import gridIcon from "../../assets/IconsCaintra/menu-grid.svg";
import usersIcon from "../../assets/IconsCaintra/users.svg";
import articleIcon from "../../assets/IconsCaintra/article.svg";
import suitcaseIcon from "../../assets/IconsCaintra/suitcase.svg";
import dollarIcon from "../../assets/IconsCaintra/dollar.svg";
import noteIcon from "../../assets/IconsCaintra/note.svg";
import newspaperIcon from "../../assets/IconsCaintra/newspaper.svg";
import pencilIcon from "../../assets/IconsCaintra/pencil.svg";
import chatIcon from "../../assets/IconsCaintra/chat.svg";
import logoutIcon from "../../assets/IconsCaintra/log-out.svg";
import changeCompany from "../../assets/IconsCaintra/change-company.svg";
import shieldIcon from "../../assets/IconsCaintra/shield.svg";
import payment from "../../assets/IconsCaintra/payment.svg";
import upload from "../../assets/IconsCaintra/upload.svg";
import groupIcon from "../../assets/IconsCaintra/group_work.svg";
import amountsIcon from "../../assets/IconsCaintra/amountsIcon.svg";
import { notify } from "../../utils/notify";
import VersionNotes from "./VersionNotes";
import logo from "../../assets/logo.svg";
import logoMini from "../../assets/logo-1.svg";
import profileImg from "../../assets/IconsCaintra/defaultProfile.svg";
import gear from "../../assets/IconsCaintra/gear.svg";

export default function Sidebar({
  context,
  sidebarExtended,
  setSidebarExtended,
  setShowChangeCompany,
}) {
  const [notifications] = useState("");
  const role = context.userRole;
  const firstname = decrypt(context.userFirstName).slice(1, -1);
  let contador = 0;
 
  const [openBotUserModal, setOpenBotUserModal] = useState(false);
  const [showVersion, setShowVersion] = useState(
    localStorage.getItem("dontShowVersionNotes") == 'false' || localStorage.getItem("dontShowVersionNotes") == null
  )
  return (
    <>
      {!context.isLoading && (
        <div
          className="sidebar sidebar-display"
          style={
            sidebarExtended
              ? {
                  textAlign: "left",
                  padding: "15px",
                }
              : {
                  textAlign: "center",
                  padding: "15px 5px",
                }
          }
        >
          <div class="flex-container">
            <div className="configuration-container flex-child ">
              <img
                src={sidebarExtended ? logo : logoMini}
                className="logoCaintra"
                width={sidebarExtended ? "120px" : "50px"}
              />
            </div>
            <div className="flex-child">
            
              <span
                    className="role "
                    onClick={async () => {
                      await fetch("index.html")
                        .then(async function (response) {
                          let res = await response.text();
                          setShowVersion(!showVersion);
                          contador++;
                          if (contador > 1) {
                            contador = 0;
                            localStorage.removeItem("dontShowVersionNotes");
                            window.location.reload();
                          }
                          setTimeout(() => {
                            contador = 0;
                          }, 2000);

                          var parser = new DOMParser();
                          var doc = parser.parseFromString(res, "text/html");

                          var remoto = doc.querySelector(
                            'meta[name="description"]'
                          ).content;
                          var local = document.querySelector(
                            'meta[name="description"]'
                          ).content;
                          if (local === remoto) {
                            notify(` ${local}, Version correcta`, "success");
                          } else {
                            notify(
                              `Version incorrecta porfavor cierre sesion e inicie de nuevo o de doble click en la version para recargar la página`,
                              "error"
                            );
                          }
                        })
                        .catch(function (err) {
                          console.warn("Error.", err);
                        });
                    }}
                    class="version2"
                    style={{ textAlign: sidebarExtended ? "left" : "center" }}
                  >
                    <img
                      style={{ fontSize: sidebarExtended ? "10px" : "0" }}
                      size={8}
                    />
                    <div className="myVersion" style={{ fontSize: sidebarExtended ? "9px" : "0" }}>
                      {document.querySelector('meta[name="description"]').content}{" "}
                    </div>
                  </span>
            </div>
          </div>
          <div
            className="profile"
            style={{
              justifyContent: sidebarExtended ? "left" : "center",
              background: sidebarExtended
                ? "rgba(255, 255, 255, 0.6)"
                : "transparent",
            }}
          >
            <div
              className="img-container cont"
              style={{
                marginRight: sidebarExtended ? "8px" : "0",
                background: sidebarExtended
                  ? "transparent"
                  : "rgba(255, 255, 255, 0.6)",
              }}
            >
              <img src={profileImg} alt="Profile" width="45px" height="45px" />
            </div>
            {sidebarExtended && (
              <div className="name-container cont">
                <span className="name">{firstname}</span>
                <span className="role">{role}</span>
              </div>
            )}
          </div>

          <div
            style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}
          >
            <ul className="items">
              {context.can("view", "Dashboard") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/dashboard"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={gridIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    />
                    {sidebarExtended ? "Dashboard" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Interns") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/interns"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={usersIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    />
                    {sidebarExtended ? "Practicantes" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Prefacture") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/prefacture"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={articleIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    />
                    {sidebarExtended ? "Pre-factura" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Companies") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/companies"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={suitcaseIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    />
                    {sidebarExtended ? "Compañías" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Dispersion") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/dispersion"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={dollarIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Dispersión" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Pending") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/dispersion/pending"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={noteIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Pendientes" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Reports") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/reports"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={newspaperIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Reportes" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Universities") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/universities"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={pencilIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Universidades" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Logistics") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/logistics"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={payment}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Logística" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Groups") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/groups"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={groupIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Grupos" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Uploads") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/uploads"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={upload}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Cargas Masivas" : ""}
                  </NavLink>
                </li>
              )}
              {context.can("view", "Amounts") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/amounts"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={amountsIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Montos" : ""}
                  </NavLink>
                </li>
              )}

              { context.can("view", "suspencion") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/companies/suspension"
                    className={sidebarExtended ? "" : "mini"}
                  >
                    <img
                      src={suitcaseIcon}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    />
                    {sidebarExtended ? "Suspensión de empresas" : ""}
                  </NavLink>
                </li>
              )}
              <hr
                className="divider"
                style={{ width: sidebarExtended ? "90%" : "60%" }}
              />
              <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                <NavLink
                  to="#"
                  onClick={() => setShowChangeCompany(true)}
                  className={sidebarExtended ? "" : "mini"}
                >
                  <img
                    src={changeCompany}
                    style={{ marginRight: sidebarExtended ? "0px" : "0" }}
                    size={25}
                  />
                  {sidebarExtended ? "Cambiar compañía" : ""}
                </NavLink>
              </li>
              {context.can("view", "Configurations") && (
                <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                  <NavLink
                    exact={true}
                    activeClassName="selected"
                    to="/configurations"
                  >
                    <img
                      src={gear}
                      style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                      size={25}
                    />
                    {sidebarExtended ? "Configuración" : ""}
                  </NavLink>
                </li>
              )}
              <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                <DropdownNotification
                  items={notifications}
                  title={sidebarExtended ? "Avisos" : ""}
                  context={context.user.data}
                  sidebarExtended={sidebarExtended}
                />
              </li>
              <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                <NavLink 
                  exact={true}
                  activeClassName="selected"
                  to="/help"
                  className={sidebarExtended ? "" : "mini"}
                >
                  <img
                    src={chatIcon}
                    style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    size={25}
                  />
                  {sidebarExtended ? "Ayuda" : ""}
                </NavLink>
              </li>
              <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                <NavLink
                  exact={true}
                  activeClassName="selected"
                  to="/privacy"
                  className={sidebarExtended ? "" : "mini"}
                >
                  <img
                    src={shieldIcon}
                    style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    size={25}
                  />
                  {sidebarExtended ? "Privacidad" : ""}
                </NavLink>
              </li>
              <li style={{ textAlign: sidebarExtended ? "left" : "center" }}>
                <NavLink
                  to="/#"
                  onClick={() => {
                    context.logout();
                  }}
                  className={sidebarExtended ? "" : "mini"}
                >
                  <img
                    src={logoutIcon}
                    style={{ marginRight: sidebarExtended ? "10px" : "0" }}
                    size={25}
                  />
                  {sidebarExtended ? "Cerrar Sesión" : ""}
                </NavLink>
              </li>
            </ul>
          </div>
          <div
            id="toggle-sidebar"
            style={
              sidebarExtended
                ? {
                    justifyContent: "right",
                    left: "2300px",
                  }
                : {
                    justifyContent: "center",
                    left: "12px",
                  }
            }
            onClick={() => {
              setSidebarExtended(!sidebarExtended);
            }}
          >
            <HiChevronDoubleRight
              className={sidebarExtended ? "extended" : ""}
            />
          </div>
          {showVersion && 
            <VersionNotes
              showVersion={showVersion}
              setShowVersion={setShowVersion}
            />
          }
          <ChatBot />
       
        </div>

      )}
    </>
  );
}



import React from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
export const ConfirmDisperse = ({
    disperse,
    someoneIsAlreadyDispersed,
    selectedInterns,
    isLoading,
    setShowDisperse, 
    showDisperse
}) => {
    return (
        <Modal.Body>
            <Form onSubmit={disperse} autoComplete={"off"}>
                <p>Estás a punto de {someoneIsAlreadyDispersed ? "reprocesar" : "dispersar"} a {selectedInterns.length} practicante(s)</p>
                <small>¡Recuerda revisar la congruencia de los datos antes de continuar!</small>

                <Form.Group style={{marginTop: "20px"}} controlId="confirmation">
                    <Form.Control type="text" placeholder='Escribe "dispersar" aquí' required/>
                </Form.Group>

                <Button className="float-right ml-2" variant="primary" type="submit">
                    {!isLoading 
                    ? "Confirmar" 
                    : <Spinner animation="border" role="status">
                        </Spinner>}
                </Button> 
                
                <Button 
                    className="float-right"
                    variant="danger" 
                    onClick={() => setShowDisperse(!showDisperse)}>
                    Cancelar
                </Button>             
            </Form>
        </Modal.Body>
    )
}

import Methods from "../../../utils/https"

export const getDisabledCompanies = async ({
    search, 
    pageLimit,
    onlyDisabledCompanies,
    onlyEnabledCompanies,
    companiesLimit = 200,
    companiesToNotify = []
}) => {

    let params = {
        companiesLimit: companiesLimit, 
        searchParams: [],
        exclusiveStartKey: ''
    }

    if (search) {
        params.searchParams.push({
            key: "business_name",
            type: "contains",
            value: search.toUpperCase()
        });
    }

    if (onlyDisabledCompanies) {
        params.searchParams.push({
            key: "isDisabled",
            type: "condition",
            value: onlyDisabledCompanies
        });
    }

    if (onlyEnabledCompanies) {
        params.searchParams.push({
            key: "isDisabled",
            type: "exist"
        });

        params.searchParams.push({
            key: "isDisabled",
            type: "condition",
            value: onlyDisabledCompanies
        });
    }

    let allCompanies = await Methods.getDisabledCompanies(params);

    if(companiesToNotify){
        setCheckedCompanies(companiesToNotify, allCompanies)
    }

    let companiesByPage = getChunkArrays([...allCompanies.companies], pageLimit = 10);

    return {
        allCompanies, 
        companiesByPage
    };

}

const setCheckedCompanies = (selectedCompanies, allCompanies) => {
    if(!allCompanies.companies) return;
    
    for(let selectedCompany of selectedCompanies){
        let company = allCompanies.companies.find(company => company._id == selectedCompany._id);
        if(company){
            company.notifyCheck = true;
        }
    }
}

const getChunkArrays = (array, size) => {
    let chunks = [];
    while (array.length){
        chunks.push(array.splice(0, size));
    }
    return chunks;
}
import { useEffect, useState } from 'react'
import { isOrdinaryDispersionValid } from '../helpers';
import { Dropdown } from 'react-bootstrap';

export const DispersionTypeDropdown = ({
    payDate,
    setDispersionTypeSelected,
    dispersionTypeSelected
}) => {

    const [validDispersionsType, setValidDispersionType] = useState();
    const ALLTYPES = ['Ordinario', 'Liberado', 'Extratemporaneo', 'Ordinaria catorcenal', 'Liberada catorcenal', 'Extratemporanea catorcenal']

    useEffect(() => {
        verifyValidDispersions();
    }, [payDate]);

    const verifyValidDispersions = () => {
        let validTypes = [];
        ALLTYPES.map((type) => {
            if(isOrdinaryDispersionValid(payDate, type)) validTypes.push(type)
        });
        setValidDispersionType(validTypes)
    }

    return (
        <Dropdown drop={"down"} className='dropdown-options'>
            <Dropdown.Toggle> { dispersionTypeSelected || 'Tipo de dispersión' } </Dropdown.Toggle>
            <Dropdown.Menu className="filters-menu options-search-container">
                {   validDispersionsType?.map((type, index) => {
                        return <Dropdown.Item 
                            key={type} 
                            active={type == dispersionTypeSelected} 
                            onSelect={async () => {setDispersionTypeSelected(type)}}
                        >
                            {type}
                        </Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Image, Form, Button, Modal, InputGroup} from 'react-bootstrap';
import LogoCaintra from '../../assets/logo_caintra.png';
import InternsLogo from '../../assets/logo_interns.png';
import StepProgressBar from 'react-step-progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faCheckCircle, faArrowLeft, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {notify} from '../../utils/notify';
import {useParams, useHistory} from 'react-router-dom';
import {encrypt, decrypt} from '../../utils/crypto';
import {Auth} from 'aws-amplify';
import './RegisterCompany.css';
import Methods from '../../utils/https';
import DefaultRoles from '../Companies/components/DefaultRoles'
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';


const axios = require('axios');

export default function RegisterCompany () {

    const history = useHistory();
    const [showSucessModal, setShowSuccessModal] = useState(false);  
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [adminUsers, setAdminUsers] = useState([]);  
    const [user_id, setUser_id] = useState();
    const [formData, setFormData] = useState({
        _id: "",
        user_id: "",
        business_name: "",
        corporative: "",
        line_of_business: "",
        ext_number: "",
        int_number: "",
        municipality: "",
        zip_code: "",
        _type: "Física",
        commercial_reason: "",
        social_reason: "",
        rfc: "",
        street: "",
        suburb: "",
        state: "",
        interns_medical_insurance: "Sí",
        insurance_payment: "Empresa",
        internship_bank: "Ninguno",
        payment_period: "Catorcenal",
        additional_data_prompt: "No",
        additional_data: "",
        legal_rep_fullname: "",
        legal_rep_phone: "",
        legal_rep_email: "",
        legal_rep_position: "",
        legal_rep_department: "",
        org_admin_fullname: "",
        org_admin_phone: "",
        org_admin_position: "",
        org_admin_email: "",
        org_admin_department: "",
        billing_contact_fullname: "",
        billing_contact_phone: "",
        billing_contact_email: "",
        billing_contact_position: "",
        billing_contact_department: "",
        isValidated: false,
        showErrors: false
    });

    const [dataFormToVerify, setdataFormToVerify] = useState({
        verifyData: false,
        verifyContactsInfo: false,
        isValidatedStepOne: false,
        isEmailLegal: false,
        isEmailAdmin: false,
        isEmailBilling: false,
        isPhoneLegalRep: false,
        isPhoneAdmin: false,
        isPhoneBilling: false,
        isRfcUser: "",
        isRfcUserValidate: false,
        isAditionalData: false

    });

    const [factureAux, setFactureAux ] = useState({
        currentFacturePaymentType: undefined,
        currentCFDI: undefined,
        currentFacturePaymentMethod: undefined,
        currentFactureAgent: undefined,
        defaultValueFacturePayment: undefined
    })
    let optionsAux = []

    const [factureAditionalOptions, setFactureAditionalOptions] = useState([]) 

    useEffect(() => {
        getAdmins()
    }, []);

    const getAdmins = async () => {
        try{
            let users = await Methods.getCaintraAdminUsers();
            let usersEmails = [];
            if(users){
                for (let index = 0; index < users.length; index++) {
                    usersEmails.push(users[index].email);
                }
                setAdminUsers(usersEmails);
            }

        } catch(e){
            console.log(e);
            e.response && notify(e.response.data.message, "error");
        }
    };

    const updateKeyVerify = (keyToBeUpdatedV, valueToBeUpdatedV) => {
        let _dataVerify = dataFormToVerify;

        for(let key in _dataVerify){
            if(key === keyToBeUpdatedV){
                dataFormToVerify[key] = valueToBeUpdatedV;
            }
        }
        setdataFormToVerify(_dataVerify);
    }

    const updateKey = (keyToBeUpdated, valueToBeUpdated) => {
        let _data = formData;

        for(let key in _data){
            if(key === keyToBeUpdated){
                formData[key] = valueToBeUpdated;
            };
        };
        setFormData(_data);
    };
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
    const rfcVerificator =  new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/);
    const verifyEmail = async (dataEmail, verifyEmail) =>{
        if(dataEmail.length > 0){
            if(regexEmail.test(dataEmail)){
                updateKeyVerify(verifyEmail, false);
  
            }else{ 
                updateKeyVerify(verifyEmail, true);
            }
        } else {
            updateKeyVerify(verifyEmail, false)
        }
    };
    const verifyPhone = async (dataPhone, verifyPhone) => {
        if(dataPhone.length > 0){
            if(dataPhone.length == 10){
                updateKeyVerify(verifyPhone, false);
            }else{
                updateKeyVerify(verifyPhone, true);
            }
        } else {
            updateKeyVerify(verifyPhone, false);
        }
    };
    const verifyAditionData = async (data, verifyDataKey) =>{
        if(formData.additional_data_prompt == "Sí" && data == ""){
            updateKeyVerify(verifyDataKey, true);
        } else {
            updateKeyVerify(verifyDataKey, false);
        }
    };
    const createNotification = async () => {
        const URL = process.env.REACT_APP_SOCKET_URL;
        const notification = {
            name: "Aprobación de empresas",
            user: "Administrador Caintra",
            description:`La empresa "${formData.business_name}" está en espera de aprobación`,
            type: " ",
            date: new Date()
        };
        try{
            
            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });

        } catch (error) {
        };
    };
    const onFormSubmit = async () => {
        let _errors = 0;

        for(let key in formData){
            if(formData[key] === ""
                && key != "int_number"
                && key != "additional_data"
                && key != "_id"
                && key != "user_id"
            ) {  _errors++  }

            if (formData.additional_data_prompt == "Sí" && formData.additional_data == "") {
                _errors++;
            }
        };

        if(_errors == 0){
            if(formData.isValidated == false){
                notify("Todavía no registras un usuario", "error")
                return
            }

            if(formData.rfc == "" || formData.rfc == undefined){
                notify("La empresa requiere de un RFC para ser creada", "error")
                return;
            }

            if(formData._type == "Física"){
                if(formData.rfc.length != 13){
                    notify("El RFC para personas físicas debe ser de 13 caracteres", "error");
                    return;
                };
            } else {
                if(formData.rfc.length != 12){
                    notify("El RFC para personas morales debe ser de 12 caracteres", "error");
                    return;
                };
            };
            verifyAditionData(formData.additional_data, "isAditionalData");
            if(dataFormToVerify.isAditionalData){
                notify("No hay ningún dato adicional para la factura" , "error");
                return;
            }
            verifyEmail(formData.legal_rep_email, "isEmailLegal");
            verifyEmail(formData.org_admin_email, "isEmailAdmin" );
            verifyEmail(formData.billing_contact_email, "isEmailBilling");
            if(dataFormToVerify.isEmailLegal){
                notify("El correo ingresado en representante legal no es válido" , "error");
                return;
            }
            if(dataFormToVerify.isEmailAdmin){
                notify("El correo ingresado en contacto principal de la empresa no es válido" , "error");
                return;
            }
            if(dataFormToVerify.isEmailBilling){
                notify("El correo ingresado en contacto para pago de factura no es válido" , "error");
                return;
            }
            verifyPhone(formData.legal_rep_phone, "isPhoneLegalRep");
            verifyPhone(formData.org_admin_phone, "isPhoneAdmin" );
            verifyPhone(formData.billing_contact_phone, "isPhoneBilling");
            if(dataFormToVerify.isPhoneLegalRep){
                notify("El número de teléfono ingresado en representante legal debe tener 10 dígitos" , "error");
                return;
            }
            if(dataFormToVerify.isPhoneAdmin){
                notify("El número de teléfono ingresado en contacto principal de la empresa debe tener 10 dígitos" , "error");
                return;
            }
            if(dataFormToVerify.isPhoneBilling){
                notify("El número de teléfono ingresado en contacto para pago de factura debe tener 10 dígitos" , "error");
                return;
            }

            let users = await Methods.getCaintraAdminUsers();
            let usersEmails = [];
            let emailParams;
            if(users){
                for (let index of users) {
                    usersEmails.push(index.email);
                }
    
                emailParams = {
                    to: usersEmails,
                    subject: `Creación de compañia ${formData.business_name}`,
                    text: `La compañía ${formData.business_name} fue creada, esta en espera de su aprobación.`
                };
            }

            const data = {
                attributes: [
                    {
                        updateKey: "processFinished",
                        updateValue: true
                    }
                ],
                _id: formData._id || "None",
            };

            const userData = {
                attributes: [
                    {
                        updateKey: "companies", 
                        updateValue: [formData._id]
                    }
                ], 
                _id: user_id
            };
            
            const response = await Methods.startCompanyProcessFromRegister(data, userData);
            if(response !== false && users){
                await Methods.notifyCompanyCreation(emailParams);
            }
            createNotification();
            history.push("/");

        } else {
            if(formData.isValidated == false){
                notify("Todavía no registras un usuario", "error")
            }
            notify("Todavía no llenas todos los datos del registro, es imposible iniciar el proceso.", "error");
            updateKey("showErrors", true);
            return;
        };
    };

    const handleSaveData = async () => {

        let data = {
            attributes: [],
            _id: formData._id || "None"
        };

        for(let key in formData){

            if((key == "state" || key == "_type" || key == "number") && formData[key] != "" && key != "_id" && key != "user_id"){
                if( key === "number"){
                    data.attributes.push({
                        updateKey: `_${key}`, updateValue: formData[key], expressionAttributeName: `#_${key}`
                    });
                } else if(key === "type" ){
                    data.attributes.push({
                        updateKey: `${key}`, updateValue: formData[key], expressionAttributeName: `#_${key}`
                    });
                } else {
                    data.attributes.push({
                        updateKey: key, updateValue: formData[key], expressionAttributeName: `#${key}`
                    });
                }
                
            } else if(formData[key] != "" && key != "_id" && key != "user_id"){
                data.attributes.push({updateKey: key, updateValue: formData[key]});
            };
        }; 

        if(data.attributes.length == 0){
            return;
        };

        Methods.updateCompany(data);

    };



    return(
        <div className="register-company">
            <Row>
                <Col md={8}>
                    <a style={{cursor: "pointer"}} onClick={() => setShowConfirmModal(true)} className="back-button"><FontAwesomeIcon icon={faArrowLeft} /></a>
                    <StepProgressBar
                        startingStep={0}
                        contentClass="content-class"
                        buttonWrapperClass="buttonsClass"
                        nextBtnName="Siguiente"
                        previousBtnName="Anterior"
                        primaryBtnClass="next-button"
                        secondaryBtnClass="previous-button"
                        submitBtnName="Finalizar"
                        onSubmit={onFormSubmit}
                        labelClass="progress-labels"
                        stepClass="step-class"
                        steps={[
                            {
                                label: "Creación de Usuario",
                                name: "step 0",
                                content: <Step0 setUser_id={setUser_id} updateKey={updateKey} formData={formData} setShowSuccessModal={setShowSuccessModal} updateKeyVerify={updateKeyVerify} setFormData={setFormData} dataFormToVerify={dataFormToVerify}/>,

                            },
                            {
                                label: 'Datos generales',
                                name: 'step 1',
                                content: <Step1 updateKey={updateKey} formData={formData} updateKeyVerify={updateKeyVerify} dataFormToVerify={dataFormToVerify}/>,
                                validator: handleSaveData
                            },
                            {
                                label: 'Características del Servicio',
                                name: 'step 2',
                                content: <Step2 updateKey={updateKey} formData={formData} factureAditionalOptions={factureAditionalOptions} factureAux={factureAux} setFactureAux={setFactureAux} setFactureAditionalOptions={optionsAux} setFormData={setFormData}/>,
                                validator: handleSaveData
                            },
                            {
                                label: 'Contactos de Operación',
                                name: 'step 3',
                                content: <Step3 updateKey={updateKey} formData={formData} updateKeyVerify={updateKeyVerify} dataFormToVerify={dataFormToVerify}/>,
                                validator: handleSaveData
                            },
                            {
                                label: 'Entrega de Papelería',
                                name: 'step 4',
                                content: <Step4 updateKey={updateKey} formData={formData}/>,
                                validator: () => formData.isValidated
                            }
                        ]}
                    />

                </Col>

                <Col md={4}>
                    <div style={{height: "100%", width: "90%"}} className="background-container-register">
                        <Row className="logo-caintra-container w-100">
                            <Image src={LogoCaintra} className="logoCaintra"/>
                        </Row>
                        <Row className="description-container">
                            <h5>CAINTRA</h5>                            
                            <h2>Servicio para Empresas</h2>
                            <h4>CAINTRA es el organismo empresarial que representa a los industriales de Nuevo León. Promueve y brinda servicios que fomentan las mejores condiciones para elevar la competitividad de sus socios.</h4>
                        </Row>
                    </div>
                    
                </Col>
            </Row>

            <SuccessModal showSuccessModal={showSucessModal}/>

            <Modal className="company-modals" show={showConfirmModal} centered>
                <Modal.Body>
                    <p style={{"align-text": "red"}}>¡Estás a punto de salir de esta pantalla!</p>
                    <small>¿Estás seguro de que deseas abandonar el registro?</small>
                    <br/> <br/>

                    <Button className="float-left" variant="danger" onClick={() => setShowConfirmModal(false)}>
                        Permanecer aqui
                    </Button>

                    <Button className="float-right ml-2" variant="primary" href="/login">
                        Si, deseo salir
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export function Step0 ({setUser_id, updateKey, formData, setShowSuccessModal, updateKeyVerify, dataFormToVerify}){

    const URL = process.env.REACT_APP_SOCKET_URL;
    const key = useParams().id;
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [emailToValidate, setEmailToValidate] = useState(undefined);
    const [userId, setUserId] = useState(undefined);
    const [isVerified, setIsVerified] = useState(false);
    const history = useHistory();
    const [errorLabelUser, setErrorLabelRfcUser] = useState("");
    const [isErrorRfc, setErrorRfcUser] = useState(false);
    const [seePass, setSeePass] = useState(false);
    const [seeConfirmPass, setConfirmPass] = useState(false);

    useEffect(() => {
        key && getCompanyData();
    }, []);

    const createDefaultRoles = async (companyId) => {
        const adminRoles = {key:"ADMINISTRADOR", value: DefaultRoles.AdminRole()};
        const groupRoles = {key:"GRUPO", value: DefaultRoles.GroupRole()};
        const companyRoles = {key:"EMPRESA", value: DefaultRoles.CompanyRole()};
        const corporativeRoles =  {key:"CORPORATIVO", value: DefaultRoles.CorporativeRole()}
        const rolesArray = [adminRoles, groupRoles, companyRoles, corporativeRoles]

        for(let index of rolesArray){
            let data = {
                companyId: companyId,
                label: index.key,
                description: `Roles predeterminados para ${index.key}` ,
                _permissions: index.value,
                isAdminRole: index.key == "ADMINISTRADOR"
            }
            await Methods.createRole(data, false);
        }
    }

    const signup = async (userData) => {

        try{
            if(formData.rfc == "")
            {
                formData.rfc = " ";
            }
            const companies = await axios({
                url: `${URL}/companies`,
                method: "POST",
                data: encrypt(formData)
            });

            userData.currentCompanyId = companies.data._id;
            createDefaultRoles(companies.data._id)

            const res = await axios({
                url: `${URL}/users/admin`,
                method: "POST",
                data: encrypt(userData)
            });
            
            await Auth.signUp({
                username: userData.email,
                password: userData.password,
                attributes:{
                    email:userData.email,
                }
            });
            updateKey("_id", companies.data._id);
            updateKey("user_id", res.data._id);
            setUser_id(res.data._id);
            setUserId(res.data._id);
            setIsRegistered(true);
            setEmailToValidate(userData.email);
            notify("Usuario creado exitosamente, ya puedes continuar", "success");
        } catch (e) {
            console.log(e);
            switch (e._name) {
                case "UsernameExistsException":
                        notify("Este correo ya ha sido registrado", "error");
                    break;
                default:
                    e.response && notify(e.response.data.message, "error");
                    break;
            };
        };
    };

    const validateUserData = (e) => {
        e.preventDefault();

        let _errors = 0;
        const passwordRegex = new RegExp (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\w<>{}()\[\]'"+\-=*/\\,.;:!@#$%^&¡!¿?~`|]{8,24}$/);
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);

        const user = {
            fullName: e.target.elements.firstname.value + " " +e.target.elements.father_lastname.value + " " + e.target.elements.mother_lastname.value,
            email: e.target.elements.email.value,
            password: e.target.elements.password.value,
            confirmpassword: e.target.elements.confirmpassword.value,
            userRfc: e.target.elements.rfc.value,
            role: "Administrador de Empresa"
        };
        
        if(!rfcVerificator.test(dataFormToVerify.isRfcUser)){
            _errors++;
            notify("El RFC ingresado tiene un formato incorrecto", "error");
        }
        
        if(!passwordRegex.test(user.password)){
            _errors++;
            notify("Tu contraseña debe tener al menos 8 caracteres, una letra en mayúscula, una letra en minúscula y un número", "error");
        };

        if(user.password !== user.confirmpassword){
            _errors++;
            notify("Las contraseñas no coinciden", "error");
        }

        if(!emailRegex.test(user.email)){
            _errors++;
            notify("El correo no es válido", "error");
        };

        _errors == 0 && signup(user);

    };

    const getCompanyData = async () => {
        try{
            setIsLoading(true);

            const [company] = await Methods.getCompanies([key]);

            for(let _key in company){
                updateKey(_key, company[_key]);
            };

            if(company.isApproved){
                notify("Esta compañía ya ha sido aprobada con anterioridad", "error");
                history.push("/");
                return;
            };

            if(company.processFinished){
                setShowSuccessModal(true);

                setTimeout(() => {
                    setShowSuccessModal(false);
                }, 3500);
            };

            const user = await Methods.getUsersByCompany(company._id);    

            updateKey("user_id", user[0]._id);
            setUser_id(user[0]._id);
            updateKey("isValidated", true);
            setIsRegistered(true);
            setIsVerified(true);
            setIsLoading(false);
        } catch(e) {
            console.log(e);
            e.response && notify(e.response.data.message, "error");
        };
    };

    const handleConfirmationSubmit = async (event) => {
        event.preventDefault();
        try {
            await Auth.confirmSignUp(emailToValidate, event.target.elements.confirmationCode.value);
            setIsVerified(true);
            setIsRegistered(true);
            updateKey("isValidated", true);
            notify("Tu cuenta se ha verificado exitosamente", "success");
        } catch (e) {
            switch (e.code) {
                case "LimitExceededException":
                notify('Excediste el número de intentos permitidos. Intenta en unos minutos', "error");
                    break;
                default:
                    notify('El token no es válido. Intenta nuevamente', "error");
                    break;
            };
        };
    };



    const rfcVerificator =  new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/);
    const verifyRfcUser = async (rfcUserData) => {
        if(rfcUserData.length > 0){
                if(rfcUserData.length == 13 || rfcUserData.length == 12 ){
                    if(!rfcVerificator.test(rfcUserData)){
                        setErrorRfcUser(true);
                        setErrorLabelRfcUser("El formarto del RFC ingresado es incorrecto");
                    } else {
                        setErrorRfcUser(false);
                        setErrorLabelRfcUser("");
                    }
                } else {
                    setErrorRfcUser(true);
                    setErrorLabelRfcUser("El formarto del RFC ingresado es incorrecto");
                }
        } else {
            setErrorRfcUser(false);
            setErrorLabelRfcUser("");
        }
    }

    const getSeePass = () => 
    !seePass ? 
        <IoEyeOffOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setSeePass(!seePass) }} class="input-icon see-password"/>;


    const getConfirmPass = () =>
    !seeConfirmPass ?
        <IoEyeOffOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>
    : 
        <IoEyeOutline onClick={() => { setConfirmPass(!seeConfirmPass) }} class="input-icon see-password"/>;

    return(
        <Col>
            {(!isRegistered && !isVerified && !isLoading && formData.user_id == "") && 
            <Form onSubmit={validateUserData}>
                <Row>
                    <Col>
                        <p>Creación de usuario {(formData.showErrors == true && formData.isValidated == false)
                            && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="firstname">
                            <Form.Label>Nombre(s)</Form.Label>
                            <Form.Control type="text" placeholder="Introduce tu nombre" />
                        </Form.Group>
                        <Form.Group controlId="father_lastname">
                            <Form.Label>Apellido paterno</Form.Label>
                            <Form.Control type="text" placeholder="Introduce tu primer apellido" />
                        </Form.Group>
                        <Form.Group controlId="mother_lastname">
                            <Form.Label>Apellidos materno</Form.Label>
                            <Form.Control type="text" placeholder="Introduce tu segundo apellido" />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Correo electrónico</Form.Label>
                            <Form.Control type="text" placeholder="Introduce tu correo electrónico" />
                        </Form.Group>
                        <Form.Group controlId="rfc">
                            <Form.Label>RFC</Form.Label>
                            <Form.Control onChange={(e) => {updateKeyVerify("isRfcUser", e.target.value); verifyRfcUser(dataFormToVerify.isRfcUser);}} type="text" placeholder="Introduce tu clave única" />
                            { isErrorRfc && <Form.Label className="error-label" > {errorLabelUser} </Form.Label>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="password">
                            <Form.Label>Contraseña</Form.Label>
                            <InputGroup>
                                <Form.Control type={!seePass ? "password" : "text"} placeholder="Introduce tu contraseña" />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {getSeePass()}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
   
                        <Form.Group controlId="confirmpassword">
                            <Form.Label>Confirmar contraseña</Form.Label>
                            <InputGroup>
                                <Form.Control type={!seeConfirmPass ? "password" : "text"} placeholder="Confirma tu contraseña" />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {getConfirmPass()}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                            </InputGroup>

                        </Form.Group>
                    </Col>
                    
                </Row>

                <Button href="../Register/components/RegisterUser.jsx" className="float-right next-button" variant="primary">Cancelar</Button>
                <Button className="float-right next-button" type="submit" variant="primary" style={{marginRight: "20px"}}>Registrar usuario</Button>
            </Form>
            }

            {((formData.isValidated || isVerified) && !isLoading) &&
                <div style={{width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", margin: "100px"}}>
                        <Image src={InternsLogo} style={{margin: "auto", width: "500px", height: "auto", padding: "25px"}}/>
                        <h3 style={{margin: "auto", color: "#1B3769", fontWeight: "bold"}}>Ya tienes un usuario, ¡continúa con el registro!</h3>
                    </div>
                </div>
            }

            {(!isVerified && emailToValidate) && 
                <Form style={{height: "20vh"}} onSubmit={handleConfirmationSubmit}>
                    <Form.Group controlId="confirmationCode">
                        <Form.Label>
                            Validar e-mail 
                            {(formData.showErrors == true && formData.isValidated == false)
                            && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}
                        </Form.Label>
                        <Form.Control type="text" placeholder="Introduce el token que enviamos a tu correo electrónico" />
                    </Form.Group>
                
                    <Button className="float-right next-button" variant="primary" type="submit">
                        Validar E-mail
                    </Button>
                </Form>
            }
        </Col>
    );
};

export function Step1 ({updateKey, formData, updateKeyVerify}) {
    const [errorRfc, setErrorRfc] = useState(false);
    const [labelErrorRFC, setLabelRFC] = useState("");
    const [currentSuburbs, setCurrentSuburbs] = useState([]);
    const [currentMunicipality, setCurrentMunicipality] = useState(formData.municipality);
    const [currentState, setCurrentState] = useState(formData.state);
    const getDataByZipCode = async (currentCP) => {
        let api = (await Methods.getApiData("sepomex"))[0];
        api.apiKey = JSON.parse(decrypt(api.apiKey))
        try {
             let response = await axios({
                url: `${api.apiLink}${currentCP}?token=${api.apiKey}`,
                method: "GET",
             });
             await setZipCodeData(response.data);
         } catch (e) {
             e.data && notify(e.data.response.message, "error");
         };
     }
 
     const setZipCodeData = async (data) => {
        let suburb = []; 
        const municipality = data[0].response.municipio;
        const state = data[0].response.estado;
        updateKey("municipality", municipality);
        updateKey("state", state);
        setCurrentState(state);
        setCurrentMunicipality(municipality);
        await data.forEach(element =>{
            const data = element.response.asentamiento;
            suburb.push(data);
        });
        suburb.sort();
        setCurrentSuburbs(suburb);
    }

    const verifyRfc = async () => {
        const rfcVerificator =  new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/);
        const rfc = formData.rfc.toString()
        if(rfc == "XAXX010101000" || rfc == "XEXX010101000"){
            if (formData._type == "Moral") {
                setErrorRfc(false);
                setLabelRFC("");
                updateKeyVerify("verifyData", true);
            } else {
                setErrorRfc(true);
                setLabelRFC("El RFC genérico solo aplica para personas morales");
            }
        } else if(rfc.length > 0){
            if(formData._type == "Física"){
                if(rfc.length == 13){
                    if(!rfcVerificator.test(formData.rfc)){
                        setErrorRfc(true);
                        setLabelRFC("El formarto del RFC ingresado es incorrecto");
                    
                    } else {
                        setErrorRfc(false);
                        setLabelRFC("");       
                    }
                } else {
                    setErrorRfc(true);
                    setLabelRFC("El RFC para personas físicas debe ser de 13 caracteres");
                }
            } else {
                if(rfc.length == 12 ){
                    if(!rfcVerificator.test(formData.rfc)){
                        setErrorRfc(true);
                        setLabelRFC("El formarto del RFC ingresado es incorrecto");
                        updateKeyVerify("verifyData", false) 
                    } else {
                        setErrorRfc(false);
                        setLabelRFC("");
                        updateKeyVerify("verifyData", true) 
                    }
                } else {
                    setErrorRfc(true);
                    setLabelRFC("El RFC para personas morales debe ser de 12 caracteres");
                    updateKeyVerify("verifyData", false) 
                }
            }  
        } else {
            updateKey("verifyData", false); 
        }
    };
    return(
        <Col>
            <Form>
                <Row>
                    <Col>
                        <p>Datos Generales</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="business_name">
                            <Form.Label>Nombre de la Empresa {(formData.showErrors == true && formData.business_name == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Introduce el nombre de la empresa"
                                onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    updateKey(e.target.id, e.target.value)
                                }}
                                defaultValue={formData.business_name}
                            />
                        </Form.Group>
                        <Form.Group controlId="social_reason">
                            <Form.Label>División comercial {(formData.showErrors == true && formData.social_reason == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce la división comercial" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.social_reason}/>
                        </Form.Group>
   
                        <Form.Group controlId="corporative">
                            <Form.Label>Corporativo {(formData.showErrors == true && formData.corporative == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Corporativo" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.corporative}/>
                        </Form.Group>

                        <Form.Group controlId="line_of_business">
                            <Form.Label>Giro {(formData.showErrors == true && formData.line_of_business == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el giro" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.line_of_business}/>
                        </Form.Group>

                        <Form.Group controlId="street">
                            <Form.Label>Calle {(formData.showErrors == true && formData.street == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce la calle" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.street}/>
                        </Form.Group>

                        <Form.Group controlId="int_number">
                            <Form.Label>Número Interior</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el número interior de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.int_number} />
                        </Form.Group>

                        <Form.Group controlId="municipality">
                            <Form.Label>Municipio/Delegación {(formData.showErrors == true && formData.municipality == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Municipio o delegación de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={currentMunicipality}/>
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="_type">
                            <Form.Label>
                                Tipo
                            </Form.Label>
                            <Form.Control as="select" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyRfc();}}>
                                <option selected={formData._type == "Física"}>Física</option>
                                <option selected={formData._type == "Moral"}>Moral</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="commercial_reason">
                            <Form.Label>Nombre Comercial {(formData.showErrors == true && formData.commercial_reason == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Nombre comercial de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.commercial_reason}/>
                        </Form.Group>

                        <Form.Group controlId="rfc">
                            <Form.Label>RFC {(formData.showErrors == true && formData.rfc == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce la clave única" onChange={(e) => { updateKey(e.target.id, e.target.value.toUpperCase()); verifyRfc(); }} defaultValue={formData.rfc} />
                            { errorRfc && <Form.Label className="error-label" > {labelErrorRFC} </Form.Label>
                            }
                        </Form.Group>

                        <Form.Group controlId="payment_period">
                            <Form.Label>
                                Periodo de dispersión del practicante
                            </Form.Label>
                            <Form.Control as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                <option selected={formData.payment_period == "Catorcenal"}>Catorcenal</option>
                                <option selected={formData.payment_period == "Quincenal"}>Quincenal</option>
                                <option selected={formData.payment_period == "Quincenal-Mensual"}>Quincenal-Mensual</option>
                                <option selected={formData.payment_period == "Quincenal-Mensual C"}>Quincenal-Mensual C</option>
                                <option selected={formData.payment_period == "Quincenal 2"}>Quincenal 2</option>
                                <option selected={formData.payment_period == "Mensual 15"}>Mensual 15</option>
                                <option selected={formData.payment_period == "Mensual 30"}>Mensual 30</option>
                                <option selected={formData.payment_period == "Mensual 15 BAT"}>Mensual 15 BAT</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="ext_number">
                            <Form.Label>Número Exterior {(formData.showErrors == true && formData.ext_number == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el número exterior de la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.ext_number} />
                        </Form.Group>

                        <Form.Group controlId="zip_code">
                            <Form.Label>Código Postal {(formData.showErrors == true && formData.zip_code == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="number" placeholder="Código postal de la empresa"
                            onInput = {async (e) =>{
                                e.target.value = (0,e.target.value).toString().slice(0,5)
                                if(e.target.value.length == 5){
                                    await getDataByZipCode(e.target.value);
                                }
                            }}
                            onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.zip_code} />
                        </Form.Group>

                        
                        <Form.Group controlId="suburb">
                            <Form.Label>Colonia {(formData.showErrors == true && formData.suburb == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control as="select" placeholder="Introduce el nombre de la colonia" onChange={(e) => updateKey(e.target.id, e.target.value)} >
                            <option value="" disabled selected hidden>{formData.suburb}</option>
                                {currentSuburbs.map( element =>{
                                    return(
                                        <option>{element}</option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="state">
                            <Form.Label>Estado {(formData.showErrors == true && formData.state == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Estado donde se ubica la empresa" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={currentState} autoComplete={"off"} autocomplete="nope"/>
                        </Form.Group>

                    </Col>
                </Row>
            </Form>
        </Col>
    );
};

export function Step2 ({updateKey, formData, factureAux, setFactureAux, setFormData}) {
    const [showAgent, setShowAgent] = useState(false);
    const [labelAgent, setLabelAgent] = useState("");
    const {currentFacturePaymentType, currentCFDI, currentFacturePaymentMethod, currentFactureAgent} = factureAux;
    const [factureAditionalOptions, setFactureAditionalOptions] = useState([]);
    useEffect(async()=>{
        await setData();
    },[])

    const setData = async () => {
        
        let factureAditionalOptions = await Methods.getPrefactureOptions();
        setFactureAditionalOptions(factureAditionalOptions);
        if(factureAditionalOptions.length){
            setFactureAux({
                currentFacturePaymentType: formData.facturePaymentType ? setFacturesValues(formData.facturePaymentType, factureAditionalOptions) : setDefaulFactureOptionValue('facturePaymentType', factureAditionalOptions, formData),
                currentCFDI: formData.factureCFDI ? setFacturesValues(formData.factureCFDI, factureAditionalOptions) : setDefaulFactureOptionValue('factureCFDI', factureAditionalOptions, formData),
                currentFacturePaymentMethod: formData.facturePaymentMethod ? setFacturesValues(formData.facturePaymentMethod, factureAditionalOptions) : setDefaulFactureOptionValue('facturePaymentMethod', factureAditionalOptions, formData),
                currentFactureAgent: formData.factureAgent ? setFacturesValues(formData.factureAgent, factureAditionalOptions) : setDefaulFactureOptionValue('factureAgent', factureAditionalOptions, formData)
            });
            setFormData({
                ...formData,
                facturePaymentType: formData.facturePaymentType || setDefaulFactureOptionValue('facturePaymentType', factureAditionalOptions, formData, true),
                factureCFDI: formData.factureCFDI || setDefaulFactureOptionValue('factureCFDI', factureAditionalOptions, formData, true),
                facturePaymentMethod: formData.factureCFDI || setDefaulFactureOptionValue('facturePaymentMethod', factureAditionalOptions, formData, true),
                factureAgent:  formData.factureAgent || setDefaulFactureOptionValue('factureAgent', factureAditionalOptions, formData, true)
            });
        }
    }

    const setFacturesValues = (valueId, factureAditionalOptions) => {
        return valueId ? factureAditionalOptions.find(option => option._id == valueId) : undefined
    }


    const setDefaulFactureOptionValue = (type, factureAditionalOptions, currentData, returnId) => {
        for(let option of factureAditionalOptions){
            if(option.type == type && currentData){
                currentData[type] = option._id;
                if(returnId) return option._id;
                return option;
            }
        }
        return 
    }

    const handleFactureAgent = async (e) => {
        if(e.target.value != ""){
            let response = await Methods.getContpaqAgent(e.target.value);
            if(response != null){
                setLabelAgent(response.name);
                updateKey(e.target.id, e.target.value);
            } else {
                setLabelAgent("El numero de agente no existe en Contpaq")
                updateKey(e.target.id, "_");
            }
            setShowAgent(true);
        } else {
            updateKey(e.target.id, "_");
        }
    }
    
    const [showAdditional_data_prompt, setShowAdditional_data_prompt] = useState(formData.additional_data_prompt);

    return(
        <Col>
            <Form>
                <Row>
                    <Col>
                        <p>Características del Servicio</p>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="interns_medical_insurance">
                            <Form.Label>
                                ¿Desea contratar una póliza de seguro para los practicantes?
                            </Form.Label>
                            <Form.Control as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                <option selected={formData.interns_medical_insurance == "Sí"}>Sí</option>
                                <option selected={formData.interns_medical_insurance == "No"}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="internship_bank">
                            <Form.Label>Banco para depósito de beca</Form.Label>

                            <Form.Control as="select"  onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                <option selected={formData.internship_bank == "Ninguno"}>Ninguno</option>
                                <option selected={formData.internship_bank == "Banorte"}>Banorte</option>
                                <option selected={formData.internship_bank == "Bancomer"}>Bancomer</option>
                                <option selected={formData.internship_bank == "Santander"}>Santander</option>
                                <option selected={formData.internship_bank =="Banamex"}>Banamex</option>
                                <option selected={formData.internship_bank =="HSBC"}>HSBC</option>
                                <option selected={formData.internship_bank =="Afirme"}>Afirme</option>
                                <option selected={formData.internship_bank =="Banjio"}>Banjio</option>
                                <option selected={formData.internship_bank =="Banjercito"}>Banjercito</option>
                                <option selected={formData.internship_bank =="Bancoppel"}>Bancoppel</option>
                                <option selected={formData.internship_bank =="Inbursa"}>Inbursa</option>
                                <option selected={formData.internship_bank =="Scotiabank"}>Scotiabank</option>
                                <option selected={formData.internship_bank =="Banco Azteca"}>Banco Azteca</option>
                                <option selected={formData.internship_bank =="Banregio"}>Banregio</option>
                                <option selected={formData.internship_bank =="Bancomext"}>Bancomext</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="insurance_payment">
                            <Form.Label>Cobro de Seguro</Form.Label>
                            <Form.Control as="select"  onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.insurance_payment}>
                                <option selected={formData.insurance_payment == "Empresa"}>Empresa</option>
                                <option selected={formData.insurance_payment == "Practicante"}>Practicante</option>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="additional_data_prompt">
                            <Form.Label>¿La factura requiere algún dato adicional?</Form.Label>
                            <Form.Control as="select" onChange={(e) => {
                                updateKey(e.target.id, e.target.value);
                                setShowAdditional_data_prompt(e.target.value)
                            }}>
                                <option selected={formData.additional_data_prompt == "Sí"}>Sí</option>
                                <option selected={formData.additional_data_prompt == "No"}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {showAdditional_data_prompt == "Sí" && <Col md={6} xs={12} sm={12} show={false}>
                        <Form.Group controlId="additional_data">
                            <Form.Label>¿Cuál? {(formData.showErrors == true && formData.additional_data == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el dato adicional" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.additional_data} />
                        </Form.Group>
                    </Col>}
                </Row>

                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="requireSife">
                            <Form.Label>
                                ¿Desea contratar la firma electrónica para los practicantes?
                            </Form.Label>

                            <Form.Control className="input" as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                <option disabled selected hidden> {formData.requireSife || 'No'}</option>
                                <option selected={formData.requireSife == 'Sí'}>Sí</option>
                                <option selected={formData.requireSife == 'No'}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="requirePurchaseOrder">
                            <Form.Label>
                                ¿Necesita ingresar la OC antes de enviar su factura a timbrar?
                            </Form.Label>

                            <Form.Control className="input" as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                <option disabled selected hidden> {formData.requirePurchaseOrder || 'No'}</option>
                                <option selected={formData.requirePurchaseOrder == 'Sí'}>Sí</option>
                                <option selected={formData.requirePurchaseOrder == 'No'}>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="facturePaymentType">
                            <Form.Label>
                                Forma de pago
                            </Form.Label>
                            {factureAditionalOptions.length ? 'si' : 'no'}
                            <Form.Control className="input" as="select" onChange={(e) => { updateKey(e.target.id, e.target.value); }}>
                                <option disabled selected hidden> {`${currentFacturePaymentType?.satKey - currentFacturePaymentType?.concept}` || ''}</option>
                                {factureAditionalOptions.length && factureAditionalOptions.map((element, index) => {
                                    if (element.type == 'facturePaymentType') {
                                        return (
                                            <option id={index} value={element._id} selected={formData.facturePaymentType == element._id}>{`${element.satKey} - ${element.concept}` }</option>
                                        );
                                    }
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="factureCFDI">
                            <Form.Label>Uso de CFDI</Form.Label>
                            <Form.Control className="input" as="select" onChange={(e) => updateKey(e.target.id, e.target.value)}>
                                <option disabled selected hidden> {`${currentCFDI?.satKey - currentCFDI?.concept}` || ''}</option>
                                {factureAditionalOptions.length && factureAditionalOptions.map((element, index) => {
                                    if (element.type == 'factureCFDI') {
                                        return (
                                            <option id={index} value={element._id} selected={formData.factureCFDI == element._id}>{`${element.satKey} - ${element.concept}` }</option>
                                        );
                                    }
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="facturePaymentMethod">
                            <Form.Label>
                                Método de pago
                            </Form.Label>

                            <Form.Control className="input" as="select" onChange={(e) => {updateKey(e.target.id, e.target.value);}}>
                                <option disabled selected hidden> {`${currentFacturePaymentMethod?.satKey - currentFacturePaymentMethod?.concept }` || ''}</option>
                                {factureAditionalOptions.length && factureAditionalOptions.map((element, index) =>{
                                    if(element.type == 'facturePaymentMethod'){
                                        return(
                                            <option id={index} value={element._id}  selected={formData.facturePaymentMethod == element._id}>{`${element.satKey} - ${element.concept}` }</option>
                                        );
                                    }
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={6} xs={12} sm={12}>
                        <Form.Group controlId="factureAgent">
                            <Form.Label>No de agente ejecutivo</Form.Label>
                            <Form.Control type="text" onChange={(e) => handleFactureAgent(e)} defaultValue={"_"} />
                            {
                            showAgent && <Form.Label className="agent"> {labelAgent} </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Col>
    );
};

export function Step3 ({updateKey, formData}) {
    console.log(formData);

    const [errorPhone, setErrorPhone] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhoneSec, setErrorPhoneSec] = useState(false);
    const [errorEmailSec, setErrorEmailSec] = useState(false);
    const [errorPhoneThr, setErrorPhoneThr] = useState(false);
    const [errorEmailThr, setErrorEmailThr] = useState(false);
    const [labelPhone, setLabelPhone] = useState("");
    const [labelEmail, setLabelEmail] = useState("");
    const [labelPhoneSec, setLabelPhoneSec] = useState("");
    const [labelEmailSec, setLabelEmailSec] = useState("");
    const [labelPhoneThr, setLabelPhoneThr] = useState("");
    const [labelEmailThr, setLabelEmailThr] = useState("");
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);

    const verifyDataForm = async (setError, labelError, typeError, formDataName, errorN) => {

        if(typeError == "Email"){
          if(formDataName.length > 0){
            if(!regexEmail.test(formDataName)){
                await setError(true);
                await labelError("El correo no es válido");
            } else {
                await setError(false);
                await labelError("");
            }
          } else {
            await  setError(true);
          }
        } 
        if(typeError == "Phone"){
            if(formDataName.length > 0){
                if(formDataName.length == 10){
                    await setError(false)
                    await labelError("")
                }
                if(formDataName.length < 10 && formDataName.length >= 1){
                    await setError(true);
                    await labelError("El número de teléfono debe tener 10 dígitos");
                }
            }
        }    
    };


    return(
        <Col>
            <Form>
                <Row>
                    <Col>
                        <p>Representante Legal</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="legal_rep_fullname">
                            <Form.Label>Nombre completo {(formData.showErrors == true && formData.legal_rep_fullname == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el nombre del representante legal" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.legal_rep_fullname} />
                        </Form.Group>

                        <Form.Group controlId="legal_rep_phone">
                            <Form.Label>Teléfono (10 dígitos) {(formData.showErrors == true && formData.legal_rep_phone == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="number" 
                            onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                            }}
                            placeholder="Introduce el teléfono" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorPhone, setLabelPhone, "Phone", formData.legal_rep_phone, errorPhone);  }} defaultValue={formData.legal_rep_phone} />
                            {errorPhone && <Form.Label className="error-label" > {labelPhone} </Form.Label>
                            }
                        </Form.Group>

                        <Form.Group controlId="legal_rep_email">
                            <Form.Label>Correo electrónico {(formData.showErrors == true && formData.legal_rep_email == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="email" placeholder="El correo electrónico del representante legal" onChange={(e) => { updateKey(e.target.id, e.target.value); verifyDataForm(setErrorEmail, setLabelEmail, "Email", formData.legal_rep_email, errorEmail);   }} defaultValue={formData.legal_rep_email} />
                            {errorEmail && <Form.Label className="error-label" > {labelEmail} </Form.Label>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="legal_rep_position">
                            <Form.Label>Puesto {(formData.showErrors == true && formData.legal_rep_position == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="¿Cuál es el puesto del representante legal?" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.legal_rep_position}/>
                        </Form.Group>
                        <Form.Group controlId="legal_rep_department">
                            <Form.Label>Departamento {(formData.showErrors == true && formData.legal_rep_department == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder={`¿A qué departamento pertenece?`} onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.legal_rep_department}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>Contacto principal de la empresa con CAINTRA (Manejará el sistema y se encargará de los practicantes)</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="org_admin_fullname">
                            <Form.Label>Nombre completo {(formData.showErrors == true && formData.org_admin_fullname == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Nombre del administrador" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.org_admin_fullname}/>
                        </Form.Group>
    
                        <Form.Group controlId="org_admin_phone">
                            <Form.Label>Teléfono (10 dígitos) {(formData.showErrors == true && formData.org_admin_phone == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="number" 
                            onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                            }}
                            placeholder="Introduce el teléfono del administrador" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorPhoneSec, setLabelPhoneSec, "Phone", formData.org_admin_phone, errorPhoneSec);  }} defaultValue={formData.org_admin_phone}/>
                            { errorPhoneSec && <Form.Label className="error-label" > {labelPhoneSec} </Form.Label>
                            }                        
                        </Form.Group>

                        <Form.Group controlId="org_admin_email">
                            <Form.Label>Correo electrónico {(formData.showErrors == true && formData.org_admin_email == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="email" placeholder="Introduce el correo del administrador" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorEmailSec, setLabelEmailSec, "Email", formData.org_admin_email, errorEmailSec);  }} defaultValue={formData.org_admin_email}/>
                            { errorEmailSec && <Form.Label className="error-label" > {labelEmailSec}</Form.Label>
                            }                    
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="org_admin_position">
                            <Form.Label>Puesto {(formData.showErrors == true && formData.org_admin_position == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="¿Cuál es el puesto del administrador?" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.org_admin_position}/>
                        </Form.Group>
                        <Form.Group controlId="org_admin_department">
                            <Form.Label>Departamento {(formData.showErrors == true && formData.org_admin_department == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder={`¿A qué departamento pertenece?`} onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.org_admin_department}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>Contacto para pago de factura</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="billing_contact_fullname">
                            <Form.Label>Nombre completo {(formData.showErrors == true && formData.billing_contact_fullname == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el nombre del contacto" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.billing_contact_fullname}/>
                        </Form.Group>
    
                        <Form.Group controlId="billing_contact_phone">
                            <Form.Label>Teléfono (10 dígitos) {(formData.showErrors == true && formData.billing_contact_phone == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="number"
                            onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                            }}
                            placeholder="Introduce el teléfono" onChange={(e) => {updateKey(e.target.id, e.target.value); verifyDataForm(setErrorPhoneThr, setLabelPhoneThr, "Phone", formData.billing_contact_phone, errorPhoneThr);}} defaultValue={formData.billing_contact_phone}/>
                            { errorPhoneThr && <Form.Label className="error-label" > {labelPhoneThr} </Form.Label>
                            }                       
                        </Form.Group>

                        <Form.Group controlId="billing_contact_email">
                            <Form.Label>Correo electrónico {(formData.showErrors == true && formData.billing_contact_email == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="email" placeholder="Introduce el correo del contacto" onChange={(e) => {updateKey(e.target.id, e.target.value);  verifyDataForm(setErrorEmailThr, setLabelEmailThr, "Email", formData.billing_contact_email, errorEmailThr);}} defaultValue={formData.billing_contact_email}/>
                            { errorEmailThr && <Form.Label className="error-label" > {labelEmailThr} </Form.Label>
                            }                    
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12} xs={12}>
                        <Form.Group controlId="billing_contact_position">
                            <Form.Label>Puesto {(formData.showErrors == true && formData.billing_contact_position == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder="Introduce el puesto del contacto" onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.billing_contact_position}/>
                        </Form.Group>
                        <Form.Group controlId="billing_contact_department">
                            <Form.Label>Departamento {(formData.showErrors == true && formData.billing_contact_department == "")
                                && <FontAwesomeIcon style={{fontSize: "15px", margin: 0, padding: 0}} color="orange" icon={faExclamationTriangle}/>}</Form.Label>
                            <Form.Control type="text" placeholder={`¿A qué departamento pertenece?`} onChange={(e) => updateKey(e.target.id, e.target.value)} defaultValue={formData.billing_contact_department}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Col>
    );
};

export function Step4 ({formData}) {

    
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [updateDocs, setUpdateDocs] = useState(false);
    const [ineLegalRepIcon, setIneLegalRepIcon] = useState(faPlusCircle);
    const [rfcIcon, setRfcIcon] = useState(faPlusCircle);
    const [ineHumanResourcesIcon, setIneHumanResourcesIcon] = useState(faPlusCircle);
    const [bankAccountStatusIcon, setBankAccountStatusIcon] = useState(faPlusCircle);
    const [constitutiveActIcon, setConstitutiveActIcon] = useState(faPlusCircle);
    const [legalPowerIcon, setLegalPowerIcon] = useState(faPlusCircle);
    const [proofOfResidenceIcon, setProofOfResidenceIcon] = useState(faPlusCircle);
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        formData._id != "" && getCompanyDocs();
    }, [updateDocs]);

    const getCompanyDocs = async () => {        
        try{
            const docs = await axios({
                url: `${URL}/companies/docs`,
                method: "PUT",
                data: encrypt({company_id: formData._id})
            });
            setDocs(docs.data.docs);
        } catch (e) {
            console.log(e);
            e.response && notify(e.response.data.message, "error");
        };
    };

    const getExtension = (fileName) => {
        let basename = fileName.split(/[\\/]/).pop();
        let pos = basename.lastIndexOf(".");      

        if (basename === "" || pos < 1){
            return "";
        };

        return basename.slice(pos + 1);
    };

    const handleUploadFile = async (file, name, key, hook) => {
        const company_id = formData._id;
        let lastDocId = undefined;
        let isUrl = undefined;
        try{
            await Methods.uploadCompanyDoc(company_id, file, name, key, lastDocId, isUrl);
            hook(faCheckCircle);
            setUpdateDocs(!updateDocs);
        } catch(e) {
            console.log(e);
            e.response && notify(e.response.data.message, "error");
        };

    };

    const docsIncludes = (name) => {
        let result = false;
        docs.map(doc => {
            if (doc.name === name){
                result = true;
            }
        });

        return result;
    };

    const renderFiles = () => {
        return(
            <Form>
                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("INE-Representante-Legal") ? faCheckCircle : ineLegalRepIcon}/>
                        <Form.File
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "INE-Representante-Legal", "INE Representante Legal", setIneLegalRepIcon);
                                }
                            }
                        } 
                        id="ine-legal-rep" 
                        label="INE Representante Legal (debe coincidir con el firmante del contrato de servicios)"
                        />
                    </a>
                </Form.Group>

                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("Constancia-RFC") ? faCheckCircle : rfcIcon}/>
                        <Form.File 
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "Constancia-RFC", "Constancia RFC", setRfcIcon);
                                }
                            }
                        } 
                        id="rfc" 
                        label="Constancia RFC" 
                        />
                    </a>
                </Form.Group>

                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("INE-Contacto-Recursos-Humanos") ? faCheckCircle : ineHumanResourcesIcon}/>
                        <Form.File 
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "INE-Contacto-Recursos-Humanos", "INE Contacto Recursos Humanos", setIneHumanResourcesIcon);
                                }
                            }
                        } 
                        id="ine-human-resources" 
                        label="INE Contacto Recursos Humanos (ID del responsable de recibir las tarjetas bancarias)" 
                        />
                    </a>
                </Form.Group>

                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("Estado-de-Cuenta") ? faCheckCircle : bankAccountStatusIcon}/>
                        <Form.File 
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "Estado-de-Cuenta", "Estado de Cuenta", setBankAccountStatusIcon);
                                }
                            }
                        } 
                        id="bank-account-status" 
                        label="Estado de Cuenta" 
                        />
                    </a>
                </Form.Group>

                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("Acta-Constitutiva") ? faCheckCircle : constitutiveActIcon}/>
                        <Form.File 
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "Acta-Constitutiva", "Acta Constitutiva", setConstitutiveActIcon);
                                }
                            }
                        } 
                        id="constitutive-act" 
                        label="Acta Constitutiva (registrada ante el registro público y comercio)" 
                        />
                    </a>
                </Form.Group>

                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("Poder-Legal") ? faCheckCircle : legalPowerIcon}/>
                        <Form.File 
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "Poder-Legal", "Poder Legal", setLegalPowerIcon);
                                }
                            }
                        } 
                        id="legal-power" 
                        label="Poder Legal (contar con poder general de administración)" 
                        />
                    </a>
                </Form.Group>

                <Form.Group className="files">
                    <a href="#">
                        <FontAwesomeIcon icon={docsIncludes("Comprobante-De-Domicilio") ? faCheckCircle : proofOfResidenceIcon}/>
                        <Form.File 
                        onChange={(e) => {
                                e.preventDefault();
                                if(e.target.files[0]){
                                    handleUploadFile(e.target.files[0], "Comprobante-De-Domicilio", "Comprobante de Domicilio", setProofOfResidenceIcon);
                                }
                            }
                        } 
                        id="proofofresidence" 
                        label="Comprobante de Domicilio (en caso de que el estado de cuenta no cuadre con la misma dirección del RFC)" 
                        />
                    </a>
                </Form.Group>
            </Form>
        );
    };

    return(
        <Col>
            <Form>
                <Row>
                    <Col>
                        <p>Documentos adjuntos</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {renderFiles()}
                    </Col>
                </Row>
            </Form>
        </Col>
    );
};

export function SuccessModal ({showSuccessModal}) {
    return(
        <Modal className="success-modal" show={showSuccessModal} centered>

            <Modal.Body>
                <h2> ¡Has concluido tu solicitud! <FontAwesomeIcon  icon={faCheckCircle}/> </h2>
                <p>Pronto revisaremos tus datos y enviaremos una respuesta al correo del administrador de la plataforma</p>
            </Modal.Body>
        </Modal>
    );
};
import { useEffect, useState } from "react";
import { Button, Modal, Table, Row, Col } from "react-bootstrap";
import { getFormatedAmount, updateInvoicesToSend } from "../helpers/index";


let invoicesArray = [];
export const CompanyInvoices = ({
  companyInvoices,
  setCompanyInvoices,
  setShowCompanyInovices,
  showCompanyInovices,
  setCompanyIdInvoice,
  setInovicesToSend,
  inovicesToSend,
  onInvoice,
  invoicesSelected,
  allCompanies,
  companyIdInvoice,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  let [checkedItem, setCheckedItem] = useState([]);
  const [total, setTotal] = useState({});
  let totalCount = 0;
  let itemTotal = null;

 const init = () =>{
  
  
   let companyTmp = allCompanies.companies.filter(
     (company) => company._id === companyIdInvoice
   );


   companyInvoices?.map((item) => {
     let checkedItemsCopy = checkedItem;
    if(item.asosiate && !checkedItem.includes(item._id)){
      checkedItemsCopy.push(item._id);
    
    }
    setCheckedItem(checkedItemsCopy);
  })

   companyTmp[0].asosiate?.map((item) => {
     
     let checkedItemsCopy = checkedItem;
     checkedItemsCopy.push(item._id);
     
     
     setCheckedItem(checkedItemsCopy);
   })
 }




  useEffect(() => {
    asosiateInovices();
    init();
  }, []);

  const asosiateInovices = () => {
    totalCount = 0;
    if (!inovicesToSend) {
      setIsLoading(false);
      return;
    }
    //DELETE DUPLICATES
    let tmpChecked = checkedItem.filter((elemento, indice) => checkedItem.indexOf(elemento) === indice);
    checkedItem = tmpChecked;
    let invoicesTmp = [];
    for (let companyInvoice of companyInvoices) {

      companyInvoice.asosiate = inovicesToSend.find(
        (invoiceAux) => invoiceAux._id == companyInvoice._id
      )
        ? true
        : false;
       
        checkedItem.map(item => {
          if(companyInvoice._id === item) {
            invoicesTmp.push(companyInvoice);
            totalCount += companyInvoice.invoiceAmount;
            itemTotal = { id: companyInvoice.companyId, total: totalCount };
          }
          
         })
        
   
      let companyTmp = allCompanies.companies.filter(
        (company) => company._id === companyIdInvoice
      );
      companyTmp[0].amountToEnable = totalCount;

       
      setTotal({ id: companyInvoice.companyId, total: totalCount });
    }

    setInovicesToSend(invoicesTmp)
    onInvoice(itemTotal);
    setCompanyInvoices(companyInvoices);
    setIsLoading(false);
  };

  const handleClose = () => {
    setCompanyInvoices([]);
    asosiateInovices();
    setCompanyIdInvoice("");
    setShowCompanyInovices(!showCompanyInovices);
  };

  const currentRecordHeader = [
    {
      key: "periodType",
      keyName: "Periodo",
    },
    {
      key: "periodDates",
      keyName: "Fechas de periodo",
      minWidth: "250px",
    },
    {
      key: "invoiceNumber",
      keyName: "Número de factura",
    },
    {
      key: "stampedDate",
      keyName: "Fecha de timbrado",
    },
    {
      key: "isStamped",
      keyName: "Timbrada",
      specialFormat: (value) => {
        return value ? "Si" : "No";
      },
    },
    {
      key: "invoiceAmount",
      keyName: "Total de factura",
      specialFormat: (data) => {
        return getFormatedAmount(data);
      },
    },
    {
      key: "asosiate",
      keyName: "Asociar",
      specialType: "Check",
    },
  ];

  const updateInvoiceStatus = (item) => {
    updateInvoicesToSend(
      item,
      item.asosiate ? "remove" : "add",
      setInovicesToSend,
      inovicesToSend
    );
  };

  const handleChange = (event, record) => {
    const id = record._id;
  
    let checkedItemsCopy = [...checkedItem];
    if (checkedItemsCopy.includes(id) && !event.target.checked) {
      //elimina el item porque ya existian
      checkedItemsCopy = checkedItemsCopy.filter((item) => item !== id);
    } else {
      //pone el item si no existe
      checkedItemsCopy.push(id);
    }
    setCheckedItem(checkedItemsCopy);
    
    invoicesArray = [];
    companyInvoices.map(item => {
      if(checkedItemsCopy.includes(item._id)){
        invoicesArray.push(item);
      }
     
    })


   //NO SE
    if(invoicesArray.length == 0){
      
      invoicesArray = {
        agentName:"",
        asosiate: false,
        authorizeAuthorEmail: " ",
        authorizeAuthorName: "Administrador Caintra",
        authorizeDate:"2023-03-31T10:36:04-06:00",
        companyId:record._id,
        companyName: " ",
        createdAtTimestamp: 1680280564,
        invoiceAmount: 0,
        invoiceNumber: "",
        isAuthorized: true,
        isStamped: false,
        periodDates :"PERIODO DEL 31/03/2023 AL 13/04/2023",
        periodType:"Catorcenal",
        prefactureDetailId: "210704a0-cfe2-11ed-8c40-4995e798dc1f",
        reportType: "Authorization",
        _id:"0000"
      }
    }
    invoicesSelected(invoicesArray);
    updateInvoiceStatus(record);

  
  };


  return (
    <Modal
      className="history-modal"
      dialogClassName="modal-width-companies"
      contentClassName="modal-height-companies"
      onHide={() => {
        handleClose();
      }}
      show={showCompanyInovices}
      centered
    >
      <Modal.Body>
        <Modal.Header>
          <p>Facturas</p>
        </Modal.Header>
        <Row>
          <Col>
            <div className="history-modal-table">
              <Table>
                <thead>
                  <tr>
                    {currentRecordHeader.map((item) => {
                      return (
                        <th
                          key={item.key}
                          className="header-table"
                          style={{
                            minWidth: item.minWidth
                              ? `${item.minWidth}`
                              : "200px",
                            fontWeight: "700",
                          }}
                        >
                          {item.keyName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    companyInvoices.map((record, index) => {
                      return (
                        <tr key={index}>
                          {currentRecordHeader.map((item) => {
                            if (item.specialType == "Check") {
                                                        

                              return (
                                <td key={item.key + index}>
                                  <div className="center-content">
                                    <input
                                      type="checkbox"
                                      className="form-check-custom pointer"
                                      onClick={(event) => {
                                     
                                      }}
                                      onChange={(event) => {

                                        handleChange(event, record);
                                       
                                      }}
                                      checked={checkedItem.includes(record._id)}
                                  
                                    />

                                   
                                  </div>
                                </td>
                              );
                            } else {
                              return (
                                <td key={item.key + index}>
                                  <div className="center-content font-content">
                                    {item.specialFormat
                                      ? item.specialFormat(record[item.key])
                                      : record[item.key] || ""}
                                  </div>
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <div className="close-button-container">
        <Button
          className=""
          variant="danger"
          onClick={() => {
            handleClose();
          }}
          style={{ marginBottom: "20px" }}
        >
          Cerrar
        </Button>
      </div>
    </Modal>
  );
};

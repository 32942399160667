import React from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function NewNotification ({displayNewUniversity, setDisplayNewUniversity, updateList, setUpdateList}) {
    const URL = process.env.REACT_APP_SOCKET_URL;

    const createNotification = async (e) => {
        e.preventDefault();
        const notification = {
            name: e.target.elements.title.value,
            user: "all",
            description: e.target.elements.description.value,
            type: " ",
            date: new Date()
        };

        try{
            
            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });

            notify(`${notification.name} agregado exitosamente como Aviso`, "success");
            setUpdateList(!updateList);
            setDisplayNewUniversity(!displayNewUniversity);
        } catch (e) {
            e.response && notify(e.response.data.message);
        };
    };

    return(
        <Modal className="new-cc-modal" show={displayNewUniversity} onHide={() => setDisplayNewUniversity(!displayNewUniversity)} centered>
            <Modal.Header>
                <p>Nuevo Aviso</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={createNotification}>
                    <Form.Group controlId="title">
                        <Form.Label>Título</Form.Label>
                        <Form.Control type="text" required/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" required/>
                    </Form.Group>
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Registrar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayNewUniversity(!displayNewUniversity)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
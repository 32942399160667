
export default {
    

    test(workbook, companyInfo, interns) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        const data = {
            'cols': ["A","B","C","D","E","F","G","H"],
            'config' : [{  width: 20,  value: "id",              text: "ID",                    getData: (index) => { return interns[index]._id || "" }  },
                        {  width: 20,  value: "firstname",         text: "Nombre(s)",               getData: (index) => { return interns[index].firstname || "" }  },
                        {  width: 20,  value: "fatherLastName",    text: "Apellido Paterno",        getData: (index) => { return interns[index].father_lastname || "" }  },
                        {  width: 20,  value: "motherLastName",    text: "Apellido Materno",        getData: (index) => { return interns[index].mother_lastname || "" }  },
                        {  width: 20,  value: "adjustment",       text: "Incremento",             getData: (index) => { return 0.00 }  },
                        {  width: 20,  value: "adjustment2",       text: "Decremento",             getData: (index) => { return 0.00 }  },
                        {  width: 20,  value: "adjustmentDays",       text: "Incremento Días",             getData: (index) => { return 0.00 }  },
                        {  width: 20,  value: "adjustmentDays2",       text: "Decremento Días",             getData: (index) => { return 0.00 }  },
                        ],
            'worksheetName': "Ajustes Masivos",
            'headerText': "Plantilla Ajustes Masivos"
        }

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A1",
                "Nombre de Empresa",
                worksheetName
            )
            createWhiteCell(
                "B1",
                companyInfo.companyName  || "",
                worksheetName
            )

            createBlueCell(
                "C1",
                "RFC",
                worksheetName
            )

            createWhiteCell(
                "D1",
                companyInfo.companyRFC  || "",
                worksheetName
            )

            createBlueCell(
                "E1",
                "ID",
                worksheetName
            )
            createWhiteCell(
                "F1",
                companyInfo.companyId  || "",
                worksheetName
            )
        }

        function createTableAvail(worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                data.headerText,
                worksheetName
            );
            cellNumber++
            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            
            interns.forEach((intern, index) => {
                cellNumber++
                config.forEach((configItem) => {
                    createWhiteCell(
                        configItem.col + cellNumber,
                        configItem.getData(index),
                        worksheetName
                    )
                });
            });
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}

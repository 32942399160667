import React, {useState, useContext} from 'react';
import {Modal, Button, Form, Image, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {UserContext} from '../../../context/UserContext';
import DefaultRoles from '../components/DefaultRoles'


export default function DeleteRole({currentRole, openDeleteRole, setOpenDeleteRole, updateRoles, setUpdateRoles}){
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(UserContext);

    const deleteRole = async (e) => {
        e.preventDefault();
        
        if(!context.can("delete", "Roles")){
            notify("No tienes acceso a esta funcionalidad, solicítala a un administrador", "error");
            return;
        };

        setIsLoading(true);

        const confirmation = e.target.elements.confirmation.value;

        if(confirmation.trim().toLowerCase() != "confirmar"){
            notify("La palabra de confirmación es incorrecta, por favor intenta nuevamente", "error");
            return;
        };
        await Methods.deleteRole(currentRole);

        for(let user of currentRole.users){
            if(user._role == currentRole.label){
                const userData = {
                    _id: user._id,
                    attributes: [
                        {
                            updateKey: "_permissions",
                            updateValue: DefaultRoles.NewUser(), 
                            expressionAttributeName: "#_permissions"
                        }
                    ]
                };
                await Methods.updateUser(userData);
            }
        }
        setIsLoading(false);
        setUpdateRoles(!updateRoles);
        setOpenDeleteRole(false);
    };

    return(
        <Modal className="prefacture-modals" show={openDeleteRole} onHide={() => setOpenDeleteRole(!openDeleteRole)} centered>

            <Modal.Body>
                <Form onSubmit={deleteRole} autoComplete={"off"}>
                    <p>¿Estás seguro de que deseas eliminar este rol?</p>

                    <Form.Group style={{marginTop: "20px"}} controlId="confirmation">
                        <Form.Control type="text" placeholder='Escribe "confirmar" aquí' required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                       {isLoading ? <Spinner animation="border" /> : "Confirmar"}
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setOpenDeleteRole(!openDeleteRole)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    )
}
import React, { useContext, useEffect, useState } from 'react';
import InternsLogo from '../../../assets/logo_interns.png';
import { Modal, Button, Form, Image, Row, Col } from 'react-bootstrap';
import { notify } from "../../../utils/notify";
import { UserContext } from '../../../context/UserContext';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Methods from '../../../utils/https';
import {faCloudDownloadAlt, faCloudUploadAlt, faBan, faExclamationTriangle, faFileDownload, faTrashAlt, faEdit} from '@fortawesome/free-solid-svg-icons';
const $ENV = process.env.env;


const AWS2 = require("aws-sdk");
const axios = require("axios");
const bucket = process.env.bucketName;



const temas = [
  { "id": "1", "tema": "Desarrollo personal", "titulo": "Desarrollo personal", "subtitulo": "Desarrollo personal", "autor": "Grow", "caption": "img/resources/desarrollo.jpeg" },
  { "id": "2", "tema": "Tendencias en empresas", "titulo": "Tendencias en empresas", "subtitulo": "Tendencias en empresas", "autor": "Grow", "caption": "img/resources/tendencias.jpg" },
  { "id": "3", "tema": "Básicos para la incorporación laboral", "titulo": "Básicos para la incorporación laboral", "subtitulo": "Básicos para la incorporación laboral", "autor": "Grow", "caption": "img/resources/incorporacion.jpg" },
  { "id": "4", "tema": "Desarrollo técnico", "titulo": "Desarrollo técnico", "subtitulo": "Desarrollo técnico", "autor": "Grow", "caption": "img/resources/tecnico.jpg" },
];
let base64PDF ="";
let embedSrc ="";


export default function LetterIn({ setUpdateList, setShowLetterInModal, showLetterInModal, currentInternId, updateList, currentIntern, setCurrentIntern }) {
  const [pdfSRC, setPdfSRC] = useState("");
  const URL = process.env.REACT_APP_SOCKET_URL;
  const context = useContext(UserContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 
  const [resultados, setResultados] = useState([]);
  let result = [];

  const updateInternDoc = async (e, fileName, oldFileId, fileKey) => {
    e.preventDefault();
    const _file = e.target.files[0];
    const fileExtension = "pdf";
    const filename = `${fileName}.${fileExtension}`;

    let data = new FormData();
    
    if(_file.size > 10485760){
        notify(`El archivo ${file.name} pesa más de 10 MB`, "error");
        return;
    };


    let files = Array();
    let file = {"filename": filename, "fileKey": fileKey};
    files.push(file);
    data.append("files", JSON.stringify(files));
    data.append("path", `interns/docs/${currentInternId}`);
    data.append("intern_id", currentInternId);     
    data.append("newFileName", filename);
    data.append("oldFilePath", `interns/docs/${currentInternId}/${filename}`);
    data.append("oldFileId", oldFileId);


    let headers = {
        "Content-Type": "pdf",
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Methods": "*"
    }

    try{

        await axios({
            url: `${URL}/interns/docs/`,
            method: "PATCH",
            data: data
        });

        let response = await axios({
            url: `${URL}/interns/docs/${currentInternId}/`,
            method: "POST",
            data: data
        });

        await axios({
            url: response.data[0].presignedPUTURL,
            method: "PUT",
            data: _file, 
            headers : headers
        });

        notify("El archivo se actualizó correctamente", "success");
        test();
        
    } catch(e) {
        e.response && notify(e.response.data.message, "error");
    };
};

  const QueryData = async () => {   


    for (let i = 0; i < temas.length; i++) {
      result[i] = await axios({
        url: `${URL}/getTest`,
        method: "POST",
        data: { userId: currentInternId, item: temas[i].titulo }
      });

      let tmp = result[i]?.data[0]?.correctas * 5 / result[i]?.data[0]?.totales;
      result[i].data[0].calificacion = tmp;
    }
   
  };

  /* useEffect(() => {
    QueryData();
  }, []); */

  const test = async (e) => {
    let tmpRes = [];
    setResultados([]);


  const url = 'aceptacion.pdf';
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const page = pdfDoc.getPages()[0];
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  

  page.drawText(context.user.company.municipality.toUpperCase()+", "+ context.user.company.state.toUpperCase(), {
    x: 400,
    y: 700,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  let userData = {
    _id : currentIntern._id,
    opc : "aceptacion"
  }


  let res = await Methods.getLetter(userData);
  currentIntern.contractStartDate =  res?.Items[0]?.letterAInit;
  currentIntern.contractEndDate =  res?.Items[0]?.letterAEnd;

  let contractRes = null;
  if(!currentIntern.contractStartDate){
    contractRes = await Methods.getDates({ _id : currentIntern.currentContractId});

    currentIntern.contractStartDate =  contractRes.Items[0].internStartDate;
    currentIntern.contractEndDate =  contractRes.Items[0].internEndDate;
  }



  userData = {
    fechaInicio : startDate ?  moment(startDate).format('DD/MM/YYYY') : currentIntern.contractStartDate,
    fechaFinal : endDate ?  moment(endDate).format('DD/MM/YYYY') : currentIntern.contractEndDate,
    empresa : context.user.company.commercial_reason,
    userId : currentIntern._id,
    opc : "aceptacion"
  }


  await Methods.saveLetter(userData);


  let mes = "";
  if(moment().format('MMMM').toUpperCase() == 'JANUARY' ){
    mes= "ENERO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'FEBRUARY' ){
    mes= "FEBRERO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'MARCH' ){
    mes= "MARZO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'APRIL' ){
    mes= "ABRIL";
  }
  else if(moment().format('MMMM').toUpperCase() == 'MAY' ){
    mes= "MAYO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'JUNE' ){
    mes= "JUNIO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'JULY' ){
    mes= "JULIO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'AUGUST' ){
    mes= "AGOSTO";
  }
  else if(moment().format('MMMM').toUpperCase() == 'SEPTEMBER' ){
    mes= "SEPTIEMBRE";
  }
  else if(moment().format('MMMM').toUpperCase() == 'OCTUBER' ){
    mes= "OCTUBRE";
  }
  else if(moment().format('MMMM').toUpperCase() == 'NOVEMBER' ){
    mes= "NOVIEMBRE";
  }
  else if(moment().format('MMMM').toUpperCase() == 'DECEMBRER' ){
    mes = "DICIEMBRE";
  }


  page.drawText(mes + " " + (moment().format('YYYY')), {
    x: 400,
    y: 690,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText(currentIntern.firstname + " " + currentIntern.father_lastname + " " + currentIntern.mother_lastname, {
    x: 85,
    y: 570,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });

  page.drawText(currentIntern.school_enrollment.toString(), {
    x: 450,
    y: 570,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText(currentIntern.career.toUpperCase(), {
    x: 180,
    y: 545,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText(currentIntern.faculty.toUpperCase(), {
    x: 85,
    y: 525,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText(context.user.company.commercial_reason, {
    x: 85,
    y: 485,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText( startDate ?  moment(startDate).format('DD/MM/YYYY') : currentIntern.contractStartDate || "", {
    x: 85,
    y: 465,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText(endDate ? moment(endDate).format('DD/MM/YYYY') : currentIntern.contractEndDate || "", {
    x: 285,
    y: 465,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });


  page.drawText(currentIntern.directBoss_name.toUpperCase(), {
    x: 220,
    y: 300,
    font: font,
    size: 9,
    color: rgb(0, 0, 0),
  });
  

  const modifiedPdfBytes = await pdfDoc.save();
  base64PDF = window.btoa(String.fromCharCode(...new Uint8Array(modifiedPdfBytes)));
  const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
  //saveAs(blob, 'Carta.pdf');
  const pdfBuffer = Buffer.from(base64PDF, 'base64');

  



  //VERIFICAR SI EXISTE UN ARCHIVO PDF CUSTOMIZADO

  let s3Params2 = {
    Bucket: 'caintrabucket-prod',
    Key: 'interns/docs/' + currentIntern._id+ "/letterInCustom.pdf",
  };
  console.log(s3Params2);
  let list;
  try{
    list = await axios({
      url: `${URL}/app/listFilesApp`,
      method : "GET",
      params: s3Params2
    })
  }
  catch(error){
    console.log("Error reading");
  }
  if(list?.data?.message == "Archivo verificado exitosamente en S3"){
    const respuesta = await axios.get(list.data.file, {
      responseType: 'arraybuffer'
    });

    const pdfBase64 = Buffer.from(respuesta.data).toString('base64');
    embedSrc = `data:application/pdf;base64,${pdfBase64}`;
  }
  else{

  const reader = new FileReader();
  let base64data = null

  reader.onloadend = function () {
    // Obtener el resultado codificado en base64 y quitar el prefijo del Data URL
    base64data = reader.result.split(',')[1];


    const params = {
      Bucket: 'caintrabucket-prod',
      Key: 'interns/docs/'+currentIntern._id+"/letterIn.pdf",
      Body: JSON.stringify(base64data),
      ContentType: "application/pdf",
    }
     axios({
      url: `${URL}/uploadFilesApp`,
      method: "POST",
      data: params
    });
    

  };

  reader.readAsDataURL(blob);
  
    embedSrc = `data:application/pdf;base64,${base64PDF}`;
    
  }
  setPdfSRC(embedSrc);

    for (let i = 0; i < temas.length; i++) {
        result[i] = await axios({
          url: `${URL}/getTest`,
          method: "POST",
          data: { userId: currentInternId, item: temas[i].titulo }
        });
     
        if(result[i].data.length>0){
            let tmp = result[i]?.data[0]?.correctas * 5 / result[i]?.data[0]?.totales;
            result[i].data[0].calificacion = tmp;
            tmpRes.push(result[i]);
        
        }
      }

    setResultados(tmpRes)
    notify('Generado correctamente', "success");

  };

  useEffect(() => {
    if(showLetterInModal){

      test();
    }
  }, [showLetterInModal]);
  
  return (
    <Modal className="training-intern-modal" show={showLetterInModal} onHide={() => setShowLetterInModal(!showLetterInModal)}  style={{ width: "90%", margin: "" }}>
        <Modal.Header>
            <h5>Carta de aceptación</h5>
        </Modal.Header>

            
        <Modal.Body >
          <embed src={pdfSRC} type="application/pdf" width="100%" height="500px" />

          <h5>Generar carta:</h5>
          <Row className="btn-container">
          
            <Col md={6} className="col mt-6 "  style={{ marginTop:"15px" }}>
            <div className="text-center">
                Fecha de inicio: 
                <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
                <DatePicker className='myInputDate'
                selected={startDate}
                onChange={ async (date) => {
                    setStartDate(date);
                   
                }}
                dateFormat='dd-MM-yyyy'
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
                />        
                </div>
            </Col>
            <Col md={6} className="col mt-6"  style={{ marginTop:"15px" }}>
            <div className="text-center">
                Fecha final: 
                <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
                <DatePicker className='myInputDate'
                selected={endDate}
                onChange={ async (date) => {
                    setEndDate(date);

                }}
                dateFormat='dd-MM-yyyy'
                style={{backgroundImage: "none", backgroundColor: "transparent"}}
                />        
                </div>
            </Col>
             
            

            <Col md={12} className="col mt-12"  style={{ marginTop:"15px",marginLeft:"70%" }}>
            <div className="text-center1" style={{ }}>

                <Button className="confirm-btn ml-2" variant="primary" onClick={() => test()}>
                    Cambiar fechas
                </Button> 
                </div>
            </Col>
          </Row>
            <h5>ó subir carta personalizada</h5>   
            <Row className="btn-container">
            <Col md={12} style={{ marginLeft:"10%", marginTop:"15px" }} >
            <div className="text-letf">
              Subir archivo personalizado
              
                  {
                  <Form.File
                      hidden
                      onChange={(e) => {
                              e.preventDefault(); 
                              updateInternDoc(e, "letterInCustom", "letterInCustom.pdf", "letterInCustom.pdf");
                              updateInternDoc(e, "letterIn", "letterIn.pdf", "letterIn.pdf");
                              
                          }
                      }
                      id={`letterInCustom.pdf`}
                      label={<FontAwesomeIcon className="form-file-label center-text" icon={faCloudUploadAlt}/>}
                  />
                  }
     
              </div>      
            </Col>

            <Col md={12} className="col mt-12"  style={{ marginTop:"15px",marginLeft:"77%" }}>
            <div className="text-center1" style={{ }}>
                <Button className="confirm-btn ml-2" variant="danger" onClick={() => {
                    setShowLetterInModal(!showLetterInModal);
                    setEndDate(null)
                    setStartDate(null)
                }}>
                    Cerrar
                </Button>
                
                </div>
            </Col>
          
            </Row>


          
        </Modal.Body>

    </Modal>
);
};

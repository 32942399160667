import React, {useContext} from 'react';
import InternsLogo from '../../../assets/logo_interns.png';
import {Modal, Button, Form, Image, Row, Col} from 'react-bootstrap';
import {notify} from "../../../utils/notify";
import {DateTimePicker} from 'react-widgets';
import {UserContext} from '../../../context/UserContext';
import Methods from '../../../utils/https';
const axios = require("axios");

export default function UnsuscribeCompanyModal({setUpdateList, setShowUnsuscribeCompanyModal, showUnsuscribeCompanyModal, currentCompanyId, updateList}) {
    const context = useContext(UserContext);
    const isCaintraUser = context.user.data.isCaintraUser;
    const handleUnsuscribeIntern = async (e) => {
        e.preventDefault();

        if(!context.can("delete", "Companies") || !isCaintraUser){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        const description = e.target.elements.description.value;

        if(description.trim() == ""){
            notify("La descripción de la baja no puede estar vacía", "error");
            return;
        };

        const attributesToBeUpdated = {
            _id: currentCompanyId,
            attributes: [
                {updateKey: "unsuscribedCompany", updateValue: true},
                {updateKey: "unsuscribeMotive", updateValue: "Baja"},
                {updateKey: "unsuscribeDescription", updateValue: description}
            ]
        };

        try {
            await Methods.updateCompany(attributesToBeUpdated);
            setShowUnsuscribeCompanyModal(!showUnsuscribeCompanyModal);
            setUpdateList(!updateList);
            notify("La compañía fue dada de baja satisfactoriamente", "success");
        } catch (e) {
            notify(e.response.data.message, "error");
        };
    
    };

    return (
        <Modal className="unsuscribe-intern-modal" show={showUnsuscribeCompanyModal} onHide={() => setShowUnsuscribeCompanyModal(!showUnsuscribeCompanyModal)} centered>
            <Modal.Header>
                <p>Dar de baja a compañía</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleUnsuscribeIntern}>
                    <Form.Group controlId="unsuscribeDate">
                        <Form.Label>Fecha</Form.Label>
                        <DateTimePicker name="unsuscribeDate" format={'DD/MM/YYYY'} editFormat={'DD/MM/YYYY'} value={new Date()} time={false} />
                    </Form.Group>
                    
                    <Form.Group controlId="description">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control type="text" autoComplete={"off"} placeholder="Escribe el por qué" required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setShowUnsuscribeCompanyModal(!showUnsuscribeCompanyModal)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Methods from "../../../utils/https";
import { notify } from '../../../utils/notify';

const DUMMY_RAZONES_SOCIALES = ["RAZON SOCIAL 1", "RAZON SOCIAL 2", "RAZON SOCIAL 3"];

export default function AddRFC(props) {
    const [razonesSociales, setRazonesSociales] = React.useState(null);
    const [RFC, setRFC] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleAddGroup = async (event) => {
        setLoading(true);
        event.preventDefault();
        await props.addRFC(event.target.elements.razon_social.value, RFC);
        setRazonesSociales(null);
        setLoading(false);
    }

    const cleanData = async () => {
        setRazonesSociales(null);
        setRFC('');
    }

    return <Modal className="new-cc-modal" show={props.displayModal} onHide={() => { props.hideModal(); cleanData() }} centered>
        
        <Modal.Header>
            <p>Nuevo RFC</p>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={(event) => handleAddGroup(event)}>
                <Form.Group controlId="RFC">
                    <Form.Label>Escribe el RFC</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Escribe el RFC"
                        required
                        onChange={event => {
                            setRFC(event.target.value);
                            setRazonesSociales(null);
                        }}
                    />
                    <button type="button" onClick={async () => {
                        if(!RFC) return;
                        let search = true;
                        
                        props.allGroups.map( groupAux => {
                            groupAux.groupCompaniesRFC && groupAux.groupCompaniesRFC.map(rfcAux => {
                                if(rfcAux == RFC) search = false;
                            });
                        });

                        props.selectedGroup.groupCompaniesRFC.map(rfcAux => {
                            if(rfcAux == RFC) search = false;
                        });

                        if(!search){
                            notify('Este RFC ya está registrado en un grupo', 'error');
                            //return;
                        }

                        setRazonesSociales(await Methods.getCompaniesByRFC({RFC}));
                    }}
                    >Buscar razones sociales</button>
                </Form.Group>


                <Form.Group controlId='razon_social' >
                    <Form.Label>Razón Social</Form.Label>
                    <Form.Control as="select" disabled={razonesSociales == null}>
                        {razonesSociales && razonesSociales.map(k => {
                            return <option value={k._id}>{k.business_name}</option>;
                        })}
                    </Form.Control>
                </Form.Group>

                <Button className="float-right ml-2" variant="primary" disabled={loading} type="submit">
                    Registrar
                </Button>
                <Button className="float-right" variant="danger" type="button" onClick={props.hideModal}>
                    Cancelar
                </Button>
            </Form>
        </Modal.Body>
    </Modal >
};
import React from 'react';
import {Modal, Button, Form, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';

export default function ApproveCompanyModal({showApproveCompanyModal, setShowApproveCompanyModal, currentCompanyId, updateList, setUpdateList, companyEmail, companyName}) {
    
    const approveCompany = async (e) => {
        e.preventDefault();
        
        const confirmation = e.target.elements.confirmation.value;

        if(confirmation.trim() != "Aprobar" && confirmation.trim() != "aprobar"){
            notify("La palabra de confirmación es incorrecta, por favor intenta nuevamente", "error");
            return;
        };

        await Methods.approveCompany(currentCompanyId, companyEmail, companyName);
        setUpdateList(!updateList);
        setShowApproveCompanyModal(!showApproveCompanyModal);
    };

    return(
        <Modal className="company-modals" show={showApproveCompanyModal} onHide={() => setShowApproveCompanyModal(!showApproveCompanyModal)} centered>

            <Modal.Body>
                <Form onSubmit={approveCompany} autoComplete={"off"}>
                    <p>Estás a punto de aprobar esta compañía...</p>
                    <small>¡Recuerda revisar todos los datos y documentos antes de continuar!</small>

                    <Form.Group style={{marginTop: "20px"}} controlId="confirmation">
                        <Form.Control type="text" placeholder='Escribe "aprobar" aquí' required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setShowApproveCompanyModal(!showApproveCompanyModal)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import moment from 'moment';
import Methods from '../../../utils/https';

const axios = require("axios");



export default function VerifyChangeOnDate ({showVerifyChangeOnDate, setShowVerifyChangeOnDate,setDateChanged, setDateConfirm, setConfirmation}) {

    const endModal = async (e) => {

       setShowVerifyChangeOnDate(false);
       setConfirmation(true);
    };

    return(
        <Modal className="new-cc-modal" show={showVerifyChangeOnDate} onHide={() => setShowVerifyChangeOnDate(!showVerifyChangeOnDate)} centered>
            <Modal.Header>
                <p>Advertencia</p>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group controlId="title">
                        <Form.Label>Ya se cobró la póliza del seguro y esta acción generaría otro cobro de póliza. ¿Desea continuar? </Form.Label>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit" onClick={() => endModal()} form='updateInternForm' >
                        Continuar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => {setShowVerifyChangeOnDate(!showVerifyChangeOnDate)}}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
import React, {useState, useEffect} from "react";
import {motion} from "framer-motion";
import {Table, Row, Col, Card, OverlayTrigger, Tooltip, Popover, ListGroup} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSyncAlt, faEdit, faTrash, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import NewNotificationModal from './components/NewNotificationModal';
import UpdateNotificationModal from './components/UpdateNotificationModal';
import DeleteNotificationModal from './components/DeleteNotificationModal';
import { notify } from "../../utils/notify";
const axios = require("axios");

export default function Notifications ({setCurrentTab, context}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState(undefined);
    const [updateList, setUpdateList] = useState(false);
    const [rotateSync, setRotateSync] = useState(false);
    const rotate = rotateSync ? "rotate" : "";
    const [currentNotificationId, setcurrentNotificationId] = useState("");
    const [displayNewUniversity, setDisplayNewUniversity] = useState(false);
    const [displayDeleteNotificationModal, setDisplayDeleteNotificationModal] = useState(false);
    const [displayUpdateNotification, setDisplayUpdateNotification] = useState(false);
    const [currentCareer, setCurrentCareer] = useState({});

    useEffect(() => {
        getNotifications();
    }, [updateList]);

    const getNotifications = async () => {
        try {
            let _notifications = await axios({
                url: `${URL}/notifications`,
                method: "PUT",
            });
            setNotifications(_notifications.data.data);
            setIsLoading(false);
            setRotateSync(false); 
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setRotateSync(false);
            setIsLoading(false);
            setNotifications([]);
        };
    };


    const handleUniversityOptions = (option, notification_id, data) => {
        switch (option) {
            case "create":
                setDisplayNewUniversity(!displayNewUniversity);
                break;
            case "update":
                setcurrentNotificationId(notification_id);
                setCurrentCareer(data)
                setDisplayUpdateNotification(!displayUpdateNotification);
                break;
            case "delete":
                setcurrentNotificationId(notification_id);
                setDisplayDeleteNotificationModal(!displayDeleteNotificationModal);
                break;
            default:
                break;
        };
    };

    const generateDepartmentsCards = () => {
        if(!notifications || notifications.length == 0){
            return <></>;
        };

        return(
            <Table striped bordered responsive hover className="mt-4 h-50">
            <thead>
              <tr>
                <th>Título</th>
                <th>Descripción</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody style={{height: "100%"}}>
            {notifications.map((notification, index) => {
                if(notification._user == "all"){
                return(
                <tr key={index}>
                <td>{notification._name}</td>
                <td>{notification.description}</td>
                <td className="options">    
                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Editar Aviso</Tooltip>} >
                <a href="#" onClick={() => {handleUniversityOptions("update", notification._id, notification)}}><FontAwesomeIcon icon={faEdit}/></a> 
                </OverlayTrigger>
                
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Eliminar Aviso</Tooltip>}
                >   
                <a href="#" onClick={() => {handleUniversityOptions("delete", notification._id)}}><FontAwesomeIcon icon={faTrash}/></a>
                </OverlayTrigger>
            
                </td>
                </tr>);
                }
            })
            }             
            </tbody>
          </Table>
        );
    };

    return(
        <motion.div initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }} className="manage-departments">
            <Row className="title">
                <a href="#"></a> <h4>Administración de Avisos</h4>
                
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Agregar Aviso</Tooltip>}
                >
                    <a href="#" style={{fontSize: "25px"}} onClick={() => {setDisplayNewUniversity(!displayNewUniversity)}}><FontAwesomeIcon icon={faPlusCircle}/></a>
                </OverlayTrigger>

                <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                >
                    <a href="#" style={{fontSize: "25px"}} onClick={() => {setUpdateList(!updateList); setRotateSync(true)}}><FontAwesomeIcon className={rotate} icon={faSyncAlt}/></a>
                </OverlayTrigger>
            </Row>
            
            {!isLoading  &&
            
            <div >
                {generateDepartmentsCards()}
            </div>
            
            }


            <NewNotificationModal 
                displayNewUniversity={displayNewUniversity} 
                setDisplayNewUniversity={setDisplayNewUniversity} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
            />

            <DeleteNotificationModal 
                displayDeleteNotificationModal={displayDeleteNotificationModal} 
                setDisplayDeleteNotificationModal={setDisplayDeleteNotificationModal} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentNotificationId={currentNotificationId} 
            />

            <UpdateNotificationModal 
                displayUpdateNotification={displayUpdateNotification} 
                setDisplayUpdateNotification={setDisplayUpdateNotification} 
                updateList={updateList} 
                setUpdateList={setUpdateList} 
                currentCareer={currentCareer}
                currentNotificationId={currentNotificationId} 
            />


        </motion.div>
    );
};
import React from 'react';
import { Modal, Image, Form, Button } from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';


import { useContext } from "react";
import { UserContext } from '../../../context/UserContext';
const axios = require("axios");



export default function UpdateDepartmentModal({ displayUpdateDepartmentModal, setDisplayUpdateDepartmentModal, currentDepartmentId, updateList, setUpdateList }) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);

    const updateDepartment = async (e) => {
        e.preventDefault();

        const title = e.target.elements.title.value;

        const department = {
            attributes: [],
            id_department: currentDepartmentId,
            departmentField: context.getDepartmentField(),
        };

        title != "" && department.attributes.push({ updateKey: "title", updateValue: title });

        if (title == "") {
            notify("No hay cambios para realizar", "error");
            return;
        };

        try {
            await axios({
                url: `${URL}/departments`,
                method: "PATCH",
                data: department
            });

            notify(`${context.getDepartmentField(true)} actualizado exitosamente`, "success");
            setUpdateList(!updateList);
            setDisplayUpdateDepartmentModal(!displayUpdateDepartmentModal);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
        };
    };

    const validateEntryKey = (e) => {
        e.charCode == 13 && e.preventDefault()
    }


    return (
        <Modal className="new-cc-modal" show={displayUpdateDepartmentModal} onHide={() => setDisplayUpdateDepartmentModal(!displayUpdateDepartmentModal)} centered>
            <Modal.Header>
                <p>Actualizar {context.getDepartmentField()}</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={updateDepartment}>
                    <Form.Group controlId="title">
                        <Form.Label>Título</Form.Label>
                        <Form.Control onKeyPress={validateEntryKey} type="text" />
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Registrar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayUpdateDepartmentModal(!displayUpdateDepartmentModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import { useContext } from "react";
import { OverlayTrigger, Tooltip, Table, Form, InputGroup } from "react-bootstrap";
import { updateCompanyValue, removeCompanyFromDesactivation } from "../helpers";
import { BsTrash } from "react-icons/bs";
import { UserContext } from "../../../context/UserContext";
import historyDashboard from "../../../assets/IconsCaintra/historyDashboard.svg";
import dollarIcon from "../../../assets/IconsCaintra/dollar.svg";
import saveIcon from "../../../assets/IconsCaintra/save.svg";
import { DateTimePicker } from "react-widgets";
import moment from "moment";



export const CompaniesTable = ({
  statusTable, 
  companiesByPage,
  currentPage,
  setCompanies,
  setCompany,
  allCompanies,
  setCompanyIdRecordsToSearch,
  setCompanyIdInvoice,
  setCompaniesToNotify,
  companiesToNotify,
  totalCount,
  invoicesSelected
}) => {
  const context = useContext(UserContext);

  const updateCompany = (companyToUpdate, keyToUpdate) => {
  
    let validations = {
      desactivationComments: () => {
        return true;
      },
      desactivationComments2: () => {
        return true;
      },
      amountToEnable: () => {
        return companyToUpdate.updateCompany;
      },
      isDisabled: () => {
        return true;
      },
      dateToEndableDispersion: () => {
        return true;
      },
      asosiate : () => {
        return true
      }
    };
    if (validations[keyToUpdate]() === true) {
      
      companyToUpdate.updateCompany = "false";
      
      updateCompanyValue(
        companyToUpdate,
        companiesByPage,
        setCompanies,
        allCompanies,
        context,
        keyToUpdate
      );
    }
  };

  const removeCompany = (companyToUpdate) => {
    removeCompanyFromDesactivation(
      companyToUpdate,
      companiesByPage,
      setCompanies,
      allCompanies,
      context
    );

    companyToUpdate.notifyCheck = false;
    setCompaniesToNotify(
      companiesToNotify.filter(
        (companyAux) => companyAux._id != companyToUpdate._id
      )
    );
  };

  const updateCompaniesToNotify = (company, action) => {
    if (action == "add") {
      company.notifyCheck = true;
      setCompaniesToNotify([...companiesToNotify, company]);
    }

    if (action == "remove") {
      company.notifyCheck = false;
      setCompaniesToNotify(
        companiesToNotify.filter((companyAux) => companyAux._id != company._id)
      );
    }

    setCompanies({
      allCompanies: allCompanies,
      companiesByPage: companiesByPage,
    });
  };

  const getSwitchColorClassByStatus = (company) => {
    return !company.hasOwnProperty("isDisabled")
      ? "green-switch"
      : "orange-switch";
  };

  return (
    <div
      id="table"
      className="companies-table-container-list companies-table-container"
    >
      <Table hover>
        <thead>
          <tr>
            <th style={{ minWidth: "500px" }} className="th-text">
              <div className="center-content">Empresa</div>
            </th>
            <th style={{ minWidth: "150px" }} className="th-text">
              <div className="center-content">Acciones</div>
            </th>
            <th
              style={{ minWidth: "175px", width: "auto" }}
              className="th-text"
            >
              <div className="center-content">Fecha dispersión</div>
            </th>
            <th style={{ minWidth: "160px" }} className="th-text">
              <div className="center-content">Monto</div>
            </th>
            <th
              style={{ minWidth: "190px", width: "auto" }}
              className="th-text"
            >
              <div className="center-content">Periodo</div>
            </th>
            <th
              style={{ minWidth: "190px", width: "auto" }}
              className="th-text"
            >
              <div className="center-content">Comentarios</div>
            </th>
            <th style={{ minWidth: "160px" }} className="th-text">
              <div className="center-content">Suspender/Liberar</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {companiesByPage?.[currentPage]?.map((company) => {
            return (
              <tr key={company._id}>
                <td>
                  <div className="center-content">{company.business_name}</div>
                </td>

                <td>
                  <div className="center-content">
                  <OverlayTrigger placement="top"  overlay={<Tooltip id="button-tooltip-2">Guardar cambios</Tooltip>}>
                    <img
                      src={saveIcon}
                      className="pointer save-icon-size"
                      size={25}
                      onClick={(e) => {

                        
                        company.amountToEnable = totalCount?.total || company?.amountToEnable || 0;
                 
                        if(invoicesSelected.length > 0) {
                            company.asosiate = invoicesSelected.filter(invoice => invoice.companyId === company._id);
                            
                            company.updateCompany = true;
                            updateCompany(company, "asosiate");
                        }
                       
                       
                        company.amountToEnable =
                        totalCount?.total || company?.amountToEnable;
                      company.updateCompany = true;
                      updateCompany(company, "amountToEnable");

                        //CAMBIOS
                        if (company.desactivationComments != e.target.value) {
                          company.desactivationComments = e.target.value;
                          company.updateCompany = true;
                          setCompanies({
                            allCompanies: allCompanies,
                            companiesByPage: companiesByPage,
                          });
                        } else {
                          company.updateCompany = false;
                        }
                      }}
                    />
                  </OverlayTrigger>

                  {/* <OverlayTrigger placement="top"  overlay={<Tooltip id="button-tooltip-2">Asociar facturas</Tooltip>}>
                    <img
                      src={dollarIcon}
                      className="pointer dollar-icon-size"
                      size={25}
                      onClick={() => {
                      
                        setCompany(company._id);
                        let tmp = company._id;
                        company._id = '';
                        setTimeout(()=>{

                          setCompanyIdInvoice(company._id);
                          company._id = tmp;
                          setCompanyIdInvoice(company._id);
                        
      
                        }, 1000)
                      }}  
                    />
                   </OverlayTrigger> */}

                   <OverlayTrigger placement="top"  overlay={<Tooltip id="button-tooltip-2">Eliminar de la lista actual</Tooltip>}>
                    <BsTrash
                      color="black"
                      className="pointer trash-icon-size"
                      onClick={() => {
                        company.desactivationComments = "";
                        removeCompany(company);
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger placement="top"  overlay={<Tooltip id="button-tooltip-2">Ver historial de cambios</Tooltip>}>
                    <img
                      src={historyDashboard}
                      className="history-icon pointer"
                      size={25}
                      onClick={() => {
                        setCompanyIdRecordsToSearch(company._id);
                      }}
                    />
                    </OverlayTrigger>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        className="form-check-custom pointer"
                        disabled={!company.hasOwnProperty("isDisabled")}
                        checked={company.notifyCheck}
                        onClick={() => {
                          updateCompaniesToNotify(
                            company,
                            company.notifyCheck ? "remove" : "add"
                          );
                        }}
                      />
                      Notificar
                    </div>
                  </div>
                </td>
                <td>
                  <div className="center-content">
                    <DateTimePicker
                      name="dateToEndableDispersion"
                      placeholder={company.isDisabled ? company.dateToEndableDispersion : ""}
                      editFormat={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      time={false}
                      onChange={(e) => {
                        company.dateToEndableDispersion =
                          moment(e).format("DD/MM/YYYY");
                        updateCompany(company, "dateToEndableDispersion");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="center-content">
                    <InputGroup className="amount-max-width">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="number"
                        disabled="disabled"
                        className="disabled-white-selection amount-max-width"
                        value={ company.isDisabled ? (totalCount?.id == company._id
                          ? parseFloat(totalCount?.total).toFixed(2)
                          : parseFloat(company.amountToEnable).toFixed(2)) : ""
                          
                        }
                        id={`amountToEnable-${company._id}`}
                        onBlurCapture={(e) => {
                          updateCompany(company, "amountToEnable");
                        }}
                        onInput={async (e) => {
                          if (company.amountToEnable != e.target.value) {
                            company.amountToEnable = e.target.value;
                            company.updateCompany = true;
                            updateCompany(company, "amountToEnable");
                            setCompanies({
                              allCompanies: allCompanies,
                              companiesByPage: companiesByPage,
                            });
                          } else {
                            company.updateCompany = false;
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="disabled-white-selection"
                    value={company.isDisabled || statusTable=="Liberadas"  ?  company.desactivationComments : ""}
                    id={`comment-${company._id}`}
                    onBlurCapture={(e) => {
                      updateCompany(company, "desactivationComments"); 
                    
                      company.updateCompany = true;
                      updateCompany(company, "amountToEnable");
                    }}
                    onInput={async (e) => {
                      if (company.desactivationComments != e.target.value) {
                        company.desactivationComments = e.target.value;
                        company.updateCompany = true;
                        setCompanies({
                          allCompanies: allCompanies,
                          companiesByPage: companiesByPage,
                        });
                      } else {
                        company.updateCompany = false;
                      }
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="disabled-white-selection"
                    value={company.isDisabled || statusTable=="Liberadas"  ?  company.desactivationComments2 : ""}
                    id={`comment2-${company._id}`}
                    onBlurCapture={(e) => {
                      updateCompany(company, "desactivationComments2"); 
                    
                      company.updateCompany = true;
                      updateCompany(company, "amountToEnable");
                    }}
                    onInput={async (e) => {
                      if (company.desactivationComments2 != e.target.value) {
                        company.desactivationComments2 = e.target.value;
                        company.updateCompany = true;
                        setCompanies({
                          allCompanies: allCompanies,
                          companiesByPage: companiesByPage,
                        });
                      } else {
                        company.updateCompany = false;
                      }
                    }}
                  />
                </td>
                <td>
                  <div className="center-content">
                    <Form.Check
                      type="switch"
                      id={`switch-${company._id}`}
                      className={`custom-switch pointer ${getSwitchColorClassByStatus(
                        company
                      )}`}
                      onChange={() => {
                        if(company.isDisabled) {
                          company.amountToEnable = "";
                        }
                        company.isDisabled = !company.isDisabled;
                        allCompanies?.companies?.map((element, index) =>{
                          if(company._id === element._id){
                    
                            allCompanies.companies[index].isDisabled = company.isDisabled;
                            allCompanies.companies[index].dateToEndableDispersion = "";
                            allCompanies.companies[index].amountToEnable = "";
                            setCompany(company._id);
                            
                          } 
                        })
                       
                    
                       // allCompanies.companies[0].isDisabled = company.isDisabled;
                        updateCompany(company, "isDisabled");
                        updateCompany(company, "dateToEndableDispersion");
                        updateCompany(company, "desactivationComments");
                        updateCompany(company, "desactivationComments2");
                      }}
                      checked={!company.isDisabled}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

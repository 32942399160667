import React from 'react'
import {Modal, Button, Form, Col, Row, InputGroup} from 'react-bootstrap';
import Methods from '../../../utils/https';
import Coin from '../../../assets/IconsCaintra/Coin.svg'
import moment from 'moment'

export const PurchaseOrder = ({approvedInterns, showPurchaseOrderModal, sendPrefactureAux,  setSendPrefactureAux, updateList, setUpdateList, idCompany, company, period, lastPeriod, isConsolidationPrefacture}) => {

    const sendSetCompaqPrefacture = async(e) => {
        e.preventDefault();
        const periodAux = new Array(...period);
        let purchaseOrder = e.target.elements.purchaseOrder.value;
        let prefactureDetailId = approvedInterns[0].prefacture_detail_id
        if(isConsolidationPrefacture && lastPeriod){
            periodAux[0] = moment(lastPeriod.start_date, 'DD/MM/YYYY');
        }
        await Methods.setCompaqPrefacture(prefactureDetailId, purchaseOrder, idCompany, company, sendPrefactureAux.observations, sendPrefactureAux.authorizedInfo, periodAux, lastPeriod, isConsolidationPrefacture);
        await Methods.createRecord(sendPrefactureAux.record);
        handleClose();
        setUpdateList(!updateList);
    }

    const handleClose = () => {
        setSendPrefactureAux({...sendPrefactureAux, showSendPrefacture: false, showPurchaseOrderModal: false})
    }
    
    return (
        <Modal className="new-cc-modal purchase-padding" onHide={() => {handleClose()}} show={showPurchaseOrderModal} centered>
            <Modal.Body>
                <Form onSubmit={sendSetCompaqPrefacture} autoComplete={"off"}>
                    <Modal.Header>
                        <h3 className='purchase-order-title'>Orden de compra</h3>
                    </Modal.Header>
                    <Row>
                        <Col className='text-column'>
                            <Form.Group controlId="purchaseOrder" className='purchase-order'>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            placeholder='Indetificador de orden de compra'
                                            className='input-icon-text'
                                        />
                                        <InputGroup.Text className='input-icon'>
                                            <img src={Coin} size={25}/>
                                        </InputGroup.Text>
                                    </InputGroup>
                            </Form.Group>
                        </Col> 
                    </Row>
                    
                    <div className='center-buttons'>
                        <Button className="float-right" variant="danger" type="button" onClick={() => handleClose()}>
                            Cancelar
                        </Button>

                        <Button className="float-right ml-2" variant="primary" type='submit'>
                            Timbrar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

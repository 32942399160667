export const getLiberationEmailText = (
    liberationDate = '', customer
) => {

    return `
         Estimado socio – cliente  ${customer}
        <br>
        Le informamos que el estatus de su empresa ha cambiado de "Suspendida" a "Liberada" para el servicio de practicantes. 
        <br>
        Por tal motivo la dispersión se realizará el día ${liberationDate} a más tardar a las 8:00pm 
        <br>
        Cualquier duda favor de contactar al ejecutivo de cuentas por cobrar. 
        <br>
        Atentamente
        Tesorería CAINTRA
    `
    
}
import JsPDF from 'jspdf'
const InternsLogo = require("./img");

function getFont() {
    return "helvetica";
  }

  function getDate() {
    const today = new Date();
    const anio = today.getFullYear();
    const mes = today.getMonth();
    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const m = meses[mes];
    let dateDay = today.getDate().toString();
    if (today.getDate() <= 9) {
      dateDay = '0' + dateDay;
    }
    return dateDay + "-" + m + "-" + anio;
  }

  function formatDate(date){
    const meses = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const fechaSplit = date.split("-");
    return fechaSplit[2] + "-" + meses[parseInt(fechaSplit[1], 10)] + "-" + fechaSplit[0];
  }

  function getHeader(doc, title, y) {
    const alto = 90;
    const largo = 600;
    doc.addImage(InternsLogo.getImgCaintra(doc), 'png', 55, y + 30, 80, 30);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.5);
    doc.line(40, y, largo, y);
    doc.line(40, y, 40, y + alto);
    doc.line(40, y + 90, largo, y + 90);
    doc.line(150, y + 50, 450, y + 50);
    doc.line(40, y + alto, largo, y + alto);
    doc.line(150, y, 150, y + alto);
    doc.line(450, y, 450, y + alto);
    doc.line(largo, y, largo, y + alto);
    doc.setFont(getFont(), "bold");
    doc.addImage(InternsLogo.getImgCaintra(doc), 'png', 469, y + 30, 80, 30);
    doc.text(160, y + 30, title);
    doc.setFont(getFont(), "bold");
    doc.setFont(getFont(), "normal");
    doc.setFontSize(8);
    doc.text(260, y + 72, '"Reporte de Practicantes"');
    doc.text(280, y + 82, 'PC+ CAINTRA');
    doc.setFontSize(9);
    doc.setFont(getFont(), "normal");
    doc.setFontSize(16);
    doc.text("Lista de practicantes", 80, 175);
  }

  function getFooter(doc, currentPage) {
    doc.setFont(getFont(), "normal");
    doc.setTextColor(128, 0, 0);
    doc.setFontSize(9);
    doc.text(50, 760, 'CONFIDENCIAL');
    doc.text(230, 760, 'PARA USO EXCLUSIVO CAINTRA');
    doc.text(490, 760, 'Página ' + currentPage);
    doc.setTextColor(0, 0, 0);
  }

  function getPortada(doc, title) {
    InternsLogo.getImgVertical(doc);
    doc.addImage(InternsLogo.getImgCuadros(doc), 'png', 540, 10, 60, 70);
    doc.addImage(InternsLogo.getImgCaintra(doc), 'png', 250, 250, 160, 80);
    doc.setDrawColor(210, 210, 210);
    doc.rect(110, 400, 480, 80);
  
    doc.setLineWidth(3);
    doc.line(110, 481, 590, 481);
  
    doc.setFontSize(12);
    doc.setFont(getFont(), "bold");
    doc.text(235, 430, title);
    doc.setFont(getFont(), "normal");
  
    doc.setFontSize(14);
    const now = new Date().toLocaleString("es-MX")
    doc.text(320, 690, 'Fecha de impresión: ' + now);
  
    doc.setFontSize(9);
    doc.setFont(getFont(), "normal");
    doc.text(310, 770, 'Reporte de Practicantes');
    doc.text(330, 780, 'PC+ CAINTRA');
  }
  

  async function getPdf(moduleTitle, data1) {
    let contador = 1; 
    const doc = new JsPDF('p', 'pt', 'letter');
    const font = getFont();
    const fecha = getDate();
    let currentPage = 0;
    const totalPage = 1;
    let y = 200;
    const pages = Math.ceil((data1.length + 1) / 20);
    console.log(pages);
    for (let i = 0; i < pages; i += 1){
      doc.setFontSize(10);
      getHeader(doc, moduleTitle + "--" + data1[i].companyName, 30);
      doc.setFontSize(8);
      y = 280;
      if(i === pages - 1){
        const extra = (data1.length % 20) + 1
        const alto = extra * 21;
        const largo = 600;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);
        doc.line(15, y, largo, y);
        doc.line(15, y, 15, y + alto);
        doc.line(15, y + alto, largo, y + alto);
        doc.line(largo, y, largo, y + alto);
        doc.line(48, y, 48, y + alto);
        doc.line(87, y, 87, y + alto);
        doc.line(127, y, 127, y + alto);
        doc.line(167, y, 167, y + alto);
        doc.line(192, y, 192, y + alto);
        doc.line(300, y, 300, y + alto);
        doc.line(395, y, 395, y + alto);
        doc.line(445, y, 445, y + alto);
        doc.line(488, y, 488, y + alto);
        doc.line(530, y, 530, y + alto);
        doc.line(553, y, 553, y + alto);
        for (let j = 1; j < extra; j += 1){
          const line = j * 21
          doc.line(15, y + line, largo, y + line);
        }
        const space = 21
        if(i == 0){
          doc.text(18, y + 15, "Estatus");
          doc.text(51, y + 15, "Nombre");
          doc.text(90, y + 15, "Ap Pat");
          doc.text(130, y + 15, "Ap Mat");
          doc.text(170, y + 15, "Edad");
          doc.text(195, y + 15, "Correo");
          doc.text(303, y + 15, "CURP");
          doc.text(398, y + 15, "Teléfono");
          doc.text(448, y + 15, "F Inicio");
          doc.text(491, y + 15, "F Final");
          doc.text(533, y + 15, "Dur.");
          doc.text(556, y + 15, "Proyecto");
          doc.setFont(getFont(), "normal");
          for (let j = 0; j < extra - 1; j += 1){
            doc.text(3, y + (space * (j + 1)) , contador.toString());
            contador++;
            doc.text(18, y + (space * (j + 1)) + 15, data1[(20 * i) + j].internStatus.substring(0,7));
            doc.text(51, y + (space * (j + 1)) + 15, data1[(20 * i) + j].firstname.substring(0,7));
            doc.text(90, y + (space * (j + 1)) + 15, data1[(20 * i) + j].father_lastname.substring(0,7));
            doc.text(130, y + (space * (j + 1)) + 15, data1[(20 * i) + j].mother_lastname.substring(0,7));
            doc.text(170, y + (space * (j + 1)) + 15, data1[(20 * i) + j].age !== undefined ?  data1[(20 * i) + j].age.toString() : "");
            doc.text(195, y + (space * (j + 1)) + 15, data1[(20 * i) + j].email.substring(0,26));
            doc.text(303, y + (space * (j + 1)) + 15, data1[(20 * i) + j].curp);
            doc.text(398, y + (space * (j + 1)) + 15, data1[(20 * i) + j].phone.toString());
            doc.text(448, y + (space * (j + 1)) + 15, data1[(20 * i) + j].contractStartDate);
            doc.text(491, y + (space * (j + 1)) + 15, data1[(20 * i) + j].contractEndDate);
            doc.text(533, y + (space * (j + 1)) + 15, data1[(20 * i) + j].contractDuration.toString());
            doc.text(556, y + (space * (j + 1)) + 15,data1[(20 * i) + j].projectName !== undefined ?  data1[(20 * i) + j].projectName.substring(0,9) : "");
          }
        } else{
          for (let j = 0; j < extra; j += 1){
            doc.text(3, y + (space * (j + 1)) , contador.toString() );
            contador++;
            doc.text(18, y + (space * j) + 15, data1[(20 * i) + j - 1].internStatus.substring(0,7));
            doc.text(51, y + (space * j) + 15, data1[(20 * i) + j - 1].firstname.substring(0,7));
            doc.text(90, y + (space * j) + 15, data1[(20 * i) + j - 1].father_lastname.substring(0,7));
            doc.text(130, y + (space * j) + 15, data1[(20 * i) + j - 1].mother_lastname.substring(0,7));
            doc.text(170, y + (space * j) + 15, data1[(20 * i) + j - 1].age!== undefined ?  (data1[(20 * i) + j - 1]?.age).toString() : "");
            doc.text(195, y + (space * j) + 15, data1[(20 * i) + j - 1].email.substring(0,26));
            doc.text(303, y + (space * j) + 15, data1[(20 * i) + j - 1].curp);
            doc.text(398, y + (space * j) + 15, data1[(20 * i) + j - 1].phone.toString());
            doc.text(448, y + (space * j) + 15, data1[(20 * i) + j - 1].contractStartDate);
            doc.text(491, y + (space * j) + 15, data1[(20 * i) + j - 1].contractEndDate);
            doc.text(533, y + (space * j) + 15, data1[(20 * i) + j - 1].contractDuration.toString());
            doc.text(556, y + (space * j) + 15,data1[(20 * i) + j - 1].projectName!== undefined ? data1[(20 * i) + j - 1].projectName.substring(0,9) : "");
          }
        }
      } else{
        const alto = 420;
        const largo = 600;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1);
        doc.line(15, y, largo, y);
        doc.line(15, y, 15, y + alto);
        doc.line(15, y + alto, largo, y + alto);
        doc.line(largo, y, largo, y + alto);
        doc.line(48, y, 48, y + alto);
        doc.line(87, y, 87, y + alto);
        doc.line(127, y, 127, y + alto);
        doc.line(167, y, 167, y + alto);
        doc.line(192, y, 192, y + alto);
        doc.line(300, y, 300, y + alto);
        doc.line(395, y, 395, y + alto);
        doc.line(445, y, 445, y + alto);
        doc.line(488, y, 488, y + alto);
        doc.line(530, y, 530, y + alto);
        doc.line(553, y, 553, y + alto);
        if(i == 0){
          const space = 21
          doc.text(18, y + 15, "Estatus");
          doc.text(51, y + 15, "Nombre");
          doc.text(90, y + 15, "Ap Pat");
          doc.text(130, y + 15, "Ap Mat");
          doc.text(170, y + 15, "Edad");
          doc.text(195, y + 15, "Correo");
          doc.text(303, y + 15, "CURP");
          doc.text(398, y + 15, "Teléfono");
          doc.text(448, y + 15, "F Inicio");
          doc.text(491, y + 15, "F Final");
          doc.text(533, y + 15, "Dur.");
          doc.text(556, y + 15, "Proyecto");
          doc.setFont(getFont(), "normal");
          for (let j = 1; j < 20; j += 1){
            doc.text(3, y + (space * (j + 1)) , contador.toString() );
            contador++;
            doc.text(18, y + (space * j) + 15, data1[j - 1].internStatus.substring(0,7));
            doc.text(51, y + (space * j) + 15, data1[j - 1].firstname.substring(0,7));
            doc.text(90, y + (space * j) + 15, data1[j - 1].father_lastname.substring(0,7));
            doc.text(130, y + (space * j) + 15, data1[j - 1].mother_lastname.substring(0,7));
            doc.text(170, y + (space * j) + 15, data1[j - 1].age!== undefined ? data1[j - 1].age.toString() : "");
            doc.text(195, y + (space * j) + 15, data1[j - 1].email.substring(0,26));
            doc.text(303, y + (space * j) + 15, data1[j - 1].curp);
            doc.text(398, y + (space * j) + 15, data1[j - 1].phone.toString());
          doc.text(448, y + (space * j) + 15, data1[j - 1].contractStartDate);
          doc.text(491, y + (space * j) + 15, data1[j - 1].contractEndDate);
          doc.text(533, y + (space * j) + 15, data1[j - 1].contractDuration.toString());
          doc.text(556, y + (space * j) + 15, data1[j - 1].projectName!== undefined ? data1[j - 1].projectName.substring(0,9) : "");
          }
        } else{
        const space = 21
        for (let j = 0; j < 20; j += 1){
          doc.text(3, y + (space * (j + 1)) , contador.toString() );
          contador++;
          doc.text(18, y + (space * j) + 15, data1[(20 * i) + j - 1].internStatus.substring(0,7));
          doc.text(51, y + (space * j) + 15, data1[(20 * i) + j - 1].firstname.substring(0,7));
          doc.text(90, y + (space * j) + 15, data1[(20 * i) + j - 1].father_lastname.substring(0,7));
          doc.text(130, y + (space * j) + 15, data1[(20 * i) + j - 1].mother_lastname.substring(0,7));
          doc.text(170, y + (space * j) + 15, data1[(20 * i) + j - 1].age!== undefined ?  data1[(20 * i) + j - 1].age.toString() : "");
          doc.text(195, y + (space * j) + 15, data1[(20 * i) + j - 1].email.substring(0,26));
          doc.text(303, y + (space * j) + 15, data1[(20 * i) + j - 1].curp);
          doc.text(398, y + (space * j) + 15, data1[(20 * i) + j - 1].phone.toString());
          doc.text(448, y + (space * j) + 15, data1[(20 * i) + j - 1].contractStartDate);
          doc.text(491, y + (space * j) + 15, data1[(20 * i) + j - 1].contractEndDate);
          doc.text(533, y + (space * j) + 15, data1[(20 * i) + j - 1].contractDuration.toString());
          doc.text(556, y + (space * j) + 15, data1[(20 * i) + j - 1].projectName!== undefined ? data1[(20 * i) + j - 1].projectName.substring(0,9) : "");
        }
        }
        for (let j = 1; j < 20; j += 1){
          const line = j * 21
          doc.line(15, y + line, largo, y + line);
        }
      }
      currentPage += 1
      getFooter(doc, currentPage.toString());
      if(contador <= data1.length){
        doc.addPage();
      }
    }


    window.open(doc.output('bloburl'), '_blank', "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=200,left=350,width=1200,height=1200");
}

/* eslint-disable import/prefer-default-export */
export { getPdf };

import excelByItems from '../../../utils/excelByItems';
import { getFormatedAmount } from './getFormatedAmount'
import * as Excel from "exceljs";
import moment from 'moment';
export const createExcel = async(dataToDisplay, createdBy) => {
    try{
        const excelKeys = [
            {key: 'adviserBankName', label: 'Banco/Asesor', customColor: 'ffe9edf3'},
            {key: 'total', label: 'Total', customColor: 'ffe9edf3'},
            {key: 'fileName', label: 'Nombre de archivo', customColor: 'ffe9edf3'}
        ]

        let excelFormatedData = [];

        for(let data of dataToDisplay){
            data.advisers.map( advisorLine => {
                excelFormatedData.push({
                    adviserBankName: advisorLine.adviserName,
                    total: '$' + getFormatedAmount(advisorLine.totalByBank),
                    customColor: 'ffffffff',
                    fileName: ''
                });
            });

            excelFormatedData.push({
                adviserBankName: data.bankName,
                total: '$' + getFormatedAmount(data.total),
                customColor: 'fffeff00',
                fileName: data.fileName || ''
            });
        }

        const workbook = new Excel.Workbook();  

        await excelByItems.test(
            workbook,
            [{
                name: 'Montos',
                values: excelFormatedData
            }]
            ,
            excelKeys,
            moment().format(),
            createdBy
        );
        
        let result = workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
            return blob;
        });

        return result

    }catch(error){
        console.log(error)
    }

}
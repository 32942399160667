import React, {useState, useEffect} from 'react';
import {Table, Row, Col, Form} from 'react-bootstrap';
import {motion} from 'framer-motion';
import {DateTimePicker} from 'react-widgets';
import moment from 'moment';

export default function ListPrefactures({interns, payDate, setPayDate, getCompanyName, getCompanyPaymentPeriod, setSelectedInterns}){
    const [startDate, endDate] = payDate;
    const [filteredInterns, setFilteredInterns] = useState(interns);

    useEffect(() => {
        getInternsWithoutCLABE();
    }, [interns]);

    const getInternsWithoutCLABE = () => {
        let _filtered;
        _filtered = interns.filter(intern => intern.clabe == "");
        setSelectedInterns(_filtered);
        setFilteredInterns(_filtered);
    };

    const handleChangePayDate = (date) => {
        const newStartDate = new Date(date);
        const day = newStartDate.getDate();
        const year = newStartDate.getFullYear();
        const month = newStartDate.getMonth();
        let newEndDate;

        if(day >= 15) newEndDate = new Date(year, month, 0);
        if(day < 15) newEndDate = new Date(year, month, 15);

        setPayDate([newStartDate, newEndDate]);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Pendiente":
                return "#FFB100";
            case "Dispersado":
                return "#25EB51";
            default:
                return "#D7D7D7";
        };
    };

    return(
        <Col style={{height: "75vh", overflowX: "auto", paddingLeft: "0"}}>
            <Row>
                <Col md={12} xs={12} sm={12}>
                    <div className="options">
                        <Form.Group controlId="payDate">
                            <DateTimePicker placeholder="Fecha de Pago" value={startDate} onCurrentDateChange={(date) => handleChangePayDate(date)} time={false} format={"DD/MM/YYYY"} />
                        </Form.Group>
                    </div>
                </Col>
            </Row>
            
            {filteredInterns.length > 0 && 
                <motion.div initial={{y: -30, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{ duration: 0.4 }}>
                    <div className="main-table">
                        <Row className="table-header">
                            <Col>Compañía</Col>
                            <Col>Nombres</Col>
                            <Col>Apellido Paterno</Col>
                            <Col>Apellido Materno</Col>
                            <Col>Periodo de pago</Col>
                            <Col>Fecha de pago</Col>
                            <Col>Banco</Col>
                            <Col>Monto a dispersar</Col>
                            <Col>Estatus</Col>
                        </Row>
                        {filteredInterns.map((intern, index) => {
                            return (
                                <Row className="table-body">
                                    <Col>{getCompanyName(intern.id_company)}</Col>
                                    <Col>{intern.firstname}</Col>
                                    <Col>{intern.father_lastname}</Col>
                                    <Col>{intern.mother_lastname}</Col>
                                    <Col>{getCompanyPaymentPeriod(intern.id_company)}</Col>
                                    <Col>{moment(startDate).format("D/MM/YYYY")}</Col>
                                    <Col>{intern.bank}</Col>
                                    <Col>$ {intern.dispersionAmount}</Col>
                                    <Col style={{color: getStatusColor(intern.dispersionStatus)}}>{intern.dispersionStatus}</Col>
                                </Row>
                            )
                        })}
                    </div>
                </motion.div>
            } 
        </Col>
    );
};
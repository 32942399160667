import React, { useContext, useEffect, useState } from 'react';
import InternsLogo from '../../../assets/logo_interns.png';
import { Modal, Button, Form, Image, Row, Col } from 'react-bootstrap';
import { notify } from "../../../utils/notify";
import { UserContext } from '../../../context/UserContext';
const $ENV = process.env.env;
const axios = require("axios");

const temas = [
  { "id": "1", "tema": "Desarrollo personal", "titulo": "Desarrollo personal", "subtitulo": "Desarrollo personal", "autor": "Grow", "caption": "img/resources/desarrollo.jpeg" },
  { "id": "2", "tema": "Tendencias en empresas", "titulo": "Tendencias en empresas", "subtitulo": "Tendencias en empresas", "autor": "Grow", "caption": "img/resources/tendencias.jpg" },
  { "id": "3", "tema": "Básicos para la incorporación laboral", "titulo": "Básicos para la incorporación laboral", "subtitulo": "Básicos para la incorporación laboral", "autor": "Grow", "caption": "img/resources/incorporacion.jpg" },
  { "id": "4", "tema": "Desarrollo técnico", "titulo": "Desarrollo técnico", "subtitulo": "Desarrollo técnico", "autor": "Grow", "caption": "img/resources/tecnico.jpg" },
];


export default function Training({ setUpdateList, setShowTrainingModal, showTrainingModal, currentInternId, updateList, currentIntern, setCurrentIntern }) {
  const URL = process.env.REACT_APP_SOCKET_URL;
  const context = useContext(UserContext);
  
  const [resultados, setResultados] = useState([]);
  let result = [];

  const QueryData = async () => {
   
    

    console.log("montando logros");
    for (let i = 0; i < temas.length; i++) {
      result[i] = await axios({
        url: `${URL}/getTest`,
        method: "POST",
        data: { userId: currentInternId, item: temas[i].titulo }
      });

      let tmp = result[i]?.data[0]?.correctas * 5 / result[i]?.data[0]?.totales;
      result[i].data[0].calificacion = tmp;
    }
   
    console.log(resultados);
  };

  /* useEffect(() => {
    QueryData();
  }, []); */

  const test = async (e) => {
    let tmpRes = [];
    setResultados([]);
   

    for (let i = 0; i < temas.length; i++) {
        result[i] = await axios({
          url: `${URL}/getTest`,
          method: "POST",
          data: { userId: currentInternId, item: temas[i].titulo }
        });
     
        if(result[i].data.length>0){
            let tmp = result[i]?.data[0]?.correctas * 5 / result[i]?.data[0]?.totales;
            result[i].data[0].calificacion = tmp;
            tmpRes.push(result[i]);
        
        }
      }

    setResultados(tmpRes)

  };

  useEffect(() => {
    if(showTrainingModal){

      test();
    }
  }, [showTrainingModal]);
  
  return (
    <Modal className="training-intern-modal" show={showTrainingModal} onHide={() => setShowTrainingModal(!showTrainingModal)} centered>
        <Modal.Header>
            <h5>Resultados que ha obtenido el practicante</h5>
        </Modal.Header>

        <Modal.Body>
            
            <Form onSubmit={test}>

              <Row className="result-container">
                <Col md={12}  className="col">
                <table className="table">
                <thead>
                    <tr>
                      <th>Curso</th>
                      <th>Puntuación</th>
                    </tr>
                  </thead>
                  <tbody>
                {resultados.map((elemento, index) => {
                  console.log("ELEMENTO", resultados);
                  let calificacion = elemento.data[0].calificacion;
                  let estrellas = '';
              
                  for (let i = 0; i < calificacion; i++) {
                    estrellas += '⭐'; // Agregar una estrella por cada respuesta incorrecta
                  }
                   
                  return (
                    <tr>
                      <td>{elemento.data[0].tema}</td>
                      <td>{estrellas}</td>
                    </tr>
                  )
                    
                })}

                </tbody>
                </table>

                </Col>
              </Row>
                

              <Row className="btn-container">
                  <Col md={4} className="col">
                      <Button className="cancel-btn" onClick={() => setShowTrainingModal(!showTrainingModal)}>
                          Cerrar
                      </Button>             
                  </Col>
                  
              </Row>

            </Form>
        </Modal.Body>
    </Modal>
);
};

import { notify } from '../../../utils/notify';
export const canDisperseByValidations = (
    confirmation, 
    selectedInterns, 
    context,
    dispersionTypeSelected,
    nextNumber,
    selectedBank
) => {

    for(let intern of selectedInterns){
        if(!intern.clabe){
            let name = intern.firstname + " " + intern.father_lastname + " " + intern.mother_lastname; 
            notify("El practicante " + name + " no tiene una cuenta de banco" , "error");
            return false;
        };
    }
    
    if(!context.can("disperse", "Dispersion")){
        notify("No tienes acceso a esta funcionalidad, solicítala a un administrador", "error");
        return false;
    };

    if(confirmation.trim().toLowerCase() != "dispersar"){
        notify("La palabra de confirmación es incorrecta, por favor intenta nuevamente", "error");
        return false;
    };

    if(!dispersionTypeSelected){
        notify('Debes seleccionar un tipo de dispersión', 'error');
        return false 
    }

    if((selectedBank != 'Santander' && selectedBank != 'Bancomer') && (!nextNumber)){  
        notify('Es necesario ingresar el número siguiente para continuar', 'error');
        return false;
    }

    return true;
}
import moment from 'moment';
export default {

    test(workbook, filters, data, companyReason, dates, departmentField) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()


        const cols = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF"];
        let config = [ //cols & config, same length
            {  width: 20,  value: "internStatus",       text: "Estatus"  },
            {  width: 20,  value: "agreement",          text: "ID Convenio"  },
            {  width: 20,  value: "firstname",          text: "Nombres"  },
            {  width: 20,  value: "father_lastname",    text: "Apellido Paterno"  },
            {  width: 20,  value: "mother_lastname",    text: "Apellido Materno"  },
            {  width: 20,  value: "age",                text: "Edad"  },
            {  width: 20,  value: "email",              text: "Correo"  },
            {  width: 20,  value: "curp",               text: "CURP"  },
            {  width: 20,  value: "phone",              text: "Teléfono"  },
            {  width: 20,  value: "bank",               text: "Banco"  },
            {  width: 20,  value: "location",           text: "Dirección"  },
            {  width: 20,  value: "born_date",          text: "Fecha de nacimiento"  },
            {  width: 20,  value: "gender",             text: "Género"  },
            {  width: 20,  value: "_id",                text: "ID practicante"  },
            {  width: 20,  value: "startDate",          text: "Fecha de Inicio"  },
            {  width: 20,  value: "endDate",            text: "Fecha de término"  },
            {  width: 20,  value: "contractStartDate",  text: "Fecha Inicio del Contrato"  },
            {  width: 20,  value: "contractEndDate",    text: "Fecha Fin del Contrato"  },
            {  width: 20,  value: "contractDuration",   text: "Duración Contrato"  },
            {  width: 20,  value: "department_name",    text: departmentField  },
            {  width: 20,  value: "directBoss_name",    text: "Jefe directo"  },
            {  width: 20,  value: "directBoss_email",   text: "Correo del Jefe Directo"  },
            {  width: 20,  value: "projectName",        text: "Proyecto"  },
            {  width: 20,  value: "unsuscribeMotive",   text: "Motivo de Cancelación"  },
            {  width: 20,  value: "cost_center_name",   text: "Centro de Costos"  },
            {  width: 20,  value: "scolarshipAmount",   text: "Aportación Mensual"  },
            {  width: 20,  value: "college",            text: "Universidad"  },
            {  width: 20,  value: "career",             text: "Carrera"  },
            {  width: 20,  value: "period_type",        text: "Ciclo escolar"  },
            {  width: 20,  value: "grade",              text: "Grado"  },
            {  width: 20,  value: "_group",             text: "Grupo"  },
            {  width: 20,  value: "school_enrollment",  text: "Matricula"  }
        ];


        function filterIncludes(configItem){
            for(let index in filters){
                if(filters[index].value == configItem.value) return true;
            };
            return false;
        }
        config = config.filter((configItem) => filterIncludes(configItem))


        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet("Reportes", {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }


        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'left' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }


        function createWhiteCell(startCell, text, worksheetName){
            let textAux = (text || "").toString();
            
            if(textAux != ""){
                text = textAux.replace("undefined", "");
            } else{
                text = textAux;
            }
            

            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'left' }
            worksheetName.getCell(startCell).value = {
              richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A1",
                "Razón Factura:",
                worksheetName
            )
            worksheetName.mergeCells("B1" + ":" + "C1")

            createWhiteCell(
                "B1",
                companyReason  || "",
                worksheetName
            )

            createBlueCell(
                "D1",
                "Período: ",
                worksheetName
            )
            worksheetName.mergeCells("E1" + ":" + "F1")

            createWhiteCell(
                "E1",
                getPeriodString(dates)  || "",
                worksheetName
            )

            createBlueCell(
                "G1",
                "Fecha de Creación:",
                worksheetName
            )

            worksheetName.mergeCells("H1" + ":" + "I1")

            createWhiteCell(
                "H1",
                moment().format('DD/MM/YYYY'),
                worksheetName
            )
        }

        const getPeriodString = (period) => {
            let [startDate, endDate] = period;
            let a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
            let b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
            return `Del ${a} al ${b}`;
        };

        const getFormatedDate = (dateString) => {
            const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
            let result;
            let year;
            let month;
            let day;
            if (regexDate.test(dateString)) {
                const newDateArray = dateString.split("-");
                year = newDateArray[0];
                month = addZeroToDate(newDateArray[1]);
                day = addZeroToDate(newDateArray[2]);
                result = month + "/" + day + "/" + year;
            } else {
                const newDateArray = dateString.split("/");
                month = addZeroToDate(newDateArray[0]);
                day = addZeroToDate(newDateArray[1]);
                result = day + "/" + month + "/" + newDateArray[2];
            }
            return result;
        }

        const addZeroToDate = (value) => {
            if (value && value.length == 1) {
                return "0" + value;
            } else {
                return value;
            }
        };


        function createTableAvail(data, worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                "Reportes",
                worksheetName
            )
            cellNumber++

            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });

            data.forEach(row => {
                cellNumber++
                config.forEach(configItem => {
                    createWhiteCell(
                        configItem.col + cellNumber,
                        row[configItem.value],
                        worksheetName
                    )
                });
            });
        }


        createTableAvail(data, worksheetPicoUsoInfra)
        return workbook
    }
}

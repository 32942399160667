import {CognitoUserPool} from "amazon-cognito-identity-js";
const env = "prod";

export function getPoolConfig() {
    let poolData;
    let userPool;

    switch (env) {
        case "testing":

            poolData = {
                UserPoolId: "us-east-1_PLzuHUrFl",
                ClientId: "7q643v9t9q0aic6925arj1fpdv",
            };

            return userPool = new CognitoUserPool(poolData);
            break;
        case "uat":

            poolData = {
                UserPoolId: "us-east-1_3DixFlEtw",
                ClientId: "7var0jdorbt6e8hvi5ibcoh69o",
            };

            return userPool = new CognitoUserPool(poolData);
            break;

        case "preprod":

            poolData = {
                UserPoolId: "us-east-1_utzsmuuOz",
                ClientId: "7kcpdhb86qv83a76nd4mcismq8",
            };
            return userPool = new CognitoUserPool(poolData);

            break;
    
        case "preprod-tec":

                poolData = {
                    UserPoolId: "us-east-1_ffHi5LVoI",
                    ClientId: "h0kh08396nf2urrg20l8vbrlt",
                };
                return userPool = new CognitoUserPool(poolData);

            break;
        
        case "prod":

                poolData = {
                    UserPoolId: "us-east-1_C8yOPa324",
                    ClientId: "acf1tbchbd1trjisla44amd1s",
                };
                return userPool = new CognitoUserPool(poolData);

            break;

        default:

            poolData = {
                UserPoolId: "us-east-1_PgxuiMK99",
                ClientId: "492tgoevfudetchdif356cqc00",
            };

            return userPool = new CognitoUserPool(poolData);
        break;
    }
}


export function getPoolData() {
    let poolData;


    switch (env) {

        case "testing":
            poolData = {
                UserPoolId: "us-east-1_PLzuHUrFl",
                ClientId: "7q643v9t9q0aic6925arj1fpdv",
                Region: "us-east-1"
            };

            return poolData;
            break;
        case "uat":

            poolData = {
                UserPoolId: "us-east-1_3DixFlEtw",
                ClientId: "7var0jdorbt6e8hvi5ibcoh69o",
                Region: "us-east-1"
            };

            return poolData;
            break;

        case "preprod":

            poolData = {
                UserPoolId: "us-east-1_utzsmuuOz",
                ClientId: "7kcpdhb86qv83a76nd4mcismq8",
                Region: "us-east-1"
            };
            return poolData;
            break;

        case  "preprod-tec":

            poolData = {
                UserPoolId: "us-east-1_ffHi5LVoI",
                ClientId: "h0kh08396nf2urrg20l8vbrlt",
                Region: "us-east-1"
            }
            return poolData;
            break;
        
        case "prod":

            poolData = {
                UserPoolId: "us-east-1_C8yOPa324",
                ClientId: "acf1tbchbd1trjisla44amd1s",
                Region: "us-east-1"
            };
            return poolData;
            break;
        default:
            poolData = {
                UserPoolId: "us-east-1_PgxuiMK99",
                ClientId: "492tgoevfudetchdif356cqc00",
                Region: "us-east-1"
            };

            return poolData;
            break;
    }
}

import React from 'react'
import DatePicker from "react-datepicker";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
export const DatePickerSection = ({
    setAux,
    label,
    dateAux
}) => {
  return (
    <>
        {label}  
        <Button 
            className="default-button date-btn"
            style={{
                backgroundImage: "none", 
                backgroundColor: "#1B3769",
                marginLeft: "5px"
            }}
        >
        <FontAwesomeIcon className="inner-text" icon={faCalendar}/>
        <DatePicker
            selected={dateAux}
            onChange={
                async (date) => {
                    setAux(date);
                }
            }
            placeholderText={label}
            dateFormat='dd MMM yyyy'
            style={{backgroundImage: "none", backgroundColor: "transparent"}}
        />  
        </Button> 
    </>   
  )
}

import React, { useEffect, useState, useContext } from 'react';
import {Form, Row, Col, Button, InputGroup, Modal, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
import {DateTimePicker} from 'react-widgets';
import Methods from '../../../utils/https';
import {UserContext} from '../../../context/UserContext';
import moment from 'moment';


export default function ApproveConvenio(props) {

    function handleClose() {
        props.setShowApproveConvenio(false);
    };

    return (
        <Modal className="new-cc-modal" show={props.showApproveConvenio} onHide={() => handleClose()} centered>
            <Modal.Header>
                <Image src={InternsLogo} className="logoCaintra" />
                <p>Renovación de practicante</p>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='center-modal-text'>
                    <Form.Label>
                        ¿Ya actualizaste los datos de la póliza para la renovación?
                    </Form.Label>
                </Form.Group>


                <div className='center-buttons'>
                    <Button className="float-right" variant="danger" type="button" onClick={() => { props.setDocumentationTab('internMedicalInsurance'); handleClose(); }}>
                        No
                    </Button>
                    <Button className="float-right ml-2" variant="primary" onClick={props.approveConvenioHandler}>
                        Sí
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
};
import React, { useState } from "react";
import { Container, Button, lightColors, darkColors } from 'react-floating-action-button';
import '../Layout.css'; 
import {Modal, Button as ButtonB} from 'react-bootstrap';

function ChatBot(){
    const [openBotUserModal, setOpenBotUserModal] = useState(false);
    return(
        <>
        <Container>
            <Button
            tooltip="ChatBot"
            onClick={() => setOpenBotUserModal(true)}
            styles={{backgroundColor: darkColors.lightBlue, color: lightColors.white, left: "100px", top:"80px", zIndex: "1000", backgroundImage:"url(https://caintrabucket-testing.s3.amazonaws.com/mailImages/ChatIco.png)"}}
            />
        </Container>

    <Modal  show={openBotUserModal}  centered>

      <Modal.Body style={{width: "800px"}}>
          
              

              {<iframe src="https://app.chatsimple.ai/iframe23/46f09b22-840f-4044-ab5a-248e2057e2ae/6a390712-ccb0-4b03-a62c-5db8a0cc6085/cc56d198-19ab-4fae-a43d-83db2ad38de3" height="400" width="769" title="Chatsimple" style={{
                  display: "block",
                  marginLeft: "auto", 
                  marginRight: "auto",
                  border: "none",
                  borderRadius: '20px',
                  boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                  zIndex : "100",
                  position: "relative !important"
                }}></iframe>}


              <ButtonB className="float-right" variant="danger" onClick={() => setOpenBotUserModal(false)}>
                  Cerrar
              </ButtonB>             
       
      </Modal.Body>
    </Modal>
    
    </>
    );
};

export default ChatBot;
import React, {useState, useContext, useEffect} from 'react';
import {Modal, Button, Form, Image, Spinner, Row, Col, Table} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {UserContext} from '../../../context/UserContext';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import {motion} from 'framer-motion';


const axios = require("axios");

export default function EditAdvisorModal({openEditUserModal, setOpenEditUserModal, currentAdvisor, updateList, setUpdateList, advisors}){
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(UserContext);
    const canEdit = context.can("update", "Users");
    const [companies, setCompanies] = useState(undefined);

    useEffect(() => {
        getAllCompanies();
    }, [updateList]);

    const getAllCompanies = async () => {
        const _comp = await Methods.getAllCompanies();
        _comp.companiesObject && setCompanies(_comp.companiesObject);
    };

    const isCompanyAdvised = (companyId) => {
        if(currentAdvisor.advisedCompanies && currentAdvisor.advisedCompanies.some(id => id == companyId)){
            return true;
        }
        return false;
    }

    const disableCheckbox = (companyId) => {
        for(let advisor of advisors){
            if(advisor.advisedCompanies && advisor.advisedCompanies.some(id => id == companyId) && advisor._id != currentAdvisor._id){
                return true;
            }
        }
        return false;
    }

    const updateCompany = (companyId, value) =>{
        if(value){
           if(!currentAdvisor.advisedCompanies.includes(companyId)) currentAdvisor.advisedCompanies.push(companyId);
        } else {
            currentAdvisor.advisedCompanies = currentAdvisor.advisedCompanies.filter(id => id != companyId);
            if(companyId != currentAdvisor.principalCompany){
                currentAdvisor.companies = currentAdvisor.companies.filter(id => id != companyId);
            }
        }
    }

    const updateUser = async (e) => {
        e.preventDefault();

        if(!canEdit){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        setIsLoading(true);

        let companiesArrAux = currentAdvisor.advisedCompanies.filter((v, i, a) => a.indexOf(v) === i);
        let companies = currentAdvisor.companies.filter((v, i, a) => a.indexOf(v) === i);

        let dataToBeUpdated = {
            _id: currentAdvisor._id,
            attributes: [{updateKey: "advisedCompanies", updateValue: companiesArrAux }, {updateKey: "companies", updateValue: companies }],
            userEmail: currentAdvisor.email,
        };

        

        await Methods.updateUser(dataToBeUpdated);
        setIsLoading(false);
        setUpdateList(!updateList);
        setOpenEditUserModal(false);
    };

    return(
        <Modal className="prefacture-modals" show={openEditUserModal} onHide={() => setOpenEditUserModal(!openEditUserModal)} centered>
            <Modal.Header>
                <Image src={InternsLogo} className="logoCaintra" />
            </Modal.Header>

            <Modal.Body>
                <p>Actualizar empresas de {currentAdvisor.fullName}</p>
                <Form onSubmit={updateUser} autoComplete={"off"}>
                    <Row>
                        <Col>
                        <motion.div initial={{opacity: 0, y: -40}} animate={{opacity: 1, y: 0}} className="table-width">
                            <Table triped bordered responsive hover className="mt-4 h-50" style={{marginBottom: "100px"}}>
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>RFC</th>
                                        <th>Asignar</th>
                                    </tr>
                                </thead>
                                {companies && <tbody style={{height: "50%", overflow:"scroll"}} >
                                    {companies.map((company) => {
                                            return(
                                                <tr>
                                                    <td>
                                                        <div className="center-container">
                                                            {company.name}
                                                        </div>  
                                                    </td>
                                                    <td>
                                                        <div className="center-container">
                                                            {company.rfc}
                                                        </div>  
                                                    </td>
                                                    <td>
                                                        <div className="center-container">
                                                        <Form.Check 
                                                            type="checkbox" 
                                                            defaultChecked={isCompanyAdvised(company.id)}
                                                            onChange={event => updateCompany(company.id ,event.target.checked)}
                                                        />
                                                        </div>  
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>}
                            </Table>
                        </motion.div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button className="btn-register float-right" disabled={!canEdit} variant="primary" type="submit">
                                {isLoading ? <Spinner animation="border" /> : "Actualizar"}
                            </Button>

                            <Button className="float-right mr-3" variant="danger" onClick={() => setOpenEditUserModal(!openEditUserModal)}>
                                Cancelar
                            </Button>  
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
import React from "react";
import { Text, StyleSheet, Document, Page} from "@react-pdf/renderer";

export const ConfidentialityAgreements = (props) => {
    return (
        <Document>
            <Page style={styles.page} size="A4">
                <Text style={styles.text}>
                    Carta de confidencialidad para Campus Intern del {props.companyName}
                </Text>
                <Text style={[styles.text, styles.alignRigth]}>Monterrey, N.L.</Text>

                <Text style={styles.text}>
                    ASUNTO. - Carta de Confidencialidad en relación a las
                    actividades desarrolladas por encargo o a favor del {props.companyName} a quien en lo sucesivo se le denominará {props.companyName}.
                </Text>

                <Text style={styles.text}>Estimados señores:</Text>

                <Text style={styles.text}>
                    Con motivo de mi participación en las actividades
                    relacionadas con el {props.companyName}, como Campus Intern
                    reconozco que tendré acceso a información confidencial, por
                    lo que conoceré, aprenderé y/o desarrollaré Información
                    Confidencial propiedad del {props.companyName}. Para los efectos
                    de esta carta, se entenderá como Información Confidencial
                    toda la Información que yo desarrolle, tenga a mi
                    disposición o alcance por razón de mi participación en las
                    actividades relacionadas dentro de las actividades
                    encomendadas a mí por el {props.companyName}, ya sea de manera
                    escrita, oral o magnética, electrónica, incluyendo, sin
                    limitar: información administrativa, escenario de premios,
                    documentos comerciales, archivos, programas computacionales,
                    listas de clientes, proyectos, cotizaciones de los mismos,
                    políticas y procedimientos administrativos, bases de datos,
                    métodos, tecnologías, metodología de análisis e
                    interpretación, estudios, dibujos, diseños, sistemas,
                    maquetas, modelos, cálculos, datos, notas, información
                    histórica relacionada con el {props.companyName} o sus filiales y
                    con instituciones gubernamentales Municipales, Estatales y
                    Federales, proyectos actuales y futuros, estrategias del
                    negocio y todo aquello que llegara a ser clasificado o
                    considerado como confidencial por el {props.companyName}, en
                    cualquier momento previo o posterior a la firma de este
                    convenio.
                </Text>

                <Text style={styles.text}>
                    Me obligo a utilizar la Información Confidencial sólo para
                    los efectos de desarrollar mis actividades relacionadas con
                    el {props.companyName} y en ningún caso utilizaré la Información
                    Confidencial para mi beneficio personal o para el beneficio
                    de cualquier otra persona o entidad, o la divulgaré a
                    terceras personas en ningún tiempo o lugar, incluyendo
                    dentro del propio {props.companyName}, salvo previa autorización
                    por escrito. La anterior obligación de secrecía y
                    confidencial permanecerá vigente en forma indefinida aún y
                    cuando mi relación de colaboración con el {props.companyName} se
                    termine o se suspenda.
                </Text>

                <Text style={styles.text}>
                    Estoy de acuerdo que la Información Confidencial que sea
                    considerada por los términos de la presente como tal, será
                    considerada como “secreto industrial” de conformidad con los
                    artículos 163 al 169 de la Ley Federal de Protección a la
                    Propiedad Industrial, sin perjuicio de las sanciones penales
                    a que me pudiese hacer acreedor de conformidad con el
                    artículo 362 de dicha Ley y demás disposiciones aplicables,
                    por lo que manifiesto por medio de la presente, que tengo
                    conocimiento de que el incumplimiento de lo anterior o la
                    revelación de secretos industriales puede constituir un
                    delito penal o sanciones aplicables tales como: el pago de
                    multas por el importe de diez mil unidades de medida y
                    actualización (UMAs) vigente en el país, el arresto
                    administrativo, la prisión, la reparación del daño material
                    y/o el pago de la indemnización de daños y prejuicios.
                </Text>

                <Text style={styles.text}>Atentamente,</Text>

                <Text style={styles.text}>
                    Yo {props.internName} he leído y acepto lo establecido en
                    esta carta de confidencialidad
                </Text>
            </Page>
        </Document>
    );
};


const styles = StyleSheet.create({
    page: { padding:  '25 50 20 50' },
    text: {
      margin: 8,
      fontSize: 11,
      textAlign: 'justify',
      lineHeight: 1.1
    },
    alignRigth:{
       textAlign: 'right'
    }
  });
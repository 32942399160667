import React, { useEffect, useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import Methods from "../../../utils/https";
import "../Configurations.css";
import moment from 'moment-timezone';
import { UserContext } from "../../../context/UserContext";

export default function StampInvoice () {
    const context = useContext(UserContext);
    const [invoiceConfiguration, setInvoiceConfiguration] = useState({
        configurationData: {},
        canStampInvoice: false,
    });
    const { configurationData, canStampInvoice } = invoiceConfiguration;
    const [reloadConfiguration, setReloadConfiguration] = useState(false);

    useEffect(() => {
        getConfiguratios();
    }, [reloadConfiguration]);

    const getConfiguratios = async () => {
        let stampedStatus = await Methods.getStampedStatus();
        if (stampedStatus) {
            setInvoiceConfiguration({
                configurationData: stampedStatus,
                canStampInvoice: stampedStatus.canStampInvoice,
            });
        }
    };

    const handleTurnOffInvoiceStamp = async () => {
        let body = {
            _id: configurationData._id,
            attributes: [
                { updateKey: "canStampInvoice", updateValue: !canStampInvoice },
            ],
        };

        let recordBody = {
            createdAt: moment().tz("America/Mexico_City").format(),
            authorName: context.user.data.fullName,
            authorEmail: context.user.data.email,
            description: `Cambio de estatus de timbrado de factura a: ${
                canStampInvoice ? "Apagado" : "Prendido"
            }`
        };

        setInvoiceConfiguration({
            configurationData: {
                ...configurationData,
                canStampInvoice: !configurationData.canStampInvoice,
            },
            canStampInvoice: !configurationData.canStampInvoice,
        });
        await Methods.updateConfiguration(body, recordBody);
        setReloadConfiguration(!reloadConfiguration);
    };

    return (
        <motion.div
            initial={{ x: 30, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.25 }}
            className="configurations"
        >
            <div className="stamp-container">
                <div>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={() => {
                            handleTurnOffInvoiceStamp();
                        }}
                        checked={canStampInvoice}
                    />
                </div>
                <div className="text-switch">
                    <h2>Timbrado de facturas</h2>
                    <p>
                        Activa esta opción para habilitar el timbrado de
                        facturas
                    </p>
                </div>
            </div>
        </motion.div>
    );
};

import React, {useContext} from 'react';
import InternsLogo from '../../../assets/logo_interns.png';
import {Modal, Button, Form, Image} from 'react-bootstrap';
import {notify} from "../../../utils/notify";
import {UserContext} from '../../../context/UserContext';
import {encrypt} from '../../../utils/crypto';
const axios = require("axios");

export default function DeleteIntern({setUpdateList, setShowDeleteCompanyModal, showDeleteCompanyModal, currentCompanyId, updateList}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const context = useContext(UserContext);
    const isCaintraUser = context.user.data.isCaintraUser;

    
    const handleDeleteCompany = async (e) => {
        e.preventDefault();     

        if(!context.can("delete", "Companies") || !isCaintraUser){
            notify("No tienes permiso para acceder a esta función", "error"); 
            return;
        }
        
        if(e.target.elements.test.value != "eliminar permanentemente"){
            notify("La frase fue escrita de forma errónea, inténtalo nuevamente", "error");
            return;
        };
        const data = {
            company_id: currentCompanyId
        }
        try {
            await axios({
                url: `${URL}/companies/delete`,
                method: "DELETE",
                data: encrypt(data)
            });

            setShowDeleteCompanyModal(!showDeleteCompanyModal);
            setUpdateList(!updateList);
            notify("La compañía fue eliminada del sistema satisfactoriamente", "success");
        } catch (e) {
            notify(e.response.data.message, "error");
        };
    
    };

    return (
        <Modal className="unsuscribe-intern-modal" show={showDeleteCompanyModal} onHide={() => setShowDeleteCompanyModal(!showDeleteCompanyModal)} centered>
            <Modal.Header>
                <p>Eliminar Registro de Compañía</p>
            </Modal.Header>

            <Modal.Body>
                
                <Form onSubmit={handleDeleteCompany}>
                    <Form.Group controlId="test">
                        <Form.Label>Escribe "eliminar permanentemente" para confirmar</Form.Label>
                        <Form.Control type="text" autoComplete={"off"} placeholder="eliminar permanentemente" required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setShowDeleteCompanyModal(!showDeleteCompanyModal)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};
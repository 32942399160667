import React, {useState} from 'react';
import {Modal, Image, Form, Button, Spinner, Row, Col} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function NewCareerModal ({displayNewCareerModal, setDisplayNewCareerModal, currentUniversityId, updateList, setUpdateList, currentFacultyId}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);

    const createCareer = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const career = {
            id_university: currentUniversityId,
            id_faculty: currentFacultyId,
            name: e.target.elements.name.value
        };

        try {
            await axios({
                url: `${URL}/careers`,
                method: "POST",
                data: career
            });

            notify(`"${career.name}" registrado exitosamente`, "success");
            setUpdateList(!updateList);
            setIsLoading(false);
            setDisplayNewCareerModal(!displayNewCareerModal);
        } catch (e) {
            setIsLoading(false);
            e.response && notify(e.response.data.message, "error");
        };
    };

    return(
        <Modal className="new-cc-modal" show={displayNewCareerModal} onHide={() => setDisplayNewCareerModal(!displayNewCareerModal)} centered>
            <Modal.Header>
                <p>Nueva carrera</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={createCareer} autoComplete={"off"}>
                    <Row>
                        <Col>
                            <Form.Group controlId="name">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" required/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                       {isLoading ? <Spinner animation="border" /> : "Registrar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayNewCareerModal(!displayNewCareerModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
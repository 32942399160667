import React from 'react'
import { useState } from 'react';
import { Modal, Button, Form, Col, Row, Spinner, InputGroup } from 'react-bootstrap';
import Methods from '../../../utils/https';


export const UploadManually = ({uploadManually, setUploadManually, documentsNumber, prefactureDetailID, idCompany, setUpdateList, updateList}) => {
    const [loading, setLoading] = useState(false);
    const uploadDocs = async (e) => {
        setLoading(true);
        e.preventDefault();
        let inoviceNumber = e.target.elements.inoviceNumber.value;
        let invoiceAmount = e.target.elements.invoiceAmount.value;
        let pdf = await Methods.uploadPrefactureDoc(prefactureDetailID,e.target[0].files[0], idCompany, "facture", documentsNumber);
        let xml = await Methods.uploadPrefactureDoc(prefactureDetailID,e.target[1].files[0], idCompany, "factureXml", documentsNumber);
        await Methods.uploadManually(prefactureDetailID, pdf, xml, invoiceAmount, inoviceNumber);
        handleClose();
        setUpdateList(!updateList);
        setLoading(false);
    }

    const handleClose = () => {
        setUploadManually(!uploadManually);
    }

    const blockInvalidChar = (e) => {
        ['e', 'E', '+', '-', '`'].includes(e.key) && e.preventDefault();
    };
    
    return (
        <Modal className="new-cc-modal purchase-padding" show={uploadManually} centered>
            <Modal.Body>
                <Form onSubmit={(e) => uploadDocs(e)} autoComplete={"off"}>
                    <Modal.Header>
                        <h3 className='purchase-order-title'>Cargar Archivos</h3>
                    </Modal.Header>
                    <Row align="right" style={{"marginBottom":"20px"}}>
                        <Col className='text-column'>
                            <label style={{"marginRight":"10px"}} for="pdf">PDF</label>
                            <input type="file" id="pdf" name="pdf" accept=".pdf" required/>
                        </Col> 
                        <Col className='text-column'>
                            <label style={{"marginRight":"10px"}} for="xml">XML</label>
                            <input type="file" id="xml" name="xml" accept=".xml" required/>
                        </Col> 
                    </Row>
                    <Row className='manually-invoice-row'>
                        <Form.Group 
                            controlId="invoiceAmount" 
                            id={`invoiceAmount-${prefactureDetailID}`}
                            className='custom-form'
                        >
                            <Form.Label className='custom-label'>Total de prefactura</Form.Label>

                            <InputGroup className="mb-2 custom-group">
                                <InputGroup.Text className='money-radius'>$</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    step=".01" 
                                    min="0"
                                    onKeyDown={blockInvalidChar}
                                    className='money-input'
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className='manually-invoice-row'>
                        <Form.Group 
                            controlId="inoviceNumber" 
                            id={`inoviceNumber-${prefactureDetailID}`}
                            className='custom-form'
                        >
                            <Form.Label className='custom-label'>Número de prefactura</Form.Label>
                            <Form.Control
                                type="text"
                                className='custom-input'
                                required
                            />
                        </Form.Group>
                    </Row>
                    {!loading && <div className='center-buttons'>
                        <Button className="float-right" variant="danger" type="button" onClick={() => handleClose()}>
                            Cancelar
                        </Button>

                        <Button className="float-right ml-2" variant="primary" type='submit'>
                            Cargar
                        </Button>
                    </div>}
                    {loading && <div className='center-container'>
                        <Spinner animation="border" role="status" />
                    </div>}
                </Form>
            </Modal.Body>
        </Modal>
    )
}

import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import Methods from "../../../utils/https";
import { Editor } from "@tinymce/tinymce-react";
import { notify } from "../../../utils/notify";
const axios = require("axios");

export default function PatchNotes() {
    const [notesBody, setNotesBody] = useState({});
    const [s3URL, sets3URL] = useState('');

    useEffect(() => {
        getNotes();
    }, []);

    const getNotes = async () => {
        let result = await Methods.getPatchNotes();

        sets3URL(result);
        let patchHtml = await axios({
          url: result +  `/patchNotes.json?${Math.floor(Math.random() * 100) + 1}`,
          method: "GET"
        });

        setNotesBody(patchHtml ? patchHtml.data.htmlBody : "<p>Ingresa las notas de la versión</p>");
    };

    const editorRef = useRef(null);
    const savePatchNote = async () => {
        try{
          if (editorRef.current) {
            let content = editorRef.current.getContent()

            let contentSplited = content.split("<");
            
            for(let line of contentSplited){
                if(line.includes('img title')){
                  let lineIndex = contentSplited.indexOf(line)
                  contentSplited[lineIndex] = line.replace('>', '> </img>');
                }
            } 
            
            content = contentSplited.join('<');

            let noteBody = {
                htmlBody: content,
            };

              noteBody = JSON.stringify(noteBody);
              
              await Methods.getPresignedUrlAndPutImage(
                  {
                      path: "documents/patchNotes.json",
                      fileName: "patchNotes.json",
                      fileKey: "patchNotes.json",
                      fileExtension: "json",
                      contentTypeAux: "application/json; charset=utf-8",
                  },
                  noteBody
              );
              notify('Información guardada correctamente', 'success')
          }
        }catch(error){
          notify('Ocurrió algo al momento de guardar la información', 'error')
        }

    };

    const loadImage = async(img, fileName) => {
        try{
          await Methods.getPresignedUrlAndPutImage(
            {
                path: `documents/${fileName}`,
                fileName: fileName,
                fileKey: fileName,
                fileExtension: getExtension(fileName),
            },
            img
        );
        }catch(error){
          notify('Ocurrió algo al momento de guardar la información', 'error')
        }
    } 

      
    const getExtension = (fileName) => {
        let basename = fileName.split(/[\\/]/).pop();
        let pos = basename.lastIndexOf(".");

        if (basename === "" || pos < 1){
            return "";
        };

        return basename.slice(pos + 1);
    };


    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    return (
        <>
            <div className="editor-container">
                {notesBody && <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={notesBody}

                    apiKey='qpmumlg38s4k7l7xjo0j2a6elarhp8cq33f2hlcbiuensy09'
                    
                    init={{
                        selector: "textarea#file-picker",
                        plugins: "image code preview lists link",
                        toolbar:
                            "undo redo | styleselect | link image bold italic forecolor | alignleft aligncenter alignrigth alignjustify | bullist numlist outdent indent | preview link",
                        image_title: true,
                        file_picker_types: "image",
                        file_picker_callback: function (cb) {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.onchange = async function () {
                                var file = this.files[0];
                                var reader = new FileReader();
                                let url = await Methods.getPatchNotes();
                                reader.onload = async function () {
                                    var base64 = reader.result.split(",")[1];
                                    const blob = b64toBlob(base64, "");
                                    const blobUrl = URL.createObjectURL(blob);
                                    await loadImage(file, file.name);
                                    cb(`${url}/${file.name}`, { title: file.name });
                                };   
                                reader.readAsDataURL(file);
                            };
                            input.click();
                        },
                        paste_data_images: true,
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />}
                <Button className="ButtonTest" onClick={savePatchNote}>
                    Guardar
                </Button>
            </div>
        </>
    );
}


import {Document, Text, Page, View, StyleSheet, Image} from '@react-pdf/renderer';
import Footer from '../../../assets/footer.png';
import Agreement from '../../../assets/logo_agreement.png';
import logoAgreement from '../../../assets/caintra_interns.png';
import signatureCaintra from '../../../assets/caintra_karo_signature.png';
import caintraLogo from '../../../assets/caintra_logo_agreement.png';
import noLogo from '../../../assets/university_logo.png';
import React from 'react';
const axios = require("axios");
const URL = process.env.REACT_APP_SOCKET_URL;

export default function DownloadPDF ({internContractData, signatureIntern, universityLogo, signatureUniversity, signatureCompany, companyLogo, context}){ 
  
    return(
        <Document>
        <Page size="A4" style={styles.page}>

             <Image source={Agreement} style={styles.containerLogoGreen}/>
             <Image source={logoAgreement} style={styles.caintraInterns}/>
             <Image source={caintraLogo} style={styles.caintraLogo}/>
             <Text style={[styles.titleName, styles.titleLabel]}>NOMBRE DE LA INSTITUCIÓN</Text>
          
             <View style={[styles.section, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.collegeName}</Text>
             </View>
          
              <Text style={[styles.interNameLabel, styles.titleLabel]}>DATOS DEL ALUMNO</Text>
              <Text style={[styles.labelName, styles.subtitleLabel]}>Nombre completo</Text>
              <View style={[styles.nameContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internFirstName + " " + internContractData.internFatherLastname + " " + internContractData.internMotherLastname}</Text>
              </View>
              
          
              <Text style={[styles.enrollmentLabel, styles.subtitleLabel]}>No. de Matrícula</Text>
              <View style={[styles.enrollmentContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.schoolEnrollment}</Text>
             </View>
          
              <Text style={[styles.scholarShipLabel, styles.subtitleLabel]}>Escolaridad</Text>
              <View style={[styles.scholarShipContainer, styles.containerBox]}>
                <Text style={styles.scholarshipText}>{internContractData.internScholarship}</Text>
             </View>
          
              <Text style={[styles.careerLabel, styles.subtitleLabel]}>Carrera o especialidad</Text>
              <View style={[styles.careerContainer, styles.containerBox]}>
                <Text style={styles.scholarshipText}>{internContractData.internCareer}</Text>
             </View>
              
          
              <Text style={[styles.addressTitle, styles.titleLabel]}>DOMICILIO</Text>
              <Text style={[styles.streetLabel, styles.subtitleLabel]}>Calle</Text>
              <View style={[styles.streetContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internStreet}</Text>
             </View>
              
              <Text style={[styles.numberLabel, styles.subtitleLabel]}>Número</Text>
            <View style={[styles.numberContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internExtNumber}</Text>
             </View>
          
              <Text style={[styles.suburbLabel, styles.subtitleLabel]}>Colonia</Text>
              <View style={[styles.suburbContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internSuburb}</Text>
             </View>
          
          
            <Text style={[styles.populationLabel, styles.subtitleLabel]}>Población</Text>
              <View style={[styles.populationContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internMunicipality}</Text>
             </View>
              
              <Text style={[styles.phoneLabel, styles.subtitleLabel]}>Teléfono</Text>
            <View style={[styles.phoneContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internPhone}</Text>
             </View>
          
              <Text style={[styles.emailLabel, styles.subtitleLabel]}>Email</Text>
              <View style={[styles.emailContainer, styles.containerBox]}>
                <Text style={[styles.institutionName, styles.emailInternText]}>{internContractData.internEmail}</Text>
             </View>
              
              <Text style={[styles.socialTitle, styles.titleLabel]}>REALIZARÁ SUS PRACTICAS PROFESIONALES Y/O SERVICIO SOCIAL EN:</Text>
              <Text style={[styles.companyLabel, styles.subtitleLabel]}>Nombre de la Empresa</Text>
              <View style={[styles.companyContainer, styles.containerBox]}>
                <Text style={[styles.institutionName, styles.companyNameText]}>{internContractData.companyName}</Text>
             </View>
              
              <Text style={[styles.phoneCompanyLabel, styles.subtitleLabel]}>Teléfono</Text>
            <View style={[styles.phoneCompanyContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.companyAdminPhone}</Text>
             </View>
              
              <Text style={[styles.addressCompanyLabel, styles.subtitleLabel]}>Domicilio Fiscal</Text>
              <View style={[styles.addressCompanyContainer, styles.containerBox]}>
                <Text style={styles.addressCompany}>{internContractData.companyAddress}</Text>
             </View>
              
              
              <Text style={[styles.startDateLabel, styles.subtitleLabel]}>Fecha de inicio</Text>
            <View style={[styles.startDateContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internStartDate}</Text>
             </View>
          
              <Text style={[styles.endDateLabel, styles.subtitleLabel]}>Fecha de término</Text>
            <View style={[styles.endDateContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.internEndDate}</Text>
             </View>
          
              <Text style={[styles.conceptLabel, styles.subtitleLabel]}>Por concepto de beca, el alumno percebirá mensualmente la cantidad de $</Text>
            <View style={[styles.conceptContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>MXN {internContractData.internAmount}</Text>
             </View>
          
              <Text style={[styles.leaderLabel, styles.subtitleLabel]}>{context.getGeneralistaField(true)} de programa</Text>
            <View style={[styles.leaderContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.directBoss}</Text>
             </View>
          
              <Text style={[styles.areaLabel, styles.subtitleLabel]}>Área de desarrollo del programa (Depto)</Text>
            <View style={[styles.areaContainer, styles.containerBox]}>
                <Text style={styles.institutionName}>{internContractData.departmentName}</Text>
             </View>
          
              <Text style={[styles.promiseLabel, styles.titleLabel]}>
              ME COMPROMETO A CUMPLIR LAS ACTIVIDADES EN LA FORMA AQUÍ MANIFESTADA, Y DE
                ACUERDO A LAS POLÍTICAS Y LO ESTIPULADO POR EL PLAN ESCUELA EMPRESA
              </Text>
          
              
              <Text style={[styles.accordingLabel, styles.subtitleLabel]}>Conforme</Text>
            <View style={[styles.accordingContainer, styles.containerBoxSignatures]}>
                {(signatureIntern && signatureIntern.length) ? 
                  signatureIntern.map(data => {
                    return (
                      <Text style={styles.institutionNameSignature}>{data}</Text>
                    )
                  }): <Text style={styles.institutionNameSignature}>{''}</Text>
                }
             </View>
          
              <Text style={[styles.planSLabel, styles.subtitleLabel]}>Plan Escuela Empresa</Text>
            <View style={[styles.planContainer, styles.containerBoxSignatures]}>
                <Text style={styles.institutionName}>
                </Text>
             </View>
             <Image source={signatureCaintra} style={styles.caintraSignature}/>
             <Image source={caintraLogo} style={styles.caintraLogoSignature}/>
             <Text style={[styles.signatureInternLabel, styles.subtitleLabel]}>Nombre y firma de alumno</Text>
             <Text style={[styles.signatureCaintraLabelLidia, styles.subtitleLabel]}>Lic. Karolina Ayala</Text>
             <Text style={[styles.signatureCaintraLabelName, styles.subtitleLabel]}>CAINTRA</Text>


             
             <View style={[styles.sigantureSchoolContainer, styles.containerBoxSignatures]}>
                {(signatureUniversity && signatureUniversity.length) ? 
                  signatureUniversity.map(data => {
                    return (
                      <Text style={styles.institutionNameSignature}>{data}</Text>
                    )
                  }): <Text style={styles.institutionNameSignature}>{''}</Text>
                }
             </View>
             <Image source={companyLogo || noLogo } style={styles.caintraLogoSignature2}/>
            <View style={[styles.signatureCompanyContainer, styles.containerBoxSignatures]}>
               { <Image source={companyLogo || noLogo } style={styles.companyLogoSignature}/> }
                {(signatureCompany && signatureCompany.length) ? 
                  signatureCompany.map(data => {
                    return (
                      <Text style={styles.companyNameSignature}>{data}</Text>
                    )
                  }): <Text style={styles.companyNameSignature}>{''}</Text>
                }
              {/*   <Text style={styles.institutionName}></Text> */}
            </View>
          
            <Text style={[styles.signatureSchoolLabel, styles.subtitleLabel]}>Sello de la escuela</Text>
            <Text style={[styles.nameSigSchoolLabel, styles.subtitleLabel]}>Nombre y firma del responsable</Text>
          
            <Text style={[styles.signatureCompanylLabel, styles.subtitleLabel]}>Firma / Sello de la empresa</Text>
            <View style={styles.companyLogoSignature}></View>
            <Text style={[styles.nameSigComapanylLabel, styles.subtitleLabel]}>
            </Text>

            <Image source={Footer} style={styles.footerContainer}/>
            
            <Image src={universityLogo || noLogo} style={universityLogo ? styles.universityLogoSignature : styles.nouniversityLogoSignature}/>

        </Page>
      </Document>
    );
}

const styles = StyleSheet.create({
    page: { backgroundColor: 'white' },
    containerLogoGreen:{backgroundImage:'linear-gradient(to right, #430089, #82ffa1)', position: 'absolute', borderRadius:'5px', width: '254px', height: '37px', left: '35px', top: '23px'},
    planLabel:{ width: '124px', height: '11px', left:' 55px', top: '5px'},
    planSubtitleLabel:{ width: '195px', height: '11px', left:'46px', top: '20px'}, 
    containerBox:{ color: 'black', textAlign: 'center', backgroundColor:'#E6EAF0', opacity: '0.8',
    border: '1px solid rgba(147, 150, 150)', boxSizing: 'border-box', position: 'absolute', borderRadius:'5px'},
    titleLabel:{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '9px', position: 'absolute', color: '#121213' },
    subtitleLabel: { fontSize: '9px', color: '#121213', position: 'absolute'},
    section: { borderRadius:'5px', width: '515px', height: '20px', left: '40px', top:'91px'},
    institutionName: { textAlign: "left", fontSize:"12px", paddingTop: '2px', paddingLeft: '5px' },
    titleName: { position:'absolute', width: '131px', height: '11px', left: '40px', top: '75px'},
    interNameLabel:{ width: '120px', height: '11px', left: '40px', top: '126px'},
    labelName: { width: '74px', height: '11px', left:'40px', top: '147px'},
    nameContainer:{ width: '515px', height: '20px', left: '40px', top:'163px'},
    enrollmentLabel:{ width: '68px', height: '11px', left:'40px', top: '193px'},
    enrollmentContainer:{ width: '120px', height: '20px', left: '40px', top:'209px' }, 
    scholarShipLabel:{ width: '49px', height: '11px', left:'170px', top: '193px'},
    scholarShipContainer:{ width: '120px', height: '20px', left: '170px', top:'209px' },  
    careerLabel:{ width: '94px', height: '11px', left:'300px', top: '193px'},
    careerContainer:{ width: '255px', height: '20px', left: '300px', top:'209px'},
    addressTitle:{ width: '49px', height: '11px', left: '40px', top:'244px'},
    streetLabel:{ width: '21px', height: '11px', left: '40px', top:'265px' },
    footerLabel: { textAlign: "center", fontSize:"9px", paddingTop: '4px'},
    streetContainer:{ width: '260px', height: '20px', left: '40px', top:'281px'},
    numberLabel:{ width: '34px', height: '11px', left: '310px', top:'265px'},
    numberContainer: { width: '60px', height: '20px', left: '310px', top:'281px'},
    suburbLabel:{ width: '34px', height: '11px', left: '380px', top:'265px'},
    suburbContainer: { width: '175px', height: '20px', left: '380px', top:'281px'},
    populationLabel:{ width: '41px', height: '11px', left: '40px', top:'311px'},
    populationContainer: { width: '180px', height: '20px', left: '40px', top:'327px'},
    phoneLabel:{ width: '36px', height: '11px', left: '230px', top:'311px'},
    phoneContainer: { width: '100px', height: '20px', left: '230px', top:'327px'},
    emailLabel:{ width: '23px', height: '11px', left: '340px', top:'311px'},
    emailContainer: { width: '215px', height: '20px', left: '340px', top:'327px'},
    socialTitle: { width: '323px', height: '11px', left: '40px', top:'362px'},
    companyLabel:{ width: '95px', height: '11px', left: '40px', top:'383px'},
    companyContainer: { width: '405px', height: '20px', left: '40px', top:'399px'},
    phoneCompanyLabel:{ width: '36px', height: '11px', left: '455px', top:'383px'},
    phoneCompanyContainer: { width: '100px', height: '20px', left: '455px', top:'399px'},
    addressCompanyLabel:{ width: '62px', height: '11px', left: '40px', top:'429px'},
    addressCompanyContainer: { width: '515px', height: '20px', left: '40px', top:'445px'},
    startDateLabel:{ width: '62px', height: '11px', left: '40px', top:'475px'},
    startDateContainer: { width: '90px', height: '20px', left: '40px', top:'491px'},
    endDateLabel:{ width: '72px', height: '11px', left: '140px', top:'475px'},
    endDateContainer: { width: '90px', height: '20px', left: '140px', top:'491px'}, 
    conceptLabel:{ width: '309px', height: '11px', left: '240px', top:'475px'},
    conceptContainer: { width: '315px', height: '20px', left: '240px', top:'491px'},  
    leaderLabel:{ width: '77px', height: '11px', left: '40px', top:'521px'},
    leaderContainer: { width: '252px', height: '20px', left: '40px', top:'537px'}, 
    areaLabel:{ width: '165px', height: '11px', left: '302px', top:'521px'},
    areaContainer: { width: '252px', height: '20px', left: '302px', top:'537px'},  
    promiseLabel: { width: '484px', height: '22px', left: '40px', top:'572px'}, 
    accordingLabel:{ width: '41px', height: '11px', left: '146px', top:'604px'},
    accordingContainer: { width: '252px', height: '50px', left: '40px', top:'620px'}, 
    planSLabel:{ width: '120px', height: '11px', left: '382px', top:'604px'},
    planContainer: { width: '252px', height: '50px', left: '303px', top:'620px'},   
    signatureInternLabel: { width: '113px', height: '33px', left: '110px', top:'675px'},   
    signatureCaintraLabel: { width: '133px', height: '33px', left: '363px', top:'675px', textAlign: 'center'},
    signatureCaintraLabelLidia: { width: '133px', height: '33px', left: '363px', top:'675px', textAlign: 'center'},    
    signatureCaintraLabelName: { width: '133px', height: '33px', left: '363px', top:'685px', textAlign: 'center'},        
    sigantureSchoolContainer: { width: '252px', height: '50px', left: '40px', top:'718px'},   
    signatureCompanyContainer: { width: '252px', height: '50px', left: '303px', top:'718px'}, 
    signatureSchoolLabel: { width: '133px', height: '22px', left: '100px', top:'773px', textAlign:'center'},  
    nameSigSchoolLabel: { width: '133px', height: '22px', left: '100px', top:'783px'},
    signatureCompanylLabel: { width: '133px', height: '22px', left: '363px', top:'773px', textAlign:'center'},  
    nameSigComapanylLabel: { width: '133px', height: '22px', left: '363px', top:'783px'},
    footerContainer: { position: 'absolute', width: '515px', height: '28px', left: '40px', top:'805px'},
    caintraInterns: {width: '122.85px', height: '27px', left: '385.15px', top:'28px'},
    caintraLogo: {position: 'absolute', width: '27px', height: '27px', left: '528px', top:'28px'},
    caintraSignature: {position: 'absolute', width: '70px', height: '40px', left: '400px', top:'625px', alignContent: 'center', alignSelf: 'center'},
    caintraLogoSignature: {position: 'absolute', width: '40px', height: '40px', left: '510px', top:'625px', alignContent: 'center', alignSelf: 'center'},
   
    companyLogoSignature: { 
      position: 'absolute', width: '140', height: '40px', left: '510px', top:'723px', alignContent: 'center', alignSelf: 'center', zIndex: '99999'
    },
    universityLogoSignature:{
        position: 'absolute', width: '40px', height: '40px', left: '150px', top: '723px', backgroundColor: 'red'
    },
    institutionNameSignature: { textAlign: "left", fontSize:"8.7px", paddingTop: '4px', paddingLeft: '5px'},
    companyNameSignature: { textAlign: "left", fontSize:"8.7px", paddingTop: '4px', paddingLeft: '5px'},
    scholarshipText: { textAlign: "left", fontSize:"8px", paddingTop: '4.5px', paddingLeft: '5px' },
    addressCompany: { textAlign: "left", fontSize:"10px", paddingTop: '4px', paddingLeft: '5px' },
    univesitySignature: { textAlign: "left", fontSize:"12px", paddingTop: '2px', paddingLeft: '5px', position: 'absolute'},
    nouniversityLogoSignature:{
     width: '40px', height: '40px', left: '600px', top: '723px', display: 'none'
    },
    containerBoxSignatures:{ color: 'black', textAlign: 'center', backgroundColor:'#FFFFFF', opacity: '0.3',
    border: '1px solid rgba(147, 150, 150)', boxSizing: 'border-box', position: 'absolute', borderRadius:'5px'},
    caintraLogoSignature2: {position: 'absolute', width: '80px', height: '40px', left: '470px', top:'723px', alignContent: 'center', alignSelf: 'center', zIndex : 1000},
    setContainer:{    
      '@media max-width: 200': {
        flexDirection: 'column',
      },
      '@media min-width: 200': {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    emailInternText:{
      fontSize:"9px",
      paddingTop: '4px'
    },
    companyNameText:{
      fontSize:"9px",
      paddingTop: '4px'
    }
    });
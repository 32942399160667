import React, { useState, useEffect, useContext} from 'react';
import {Modal, Button, Form, Spinner} from 'react-bootstrap';
import { notify } from '../../../utils/notify';
import moment from 'moment';
import Methods from '../../../utils/https';
import {UserContext} from '../../../context/UserContext';
const axios = require("axios");


export default function ChangePeriod({
    isMonthly,
    showChangePeriodModal,
    setShowChangePeriodModal,
    firstPeriod,
    period,
    setPeriod,
    setUpdateList,
    updateList,
    idCompany,
    setIsHistoricalSearch,
    subPeriods,
    previousPeriods,
    isFirstSubPeriod,
    isSecondSubPeriod,
    isFirstPrevPeriod,
    setPayDay,
    setPayDayLimit,
    actualPayDay,
    actualPayDayLimit,
    setIsConsolidationPrefacture,
    setAllPeriodValues, 
    allPeriodValues
}) {   
    const [disabledPeriods, setDisabledPeriods] = useState(true);
    const [prefactures, setPrefactures] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState({
        month: undefined,
        year: undefined
    });

    const {year, month} = dateSelected;

    const context = useContext(UserContext);
    const URL = process.env.REACT_APP_SOCKET_URL;

    useEffect(() => {
        verifyHistoricPeriods();
    }, [dateSelected]);
    

    const getActualPeriod = () => {
        return [firstPeriod[0], firstPeriod[1]];
    };

    const getActualPeriodString = () => {
        const [actualStartDate, actualEndDate] = getActualPeriod();
        const formattedStartDate = moment(actualStartDate).format("D/MM/YYYY");
        const formattedEndDate = moment(actualEndDate).format("D/MM/YYYY");
        return `${formattedStartDate} al ${formattedEndDate}`;
    };

    const handleChangePeriod = ([startDate, endDate], isHistorical) => {
        setIsHistoricalSearch(isHistorical);
        setPeriod([startDate, endDate]);
        setUpdateList(!updateList);
        setShowChangePeriodModal(!showChangePeriodModal);
        setIsConsolidationPrefacture(false);
    };  

    const getFormatedDate = (dateString) => {
        let result;
        let month;
        let day;
        const newDateArray = dateString.split("/");
        month = addZeroToDate(newDateArray[1]);
        day = addZeroToDate(newDateArray[0]);
        result = newDateArray[2] + "-" + month + "-" + day;
        return result;
    }
    
    const addZeroToDate =  (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };
    

    const getPeriodsBySelectedDate = async () => {
        let result = await Methods.getPeriodsByPrefacture(idCompany, year, month);
        if(result) {
            setPrefactures(result);
            setDisabledPeriods(false);
        }else{
            setDisabledPeriods(true);
        }
    }
 
    const verifyHistoricPeriods = async () => {

        if(!year) return;
        if(!month) return;

        setIsLoading(true);
        await getPeriodsBySelectedDate();
        setIsLoading(false);
    }

    const setSpecificPeriod = (periodSelected) => {
        let periodSelectedAux = JSON.parse(periodSelected);
        let _startDate
        let _endDate
        let _payDay
        if(periodSelectedAux.period[0].includes("T")){
            _startDate = new Date(periodSelectedAux.period[0].replace(/-/g, '\/').replace(/T.+/, ''));
            _endDate = new Date(periodSelectedAux.period[1].replace(/-/g, '\/').replace(/T.+/, ''));
            _payDay = periodSelectedAux.payDay

        } else {
            _startDate = new Date(periodSelectedAux.period[0]);
            _endDate = new Date(periodSelectedAux.period[1]);
            _payDay = moment(periodSelectedAux.payDay.substring(0,10)).format('DD/MM/YYYY'); 
        }
        setPayDay(_payDay);
        handleChangePeriod([_startDate, _endDate], false);
        periodSelectedAux.consolidationPeriodToSelect ? setIsConsolidationPrefacture(true) : setIsConsolidationPrefacture(false)
        setDisabledPeriods(true);
    };

    const setPeriodDates = (period) => {
       setPayDay(period.payment_date);
       setPayDayLimit(period.limit_authorization);
       let _startDate = moment(getFormatedDate(period.start_date));
       let _endDate = moment(getFormatedDate(period.end_date));
       let result = [_startDate, _endDate];
       period.periodAux = result;
       setAllPeriodValues({...allPeriodValues, selectedPeriod: period})
       return result;
    };

    return(
        <Modal className="change-period" show={showChangePeriodModal} centered onHide={() =>{setShowChangePeriodModal(!showChangePeriodModal); setDisabledPeriods(true); setDateSelected({ month: undefined, year: undefined})} }>
            <Modal.Body>
                <div className="content">
                    <h5>Cambio de periodo</h5>


                    <p>Anterior</p>
                    <Button className="default-button" disabled={isFirstPrevPeriod} onClick={() => {handleChangePeriod(setPeriodDates(previousPeriods[0]), false)}}>
                        { previousPeriods && previousPeriods[0] ? previousPeriods[0].start_date + " al " + previousPeriods[0].end_date : "No hay periodos disponibles"}
                    </Button>

                    <p>Actual</p>  
                    <Button 
                        className="default-button" 
                        onClick={() => {
                            handleChangePeriod(getActualPeriod(), false); 
                            setIsHistoricalSearch(false); 
                            setPayDayLimit(actualPayDayLimit); 
                            setPayDay(actualPayDay);
                            setAllPeriodValues({...allPeriodValues, selectedPeriod: allPeriodValues.actualPeriod})
                        }}> 
                            {getActualPeriodString()}
                    </Button>

                    <p>Posterior</p>
                    <Button className="default-button" disabled={isFirstSubPeriod} onClick={() => {handleChangePeriod(setPeriodDates(subPeriods[0]), false)}}>
                        {subPeriods && subPeriods[0] ? subPeriods[0].start_date + " al " + subPeriods[0].end_date : "No hay periodos disponibles"}
                    </Button>

                    {!isMonthly && 
                        <Button className="default-button" disabled={isSecondSubPeriod} onClick={() => {handleChangePeriod(setPeriodDates(subPeriods[1]), false)}}>
                            {subPeriods && subPeriods[1]  ? subPeriods[1].start_date + " al " + subPeriods[1].end_date : "No hay periodos disponibles"}
                        </Button>
                    }
                   
                   
                    <p>Buscar en periodos anteriores</p>
                    
                    <Form>
                        <Form.Group controlId="year" style={{width: "80px"}}>
                            <Form.Control as="select" onChange={async(e) => {setDateSelected({...dateSelected, year: e.target.value})}}>
                                <option selected hidden>Año</option>
                                <option value={2005}>2005</option>
                                <option value={2006}>2006</option>
                                <option value={2007}>2007</option>
                                <option value={2008}>2008</option>
                                <option value={2009}>2009</option>
                                <option value={2010}>2010</option>
                                <option value={2011}>2011</option>
                                <option value={2012}>2012</option>
                                <option value={2013}>2013</option>
                                <option value={2014}>2014</option>
                                <option value={2015}>2015</option>
                                <option value={2016}>2016</option>
                                <option value={2017}>2017</option>
                                <option value={2018}>2018</option>
                                <option value={2019}>2019</option>
                                <option value={2020}>2020</option>
                                <option value={2021}>2021</option>
                                <option value={2022}>2022</option>
                                <option value={2023}>2023</option>
                                <option value={2024}>2024</option>
                                <option value={2025}>2025</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="month" style={{width: "120px"}}>
                            <Form.Control as="select" onChange={async (e) => {setDateSelected({...dateSelected, month: e.target.value});}}>
                                <option selected hidden>Mes</option>
                                <option value={1}>Enero</option>
                                <option value={2}>Febrero</option>
                                <option value={3}>Marzo</option>
                                <option value={4}>Abril</option>
                                <option value={5}>Mayo</option>
                                <option value={6}>Junio</option>
                                <option value={7}>Julio</option>
                                <option value={8}>Agosto</option>
                                <option value={9}>Septiembre</option>
                                <option value={10}>Octubre</option>
                                <option value={11}>Noviembre</option>
                                <option value={12}>Diciembre</option>
                            </Form.Control>
                        </Form.Group>

                        
                        <Form.Group controlId="period" style={{width: "170px"}}>
                            {isLoading ? <Spinner animation="border" /> : 
                                <Form.Control as="select" disabled={disabledPeriods} onChange={(e) => { setSpecificPeriod(e.target.value);}}>
                                    <option selected hidden>Periodo</option>
                                    {prefactures ? prefactures.map((period, index) => {
                                        return(
                                            period.consolidationPeriodToSelect ? <option key={index} value={JSON.stringify(period)}>Prefactura mensual</option> :
                                            <option key={index} value={JSON.stringify(period)}>Periodo {period.prefactureNumberAux} {period.QMCText ? period.QMCText : ''}</option>
                                        )
                                    }) : <option disabled>No hay periodos</option>}
                                </Form.Control> 
                            }
                        </Form.Group>
                        

                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
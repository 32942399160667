import React from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';

export default function UpdateClabe({showUpdateUser, setShowUpdateUser, internToUpdate, context, setUpdateInternList, updateInternList}){
    const disperse = async (e) => {
        e.preventDefault();

        if(!context.can("update", "Interns")){
            notify("No tienes acceso a esta funcionalidad, solicítala a un administrador", "error");
            return;
        };

        if(e.target.elements.number.value !== internToUpdate.clabe && await getInternsByClabe(e)) return;

        internToUpdate.clabe = e.target.elements.number.value;

        let dataIntern = {
            _id: internToUpdate._id,
            attributes: [{updateKey: "clabe", updateValue: e.target.elements.number.value}]
        }

        await Methods.updateIntern(dataIntern);
        setUpdateInternList(!updateInternList);
        setShowUpdateUser(!showUpdateUser);
    };

    const getInternsByClabe = async (data) => {
        if(data.target.elements.number.value !== "" && data.target.elements.number.value !== "_"){
            let duplicated;
            duplicated = await Methods.getInternsByClabe(data.target.elements.number.value);
            if (duplicated.data.total > 0) {
                notify("Cuenta o clabe interbancaria existente. Favor de revisar la información." , "error");
                return true;
            }
        }
        return false;
    };

    const verifyBankType = (internBank) => {
        console.log(internToUpdate);
        let currenBankLowerCase = internBank?.toLowerCase();
        if(currenBankLowerCase == "banorte" || currenBankLowerCase == "bancomer" || currenBankLowerCase == "santander"){
            return "Proporciona la cuenta del practicante"
        }else{
            return "Proporciona la CLABE interbancaria del practicante"
        }
    }

    return(
        <Modal className="prefacture-modals" onHide={() => {}} show={showUpdateUser} centered>
            <Modal.Body>
                <Form onSubmit={disperse} autoComplete={"off"}>
                <Modal.Header>
                    <p>Actualización de practicante</p>
                </Modal.Header>
                    <Form.Group controlId="number">
                        <Form.Control type="number"
                        onInput = {(e) =>{
                            let maxLength = 10;
                            if(  /^(santander)$/i.test(internToUpdate?.bank?.toLowerCase())) maxLength = 11;
                            else if( !/^(bancomer|banorte)$/i.test(internToUpdate?.bank?.toLowerCase())) maxLength = 18;
                            e.target.value = e.target.value.toString().slice(0,maxLength);
                        }}
                        placeholder={ internToUpdate ? verifyBankType(internToUpdate?.bank): ""} required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button> 
                    
                    <Button className="float-right" variant="danger" onClick={() => setShowUpdateUser(!showUpdateUser)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};
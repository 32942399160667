
export default {
    
    

    test(workbook, companyInfo, interns) {

        
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()

        const data = {
            'cols': ["A","B","C","D","E","F","G","H","I","J","K","L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH"],
            'config' : [
                        {  width: 20,  value: "#",         text: "#",               getData: (intern) => { return intern.firstname || '' } },
                        {  width: 40,  value: "firstname",         text: "Nombre(s)",               getData: (intern) => { return intern.firstname || '' }  },
                        {  width: 30,  value: "fatherLastName",    text: "Apellido Paterno",        getData: (intern) => { return intern.father_lastname || '' }  },
                        {  width: 30,  value: "motherLastName",    text: "Apellido Materno",        getData: (intern) => { return intern.mother_lastname || '' }  },
                        {  width: 30,  value: "email",             text: "Correo",                  getData: (intern) => { return intern.email }  },
                        {  width: 20,  value: "phone",         text: "Teléfono",      getData: (intern) => { return intern.phone }  },
                        {  width: 20,  value: "internStatus",            text: "Estatus",      getData: (intern) => { return intern.internStatus }  },
                        {  width: 20,  value: "sifeStatus",            text: "Firma Electrónica",      getData: (intern) => { return intern.signatureStatus }  },
                        {  width: 20,  value: "sifeStatus",            text: "Desarrollo personal",      getData: (intern) => { return intern.personal }  }
                        ,
                        {  width: 20,  value: "sifeStatus",            text: "Tendencias en empresas",      getData: (intern) => { return intern.tendencias }  }
                        ,
                        {  width: 20,  value: "sifeStatus",            text: "Básicos para la incorporación laboral",      getData: (intern) => { return intern.basicos }  }
                        ,
                        {  width: 20,  value: "sifeStatus",            text: "Desarrollo técnico",      getData: (intern) => { return intern.tecnico }  }
                        
                    ],
            'worksheetName': "Reporte Practicantes",
            'headerText': "Reporte Practicantes"
        }

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){

            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).value = text;
            
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A1",
                "Nombre de Empresa",
                worksheetName
            )
            createWhiteCell(
                "B1",
                companyInfo.companyName  || "",
                "",
                worksheetName
            )

            createBlueCell(
                "C1",
                "RFC",
                worksheetName
            )

            createWhiteCell(
                "D1",
                companyInfo.companyRFC  || "",
                "",
                worksheetName
            )

            createBlueCell(
                "E1",
                "ID",
                worksheetName
            )
            createWhiteCell(
                "F1",
                companyInfo.companyId  || "",
                "",
                worksheetName
            )
        }

        function createTableAvail(interns, worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                data.headerText,
                worksheetName
            );
            cellNumber++
            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            if(interns.length){
                interns.forEach((intern, index) => {
                    cellNumber++
                    config.forEach((configItem) => {
                        if(configItem.value == "#"){
                            createWhiteCell(
                                configItem.col + cellNumber,
                                index + 1,
                                '',
                                worksheetName
                            )
                        }else{
                            createWhiteCell(
                                configItem.col + cellNumber,
                                configItem.getData(intern),
                                configItem.value,
                                worksheetName
                            )
                        }
                    });
                })
            }
        }   

        createTableAvail(interns, worksheetPicoUsoInfra)
        return workbook
    }
}

import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { notify } from '../../../utils/notify';
import { motion } from "framer-motion";
import ReactPaginate from "react-paginate";
import { DropdownList } from "react-widgets";
import { BsThreeDots, BsTrash, BsFillPencilFill, BsFiles, BsFileText } from 'react-icons/bs';
import { RiErrorWarningLine } from 'react-icons/ri';
import { FaRegEye } from 'react-icons/fa';
import Methods from '../../../utils/https';
import { ImCancelCircle, ImAndroid } from 'react-icons/im';
import LoadingModal from '../../../custom/LoadingModal/LoadingModal';
import { InternListTable } from './InternListTable';
 
const axios = require("axios");

export default function InternsList ({context, isGlobal, interns, setInterns, updateList, setUpdateList, setCurrentInternId, showUnsuscribeInternModal, setShowUnsuscribeInternModal, setCurrentTab, setRotateSync, setCurrentDepartmentId, setShowDeleteInternModal, showDeleteInternModal, setShowTrainingModal, showTrainingModal,setShowLetterInModal, showLetterInModal,setShowLetterOutModal, showLetterOutModal, listType, searchQuery, setCurrentInternName, searchQuery2, currentIntern, setCurrentIntern, statusTable, setStatusTable, signatureStatus, setSignatureStatus}) {
    const role = context.userRole;
    const id_company = context.user.company._id;
    const requireSife = context.user.company.requireSife == 'Sí' ? true : false;
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [totalPages, setTotalPages] = useState(undefined);
    const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState({});
    const [companies, setCompanies] = useState([]);
    const [currentSearch, setCurrentSearch] = useState(undefined);
    const [limitValues, setLimitValues] = useState({
      currentLimit: 10,
      limitPages: 20
    });
    const {currentLimit, limitPages} = limitValues;
    const canEdit = context.can("update", "Interns")
    const [prevPageKeys, setPrevPageKeys] = useState([]);
    const [currPageKey, setCurrPageKey] = useState(undefined);
    const [nextPageKey, setNextPageKey] = useState(undefined);
    const [allInterns, setAllInterns] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(async () => {
        setCurrentSearch(searchQuery);
        setPrevPageKeys([]);
        setNextPageKey(undefined);

        if(context.dashboardRedirectData.setActiveInterns){
          setSignatureStatus('Activo');
        }
        
        if (context.dashboardRedirectData.isRedirected) {
            setValuesByDashboard();
        } else {
            if (searchQuery != "") {
                await getInterns(undefined, undefined, limitValues, true, searchQuery);
            } else if (searchQuery2 != "") {
                await getInterns(undefined, undefined, limitValues, true, "", null, searchQuery2);
            } else {
                await getInterns(undefined, undefined, limitValues);
            }
        }
    }, [updateList, isGlobal]);

    

    const setValuesByDashboard = async () => {
      setCurrentPage(0);
      setRotateSync(true);
      setIsLoading(true);
      setStatusTable(context.dashboardRedirectData.internStatusSelected || 'Activo');

      
      setInterns(context.dashboardRedirectData.interns);
      await getCompany(context.dashboardRedirectData.interns);
      setAllInterns(context.dashboardRedirectData.interns);


      setTotalPages(1);
      setRotateSync(false);
      setIsLoading(false);
    }

    function truncateString(cadena) {
        let tmp = cadena.split(" ");

        if(tmp.length==4){
            tmp[0] =doTruncate(tmp[0]);
            tmp[1] =doTruncate(tmp[1]);
            tmp[2] =doTruncate(tmp[2]);
            tmp[3] =doTruncate(tmp[3]);
            return tmp[0] + " " + tmp[1]+ " " + tmp[2]+ " " + tmp[3]; 
        }
        else if(tmp.length==3){
            tmp[0] =doTruncate(tmp[0]);
            tmp[1] =doTruncate(tmp[1]);
            tmp[2] =doTruncate(tmp[2]);
            return tmp[0] + " " + tmp[1]+ " " + tmp[2];
        }
        else if(tmp.length==2){
            tmp[0] =doTruncate(tmp[0]);
            tmp[1] =doTruncate(tmp[1]);
            return tmp[0] + " " + tmp[1];
        }
        else{
            tmp[0] =doTruncate(tmp[0]);
            return tmp[0];

        }
               
      }

    function doTruncate(cadena){
        let regex = /[\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]/g;
        let indice = cadena.search(regex);
        if (indice === -1) {
          return cadena;
        } else {
          return cadena.substring(0, indice);
        }
    }

    const getInterns = async (
        startKey,
        _page,
        _limit,
        isQuery,
        query,
        internStatus,
        query2,
        signatureStatusValue
    ) => {
        
        setCurrentPage(0);
        setRotateSync(true);
        setIsLoading(true);
        try {
            const departmentId = context.user.data.departmentId;
            const page = _page || 1;
            const limit = _limit.currentLimit || _limit || 10;
            const approvedInterns = false;
            const data = {
                id_company: id_company,
                limit: limit,
                approvedInterns: approvedInterns,
                internStatus: internStatus || statusTable,
                limitPages: _limit.limitPages || limitPages,
            };

            if (startKey) data.startKey = startKey;
            if (isQuery) {
                data.isQuery = isQuery;
                data.query = query;
            }
            if (isQuery && isGlobal) {
                let queryJson = JSON.parse(query2 || {});
                data.curp = queryJson.curp;
                data.firstName = queryJson.firstName;
                data.fatherLastName = queryJson.fatherLastName;
                data.motherLastName = queryJson.motherLastName;
            }
            if (departmentId) {
                data.departmentId = departmentId;
            }
            if (requireSife) {
                data.requireSife = requireSife;
                data.signatureStatus = signatureStatusValue || signatureStatus;
            }
            let tempSearch = "";
            if(data.query){
                tempSearch = data.query;
                data.query = truncateString(data.query);
            }
            let response = {
                "data": {
                    "pages": 1,
                    "interns": [],
                    "status": 200,
                    "statusText": "",
                    "headers": {
                        "content-length": "6401",
                        "content-type": "application/json"
                    },
                    "config": {
                        "url": "https://stg42dgluj.execute-api.us-east-1.amazonaws.com/prod/allInterns/status",
                        "method": "post",
                        "data": "",
                        "headers": {
                            "Accept": "application/json, text/plain, */*",
                            "Content-Type": "application/json;charset=utf-8"
                        },
                        "transformRequest": [
                            null
                        ],
                        "transformResponse": [
                            null
                        ],
                        "timeout": 0,
                        "xsrfCookieName": "XSRF-TOKEN",
                        "xsrfHeaderName": "X-XSRF-TOKEN",
                        "maxContentLength": -1,
                        "maxBodyLength": -1
                    },
                    "request": {}
                }
            };
            try{
                response = await axios({
                    url: isGlobal
                        ? `${URL}/iterns/listAll`
                        : `${URL}/allInterns/status`,
                    method: "POST",
                    data: data,
                });
  
            }
            catch(e){
                console.log("ERROR");
            } 
            if (isGlobal) {

                response = await axios({
                    url: isGlobal
                        ? `${URL}/iterns/listAll`
                        : `${URL}/allInterns/status`,
                    method: "POST",
                    data: data,
                });
                if(data.query){
    
                    data.query = tempSearch;
                }

                let regex = /[À-ÿñ]/;

                if ( regex.test(data.fatherLastName) || regex.test(data.motherLastName) || regex.test(data.firstName) ) {

                    data.fatherLastName = replaceAccents(data.fatherLastName);
                    data.motherLastName = replaceAccents(data.motherLastName);
                    data.firstName = replaceAccents(data.firstName);

                    let responseTmp = await axios({
                        url: `${URL}/iterns/listAll`,
                        method: "POST",
                        data: data
                    });

                    for (const item in responseTmp.data.interns) {
                        response.data.interns.push(
                            responseTmp.data.interns[item]
                        );
                    }

                } else {

                    await setMarkAccent(data, response);

                }
            }
            else if(data.query){
                
                let regex = /[À-ÿñ]/;
                if ( !regex.test(data.query) && data.query.length>0) {
               
                    await setMarkAccentLocal(data, response)
                }
                else{
                
                  
                }
                
            }

          
            

            let internAux = isGlobal ? response.data.interns : response.data.interns[0];
            setInterns(internAux);
            await getCompany(internAux);
            setAllInterns(response.data.interns);
            setTotalPages(response.data.pages);
            setLastEvaluatedKeys({
                ...lastEvaluatedKeys,
                [page]: response.data.lastEvaluatedKey,
            });
            setNextPageKey(response.data.lastEvaluatedKey);
            setRotateSync(false);
            setIsLoading(false);
        } catch (e) {
            console.log("ERROR",e)
            e.response && notify(e.response.data.message, "error");
            setAllInterns([]);
            setInterns([]);
            setRotateSync(false);
            setIsLoading(false);
            setTotalPages(1);
        }
    };

    const setMarkAccentLocal = async (data, response) => {
     
        let tmp1 = "";
        let responseTmp = [];
        let responseTmp2 = [];
        let unique = [];


        let source1 = data.query;
       

        let length1 = data.query.length;
       

        if (length1 === 0) {
            length1 = 100;
        }

        let lengthN = 0;
        let lengthP = 0;

        let tmpName = data.query.split(" ");
        if(tmpName == 4){
            lengthN = tmpName[0].length + tmpName[1].length;
            lengthP = tmpName[2].length;
        }
        if(tmpName == 3){
            lengthN = tmpName[0].length ;
            lengthP = tmpName[1].length;
        }
        if(tmpName == 2){
            lengthN = tmpName[0].length;
            lengthP = tmpName[1].length;
        }
        else{
            lengthN = 1;
            lengthP = 1;

        }

        for(let n = 0 ; n< lengthN ; n++){
            for(let p = 0 ; p< lengthP ; p++){
                for (var i = 0; i < length1; i++) {
          
                    if (data.query.length > 0) {
                        data.query = source1;
                        let resT = addAccents(data.query[i]);
                        tmp1 = data.query.split("");
                        tmp1[i] = resT;
                        data.query = tmp1.join("");
                    } else {
                        tmp1 = "".split("");
                    }
          
                    try{
                        responseTmp = await axios({
                            url: isGlobal
                                ? `${URL}/iterns/listAll`
                                : `${URL}/allInterns/status`,
                            method: "POST",
                            data: data,
                        });
                        console.log(response);
                        for (const item in responseTmp.data.interns) {
                            for (const item2 in responseTmp.data.interns[item]) {
                               responseTmp2.push(responseTmp.data.interns[item][item2])
                            }
                        }
                    }
                    catch(e){
                        console.log("Not found");
                    }
        
                }
            }
        }

        var result = responseTmp2.reduce((unique, o) => {
            if ( !unique.some((obj) => obj.firstname === o.firstname && obj.father_lastname === o.father_lastname && obj.mother_lastname === o.mother_lastname) ) {
                unique.push(o);
            }
            return unique;
        }, []);

      
        
        response.data.interns[0]= [];
        response.data.interns[0]=result;     
       
        response.data.pages = 1;
       
    };

    const setMarkAccent = async (data, response) => {
        let jsonArray = [{ n: "1", fn: "1", mn: "1" }];
        let unique = [];
        let tmp1 = "";
        let tmp2 = "";
        let tmp3 = "";

        let source1 = data.firstName;
        let source2 = data.fatherLastName;
        let source3 = data.motherLastName;

        let length1 = data.firstName.length;
        let length2 = data.fatherLastName.length;
        let length3 = data.motherLastName.length;

        if (length1 === 0) {
            length1 = 100;
        }

        if (length2 === 0) {
            length2 = 100;
        }

        if (length3 === 0) {
            length3 = 100;
        }

        for (var i = 0; i < length1; i++) {
            if (data.firstName.length > 0) {
                data.firstName = source1;
                let resT = addAccents(data.firstName[i]);
                tmp1 = data.firstName.split("");
                tmp1[i] = resT;
                data.firstName = tmp1.join("");
            } else {
                tmp1 = "".split("");
            }

            for (var ii = 0; ii < length2; ii++) {

                if (data.fatherLastName.length > 0) {
                    data.fatherLastName = source2;
                    let resT = addAccents(data.fatherLastName[ii]);
                    tmp2 = data.fatherLastName.split("");
                    tmp2[ii] = resT;
                    data.fatherLastName = tmp2.join("");
                } else {
                    tmp2 = "".split("");
                }

                for (var iii = 0; iii < length3; iii++) {

                    if (data.motherLastName.length > 0) {
                        data.motherLastName = source3;
                        let resT = addAccents(data.motherLastName[iii]);
                        tmp3 = data.motherLastName.split("");
                        tmp3[iii] = resT;
                        data.motherLastName = tmp3.join("");
                    } else {
                        tmp3 = "".split("");
                    }

                    var obj = {
                        n: tmp1.join(""),
                        fn: tmp2.join(""),
                        mn: tmp3.join("")
                    };
                    jsonArray.push(obj);
                }
            }
        }

        var result = jsonArray.reduce((unique, o) => {
            if ( !unique.some((obj) => obj.n === o.n && obj.fn === o.fn && obj.mn === o.mn) ) {
                unique.push(o);
            }
            return unique;
        }, []);

        for (let items in result) {
            data.firstName = result[items].n;
            data.fatherLastName = result[items].fn;
            data.motherLastName = result[items].mn;
            let responseTmp = await axios({
                url: `${URL}/iterns/listAll`,
                method: "POST",
                data: data,
            });

            for (const item in responseTmp.data.interns) {
                response.data.interns.push(responseTmp.data.interns[item]);
            }
        }
    };

  
    const addAccents = (source) =>{
      
      let tmp = source.replace(/a/g, 'á');
      tmp = tmp.replace(/e/g, 'é');
      tmp = tmp.replace(/i/g, 'í');
      tmp = tmp.replace(/o/g, 'ó');
      tmp = tmp.replace(/u/g, 'ú');

      tmp = tmp.replace(/A/g, 'Á');
      tmp = tmp.replace(/E/g, 'É');
      tmp = tmp.replace(/I/g, 'Í');
      tmp = tmp.replace(/O/g, 'Ó');
      tmp = tmp.replace(/U/g, 'Ú');

      tmp = tmp.replace(/N/g, 'Ñ');
      tmp = tmp.replace(/n/g, 'ñ');

      return tmp;

    }

    const replaceAccents = (source) => {
        let tmp = source.replace(/á/g, 'a');
        tmp = tmp.replace(/é/g, 'e');
        tmp = tmp.replace(/í/g, 'i');
        tmp = tmp.replace(/ó/g, 'o');
        tmp = tmp.replace(/ú/g, 'u');

        tmp = tmp.replace(/Á/g, 'A');
        tmp = tmp.replace(/É/g, 'E');
        tmp = tmp.replace(/Í/g, 'I');
        tmp = tmp.replace(/Ó/g, 'O');
        tmp = tmp.replace(/Ú/g, 'U');

        tmp = tmp.replace(/Ñ/g, 'N');
        tmp = tmp.replace(/ñ/g, 'n');

        return tmp;
    } 


    const sendEmail = async (intern) => {
        const emailParams = {
            to: intern.email,
            subject: 'App movil Grow',
            text: `
            <br/>
            <br/>
            <div class="im1_1">
            <a href="www.growpracticantes.com"> <img style="width:100%" src="https://caintrabucket-prod.s3.amazonaws.com/mailImages/qr4.png"></a>
            <br/>
            <br/>
            Consulta el archivo adjunto que te será de ayuda para ingresar a la nueva plataforma grow. 
            <br>
            <a href="https://caintrabucket-prod.s3.amazonaws.com/mailImages/Manual.pdf">Manual</a> 
            <br/>
            <br/><br/> En caso de presentar problemas para accesar por favor notificalo mediante el correo  de atención: <a href="mailto:soporte.practicantes@caintra.org.mx">soporte.</a>practicantes@caintra.org.mx Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.`
   
        };

        await Methods.sendExternalEmailApp(intern, emailParams);

    }

    const nextPage = async () => {
      if(nextPageKey && searchQuery2 != "") {
        setPrevPageKeys(prevPageKeys => [...prevPageKeys,currPageKey]);
        setCurrPageKey(nextPageKey);
        await getInterns(nextPageKey, undefined, currentLimit, true, '',null,searchQuery2);
      } else if(nextPageKey) {        
        setPrevPageKeys(prevPageKeys => [...prevPageKeys,currPageKey]);
        setCurrPageKey(nextPageKey);
        await getInterns(nextPageKey, undefined, currentLimit);
      }
    }

    const previousPage = async () => {
      if(prevPageKeys.length>0 && searchQuery2 != ""){
        let prevPageKey = prevPageKeys[prevPageKeys.length-1];
        setCurrPageKey(prevPageKey);
        let tmpKeys = prevPageKeys;        
        tmpKeys.pop();
        setPrevPageKeys(tmpKeys);        
        await getInterns(prevPageKey, undefined, currentLimit, true, '',null,searchQuery2);
      }else if(prevPageKeys.length>0 ){
        let prevPageKey = prevPageKeys[prevPageKeys.length-1]; 
        setCurrPageKey(prevPageKey);       
        let tmpKeys = prevPageKeys;        
        tmpKeys.pop();
        setPrevPageKeys(tmpKeys);        
        await getInterns(prevPageKey, undefined, currentLimit);
      }      
    }

    const handleChangePage = (_page) => {
        if (_page + 1 == allInterns.length) {
            getNextPage();
        }
        if (_page > 1) {
            setInterns(allInterns[_page]);
        } else {
            setInterns(allInterns[_page]);
        }
    };


    const getNextPage = async () => {
      setIsLoading(true);
      let {pages, interns: internsNextPage} = await Methods.getInternsNextPage(id_company, currentLimit, undefined, searchQuery, statusTable, totalPages, limitPages)
      setTotalPages(pages);
      setAllInterns(internsNextPage);
      setIsLoading(false);
    };

    const getCompany = async (allInterns) =>{
      let comp = [];
      let internsId = allInterns.map(intern => {
        return Methods.getCompanies([intern.id_company]);
      })
      let res = await Promise.all(internsId);
      res.forEach(r =>{
        comp.push(r[0].business_name);
      })
      setCompanies(comp)

    };

    const getLimitPages = (limit) => {
      let limitPagesAux={
          10: 20,
          20: 10,
          40: 5,
          60: 3,
          100: 2 
      }
      return limitPagesAux[limit];
    };


    const changeStatusByFilter = () => {
        setCurrentPage(0);
        let newStatus
        if (statusTable == "Todo") {
          newStatus = "Activo"
        } else if (statusTable == "Activo") {
            newStatus = "En proceso"
        } else if  (statusTable == "En proceso"){
          newStatus = "Pendiente"
        }else if (statusTable == "Pendiente") {
            newStatus = "Inactivo"
        } else if  (statusTable == "Inactivo"){
            newStatus = "Todo"
        }
        context.cleanDataByDashboard();
        setStatusTable(newStatus);
        getInterns(null, null, limitValues, true, searchQuery, newStatus);
    }

    const changeSignatureStatus = () => {
        setCurrentPage(0);
        let newStatus
        if (signatureStatus == "Todo") {
            newStatus = "Firmado"
        } else if (signatureStatus == "Firmado"){
            newStatus = "Pendiente"
        } else if  (signatureStatus == "Pendiente"){
            newStatus = "Atrasado"
        }else if (signatureStatus == "Atrasado") {
            newStatus = "Todo"
        }
        context.cleanDataByDashboard();
        setSignatureStatus(newStatus);
        getInterns(null, null, limitValues, true, searchQuery, statusTable, null, newStatus);
    }
    


    const dropDownOptions = (intern, showMissingValueAlert) => {
      return (
          <div>
              <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                      <BsThreeDots />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                      {(showMissingValueAlert || true) && (
                          <Dropdown.Item className="no-select">
                              <Row className="row-in-drop">
                                  <Col md={2} className="col icon-container">
                                      {" "}
                                      <RiErrorWarningLine />
                                  </Col>
                                  <Col md={9} className="col">
                                      Atención, tienes documentos <br />{" "}
                                      pendientes por llenar
                                  </Col>
                              </Row>
                          </Dropdown.Item>
                      )}

                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              setCurrentInternId(intern._id);
                              setCurrentTab("verifyInternDocs");
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <FaRegEye /> {" "}
                              </Col>
                              <Col md={9} className="col">
                                  {" "} Verificación de datos {" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>

                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              if (intern.internStatus != "Activo") {
                                  notify( "No puedes dar de baja un convenio si aún no esta activo", "error");
                                  return;
                              }
                              setCurrentIntern(intern);
                              setCurrentInternId(intern._id);
                              setCurrentInternName(intern.firstname + " " + intern.father_lastname);
                              setShowUnsuscribeInternModal(!showUnsuscribeInternModal);
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <ImCancelCircle />{" "}
                              </Col>
                              <Col md={9} className="col">
                                  {" "} Dar de baja{" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>

                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              setCurrentIntern(intern);
                              setCurrentInternId(intern._id);
                              if(role !== "Administrador Caintra2"){
                                setShowLetterInModal(!showLetterInModal);
                              }
                              else{
                                notify( "Estamos trabajando para brindarte nuevas funcionalidades.");
                                  return;
                              }
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <BsFileText /> {" "}
                              </Col>
                              <Col md={9} className="col">
                                  {" "} Carta Aceptación {" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>

                    
                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              setCurrentIntern(intern);
                              setCurrentInternId(intern._id);

                              if(role !== "Administrador Caintra2"){
                                setShowLetterOutModal(!showLetterOutModal);
                              }
                              else{
                                notify( "Estamos trabajando para brindarte nuevas funcionalidades.");
                                  return;
                              }
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <BsFiles /> {" "}
                              </Col>
                              <Col md={9} className="col">
                                  {" "} Carta Finalización {" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>

                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              setCurrentIntern(intern);
                              setCurrentInternId(intern._id);
                              if(role !== "Administrador Caintra2"){
                                  sendEmail(intern);
                              }

                              else{
                                notify( "Estamos trabajando para brindarte nuevas funcionalidades");
                                  return;
                              }
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <ImAndroid />{" "}
                              </Col>
     
                              <Col md={9} className="col">
                                  {" "} Notificación de APP {" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>


                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              setCurrentInternId(intern._id);
                              if(role == "Administrador Caintra"){
                                setShowTrainingModal(!showTrainingModal);
                              }
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <BsFillPencilFill/> {" "}
                              </Col>
                              <Col md={9} className="col">
                                  {" "} Resultados cursos {" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>

                      <Dropdown.Item
                          onClick={(e) => {
                              e.preventDefault();
                              setCurrentInternId(intern._id);
                              setShowDeleteInternModal(!showDeleteInternModal);
                          }}
                      >
                          <Row className="row-in-drop">
                              <Col md={2} className="col icon-container">
                                  {" "} <BsTrash /> {" "}
                              </Col>
                              <Col md={9} className="col">
                                  {" "} Eliminar registro {" "}
                              </Col>
                          </Row>
                      </Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>
          </div>
      );
    }

    return(
      <div className="interns-list">

        {(listType == "table" && searchQuery === "") && 
          <InternListTable
              interns={interns}
              context={context}
              setUpdateList={setUpdateList}
              updateList={updateList}
              statusTable={statusTable}
              isGlobal={isGlobal}
              companies={companies}
              requireSife={requireSife}
              changeSignatureStatus={changeSignatureStatus}
              dropDownOptions={dropDownOptions}
              changeStatusByFilter={changeStatusByFilter}
              signatureStatu={signatureStatus}
          />
        }
        
        {(listType == "table" && searchQuery != "") &&  
            <InternListTable
                interns={interns}
                context={context}
                setUpdateList={setUpdateList}
                updateList={updateList}
                statusTable={statusTable}
                isGlobal={isGlobal}
                companies={companies}
                requireSife={requireSife}
                changeSignatureStatus={changeSignatureStatus}
                dropDownOptions={dropDownOptions}
                changeStatusByFilter={changeStatusByFilter}
                signatureStatu={signatureStatus}
            />
        }

        <div className="interns-list-options float-right">
          <p>Elementos por página:</p>
            <DropdownList
                data={[10, 20, 40, 60, 100]}
                defaultValue={currentLimit}
                onSelect={(e) => {
                    context.cleanDataByDashboard();
                    setLimitValues({
                        currentLimit: e,
                        limitPages: getLimitPages(e)
                    })
                    if(currentSearch && currentSearch != ""){
                        getInterns(undefined, undefined, {currentLimit: e, limitPages: getLimitPages(e)}, true, currentSearch);
                    }else{
                        getInterns(undefined, undefined, {currentLimit: e, limitPages: getLimitPages(e)})
                    }
                }}
                containerClassName="limit-container"
            />

            { isGlobal &&
                <Button onClick={() => previousPage()}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Button>
            }
            { isGlobal && 
              <Button onClick={() => nextPage()}>
                <FontAwesomeIcon icon={faChevronRight}/>
              </Button>
            }
            { !isGlobal && 
                <ReactPaginate
                    pageCount={totalPages || 1}
                    forcePage={currentPage}
                    containerClassName="pagination-container"
                    previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                    nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                    onPageChange={(e) => {
                      handleChangePage(e.selected);
                      setCurrentPage(e.selected);
                    }}
                />
            }
            {isLoading && <LoadingModal isLoading={isLoading}/>}
        </div>
      </div>
    );
}
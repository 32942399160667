import React from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function VerifyChangeTab ({showVerifyChangeTab, setShowVerifyChangeTab, newTabName, setDocumentationTab,setFormChanged}) {
    const newDepartment = async (e) => {
        e.preventDefault();
        setDocumentationTab(newTabName);
        setShowVerifyChangeTab(!showVerifyChangeTab);
        setFormChanged(false);
    };

    return(
        <Modal className="new-cc-modal" show={showVerifyChangeTab} onHide={() => setShowVerifyChangeTab(!showVerifyChangeTab)} centered>
            <Modal.Header>
                <p>Advertencia</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={newDepartment}>
                    <Form.Group controlId="title">
                        <Form.Label>Todos los cambios no guardados se perderán</Form.Label>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Continuar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setShowVerifyChangeTab(!showVerifyChangeTab)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
import { Table } from 'react-bootstrap';
import { getFormatedAmount } from '../helpers';
export const TableReport = ({
    dataByBanks
}) => {

    return (
        <div>
            <Table className='table-amounts'>
                <thead>
                    <tr className='header-height'>
                        <th>Banco/Asesor</th>
                        <th> </th>
                        <th>Total</th>
                    </tr>
                </thead>

                <tbody>
                    {dataByBanks?.map(bank => {
                        return(
                            <>
                                {bank.advisers.map(advisor => {
                                    return (
                                        <tr className='header-height'>
                                            <th>{bank.bankName} {advisor.adviserName} </th>
                                            <th>$</th>
                                            <th>{getFormatedAmount(advisor.totalByBank)}</th>
                                        </tr>
                                    )

                                })}

                                <tr className='header-height total'>
                                    <th>{bank.bankName}</th>
                                    <th>$</th>
                                    <th>{getFormatedAmount(bank.total)}</th>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

import React, {useContext, useState} from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { UserContext } from '../../../context/UserContext';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function NewUniversity ({displayNewUniversity, setDisplayNewUniversity, updateList, setUpdateList}) {
    const context = useContext(UserContext);
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [errorPhoneSec, setErrorPhoneSec] = useState(false);
    const [labelPhoneSec, setLabelPhoneSec] = useState("");
    const [errorEmailSec, setErrorEmailSec] = useState(false);
    const [labelEmailSec, setLabelEmailSec] = useState("");
    const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/);
    const NewUniversity = async (e) => {
        e.preventDefault();
        const university = {
            collegeName: e.target.elements.collegeName.value,
            contactName: e.target.elements.contactName.value,
            fatherLastName: e.target.elements.fatherLastName.value,
            motherLastName: e.target.elements.motherLastName.value,
            email: e.target.elements.email.value,
            phone: e.target.elements.phone.value,
            sign: e.target.elements.sign.checked
        };
        try{
            await axios({
                url: `${URL}/universities`,
                method: "POST",
                data: university
            });

            notify(`${university.collegeName} agregado exitosamente como Universidad`, "success");
            setUpdateList(!updateList);
            setDisplayNewUniversity(!displayNewUniversity);
        } catch (e) {
            e.response && notify(e.response.data.message);
        };
    };

    const verifyEmail = async (setError, labelError, formDataName) => {
        if(formDataName.length > 0){
            if(!regexEmail.test(formDataName)){
                await setError(true);
                await labelError("El correo no es válido");
            } else {
                await setError(false);
                await labelError("");
            }
        } else {
            await  setError(false);
        }
    }

    const verifyPhone = async (setError, labelError, formDataName) => {
        if(formDataName.length > 0){
            if(formDataName.length == 10){
                await setError(false)
                await labelError("")
            }
            if(formDataName.length < 10 && formDataName.length >= 1){
                await setError(true);
                await labelError("El número de teléfono debe tener 10 dígitos");
            }
        } else {
            await setError(false);
            await labelError("El número de teléfono debe tener 10 dígitos");
        }
    }


    return(
        <Modal className="new-cc-modal" show={displayNewUniversity} onHide={() => setDisplayNewUniversity(!displayNewUniversity)} centered>
            <Modal.Header>
                <p>Nueva Universidad</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={NewUniversity}>
                    <Form.Group controlId="collegeName">
                        <Form.Label>Nombre de la Institución</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nombre de la institución"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="contactName">
                        <Form.Label>Nombre(s)</Form.Label>
                        <Form.Control type="text" placeholder="Nombre(s) del contacto"/>
                    </Form.Group>
                    <Form.Group controlId="fatherLastName">
                        <Form.Label>Apellido Paterno</Form.Label>
                        <Form.Control type="text" placeholder="Apellido paterno del contacto"/>
                    </Form.Group>
                    <Form.Group controlId="motherLastName">
                        <Form.Label>Apellido Materno</Form.Label>
                        <Form.Control type="text" placeholder="Apellido materno del contacto"/>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Introduce el correo del contacto" onChange={(e) => {verifyEmail(setErrorEmailSec, setLabelEmailSec, e.target.value)}}
                        />
                        { 
                            errorEmailSec && <Form.Label className="error-label" > {labelEmailSec}</Form.Label>
                        }
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Teléfono (10 dígitos)</Form.Label>
                        <Form.Control 
                            type="number"
                            onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                            }}
                            placeholder="Introduce el teléfono del contacto"
                            onChange={(e) => { verifyPhone(setErrorPhoneSec, setLabelPhoneSec, e.target.value)}}
                        />
                        {
                            errorPhoneSec && <Form.Label className="error-label" > {labelPhoneSec} </Form.Label>
                        }
                    </Form.Group>

                    <Form.Group controlId="sign">
                        <Form.Check className="checkbox" type="checkbox" label={`El usuario firmará por todas las ${context.getFacultadField(false, true)}`}/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Registrar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayNewUniversity(!displayNewUniversity)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};
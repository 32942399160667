import React from 'react';
import {Auth} from 'aws-amplify';
import {Container, Form, Button} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {notify} from '../../../utils/notify';

export default function ValidateEmail ({emailToValidate}) {
    const history = useHistory();

    const handleConfirmationSubmit = async (event) => {
        event.preventDefault();
        const _email = emailToValidate;
            
        try {
            await Auth.confirmSignUp(_email, event.target.elements.confirmationCode.value);
            notify("Tu cuenta se ha verificado exitosamente", "success");
            history.push("/");
        } catch (e) {
            switch (e.code) {
                case "LimitExceededException":
                notify('Excediste el número de intentos permitidos. Intenta en unos minutos', "error");
                    break;
                default:
                    notify('El token no es válido. Intenta nuevamente', "error");
                    break;
            };
        };
    };

    return(
        <Container>
            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId="confirmationCode">
                    <Form.Label>
                        Validar e-mail 
                    </Form.Label>
                    <Form.Control type="text" placeholder="Introduce el token que enviamos a tu correo electrónico" />
                </Form.Group>
            
            <Button className="float-right" variant="primary" type="submit">
                Validar E-mail
            </Button>

            </Form>
        </Container>
    )
}
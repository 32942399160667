import React, { useRef, useContext } from "react";
import html2canvas from "html2canvas";
import { Dropdown } from "react-bootstrap";
import download from "../../../assets/IconsCaintra/download.svg";
import genericExcel from "../Excel/GenericExcel";
import {saveAs} from 'file-saver';
import { notify } from "../../../utils/notify";
import * as Excel from "exceljs";
import moment from "moment";
import {UserContext} from '../../../context/UserContext';
import {Spinner} from 'react-bootstrap';
export const DownloadDashboard = ({
    refDashboard, 
    fileName, 
    customStyle,
    excelConfigurations,
    excelValues,
    periodSelected,
    dateRef,
    isLoadingExcel,
    currentRef,
    monthsToDownload
}) => {
    const context = useContext(UserContext);
    const exportAsImage = async (element, childrenRef, dateRefAux) => {
        if(dateRefAux?.current) dateRefAux.current.style.display = "";
        childrenRef.current.style.display = "none";
        const canvas = await html2canvas(element.current);
        const image = canvas.toDataURL("image/png", 1);
        downloadImage(image, (fileName + '_' + getCurrentMonth()));
        childrenRef.current.style.display = "";
        if(dateRefAux?.current) dateRefAux.current.style.display = "none";
    };

    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;
        fakeLink.href = blob;
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        fakeLink.remove();
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {<img src={download} className="download-icon" width="19px" />}
        </a>
    ));

    const getFormatedDate = (period) => moment().startOf('day').subtract((period-1) , 'month').format('MMM YYYY');

    const downloadGenericExcel = () => {
        
        let periodDates = getFormatedDate(periodSelected) +
        (monthsToDownload && monthsToDownload > 1 ? (' al ' + getFormatedDate(monthsToDownload) ) : '');
        const workbook = new Excel.Workbook();
        genericExcel.test(
            workbook,
            excelValues,
            excelConfigurations,
            moment().format(),
            context.user.data.fullName || '',
            fileName,
            periodDates
        );

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            saveAs(blob, fileName + '_' + getCurrentMonth());
        });
    }

    const getCurrentMonth = () =>  moment().startOf('day').subtract((periodSelected-1) , 'month').format('MMM_YYYY')

    return (
    <>
        { !isLoadingExcel ?
            <Dropdown ref={currentRef} style={{ display: "", ...customStyle}}>
                <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                ></Dropdown.Toggle>
                <Dropdown.Menu className="filters-menu options-search-container">
                    <Dropdown.Item
                        onClick={async () => {
                            await exportAsImage(refDashboard, currentRef, dateRef);
                        }}
                    >
                        Descargar imagen
                    </Dropdown.Item>
                    <hr/>
                    <Dropdown.Item
                        onClick={async () => {
                            if(excelConfigurations?.length && excelValues.length){
                                downloadGenericExcel();
                            }else{
                                notify('No hay datos por descargar', 'error');
                            }
                        }}
                    >
                        Descargar datos
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> : 
            <Spinner 
                animation="border" 
                role="status"
            />
        }
    </>
    );
};

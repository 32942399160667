import React from 'react'
import {Modal, Button, Form, Col, Row} from 'react-bootstrap';
import Methods from '../../../utils/https';
import moment from 'moment';
export const CancelContpaq = ({
    prefactureDetailID, 
    prefactureUrl, 
    prefactureUrlXml, 
    historicalPDF, 
    historicalXML, 
    showCancel, 
    setShowCancel, 
    setUpdateList, 
    updateList, 
    documentsNumber, 
    context,
    period,
    lastPeriod,
    isConsolidationPrefacture
}) => {


    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            result = month + "/" + day + "/"+ year;
        } else {        
            const newDateArray = dateString.split("/");
            month = addZeroToDate(newDateArray[0]);
            day = addZeroToDate(newDateArray[1]);
            result = day + "/" + month + "/" + newDateArray[2];
        }
        return result;
    }

    const addZeroToDate = (value) => {
        return value.length == 1 ? "0" + value : value
    };

    const getPeriodString = () => {
        const [startDate, endDate] = period;
        const a =  isConsolidationPrefacture ? moment(getFormatedDate(lastPeriod.start_date)).format('D/MM/YYYY') : moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
        const b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');

        return `periodo del ${a} al ${b}`;
    };

    const handleClose = () => {
        setShowCancel(!showCancel)
    }

    const cancelPrefacture = async () => {
        documentsNumber++;
        const record = {
            message: `Cancelación de prefactura del ${getPeriodString()}`,
            author: `${context.user.data.fullName}`,
            role: context.user.data.role,
            companyId: context.user.company._id,
            createdAt: new Date(),
            authorEmail: context.user.data.email,
        };

        await Methods.cancelPrefacture(prefactureDetailID, prefactureUrl, prefactureUrlXml, historicalPDF, historicalXML, documentsNumber);
        await Methods.createRecord(record);
        handleClose();
        setUpdateList(!updateList);
    }

    return (
        <Modal className="new-contpaq-modal" onHide={() => handleClose()} show={showCancel} centered >
            <Modal.Body>
                <Form autoComplete={"off"}>
                    <Row className='contpaq-margin'>
                        <Col>              
                            <Form.Label className='center-modal-text' style={{marginTop: '30px'}}>
                                ¿Deseas cancelar la factura actual?
                            </Form.Label>
                        </Col>
                    </Row>
                    
                    <Modal.Body className='contpaq-margin'>
                        <div className='center-buttons'>
                            <Button className='margin-buttom' variant="danger" onClick={() => handleClose()}>
                                Cancelar
                            </Button>
                            <Button variant="primary" onClick={() => cancelPrefacture()}>
                                Confirmar
                            </Button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

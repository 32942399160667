import React from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function DeleteNotificationModal ({displayDeleteNotificationModal, setDisplayDeleteNotificationModal, currentNotificationId, updateList, setUpdateList}) {
    const URL = process.env.REACT_APP_SOCKET_URL;
    const deleteNotification = async (e) => {
        e.preventDefault();
        const confirmation_code = e.target.elements.confirmation.value;
        
        if(confirmation_code != "eliminar permanentemente"){
            notify("La confirmación es incorrecta", "error");
            return;
        }

        try {
            await axios({
                url: `${URL}/notifications`,
                method: "DELETE", 
                data: {id_notification: currentNotificationId}
            });

            notify("Aviso eliminado con éxito", "success");
            setUpdateList(!updateList);
            setDisplayDeleteNotificationModal(!displayDeleteNotificationModal);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setUpdateList(!updateList);
        }
    };

    return(
        <Modal className="new-cc-modal" show={displayDeleteNotificationModal} onHide={() => setDisplayDeleteNotificationModal(!displayDeleteNotificationModal)} centered>
            <Modal.Header>
                <p>Eliminar Aviso</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={deleteNotification}>

                    <Form.Group controlId="confirmation">
                        <Form.Label>Escribe "eliminar permanentemente" para continuar</Form.Label>
                        <Form.Control type="text" required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayDeleteNotificationModal(!displayDeleteNotificationModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
import React, {useEffect, useState} from 'react'
import { InternByStatusDashboard } from './InternByStatusDashboard';
import { TotalInternsDashboard } from './TotalInternsDashboard';
import Methods from '../../../utils/https';
import moment from 'moment';
export const InternsByStatus = ({
    selectedCompanies,
    periodSelected,
    periodSelectedToCompare,
    getPeriodString,
    context,
    monthsToDownload,
    refsAux,
    setAllStatusExcelValues
}) => {

    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
    );

    const [dataByMonths, setDataByMonths] = useState({
        currentData: [],
        dataToCompare: []
    });
    const [allMonthsData, setAllMonthsData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const allStatusExcelConfigurations = [
        { key: "period", label: "Mes" },
        { key: "active", label: "Practicantes activos" },
        { key: "pending", label: "Practicantes pendientes" },
        { key: "inProcess", label: "Practicantes en proceso" },
        { key: "totalNoInactive", label: "Total de practicantes" }
    ];
    
    const totalInternsExcelConfigurations = [
        {key: 'period', label: 'Periodo'},
        {key: 'total', label: 'Total de practicantes'}
    ];

    useEffect(() => {
        getDataByMonthAndCompany();
    }, [periodSelected, selectedCompanies, periodSelectedToCompare]);

    useEffect(() =>{
        getAllMonthsData();
    }, [periodSelected, selectedCompanies, monthsToDownload]);

    const getDataByMonthAndCompany = async() => {
        setIsLoadingData(true);
        let dataToCompare = [];
        let results = await Promise.all(
            getPromises(periodSelected, 6)
        );

        if(periodSelectedToCompare){
            dataToCompare = await Promise.all(getPromises(periodSelectedToCompare, 6));
        }

        setDataByMonths({
            currentData: results,
            dataToCompare: dataToCompare
        });
        setIsLoadingData(false);
    }

    const getAllMonthsData = async() => {
        setIsLoadingExcel(true)
        let results = await Promise.all(getPromises(periodSelected, monthsToDownload));
        setAllMonthsData(getAllmonths(results));
        setIsLoadingExcel(false)
    }
    
    const getPromises = (period, months) => {
        let promisesAux = [];
        for(let selectedCompany of selectedCompanies){
            promisesAux.push(
                Methods.getInternStatusDataByCompanyMonth(selectedCompany, parseInt(period), months)
            );
        }
        return promisesAux;
    }

    const getAllmonths = (allMonthsDataAux) => {
        let excelValuesAux = [];
        let allStatusAux = {
            Activo: [0, 0, 0, 0, 0, 0],
            Pendiente: [0, 0, 0, 0, 0, 0],
            "En proceso": [0, 0, 0, 0, 0, 0],
        };
        let labelsAux = {
            Activo: "active",
            Pendiente: "pending",
            "En proceso": "inProcess",
            Inactivo: "inactive",
        };

        for (let companyData of allMonthsDataAux) {
            let companyExcelValues = {}
            let companyAux = context?.user?.data?.companiesByName?.find(
                (companyAux) => companyAux._id === companyData[0].companyId
            );
            companyExcelValues.name = companyAux?.business_name;
            companyExcelValues.values = [];

            for (let companyMonthData of companyData) {
                let monthDataByCompany = {
                    period: moment()
                        .startOf("day")
                        .subtract(companyMonthData.month - 1, "month")
                        .format("MMM YYYY"),
                    active: 0,
                    inactive: 0,
                    inProcess: 0,
                    pending: 0,
                    total: 0,
                    totalNoInactive: 0
                };
                companyMonthData?.allStatus?.map((statusAux) => {
                    let statusValue = allStatusAux[statusAux.status];
                    if (statusValue) {
                        statusValue[companyMonthData.month - periodSelected] +=
                            statusAux.total;
                    }
                    if (labelsAux[statusAux.status])
                        monthDataByCompany[labelsAux[statusAux.status]] =
                            statusAux.total;
                    monthDataByCompany.total =
                        companyMonthData.allStatus.reduce((acc, obj) => {
                            return acc + parseInt(obj.total || 0);
                        }, 0);
                    monthDataByCompany.totalNoInactive = companyMonthData.allStatus.reduce((acc, obj) => {
                        return acc + parseInt((obj.status != 'Inactivo' ? obj.total : 0)|| 0);
                    }, 0)
                });
                companyExcelValues.values.push(monthDataByCompany);
            }
            excelValuesAux.push(companyExcelValues);
        }
        setAllStatusExcelValues({
            excelValues: excelValuesAux,
            allStatusExcelConfigurations: allStatusExcelConfigurations,
            totalInternsExcelConfigurations: totalInternsExcelConfigurations
        })
        return excelValuesAux;
    };

    const sortByMonth = (currentMonths, monthsToCompare) => {
        let results = [];
        for (let start = 0; start <= 5; start++) {
            results.push(currentMonths[start]);
            results.push(monthsToCompare[start]);
        }

        return results;

    };


    return (
        <div className='intern-status-container'>
            <InternByStatusDashboard
                selectedCompanies={selectedCompanies} 
                periodSelected={periodSelected}
                periodSelectedToCompare={periodSelectedToCompare}
                context={context}
                getPeriodString={getPeriodString}
                moment={moment}
                dataByMonths={dataByMonths}
                allMonthsData={allMonthsData}
                sortByMonth={sortByMonth}
                isLoadingData={isLoadingData}
                isLoadingExcel={isLoadingExcel}
                allStatusExcelConfigurations={allStatusExcelConfigurations}
                refsAux={refsAux}
                monthsToDownload={monthsToDownload}
            />
            <TotalInternsDashboard
                selectedCompanies={selectedCompanies} 
                periodSelected={periodSelected}
                periodSelectedToCompare={periodSelectedToCompare}
                context={context}
                getPeriodString={getPeriodString}
                moment={moment}
                dataByMonths={dataByMonths}
                allMonthsData={allMonthsData}
                sortByMonth={sortByMonth}
                isLoadingData={isLoadingData}
                isLoadingExcel={isLoadingExcel}
                totalInternsExcelConfigurations={totalInternsExcelConfigurations}
                refsAux={refsAux}
                monthsToDownload={monthsToDownload}
            />
        </div>
    )
}

import Methods from "../../../utils/https";
import { notify } from "../../../utils/notify";

export const removeCompanyFromDesactivation = async (
    companySelected,
    companiesArray, 
    setCompanies,
    allCompanies,
    context
) => {

    if(!companySelected.isDisabled){
        
        delete companySelected['isDisabled'];
        
        setCompanies({
            allCompanies: allCompanies,
            companiesByPage: companiesArray
        });

        await Methods.removeCompanyFromDesactivation({
            companyId: companySelected._id,
            desactivationComments: companySelected.desactivationComments,
            createdBy: context.user.data.fullName,
            createdByEmail: context.user.data.email
        });

    }else{
        notify(
            'La compañía debe estar liberada para poder realizar la acción',
            'error'
        );
    }
}
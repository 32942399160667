
export default {
    

    test(workbook, period) {
        workbook.creator = "CAINTRA";
        workbook.lastModifiedBy = "CAINTRA";
        workbook.created = new Date();

        const dropDown = {
            "isConsolidationPeriod": ['"Sí, No"'],
            "isFirstConsolidationPeriod": ['"Sí, No"'],  
            "isSecondConsolidationPeriod": ['"Sí, No"'],
            "forceApprovePeriod": ['"Sí, No"'],
        }

        const data = {
            'cols': ["A","B","C","D","E","F","G","H","I","J","K"],
            'config' :[ 
                {  width: 20,  value: "start_date",              text: "Fecha Inicio",                    getData: () => { return "DD/MM/YYYY" }  },
                {  width: 20,  value: "end_date",         text: "Fecha Fin",               getData: () => { return "DD/MM/YYYY" }  },
                {  width: 20,  value: "payment_date",    text: "Dia de pago",        getData: () => { return "DD/MM/YYYY" }  },
                {  width: 20,  value: "limit_authorization",    text: "Limite de autorización",        getData: () => { return "DD/MM/YYYY" }  },
                {  width: 20,  value: "isConsolidationPeriod",         text: "Es Consolidado ?",      getData: () => { return "list" }  },
                {  width: 20,  value: "isFirstConsolidationPeriod",           text: "Primer Mes Consolidado",getData: () => { return "list" }  },
                {  width: 20,  value: "isSecondConsolidationPeriod", text: "Segundo Mes Consolidado",     getData: () => { return "list" }  },
                {  width: 20,  value: "forceApprovePeriod",            text: "Forzar aprobado",      getData: () => { return "list" }  },
            ],
            'worksheetName': "Altas Calendarios",
            'headerText': "Plantilla Altas Calendarios"
            };

        const cols = data.cols;
        let config = data.config;

        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet(data.worksheetName, {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createWhiteCell(startCell, text, key, worksheetName){
            if (text == "list"){
                worksheetName.getCell(startCell).dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: dropDown[key],
                    fgColor:{argb:'FFFFFFFF'}
                };
            } else {
                worksheetName.getCell(startCell).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFFFFFFF'}
                }
                worksheetName.getCell(startCell).value = text;
            }
            worksheetName.getCell(startCell).alignment = { wrapText:true, vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A1",
                "Periodo",
                worksheetName
            );
            createWhiteCell(
                "B1",
                period  || "",
                "",
                worksheetName
            )
        }

        function createTableAvail(worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                data.headerText,
                worksheetName
            );
            cellNumber++
            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });
            
            let rows = 50;

            while(rows-- > 0){
                cellNumber++
                config.forEach((configItem) => {
                    createWhiteCell(
                        configItem.col + cellNumber,
                        configItem.getData(),
                        configItem.value,
                        worksheetName
                    )
                });
            }
        }   

        createTableAvail(worksheetPicoUsoInfra)
        return workbook
    }
}

import React from 'react'
import { Modal } from 'react-bootstrap';
const $ENV = "localEnv";
export const VideoPlayer = ({showVideoPlayer, setShowVideoPlayer, videoLink}) => {

    const handleClose = () => {
        setShowVideoPlayer(!showVideoPlayer)
    }   

    return (
        <Modal 
            dialogClassName='modal-width' 
            contentClassName='modal-height' 
            show={showVideoPlayer} 
            centered
            onHide={() => handleClose()}>
            <video 
                className='video-player' 
                controls muted controlsList="nodownload" autoplay="autoplay">
                    <source 
                        src={videoLink}
                    />
            </video>
        </Modal>
    )
}

import React, { useContext } from 'react';
import {Modal, Image, Form, Button} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { UserContext } from '../../../context/UserContext';
import { notify } from '../../../utils/notify';


const axios = require("axios");


export default function DeleteUniversityModal ({displayDeleteUniversityModal, setDisplayDeleteUniversityModal, currentUniversity, updateList, setUpdateList}) {
    
    const context = useContext(UserContext);
    const URL = process.env.REACT_APP_SOCKET_URL;
    const deleteUniversity = async (e) => {
        e.preventDefault();
        const confirmation_code = e.target.elements.confirmation.value;
        
        if(confirmation_code != "eliminar permanentemente"){
            notify("La confirmación es incorrecta", "error");
            return;
        }

        try {
            await axios({
                url: `${URL}/universities`,
                method: "DELETE", 
                data: {id_university: currentUniversity._id, name_university: currentUniversity._name, university_data: currentUniversity }
            });

            notify("Universidad eliminada con éxito", "success");
            setUpdateList(!updateList);
            setDisplayDeleteUniversityModal(!displayDeleteUniversityModal);
        } catch (e) {
            e.response && notify(e.response.data.message, "error");
            setUpdateList(!updateList);
        }
    };

    return(
        <Modal className="new-cc-modal" show={displayDeleteUniversityModal} onHide={() => setDisplayDeleteUniversityModal(!displayDeleteUniversityModal)} centered>
            <Modal.Header>
                <p>Eliminar {context.getDepartmentField()}</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={deleteUniversity}>

                    <Form.Group controlId="confirmation">
                        <Form.Label>Escribe "eliminar permanentemente" para continuar</Form.Label>
                        <Form.Control type="text" required/>
                        <small>¡Estos cambios son irreversibles, todo Centro de Costos asociado con éste {context.getDepartmentField()} desaparecerá!</small>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        Confirmar
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayDeleteUniversityModal(!displayDeleteUniversityModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
const moment = require('moment');

export const createTextDispersionFile = (selectedInterns, selectedBank, dateToDisperse, nextNumber) => {
    let txt = [];
    if(selectedBank === "Santander"){
        let sequenceStartNumber = 2;
        const today = moment().format("MMDDYYYY");
        const applicationDate = moment(dateToDisperse).format("MMDDYYYY");
        const header = `100001E${today}65504448798     ${applicationDate}`;
        txt.push(header);

        selectedInterns.map(intern => {
            const id = (intern._id.slice(0, 6)).toUpperCase();
            const sequence = ('00000' + sequenceStartNumber).slice(-5);
            const spacedFatherLastName = (normalizeString(intern.father_lastname) + '                              ').slice(0, 30);
            const spacedMotherLastName = (normalizeString(intern.mother_lastname) + '                    ').slice(0, 20);
            const spacedFirstName = (normalizeString(intern.firstname) + '                              ').slice(0, 30);
            const accountNumber = ((intern.clabe.trim()).slice(0, 15)).slice(-16) + '     ';
            let internAmount = getAmount(intern.total_amount);
            const amountToDisperse = (("0000000000000000" + internAmount).slice(-18));
            txt.push(`2${sequence}${id} ${spacedFatherLastName}${spacedMotherLastName}${spacedFirstName}${accountNumber}${amountToDisperse}01`);
            sequenceStartNumber++;
        });

        const footerSequence = ('00000' + sequenceStartNumber).slice(-5);
        const totalSelectedInterns = ('00000' + selectedInterns.length).slice(-5);
        let totalAmount = getTotalPayment(selectedInterns);
        totalAmount = getAmount(totalAmount);
        const totalPayment = ('0000000000000000' + totalAmount).slice(-18);
        const footer = `3${footerSequence}${totalSelectedInterns}${totalPayment}`;
        txt.push(footer);
    };

    if(selectedBank === "Bancomer"){
        let sequenceStartNumber = 1;
        const digit = ("000000000" + sequenceStartNumber).slice(-9);

        selectedInterns.map(intern => {
            let intern_rfc = intern.rfc != undefined ? intern.rfc : " ";
            const rfc = (normalizeString(intern_rfc) + '                  ').slice(0, 16);
            const accountNumber = ((intern.clabe.trim()).slice(0, 15)).slice(-10);
            let internAmount = getAmount(intern.total_amount);
            const amountToDisperse = (("0000000000000000" + internAmount).slice(-15));
            const fullName = `${normalizeString(intern.firstname)} ${normalizeString(intern.father_lastname)} ${normalizeString(intern.mother_lastname)}`;
            const spacedFullName = (fullName + "                                      ").slice(0, 40);

            txt.push(`${digit}${rfc}99${accountNumber}          ${amountToDisperse}${spacedFullName}001001`);
            sequenceStartNumber++;
        });
    };

    if(selectedBank === "Banorte"){
        const applicationDate = moment(dateToDisperse).format("YYYYMMDD");
        const consecutive = ("00" + nextNumber).slice(-2);
        const totalSelectedInterns = ('000000' + selectedInterns.length).slice(-6);
        let totalAmount = getTotalPayment(selectedInterns);
        totalAmount = getAmount(totalAmount);
        const totalPayment = ('0000000000000' + totalAmount).slice(-15);

        let header = `HNE34954${applicationDate}${consecutive}${totalSelectedInterns}${totalPayment}${Array(50).join("0")}${Array(78).join(" ")}`;
        txt.push(header);

        selectedInterns.map((intern, index) => {
            const id = ("000000000" + (index + 1)).slice(-10);
            let internAmount = getAmount(intern.total_amount);
            const amountToDisperse = (("0000000000000000" + internAmount).slice(-15));
            const accountNumber = "00000000" + (((intern.clabe.trim()).slice(0, 15)).slice(-10));

            txt.push(`D${applicationDate}${id}${Array(81).join(" ")}${amountToDisperse}07201${accountNumber}0 00000000${Array(19).join(" ")}`);
        });
    };

    if(selectedBank != "Banorte" && selectedBank != "Bancomer" && selectedBank != "Santander"){
        let applicationDate = moment(dateToDisperse);
        applicationDate = moment(applicationDate).add(1, 'd').format("YYYYMMDD");
        const consecutive = ("00" + nextNumber).slice(-2);
        const totalSelectedInterns = ('000000' + selectedInterns.length).slice(-6);
        let totalAmount = getTotalPayment(selectedInterns);
        totalAmount = getAmount(totalAmount);
        const totalPayment = ('0000000000000' + totalAmount).slice(-15);

        let header = `HNE34954${applicationDate}${consecutive}${totalSelectedInterns}${totalPayment}${Array(50).join("0")}${Array(78).join(" ")}`;
        txt.push(header);

        selectedInterns.map((intern, index) => {
            const id = ("000000000" + (index + 1)).slice(-10);
            let internAmount = getAmount(intern.total_amount);
            const amountToDisperse = (("0000000000000000" + internAmount).slice(-15));
            const accountNumber = ('000000000000000000' + intern.clabe).slice(-18);

            txt.push(`D${applicationDate}${id}${Array(81).join(" ")}${amountToDisperse}${getBankCode(intern.bank)}40${accountNumber}0 00000000${Array(19).join(" ")}`);
        });
    };

    const data = new Blob([txt.join('\n')], { type: 'text/plain' });
    
    return data
}


const getAmount = (amount) => {
    let result;
    let internAmount = amount.toFixed(2).toString();
    internAmount = internAmount.split(".") || amount;
    if(internAmount[0] == "0"){
        result = (internAmount[1].toString() + "000").slice(0,2);
    }else{
        if(internAmount[1] == "00"){
            result = internAmount[0] + "00"
        }else{
            result = internAmount[0] + internAmount[1];
        }
    }

    return result 
};



const getBankCode = (bank) => {
    let selectedBank = {
        "Banamex": "002",
        "HSBC": "021",
        "Afirme": "062",
        "Banjio": "030",
        "Banjercito": "019",
        "Bancoppel": "137",
        "Inbursa": "036",
        "Scotiabank": "044",
        "Banregio": "058",
        "Banco Azteca": "127",
        "Bancomext": "006",
        "Santander": "014"
    };
    return selectedBank[bank];
};

const normalizeString = (string) => {
    let str = string.toUpperCase().trim();
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return str;
};

const getTotalPayment = (selectedInterns) => {
    let total = 0;
    selectedInterns.map(intern => total += Number.parseFloat(intern.total_amount.toFixed(2)));
    return Number.parseFloat(total);
};
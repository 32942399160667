import React, { useContext, useState } from 'react';
import { Modal, Image, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import { UserContext } from '../../../context/UserContext';
import { notify } from '../../../utils/notify';
const axios = require("axios");

export default function NewFacultyModal ({displayNewFacultyModal, setDisplayNewFacultyModal, currentUniversity, currentUniversityId, updateList, setUpdateList}) {
    const context = useContext(UserContext);
    
    const URL = process.env.REACT_APP_SOCKET_URL;
    const [isLoading, setIsLoading] = useState(false);
    const faculty = {};
    const createFaculty = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (currentUniversity.sign) {
            faculty.id_university = currentUniversityId;
            faculty.facultyName = e.target.elements.facultyName.value;
            faculty.region = e.target.elements.region?.value;
            faculty.contactName = currentUniversity.contactName;
            faculty.fatherLastName = currentUniversity.fatherLastName;
            faculty.motherLastName = currentUniversity.motherLastName;
            faculty.email = currentUniversity.email;
            faculty.phone = currentUniversity.phone;
        } else {
            faculty.id_university = currentUniversityId;
            faculty.facultyName = e.target.elements.facultyName.value;
            faculty.region = e.target.elements.region?.value;
            faculty.contactName = e.target.elements.contactName.value;
            faculty.fatherLastName = e.target.elements.fatherLastName.value;
            faculty.motherLastName = e.target.elements.motherLastName.value;
            faculty.email = e.target.elements.email.value;
            faculty.phone = e.target.elements.phone.value;
        }

        try {
            await axios({
                url: `${URL}/faculties`,
                method: "POST",
                data: faculty
            });

            notify(`"${faculty.facultyName}" registrado exitosamente`, "success");
            setUpdateList(!updateList);
            setIsLoading(false);
            setDisplayNewFacultyModal(!displayNewFacultyModal);
        } catch (e) {
            setIsLoading(false);
            e.response && notify(e.response.data.message, "error");
        };
    };

    return (
        <Modal className="new-cc-modal" show={displayNewFacultyModal} onHide={() => setDisplayNewFacultyModal(!displayNewFacultyModal)} centered>
            <Modal.Header>
                <p>Nueva {context.getFacultadField()}</p>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={createFaculty} autoComplete={"off"}>
                    <Form.Group controlId="facultyName">
                        <Form.Label>Nombre de {context.getFacultadField()}</Form.Label>
                        <Form.Control type="text" required />
                    </Form.Group>
                    {currentUniversity._name == "Instituto Tecnológico y de Estudios Superiores de Monterrey" &&
                        <Form.Group controlId='region'>
                            <Form.Label>Nombre de region</Form.Label>
                            <Form.Control as="select" required >
                                <option>Online</option>
                                <option>EGADE Business School y Escuela de Gobierno y Transfromación Pública</option>
                                <option>Centro/Sur</option>
                                <option>Ciudad de México</option>
                                <option>Monterrey</option>
                                <option>Occidente</option>
                                <option>Vicepresidencia de PrepaTec y Desarrollo Regional</option>
                            </Form.Control >
                        </Form.Group>
                    }
                    {currentUniversity._name == "Universidad TecMilenio" &&
                        <Form.Group controlId='region'>
                            <Form.Label>Nombre de region</Form.Label>
                            <Form.Control as="select" required >
                                <option>Online</option>
                                <option>Centro/Sur</option>
                                <option>Ciudad de México</option>
                                <option>Monterrey</option>
                                <option>Occidente</option>
                            </Form.Control >
                        </Form.Group>
                    }
                    {currentUniversity.sign ? (
                        <>
                            <Form.Group controlId="contactName">
                                <Form.Label>Nombre del contacto</Form.Label>
                                <Form.Control type="text" defaultValue={currentUniversity.contactName} readOnly />
                            </Form.Group>
                            <Form.Group controlId="fatherLastName">
                                <Form.Label>Apellido Paterno</Form.Label>
                                <Form.Control type="text" defaultValue={currentUniversity.fatherLastName} readOnly />
                            </Form.Group>
                            <Form.Group controlId="motherLastName">
                                <Form.Label>Apellido Materno</Form.Label>
                                <Form.Control type="text" defaultValue={currentUniversity.motherLastName} readOnly />
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" defaultValue={currentUniversity.email} readOnly />
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control type="number" defaultValue={currentUniversity.phone} readOnly />
                            </Form.Group>
                        </>) : (
                        <>
                            <Form.Group controlId="contactName">
                                <Form.Label>Nombre del contacto</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group controlId="fatherLastName">
                                <Form.Label>Apellido Paterno</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group controlId="motherLastName">
                                <Form.Label>Apellido Materno</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control type="number" />
                            </Form.Group>
                        </>)}
                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Registrar"}
                    </Button>
                    <Button className="float-right" variant="danger" type="button" onClick={() => setDisplayNewFacultyModal(!displayNewFacultyModal)}>
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
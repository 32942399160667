import React, {useEffect, useState, useContext} from 'react';
import Methods from '../../../utils/https';
import {Card, Col, Row, Tooltip, OverlayTrigger, Table} from 'react-bootstrap';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faUser, faMailBulk, faPlusCircle, faIdCard, faUserTag, faCheckCircle, faTimesCircle, faTrashAlt, faPen, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import DeleteUserModal from './DeleteUserModal.jsx';
import ApproveUserModal from './ApproveUser.jsx';
import DenyUserModal from './DenyUser.jsx';
import EditUserModal from './EditUserModal.jsx';
import {UserContext} from '../../../context/UserContext';
import { BiArrowBack } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { IoReload } from 'react-icons/io5';
import EditAdvisorModal from './EditAdvisorModal.jsx';
import { notify } from '../../../utils/notify';


const axios = require("axios");

export default function Users({currentCompanyId, setTab, availableDepartments, setAvailableDepartments, companies}){
    const [users, setUsers] = useState([]);
    const [updateList, setUpdateList] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [openApproveUserModal, setOpenApproveUserModal] = useState(false);
    const [openDenyUserModal, setOpenDenyUserModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [openEditAdvisorModal, setOpenEditAdvisorModal] = useState(false);
    const [rotateSync, setRotateSync] = useState(false);
    const [usersApproved, setUsersApproved] = useState(false);
    const [usersUnsubscribed, setUsersUnsubscribed] = useState(false);
    const [usersNotApproved, setUsersNotApproved] = useState(false);
    const [usersLeaderApproved, setUsersLeaderApproved] = useState(false);
    const [currentDepartment, setCurrentDepartment] = useState(undefined);
    const [currentDepartmentId, setCurrentDepartmentId] = useState(undefined);
    const [isCaintraCompany, setIsCaintraCompany] = useState(false);
    const [valueLeader, setValueLeader] = useState("No");
    const [currentCompany, setCurrentCompany] = useState(undefined); 
    const [leader, setLeader] = useState(false);

    const unsuscribe = useState(true);
    const noUnsuscribe = useState(false);
    
    const rotate = rotateSync ? "rotate" : "";
    const context = useContext(UserContext);
    const canEdit = context.can("update", "Users");
    const userId = context.user.data._id;

    useEffect(() => {
        getData()
    }, [updateList]);

    const getData = async () => {
       await Promise.all([verifyCompany(), getUsersByCompany()])
    }

    const verifyCompany = async () => {
        let [currentCompany] = companies.filter(company => company._id == currentCompanyId)
        setCurrentCompany(currentCompany);
        if(currentCompany?.isCaintraCompany) setIsCaintraCompany(true);
    };

    const getUsersByCompany = async () => {
        setRotateSync(true);
        const data = await Methods.getUsersByCompany(currentCompanyId);
        data && setUsers(data);
        await verifyUsers(data);
        setRotateSync(false);
    };

    const verifyUsers = async (data) => {
        let approved = 0;
        let notApproved = 0;
        let leader = 0;
        let unsubscribed = 0;
        await data.forEach(element => {
            if(element.isApproved){
                approved += 1;
                if(element.departmentId){
                    leader += 1;
                }
            }else if(element.unsubscribed){
                unsubscribed += 1;
            }else{
                notApproved += 1;
            }
        });
        await setVisibility(setUsersApproved, approved);
        await setVisibility(setUsersNotApproved, notApproved);
        await setVisibility(setUsersLeaderApproved, leader);
        await setVisibility(setUsersUnsubscribed, unsubscribed);
    }

    const setVisibility = async (set, value) => {
        if(value == 0){
            set(false);
        } else {
            set(true);
        }
    }

    const handleDeleteUser = (user, departmentId) => {
        setCurrentUser(user);
        setOpenDeleteUserModal(true);
        setCurrentDepartmentId(departmentId)
    };

    const handleEditUser = (user, departmentId, department, value, isLeader) => {
        if(!canEdit){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };
        setCurrentUser(user);
        setCurrentDepartment(department);
        setCurrentDepartmentId(departmentId);
        setValueLeader(value)
        setLeader(isLeader)
        setOpenEditUserModal(true);
    };

    const handleApproveUserModal = (user) => {
        setCurrentUser(user);
        setOpenApproveUserModal(true);
    };

    const handleDenyUserModal =(user) => {
        setCurrentUser(user);
        setOpenDenyUserModal(true);
    }

    const handleEditAdvisor = (user) => {
        setCurrentUser(user);
        setOpenEditAdvisorModal(true);
    };

    const generateUsersList = (type) => {
        const optionsByType = {
            requesting: (user) => <>
                <a href="#" onClick={() => handleDenyUserModal(user)}>
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </a>
                <a href="#" onClick={() => handleApproveUserModal(user)}>
                    <FontAwesomeIcon icon={faCheckCircle}/>
                </a>
            </>,
            approved: (user) => <>
                {
                    user.isCaintraUser &&
                    <a href="#" onClick={() => handleEditAdvisor(user)}>
                        <FontAwesomeIcon icon={faUserTag}/>
                    </a>
                }
                {
                    userId != user._id &&
                    <a href="#" onClick={() => handleEditUser(user, undefined , undefined, "No", false)}>
                        <FontAwesomeIcon icon={faPen}/>
                    </a>
                }
                {
                    userId != user._id &&
                    <a href="#" onClick={() => handleDeleteUser(user, undefined)}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </a>
                }
            </>,
            leader: (user) => <>
                {
                    user.isCaintraUser &&
                    <a href="#" onClick={() => handleEditAdvisor(user)}>
                        <FontAwesomeIcon icon={faUserTag}/>
                    </a>
                }
                {
                    userId != user._id &&
                    <a href="#" onClick={() => handleEditUser(user, user.departmentId, user.department?.title, "Si", true)}>
                        <FontAwesomeIcon icon={faPen}/>
                    </a>
                }
                {
                    userId != user._id &&
                    <a href="#" onClick={() => handleDeleteUser(user, user.departmentId)}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </a>
                }
            </>,
            unsubscribed: (user) => <>
                
            </>
        };
        const titlesByType = {
            requesting: 'SOLICITANDO ACCESO',
            approved: 'APROBADOS',
            leader: context.getGeneralistaField(false, true).toUpperCase(),
            unsubscribed: 'BAJA',
        };
        return (
            <div className="table">
                <h5>{titlesByType[type]}</h5>
                <Row className="header">
                    <Col md={3}>Usuario</Col>
                    <Col md={3}>Correo</Col>
                    <Col md={type == 'leader' ? 3 : 5}>Rol</Col>
                    {type == 'leader' && <Col md={2}>{context.getDepartmentField(true)}</Col>}
                    <Col className="options" md={1}>Opciones</Col>
                </Row>
                {
                    users.map(user => {
                        if(((user.isCaintraUser && isCaintraCompany) || !user.isCaintraUser) && currentCompany._id == user.principalCompany){
                            if(user.unsubscribed && user._id != userId)
                            user.type = 'unsubscribed';
                            else if(!user.isApproved && user._id != userId)
                                user.type = 'requesting';
                            else if(user.isApproved && !user.departmentId)
                                user.type = 'approved';
                            else if(user.isApproved && user._id != userId)
                                user.type = 'leader';
                            return (
                                user.type == type &&
                                <Row className="body">
                                    <Col md={3}> {user.fullName} </Col>
                                    <Col md={3}> {user.email} </Col>
                                    <Col md={type == 'leader' ? 3 : 5}> {user._role} </Col>
                                    {
                                        type == 'leader' &&
                                        <Col md={2}> {user.department ? user.department?.title : ""} </Col>
                                    }
                                        <Col className="options" md={1}> {optionsByType[type](user)} </Col>
                                </Row>
                            );
                        }
                    })
                }
            </div>
        );
    }

    return(
        <motion.div className="users" initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>
            <div className="title-container">
                <BiArrowBack onClick={() => setTab("listCompanies")} className="back"/> <h4>Gestión de usuarios</h4>
                <div className="users-options">
                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Registrar Usuario</Tooltip>}
                    >
                        <div onClick={() => {setTab("createUser")}}><FiPlus/></div>
                    </OverlayTrigger>

                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                    >
                        <div className="reload-table" onClick={() => {setUpdateList(!updateList);}}><IoReload className={rotate}/></div> 
                    </OverlayTrigger>
                </div>
            </div>
            
            <Row className="user-container">
                {users && 
                    <div className="scroll-row-section">
                        {usersNotApproved &&
                            generateUsersList('requesting')
                        }
                        {usersApproved &&
                            generateUsersList('approved')
                        }
                        {usersLeaderApproved &&
                            generateUsersList('leader')
                        }
                        {usersUnsubscribed &&
                            generateUsersList('unsubscribed')
                        }
                    </div>
                }
            </Row>

           
            <EditUserModal
                openEditUserModal={openDeleteUserModal}
                setOpenEditUserModal={setOpenDeleteUserModal}
                currentUser={currentUser}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentDepartment={currentDepartment}
                currentDepartmentId={currentDepartmentId}
                currentCompanyId={currentCompanyId}
                valueLeader={valueLeader}
                leader={leader}
                setValueLeader={setValueLeader}
                setLeader={setLeader}
                unsuscribe={unsuscribe}
            />

            <ApproveUserModal
                openApproveUserModal={openApproveUserModal}
                setOpenApproveUserModal={setOpenApproveUserModal}
                currentUser={currentUser}
                updateList={updateList}
                setUpdateList={setUpdateList}
            />

            <DenyUserModal
                openDenyUserModal={openDenyUserModal}
                setOpenDenyUserModal={setOpenDenyUserModal}
                currentUser={currentUser}
                updateList={updateList}
                setUpdateList={setUpdateList}
            />

            <EditUserModal
                openEditUserModal={openEditUserModal}
                setOpenEditUserModal={setOpenEditUserModal}
                currentUser={currentUser}
                updateList={updateList}
                setUpdateList={setUpdateList}
                currentDepartment={currentDepartment}
                currentDepartmentId={currentDepartmentId}
                currentCompanyId={currentCompanyId}
                valueLeader={valueLeader}
                leader={leader}
                setValueLeader={setValueLeader}
                setLeader={setLeader}
                unsuscribe={noUnsuscribe}
            />

            <EditAdvisorModal
                openEditUserModal={openEditAdvisorModal}
                setOpenEditUserModal={setOpenEditAdvisorModal}
                currentAdvisor={currentUser}
                updateList={updateList}
                setUpdateList={setUpdateList}
                advisors={users}
            />
        </motion.div>
    );
};

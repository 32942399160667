import React, {useState, useEffect} from 'react';
import {Modal, Button, ProgressBar} from 'react-bootstrap';



export default function SendInsurance({sentIntern, internsSelected, showSendInsurance, setshowSendInsurance, processData,loadingInsurance,setLoadingInsurance}){
  

  useEffect(()=>{
  }, []);
   
    return(
        <Modal className="prefacture-modals" show={showSendInsurance} centered>
            <Modal.Body>
                <Modal.Header>
                    <p>Enviar Pólizas</p>
                    <p> Tiempo de carga aproximado { internsSelected * 5 } segundos</p> 
                </Modal.Header>
                {!loadingInsurance && 
                <Button className="float-right ml-2" variant="primary" onClick={ () => {setLoadingInsurance(true); processData();} }>
                    Confirmar
                </Button>}
                {!loadingInsurance && 
                <Button className="float-right" variant="danger" onClick={() => {setshowSendInsurance(false);}}>
                    Cancelar
                </Button>}
                {loadingInsurance && <ProgressBar animated now={Math.ceil( sentIntern / internsSelected*100)} />}
            </Modal.Body>
        </Modal>
    );
};
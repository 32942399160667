import React, {useContext, useState, useEffect} from 'react';
import {UserContext} from '../context/UserContext';
import Sidebar from './components/Sidebar.jsx';
import './Layout.css';
import LoadingModal from '../custom/LoadingModal/LoadingModal';
import Unauthorized from './components/Unauthorized.jsx';
import ChangeCompany from './components/ChangeCompany.jsx';


export default function Layout ({component, componentName}) {
    const context = useContext(UserContext);
    const [sidebarExtended, setSidebarExtended] = useState(true);
    const [showChangeCompany, setShowChangeCompany] = useState(false);
    const [currentCompanyId, setCurrentCompanyId] = useState();
    const [reload, setReload] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [textCompanies, setTextCompanies] = useState([]);

    useEffect(() => {
        loadCompanies();
        setCurrentCompanyId(context.user ? context.user.company._id : "");
    }, [context.isLoading]);

    const loadCompanies = async () => {
        !context.isLoading && await getCompanies();
    }

    const assignCompanies = (array) => {
        setCompanies(array);

        let _textCompanies = [];
        array.forEach(company => {
            !!company.name && _textCompanies.push(company.name)
        });

        setTextCompanies(_textCompanies);
    }

    const getCompanies = async () => {
        const companiesObject = []; 
        context.user.data.companiesByName.map(element => {
            companiesObject.push({
                id: element._id,
                name: element.business_name
            });
        });
        companiesObject.length && await assignCompanies(companiesObject);
    };


    const seeAsCompany = async (companyId) => {
        setReload(true);
        await context.setCurrentCompany(companyId);
        setReload(false);
    };

    const findCompanyId = (nameCompany) => {
        const foundValue = companies.find((company) => company.name == nameCompany);
        setCurrentCompanyId(foundValue.id || companies[0].id);
    }





    return(
        <>
            <div className="layout" style={{padding: '10px'}}>
                <div 
                    className="sidebar-container"
                    style={
                        sidebarExtended ? {
                            width: '245px',
                        } : {
                            width: '75px',
                        }
                    }
                >
                    <Sidebar
                        context={context}
                        sidebarExtended={sidebarExtended}
                        setSidebarExtended={setSidebarExtended}
                        setShowChangeCompany={setShowChangeCompany}
                    />
                    {context && 
                        <ChangeCompany
                            setShowChangeCompany={setShowChangeCompany}
                            showChangeCompany={showChangeCompany}
                            textCompanies={textCompanies}
                            seeAsCompany={seeAsCompany}
                            findCompanyId={findCompanyId}
                            currentCompanyId={currentCompanyId}
                        />
                    }
                </div>
                <div className="page" style={{width: `calc(99% - ${ sidebarExtended ? '245px' : '75px' })`}}>
                    <div style={{padding: "0rem 0 0 1rem"}}>
                        {context.isLoading && <LoadingModal isLoading={context.isLoading} />}
                        {(!context.isLoading && !reload && context.can("view", componentName)) && component}
                        {(!context.isLoading && !reload && !context.can("view", componentName)) && <Unauthorized />}
                    </div>
                </div>
            </div>
        </> 
    );
};
import React, { useState, useEffect, createContext } from "react";
import moment from 'moment';
import {Modal, Button} from 'react-bootstrap';
import logoPreview from '../../../assets/logo_preview.png';
import layerPreview from '../../../assets/layer_preview.svg';
import {NumberAsString} from '../../../utils/NumberAsString';
import Methods from '../../../utils/https';
import { FiZoomIn, FiZoomOut} from "react-icons/fi";
import './Preview.css';
let paidDual = 0;
let countInternsTmp = 0;
let totalDualGlobal= 0;

function Peview({totalDual ,countInterns, showPreviewMD, setShowPreviewMD, isApproved, context, isConsolidationPrefacture, period, getFormatedDate, lastPeriod, prefactureData, setVerifyPreviewMD, verifyPreviewMD, setSendPrefactureAux, sendPrefactureAux, agentName, options}) {
    countInternsTmp = countInterns;
    totalDualGlobal = totalDual;
    if(countInterns <= 10 ){
        paidDual = 1599.00;
    }
    else if(countInterns >10 && countInterns <= 24){
        paidDual = 1499.00;
    }
    else if(countInterns> 24){
        paidDual = 1299.00;
    }
    const [currentPayDay, setCurrentPayDay] = useState(moment().format("DD/MM/YYYY"));
    const getPeriodString = () => {
        const [startDate, endDate] = period;
        const a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
        const b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');

        return `PERIODO DEL ${a} AL ${b}`;
    };
    
    const getConsolidationPeriodString = () => {
        
        const [startDate, endDate] = period;
        const a = moment(getFormatedDate(lastPeriod.start_date)).format('D/MM/YYYY');
        const b =  moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');

        return `PERIODO DEL ${a} AL ${b}`;
    };
    const DATE = !isConsolidationPrefacture ? getPeriodString() : getConsolidationPeriodString();
    const handleVerify = () => {
        setVerifyPreviewMD(false); 
        setShowPreviewMD(!showPreviewMD);
        setSendPrefactureAux({ 
            ...sendPrefactureAux, 
            showSendPrefacture: true, 
            observations: DATE, 
            authorizedInfo: {
                authorName: context.user.data.fullName, 
                authorId: context.user.data._id, 
                authorEmail: context.user.data.email,
                periodType: getFormatedPayment(context.user.company.payment_period),
                companyName: context.user.company.business_name,
                invoiceAmount: prefactureData._total,
                periodDates: DATE,
                agentName: agentName
            } 
        })
    }


    const getFormatedPayment = (payment_period)=>{
        const formatedPayment = {
            "CATORCENAL": 'Catorcenal',
            "QUINCENAL": 'Quincenal',
            "QUINCENAL MENSUAL":'Quincenal-Mensual',
            "MENSUAL 15": 'Mensual 15',
            "MENSUAL 30": 'Mensual 30',
            "MENSUAL 15 BAT": 'Mensual 15 BAT'
        }
        return formatedPayment[payment_period] || payment_period;
    }

    const returnOptionKey = (optionId, options) => {
        for(let option of options){
            if(option._id == optionId) {
                return option.type == "factureCFDI" ? option.satKey.toString() + " " + option.concept.toString() : option.satKey.toString() + " - " + option.concept.toString();
            };
        }
        return " ";
    } 

    return (
        <Modal className="prefacture-modals" onHide={() => {setVerifyPreviewMD(false); setShowPreviewMD(!showPreviewMD)}} show={showPreviewMD} centered>
                <Invoice 
                currentPayDay={currentPayDay}
                context={context}
                DATE={DATE}
                prefactureData={prefactureData}
                verifyPreviewMD={verifyPreviewMD}
                handleVerify={handleVerify}
                agentName={agentName}
                returnOptionKey={returnOptionKey}
                options={options}
                />
        </Modal>
    );
}

export default Peview;

function Invoice({currentPayDay,context,DATE,prefactureData,verifyPreviewMD,handleVerify,agentName,returnOptionKey,options}){
    console.log(prefactureData);
    const STRINGTOTAL = `${NumberAsString(prefactureData._total)} ${((Math.round(prefactureData._total.toFixed(2) * 100)) - (Math.floor(prefactureData._total.toFixed(2)) * 100))}/100 M.N.`;
    const INSURANCE = context.user.company.interns_medical_insurance == "Sí" || context.user.company.business_name?.toUpperCase().includes("DUAL") ? "150px" : "80px";
    const BOTTOM = context.user.company.interns_medical_insurance == "Sí" || context.user.company.business_name?.toUpperCase().includes("DUAL")? "5px" : "0px";
    const PAYMENT = context.user.company.interns_medical_insurance == "Sí" || context.user.company.business_name?.toUpperCase().includes("DUAL")? "220px" : "169px";
    const [cssAux, setCssAux] =useState({zoom: 1, paddingTop: 0});

    const zoomIn = () => {
        setCssAux({zoom: cssAux.zoom+0.1, paddingTop: cssAux.paddingTop+1})
    }

    const zoomOut = () => {
        setCssAux({zoom: cssAux.zoom-0.1, paddingTop: cssAux.paddingTop-1})
    }

    return(
        <>
        <div className="container-center-horizontal" style={{...cssAux}}>
            <div className="factura screen">
                <div className="flex-row">
                    <div className="flex-row-1">
                        <img className="logo" alt="Logo" src={logoPreview}/>
                        <div className="flex-col">
                            <div className="flex-row-2">
                                <div className="emisor roboto-bold-black-10px">
                                    Emisor:
                                </div>
                                <div className="documento-no-oficial">
                                    DOCUMENTO NO OFICIAL
                                </div>
                            </div>
                            <p className="flex-col-item roboto-bold-black-10px-2">
                                CAMARA DE LA INDUSTRIA DE TRANSFORMACION DE NUEVO LEON
                            </p>
                            <div className="rfc-cit4402264-p8 roboto-bold-black-10px">
                                <span className="roboto-bold-black-10px">RFC:</span>
                                <span className="roboto-normal-black-10px"> CIT4402264P8</span>
                            </div>
                            <div className="flex-col-item roboto-normal-black-10px">
                                Parque Fundidora 501-95-A
                            </div>
                            <div className="overlap-group4 roboto-normal-black-10px">
                                <div className="col-obrera">
                                    Col. Obrera
                                </ div>
                                <div className="x64010-monterrey-nuevo-len">
                                    64010, Monterrey. Nuevo León
                                </div>
                            </div>
                            <div className="flex-col-item roboto-normal-black-10px" style={{"marginTop":"2px"}}>
                                Tel. (81) 8369-0200
                            </div>
                        </div>
                    </div>
                    <div className="flex-col-1">
                        <div className="field-4">
                            <div className="factura-1 roboto-bold-alabaster-10px">
                                Factura
                            </div>
                        </div>
                        <div className="field-container">
                            <div className="field border-1px-black">
                                <div className="serie roboto-bold-black-10px">
                                    Serie:
                                </div>
                            </div>
                            <div className="field-1 border-1px-black">
                                <div className="adp roboto-bold-carnation-10px">
                                    
                                </div>
                            </div>
                        </div>
                        <div className="field-container">
                            <div className="field border-1px-black">
                                <div className="folio roboto-bold-black-10px" >
                                    Folio:
                                </div>
                            </div>
                            <div className="field-1 border-1px-black">
                                <div className="number roboto-bold-carnation-10px">
                                    
                                </div>
                            </ div>
                        </div>
                        <div className="field-container">
                            <div className="field border-1px-black">
                                <div className="fecha roboto-bold-black-10px" >
                                    Fecha:
                                </div>
                            </div>
                            <div className="field-1 border-1px-black">
                                <div className="date roboto-normal-black-10px">
                                    {currentPayDay}
                                </div>
                            </div>
                        </div>
                        <div className="field-container">
                            <div className="field border-1px-black">
                                <div className="hora roboto-bold-black-10px">
                                    Hora:
                                </ div>
                            </div>
                            <div className="field-1 border-1px-black">
                                <div className="text-3 roboto-normal-black-10px">
                                    : :
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col-2">
                    <div className="flex-row-3">
                        <p className="rgimen-fiscal-603 roboto-bold-black-10px">
                            <span className="roboto-bold-black-10px">Régimen Fiscal:</span>
                            <span className="roboto-normal-black-10px"> 603 Personas Morales con Fines no Lucrativos</span>
                        </p>
                        <div className="field-5 border-1px-black" >
                            <div className="moneda roboto-bold-black-10px">
                                Moneda:
                            </div>
                        </div>
                        <div className="field-1 border-1px-black" style={{"marginRight":"-3px"}} >
                            <div className="mxn roboto-normal-black-10px">
                                MXN
                            </div>
                        </div>
                    </div>
                    <div className="field-6">
                        <div className= "receptor roboto-bold-alabaster-10px">
                            Receptor
                        </div>
                    </div>
                    <div className="frame-16 border-1px-black">
                        <div className= "column">
                            <div className="socio-15586 roboto-bold-black-10px">
                                <span className="roboto-bold-black-10px">Socio:</span>
                                <span className="roboto-normal-black-10px"> {context.user.company.client_number}</span>
                            </div>
                            <div>
                                <p className= "celestica-de-monterrey-sa-de-cv roboto-normal-black-10px">
                                {context.user.company.business_name}
                                </p>
                            </div>
                            <div className="rfc-cm0980416-b80 roboto-bold-black-10px" >
                                <span className="roboto-bold-black-10px">RFC:</span>
                                <span className="roboto-normal-black-10px"> {context.user.company.rfc}</span>
                            </div>
                        </div>
                        <div className="column-1">
                            <div className="ejecutivo-ninguno roboto-bold-black-10px">
                                <span className="roboto-bold-black-10px">Ejecutivo</span>
                                <span className="roboto-normal-black-10px">: ({agentName})</span>
                            </div>
                            <div>
                                <p className="efectuar-el-pago-uti roboto-bold-black-8px">
                                    Efectuar el PAGO utilizando los siguientes datos:
                                </p>
                            </div>
                            <div>
                                <p className="convenio-cie-bbva00 roboto-bold-black-8px">
                                    Convenio CIE BBVA: 001762087 CLABE INTERBANCARIA:012914002017620872
                                </p>
                            </div>
                            <div>
                                <p className="la-referencia-es-un roboto-normal-black-8px">
                                    <React.Fragment>
                                        *El monto a pagar debe ser igual al de esta factura, en caso contrario se rechazará.
                                    </React.Fragment>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{"height":INSURANCE, "marginBottom":BOTTOM}} className="overlap-group3">
                        <div className="row-1">
                            <div className="column-container roboto-bold-black-8px">
                                <Column>{column1Data.children}</Column>
                                <div className="column-2 border-1px-silver">
                                    <div className="clave-unidad-sat">
                                        Clave Unidad SAT
                                    </div>
                                </ div>
                                <div className="column-3 border-1px-silver">
                                    <div className="clave-prod-servicio">
                                        Clave Prod/Servicio
                                    </div>
                                </div>
                                <div className="column-4 border-1px-silver">
                                    <div className="descripcin">
                                        Descripción
                                    </div>
                                </div>
                                <div className="column-5 border-1px-silver">
                                    <div className="valor-unitario">
                                        Valor unitario
                                    </div>
                                </div>
                                <Column className={column2Data.className}>
                                    {column2Data.children}
                                </Column>
                                <Column className={column3Data.className}>
                                    {column3Data.children}
                                </Column>
                                <Column className={column4Data.className}>
                                    {column4Data.children}
                                </Column>
                            </div>
                        </div>
                        {!context.user.company?.business_name.toUpperCase().includes("DUAL") &&
                        <div className="column-container-1">
                            <div className="column-6 border-1px-silver">
                                <div className="text-5 roboto-normal-black-7px">
                                    1.00
                                </div>
                            </div>
                            <div className="column-7 border-1px-silver">
                                <div className="pce-pce roboto-normal-black-7px">
                                    E48 - Unidad de servicio
                                </div>
                            </div>
                            <div className="column-8 border-1px-silver">
                                <div className="x94131500-organizac roboto-normal-black-7px">
                                    <React.Fragment>
                                        94131500 - <br />
                                        Organizaciones no
                                        <br />
                                        guberamentales
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className="column-9 border-1px-silver">
                                <div className="adp-administracion-de-becas roboto-normal-black-7px">
                                    <div>ADP ADMINISTRACION DE BECAS</div>
                                    <div>{DATE}</div>
                                    <div>{context.user.company.social_reason ? context.user.company.social_reason : "N/a"}</div>
                                    {prefactureData.purchaseOrder && <div> OC {prefactureData.purchaseOrder} </div>}
                                </div>
                            </div>
                            <div className="column-10 border-1px-silver">
                                <div className="price roboto-normal-black-7px">
                                    ${prefactureData.subtotalNoInsurance.toFixed(2)}
                                </ div>
                            </div>
                            <Column2 insurance={false}>${prefactureData.creditCompany}</Column2>
                            <Column2 className={column23Data.className} insurance={false}>
                            IVA. - ${(((prefactureData.subtotalNoInsurance  - prefactureData.creditCompany) *.16 )).toFixed(2)}
                            </Column2>
                            <Column2 className={column24Data.className} insurance={false}>
                                ${(prefactureData.subtotalNoInsurance  - prefactureData.creditCompany).toFixed(2)}
                            </Column2>
                        </div>
                        }
                        {/* MODELO DUAL */}
                        {context.user.company?.business_name.toUpperCase().includes("DUAL") &&
                        <div className="column-container-1">
                            <div className="column-6 border-1px-silver">
                                <div className="text-5 roboto-normal-black-7px">
                                    1.00
                                </div>
                            </div>
                            <div className="column-7 border-1px-silver">
                                <div className="pce-pce roboto-normal-black-7px">
                                    E48 - Unidad de servicio
                                </div>
                            </div>
                            <div className="column-8 border-1px-silver">
                                <div className="x94131500-organizac roboto-normal-black-7px">
                                    <React.Fragment>
                                        94131500 - <br />
                                        Organizaciones no
                                        <br />
                                        guberamentales
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className="column-9 border-1px-silver">
                                <div className="adp-administracion-de-becas roboto-normal-black-7px">
                                    <div>ADP ADMINISTRACION DE BECAS</div>
                                    <div>{DATE}</div>
                                    <div>{context.user.company.social_reason ? context.user.company.social_reason : "N/a"}</div>
                                    {prefactureData.purchaseOrder && <div> OC {prefactureData.purchaseOrder} </div>}
                                </div>
                            </div>
                            <div className="column-10 border-1px-silver">
                                <div className="price roboto-normal-black-7px">
                                    ${ (prefactureData.totalCost + (prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense /100)) : prefactureData.totalCost * 0.10)).toFixed(2)}
                                </ div>
                            </div>
                            <Column2 insurance={false}>${(prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense /100)) : prefactureData.totalCost * 0.10)}</Column2>
                            <Column2 className={column23Data.className} insurance={false}>
                            IVA. - ${ (prefactureData.totalCost  * 0.16).toFixed(2)}
                            </Column2>
                            <Column2 className={column24Data.className} insurance={false}>
                                ${ prefactureData.totalCost }
                            </Column2>
                        </div>
                        }
                        {/* FIN MODELO DUAL */}

                        {/* MODELO DUAL */}
                        {context.user.company?.business_name?.toUpperCase().includes("DUAL") && 
                        <div className="column-container-2">
                            <div className="column-6 border-1px-silver" style={{height: '40px'}}>
                                <div className="text-5 roboto-normal-black-7px">
                                    1.00
                                </div>
                            </div>
                            <div className="column-7 border-1px-silver" style={{height: '40px'}}>
                                <div className="pce-pce roboto-normal-black-7px">
                                    E48 - Unidad de servicio
                                </div>
                            </div>
                            <div className="column-8 border-1px-silver" style={{height: '40px'}}>
                                <div className="x94131500-organizac roboto-normal-black-7px">
                                    <React.Fragment>
                                        94101500 - <br />
                                        Asociaciones de
                                        <br />
                                        negocios
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className="column-9 border-1px-silver" style={{height: '40px'}}>
                                <div className="adp-administracion-de-becas roboto-normal-black-7px">
                                    <div>ADMINISTRACIÓN DEL MODELO DUAL</div>
                                </div>
                            </div>
                            <div className="column-10 border-1px-silver" style={{height: '40px'}}>
                                <div className="price roboto-normal-black-7px">
                                    ${ totalDualGlobal }
                                </ div>
                            </div>
                            <Column2 insurance={true}>$0.00</Column2>
                            <Column2 className={column23Data.className} insurance={true}>
                            IVA. - ${( (totalDualGlobal) *.16).toFixed(2)}
                            </Column2>
                            <Column2 className={column24Data.className} insurance={true}>
                                ${(totalDualGlobal).toFixed(2)}
                            </Column2>
                        </div>
                        }
                        { context.user.company.business_name?.toUpperCase().includes("DUAL") && prefactureData.insuranceCompanyData!=0 &&
                        <div className="column-container-3">
                            <div className="column-6 border-1px-silver" style={{height: '40px'}}>
                                <div className="text-5 roboto-normal-black-7px">
                                    1.00
                                </div>
                            </div>
                            <div className="column-7 border-1px-silver" style={{height: '40px'}}>
                                <div className="pce-pce roboto-normal-black-7px">
                                    E48 - Unidad de servicio
                                </div>
                            </div>
                            <div className="column-8 border-1px-silver" style={{height: '40px'}}>
                                <div className="x94131500-organizac roboto-normal-black-7px">
                                    <React.Fragment>
                                        94101500 - <br />
                                        Asociaciones de
                                        <br />
                                        negocios
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className="column-9 border-1px-silver" style={{height: '40px'}}>
                                <div className="adp-administracion-de-becas roboto-normal-black-7px">
                                    <div>ADP - CUOTA SEGUROS AIG</div>
                                </div>
                            </div>
                            <div className="column-10 border-1px-silver" style={{height: '40px'}}>
                                <div className="price roboto-normal-black-7px">
                                    ${prefactureData.insuranceCompanyData }
                                </ div>
                            </div>
                            <Column2 insurance={true}>${(prefactureData.insuranceCompanyData - 0.01).toFixed(2)}</Column2>
                            <Column2 className={column23Data.className} insurance={true}>
                            IVA. - $0.00
                            </Column2>
                            <Column2 className={column24Data.className} insurance={true}>
                                $ {prefactureData.insuranceCompanyData - prefactureData.insuranceCompanyData + 0.01}  
                            </Column2>
                        </div>
                        }
                        {/* FIN MODELO DUAL */}
                        {context.user.company.interns_medical_insurance == "Sí" && !context.user.company.business_name?.toUpperCase().includes("DUAL") && 
                        <div className="column-container-2">
                            <div className="column-6 border-1px-silver" style={{height: '40px'}}>
                                <div className="text-5 roboto-normal-black-7px">
                                    1.00
                                </div>
                            </div>
                            <div className="column-7 border-1px-silver" style={{height: '40px'}}>
                                <div className="pce-pce roboto-normal-black-7px">
                                    E48 - Unidad de servicio
                                </div>
                            </div>
                            <div className="column-8 border-1px-silver" style={{height: '40px'}}>
                                <div className="x94131500-organizac roboto-normal-black-7px">
                                    <React.Fragment>
                                        94101500 - <br />
                                        Asociaciones de
                                        <br />
                                        negocios
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className="column-9 border-1px-silver" style={{height: '40px'}}>
                                <div className="adp-administracion-de-becas roboto-normal-black-7px">
                                    <div>ADP - CUOTA SEGUROS AIG</div>
                                </div>
                            </div>
                            <div className="column-10 border-1px-silver" style={{height: '40px'}}>
                                <div className="price roboto-normal-black-7px">
                                    ${prefactureData.insuranceCompanyData.toFixed(2)}
                                </ div>
                            </div>
                            <Column2 insurance={true}>$0.00</Column2>
                            <Column2 className={column23Data.className} insurance={true}>
                            IVA. - ${(prefactureData.insuranceCompanyData*.16).toFixed(2)}
                            </Column2>
                            <Column2 className={column24Data.className} insurance={true}>
                                ${(prefactureData.insuranceCompanyData).toFixed(2)}
                            </Column2>
                        </div>
                        }
                    </div>

                    {/* MODELO DUAl */}
                    {context.user.company?.business_name.toUpperCase().includes("DUAL") &&
                    <><div style={{ "height": PAYMENT }} className="overlap-group6">
                                <div className="overlap-group5_">
                                    <img className="layer-2_" alt="" src={layerPreview} />
                                    <div className="tipo-de-relacin_ roboto-bold-black-10px">
                                        Tipo de relación:
                                    </div>
                                    <div className="field-7_">
                                        <div className="factura-2 roboto-bold-alabaster-10px">
                                            Factura
                                        </div>
                                    </div>
                                    <div className="field-8_ border-1px-black">
                                        <p className="tres-mil-cuatrocient roboto-medium-mountain-mist-10px">
                                            {`${NumberAsString( ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) + ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) * 0.16)).toFixed(2))} ${
                                                (
                                                    (Math.round(( ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) + ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) * 0.16))) * 100)) - 
                                                    
                                                    
                                                    Math.floor( (parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) + ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) * 0.16)) * 100)}/100 M.N.`}
                                        </p>
                                    </div>
                                    <Field className={field1Data.className}>{field1Data.children}</Field>
                                    <div className="phone_ roboto-medium-mountain-mist-10px">
                                        {(prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData).toFixed(2) 
                                         }   
                                    </div>
                                    <Field className={field2Data_.className}>
                                        {field2Data_.children}
                                    </Field>
                                    <div className="text-2_ roboto-medium-mountain-mist-10px">
                                        {(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01).toFixed(2)}
                                    </div>
                                    <Field className={field3Data_.className}>
                                        {field3Data_.children}
                                    </Field>
                                    <div className="phone-1_ roboto-medium-mountain-mist-10px">
                                    { (parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)).toFixed(2)}  
                                    </div>
                                    <Field className={field4Data_.className}>
                                        {field4Data_.children}
                                    </Field>
                                    <div className="text-4_ roboto-medium-mountain-mist-10px">
                                        {((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) * 0.16).toFixed(2)}
                                    </div>
                                    <Field className={field5Data_.className}>
                                        {field5Data_.children}
                                    </Field>
                                    <div className="field-9_ border-1px-black">
                                        <div className="price-1_ roboto-bold-black-10px">
                                            {   ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) + ((parseFloat((prefactureData.totalCost+ (prefactureData.totalCost* prefactureData.administrativeExpense!== 0 ? prefactureData.totalCost *(prefactureData.administrativeExpense/100) : prefactureData.totalCost *(10/100))  + totalDualGlobal+ prefactureData.insuranceCompanyData)).toFixed(2)  -(parseFloat((prefactureData.administrativeExpense !== 0 ?  (prefactureData.totalCost * (prefactureData.administrativeExpense)/100).toFixed(2) : ((prefactureData.subtotalNoInsurance *0.10)) )) + (parseFloat(prefactureData.insuranceCompanyData))  -0.01)) * 0.16)).toFixed(2)
                                            }
                                        </div>
                                    </div>
                                    <div className="mtodo-de-pago_ roboto-bold-black-10px">
                                        Método de Pago:
                                    </div>
                                    <div className="forma-de-pago_ roboto-bold-black-10px">
                                        Forma de Pago:
                                    </div>
                                    <div className="uso-cfdi_ roboto-bold-black-10px">
                                        Uso CFDI:
                                    </div>
                                    <div className="serie-del-certificado-del-emisor_ roboto-bold-black-10px">
                                        Serie del Certificado del emisor:
                                    </div>
                                    <div className="ppd-pago-en-parcialidades-o-diferido_ roboto-normal-black-10px">
                                        {prefactureData.facturePaymentMethod ? returnOptionKey(prefactureData.facturePaymentMethod, options) : returnOptionKey(context.user.company.facturePaymentMethod, options)}
                                    </div>
                                    <div className="×99-por-definir_ roboto-normal-black-10px">
                                        {prefactureData.facturePaymentType ? returnOptionKey(prefactureData.facturePaymentType, options) : returnOptionKey(context.user.company.facturePaymentType, options)}
                                    </div>
                                    <div className="p01-por-definir_ roboto-normal-black-10px">
                                        {prefactureData.factureCFDI ? returnOptionKey(prefactureData.factureCFDI, options) : returnOptionKey(context.user.company.factureCFDI, options)}
                                    </div>
                                    <div className="no-se-puede-agregar-firma-digital-a-un_ roboto-normal-black-10px">
                                        No se puede agregar firma digital a un
                                    </div>
                                </div>
                                <div className="text-1_ roboto-normal-black-10px">
                                    00.00
                                </div>
                            </div><div className="flex-row-4">
                                    <div className="folio-fiscal_ roboto-bold-black-10px">
                                        Folio Fiscal:
                                    </div>
                                    <div className="text-6_ roboto-normal-black-10px">
                                        -
                                    </div>
                                </div><div className="flex-row-5">
                                    <div className="no-de-serio-del-certificado-del-sat_ roboto-bold-black-10px">
                                        No. de serie del Certificado del SAT:
                                    </div>
                                    <div className="no-se-puede-agregar-firma-digital-a-un-1_ roboto-normal-black-10px">
                                        No se puede agregar firma digital a un
                                    </div>
                                </div><div className="flex-row-6">
                                    <div className="fecha-y-hora-de-certificacin_ roboto-bold-black-10px">
                                        Fecha y hora de certificación:
                                    </div>
                                    <div className="text-7_ roboto-normal-black-10px">
                                        -
                                    </div>
                                </div><div className="este-documento-es-u roboto-bold-black-10px">
                                    "Este documento es una representación impresa de un CFDI"
                                </div><div className="este-comprobante-no roboto-normal-black-8px">
                                    "Este comprobante no es válido si no ha sido pagado a favor de CAMARA DE LA INDUSTRIA DE TRANSFORMACION DE NUEVO LEON"
                                </div><div className="field-container-1">
                                    <div className="field-2 border-1px-black">
                                        <div className="sello-digital-del-cfdi roboto-bold-bunker-10px">
                                            Sello digital del CFDI
                                        </div>
                                    </div>
                                    <div className="field-3 border-1px-black">
                                        <div className="no-se-puede-agregar roboto-normal-black-8px">
                                            No se puede agregar firma digital a un documento inválido para efectos fiscales
                                        </div>
                                    </div>
                                </div><div className="field-container-2">
                                    <div className="field-2 border-1px-black">
                                        <div className="sello-del-sat roboto-bold-bunker-10px">
                                            Sello del SAT
                                        </div>
                                    </div>
                                    <div className="field-3 border-1px-black">
                                        <div className="no-se-puede-agregar roboto-normal-black-8px">
                                            No se puede agregar firma digital a un documento inválido para efectos fiscales
                                        </div>
                                    </div>
                                </div><div className="field-container-3">
                                    <div className="field-2 border-1px-black">
                                        <div className="cadena-original-del roboto-bold-bunker-10px">
                                            Cadena original del complemento del certificación digital del SAT
                                        </div>
                                    </div>
                                    <div className="field-10 border-1px-black">
                                        <div className="no-se-puede-agregar-1 roboto-normal-black-8px">
                                            <React.Fragment>
                                                No se puede agregar firma digital a un documento inválido para efectos fiscales
                                                <br />
                                                <br />
                                                <br />
                                                Version del comprobante:
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div></>
                    }
                    {/* FIN MODELO DUAL */}

                    {!context.user.company?.business_name.toUpperCase().includes("DUAL") &&
                    <><div style={{ "height": PAYMENT }} className="overlap-group6">
                                <div className="overlap-group5">
                                    <img className="layer-2" alt="" src={layerPreview} />
                                    <div className="tipo-de-relacin roboto-bold-black-10px">
                                        Tipo de relación:
                                    </div>
                                    <div className="field-7">
                                        <div className="factura-2 roboto-bold-alabaster-10px">
                                            Factura
                                        </div>
                                    </div>
                                    <div className="field-8 border-1px-black">
                                        <p className="tres-mil-cuatrocient roboto-medium-mountain-mist-10px">
                                            {STRINGTOTAL}
                                        </p>
                                    </div>
                                    <Field>{field1Data.children}</Field>
                                    <div className="phone roboto-medium-mountain-mist-10px">
                                        {(prefactureData.insuranceCompanyData + prefactureData.subtotalNoInsurance).toFixed(2)}
                                    </div>
                                    <Field className={field2Data.className}>
                                        {field2Data.children}
                                    </Field>
                                    <div className="text-2 roboto-medium-mountain-mist-10px">
                                        {prefactureData.creditCompany.toFixed(2)}
                                    </div>
                                    <Field className={field3Data.className}>
                                        {field3Data.children}
                                    </Field>
                                    <div className="phone-1 roboto-medium-mountain-mist-10px">
                                        {prefactureData.subtotalCredits.toFixed(2)}
                                    </div>
                                    <Field className={field4Data.className}>
                                        {field4Data.children}
                                    </Field>
                                    <div className="text-4 roboto-medium-mountain-mist-10px">
                                        {prefactureData.iva.toFixed(2)}
                                    </div>
                                    <Field className={field5Data.className}>
                                        {field5Data.children}
                                    </Field>
                                    <div className="field-9 border-1px-black">
                                        <div className="price-1 roboto-bold-black-10px">
                                            {prefactureData._total.toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="mtodo-de-pago roboto-bold-black-10px">
                                        Método de Pago:
                                    </div>
                                    <div className="forma-de-pago roboto-bold-black-10px">
                                        Forma de Pago:
                                    </div>
                                    <div className="uso-cfdi roboto-bold-black-10px">
                                        Uso CFDI:
                                    </div>
                                    <div className="serie-del-certificado-del-emisor roboto-bold-black-10px">
                                        Serie del Certificado del emisor:
                                    </div>
                                    <div className="ppd-pago-en-parcialidades-o-diferido roboto-normal-black-10px">
                                        {prefactureData.facturePaymentMethod ? returnOptionKey(prefactureData.facturePaymentMethod, options) : returnOptionKey(context.user.company.facturePaymentMethod, options)}
                                    </div>
                                    <div className="×99-por-definir roboto-normal-black-10px">
                                        {prefactureData.facturePaymentType ? returnOptionKey(prefactureData.facturePaymentType, options) : returnOptionKey(context.user.company.facturePaymentType, options)}
                                    </div>
                                    <div className="p01-por-definir roboto-normal-black-10px">
                                        {prefactureData.factureCFDI ? returnOptionKey(prefactureData.factureCFDI, options) : returnOptionKey(context.user.company.factureCFDI, options)}
                                    </div>
                                    <div className="no-se-puede-agregar-firma-digital-a-un roboto-normal-black-10px">
                                        No se puede agregar firma digital a un
                                    </div>
                                </div>
                                <div className="text-1 roboto-normal-black-10px">
                                    00.00
                                </div>
                            </div><div className="flex-row-4">
                                    <div className="folio-fiscal roboto-bold-black-10px">
                                        Folio Fiscal:
                                    </div>
                                    <div className="text-6 roboto-normal-black-10px">
                                        -
                                    </div>
                                </div><div className="flex-row-5">
                                    <div className="no-de-serio-del-certificado-del-sat roboto-bold-black-10px">
                                        No. de serio del Certificado del SAT:
                                    </div>
                                    <div className="no-se-puede-agregar-firma-digital-a-un-1 roboto-normal-black-10px">
                                        No se puede agregar firma digital a un
                                    </div>
                                </div><div className="flex-row-6">
                                    <div className="fecha-y-hora-de-certificacin roboto-bold-black-10px">
                                        Fecha y hora de certificación:
                                    </div>
                                    <div className="text-7 roboto-normal-black-10px">
                                        -
                                    </div>
                                </div><div className="este-documento-es-u roboto-bold-black-10px">
                                    "Este documento es una representación impresa de un CFDI"
                                </div><div className="este-comprobante-no roboto-normal-black-8px">
                                    "Este comprobante no es válido si no ha sido pagado a favor de CAMARA DE LA INDUSTRIA DE TRANSFORMACION DE NUEVO LEON"
                                </div><div className="field-container-1">
                                    <div className="field-2 border-1px-black">
                                        <div className="sello-digital-del-cfdi roboto-bold-bunker-10px">
                                            Sello digital del CFDI
                                        </div>
                                    </div>
                                    <div className="field-3 border-1px-black">
                                        <div className="no-se-puede-agregar roboto-normal-black-8px">
                                            No se puede agregar firma digital a un documento inválido para efectos fiscales
                                        </div>
                                    </div>
                                </div><div className="field-container-2">
                                    <div className="field-2 border-1px-black">
                                        <div className="sello-del-sat roboto-bold-bunker-10px">
                                            Sello del SAT
                                        </div>
                                    </div>
                                    <div className="field-3 border-1px-black">
                                        <div className="no-se-puede-agregar roboto-normal-black-8px">
                                            No se puede agregar firma digital a un documento inválido para efectos fiscales
                                        </div>
                                    </div>
                                </div><div className="field-container-3">
                                    <div className="field-2 border-1px-black">
                                        <div className="cadena-original-del roboto-bold-bunker-10px">
                                            Cadena original del complemento del certificación digital del SAT
                                        </div>
                                    </div>
                                    <div className="field-10 border-1px-black">
                                        <div className="no-se-puede-agregar-1 roboto-normal-black-8px">
                                            <React.Fragment>
                                                No se puede agregar firma digital a un documento inválido para efectos fiscales
                                                <br />
                                                <br />
                                                <br />
                                                Version del comprobante:
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div></>
                    }
                </div>
                {verifyPreviewMD && 
                <div className="verify-preview">
                    <Button variant="primary" onClick={() => {handleVerify()}}>
                        Validado
                    </Button>
                </div>}
            </div>
        </div>
        <div className="zoom-div">
            <FiZoomOut className="zoom" onClick={() => zoomOut()}/>
            <FiZoomIn className="zoom" onClick={() => zoomIn()}/>
        </div>
        </>
    );
}

function Column (props){
    const { children, className} = props;
    return (
        <div className={`column-11 border-1px-silver ${className || ""}`}>
            <div className="cantidad">
                {children}
            </div>
        </div>
    );
}

function Column2 (props) {
    const { children, className, insurance} = props;
    return(
        <div className={`column-15 border-1px-silver ${className || ""}`} style={insurance ? {height: '40px'} : {}}>
            <div className="price-2 roboto-normal-black-7px">
                {children}
            </div>
        </div>
    );
}

function Field (props) {
    const { children, className} = props;
    return(
        <div className={`field-11 ${className || ""}`}>
            <div className="subtotal roboto-normal-white-10px">
                {children}
            </div>
        </div>
    );
}

const column1Data = {
    children: "Cantidad"
};

const column2Data = {
    children: "Descuento",
    className: "column-12"
};

const column3Data = {
    children:"Impuestos",
    className: "column-13"
};

const column4Data = {
    children: "Importe",
    className: "column-14"
};

const column22Data = {
    children: "$0.00"
};

const column23Data = {
    children: "IVA. - 480.",
    className: "column-16"
};

const column24Data ={
    children: "$3.000.00",
    className: "column-17"
};

const field1Data = {
    children: "Subtotal:",
    className: "field-11_"
};

const field2Data = {
    children: "Descuentos:",
    className: "field-12"
};

const field2Data_ = {
    children: "Descuentos:",
    className: "field-12_"
};

const field3Data = {
    children: "NETO:",
    className: "field-13",
};

const field3Data_ = {
    children: "NETO:",
    className: "field-13_",
};

const field4Data = {
    children: "IVA:",
    className: "field-14",
};


const field4Data_ = {
    children: "IVA:",
    className: "field-14_",
};


const field5Data = {
    children: "Total:",
    className: "field-15",
};

const field5Data_ = {
    children: "Total:",
    className: "field-15_",
};
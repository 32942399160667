import React, {useState} from 'react';
import {Modal, Button, Form, Image, Spinner} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';

export default function ApproveUserModal({currentUser, openApproveUserModal, setOpenApproveUserModal, updateList, setUpdateList}){
    const [isLoading, setIsLoading] = useState(false);

    const approveUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const confirmation = e.target.elements.confirmation.value;

        if(confirmation.trim().toLowerCase() != "confirmar"){
            notify("La palabra de confirmación es incorrecta, por favor intenta nuevamente", "error");
            return;
        };

        const data = {
            _id: currentUser._id,
            attributes: [
                {updateKey: "isApproved", updateValue: true}
            ]
        };
        const emailParams = {
            to: currentUser.email,
            subject: `PRACTICANTES CAINTRA - Usuario Aprobado`,
            text: `¡Felicidades! ¡Ya puedes ingresar a la plataforma!`,
            userName: currentUser.fullName
        };

        await Methods.updateUser(data);
        await Methods.sendEmail(emailParams);
        setIsLoading(false);
        setUpdateList(!updateList);
        setOpenApproveUserModal(false);
    };

    return(
        <Modal className="prefacture-modals" show={openApproveUserModal} onHide={() => setOpenApproveUserModal(!openApproveUserModal)} centered>

            <Modal.Body>
                <Form onSubmit={approveUser} autoComplete={"off"}>
                    <p>¡Estás a punto de aprobar a {currentUser.fullName}!</p>

                    <Form.Group style={{marginTop: "20px"}} controlId="confirmation">
                        <Form.Control type="text" placeholder='Escribe "confirmar" aquí' required/>
                    </Form.Group>

                    <Button className="float-right ml-2" variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Confirmar"}
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => setOpenApproveUserModal(!openApproveUserModal)}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    )
}
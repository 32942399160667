import React, {useEffect, useState, useContext} from 'react';
import Methods from '../../../utils/https';
import {Card, Col, Row, Tooltip, OverlayTrigger, Table} from 'react-bootstrap';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faUser, faMailBulk, faPlusCircle, faIdCard, faUserTag, faCheckCircle, faTimesCircle, faTrashAlt, faPen, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import DeleteUserModal from './DeleteUserModal.jsx';
import ApproveUserModal from './ApproveUser.jsx';
import DenyUserModal from './DenyUser.jsx';
import EditAdvisorModal from './EditAdvisorModal.jsx';
import {UserContext} from '../../../context/UserContext';

const axios = require("axios");

export default function Advisors({currentCompanyId, setTab,companies}){
    const [users, setUsers] = useState([]);
    const [updateList, setUpdateList] = useState(false);
    const [rotateSync, setRotateSync] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [openEditUserModal, setOpenEditUserModal] = useState(false);


    
    const rotate = rotateSync ? "rotate" : "";
    const context = useContext(UserContext);
    const userId = context.user.data._id;

    useEffect(() => {
        getUsersByCompany();
    }, [updateList]);

    const getUsersByCompany = async () => {
        setRotateSync(true);
        const data = await Methods.getCaintraUsers(currentCompanyId);
        for(let user of data){
            if(!user.advisedCompanies || user.advisedCompanies == undefined){
                user.advisedCompanies = [];
            }
        }
        data && setUsers(data);
        await verifyUsers(data);
        setRotateSync(false);
    };

    const verifyUsers = async (data) => {
        let approved = 0;
        let notApproved = 0;
        let leader = 0;
        await data.forEach(element => {
            if(element.isApproved){
                approved += 1;
                if(element.departmentId){
                    leader += 1;
                }
            }else{
                notApproved += 1;
            }
        });
    }

    const handleEditUser = (user) => {
        setCurrentUser(user);
        setOpenEditUserModal(true);
    };

    return(
        <motion.div className="users" initial={{x: 30, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ duration: 0.25 }}>
           <Row className="title">
                <a href="#" onClick={() => {setTab("listCompanies"); setUpdateList(!updateList)}}><FontAwesomeIcon icon={faArrowLeft} /></a> 

                <h4>Gestión de asesores</h4>
                
                <div className="users-options">
                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Recargar lista</Tooltip>}
                    >
                        <a href="#" onClick={() => {setUpdateList(!updateList);}}><FontAwesomeIcon className={rotate} icon={faSyncAlt}/></a>
                    </OverlayTrigger>
                </div>
            </Row>
            <motion.div initial={{opacity: 0, y: -40}} animate={{opacity: 1, y: 0}} className="table-width">
                <Table triped bordered responsive hover className="mt-4 h-50" style={{marginBottom: "100px"}}>
                <thead>
                    <tr>
                        <th>Asesor</th>
                        <th>Correo</th>
                        <th>Empresas</th>
                        <th>Editar</th>
                    </tr>
                </thead>
                <tbody style={{height: "50%", overflow:"scroll"}} >
                        {users.map((user, index) => {
                            if(user.isApproved && !user.departmentId){
                                return(
                                    <tr>
                                        <td>
                                            <div className="center-container">
                                                {user.fullName}
                                            </div>  
                                        </td>
                                        <td>
                                            <div className="center-container">
                                                {user.email}
                                            </div>  
                                        </td>
                                        <td>
                                            <div className="center-container">
                                                Asesor de { user.advisedCompanies? user.advisedCompanies.length: 0} empresas
                                            </div>  
                                        </td>
                                        <td>
                                            <div className="center-container options">
                                                <a href="#" onClick={() => handleEditUser(user)}>
                                                    <FontAwesomeIcon icon={faPen}/>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }
                        }) }
                </tbody>
                </Table>
            </motion.div>

            <EditAdvisorModal
                openEditUserModal={openEditUserModal}
                setOpenEditUserModal={setOpenEditUserModal}
                currentAdvisor={currentUser}
                updateList={updateList}
                setUpdateList={setUpdateList}
                companies={companies}
                advisors={users}
            />

        </motion.div>
    );
};

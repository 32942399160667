import moment from 'moment';
export default {

    test(workbook, data, companyReason) {
        workbook.creator = "CAINTRA"
        workbook.lastModifiedBy = "CAINTRA"
        workbook.created = new Date()


        const cols = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N"];
        let config = [ //cols & config, same length
            {  width: 20,  value: "date",       text: "Fecha"  },
            {  width: 30,  value: "company",       text: "Empresa"  },
            {  width: 40,  value: "email",      text: "Correo"  },
            {  width: 20,  value: "period",       text: "Periodo"  },
            {  width: 20,  value: "invoice",      text: "Factura"  },
            {  width: 50,  value: "description",    text: "Error"  },
        ];


        function filterIncludes(configItem){
            return true;
        }
        config = config.filter((configItem) => filterIncludes(configItem))


        config.forEach((configItem, index) => {
            configItem.col = cols[index];
        });


        const worksheetPicoUsoInfra = workbook.addWorksheet("Reportes", {
            properties: { tabColor: { argb: "FFB1CEF2" }, },
            views: [{ showGridLines: false }]
        })


        let width = []
        config.forEach(item => {
            width.push({ width: item.width })
        });
        worksheetPicoUsoInfra.columns = width;


        function createTableHeader(startCell, EndCell, text, worksheetName){
            worksheetName.mergeCells(startCell + ":" + EndCell)
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF4792ED'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: true, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }


        function createBlueCell(startCell, text, worksheetName){
            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFB1CEF2'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'left' }
            worksheetName.getCell(startCell).value = {
                richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }


        function createWhiteCell(startCell, text, worksheetName){
            let textAux = (text || "").toString();
            
            if(textAux != ""){
                text = textAux.replace("undefined", "");
            } else{
                text = textAux;
            }
            

            worksheetName.getCell(startCell).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            worksheetName.getCell(startCell).alignment = { vertical: 'middle', horizontal: 'left' }
            worksheetName.getCell(startCell).value = {
              richText: [
                    {font: {bold: false, size: 12, color: {argb:'FF000000'}, name: 'Calibri', family: 2, scheme: 'minor'}, text: text}
                ]
            }
            worksheetName.getCell(startCell).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }

        function createHeaders(worksheetName){
            createBlueCell(
                "A1",
                "Generado por:",
                worksheetName
            )
            worksheetName.mergeCells("B1" + ":" + "C1")

            createWhiteCell(
                "B1",
                companyReason  || "",
                worksheetName
            )

            createBlueCell(
                "D1",
                "Fecha de creación: ",
                worksheetName
            )
            worksheetName.mergeCells("E1" + ":" + "F1")

            createWhiteCell(
                "E1",
                moment().format('DD/MM/YYYY'),
                worksheetName
            )
        }



        function createTableAvail(data, worksheetName){
            let cellNumber = 3;

            createHeaders(worksheetName)

            createTableHeader(
                config.at(0).col + cellNumber,
                config.at(-1).col + cellNumber,
                "Reportes",
                worksheetName
            )
            cellNumber++

            config.forEach((configItem) => {
                createBlueCell(
                    configItem.col + cellNumber,
                    configItem.text,
                    worksheetName
                )
            });

            data.forEach(row => {
                cellNumber++
                config.forEach(configItem => {
                    createWhiteCell(
                        configItem.col + cellNumber,
                        row[configItem.value],
                        worksheetName
                    )
                });
            });
        }


        createTableAvail(data, worksheetPicoUsoInfra)
        return workbook
    }
}

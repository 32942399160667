import React, {useEffect, useState, useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import {UserContext} from '../../../context/UserContext';
import Methods from '../../../utils/https';
import moment from 'moment';
import 'moment/locale/es-mx';

export default function Records ({showRecords, setShowRecords}){
    const context = useContext(UserContext);
    const companyId = context.user.company._id;
    const [records, setRecords] = useState(undefined);

    useEffect(() => {
        showRecords && getRecords();
    }, [showRecords]);

    const getRecords = async () => {
        const data = await Methods.getRecords(companyId);
        data && setRecords(data);
    };

    return(
        <Modal className="records" show={showRecords} centered onHide={() => setShowRecords(!showRecords)}>
            <Modal.Body>
                <div className="records-title">
                    <FontAwesomeIcon icon={faHistory} /> <p>Historial</p>
                </div>
                <div className="records-list">
                    {
                        records && records.map((record, index) => {
                            return(
                                <div className="record" key={index}>
                                    <p className="message">{record.message}</p>
                                    <p className="author">Realizado por {record._role} {record.author}</p>
                                    <p className="date">{moment(record.createdAt).locale('es-mx').format('LLLL')}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};
import excelByItems from '../../../utils/excelByItems';
import * as Excel from "exceljs";
import { getFormatedAmount } from '../../Amounts/helpers/getFormatedAmount';
import { getDisabledCompanies } from './getDisabledCompanies';
import moment from 'moment';
import { notify } from '../../../utils/notify';
export const createExcel = async({ 
    createdBy,
    search, 
    onlyDisabledCompanies,
    onlyEnabledCompanies,
    startDay,
    endDay
}) => {

    try{

        let { allCompanies } = await getDisabledCompanies({
            search, 
            pageLimit: 400,
            onlyDisabledCompanies,
            onlyEnabledCompanies,
            companiesLimit: 100000
        });

        const excelKeys = [
            {key: 'business_name', label: 'Empresa', customColor: 'ffe9edf3'},
            {key: 'dateToEndableDispersion', label: 'Fecha para dispersar', customColor: 'ffe9edf3'},
            {key: 'amountToEnable', label: 'Monto', customColor: 'ffe9edf3'},
            {key: 'desactivationComments', label: 'Comentarios', customColor: 'ffe9edf3'},
            {key: 'statusAux', label: 'Estatus Actual', customColor: 'ffe9edf3'},
            {key: 'movement', label: 'Movimientos', customColor: 'ffe9edf3'},
            {key: 'dateMove', label: 'Fecha', customColor: 'ffe9edf3'},
            {key: 'user', label: 'Usuario', customColor: 'ffe9edf3'}
        ];
        let excelFormatedData = [];

        if(!allCompanies.companies.length){
            notify('No hay información para generar el archivo', 'error')
            return undefined
        }

        let start = moment(startDay).subtract(1, 'day');
        let end = moment(endDay).add(1, 'day');
    
        //console.log(allCompanies)
        for(let data of allCompanies.companies){
            if(data.business_name.trim() != ''){
                for(let i =0; i<data.history.length;i++){
                    let timeTmp = moment(data.history[i].createdAtFormated)
                    if(timeTmp.isSameOrAfter(start) && timeTmp.isSameOrBefore(end)){
                        excelFormatedData.push({
                            business_name: data.business_name,
                            dateToEndableDispersion: data.dateToEndableDispersion,
                            amountToEnable:data.history[i].action.includes("Actualización de monto a: ") ? '$' + data.history[i].action.replace("Actualización de monto a: ", "") : "---",
                            desactivationComments: data.history[i].desactivationComments ,
                            statusAux: data.isDisabled ? 'Suspendida' : (data.isDisabled == false ? 'Liberada' : 'Sin movimientos'),
                                movement : data.history[i].action,
                                dateMove: data.history[i].createdAtFormated,
                                user: data.history[i].createdBy
                            });
                    }
                    

                }
              
                
            }
        }
        

        excelFormatedData.sort((a, b) => {
            if (a.business_name < b.business_name) {
              return -1;
            }
            if (a.business_name > b.business_name) {
              return 1;
            }
            return 0;
        });

        const workbook = new Excel.Workbook();  
        

        await excelByItems.test(
            workbook,
            [{
                name: 'Compañías deshabilitadas',
                values: excelFormatedData
            }]
            ,
            excelKeys,
            moment().format(),
            createdBy
        );
        
        let result = workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
            return blob;
        });

        return result

    }catch(error){
        console.log(error);
        notify('Hubo un problema al generar el archivo', 'error')
    }
}